export const StyleFix = () => {
  return (
    <style>
      {`
        html, body, #root {
            height: 100%;
            background-color: white;
        }
      `}
    </style>
  );
};
