/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceDiscountsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.MarketplaceDiscountFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceDiscountSortingInput>;
}>;


export type MarketplaceDiscountsQuery = { __typename?: 'Query', marketplaceDiscounts?: { __typename?: 'MarketplaceDiscountConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceDiscountEdge', node: { __typename?: 'MarketplaceDiscount', id: string, name?: string | null, isPublished: boolean, startDate: any, endDate: any, percentage?: number | null, type: Types.MarketplaceDiscountType, amount?: any | null, products?: Array<{ __typename?: 'MarketplaceProduct', id: string, nameAr?: string | null, name?: string | null } | null> | null, healthPackages?: Array<{ __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null } | null> | null } }> } | null };


export const MarketplaceDiscountsDocument = gql`
    query MarketplaceDiscounts($after: String, $before: String, $filter: MarketplaceDiscountFilterInput, $first: Int, $last: Int, $sortBy: MarketplaceDiscountSortingInput) {
  marketplaceDiscounts(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
        isPublished
        products {
          id
          nameAr
          name
        }
        healthPackages {
          id
          name
          nameAr
        }
        startDate
        endDate
        percentage
        type
        amount
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useMarketplaceDiscountsQuery__
 *
 * To run a query within a React component, call `useMarketplaceDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceDiscountsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketplaceDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceDiscountsQuery, MarketplaceDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceDiscountsQuery, MarketplaceDiscountsQueryVariables>(MarketplaceDiscountsDocument, options);
      }
export function useMarketplaceDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceDiscountsQuery, MarketplaceDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceDiscountsQuery, MarketplaceDiscountsQueryVariables>(MarketplaceDiscountsDocument, options);
        }
export type MarketplaceDiscountsQueryHookResult = ReturnType<typeof useMarketplaceDiscountsQuery>;
export type MarketplaceDiscountsLazyQueryHookResult = ReturnType<typeof useMarketplaceDiscountsLazyQuery>;
export type MarketplaceDiscountsQueryResult = Apollo.QueryResult<MarketplaceDiscountsQuery, MarketplaceDiscountsQueryVariables>;