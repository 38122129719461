/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorLicenseQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DoctorLicenseQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, healthLicenseEndDate?: any | null } | null };


export const DoctorLicenseDocument = gql`
    query doctorLicense {
  me {
    id
    healthLicenseEndDate
  }
}
    `;

/**
 * __useDoctorLicenseQuery__
 *
 * To run a query within a React component, call `useDoctorLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useDoctorLicenseQuery(baseOptions?: Apollo.QueryHookOptions<DoctorLicenseQuery, DoctorLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorLicenseQuery, DoctorLicenseQueryVariables>(DoctorLicenseDocument, options);
      }
export function useDoctorLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorLicenseQuery, DoctorLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorLicenseQuery, DoctorLicenseQueryVariables>(DoctorLicenseDocument, options);
        }
export type DoctorLicenseQueryHookResult = ReturnType<typeof useDoctorLicenseQuery>;
export type DoctorLicenseLazyQueryHookResult = ReturnType<typeof useDoctorLicenseLazyQuery>;
export type DoctorLicenseQueryResult = Apollo.QueryResult<DoctorLicenseQuery, DoctorLicenseQueryVariables>;