import { useEffect, useMemo } from "react";
import { useBreadCrumbs } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { SadaApp } from "@health/sada";
import { useLocation } from "react-router-dom";

export const StreamingPage: React.FC = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const location = useLocation();
  const currentUrl = useMemo(() => ({ url: `${location.pathname}${location.search}${location.hash}` }), [location]);

  useEffect(() => {
    setBreadCrumb({ title: t("Streaming"), values: [] });
  }, [setBreadCrumb, t]);

  return <SadaApp currentUrl={currentUrl} appType='provider' />;
};

export default StreamingPage;
