import { NetworkStatus } from "@apollo/client/core";
import { AppointmentSortingField, H_OrderDirection } from "@health/queries/types";
import { useOnAppointmentStatusUpdate } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import { Button, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { AppointmentReschedule } from "pages/appointments";
import { FC } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isReceptionistUser } from "utils";
import { useFetchDashboardAppointmentsQuery } from "../../gql/queries";
import { EmptyCard, EventCard } from "../Cards";
import { CardsContainer } from "../Cards/CardContainer";
import { ViewPatientJourney } from "../ViewPatientJourney/ViewPatientJourney";

export const EventCards: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const {
    data,
    networkStatus,
    refetch: refetchAppointment,
  } = useFetchDashboardAppointmentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      isAdmin: false,
      first: 1,
      filter: {
        upcoming: true,
        selfOnly: true,
      },
      sortBy: {
        direction: H_OrderDirection.Asc,
        field: AppointmentSortingField.ConfirmedStartDate,
      },
    },
  });

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      refetchAppointment();
    }
  });

  const upcomingAppointments = data?.appointments?.edges?.map(edge => edge.node) || [];
  const isLoadingAppointments = NetworkStatus.loading === networkStatus;
  const { userInfo } = useOidcUserProfile();
  const isReceptionist = isReceptionistUser(userInfo);
  const AppointmentEventCards = upcomingAppointments?.length
    ? upcomingAppointments?.map((upcomingAppointment, idx) => (
        <EventCard
          key={idx}
          isAdmin={false}
          doctorName={upcomingAppointment.doctor?.user?.fullName ?? ""}
          eventDate={moment(upcomingAppointment?.confirmedStartTime).toDate()}
          patientName={upcomingAppointment?.consumerUser?.fullName ?? ""}
          careType={upcomingAppointment?.type ?? ""}
          cardWidth={"600px"}
          cardActions={
            <AppointmentReschedule
              status={upcomingAppointment?.status || ""}
              id={upcomingAppointment?.id!}
              doctorId={upcomingAppointment?.doctor?.id}
              buttonEl={
                <Button
                  sx={{
                    color: theme.palette.common.white,
                    width: 140,
                    height: 48,
                    borderRadius: 24,
                    textAlign: "center",
                    lineHeight: 3,
                    border: "1px solid " + theme.palette.common.white,
                  }}
                  variant='text'
                >
                  {t("Reschedule")}
                </Button>
              }
            />
          }
        />
      ))
    : [<EmptyCard key={0} message={t("No Upcoming Appointments")} />];
  const cards = [<ViewPatientJourney key={0} />, ...AppointmentEventCards];
  isReceptionist && cards.shift();
  return <CardsContainer isLoadingData={isLoadingAppointments} cards={cards} />;
};
