import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { OrdersInvoicesPage } from "@health/domains";
import { marketplaceOrdersInvoicesTitleBreadcrumb } from "../constants/MarketplaceOrderInvoicesBreadcrumbs";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isVendorAdmin } from "utils";

export const ProviderMarketPlaceOrdersInvoicesContainer = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const { userInfo } = useOidcUserProfile();
  const isUserVendorAdmin = isVendorAdmin(userInfo);

  useEffect(() => {
    setBreadCrumb({
      title: marketplaceOrdersInvoicesTitleBreadcrumb(t),
    });
  }, []);

  return <OrdersInvoicesPage canDownloadReport={isUserVendorAdmin} />;
};
