/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorAppointmentCancelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.CancelInput;
}>;


export type DoctorAppointmentCancelMutation = { __typename?: 'Mutation', doctorAppointmentCancel?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string, created?: any | null, status?: Types.AppointmentStatus | null, cancellationReason?: string | null } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const DoctorAppointmentCancelDocument = gql`
    mutation doctorAppointmentCancel($id: ID!, $input: CancelInput!) {
  doctorAppointmentCancel(id: $id, input: $input) {
    appointment {
      id
      created
      status
      cancellationReason
    }
    appointmentErrors {
      code
      field
      message
    }
  }
}
    `;
export type DoctorAppointmentCancelMutationFn = Apollo.MutationFunction<DoctorAppointmentCancelMutation, DoctorAppointmentCancelMutationVariables>;

/**
 * __useDoctorAppointmentCancelMutation__
 *
 * To run a mutation, you first call `useDoctorAppointmentCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorAppointmentCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorAppointmentCancelMutation, { data, loading, error }] = useDoctorAppointmentCancelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoctorAppointmentCancelMutation(baseOptions?: Apollo.MutationHookOptions<DoctorAppointmentCancelMutation, DoctorAppointmentCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorAppointmentCancelMutation, DoctorAppointmentCancelMutationVariables>(DoctorAppointmentCancelDocument, options);
      }
export type DoctorAppointmentCancelMutationHookResult = ReturnType<typeof useDoctorAppointmentCancelMutation>;
export type DoctorAppointmentCancelMutationResult = Apollo.MutationResult<DoctorAppointmentCancelMutation>;
export type DoctorAppointmentCancelMutationOptions = Apollo.BaseMutationOptions<DoctorAppointmentCancelMutation, DoctorAppointmentCancelMutationVariables>;