import { GuidedCareJourneyItem, GuidedCareJourneyItemFilter } from "@health/queries/types";
import moment from "moment";
import { FC } from "react";
import { ActivityItemsDialog } from "./ActivityItemsDialog";
import { AppointmentIconView } from "./AppointmentIconView";
import JourneyTimelineItem from "./TimelineItem";

type AnnualTimeLineItemProps = {
  year: number;
  filterInput?: GuidedCareJourneyItemFilter;
  activities?: GuidedCareJourneyItem[];
  hasSimilar: boolean;
  selectedItem: GuidedCareJourneyItem;
  onSelectItem: (appointment: GuidedCareJourneyItem) => void;
};

export const AnnualTimeLineItem: FC<AnnualTimeLineItemProps> = props => {
  const { year, filterInput, activities, hasSimilar, selectedItem, onSelectItem } = props;

  const numItemsToShow = window.innerWidth > 1300 ? 10 : 5;

  return (
    <>
      {activities?.slice(0, numItemsToShow)?.map((activity, index) => {
        const getCurrentMonthFromActivity = moment(activity?.dueDate!).month();
        const firstDateOfMonth = moment({ year, month: getCurrentMonthFromActivity }).startOf("month").format("YYYY-MM-DD");
        const lastDateOfMonth = moment({ year, month: getCurrentMonthFromActivity }).endOf("month").format("YYYY-MM-DD");

        return (
          <>
            <JourneyTimelineItem item={activity} key={activity?.id}>
              <AppointmentIconView
                item={activity}
                hasSimilar={hasSimilar}
                selectedItem={selectedItem!}
                onSelectItem={() => onSelectItem(activity)}
              />
            </JourneyTimelineItem>

            {index + 1 === numItemsToShow && activities?.length! > numItemsToShow && (
              <ActivityItemsDialog filterInput={{ ...filterInput, dueDate: { gte: firstDateOfMonth, lte: lastDateOfMonth } }} />
            )}
          </>
        );
      })}
    </>
  );
};
