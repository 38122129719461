import { useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, useTheme } from "@toolkit/ui";
import React from "react";

export const ChatButton = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Button
      startIcon={<CustomIcon icon='chatCloud' color={theme.palette.common.white} />}
      sx={{
        background: theme.palette.warning.main,
        color: theme.palette.common.white,
        margin: 5,
      }}
    >
      {t("You have a chat request")}
    </Button>
  );
};
