import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CircularProgress, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const TranscriptionLoading: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: "400px",
        backgroundColor: theme.palette.common.white,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", mb: 1 }}>
        <CircularProgress size={40} sx={{ mx: 1, my: 1 }} />
        {t("Loading")}...
      </Box>
      <Typography textAlign={"center"} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
        {t("Your transcript is being processed")}
      </Typography>
      <Typography textAlign={"center"}>{t("Please try again later")}</Typography>
    </Card>
  );
};
