import { TFunction } from "@toolkit/i18n";
import { healthPackagesManagementPaths } from "./route";

export const healthPackageListBreadCrumb = (t: TFunction) => {
  return {
    id: healthPackagesManagementPaths.indexPage.fullPath,
    name: t("Packages", { ns: "provider" }),
    route: healthPackagesManagementPaths.indexPage.fullPath,
  };
};

export const healthPackageNewBreadCrumb = (t: TFunction) => {
  return {
    id: healthPackagesManagementPaths.addNew.fullPath,
    name: t("New", { ns: "provider" }),
    // route: healthPackagesManagementPaths.addNew.fullPath
  };
};

export const healthPackageEditBreadCrumb = (healthPackageName: string | undefined) => {
  return {
    id: healthPackagesManagementPaths.edit.fullPath,
    name: healthPackageName || "",
    // route: healthPackagesManagementPaths.edit.fullPath.replace(":id", healthPackageId)
  };
};
