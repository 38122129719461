import { TFunction } from "@toolkit/i18n";
import { healthProviderBranchesPaths } from "../constants/HealthProvidersManagementPaths";

export const branchListBreadCrumb = (t: TFunction) => {
  return {
    id: healthProviderBranchesPaths.indexPage.fullPath,
    name: t("Branches", { ns: "provider" }),
    route: healthProviderBranchesPaths.indexPage.fullPath,
  };
};

export const branchNewBreadCrumb = (t: TFunction) => {
  return {
    id: healthProviderBranchesPaths.addNew.fullPath,
    name: t("New", { ns: "provider" }),
    // route: healthProviderBranchesPaths.addNew.fullPath
  };
};

export const branchEditBreadCrumb = (branchId: string, branchName: string | undefined, t: TFunction, isRouteActive = true) => {
  return {
    id: healthProviderBranchesPaths.edit.fullPath,
    name: branchName || "",
    route: isRouteActive ? healthProviderBranchesPaths.edit.fullPathWithParams({ branchId }) : undefined,
  };
};

export const branchUserListBreadCrumb = (t: TFunction) => {
  return {
    id: healthProviderBranchesPaths.branchUsers.fullPath,
    name: t("Users", { ns: "provider" }),
    // route: healthProviderBranchesPaths.branchUsers.fullPath.replace(":id", branchId)
  };
};
