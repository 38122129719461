import { TFunction } from "@toolkit/i18n";
import { StaffIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { GuidedCarePatientsScreen } from "./GuidedPatients";

const guidedCarePatientsRoute = "/guided-care-patients";

export const settingsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${guidedCarePatientsRoute}${this.route}`;
    },
  },
};

export const guidedCarePatientsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({
  navigate,
  t,
}): RouteItem => {
  return {
    id: "guidedCarePatients",
    text: t("Guided Care Patients", { ns: "provider" }),
    icon: <StaffIcon />,
    route: guidedCarePatientsRoute,
    fullPath: settingsPaths.indexPage.fullPath,
    element: <GuidedCarePatientsScreen />,
    onClick: (route: string) => navigate(route),
  };
};
