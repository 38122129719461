import { Appointment, AppointmentSortingField, AppointmentStatus, H_OrderDirection } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CircularProgress, EmptyCard, useGridContext, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { OngoingVisitCard } from "pages/appointments/components";
import { useAppointmentsQuery } from "pages/appointments/gql";
import { FC } from "react";

export type OngoingVisitProps = {
  isSelfOnlyAppointments: boolean;
};

export const OngoingVisit: FC<OngoingVisitProps> = props => {
  const { isSelfOnlyAppointments } = props;

  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { activeFiltersProps } = useGridContext();
  const requestedFilters = activeFiltersProps?.requestedFilters;

  const { data, loading } = useAppointmentsQuery({
    variables: {
      first: 1,
      filter: {
        selfOnly: isSelfOnlyAppointments,
        confirmedEndTime: {
          gte: moment().format(),
        },
        status: [AppointmentStatus.ChatInProgress, AppointmentStatus.CallInProgress],
        ...requestedFilters,
      },
      includeCancellationReason: false,
      sortBy: {
        direction: H_OrderDirection.Desc,
        field: AppointmentSortingField.ConfirmedStartDate,
      },
    },
    fetchPolicy: "no-cache",
  });

  const appointments = (data?.appointments?.edges?.map(item => item?.node) || []) as Appointment[];

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", flex: 1, justifyContent: "center", m: 1, height: "100%" }}>
          <CircularProgress size={70} />
        </Box>
      ) : (
        <>
          {appointments?.length > 0 ? (
            appointments?.map(item => <OngoingVisitCard key={item?.id} appointment={item} />)
          ) : (
            <Card sx={{ padding: "40px", boxShadow: theme.mixins.shadows.none, maxWidth: "924px", marginInline: "auto" }}>
              <EmptyCard title={t("There's no ongoing visit")} />
            </Card>
          )}
        </>
      )}
    </>
  );
};
