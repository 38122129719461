import { useTranslation } from "@toolkit/i18n";
import { Grid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { ComingSoonAppointmentsGrid } from "../ComingSoonAppointmentsGrid";
import { PendingAppointmentsGrid } from "../PendingAppointmentsGrid";
import { WaitingRoomsList } from "../WaitingRooms";
import { NurseUserFeedsCard } from "./NurseUserFeeds";
import { GuidedCareTaskList } from "../../../GuidedCareTasks/components/GuidedCareTaskList/GuidedCareTaskList";

export const NurseDashboardView: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  return (
    <Grid container spacing={2} padding={4}>
      <Grid item xs={12}>
        <Typography variant='h1' sx={{ fontWeight: theme.mixins.fonts.fontWeight.medium, fontSize: 26 }}>
          {t("Nurse Dashboard")}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12} order={{ xs: 1, md: 1 }}>
            <Typography
              variant='subtitle1'
              fontSize={theme.mixins.fonts.fontSize.md}
              fontWeight={theme.mixins.fonts.fontWeight.medium}
              mb={1}
            >
              {t("Virtual waiting rooms")}
            </Typography>

            <WaitingRoomsList />
          </Grid>

          <Grid item xs={12} order={{ xs: 4, md: 3 }}>
            <Typography
              variant='subtitle1'
              fontSize={theme.mixins.fonts.fontSize.md}
              fontWeight={theme.mixins.fonts.fontWeight.medium}
              mb={1}
            >
              {t("My Feeds")}
              <NurseUserFeedsCard />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={8}>
        <Grid item xs={12} order={{ xs: 2, md: 2 }}>
          <Typography
            variant='subtitle1'
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            mb={1}
          >
            {t("Upcoming Appointments")}
          </Typography>

          <ComingSoonAppointmentsGrid
            TableContainerProps={{
              sx: {
                height: 357.5,
              },
            }}
            pageSize={5}
            emptyIconHeight={100}
            iconBoxProps={{
              marginBottom: "90px",
              height: "5px",
            }}
          />
        </Grid>

        <Grid item xs={12} order={{ xs: 3, md: 4 }}>
          <Typography
            variant='subtitle1'
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            mb={1}
          >
            {t("Pending Items")}
          </Typography>

          <PendingAppointmentsGrid
            TableContainerProps={{
              sx: {
                height: 357.5,
              },
            }}
            pageSize={5}
            emptyIconHeight={100}
            iconBoxProps={{
              marginBottom: "90px",
              height: "5px",
            }}
          />
        </Grid>

        <Grid item xs={12} mt={2}>
          <GuidedCareTaskList />
        </Grid>
      </Grid>
    </Grid>
  );
};
