/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBranchUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBranchUsersQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, users?: Array<{ __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null, isActive: boolean } | null> | null } | null };


export const GetBranchUsersDocument = gql`
    query getBranchUsers($id: ID!) {
  branch(id: $id) {
    id
    name
    users {
      id
      email
      firstName
      lastName
      mobile
      isActive
    }
  }
}
    `;

/**
 * __useGetBranchUsersQuery__
 *
 * To run a query within a React component, call `useGetBranchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBranchUsersQuery(baseOptions: Apollo.QueryHookOptions<GetBranchUsersQuery, GetBranchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchUsersQuery, GetBranchUsersQueryVariables>(GetBranchUsersDocument, options);
      }
export function useGetBranchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchUsersQuery, GetBranchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchUsersQuery, GetBranchUsersQueryVariables>(GetBranchUsersDocument, options);
        }
export type GetBranchUsersQueryHookResult = ReturnType<typeof useGetBranchUsersQuery>;
export type GetBranchUsersLazyQueryHookResult = ReturnType<typeof useGetBranchUsersLazyQuery>;
export type GetBranchUsersQueryResult = Apollo.QueryResult<GetBranchUsersQuery, GetBranchUsersQueryVariables>;