import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  ButtonProps,
  CloseXIcon,
  CustomDialog,
  FormControlLabel,
  IconButton,
  IconProps,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@toolkit/ui";
import { FC } from "react";
import { getReasonText } from "utils/reasons";
import { useRejectedModelHook } from "./useRejectedModelHook";

export const RejectModel: FC<{
  id;
  onClose?: () => void;
  icon?: JSX.Element;
  iconProps?: IconProps;
  buttonProps?: ButtonProps;
}> = ({ id, onClose, iconProps, buttonProps, icon }) => {
  const { t } = useTranslation("provider");
  const {
    isSubmitting,
    handleSubmit,
    handleChangeOtherReason,
    handleChangeReason,
    handleToggle,
    state,
    isOpen,
    reasons,
    reasonsLoading,
    handleToggleDialog,
  } = useRejectedModelHook({ id, onClose });
  const disabled = reasonsLoading || isSubmitting || state.isOther ? !state.other : !state.reason;
  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      maxWidth='sm'
      open={isOpen}
      DialogTitleProps={{
        onClose: handleToggleDialog,
        title: t("Rejected Reason"),
      }}
      DialogActionsProps={{
        children: (
          <Button color='error' disabled={disabled} onClick={handleSubmit}>
            {t("Reject")}
          </Button>
        ),
      }}
      button={
        <IconButton onClick={handleToggle} {...buttonProps}>
          {icon ?? <CloseXIcon fill={theme.palette.common.white} color={iconProps?.color as any} {...iconProps} />}
        </IconButton>
      }
    >
      <RadioGroup onChange={handleChangeReason}>
        {reasons?.map(item => (
          <FormControlLabel
            key={item?.id}
            value={item?.text}
            control={<Radio />}
            label={t(getReasonText(item!) || "")}
            sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}
          />
        ))}
      </RadioGroup>
      {state.isOther && (
        <TextField
          label={t("Other Reason")}
          placeholder={t("Other Reason")}
          multiline
          minRows={3}
          fullWidth
          value={state.other}
          onChange={handleChangeOtherReason}
        />
      )}
    </CustomDialog>
  );
};

RejectModel.defaultProps = {
  onClose: undefined,
};
