import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, Typography, VideoConference } from "@toolkit/ui";
import { useActiveCallStyles } from "pages/programs-calls/components/ActiveCall/ActiveCall.styles";
import { AcceptVisit } from "pages/programs-calls/components/NewRequests/AcceptVisit";
import { RejectModel } from "pages/programs-calls/components/NewRequests/RejectModel";
import React, { FC } from "react";

export const IncomingCalls: FC<{ id: string; onClose: () => void }> = ({ id, onClose: handleClose }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useActiveCallStyles();

  return (
    <>
      <Grid item xs='auto'>
        <Box display='flex' justifyContent='center' alignItems='center' width='90px' height='85px' bgcolor={theme.palette.success.main}>
          <VideoConference
            sx={{
              width: "40px",
              height: "40px",
              "& path": {
                fill: `${theme.palette.common.white} !important`,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
          <Typography fontWeight='bold'>{t("New immediate call Request")}</Typography>
        </Box>
      </Grid>
      <Grid item xs='auto'>
        <AcceptVisit
          id={id}
          onVisitAccepted={handleClose}
          buttonProps={{ className: `${classes.iconButtonHover} ${classes.accept}`, sx: { marginLeft: 1 } }}
        />
        <RejectModel
          id={id}
          onClose={handleClose}
          buttonProps={{ className: `${classes.iconButtonHover} ${classes.cancel}`, sx: { marginLeft: 1 } }}
        />
      </Grid>
    </>
  );
};
