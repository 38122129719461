/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackageQueryVariables = Types.Exact<{
  healthPackageId: Types.Scalars['ID'];
}>;


export type HealthPackageQuery = { __typename?: 'Query', marketplaceHealthPackage?: { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, targetFromAge?: number | null, targetToAge?: number | null, targetGender?: Types.MarketplaceGender | null, isPublished: boolean, mainImageUrl?: string | null, approvalStatus?: Types.MarketplaceApprovalStatus | null, price?: any | null, category?: { __typename?: 'MarketplaceHealthPackageCategory', nameAr?: string | null, name?: string | null, id: string } | null } | null };


export const HealthPackageDocument = gql`
    query healthPackage($healthPackageId: ID!) {
  marketplaceHealthPackage(id: $healthPackageId) {
    id
    name
    nameAr
    description
    descriptionAr
    shortDescription
    shortDescriptionAr
    targetFromAge
    targetToAge
    targetGender
    isPublished
    mainImageUrl
    approvalStatus
    price
    category {
      nameAr
      name
      id
    }
  }
}
    `;

/**
 * __useHealthPackageQuery__
 *
 * To run a query within a React component, call `useHealthPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthPackageQuery({
 *   variables: {
 *      healthPackageId: // value for 'healthPackageId'
 *   },
 * });
 */
export function useHealthPackageQuery(baseOptions: Apollo.QueryHookOptions<HealthPackageQuery, HealthPackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthPackageQuery, HealthPackageQueryVariables>(HealthPackageDocument, options);
      }
export function useHealthPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthPackageQuery, HealthPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthPackageQuery, HealthPackageQueryVariables>(HealthPackageDocument, options);
        }
export type HealthPackageQueryHookResult = ReturnType<typeof useHealthPackageQuery>;
export type HealthPackageLazyQueryHookResult = ReturnType<typeof useHealthPackageLazyQuery>;
export type HealthPackageQueryResult = Apollo.QueryResult<HealthPackageQuery, HealthPackageQueryVariables>;