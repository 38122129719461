import { AppointmentNotificationSettingInput } from "@health/queries/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatGraphQLError } from "@toolkit/apollo";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useReminderSettingFormValidation } from "pages/Settings/components/ReminderSettingFormModal/useReminderSettingFormValidationHook";
import {
  SaveAppointmentNotificationSettingMutation,
  UpdateAppointmentNotificationSettingMutation,
  useSaveAppointmentNotificationSettingMutation,
  useUpdateAppointmentNotificationSettingMutation,
} from "pages/Settings/gql";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { GENERAL_ERROR_MESSAGE } from "shared/constants";
import { ReminderSettingFormModalProps } from "./types";

export const useReminderSettingFormModal = ({
  reminderSetting,
  appointmentType,
  onClose,
  onRefetchSettings,
}: Partial<ReminderSettingFormModalProps>) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const { getDefaultFormValues, reminderSettingFormSchema } = useReminderSettingFormValidation({ appointmentType, reminderSetting });

  const methods = useForm({
    defaultValues: getDefaultFormValues(),
    resolver: zodResolver(reminderSettingFormSchema),
  });

  const { control, register, formState, watch, handleSubmit } = methods;

  const formErrors = combineErrors(formState.errors, []);

  const onSubmit = values => {
    const _values: AppointmentNotificationSettingInput = {
      ...values,
      sendTimeInMinutes: values.sendTimeInMinutes * 60,
    };

    if (reminderSetting) {
      updateAppointmentNotificationSettingMutation({
        variables: {
          updateAppointmentNotificationSettingId: reminderSetting.id,
          input: _values,
        },
      });
    } else {
      createAppointmentNotificationSettingMutation({
        variables: {
          input: _values,
        },
      });
    }
  };

  const handleClose = () => {
    onClose?.();
    methods.reset();
  };

  const [createAppointmentNotificationSettingMutation, { loading: isSaveLoading }] = useSaveAppointmentNotificationSettingMutation({
    onCompleted: (mutationData: SaveAppointmentNotificationSettingMutation) => {
      const mutation = mutationData?.saveAppointmentNotificationSetting;
      if (mutation?.length) {
        succeeded(t("Appointment Notifications Saved"));
        onRefetchSettings?.();
        handleClose();
      } else {
        failed(t(GENERAL_ERROR_MESSAGE));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const [updateAppointmentNotificationSettingMutation, { loading: isUpdateLoading }] = useUpdateAppointmentNotificationSettingMutation({
    onCompleted: (mutationData: UpdateAppointmentNotificationSettingMutation) => {
      const mutation = mutationData?.updateAppointmentNotificationSetting;
      if (mutation) {
        succeeded(t("Appointment Notifications Saved"));
        onRefetchSettings?.();
        handleClose();
      } else {
        failed(t(GENERAL_ERROR_MESSAGE));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  useEffect(() => {
    methods?.reset(getDefaultFormValues());
  }, [reminderSetting, appointmentType]);

  const sendTimeInMinutes = watch("sendTimeInMinutes");

  return {
    methods,
    control,
    formState,
    formErrors,
    sendTimeInMinutes,
    isLoading: isSaveLoading || isUpdateLoading,
    handleSubmit,
    onSubmit,
    register,
    watch,
    handleClose,
    t,
  };
};

export default useReminderSettingFormModal;
