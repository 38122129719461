import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import React from "react";
import { EventCardRoot } from "./EventCardRoot";

export const EmptyCard: React.FC<{ message?: string }> = ({ message }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <EventCardRoot classNames='card' cardWidth={"100%"}>
      <Box
        sx={{
          padding: "24px",
          width: "100%",
        }}
      >
        <Typography
          variant='subtitle1'
          fontSize={theme.mixins.fonts.fontSize.xxl}
          fontWeight={theme.mixins.fonts.fontWeight.medium}
          sx={{ lineHeight: 1 }}
          alignContent={"center"}
        >
          {message || t("No Items")}
        </Typography>
      </Box>
    </EventCardRoot>
  );
};

export default EmptyCard;
