const DiagnosisIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.517 25.563' width={23.517} height={25.563} {...props}>
      <path
        d='M19.427 0a3.068 3.068 0 1 0 3.067 3.068A3.068 3.068 0 0 0 19.427 0ZM1.022 1.023A1.023 1.023 0 0 0 0 2.045v4.09a6.153 6.153 0 0 0 4.224 5.824 2.036 2.036 0 0 0 .889 1.05v2.329a4.106 4.106 0 0 0 4.09 4.09h7.157a2.03 2.03 0 0 0 .411-.042 2.008 2.008 0 0 0 .379-.12 2.052 2.052 0 0 0 1.092-1.092 2.008 2.008 0 0 0 .12-.379 2.011 2.011 0 0 0 .042-.409v-3.354a2.045 2.045 0 1 1 2.045 0v3.351a4.031 4.031 0 0 1-.427 1.781l.361 5.412a1.057 1.057 0 0 0 2.111-.07v-6.1a1.022 1.022 0 0 0 1.023-1.023v-6.135A3.071 3.071 0 0 0 20.45 8.18H18.4a3.071 3.071 0 0 0-3.067 3.068v6.135H9.2a2.029 2.029 0 0 1-2.045-2.045v-2.329a2.035 2.035 0 0 0 .911-1.12 6.077 6.077 0 0 0 4.2-5.754v-4.09a1.023 1.023 0 0 0-1.022-1.023H9.2a1.023 1.023 0 1 0 0 2.045h1.022v3.068a4.069 4.069 0 0 1-4 4.08 1.022 1.022 0 0 0-.108 0h-.078a4.069 4.069 0 0 1-4-4.08V3.068h1.031a1.023 1.023 0 1 0 0-2.045ZM18.728 20.7a4.061 4.061 0 0 1-2.369.769v3.034a1.057 1.057 0 0 0 2.111.07Z'
        fill='#fff'
      />
    </svg>
  );
};

export default DiagnosisIcon;
