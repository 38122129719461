import { makeVar } from "@apollo/client";

export const isOpenOrderCardDialog = makeVar<boolean>(false);

export const OrderColorEnum = {
  primary: "#1a1c59",
  warning: "#d46a3d",
  success: "#4fa043",
  info: "#607795",
  error: "#913263",
};
