import { formGirdSpacing } from "@toolkit/core";
import { Grid } from "@toolkit/ui";
import ContentLoader from "react-content-loader";

export const ReminderSettingLoader: React.VFC = () => {
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item xs={12} md={8}>
        <ContentLoader width={"100%"} height={100} viewBox='0 0 500 20'>
          <rect x='16' y='0' rx='3' ry='3' width='100%' height='20' />
        </ContentLoader>
      </Grid>
      <Grid item xs={false} md={4} />
      <Grid item xs={12} md={4}>
        <ContentLoader width={"100%"} height={100} viewBox='0 0 700 100'>
          <rect x='16' y='0' rx='3' ry='3' width='100%' height='100' />
        </ContentLoader>
      </Grid>
      <Grid item xs={12} md={4}>
        <ContentLoader width={"100%"} height={100} viewBox='0 0 700 100'>
          <rect x='16' y='0' rx='3' ry='3' width='100%' height='100' />
        </ContentLoader>
      </Grid>
      <Grid item xs={false} md={4} />
      <Grid item xs={12} md={8}>
        <ContentLoader width={"100%"} height={100} viewBox='0 0 500 20'>
          <rect x='16' y='0' rx='3' ry='3' width='100%' height='20' />
        </ContentLoader>
      </Grid>
    </Grid>
  );
};

export default ReminderSettingLoader;
