/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DoctorQuery = { __typename?: 'Query', user?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, photo?: string | null, doctor?: { __typename?: 'Doctor', bio?: string | null, id: string, seniority?: Types.DoctorSeniorityEnum | null } | null } | null };


export const DoctorDocument = gql`
    query Doctor($id: ID!) {
  user(id: $id) {
    id
    firstName
    lastName
    photo
    doctor {
      bio
      id
      seniority
    }
    __typename
  }
}
    `;

/**
 * __useDoctorQuery__
 *
 * To run a query within a React component, call `useDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDoctorQuery(baseOptions: Apollo.QueryHookOptions<DoctorQuery, DoctorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorQuery, DoctorQueryVariables>(DoctorDocument, options);
      }
export function useDoctorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorQuery, DoctorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorQuery, DoctorQueryVariables>(DoctorDocument, options);
        }
export type DoctorQueryHookResult = ReturnType<typeof useDoctorQuery>;
export type DoctorLazyQueryHookResult = ReturnType<typeof useDoctorLazyQuery>;
export type DoctorQueryResult = Apollo.QueryResult<DoctorQuery, DoctorQueryVariables>;