import { useTranslation } from "@toolkit/i18n";
import { last } from "lodash";
import { FC } from "react";
import BarLineChart from "../Charts/BarLineChart";
import { OverviewCard } from "./OverviewCard";
import { IVitalSignChart } from "./types";
import { Loader, useTheme } from "@toolkit/ui";
import { usePatientHealthParametersQuery } from "../../gql";
import { createPatientHealthParameterItem } from "./utils";
import { getApolloContextFormToken } from "../../../../utils";
import { useVisitsVitalSignsChanged } from "@health/sse";
import { H_OrderDirection, PatientHealthParameterSortField } from "@health/queries/types";
import { healthParameterCodes } from "pages/PatientHealthParameters/constants";

type VitalSignTemperatureChartProps = IVitalSignChart;

export const VitalSignTemperatureChart: FC<VitalSignTemperatureChartProps> = props => {
  const { token, percentage, width, patientId } = props;

  const { t } = useTranslation("provider");
  const {
    palette: { secondary },
  } = useTheme();

  const { data, loading, refetch } = usePatientHealthParametersQuery({
    variables: {
      first: 10,
      filter: {
        patientId: [patientId],
        codes: [healthParameterCodes.temperature],
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const patientHealthParameters = data?.patientHealthParameters?.edges?.map(item => item?.node) || [];

  const healthParameters = createPatientHealthParameterItem(patientHealthParameters, "°C");

  const lastItem = last(healthParameters);

  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.hpCode === healthParameterCodes.temperature) {
      refetch({
        first: 10,
        filter: {
          patientId: [patientId],
          codes: [healthParameterCodes.temperature],
        },
      });
    }
  });

  return (
    <OverviewCard
      width={width}
      title={t("Temperature")}
      value={`${lastItem?.value || 0}`}
      percentage={percentage}
      unit={"°C"}
      date={lastItem?.date!}
      description={t("By") + " " + (lastItem?.source! || "-")}
    >
      {loading ? <Loader /> : <BarLineChart title={t("Temperature")} color={secondary.main} data={healthParameters} />}
    </OverviewCard>
  );
};
