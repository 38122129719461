/* eslint-disable max-lines */
import { NotificationChannel } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import {
  Checkbox,
  CircularProgress,
  CustomDialog,
  FormControlLabel,
  FormHelperText,
  Grid,
  StyledButton,
  TextField,
  Typography,
  useTheme,
} from "@toolkit/ui";
import { FC } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { ReminderSettingFormModalProps } from "./types";
import useReminderSettingFormModal from "./useReminderSettingFormModal";

const NotificationChannelsData = [
  {
    label: "SMS",
    value: NotificationChannel.Sms,
  },
  {
    label: "using-email",
    value: NotificationChannel.Email,
  },
  {
    label: "App Notification",
    value: NotificationChannel.App,
  },
];

export const ReminderSettingFormModal: FC<ReminderSettingFormModalProps> = ({
  buttonRender,
  isOpen,
  isConfirmation,
  reminderSetting,
  appointmentType,
  onClose,
  onRefetchSettings,
}) => {
  const { sendTimeInMinutes, methods, control, formErrors, isLoading, t, register, handleSubmit, onSubmit, handleClose } =
    useReminderSettingFormModal({
      isOpen,
      reminderSetting,
      appointmentType,
      onClose,
      onRefetchSettings,
    });
  const theme = useTheme();

  return (
    <CustomDialog
      type='base'
      onClose={handleClose}
      open={isOpen}
      button={buttonRender}
      DialogTitleProps={{
        title: reminderSetting ? t("Edit Reminder Settings") : t("Add Reminder Settings"),
      }}
      DialogActionsProps={{
        children: (
          <StyledButton disabled={isLoading || sendTimeInMinutes <= 0} size='medium' type='submit' onClick={handleSubmit(onSubmit)}>
            {isLoading && <CircularProgress color='inherit' size={20} sx={{ mx: 1 }} />} {isLoading ? t("Saving...") : t("Save")}
          </StyledButton>
        ),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.sm, marginBottom: 2 }}>{t("Remind the patient before")}</Typography>
              <TextField
                fullWidth
                size='small'
                type='number'
                label={t("Time (Hours)")}
                error={Boolean(formErrors.sendTimeInMinutes?.message)}
                helperText={t(formErrors.sendTimeInMinutes?.message)}
                {...register("sendTimeInMinutes")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}>
                {isConfirmation ? t("Confirmation Message Text") : t("Reminder Message Text")}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                size='small'
                label={isConfirmation ? t("Confirmation Message Text (English)") : t("Reminder Message Text (English)")}
                multiline
                rows={7}
                error={Boolean(formErrors.englishText?.message)}
                helperText={t(formErrors.englishText?.message)}
                {...register("englishText")}
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                size='small'
                dir={"rtl"}
                label={isConfirmation ? t("Confirmation Message Text (Arabic)") : t("Reminder Message Text (Arabic)")}
                multiline
                rows={7}
                error={Boolean(formErrors.arabicText?.message)}
                helperText={t(formErrors.arabicText?.message)}
                {...register("arabicText")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}>{t("Messages Channels")}</Typography>

              <Controller
                name='channels'
                control={control}
                render={({ field: { value, onChange, ...props } }) => (
                  <>
                    {NotificationChannelsData.map(channel => {
                      const handleChange = (event, checked) => {
                        if (checked) {
                          onChange([...value, event.target.value]);
                        } else {
                          onChange(value.filter(value => value !== event.target.value));
                        }
                      };
                      return (
                        <FormControlLabel
                          key={channel.value}
                          label={t(channel.label)}
                          control={
                            <Checkbox
                              {...props}
                              value={channel.value}
                              checked={value.some(item => item === channel.value)}
                              onChange={handleChange}
                            />
                          }
                        />
                      );
                    })}

                    {formErrors?.channels?.message && (
                      <FormHelperText error={!!formErrors?.channels?.message}>{t(formErrors?.channels?.message)}</FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
