import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { discountsBreadcrumb } from "../../constants";
import { discountsPaths } from "../../constants";
import { DiscountUpsertForm, IDiscountUpsertFormValues } from "../../forms";
import { MarketplaceDiscountUpdateMutation, useMarketplaceDiscountUpdateMutation } from "../../gql";
import { useMarketplaceDiscountQuery } from "../../gql";
import { IDiscountUpdateContainerParams } from "../../types";
import { convertDiscountFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const DiscountUpdateContainer: React.FC = () => {
  const { t } = useTranslation("admin");
  const { discountId } = useParams<IDiscountUpdateContainerParams>();

  const { data, loading } = useMarketplaceDiscountQuery({
    variables: { marketplaceDiscountId: discountId! },
    skip: !discountId,
  });
  const discount = data?.marketplaceDiscount;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchDiscountUpdateMutation, { loading: isSubmitting }] = useMarketplaceDiscountUpdateMutation({
    onCompleted: (data: MarketplaceDiscountUpdateMutation) => {
      if (data?.marketplaceDiscountUpdate?.id) {
        succeeded(t("Discount updated successfully!"));
        navigate(discountsPaths.main.fullPath);
      } else {
        failed(t("Discount update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onDiscountUpsertFormChange = (values: IDiscountUpsertFormValues) => {
    const _values = convertDiscountFormValuesToBackEndValues(values, true);

    fetchDiscountUpdateMutation({
      variables: {
        marketplaceDiscountUpdateId: discountId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, update } = discountsBreadcrumb();
    setBreadCrumb({ title, values: [main, update(pickLocalizedValue(discount?.name!, discount?.name!))] });
  }, [setBreadCrumb, discount, t]);
  return (
    <DiscountUpsertForm
      isLoading={loading}
      discount={discount!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onDiscountUpsertFormChange}
    />
  );
};
