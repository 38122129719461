export const variantSubRoute = "variants/form";

const categories = "categories";
const productsManagement = "products-management";
const products = `products`;

const newProduct = `${products}/form`;
const updateProduct = `${products}/form/:id`;
const productVariant = `${updateProduct}/${variantSubRoute}`;
const addProductVariant = `${productVariant}`;
const editProductVariant = `${productVariant}/:vid`;

const attributes = "attributes";
const productType = "product-types";
const newPath = "new";
const editPath = `edit/:id`;
const subCat = `:id/sub-categories`;
const newProductType = "new";
const editProductType = "edit-product-type/:id";
const newAttribute = "new";
const updateAttribute = "update/:id";
const productBrand = "brand";
const newProductBrand = "new";
const editProductBrand = "update/:id";

export const productsManagementRoutes = {
  name: "Products Management",
  path: productsManagement,
  products: {
    name: "Products",
    path: products,
    fullPath: [productsManagement, products].join("/"),
    new: {
      name: "new Product",
      path: newProduct,
      fullPath: [productsManagement, newProduct].join("/"),
    },
    edit: {
      name: "Update Product",
      path: updateProduct,
      fullPath: [productsManagement, updateProduct].join("/"),
    },
    variantEdit: {
      name: "Update Variant",
      path: editProductVariant,
      fullPath: [productsManagement, editProductVariant].join("/"),
    },
    variantAdd: {
      id: "new-Variant",
      name: "Add Variant",
      path: addProductVariant,
      fullPath: [productsManagement, addProductVariant].join("/"),
    },
  },

  attributes: {
    name: "Attributes",
    path: attributes,
    fullPath: [productsManagement, attributes].join("/"),
    new: {
      name: "new Attributes",
      path: [attributes, newAttribute].join("/"),
      fullPath: [productsManagement, attributes, newAttribute].join("/"),
    },
    edit: {
      name: "Update Attribute",
      path: [attributes, updateAttribute].join("/"),
      fullPath: [productsManagement, attributes, updateAttribute].join("/"),
    },
  },
  productType: {
    name: "Product types",
    path: productType,
    fullPath: [productsManagement, productType].join("/"),

    new: {
      name: "new ProductType",
      path: [productType, newProductType].join("/"),
      fullPath: [productsManagement, productType, newProductType].join("/"),
    },
    edit: {
      name: "Update ProductType",
      path: [productType, editProductType].join("/"),
      fullPath: [productsManagement, productType, editProductType].join("/"),
    },
  },
  categories: {
    name: "Categories",
    path: categories,
    fullPath: [productsManagement, categories].join("/"),
    new: {
      fullPath: [productsManagement, categories, newPath].join("/"),
      name: "New",
      path: [categories, newPath].join("/"),
    },
    edit: {
      fullPath: [productsManagement, categories, editPath].join("/"),
      name: "Edit",
      path: [categories, editPath].join("/"),
    },
    subCategory: {
      fullPath: [productsManagement, categories, subCat].join("/"),
      name: "Sub Categories",
      path: [categories, subCat].join("/"),
    },
  },
  productBrand: {
    name: "Product Brand",
    path: productBrand,
    fullPath: [productsManagement, productBrand].join("/"),
    new: {
      name: "new ",
      path: [productBrand, newProductBrand].join("/"),
      fullPath: [productsManagement, productBrand, newProductBrand].join("/"),
    },
    edit: {
      name: "Update",
      path: [productBrand, editProductBrand].join("/"),
      fullPath: [productsManagement, productBrand, editProductBrand].join("/"),
    },
  },
};
