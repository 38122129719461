import { PatientProfileTabContainer } from "../PatientProfileTabContainer";
import { HealthConditions } from "./HealthConditions";

export const Readings = () => {
  return (
    <PatientProfileTabContainer
      isContainer
      isRoot
      sections={[
        {
          content: <HealthConditions />,
        },
      ]}
    />
  );
};
