import { useChatFilter } from "@meetora/react/sehacity/Chat";
import ConversationList from "@meetora/react/ui/ConversationList";
import { TPeer } from "@meetora/schema/Peer";
import { useTranslation } from "@toolkit/i18n";
import { useEffect, useMemo } from "react";

export type ConversationsListProps = {
  filter: "chats" | "channels" | "visits";
  onSelectPeer: (peer: TPeer | undefined) => void;
};

export const ConversationsList = ({ filter, onSelectPeer }: ConversationsListProps) => {
  const { t } = useTranslation("provider");
  const { onChange, filters } = useChatFilter();

  useEffect(() => {
    onChange(filter);
  }, [filter, onChange]);

  const noResultMessage = useMemo(() => {
    if (filter === "channels") {
      return t("No channels found.");
    }

    return t("No conversations found");
  }, [t, filter]);

  return <ConversationList filters={filters} onSelect={onSelectPeer} noResultMessage={noResultMessage} />;
};
