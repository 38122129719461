export const convertAgentFormValuesToBackEndValues = (values) => {
    return {
        name: values.name || "",
        description: values.description || "",
        conditionsAndRules: values.conditionsAndRules || "",
    };
};
export const convertAgentToFormValues = (agent) => {
    return {
        name: (agent === null || agent === void 0 ? void 0 : agent.name) || "",
        description: (agent === null || agent === void 0 ? void 0 : agent.description) || "",
        conditionsAndRules: (agent === null || agent === void 0 ? void 0 : agent.conditionsAndRules) || "",
        code: agent === null || agent === void 0 ? void 0 : agent.code,
    };
};
export function convertBytes(bytes) {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    if (mb >= 1) {
        return `${mb.toFixed(2)} MB`;
    }
    else {
        return `${kb.toFixed(2)} KB`;
    }
}
