import { useAutocompleteCustomHook } from "@toolkit/ui";
import { useBranchesQuery } from "gql/queries";

const withNurseWorkDetailsData = Element => {
  return props => {
    const {
      data: branchesData,
      onFetchMoreData: handleFetchMoreBranches,
      refetch,
    } = useAutocompleteCustomHook({
      useAutocompleteControllerQuery: useBranchesQuery,
      input: {
        first: 10,
        filter: {
          isActive: true,
        },
      },
      dataAccessor: "branches",
    });

    const branches = branchesData?.branches?.edges?.map(item => item.node);
    const pageInfoBranches = branchesData?.branches?.pageInfo;

    return (
      <Element
        {...props}
        branches={branches}
        pageInfoBranches={pageInfoBranches}
        refetch={refetch}
        onFetchMoreBranches={handleFetchMoreBranches}
      />
    );
  };
};

export default withNurseWorkDetailsData;
