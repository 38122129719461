import { EChart, EChartOption, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";

type IPatientHealthParameterChartLineZoomData = {
  data: number[];
  color?: string;
};

type PatientHealthParameterChartLineZoomProps = {
  dataSets: IPatientHealthParameterChartLineZoomData[];
  isLoading?: boolean;
};

export const PatientHealthParameterChartLineZoom: FC<PatientHealthParameterChartLineZoomProps> = props => {
  const { dataSets, isLoading } = props;

  const { t } = useTranslation("provider");

  const theme = useTheme();

  const defaultColors = [theme.palette.primary.main, theme.palette.secondary.main];

  const chartOption: EChartOption = {
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let tooltipText = "";
        params.forEach((param: any) => {
          tooltipText += `<strong>${t("Value")}</strong>: ${param?.value}
          `;
        });

        return tooltipText;
      },
    },
    grid: {
      show: true,
      containLabel: false,
      bottom: "20%",
      right: "4%",
    },
    xAxis: {
      type: "category",
      splitLine: {
        show: true,
      },
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
      },
      axisLabel: {
        show: false,
      },
    },
    series: dataSets?.map((dataSet, index) => ({
      name: `Line ${index + 1}`,
      type: "line",
      data: dataSet?.data,
      symbol: "none",
      lineStyle: {
        color: dataSet.color || defaultColors[index] || theme.palette.gray.main,
      },
      itemStyle: {
        borderWidth: 4,
        color: dataSet.color || defaultColors[index] || theme.palette.gray.main,
      },
    })),
    dataZoom: [
      {
        type: "slider",
        start: 70,
        end: 100,
        height: 35,
      },
      {
        type: "inside",
        start: 70,
        end: 100,
      },
    ],
  };

  return <EChart option={chartOption} isLoading={isLoading} height={300} />;
};
