import { combineErrors, formGirdBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, useAutocompleteCustomHook } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useDepartmentsQuery } from "../../gql";

export const Departments: FC<{ defaultValues; errors }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("provider");
  const {
    control,
    formState: { errors: reactFormError },
  } = useFormContext();
  const departmentsValues = defaultValues?.departments;
  const combinedErrors = combineErrors(reactFormError, errors);
  const {
    data: departmentsData,
    isLoading: isDepartmentLoading,
    onFetchMoreData,
    refetch: refetchDepartments,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useDepartmentsQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
    },
    dataAccessor: "vendorDepartments",
  });
  const handleDepartmentsSearch = (filter: string) => {
    refetchDepartments({
      filter: {
        name_Icontains: filter,
      },
    });
  };
  const departments = departmentsData?.vendorDepartments?.edges?.map(branch => branch?.node)!;
  const pageInfo = departmentsData?.vendorDepartments?.pageInfo;
  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        ControllerProps={{
          name: "departments",
          control: control,
          defaultValue: departmentsValues || [],
        }}
        multiple
        loading={isDepartmentLoading}
        hasMore={Boolean(pageInfo?.hasNextPage)}
        fetchMoreData={onFetchMoreData}
        TextFieldProps={{
          error: Boolean(combinedErrors?.departments?.type),
          helperText: t(combinedErrors?.departments?.type && t("Required")),
          label: t("Departments"),
        }}
        getOptionLabel={option => option?.name}
        options={departments || []}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        onSearchInput={handleDepartmentsSearch}
      />
    </Grid>
  );
};
