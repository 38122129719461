import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { LabTests } from "./LabTests";

export const LabTestsPage: FC<{ id?: string }> = ({ id }) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const visitId = params.get("visitId") || id;

  return <LabTests visitId={visitId!} token={token!} />;
};

LabTestsPage.defaultProps = {
  id: undefined,
};
