import { CreatePersonalizedAppointmentsInput, RequestStatus } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useParams } from "react-router-dom";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../../context/ProfileContext";
import { useAddPersonalizedPatientAppointmentMutation, useAddPersonalizedPatientAppointmentsMutation } from "../../../../gql";
import moment from "moment";

export const useAddPatientAppointmentHook = () => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const params = useParams() as { id: string };
  const { id: teamMember } = params;
  const { token, patient } = useProfileContext();

  const memberId = patient?.activeHealthProgramMembers?.find(member => member?.isDefault || !member?.isDefault)?.nationalId!;
  const handleOnCompleted = (requestStatus: RequestStatus) => {
    if (requestStatus === RequestStatus.Pending || requestStatus === RequestStatus.Approved) {
      succeeded(t("Appointment Create Success"));
    } else {
      failed(t("Appointment Create Failed"));
    }
  };

  const handleOnError = ({ graphQLErrors }) => {
    failed(t(formatGraphQLError(graphQLErrors)));
  };

  const [createAppointments, { loading: createAppointmentsLoading }] = useAddPersonalizedPatientAppointmentsMutation({
    onCompleted: data => {
      const errors = data?.addPersonalizedPatientAppointments?.appointmentErrors;
      if (!errors?.length) {
        const requestStatus = data?.addPersonalizedPatientAppointments?.requestStatus!;
        handleOnCompleted(requestStatus!);
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    context: getApolloContextFormToken(token),
  });

  const [createAppointment, { loading: createAppointmentLoading }] = useAddPersonalizedPatientAppointmentMutation({
    onCompleted: data => {
      const errors = data?.addPersonalizedPatientAppointment?.appointmentErrors;
      if (!errors?.length) {
        const requestStatus = data?.addPersonalizedPatientAppointment?.requestStatus!;
        handleOnCompleted(requestStatus);
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: handleOnError,
    context: getApolloContextFormToken(token),
  });

  const handleCreateAppointment = async (values: CreatePersonalizedAppointmentsInput) => {
    const { frequencyType, DurationBetweenAppointments, numberOfAppointments, ...input } = {
      ...values,
      memberId: memberId!,
      teamMember: teamMember!,
      appointmentServiceDetails: (values.appointmentServiceDetails || []).map(service => ({
        serviceCode: service?.serviceCode!,
        standard: service?.standard!,
      })),
      startTime: moment(values?.startTime)?.format(),
      type: values?.type?.["value"] || values?.type,
    };

    if (numberOfAppointments > 1) {
      const response = await createAppointments({
        variables: {
          input: {
            ...input,
            frequencyType,
            medicalFormId: input?.medicalFormId?.["id"]!,
            DurationBetweenAppointments,
            numberOfAppointments,
          },
        },
      });
      const requestStatus = response.data?.addPersonalizedPatientAppointments?.requestStatus;
      return requestStatus === RequestStatus.Pending || requestStatus === RequestStatus.Approved;
    } else {
      const appointment = {
        ...input,
        medicalFormId: input?.medicalFormId?.["id"]!,
      };
      const response = await createAppointment({ variables: { input: appointment } });
      const requestStatus = response.data?.addPersonalizedPatientAppointment?.requestStatus;

      return requestStatus === RequestStatus.Pending || requestStatus === RequestStatus.Approved;
    }
  };

  return {
    loading: createAppointmentLoading || createAppointmentsLoading,
    handleCreateAppointment,
  };
};
