import { makeStyles } from "@toolkit/ui";

export const useColumnHeaderStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginInline: 10,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: color,
  },
  count: {
    color: theme.palette.common.white,
    fontSize: theme.mixins.fonts.fontSize.sm,
    borderRadius: 100,
    marginInline: 10,
    paddingInline: 12,
    height: 22,
    display: "flex",
    justifyContent: "center",
    background: color,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
