const citiesManagement = "cities-management";
const newCity = "new";
const editCity = `edit/:id`;

export const citiesManagementRoutes = {
  path: citiesManagement,
  name: "Cities Management",
  fullPath: citiesManagement,
  cities: {
    name: "Cities",
    new: {
      Name: "new City",
      path: [citiesManagement, newCity].join("/"),
      fullPath: [citiesManagement, newCity].join("/"),
    },
    edit: {
      Name: "Edit City",
      path: [citiesManagement, editCity].join("/"),
      fullPath: [citiesManagement, editCity].join("/"),
    },
  },
};
