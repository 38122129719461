/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalServicesQueryVariables = Types.Exact<{
  vendorDepartmentId: Types.Scalars['ID'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MedicalServicesQuery = { __typename?: 'Query', vendorDepartment?: { __typename?: 'Department', medicalServices?: { __typename?: 'DepartmentMedicalServiceCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'DepartmentMedicalServiceCountableEdge', cursor: string, node: { __typename?: 'DepartmentMedicalService', id: string, code: string } }> } | null } | null };


export const MedicalServicesDocument = gql`
    query MedicalServices($vendorDepartmentId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
  vendorDepartment(id: $vendorDepartmentId) {
    medicalServices(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          code
        }
        cursor
      }
    }
  }
}
    `;

/**
 * __useMedicalServicesQuery__
 *
 * To run a query within a React component, call `useMedicalServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalServicesQuery({
 *   variables: {
 *      vendorDepartmentId: // value for 'vendorDepartmentId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useMedicalServicesQuery(baseOptions: Apollo.QueryHookOptions<MedicalServicesQuery, MedicalServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalServicesQuery, MedicalServicesQueryVariables>(MedicalServicesDocument, options);
      }
export function useMedicalServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalServicesQuery, MedicalServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalServicesQuery, MedicalServicesQueryVariables>(MedicalServicesDocument, options);
        }
export type MedicalServicesQueryHookResult = ReturnType<typeof useMedicalServicesQuery>;
export type MedicalServicesLazyQueryHookResult = ReturnType<typeof useMedicalServicesLazyQuery>;
export type MedicalServicesQueryResult = Apollo.QueryResult<MedicalServicesQuery, MedicalServicesQueryVariables>;