/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabRequestDeleteMutationVariables = Types.Exact<{
  labRequestDeleteId: Types.Scalars['ID'];
}>;


export type LabRequestDeleteMutation = { __typename?: 'Mutation', labRequestDelete?: { __typename?: 'LabCRUD', lab?: { __typename?: 'Lab', id: string } | null, labErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const LabRequestDeleteDocument = gql`
    mutation LabRequestDelete($labRequestDeleteId: ID!) {
  labRequestDelete(id: $labRequestDeleteId) {
    lab {
      id
    }
    labErrors {
      message
      field
      code
    }
  }
}
    `;
export type LabRequestDeleteMutationFn = Apollo.MutationFunction<LabRequestDeleteMutation, LabRequestDeleteMutationVariables>;

/**
 * __useLabRequestDeleteMutation__
 *
 * To run a mutation, you first call `useLabRequestDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLabRequestDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [labRequestDeleteMutation, { data, loading, error }] = useLabRequestDeleteMutation({
 *   variables: {
 *      labRequestDeleteId: // value for 'labRequestDeleteId'
 *   },
 * });
 */
export function useLabRequestDeleteMutation(baseOptions?: Apollo.MutationHookOptions<LabRequestDeleteMutation, LabRequestDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LabRequestDeleteMutation, LabRequestDeleteMutationVariables>(LabRequestDeleteDocument, options);
      }
export type LabRequestDeleteMutationHookResult = ReturnType<typeof useLabRequestDeleteMutation>;
export type LabRequestDeleteMutationResult = Apollo.MutationResult<LabRequestDeleteMutation>;
export type LabRequestDeleteMutationOptions = Apollo.BaseMutationOptions<LabRequestDeleteMutation, LabRequestDeleteMutationVariables>;