import { CustomTable, Grid, VideoPlayer } from "@toolkit/ui";
import { usePatientHealthParametersHistory } from "../../hooks";
import { usePatientHealthParameterHistoryVideoColumns } from "./usePatientHealthParameterHistoryVideoColumns";
import React, { FC } from "react";
import { detectFileType, FileType } from "@toolkit/core";
import { PatientHealthParameterPicture } from "../PatientHealthParameterPicture/PatientHealthParameterPicture";

type PatientHealthParameterHistoryVideoProps = {
  patientId: string;
  token: string;
  codes: string[];
};

export const PatientHealthParameterHistoryVideo: FC<PatientHealthParameterHistoryVideoProps> = props => {
  const { patientId, token, codes } = props;

  const { selectedItem, tableProps, handleSelectItem } = usePatientHealthParametersHistory({
    patientId,
    token,
    codes,
  });

  const itemFileType = detectFileType(selectedItem?.valueVideoFile!);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {selectedItem?.valueVideoFile && (
          <>
            {itemFileType === FileType.IMAGE && <PatientHealthParameterPicture src={selectedItem?.valueVideoFile} maxHeight={300} />}

            {itemFileType === FileType.VIDEO && <VideoPlayer src={selectedItem?.valueVideoFile} isAutoplayEnabled maxHeight={300} />}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable {...tableProps} columns={usePatientHealthParameterHistoryVideoColumns({ selectedItem, onChange: handleSelectItem })} />
      </Grid>
    </Grid>
  );
};
