/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.PayerFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PayersQuery = { __typename?: 'Query', payers?: { __typename?: 'PayerCountableConnection', edges: Array<{ __typename?: 'PayerCountableEdge', node: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const PayersDocument = gql`
    query Payers($first: Int, $filter: PayerFilterInput, $after: String) {
  payers(first: $first, filter: $filter, after: $after) {
    edges {
      node {
        id
        name
        nameAr
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __usePayersQuery__
 *
 * To run a query within a React component, call `usePayersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePayersQuery(baseOptions?: Apollo.QueryHookOptions<PayersQuery, PayersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayersQuery, PayersQueryVariables>(PayersDocument, options);
      }
export function usePayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayersQuery, PayersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayersQuery, PayersQueryVariables>(PayersDocument, options);
        }
export type PayersQueryHookResult = ReturnType<typeof usePayersQuery>;
export type PayersLazyQueryHookResult = ReturnType<typeof usePayersLazyQuery>;
export type PayersQueryResult = Apollo.QueryResult<PayersQuery, PayersQueryVariables>;