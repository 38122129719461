import { groupBy, isEqual } from "lodash";
import { useEffect } from "react";
import { NurseCurrentWorkSpaceDetailsVar } from "shared/components/NurseCurrentWorkDetails";
import { DoctorNurseAssignmentsQuery, useDoctorNurseAssignmentsQuery } from "shared/components/NurseCurrentWorkDetails/gql/queries";
import {
  CurrentNurseWorkSpaceDetail,
  removeNurseWorkSpaceDetailsFromLocalStorage,
  setNurseWorkSpaceDetailsToLocalStorage,
} from "shared/components/NurseCurrentWorkDetails/utils";

type NurseAssignmentEdges = NonNullable<NonNullable<DoctorNurseAssignmentsQuery["doctorNurseAssignments"]>["edges"]>;
type NurseAssignmentEdge = NonNullable<NurseAssignmentEdges[0]>;

export const useSetNurseAssignment = () => {
  const currentNurseWorkDoctors = JSON.parse(String(CurrentNurseWorkSpaceDetail))?.doctors;
  const { data, loading } = useDoctorNurseAssignmentsQuery({
    skip: !currentNurseWorkDoctors?.length,
  });

  useEffect(() => {
    if (!currentNurseWorkDoctors?.length) return;
    if (!loading) {
      const nurseAssignment = data?.doctorNurseAssignments?.edges as NurseAssignmentEdges;
      const isNurseAssignedToDoctor = !!nurseAssignment?.length;
      if (!isNurseAssignedToDoctor) {
        clearNurseWorkDetails();
      } else {
        const groupByDepartment = groupBy(nurseAssignment, (item: NurseAssignmentEdge) => item?.node?.department?.id);
        const nurseAssignmentsForOneDepartment = groupByDepartment[Object.keys(groupByDepartment)?.[0]];
        const department = nurseAssignmentsForOneDepartment?.[0]?.node?.department;
        const branch = department?.branch;
        const doctors = nurseAssignmentsForOneDepartment?.map(item => {
          return {
            id: item?.node?.doctor?.id || "",
            name: item?.node?.doctor?.user?.fullName || "",
          };
        });
        const inputData = {
          branch: branch && {
            id: branch?.id,
            name: branch?.name,
          },
          department: department && {
            id: department?.id,
            name: department?.name,
          },
          doctors: doctors || [],
        };
        if (isEqual(doctors, currentNurseWorkDoctors)) return;
        NurseCurrentWorkSpaceDetailsVar(inputData);
        setNurseWorkSpaceDetailsToLocalStorage(JSON.stringify(inputData));
      }
    }
  }, [data]);
};

const clearNurseWorkDetails = () => {
  removeNurseWorkSpaceDetailsFromLocalStorage();
  NurseCurrentWorkSpaceDetailsVar(undefined);
};
