import { ShowButton } from "@toolkit/ui";
import { healthProviderBranchesPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export const UsersList: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    const route = healthProviderBranchesPaths.branchUsers.fullPathWithParams({ branchId: id });
    navigate(route);
  };

  return <ShowButton onClick={handleClick} />;
};
