import { VisitStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { ViewVisit } from "./ViewVisit";
import { VisitLayout } from "./VisitLayout";
import { useVisitNotificationHook } from "./useVisitNotification";

export const VisitNotification: FC<{
  id: string;
  status: VisitStatus;
  isDisplayedInDropDown: boolean;
}> = ({ id, status, isDisplayedInDropDown }) => {
  const { t } = useTranslation("provider");
  const { loading, visit } = useVisitNotificationHook(id, isDisplayedInDropDown);

  const doctorFullName = visit?.doctor?.user?.fullName;
  const patientFullName = visit?.patient?.user?.fullName;
  const cancellationReason = visit?.cancellationReason;
  return (
    <>
      {loading ? (
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
          <CircularProgress size={12} sx={{ mx: 1, my: 1 }} />
          {t("Loading")}...
        </Box>
      ) : (
        <Box>
          <VisitLayout status={status!}>
            <ViewVisit
              status={status}
              cancellationReason={cancellationReason}
              doctorFullName={doctorFullName}
              patientFullName={patientFullName}
            />
          </VisitLayout>
        </Box>
      )}
    </>
  );
};
