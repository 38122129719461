import { Box, Typography } from "@toolkit/ui";
import { BorderLinearProgress } from "./DoctorRatingCard.styles";
import { FC } from "react";
import { LinearProgressWrapperProps } from "./types";

export const LinearProgressWrapper: FC<LinearProgressWrapperProps> = props => {
  const { totalRatings, rateCount, rateValue, ...rest } = props;
  const progress = Math.round((rateCount / (totalRatings || 1)) * 100);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} marginTop={"8px"}>
      <Box sx={{ marginRight: 1 }}>
        <Typography variant='body2' color='primary'>
          {rateValue}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", mr: 0 }}>
        <BorderLinearProgress variant='determinate' {...rest} value={progress} />
      </Box>
      <Box sx={{ marginLeft: 1 }}>
        <Typography variant='body2' color='primary'>
          {Math.round(rateCount)}
        </Typography>
      </Box>
    </Box>
  );
};
