/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductCloneMutationVariables = Types.Exact<{
  marketplaceProductCloneId: Types.Scalars['ID'];
}>;


export type MarketplaceProductCloneMutation = { __typename?: 'Mutation', marketplaceProductClone?: { __typename?: 'MarketplaceProduct', id: string } | null };


export const MarketplaceProductCloneDocument = gql`
    mutation MarketplaceProductClone($marketplaceProductCloneId: ID!) {
  marketplaceProductClone(id: $marketplaceProductCloneId) {
    id
  }
}
    `;
export type MarketplaceProductCloneMutationFn = Apollo.MutationFunction<MarketplaceProductCloneMutation, MarketplaceProductCloneMutationVariables>;

/**
 * __useMarketplaceProductCloneMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductCloneMutation, { data, loading, error }] = useMarketplaceProductCloneMutation({
 *   variables: {
 *      marketplaceProductCloneId: // value for 'marketplaceProductCloneId'
 *   },
 * });
 */
export function useMarketplaceProductCloneMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductCloneMutation, MarketplaceProductCloneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductCloneMutation, MarketplaceProductCloneMutationVariables>(MarketplaceProductCloneDocument, options);
      }
export type MarketplaceProductCloneMutationHookResult = ReturnType<typeof useMarketplaceProductCloneMutation>;
export type MarketplaceProductCloneMutationResult = Apollo.MutationResult<MarketplaceProductCloneMutation>;
export type MarketplaceProductCloneMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductCloneMutation, MarketplaceProductCloneMutationVariables>;