import { NetworkStatus } from "@apollo/client";
import { Doctor } from "@health/queries/types";
import { i18n, useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useStyles } from "./DoctorsList.styles";
import { FetchDoctorsQuery, useFetchDoctorsQuery, useGetVendorUserProfilesLazyQuery } from "pages/dashboard/gql/queries";

export const useDoctorsList = () => {
  const { t } = useTranslation("provider");
  const { classes } = useStyles();
  const [selectedDoctorProfileId, setSelectedDoctorProfileId] = useState<string>();

  const { setBreadCrumb } = useBreadCrumbs();
  const currentDir = i18n.dir(i18n.language);

  const [getVendorUserProfiles, { data: selectedDoctorProfile, loading: isLoadingUserProfile }] = useGetVendorUserProfilesLazyQuery({
    fetchPolicy: "no-cache",
  });

  const { data, networkStatus, fetchMore, refetch } = useFetchDoctorsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 6,
    },
  });

  const doctors = data?.doctors?.edges.map(item => item.node) ?? [];

  const pageInfo = data?.doctors?.pageInfo;

  const updateQuery = (prev: FetchDoctorsQuery, { fetchMoreResult }: any) => {
    if (!fetchMoreResult) return prev;

    const newEdges = [...(prev?.doctors?.edges || []), ...(fetchMoreResult?.doctors?.edges || [])];

    return {
      ...fetchMoreResult,
      doctors: {
        ...fetchMoreResult.doctors,
        edges: newEdges,
      },
    };
  };

  const fetchMoreData = () => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        first: 6,
        after: pageInfo.endCursor,
      },
      updateQuery,
    });
  };

  const handleFilter = (name: string) => {
    // TODO: change it to name_Icontains
    refetch({ filter: { name } });
  };

  const handleDoctorSelected = (doctor: Doctor) => {
    setSelectedDoctorProfileId(doctor?.user?.id!);
    getVendorUserProfiles({ variables: { first: 1, filter: { ids: [doctor?.user?.id!] } } });
  };

  const handleCloseDoctorProfile = () => {
    setSelectedDoctorProfileId(undefined);
  };

  useEffect(() => {
    setBreadCrumb({
      title: t("Doctors Calls Ratings"),
      values: [],
    });
  }, []);

  return {
    isLoading: NetworkStatus.loading === networkStatus,
    currentDir,
    pageInfo,
    doctors,
    classes,
    selectedDoctorProfile: selectedDoctorProfile?.users?.edges?.[0]?.node,
    isDoctorProfileOpen: !!selectedDoctorProfileId,
    isLoadingUserProfile,
    handleFilter,
    fetchMoreData,
    handleDoctorSelected,
    handleCloseDoctorProfile,
    t,
  };
};
