import { Appointment } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, LifeCycleIcon, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { PatientProfile } from "shared/modules/patient";

type OngoingVisitPatientProfileDrawerProps = {
  isDoctor: boolean;
  appointment: Appointment;
};

export const OngoingVisitPatientProfileDrawer: FC<OngoingVisitPatientProfileDrawerProps> = props => {
  const { isDoctor, appointment } = props;

  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <>
      <PatientProfile
        patientId={appointment?.visit?.patient?.id}
        patientName={{
          firstName: appointment?.visit?.patient?.firstName,
          lastName: appointment?.visit?.patient?.lastName,
        }}
        renderModalButton={props => (
          <Button
            variant='outlined'
            disabled={!isDoctor}
            startIcon={<LifeCycleIcon />}
            sx={{
              "&:hover path": {
                fill: theme.palette.common.white,
              },
            }}
            {...props}
          >
            {t("Patient Profile")}
          </Button>
        )}
      />
    </>
  );
};
