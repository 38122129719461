import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { SettingsHeaderTab } from "./components/SettingsHeader/types";

export const useSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState<SettingsHeaderTab>("General");
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const handleChangeTab = (tab: SettingsHeaderTab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    setBreadCrumb({ title: t("Settings"), values: [] });
  }, []);

  return {
    selectedTab,
    handleChangeTab,
  };
};
