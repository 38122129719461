import { DoctorProfileInput } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { WorkingHours } from "shared/components";
import { useProfileFormEditHook } from "./ProfileFormEditPage.hook";
import { AboutUserInformation } from "./components/form/AboutUserInformation.component";
import { PhotoUploader } from "../../shared/components/PhotoUploader";
import { UserProfileInformation } from "./components/form/UserProfileInformation.component";
import { UserQualificationInformation } from "./components/form/UserQualificationInformation.component";
import { profileBreadCrumb, profileEditBreadCrumb } from "./constant";

export const ProfileFormEditPage: FC = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const { user, loading, errors, onSubmit, handleCancel } = useProfileFormEditHook();

  const methods = useForm<DoctorProfileInput>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const doYouHaveData = Boolean(user);

  useEffect(() => {
    setBreadCrumb({
      title: "Doctor Profile",
      values: [profileBreadCrumb(t), profileEditBreadCrumb(t)],
    });
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={<FormActions isLoading={loading} hasCancel hasFormButton formButtonTitle={t("Save")} onNavigation={handleCancel} />}
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("About")} doYouHaveData={doYouHaveData} loading={loading}>
                <AboutUserInformation defaultValues={user} errors={errors} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Upload photo")} doYouHaveData={doYouHaveData} loading={loading}>
                <PhotoUploader defaultValues={user} errors={errors} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} doYouHaveData={doYouHaveData} loading={loading}>
                <UserProfileInformation defaultValues={user} errors={errors} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Qualification Information")} doYouHaveData={doYouHaveData} loading={loading}>
                <UserQualificationInformation defaultValues={user} errors={errors} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <WorkingHours
                isLoading={loading}
                inputName={"workingHours"}
                doYouHaveData={doYouHaveData}
                workingHoursDefaultValue={user?.doctor?.workingHours}
              />
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
