import { Maybe, OrderPaymentMethod, OrderStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import { OrderCircularProgress } from "../components/OrderCircularProgress";
import { useHeaderCardStyles } from "./HeaderCard.styles";

type HeaderCardProps = PropsWithChildren<{
  title: string;
  color: string;
  subTitle?: string;
  buttonTitle?: string;
  onClick?: () => void;
  deliveryStatus?: Maybe<string> | undefined;
  isOrderLoading?: boolean;
  paymentMethod?: Maybe<OrderPaymentMethod> | undefined;
  status?: Maybe<OrderStatus> | undefined;
  total?: string | undefined;
}>;

export const HeaderCard: FC<HeaderCardProps> = props => {
  const {
    title,
    subTitle,
    color,
    buttonTitle,
    onClick: handleClick,
    deliveryStatus,
    isOrderLoading,
    paymentMethod,
    status,
    total,
    children,
  } = props;

  const { classes, theme } = useHeaderCardStyles({ color });
  const { t } = useTranslation("provider");
  const paymentMethodText = OrderPaymentMethod.CreditWhenArrive === paymentMethod ? "Credit When Arrive" : "";
  return (
    <div className={classes.root}>
      <div>
        <Box display='flex'>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium} className={classes.text}>
            {title}
          </Typography>
          {isOrderLoading && <OrderCircularProgress />}
        </Box>
        {subTitle && (
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} className={classes.text}>
            {subTitle}
          </Typography>
        )}
        {status === OrderStatus.PaymentOnArrive && (
          <>
            {paymentMethod && (
              <Typography fontSize={theme.mixins.fonts.fontSize.xs} className={classes.text}>
                <strong>{t("Payment Method")}</strong> :{" "}
                <Box component='span' className={classes.total}>
                  {paymentMethodText}
                </Box>
              </Typography>
            )}
            {total && (
              <Typography fontSize={theme.mixins.fonts.fontSize.xs} className={classes.text}>
                <strong>{t("Required Amount")}</strong> :{" "}
                <Box component='span' className={classes.total}>
                  {total}
                </Box>
              </Typography>
            )}
          </>
        )}
        {deliveryStatus && (
          <>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} className={classes.text}>
              {t("Order delivery status")}:{" "}
            </Typography>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} className={classes.text}>
              {deliveryStatus}
            </Typography>
          </>
        )}
      </div>
      <>{children}</>
      {buttonTitle && (
        <Button className={classes.button} onClick={handleClick}>
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};

HeaderCard.defaultProps = {
  buttonTitle: "",
  subTitle: "",
  deliveryStatus: undefined,
  onClick: () => {},
  isOrderLoading: false,
};
