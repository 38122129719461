import { VitalSignUpdateContainer } from "pages/e-visit/VitalSigns/containers/VitalSignUpdate/VitalSignUpdateContainer";
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";

type VitalSignsProps = {
  id?: string;
};

export const VitalSigns: FC<VitalSignsProps> = props => {
  const { id } = props;

  const [params] = useSearchParams();
  const token = params.get("token");
  const visitId = params.get("visitId") || id;

  return <VitalSignUpdateContainer visitId={visitId!} token={token!} />;
};
