/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignUserADefaultBranchMutationVariables = Types.Exact<{
  branchId: Types.Scalars['ID'];
}>;


export type AssignUserADefaultBranchMutation = { __typename?: 'Mutation', assignDefaultBranch?: { __typename?: 'AssignDefaultBranch', branch?: { __typename?: 'Branch', id: string } | null } | null };


export const AssignUserADefaultBranchDocument = gql`
    mutation assignUserADefaultBranch($branchId: ID!) {
  assignDefaultBranch(branchId: $branchId) {
    branch {
      id
    }
  }
}
    `;
export type AssignUserADefaultBranchMutationFn = Apollo.MutationFunction<AssignUserADefaultBranchMutation, AssignUserADefaultBranchMutationVariables>;

/**
 * __useAssignUserADefaultBranchMutation__
 *
 * To run a mutation, you first call `useAssignUserADefaultBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserADefaultBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserADefaultBranchMutation, { data, loading, error }] = useAssignUserADefaultBranchMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useAssignUserADefaultBranchMutation(baseOptions?: Apollo.MutationHookOptions<AssignUserADefaultBranchMutation, AssignUserADefaultBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignUserADefaultBranchMutation, AssignUserADefaultBranchMutationVariables>(AssignUserADefaultBranchDocument, options);
      }
export type AssignUserADefaultBranchMutationHookResult = ReturnType<typeof useAssignUserADefaultBranchMutation>;
export type AssignUserADefaultBranchMutationResult = Apollo.MutationResult<AssignUserADefaultBranchMutation>;
export type AssignUserADefaultBranchMutationOptions = Apollo.BaseMutationOptions<AssignUserADefaultBranchMutation, AssignUserADefaultBranchMutationVariables>;