import { CreateVisitPrescriptionDiagnosisInput, CreateVisitPrescriptionInput, VisitPrescriptionActivityType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useFieldArray, useForm } from "react-hook-form";
import { getApolloContextFormToken } from "shared/utils";
import { useCreateVisitPrescriptionMutation, useVisitDiagnosesQuery } from "../gql";
import { frequencyTypeOptions } from "./constants";
import { ActivityFormData, PrescriptionFormProps } from "./types";
import { getInstructions } from "./utils";

export const usePrescriptionFormController = ({ visitId, token, onCreateSuccess }: PrescriptionFormProps) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const { data: visitData } = useVisitDiagnosesQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
    skip: !visitId,
  });
  const methods = useForm({
    mode: "onChange",
  });

  const { errors } = methods.formState;
  const {
    fields: activitiesFields,
    append: appendActivity,
    remove,
  } = useFieldArray({
    name: "activities",
    control: methods.control,
  });

  const [fetchCreate, { loading: isSubmittingCreate }] = useCreateVisitPrescriptionMutation({
    context: getApolloContextFormToken(token),
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const id = data?.createVisitPrescription?.id;
      if (id) {
        onCreateSuccess?.();
        handleResetForm();
        succeeded(t("Prescription created successfully"));
        methods.resetField("activities", { defaultValue: [] });
      } else {
        failed(t("Prescription creation failed"));
      }
    },
    onError: () => {
      failed(t("Prescription creation failed"));
    },
  });

  const handleResetForm = () => {
    methods.resetField("activities", { defaultValue: [] });
    methods.setValue("activities", []);
  };

  const handleAddActivity = (activity: ActivityFormData) => {
    appendActivity({ ...activity, type: VisitPrescriptionActivityType.TradeDrug });
  };

  const handleCreate = () => {
    const values = methods.getValues();
    const diagnosis: CreateVisitPrescriptionDiagnosisInput[] =
      visitData?.visit?.visitDiagnoses?.map(item => ({
        type: item?.visitDiagnosisType!, // map diagnosis types
        code: item?.code!,
      })) ?? [];

    const createPrescriptionInput: CreateVisitPrescriptionInput = {
      activities: values.activities.map(activity => ({
        code: activity.code?.value?.code,
        duration: +activity.duration,
        frequencyType: activity.frequencyType?.value,
        frequencyUnitType: activity.frequencyUnitType?.value?.code,
        frequencyValue: +activity.frequencyValue,
        instructions: getInstructions(activity, t),
        observation: activity.observation || "",
        quantity: +activity.quantity,
        refills: +activity.refills,
        type: VisitPrescriptionActivityType.TradeDrug,
      })),
      diagnoses: diagnosis,
      memberWeight: +values.memberWeight || 50,
      visitId: visitId,
    };

    if (visitId && values.activities?.length) {
      fetchCreate({
        variables: {
          createPrescriptionInput: createPrescriptionInput,
        },
      });
    }
  };

  const handleAddNewRow = () => {
    appendActivity({
      code: "",
      duration: "",
      frequencyType: frequencyTypeOptions[0],
      frequencyUnitType: null,
      frequencyValue: "",
      instructions: "",
      observation: "",
      quantity: "",
      refills: "",
      type: VisitPrescriptionActivityType.TradeDrug,
    });
  };

  const handleDeleteRow = (row: any, fieldIndex: number) => {
    if (fieldIndex >= 0) {
      remove(fieldIndex);
    }
  };
  return {
    hasNoDiagnostic: visitData?.visit?.visitDiagnoses?.length === 0,
    errors,
    methods,
    isSubmittingCreate,
    activitiesFields,
    handleAddActivity,
    handleAddNewRow,
    handleDeleteRow,
    handleResetForm,
    handleCreate,
  };
};
