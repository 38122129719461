import { useOpenState } from "@toolkit/core";
import { CustomDialog, CustomTable, ShowButton } from "@toolkit/ui";
import { FC, useMemo } from "react";
import { useGetOrderLinesQuery } from "../../gql";
import { CustomMarketplaceOrderLines } from "./types";
import { useOrderLinesColumns } from "./useOrderLinesColumns";

type ModalProps = {
  title: string;
  orderId: string;
};

export const ProductsModal: FC<ModalProps> = ({ title, orderId }) => {
  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  const { data, loading } = useGetOrderLinesQuery({
    variables: {
      orderId,
    },
    skip: !isOpen,
  });

  const orderItemsLines = useMemo(
    () =>
      data?.marketplaceOrder?.orderLines.map(line => {
        const item = line?.healthPackage || line?.product;
        return {
          ...line,
          marketPlaceItem: {
            id: item?.id,
            name: item?.name,
            nameAr: item?.nameAr,
            mainImageUrl: item?.mainImageUrl,
            shortDescription: item?.shortDescription,
            shortDescriptionAr: item?.shortDescriptionAr,
          },
        };
      }),
    [data]
  );

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      onBackdropClick={handleClose}
      DialogTitleProps={{
        title: title,
        onClose: handleClose,
      }}
      title={title}
      button={<ShowButton disabled={loading} onClick={handleOpen} />}
    >
      <CustomTable
        data={(orderItemsLines as CustomMarketplaceOrderLines[]) || []}
        columns={useOrderLinesColumns()}
        TableContainerProps={{
          sx: {
            height: 350,
          },
        }}
        hasFooter={false}
      />
    </CustomDialog>
  );
};
