import { Box, CircularProgress } from "@toolkit/ui";
import { PropsWithChildren } from "react";
import { Children, cloneElement, FC, isValidElement, ReactElement } from "react";

export const CircularIntegration: FC<PropsWithChildren<{ loading: boolean }>> = ({ children, loading }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child as ReactElement, { disabled: loading ? loading : child?.props?.disabled });
        }
      })}
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};
