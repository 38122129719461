import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import React, { FC, Suspense } from "react";
import { HealthParameterTable } from "./HealthParameterTable";

type TableProps = {
  code: string;
  isOpen: boolean;
  type: string;
  display: string;
  token: string;
  patientId: string;
  visitId?: string;
  children?: React.ReactNode;
  onClose: () => void;
  onSelectECG: (value: number[], source: string, date: string) => void;
};

export const HealthParameterDialog: FC<TableProps> = ({
  code,
  isOpen,
  type,
  display,
  visitId,
  token,
  patientId,
  onSelectECG: handleSelectECG,
  onClose: handleClose,
  children,
}) => {
  const { t } = useTranslation("provider");

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement<any>(child, { widthValue: "100%", width: "100%" });
    }
    return child;
  });

  return (
    <div>
      {isOpen && (
        <CustomDialog
          type='base'
          open={isOpen}
          maxWidth='md'
          DialogTitleProps={{ onClose: handleClose, title: display }}
          keepMounted={true}
          DialogActionsProps={{
            children: (
              <Button variant='outlined' onClick={handleClose}>
                {t("Close")}
              </Button>
            ),
          }}
          DialogContentProps={{ sx: { maxHeight: "100vh" } }}
        >
          <Suspense
            fallback={
              <Box display='flex' justifyContent='center' m={1}>
                <CircularProgress size={50} />
              </Box>
            }
          >
            <Box my={2}>{childrenWithProps}</Box>
            {!visitId && <HealthParameterTable token={token} patientId={patientId} code={code} type={type} onSelectECG={handleSelectECG} />}
          </Suspense>
        </CustomDialog>
      )}
    </div>
  );
};
