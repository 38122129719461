import { TFunction } from "@toolkit/i18n";
import { Button, RescheduleIcon } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./WaitingRoomsCard.styles";

export const RescheduleButton: FC<{ disabled?: boolean; t: TFunction }> = ({ disabled = false, t }) => {
  const { classes } = useStyles();
  return (
    <Button
      className={classes?.rescheduleButton}
      startIcon={<RescheduleIcon width={"16px"} height={"16px"} />}
      variant='text'
      disabled={disabled}
    >
      {t("Reschedule", { ns: "provider" })}
    </Button>
  );
};
