import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PasteSpecial: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20' height='27' viewBox='0 0 20 27' {...props}>
      <path
        id='icons8-paste-special'
        d='M15,0a3.015,3.015,0,0,0-3,3H8A3.015,3.015,0,0,0,5,6V24a3.015,3.015,0,0,0,3,3H22a3.015,3.015,0,0,0,3-3V6a3.015,3.015,0,0,0-3-3H18A3.015,3.015,0,0,0,15,0Zm0,2a1,1,0,1,1-1,1A.985.985,0,0,1,15,2ZM8,5h4V6a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V5h4a.984.984,0,0,1,1,1V24a.984.984,0,0,1-1,1H8a.984.984,0,0,1-1-1V6A.984.984,0,0,1,8,5Zm7,4.234a.5.5,0,0,0-.48.344l-1.158,3.428-3.617.043a.506.506,0,0,0-.3.912l2.9,2.16-1.078,3.453a.507.507,0,0,0,.777.564L15,18.047l2.951,2.092a.507.507,0,0,0,.777-.564L17.65,16.121l2.9-2.16a.506.506,0,0,0-.3-.912l-3.617-.043L15.48,9.578A.5.5,0,0,0,15,9.234Z'
        transform='translate(-5)'
        fill={palette.success.main}
      />
    </SvgIcon>
  );
};
export default PasteSpecial;
