import { Order } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useOrderPageContext } from "../state";
import { mapOrderToActionByStatus, mapOrderToColumnByStatus } from "./mapper";
import { useSiteSettings } from "shared/siteSettings.var";

export const useOrderMapper = () => {
  const { t } = useTranslation("provider");
  const { time } = useOrderPageContext();

  const { defaultCurrency } = useSiteSettings();

  const mapOrderToData = (order: Partial<Order>) => mapOrderToColumnByStatus(order, time, t, defaultCurrency!);
  const mapOrderToAction = (order: Partial<Order>) => mapOrderToActionByStatus(order, time, t, defaultCurrency!);

  return {
    mapOrderToData,
    mapOrderToAction,
  };
};
