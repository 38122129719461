import { Box } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import { OrdersCard } from "../cards/OrdersCard";
import { Loading } from "./Loading";

type OrdersLoadingProps = PropsWithChildren<{
  color: string;
  isLoading: boolean;
}>;

export const OrdersLoading: FC<OrdersLoadingProps> = props => {
  const { children, color, isLoading } = props;

  return (
    <>
      {isLoading ? (
        <Box margin='10px'>
          <OrdersCard color={color}>
            <Loading color={color} />
          </OrdersCard>
        </Box>
      ) : (
        children
      )}
    </>
  );
};
