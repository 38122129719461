/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabRequestCreateMutationVariables = Types.Exact<{
  input: Types.LabInput;
}>;


export type LabRequestCreateMutation = { __typename?: 'Mutation', labRequestCreate?: { __typename?: 'LabCRUD', lab?: { __typename?: 'Lab', id: string } | null, labErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const LabRequestCreateDocument = gql`
    mutation LabRequestCreate($input: LabInput!) {
  labRequestCreate(input: $input) {
    lab {
      id
    }
    labErrors {
      message
      field
      code
    }
  }
}
    `;
export type LabRequestCreateMutationFn = Apollo.MutationFunction<LabRequestCreateMutation, LabRequestCreateMutationVariables>;

/**
 * __useLabRequestCreateMutation__
 *
 * To run a mutation, you first call `useLabRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLabRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [labRequestCreateMutation, { data, loading, error }] = useLabRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLabRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<LabRequestCreateMutation, LabRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LabRequestCreateMutation, LabRequestCreateMutationVariables>(LabRequestCreateDocument, options);
      }
export type LabRequestCreateMutationHookResult = ReturnType<typeof useLabRequestCreateMutation>;
export type LabRequestCreateMutationResult = Apollo.MutationResult<LabRequestCreateMutation>;
export type LabRequestCreateMutationOptions = Apollo.BaseMutationOptions<LabRequestCreateMutation, LabRequestCreateMutationVariables>;