import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const UploadIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  const { width = "145.8px", height = "102px" } = props;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 145.804 102.063'>
      <path
        id='icons8-upload-to-cloud'
        d='M76.681,9.507A41.493,41.493,0,0,0,41.7,27.626,5.325,5.325,0,0,0,43,34.975a5.163,5.163,0,0,0,7.249-1.32,31.015,31.015,0,0,1,26.246-13.59c17.137.316,30.654,15.3,30.654,32.891v.536a5.244,5.244,0,0,0,5.207,5.279h5.208a20.9,20.9,0,0,1,20.822,21.769C138.043,92,128.05,101,116.468,101H82.845a1.67,1.67,0,0,1-1.736-1.76V73.275l4.516,4.578a5.156,5.156,0,0,0,7.364,0,5.331,5.331,0,0,0,0-7.466L80.031,57.253a5.165,5.165,0,0,0-8.285.028L58.815,70.388a5.33,5.33,0,0,0,0,7.466,5.158,5.158,0,0,0,7.365,0l4.515-4.579V99.245a12.317,12.317,0,0,0,12.15,12.318h33.623c16.933,0,31.806-13.276,32.321-30.705.527-17.854-13.718-32.644-31.23-32.644h-.909C114.323,27.279,98.02,9.9,76.681,9.507ZM35.335,48.214C18.4,48.214,3.529,61.49,3.014,78.919c-.526,17.854,13.718,32.644,31.23,32.644H55.588a5.28,5.28,0,0,0,0-10.559H34.244A20.9,20.9,0,0,1,13.422,79.235c.339-11.458,10.332-20.463,21.914-20.463h4.116a5.28,5.28,0,0,0,0-10.559Z'
        transform='translate(-3 -9.5)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
export default UploadIcon;
