import { guidedCareTaskStatusOptionsMap } from "@health/enum-options";
import {
  AssigneeType,
  GuidedCareTask,
  GuidedCareTaskFilterInput,
  GuidedCareTaskUpdateInput,
  TaskDefinitionStatus,
} from "@health/queries/types";
import { createAutocompleteOption } from "@toolkit/ui";
import { IGuidedCareTaskUpdateFormValues } from "pages/GuidedCareTasks/forms/GuidedCareTaskUpdateForm/GuidedCareTaskUpdateFormSchema";
import { IGuidedCareTaskFilterFormValues } from "../forms/GuidedCareTaskFilter/GuidedCareTaskFilterFormSchema";

export const isGuidedCareTaskActionable = (status: TaskDefinitionStatus) => {
  return status === TaskDefinitionStatus.Pending || status === TaskDefinitionStatus.InProgress || status === TaskDefinitionStatus.Missed;
};

export const convertGuidedCareTaskToFormValues = (task: GuidedCareTask): IGuidedCareTaskUpdateFormValues => {
  return {
    status: guidedCareTaskStatusOptionsMap[task?.status!],
    assignees:
      task?.assignee?.map(item =>
        createAutocompleteOption(
          {
            id: item?.teamMember?.id!,
            userId: item?.teamMember?.user?.id!,
            name: item?.teamMember?.user?.fullName!,
          },
          "id",
          "name"
        )
      ) || [],
  };
};

export const convertGuidedCareTaskFormValuesToBackEndValues = (
  values: IGuidedCareTaskUpdateFormValues,
  guidedCareTask: GuidedCareTask
): GuidedCareTaskUpdateInput => {
  return {
    status: values?.status?.value,
    assignee: values?.assignees?.map(item => {
      const updatedItem = guidedCareTask?.assignee?.find(assignee => assignee?.teamMember?.id === item?.value?.id);

      return {
        id: updatedItem ? updatedItem?.id : undefined,
        assigneeUserId: item?.value?.userId!,
        assigneeType: AssigneeType.TeamMember,
        teamMember: { id: item?.value?.id! },
      };
    }),
  };
};

export const convertGuidedCareTaskFilterFormValuesToBackEndValues = (
  values: IGuidedCareTaskFilterFormValues,
  userId?: string,
  patientId?: string
): GuidedCareTaskFilterInput => {
  return {
    /* eslint-disable no-nested-ternary */
    assigneeUserIds: values?.assignees?.length ? values?.assignees?.map(item => item?.value?.id) : userId ? [userId] : [],
    statuses: values?.statuses?.map(item => item?.value) || [],
    patientUserId: patientId ? [patientId] : values?.patients?.map(item => item?.value?.id) || [],
  };
};
