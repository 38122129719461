import { DoctorAvailabilityStatus, DoctorAvailabilityStatusEnum } from "@health/queries/types";
import { TFunction, useTranslation } from "@toolkit/i18n";
import { CustomIcon } from "@toolkit/ui";
import { useMemo } from "react";
import { DoctorAvailabilityStatusType } from "./DoctorAvailabilityStatus.type";
import { useStyles } from "./doctorAvailabilityStatus.styles";

const AvailabilityStatusValueListData = (classes, t: TFunction<"translation", undefined>) =>
  useMemo(
    () =>
      [
        {
          id: "available",
          availabilityStatus: DoctorAvailabilityStatusEnum?.AvailableLevel_1,
          period: 6,
          label: t("Available", { ns: "provider" }),
          className: classes.menuItemButtonAvailable,
          icon: <CustomIcon icon='correct' viewBox='0 0 17 17' color='inherit' />,
        },
        {
          id: "unavailable",
          period: 0,
          availabilityStatus: DoctorAvailabilityStatusEnum?.Unavailable,
          label: t("Unavailable", { ns: "provider" }),
          className: classes.menuItemButtonUnavailable,
          icon: <CustomIcon icon='clock' viewBox='0 0 19 19' color='inherit' />,
        },
      ] as unknown as DoctorAvailabilityStatusType[],
    []
  );
export const useDoctorAvailabilityStatusDataHook = () => {
  const { t } = useTranslation("provider");
  const { classes } = useStyles();

  const AvailabilityStatusValueList = AvailabilityStatusValueListData(classes, t);
  const getCurrentDoctorAvailabilityStatus = (availabilityStatus: DoctorAvailabilityStatusEnum | DoctorAvailabilityStatus) => {
    return AvailabilityStatusValueList.find(item => {
      const Available = DoctorAvailabilityStatusEnum?.AvailableLevel_1;
      const Unavailable = DoctorAvailabilityStatusEnum?.Unavailable;
      if (availabilityStatus) {
        return (
          (availabilityStatus == Available && item.availabilityStatus === Available) ||
          (availabilityStatus === Unavailable && item.availabilityStatus === Unavailable)
        );
      } else {
        return item.availabilityStatus === Unavailable;
      }
    });
  };
  return {
    defaultStatus: useMemo(() => AvailabilityStatusValueList.find(item => item.id === "unavailable"), []),
    getCurrentDoctorAvailabilityStatus,
    AvailabilityStatusValueList,
  };
};
