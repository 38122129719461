import { zodSchema } from "@health/domains";
import { i18n } from "@toolkit/i18n";
import moment from "moment";
import { z } from "zod";
import { providerTranslationNamespace } from "utils";

export const PromotionUpsertFormSchema = z
  .object({
    name: z.string().min(3).max(255),
    description: z.string().min(3).nullable().optional(),
    photo: z.string().nullable().optional(),
    endDate: z.string(),
    startDate: z.string(),
    healthPackageCategory: z.array(zodSchema.MarketplaceHealthPackageCategoryAutocompleteOptional),
    healthPackage: z.array(zodSchema.healthPackageAutocompleteOptional),
    productCategory: z.array(zodSchema.MarketplaceProductCategoryAutocompleteOptional),
    product: z.array(zodSchema.MarketplaceProductAutocompleteOptional),
  })
  .superRefine((data, ctx) => {
    const theresNoError =
      data?.productCategory?.length || data?.product?.length || data?.healthPackage?.length || data?.healthPackageCategory?.length;
    const targetFields = ["productCategory", "product", "healthPackage", "healthPackageCategory"];
    if (!theresNoError) {
      targetFields.forEach(field => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t(
            "Please select at least one product, product category, health package or health package category",
            providerTranslationNamespace
          ),
          path: [field],
        });
      });
    }

    if (data?.startDate && data?.endDate) {
      const startDate = moment(data.startDate);
      const endDate = moment(data.endDate);
      if (startDate.isAfter(endDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("End date must be after start date", providerTranslationNamespace),
          path: ["endDate"],
        });
      } else if (startDate.isBefore(moment())) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Start date must be after today", providerTranslationNamespace),
          path: ["startDate"],
        });
      }
    }
  });

export type IPromotionUpsertFormValues = z.infer<typeof PromotionUpsertFormSchema>;

export const promotionUpsertFormSchemaDefaultValues: Partial<IPromotionUpsertFormValues> = {
  name: undefined,
  description: undefined,
  endDate: undefined,
  startDate: undefined,
  healthPackageCategory: undefined,
  healthPackage: undefined,
  productCategory: undefined,
  product: undefined,
  photo: undefined,
};
