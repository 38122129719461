import React, { FC } from "react";
import { Grid } from "@toolkit/ui";
import { PatientHealthParameterNumeric } from "../PatientHealthParameterNumeric/PatientHealthParameterNumeric";
import { healthParameterCodes } from "../../constants";
import { PatientHealthParameterNumber } from "../PatientHealthParameterNumber/PatientHealthParameterNumber";
import { PatientHealthParameterAudio } from "../PatientHealthParameterAudio/PatientHealthParameterAudio";
import { PatientHealthParameterVideo } from "../PatientHealthParameterVideo/PatientHealthParameterVideo";
import { PatientHealthParametersVitalSigns } from "../PatientHealthParametersVitalSigns/PatientHealthParametersVitalSigns";

type PatientHealthParametersPatientProps = {
  patientId: string;
  token: string;
};

export const PatientHealthParametersPatient: FC<PatientHealthParametersPatientProps> = props => {
  const { patientId, token } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PatientHealthParametersVitalSigns patientId={patientId} token={token} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterNumeric patientId={patientId} token={token} code={healthParameterCodes.ecg} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterNumber patientId={patientId} token={token} code={healthParameterCodes.bloodGlucose} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterAudio patientId={patientId} token={token} code={healthParameterCodes.stethoscopeHeartSound} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterAudio patientId={patientId} token={token} code={healthParameterCodes.stethoscopeLungSound} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterVideo patientId={patientId} token={token} code={healthParameterCodes.otoscopePicture} />
      </Grid>

      <Grid item xs={12}>
        <PatientHealthParameterVideo patientId={patientId} token={token} code={healthParameterCodes.otoscopeVideo} />
      </Grid>
    </Grid>
  );
};
