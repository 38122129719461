import { makeStyles } from "@toolkit/ui";

export const useAvailableTimeSlotsStyle = makeStyles()(() => ({
  root: {
    width: "100%",
  },
  timeSlotsWrapper: {
    marginTop: 10,
  },
  divider: {
    width: "100%",
  },
}));
