import { Box, GridContext, TableGrid } from "@toolkit/ui";
import { useGetVisitReferralsQuery } from "pages/e-visit/gql";
import { FC, useContext, useEffect } from "react";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useReferralsColumns } from "./ReferralsColumns.component";
import { getApolloContextFormToken } from "shared/utils";

export const ReferralsList: FC<{ created: boolean; visitId: string; token: string }> = ({ created, visitId, token }) => {
  return (
    <GridProvider
      hasTableSetting
      gridName='PatientReferral'
      columns={useReferralsColumns()}
      query={useGetVisitReferralsQuery}
      variables={{ id: visitId }}
      context={getApolloContextFormToken(token)}
    >
      <Referral created={created} />
    </GridProvider>
  );
};
const Referral: FC<{ created: boolean }> = ({ created }) => {
  const { customTableProps } = useContext(GridContext);
  useEffect(() => {
    created && customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
  }, [created]);

  return (
    <Box p={1}>
      <TableGrid />
    </Box>
  );
};
