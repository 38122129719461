import { TFunction } from "@toolkit/i18n";
import { Duration } from "moment";

export function formatDuration(duration: Duration, t: TFunction) {
  const minutes = duration.minutes();
  const seconds = (duration.seconds() > 9 ? "" : "0") + duration.seconds();
  const hours = duration.hours();
  if (hours) return t("format-hours-minutes", { minutes, hours, ns: "provider" });
  if (minutes) return t("format-minutes-seconds", { minutes, seconds, ns: "provider" });
  return t("format-seconds", { seconds, ns: "provider" });
}
