import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { GeneralInformation } from "./components/GeneralInformation";
import { FormValues, useProfessionalsEditHook } from "./useProfessionalsEditHook";
import { PhotoUploader } from "shared/components/PhotoUploader";

export const ProfessionalsFormEditPage = () => {
  const { t } = useTranslation("provider");
  const { doctor, loading, errors, loadingUpdate, onSubmit, handleCancel } = useProfessionalsEditHook();

  const methods = useForm<FormValues>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasEdit
              hasCancel
              loadingIndicators={{
                edit: loadingUpdate,
              }}
              isLoading={loading || loadingUpdate}
              onNavigation={handleCancel}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={loading} doYouHaveData={!!doctor}>
                <GeneralInformation errors={errors} defaultValue={doctor} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Upload photo")} doYouHaveData={!!doctor} loading={loading}>
                <PhotoUploader defaultValues={doctor} errors={errors} isRequired={false} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
