import { guidedCareActivityTypeNonMedicalMessageOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { GridProvider } from "shared/components";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { useUpcomingPatientCareActivitiesQuery } from "../../../gql";
import { useUpcomingPatientCareActivitiesColumns } from "./useUpcomingPatientCareActivitiesColumns";
import { Table } from "./Table";

export const UpcomingActivities = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { patientId, selectedHealthProgram, token } = useProfileContext();
  const twoWeeksFromNow = moment().add(2, "weeks").format("YYYY-MM-DD");

  return (
    <>
      <Typography fontSize={theme.mixins.fonts.fontSize.xxl} fontWeight={theme.mixins.fonts.fontWeight.medium} gutterBottom>
        {t("Upcoming Patient Care Activities")}
      </Typography>

      <GridProvider
        isCardView
        gridName='upcomingPatientCareActivities'
        query={useUpcomingPatientCareActivitiesQuery}
        columns={useUpcomingPatientCareActivitiesColumns({ upcoming: true })}
        context={getApolloContextFormToken(token!)}
        variables={{
          first: 10,
          filter: {
            patientId: patientId!,
            guidedCareHealthProgramId: selectedHealthProgram,
            types: guidedCareActivityTypeNonMedicalMessageOptions?.map(item => item?.value),
            dueDate: {
              lte: twoWeeksFromNow,
            },
          },
        }}
        tableAction={{
          withoutDataMessage: " ",
        }}
      >
        <Table />
      </GridProvider>
    </>
  );
};
