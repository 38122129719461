/* eslint-disable max-statements */
import { AppRoleTypes, Clinician } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useGetClinicianLazyQuery } from "pages/HealthProviderManagement/User-Management/gql";
import { healthProviderUserManagementPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const useUserManagementFormHook = ({ onDone }) => {
  const { t } = useTranslation("provider");
  const methods = useForm({
    mode: "onChange",
  });
  const { setValue } = methods;
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const [nationalId, setNationalId] = useState();
  const [getClinician, { loading: isUserByNatIdLoading }] = useGetClinicianLazyQuery({
    onCompleted: response => {
      if (response.getClinician) {
        handleNatIdDataResponse(response.getClinician as Clinician);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedErrors = formatGraphQLError(graphQLErrors);
      failed(t(formattedErrors));
    },
  });

  const handleNatIdDataResponse = natIdFullData => {
    if (!natIdFullData) return;
    setValue("firstName", natIdFullData?.firstName);
    setValue("lastName", natIdFullData?.lastName);
    setValue("email", natIdFullData?.email);
    setValue("mobile", isNaN(natIdFullData?.phone || "--") ? "" : natIdFullData?.phone);
    setValue("password", natIdFullData?.tempPassword);
    setValue("healthLicenseNumber", natIdFullData?.license);
    setValue("healthLicenseStartDate", natIdFullData?.licenseStart);
    setValue("healthLicenseEndDate", natIdFullData?.licenseEnd);
    setValue("nationalId", nationalId);
    setValue("appRole", { value: AppRoleTypes.User, name: "User" });
    methods.clearErrors();
  };

  const handleCancel = () => {
    navigate(healthProviderUserManagementPaths.indexPage.fullPath);
  };
  const handleSearchByNatId = natId => {
    setNationalId(natId);
    getClinician({
      variables: {
        nationalId: natId,
      },
    });
  };

  const onSubmit = data => {
    const { appRole, branches, ...rest } = data;
    const request = {
      ...rest,
      appRole: appRole?.value,
      branches: appRole?.value !== AppRoleTypes.Admin ? branches?.map(item => item.id) : [],
    };

    onDone(request);
  };
  return {
    methods,
    onSubmit,
    handleCancel,
    isUserByNatIdLoading,
    handleSearchByNatId,
  };
};
