import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formData } from "./type";

export const usePackageFormHook = ({ handleOnSubmit }) => {
  const navigate = useNavigate();

  const methods = useForm<formData>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors: errorsForm },
  } = methods;

  const onSubmit = (data: formData) => {
    handleOnSubmit({ ...data });
  };

  const handleCancel = () => {
    navigate(-1);
  };
  return {
    methods,
    location,
    errorsForm,
    handleSubmit,
    onSubmit,
    handleCancel,
  };
};
