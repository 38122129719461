import { getApolloContextFormToken } from "shared/utils";
import { useVisitPrescriptionsQuery } from "../gql";

export const usePageControllerQueries = ({ visitId, token }: { visitId: string; token?: string }) => {
  const {
    data,
    loading: isLoading,
    refetch,
  } = useVisitPrescriptionsQuery({
    variables: {
      visitId,
      first: 100,
    },
    skip: !visitId,
    context: getApolloContextFormToken(token),
  });

  const gridData = data?.visitPrescriptions?.edges?.map(item => item?.node) ?? [];

  return {
    isLoading,
    gridData,
    refetch,
  };
};
