import useSelectPeerFromInvitationLink from "@meetora/react/sehacity/Chat/useSelectPeerFromInvitationLink";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useGetChatLinkMutation } from "../gql";

export const useChatHooks = ({ handleToggle, meetingPlatformLinkForChatView, userId }) => {
  const [link, setLink] = useState<string | null>(meetingPlatformLinkForChatView);
  const auth = useAuth();

  const { data: peer } = useSelectPeerFromInvitationLink(link);
  const authenticated = auth.isAuthenticated || false;

  const { failed } = useAddToast();
  const [fetchGetChatLinkMutation] = useGetChatLinkMutation({
    onCompleted: async response => {
      const responseError = response?.saveOrFetchExistedAnonymousChatGroup?.error;
      if (responseError?.length) {
        failed(responseError);
      } else {
        const chatLink = response?.saveOrFetchExistedAnonymousChatGroup?.anonymousChatLinkForChatView;
        if (chatLink && chatLink?.length > 0) {
          setLink(chatLink);
          handleToggle();
        }
      }
    },
  });

  const startChat = () => {
    if (authenticated) {
      if (link) {
        handleToggle();
      } else {
        userId &&
          fetchGetChatLinkMutation({
            variables: {
              userIds: userId,
            },
          });
      }
    } else {
      auth.signinRedirect();
    }
  };
  return { startChat, peer };
};
