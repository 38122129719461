import { makeStyles } from "tss-react/mui";

export const useDispenseModelStyles = makeStyles()(theme => ({
  paper: {
    width: 536,
    height: "fit-content",
    borderRadius: 15,
  },
  box: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 0,
  },
  id: {
    fontSize: 28,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 0,
  },
  print: {
    width: 76,
  },
  ok: {
    width: 53,
    color: theme.palette.primary.main,
    border: "1px solid",
    background: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  action: {
    height: 70,
    padding: 10,
    display: "flex",
    alignItems: "center",
    boxShadow: theme.mixins.shadows.xs,
  },
  width: {
    width: "100%",
  },
  line: {
    marginTop: 10,
    marginBottom: 10,
    background: theme.palette.gray.main,
    opacity: 0.2,
  },
  container: {
    position: "relative",
  },
  button: {
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));
