/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorUpdateMutationVariables = Types.Exact<{
  doctorUpdateId: Types.Scalars['ID'];
  input: Types.UserUpdateInput;
}>;


export type DoctorUpdateMutation = { __typename?: 'Mutation', DoctorUpdate?: { __typename?: 'UserUpdate', user?: { __typename?: 'User', id: string } | null, accountErrors: Array<{ __typename?: 'AccountError', code: Types.AccountErrorCode, field?: string | null, message?: string | null }> } | null };


export const DoctorUpdateDocument = gql`
    mutation DoctorUpdate($doctorUpdateId: ID!, $input: UserUpdateInput!) {
  DoctorUpdate: userUpdate(id: $doctorUpdateId, input: $input) {
    user {
      id
    }
    accountErrors {
      code
      field
      message
    }
  }
}
    `;
export type DoctorUpdateMutationFn = Apollo.MutationFunction<DoctorUpdateMutation, DoctorUpdateMutationVariables>;

/**
 * __useDoctorUpdateMutation__
 *
 * To run a mutation, you first call `useDoctorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorUpdateMutation, { data, loading, error }] = useDoctorUpdateMutation({
 *   variables: {
 *      doctorUpdateId: // value for 'doctorUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoctorUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DoctorUpdateMutation, DoctorUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorUpdateMutation, DoctorUpdateMutationVariables>(DoctorUpdateDocument, options);
      }
export type DoctorUpdateMutationHookResult = ReturnType<typeof useDoctorUpdateMutation>;
export type DoctorUpdateMutationResult = Apollo.MutationResult<DoctorUpdateMutation>;
export type DoctorUpdateMutationOptions = Apollo.BaseMutationOptions<DoctorUpdateMutation, DoctorUpdateMutationVariables>;