export enum JourneyDurationPeriod {
  Annual = "Annual",
  Quarterly = "Quarterly",
}

export enum JourneyActivityScope {
  AllActivities = "AllActivities",
  MyActivities = "MyActivities",
}

export enum JourneyActivityFilter {
  ShowAll = "ShowAll",
  HideMedicalMessages = "HideMedicalMessages",
}
