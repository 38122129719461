export const marketplaceInvoicesOrdersRoute = `marketplace-orders-invoices`;

export const marketplaceOrdersInvoicesPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${marketplaceInvoicesOrdersRoute}${this.route}`;
    },
  },
};
