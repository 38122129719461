import { i18n } from "@toolkit/i18n";
import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";

const productsManagement = `${marketplaceRoute}/products-management`;
const newProduct = "new-Product";
const updateProduct = "update/:id";

export const productsManagementVendorRoutes = {
  name: i18n.t("Products Management", { ns: "provider" }),
  path: productsManagement,
  fullPath: productsManagement,
  products: {
    name: "Products",
    new: {
      name: i18n.t("new Product", { ns: "provider" }),
      path: [productsManagement, newProduct].join("/"),
      fullPath: [productsManagement, newProduct].join("/"),
    },
    edit: {
      name: i18n.t("Update Product", { ns: "provider" }),
      path: [productsManagement, updateProduct].join("/"),
      fullPath: [productsManagement, updateProduct].join("/"),
    },
  },
};
