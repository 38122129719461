import { PatientProfileTabContainer } from "../PatientProfileTabContainer";
import { CareEngagement } from "./CareEngagement";

export const Assessments = () => {
  return (
    <PatientProfileTabContainer
      isContainer
      isRoot
      sections={[
        {
          content: <CareEngagement />,
        },
      ]}
    />
  );
};
