/* eslint-disable sonarjs/cognitive-complexity */
import { useReactiveVar } from "@apollo/client";
import { PermissionEnum } from "@health/queries/types";
import { useOnAppointmentStatusUpdate } from "@health/sse";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs, useTheme } from "@toolkit/ui";
import { createAppointmentsBreadcrumbs, isTabsCountVisible } from "pages/appointments/constants";
import { useAppointmentsCountsQuery } from "pages/appointments/gql";
import { AppointmentTabsStatus, getAppointmentsTabs, getFilter, mapTabStatesToTotalCountKey } from "pages/appointments/utils";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { NurseCurrentWorkSpaceDetailsVar } from "shared/components/NurseCurrentWorkDetails";
import { PageTabsStatusType } from "shared/components/PageTabs";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isNurseUser } from "utils";

export const useAppointmentsContainer = () => {
  const [status, setStatus] = useState<PageTabsStatusType>(AppointmentTabsStatus.NewRequests);
  const [isSelfOnlyAppointments, setIsSelfOnlyAppointments] = useState(true);
  const { userInfo } = useOidcUserProfile();
  const { t } = useTranslation("provider");
  const [params] = useSearchParams();
  const { setBreadCrumb } = useBreadCrumbs();
  const currentNurseWorkDetails = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const skipCall = isNurseUser(userInfo) && !currentNurseWorkDetails?.doctors?.length;
  const doctors = currentNurseWorkDetails?.doctors?.map(e => e?.id);
  const filters = { doctors: isNurseUser(userInfo) ? doctors : undefined };
  const handleChangeTab = (newStatus: PageTabsStatusType) => {
    setStatus(newStatus || AppointmentTabsStatus.NewRequests);
  };

  const { data, refetch } = useAppointmentsCountsQuery({
    variables: {
      ...getFilter(isSelfOnlyAppointments, filters),
    },
    skip: !isTabsCountVisible || skipCall,
  });

  useOnAppointmentStatusUpdate(({ data: appointmentStatusUpdateData }) => {
    if (appointmentStatusUpdateData.graphqlType === "Appointment" && appointmentStatusUpdateData?.id) {
      isTabsCountVisible && refetch();
    }
  });

  const theme = useTheme();
  const tabsWithCounts = useMemo(() => {
    const tabs = getAppointmentsTabs(t, theme);
    const tabsView = tabs.map(tab => ({
      ...tab,
      count: data?.[mapTabStatesToTotalCountKey(tab.status)]?.totalCount || 0,
    }));

    return hasPermission(PermissionEnum.ManageAppointments)
      ? tabsView
      : tabsView.filter(item => item?.status !== AppointmentTabsStatus.tentative);
  }, [data, t]);

  const handleUpdateCount = input => {
    isTabsCountVisible &&
      refetch({
        ...input,
      });
  };

  useEffect(() => {
    if (params) {
      handleChangeTab(params.get("activeTab"));
    }
  }, [params]);

  useEffect(() => {
    isTabsCountVisible && refetch();
  }, [isSelfOnlyAppointments, refetch]);

  useEffect(() => {
    setBreadCrumb({ title: createAppointmentsBreadcrumbs(t).title });
  }, [setBreadCrumb, t]);

  return {
    t,
    status,
    tabsWithCounts,
    isSelfOnlyAppointments,
    handleChangeTab,
    handleUpdateCount,
    handleSelfOnlyAppointmentsChange: setIsSelfOnlyAppointments,
    skipCall,
    filters,
  };
};
