import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { Diagnosis } from "./Diagnosis";

export const DiagnosisPage: FC<{ id?: string }> = ({ id }) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const visitId = params.get("visitId") || id;

  return <Diagnosis visitId={visitId!} token={token!} />;
};

DiagnosisPage.defaultProps = {
  id: undefined,
};
