/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentQueryVariables = Types.Exact<{
  vendorDepartmentId: Types.Scalars['ID'];
}>;


export type DepartmentQuery = { __typename?: 'Query', vendorDepartment?: { __typename?: 'Department', code: string, description?: string | null, directions?: string | null, id: string, name: string, branch: { __typename?: 'Branch', id: string, name: string }, medicalServices?: { __typename?: 'DepartmentMedicalServiceCountableConnection', edges: Array<{ __typename?: 'DepartmentMedicalServiceCountableEdge', node: { __typename?: 'DepartmentMedicalService', code: string, id: string } }> } | null } | null };


export const DepartmentDocument = gql`
    query department($vendorDepartmentId: ID!) {
  vendorDepartment(id: $vendorDepartmentId) {
    code
    description
    directions
    id
    name
    branch {
      id
      name
    }
    medicalServices(first: 100) {
      edges {
        node {
          code
          id
        }
      }
    }
  }
}
    `;

/**
 * __useDepartmentQuery__
 *
 * To run a query within a React component, call `useDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentQuery({
 *   variables: {
 *      vendorDepartmentId: // value for 'vendorDepartmentId'
 *   },
 * });
 */
export function useDepartmentQuery(baseOptions: Apollo.QueryHookOptions<DepartmentQuery, DepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentQuery, DepartmentQueryVariables>(DepartmentDocument, options);
      }
export function useDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentQuery, DepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentQuery, DepartmentQueryVariables>(DepartmentDocument, options);
        }
export type DepartmentQueryHookResult = ReturnType<typeof useDepartmentQuery>;
export type DepartmentLazyQueryHookResult = ReturnType<typeof useDepartmentLazyQuery>;
export type DepartmentQueryResult = Apollo.QueryResult<DepartmentQuery, DepartmentQueryVariables>;