import { useTranslation } from "@toolkit/i18n";
import { Badge, Box, Divider, Tab, TabContext, TabList, Typography } from "@toolkit/ui";
import { FC, useState } from "react";
import { useSuggestionsStyles } from "./styles";
import { SuggestionsTab } from "./SuggestionsTab";
import { SummaryTab } from "./SummaryTab";
import { TranscriptsTab } from "./TranscriptsTab";

enum CopilotTabs {
  Transcript = "visit-transcript",
  Summary = "visit-summary",
  Suggestions = "Suggestions",
}
interface CopilotProps {
  visitId: string;
  payerId: string;
}

export const Copilot: FC<CopilotProps> = ({ visitId, payerId }) => {
  const [activeTab, setActiveTab] = useState<CopilotTabs>(CopilotTabs.Suggestions);
  const { t } = useTranslation("provider");
  const { classes, theme } = useSuggestionsStyles();
  const handleChangeTab = (_e, tab: CopilotTabs) => setActiveTab(tab);
  const [hasUnreadSuggestedDiagnoses, setHasUnreadSuggestedDiagnoses] = useState<boolean>(false);
  const haveBadge = (currentTab: CopilotTabs) => {
    if (currentTab === CopilotTabs.Suggestions && hasUnreadSuggestedDiagnoses) {
      return true;
    }
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.stale[100], height: "100%", borderRadius: 2.5, p: 2 }}>
      <Typography variant='subtitle2' sx={{ px: 2 }} gutterBottom>
        {t("Copilot")}
      </Typography>
      <Divider />

      <TabContext value={activeTab}>
        <TabList
          value={activeTab}
          onChange={handleChangeTab}
          classes={{
            flexContainer: classes.tabs,
          }}
        >
          {[CopilotTabs.Transcript, CopilotTabs.Summary, CopilotTabs.Suggestions].filter(Boolean).map(tab => {
            return (
              <Tab
                key={tab?.toString()}
                value={tab}
                classes={{
                  root: classes.tab,
                  selected: classes.selectedTab,
                }}
                label={
                  <Badge
                    variant='dot'
                    color='error'
                    sx={{
                      "& .MuiBadge-badge": {
                        height: "12px",
                        width: "12px",
                        borderRadius: "50%",
                        display: haveBadge(tab) ? "absolute" : "none",
                      },
                    }}
                  >
                    {t(tab.toString())}
                  </Badge>
                }
              />
            );
          })}
        </TabList>
        <Box sx={{ mt: 2 }}>
          {activeTab === CopilotTabs.Transcript && <TranscriptsTab visitId={visitId} />}
          {activeTab === CopilotTabs.Summary && <SummaryTab />}
          <SuggestionsTab
            payerId={payerId}
            visitId={visitId}
            isTabSelected={activeTab === CopilotTabs.Suggestions}
            setHasUnreadSuggestedDiagnoses={setHasUnreadSuggestedDiagnoses}
          />
        </Box>
      </TabContext>
    </Box>
  );
};
