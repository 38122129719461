import { Visit } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, StaticFilterDialog, useGridFilter } from "@toolkit/ui";
import { useMemo } from "react";
import { useOrderPageContext } from "../state";

export const useFiltersFields = (): CustomTableColumnProps<Partial<Visit>>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "numbers",
        header: t("Number"),
        showOnlyForFilter: true,
        filter: {
          type: "number",
          name: "numbers",
          label: t("Numbers"),
        },
      },
    ];
  }, []);
};

export const OrdersFilter = () => {
  const { onFilter: handleApplyFilters } = useOrderPageContext();

  const { staticFiltersProps } = useGridFilter(handleApplyFilters, {});
  return <StaticFilterDialog filterFields={useFiltersFields()} {...staticFiltersProps} />;
};
