import { VisitPrescriptionTransaction } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTable, Divider, Expandable } from "@toolkit/ui";
import React, { FC } from "react";
import { StyleFix } from "../components/StyleFix";
import PrescriptionForm from "./PrescriptionForm";
import { useColumns } from "./useColumns";
import { usePageControllerQueries } from "./usePageControllerQueries";
import { useStyles } from "./useStyles";

export const Prescription: FC<{ visitId; token?: string }> = ({ visitId, token }) => {
  const { t } = useTranslation("provider");
  const columns = useColumns();
  const { isLoading, gridData, refetch } = usePageControllerQueries({ visitId, token });

  const { classes } = useStyles();
  const handleCreateSuccess = () => {
    refetch();
  };

  return (
    <React.Fragment>
      <StyleFix />
      <Box sx={{ p: 2 }}>
        <Expandable headerTitle={t("Add Prescription")} defaultOpen={true}>
          <PrescriptionForm visitId={visitId} token={token} onCreateSuccess={handleCreateSuccess} />
        </Expandable>
        <Divider sx={{ my: 4 }} />
        <Expandable headerTitle={t("Prescription History")}>
          <div className={classes.grid}>
            <CustomTable
              isLoading={isLoading}
              data={gridData as VisitPrescriptionTransaction[]}
              columns={columns}
              pageSize={10}
              sc
              pageIndex={0}
              pagesCount={10}
              hasFooter={false}
            />
          </div>
        </Expandable>
      </Box>
    </React.Fragment>
  );
};

Prescription.defaultProps = {
  token: undefined,
};

export default Prescription;
