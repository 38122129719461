import { Box, CustomIcon, paths, Typography, useTheme } from "@toolkit/ui";
import React, { FC } from "react";

type TimelineItemContentProps = {
  icon: keyof typeof paths | string;
  title?: string;
  isBold?: boolean;
  viewBox?: string;
  color?: string;
};

export const TimelineItemContent: FC<TimelineItemContentProps> = ({ icon, color, title, isBold, viewBox }) => {
  const theme = useTheme();
  return (
    <Box display='flex' alignItems='center'>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            margin: "5px",
            width: "20px",
            height: "20px",
            background: color ? color : theme.palette.primary.main,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomIcon icon={icon as keyof typeof paths} color='white' viewBox={viewBox ? viewBox : "-10 -9 45 45"} />
        </Box>
      </Box>
      <Typography
        fontWeight={isBold ? theme.mixins.fonts.fontWeight.medium : theme.mixins.fonts.fontWeight.regular}
        sx={{ color: color ? color : theme.palette.primary.main }}
        fontSize={theme.mixins.fonts.fontSize.xs}
      >
        {title}
      </Typography>
    </Box>
  );
};

TimelineItemContent.defaultProps = {
  icon: undefined,
  title: undefined,
  isBold: undefined,
  color: undefined,
  viewBox: undefined,
};
