import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import React from "react";
import { useGuidedCareJourneyGoalsStyle } from "./useGuidedCareJourneyGoalsStyle";
import { useProfileContext } from "../../../context/ProfileContext";
import { useGuidedCareTemplateGoalListQuery } from "../../../gql";

export const GuidedCareJourneyGoals = () => {
  const { t } = useTranslation("provider");

  const { guidedCareProgramTemplateId } = useProfileContext();

  const { classes } = useGuidedCareJourneyGoalsStyle();

  const { data, loading } = useGuidedCareTemplateGoalListQuery({
    variables: {
      id: guidedCareProgramTemplateId!,
    },
    skip: !guidedCareProgramTemplateId,
  });

  const templateGoals = data?.healthProgramTemplate?.templateGoals;

  return (
    <Box>
      <Typography className={classes.title}> {t("Goals")} </Typography>

      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box className={classes.wrapper}>
          {templateGoals?.length ? (
            templateGoals?.map((item, index) => {
              return (
                <Box key={item?.id} className={classes.itemWrapper}>
                  <Typography className={classes.text}> {pickLocalizedValue(item?.name, item?.nameAr)} </Typography>
                  <Typography className={classes.text}> {item?.target} </Typography>

                  {index !== templateGoals?.length - 1 && <Box className={classes.circle} />}
                </Box>
              );
            })
          ) : (
            <Typography> {t("There are no goals")} </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
