import { MarketplaceBrandsAutocomplete, MarketplaceProductsCategoriesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Chip, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import React, { FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";

export const ProductInformationForm: FC = () => {
  const { t } = useTranslation("provider");
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"name"} label={t("Name")} />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"nameAr"} label={t("Arabic Name")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <MarketplaceBrandsAutocomplete name={"productBrand"} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <MarketplaceProductsCategoriesAutocomplete name={"productCategory"} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormNumberField name={"price"} label={t("Price")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormNumberField name={"stockQuantity"} label={t("Stock Quantity")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"sku"} label={t("SKU")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <AutocompleteController
          ControllerProps={{
            control: control,
            name: "tags",
          }}
          TextFieldProps={{
            placeholder: t("Tags"),
            error: Boolean(errors?.type?.message),
            helperText: errors?.type?.message,
            rows: 3,
          }}
          multiple
          freeSolo
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Fragment key={index}>
                <Chip variant='outlined' sx={{ height: "23px" }} label={option} {...getTagProps({ index })} />
              </Fragment>
            ))
          }
        />
      </Grid>
      <Grid xs={12} />
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"shortDescription"} label={t("Short Description")} multiline rows={3} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"shortDescriptionAr"} label={t("Short Description (Arabic)")} multiline rows={3} />
      </Grid>
      <Grid item xs={12}>
        <FormSwitch name={"returnable"} label={t("Is Returnable")} />
      </Grid>
    </Grid>
  );
};
