/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientHealthParameterVitalSignsPercentageListQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID'];
}>;


export type PatientHealthParameterVitalSignsPercentageListQuery = { __typename?: 'Query', patientHealthParameterVitalSignsChangePercentage?: Array<{ __typename?: 'PatientHPVitalSignsChangePercentage', hpCode?: string | null, changePercentage?: any | null } | null> | null };


export const PatientHealthParameterVitalSignsPercentageListDocument = gql`
    query PatientHealthParameterVitalSignsPercentageList($patientId: ID!) {
  patientHealthParameterVitalSignsChangePercentage(patientId: $patientId) {
    hpCode
    changePercentage
  }
}
    `;

/**
 * __usePatientHealthParameterVitalSignsPercentageListQuery__
 *
 * To run a query within a React component, call `usePatientHealthParameterVitalSignsPercentageListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientHealthParameterVitalSignsPercentageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientHealthParameterVitalSignsPercentageListQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientHealthParameterVitalSignsPercentageListQuery(baseOptions: Apollo.QueryHookOptions<PatientHealthParameterVitalSignsPercentageListQuery, PatientHealthParameterVitalSignsPercentageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientHealthParameterVitalSignsPercentageListQuery, PatientHealthParameterVitalSignsPercentageListQueryVariables>(PatientHealthParameterVitalSignsPercentageListDocument, options);
      }
export function usePatientHealthParameterVitalSignsPercentageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientHealthParameterVitalSignsPercentageListQuery, PatientHealthParameterVitalSignsPercentageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientHealthParameterVitalSignsPercentageListQuery, PatientHealthParameterVitalSignsPercentageListQueryVariables>(PatientHealthParameterVitalSignsPercentageListDocument, options);
        }
export type PatientHealthParameterVitalSignsPercentageListQueryHookResult = ReturnType<typeof usePatientHealthParameterVitalSignsPercentageListQuery>;
export type PatientHealthParameterVitalSignsPercentageListLazyQueryHookResult = ReturnType<typeof usePatientHealthParameterVitalSignsPercentageListLazyQuery>;
export type PatientHealthParameterVitalSignsPercentageListQueryResult = Apollo.QueryResult<PatientHealthParameterVitalSignsPercentageListQuery, PatientHealthParameterVitalSignsPercentageListQueryVariables>;