import { VisitStatus as VisitStatusEnum } from "@health/queries/types";
import { useOnHealthProgramStatusUpdate } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  CustomToggleButton,
  FilterGrid,
  FormControlLabel,
  GridContextProps,
  GridContextRef,
  PageWrapper,
  TableGrid,
  useTheme,
} from "@toolkit/ui";
import { createRef, FC } from "react";
import { GridProvider } from "shared/components";
import { PageTabs, PageTabsStatusType, PageTabsType } from "shared/components/PageTabs";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useVisitColumns } from "./components/useVisitColumns";
import { isTabsCountVisible } from "./constants";
import { useVisitsQuery } from "./gql";
import { getSelfOnlyValue, VisitTabsStatus } from "./utils";

type IProgramsCalls = {
  status: VisitStatusEnum[];
  state: PageTabsStatusType;
  onChangeTab: (view: PageTabsStatusType) => void;
  tabs: PageTabsType;
  isSelfOnly: boolean;
  handleToggleOnlyMyVisit: (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
};

export const ProgramsCalls: FC<IProgramsCalls> = ({
  status,
  state,
  onChangeTab: handleChangeTab,
  tabs,
  isSelfOnly,
  handleToggleOnlyMyVisit,
}) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const gridRef = createRef<GridContextProps>();
  const { userInfo } = useOidcUserProfile();
  const isSelfOnlyValue = getSelfOnlyValue({ isSelfOnly, status: state });
  const isVendorAdmin = userInfo?.app_role?.toLowerCase() === "admin";

  useOnHealthProgramStatusUpdate(({ data }) => {
    if (data.graphqlType === "Visit" && data?.id) {
      setTimeout(() => {
        gridRef.current?.refetch();
      }, 500);
    }
  });
  return (
    <GridProvider
      hideFilterInput
      variables={{
        filter: {
          status,
          selfOnly: isSelfOnlyValue,
        },
      }}
      filterInput={{
        status,
        selfOnly: isSelfOnlyValue,
      }}
      gridName='visits'
      columns={useVisitColumns({ status: state, isSelfOnly: isSelfOnlyValue })}
      query={useVisitsQuery}
    >
      <PageWrapper
        headerComponent={<PageTabs onChangeTab={handleChangeTab} tabs={tabs} selected={state} showTabCount={isTabsCountVisible} />}
        filters={
          <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
            <Box display='flex' alignItems='center' height={60}>
              {isVendorAdmin && state !== VisitTabsStatus.activeCall && (
                <FormControlLabel
                  sx={{ minWidth: 120, marginInline: "5px", fontSize: theme.mixins.fonts.fontSize.xs }}
                  control={<CustomToggleButton value={isSelfOnly} defaultChecked={isSelfOnly} onChange={handleToggleOnlyMyVisit} />}
                  label={t("Me Only")}
                />
              )}
            </Box>
            <FilterGrid />
          </Box>
        }
      >
        <GridContextRef ref={gridRef} />
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
