import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
    "& .MuiAutocomplete-root .MuiFilledInput-root": {
      paddingTop: "unset !important",
    },
  },
  paper: {
    overflow: "auto",
    borderRadius: 10,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBlock: 5,
    paddingInline: 3,
    marginInline: 10,
    borderBottom: "1px solid #60779533",
  },
  action: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 70,
    boxShadow: theme.mixins.shadows.xs,
    justifyContent: "flex-start",
  },
}));
