import newNotificationSound from "assets/sounds/newNotificationSound.mp3";
import { useEffect, useState } from "react";

export const useNotificationSound = () => {
  const [audio] = useState(new Audio(newNotificationSound));

  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  const playNotificationSound = async () => {
    try {
      audio.loop = false;
      audio.currentTime = 0;
      await audio.play();
    } catch (error) {
      console.error("Failed to play the notification sound:", error);
    }
  };

  return { playNotificationSound };
};
