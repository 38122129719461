/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetLabResultMutationVariables = Types.Exact<{
  input: Types.LabResultInput;
}>;


export type SetLabResultMutation = { __typename?: 'Mutation', setLabResult?: { __typename?: 'LabCRUD', lab?: { __typename?: 'Lab', id: string } | null, labErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const SetLabResultDocument = gql`
    mutation SetLabResult($input: LabResultInput!) {
  setLabResult(input: $input) {
    lab {
      id
    }
    labErrors {
      code
      field
      message
    }
  }
}
    `;
export type SetLabResultMutationFn = Apollo.MutationFunction<SetLabResultMutation, SetLabResultMutationVariables>;

/**
 * __useSetLabResultMutation__
 *
 * To run a mutation, you first call `useSetLabResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLabResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLabResultMutation, { data, loading, error }] = useSetLabResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLabResultMutation(baseOptions?: Apollo.MutationHookOptions<SetLabResultMutation, SetLabResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLabResultMutation, SetLabResultMutationVariables>(SetLabResultDocument, options);
      }
export type SetLabResultMutationHookResult = ReturnType<typeof useSetLabResultMutation>;
export type SetLabResultMutationResult = Apollo.MutationResult<SetLabResultMutation>;
export type SetLabResultMutationOptions = Apollo.BaseMutationOptions<SetLabResultMutation, SetLabResultMutationVariables>;