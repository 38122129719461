import { Grid, Skeleton } from "@toolkit/ui";
import React from "react";
import { useStyles } from "./WaitingRoomsCardsSkeleton.styles";

interface WaitingRoomsCardSkeletonProps {
  count?: number;
}

const SkeletonCardElement = classes => (
  <Grid container className={classes.root}>
    {/* Left side with Avatar */}
    <Grid item xs={3} container alignItems='center' justifyContent='center'>
      <Skeleton variant='circular' width={80} height={80} animation='wave' />
    </Grid>

    {/* Right side with content */}
    <Grid item xs={9} className={classes.content}>
      <Skeleton height={24} width='70%' animation='wave' />
      <Skeleton height={20} width='60%' animation='wave' />
      <Skeleton height={16} width='20%' animation='wave' />

      <Grid container spacing={2} sx={{ marginTop: 0 }} alignItems='center'>
        <Grid item>
          <Skeleton variant='rectangular' height={22} width={120} animation='wave' />
        </Grid>
        <Grid item>
          <Skeleton variant='rectangular' height={22} width={120} animation='wave' />
        </Grid>
        <Grid item>
          <Skeleton variant='rectangular' height={22} width={120} animation='wave' />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export const WaitingRoomsCardsSkeleton: React.FC<WaitingRoomsCardSkeletonProps> = ({ count = 1 }) => {
  const { classes } = useStyles();
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Grid container key={index} className={classes.root}>
          <SkeletonCardElement classes={classes} />
        </Grid>
      ))}
    </>
  );
};
