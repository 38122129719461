import { Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { OrdersBody } from "../components";
import { useOrderPageContext } from "../state";

export const OrdersTabScreen: FC = () => {
  const { filter, selectedTab, fetchDataBySelectedTab } = useOrderPageContext();

  useEffect(() => {
    fetchDataBySelectedTab();
  }, [filter]);

  return (
    <div id={"layout"}>
      <Grid container spacing={1} justifyContent='center'>
        <Grid item xs={8}>
          <OrdersBody status={selectedTab} />
        </Grid>
      </Grid>
    </div>
  );
};
