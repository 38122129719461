import { makeStyles } from "@toolkit/ui";

export const useOrdersViewStyles = makeStyles<{ selectedView: string }>()((theme, { selectedView }) => ({
  flex: {
    display: "flex",
    boxShadow: theme.mixins.shadows.md,
    border: "1px solid",
    borderColor: theme.palette.gray[1400],
    borderRadius: 10,
    height: 37,
    alignItems: "center",
  },
  selectedButtonTabs: {
    margin: 0,
    fontSize: theme.mixins.fonts.fontSize.xs,
    whiteSpace: "nowrap",
    height: selectedView === "Tabs" ? 40 : 35,
    color: selectedView === "Tabs" ? `${theme.palette.common.white} !important` : `${theme.palette.primary.main} !important`,
    background: selectedView !== "Tabs" ? `${theme.palette.common.white} !important` : `${theme.palette.primary.main} !important`,
    "&:hover": {
      boxShadow: theme.mixins.shadows.none,
    },
  },
  selectedButtonQueue: {
    margin: 0,
    fontSize: theme.mixins.fonts.fontSize.xs,
    whiteSpace: "nowrap",
    height: selectedView !== "Tabs" ? 40 : 35,
    color: selectedView !== "Tabs" ? `${theme.palette.common.white} !important` : `${theme.palette.primary.main} !important`,
    background: selectedView === "Tabs" ? `${theme.palette.common.white} !important` : `${theme.palette.primary.main} !important`,
    "&:hover": {
      boxShadow: theme.mixins.shadows.none,
    },
  },
}));
