import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { UserInfoType } from "shared/configs/oidc";
import { AllDoctorsList } from "./List/AllDoctorsList";
import { isPharmacistUser, isVendorAdmin } from "utils";
import { allDoctorPaths, allDoctorRoute } from "./constants";

export const allDoctorsRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: UserInfoType }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}): RouteItem => {
  const isUserAVendorAdmin = isVendorAdmin(userInfo);
  const isUserAPharmacist = isPharmacistUser(userInfo);

  return {
    id: "all-doctors",
    text: t("All Doctors"),
    route: allDoctorRoute,
    hidden: true,
    isProhibited: !isUserAVendorAdmin || isUserAPharmacist,
    fullPath: allDoctorPaths.indexPage.fullPath,
    element: <AllDoctorsList />,
    onClick: route => navigate(route),
  };
};
