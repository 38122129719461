import { Typography } from "@toolkit/ui";
import { FC } from "react";
import { mapStatusToColor } from "../statuses";
import { useColumnHeaderStyles } from "./ColumnHeader.styles";

type ColumnHeaderProps = {
  name: string;
  status: string;
};

export const ColumnHeader: FC<ColumnHeaderProps> = props => {
  const { name, status } = props;
  const color = mapStatusToColor[status];
  const { classes } = useColumnHeaderStyles({ color });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography className={classes.name}>{name}</Typography>
      </div>
    </div>
  );
};
