import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import {
  Box,
  Button,
  CircularProgress,
  CustomDialog,
  CustomIcon,
  IconButton,
  Tooltip,
  Typography,
  useAddToast,
  useTheme,
} from "@toolkit/ui";
import { FC, useState } from "react";
import { useMarketplaceProductCloneMutation } from "../gql";
import { ProductsQueryNode } from "../types";

export const CloneDialog: FC<Pick<ProductsQueryNode, "id" | "name" | "nameAr" | "updatable">> = ({ id, name, nameAr, updatable }) => {
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const { t } = useTranslation("provider");
  const { succeeded } = useAddToast();
  const theme = useTheme();

  const [productCloneMutation, { loading: isCloneLoading }] = useMarketplaceProductCloneMutation({
    onCompleted: () => {
      succeeded(t("Succeeded in Cloning Product!"));
      setIsCloneModalOpen(false);
    },
    update: cache => {
      const normalizedId = cache.identify({ id: id, __typename: "MarketplaceProduct" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleClone = () => {
    if (isCloneLoading) return;
    productCloneMutation({
      variables: {
        marketplaceProductCloneId: id,
      },
    });
    handleClose();
  };

  const handleClose = () => {
    setIsCloneModalOpen(false);
  };
  const handleOpen = () => {
    setIsCloneModalOpen(true);
  };

  return (
    <>
      <Tooltip title={t("Clone Product")}>
        <IconButton disabled={!updatable} onClick={handleOpen}>
          <CustomIcon icon='clone' />
        </IconButton>
      </Tooltip>
      <CustomDialog
        type='base'
        DialogTitleProps={{
          onClose: handleClose,
          title: t("Clone Product"),
        }}
        open={isCloneModalOpen}
        maxWidth='md'
        keepMounted={false}
        DialogActionsProps={{
          children: (
            <>
              <Button onClick={handleClone} disabled={isCloneLoading} startIcon={isCloneLoading && <CircularProgress size={15} />}>
                {t("Clone")}
              </Button>
              <Button variant='outlined' onClick={handleClose} autoFocus>
                {t("Close")}
              </Button>
            </>
          ),
        }}
        DialogContentProps={{ sx: { minHeight: "10vh" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography fontSize={20} marginBottom='16px' color={theme.palette.primary[1000]}>
            {t("Are you sure you want to clone {{productName}}?", {
              productName: pickLocalizedValue(name!, nameAr!) || t("this product"),
            })}
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};
