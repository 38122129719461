/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalFormsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.MedicalFormFilter>;
}>;


export type MedicalFormsQuery = { __typename?: 'Query', medicalForms?: { __typename?: 'MedicalFormConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null, edges?: Array<{ __typename?: 'MedicalFormEdge', node?: { __typename?: 'MedicalForm', id: string, name?: string | null, active?: boolean | null, description?: string | null, updateDate?: any | null, createdDate?: any | null, questions?: Array<{ __typename?: 'Question', id?: string | null }> | null, medicalFormClassifications?: Array<{ __typename?: 'MedicalFormClassification', id: string, name?: string | null, rangeTo?: number | null, rangeFrom?: number | null } | null> | null } | null } | null> | null } | null };


export const MedicalFormsDocument = gql`
    query MedicalForms($first: Int, $after: String, $filter: MedicalFormFilter) {
  medicalForms(first: $first, after: $after, filter: $filter) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        name
        active
        description
        updateDate
        createdDate
        questions {
          id
        }
        medicalFormClassifications {
          id
          name
          rangeTo
          rangeFrom
        }
      }
    }
  }
}
    `;

/**
 * __useMedicalFormsQuery__
 *
 * To run a query within a React component, call `useMedicalFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalFormsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMedicalFormsQuery(baseOptions?: Apollo.QueryHookOptions<MedicalFormsQuery, MedicalFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalFormsQuery, MedicalFormsQueryVariables>(MedicalFormsDocument, options);
      }
export function useMedicalFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalFormsQuery, MedicalFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalFormsQuery, MedicalFormsQueryVariables>(MedicalFormsDocument, options);
        }
export type MedicalFormsQueryHookResult = ReturnType<typeof useMedicalFormsQuery>;
export type MedicalFormsLazyQueryHookResult = ReturnType<typeof useMedicalFormsLazyQuery>;
export type MedicalFormsQueryResult = Apollo.QueryResult<MedicalFormsQuery, MedicalFormsQueryVariables>;