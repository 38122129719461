import { ProviderSettings } from "@health/queries/types";
import { useProviderSettingsQuery } from "../gql";

const useGeneralSettings = () => {
  const { data, loading } = useProviderSettingsQuery();

  const providerSettings = data?.providerSettings?.edges.map(item => item?.node)[0] as ProviderSettings;

  return {
    providerSettings,
    loading,
  };
};

export default useGeneralSettings;
