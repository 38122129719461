/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type PatientHealthHistoryFragmentFragment = { __typename?: 'PatientHealthHistory', id: string, code: string, display?: string | null, healthHistoryType: Types.HealthHistoryType, lastModifiedDate?: any | null };

export const PatientHealthHistoryFragmentFragmentDoc = gql`
    fragment patientHealthHistoryFragment on PatientHealthHistory {
  id
  code
  display
  healthHistoryType
  lastModifiedDate
}
    `;