import { makeStyles } from "@toolkit/ui";

export const useAppointmentAcceptPaymentInformationStyle = makeStyles()(theme => ({
  boldTitle: {
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  },
  updateButton: {
    paddingBlock: 24,
  },
}));
