import { Department, VendorDepartmentError } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DepartmentInformation } from "./components/DepartmentInformation";

export const DepartmentForm: FC<{
  defaultValues?: Department;
  isLoading: boolean;
  onDone: (inputData: any) => void;
  isEditMode?: boolean;
  errors: VendorDepartmentError[];
}> = ({ defaultValues, isLoading, onDone, isEditMode, errors }) => {
  const { t } = useTranslation("provider");
  const methods = useForm();
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const doYouHaveData = isEditMode ? defaultValues != undefined : true;
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onDone)}>
        <PageWrapper
          actions={<FormActions isLoading={isLoading} hasCancel hasFormButton formButtonTitle={t("Save")} onNavigation={handleCancel} />}
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard loading={isLoading} title={t("Department Information")} doYouHaveData={doYouHaveData}>
                <DepartmentInformation defaultValues={defaultValues} errors={errors} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
