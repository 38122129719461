import { UserVendorUserType } from "@health/queries/types";
import { branchUserPreferenceVar, removeSelectedBranchFromLocalStorage } from "shared/components/BranchUser";
import { NurseCurrentWorkSpaceDetailsVar, removeNurseWorkSpaceDetailsFromLocalStorage } from "shared/components/NurseCurrentWorkDetails";
import { UserInfoType } from "shared/configs/oidc";

export const removeUserWorkingDetails = (vendorUserType: UserInfoType["vendor_user_type"]) => {
  if (vendorUserType?.toLowerCase() === UserVendorUserType.Nurse.toLowerCase()) {
    removeNurseWorkSpaceDetailsFromLocalStorage();
    NurseCurrentWorkSpaceDetailsVar(undefined);
  } else {
    removeSelectedBranchFromLocalStorage();
    branchUserPreferenceVar(undefined);
  }
};
