/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { UserNotificationFragmentFragmentDoc } from '../../../../../../pages/Notifications/gql/queries/__generated__/meNotifications';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkNotificationAsSeenMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type MarkNotificationAsSeenMutation = { __typename?: 'Mutation', markNotificationAsSeen?: { __typename?: 'UserNotification', id?: string | null, seen?: boolean | null, type?: Types.NotificationType | null, creationDate?: any | null, data?: string | null, errors?: Array<{ __typename?: 'SubscriptionGraphqlError', code?: Types.SubscriptionErrorCodes | null, field?: string | null, message?: string | null, errorType?: Types.ErrorType | null } | null> | null } | null };


export const MarkNotificationAsSeenDocument = gql`
    mutation MarkNotificationAsSeen($id: ID!) {
  markNotificationAsSeen(id: $id) {
    ...UserNotificationFragment
  }
}
    ${UserNotificationFragmentFragmentDoc}`;
export type MarkNotificationAsSeenMutationFn = Apollo.MutationFunction<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>;

/**
 * __useMarkNotificationAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsSeenMutation, { data, loading, error }] = useMarkNotificationAsSeenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkNotificationAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>(MarkNotificationAsSeenDocument, options);
      }
export type MarkNotificationAsSeenMutationHookResult = ReturnType<typeof useMarkNotificationAsSeenMutation>;
export type MarkNotificationAsSeenMutationResult = Apollo.MutationResult<MarkNotificationAsSeenMutation>;
export type MarkNotificationAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>;