import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { discountsBreadcrumb } from "../../constants/DiscountsBreadcrumbs";
import { useMarketplaceDiscountsQuery } from "../../gql/queries";
import { useDiscountsListTableColumns } from "./useDiscountsListTableColumns";
import { DiscountsQueryNode } from "../../types";
import { discountsPaths } from "../../constants/DiscountsPaths";
import { useNavigate } from "react-router-dom";
import { MarketplaceSortDirection } from "@health/queries/types";

export const DiscountsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const onEditRowClick = (item: DiscountsQueryNode) => {
    navigate(discountsPaths.update.fullPathWithParams({ discountId: item?.id }));
  };
  const onAddNewItemClick = () => {
    navigate(discountsPaths.new.fullPath);
  };
  useEffect(() => {
    setBreadCrumb({
      title: discountsBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName='marketplace-discounts'
      query={useMarketplaceDiscountsQuery}
      columns={useDiscountsListTableColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isRowEditable: row => !row?.isPublished,
      }}
      variables={{
        sortBy: {
          field: "CREATED",
          direction: MarketplaceSortDirection.Desc,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
