import { TFunction } from "@toolkit/i18n";
import { ListViewIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { PatientProfileComponent } from "../components/PatientProfile.screen";

const patientProfileRoute = "/patient-profile/:visitId";

export const patientProfilePaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${patientProfileRoute}${this.route}`;
    },
  },
};

export const patientProfileRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: patientProfileRoute,
    route: patientProfileRoute,
    fullPath: patientProfilePaths.indexPage.fullPath,
    text: t("Patient Profile", { ns: "provider" }),
    icon: <ListViewIcon />,
    element: <PatientProfileComponent />,
    hidden: true,
    onClick: (route: string) => navigate(route),
    // isProhibited: Boolean(userInfo?.vendor_id && userInfo?.doctor_id), // TODO Double check
  };
};
