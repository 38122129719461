/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPersonalizedPatientAppointmentMutationVariables = Types.Exact<{
  input: Types.CreatePersonalizedAppointmentInput;
}>;


export type AddPersonalizedPatientAppointmentMutation = { __typename?: 'Mutation', addPersonalizedPatientAppointment?: { __typename?: 'MutationPersonalizedConsumerAppointmentCreate', requestStatus?: Types.RequestStatus | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const AddPersonalizedPatientAppointmentDocument = gql`
    mutation AddPersonalizedPatientAppointment($input: CreatePersonalizedAppointmentInput!) {
  addPersonalizedPatientAppointment(input: $input) {
    requestStatus
    appointmentErrors {
      code
      field
      message
    }
  }
}
    `;
export type AddPersonalizedPatientAppointmentMutationFn = Apollo.MutationFunction<AddPersonalizedPatientAppointmentMutation, AddPersonalizedPatientAppointmentMutationVariables>;

/**
 * __useAddPersonalizedPatientAppointmentMutation__
 *
 * To run a mutation, you first call `useAddPersonalizedPatientAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonalizedPatientAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonalizedPatientAppointmentMutation, { data, loading, error }] = useAddPersonalizedPatientAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPersonalizedPatientAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonalizedPatientAppointmentMutation, AddPersonalizedPatientAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPersonalizedPatientAppointmentMutation, AddPersonalizedPatientAppointmentMutationVariables>(AddPersonalizedPatientAppointmentDocument, options);
      }
export type AddPersonalizedPatientAppointmentMutationHookResult = ReturnType<typeof useAddPersonalizedPatientAppointmentMutation>;
export type AddPersonalizedPatientAppointmentMutationResult = Apollo.MutationResult<AddPersonalizedPatientAppointmentMutation>;
export type AddPersonalizedPatientAppointmentMutationOptions = Apollo.BaseMutationOptions<AddPersonalizedPatientAppointmentMutation, AddPersonalizedPatientAppointmentMutationVariables>;