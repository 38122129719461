/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentBranchesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DepartmentBranchesQuery = { __typename?: 'Query', branches?: { __typename?: 'BranchCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } }> } | null };


export const DepartmentBranchesDocument = gql`
    query departmentBranches($first: Int, $last: Int, $after: String, $before: String) {
  branches(first: $first, last: $last, after: $after, before: $before) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    `;

/**
 * __useDepartmentBranchesQuery__
 *
 * To run a query within a React component, call `useDepartmentBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentBranchesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDepartmentBranchesQuery(baseOptions?: Apollo.QueryHookOptions<DepartmentBranchesQuery, DepartmentBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentBranchesQuery, DepartmentBranchesQueryVariables>(DepartmentBranchesDocument, options);
      }
export function useDepartmentBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentBranchesQuery, DepartmentBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentBranchesQuery, DepartmentBranchesQueryVariables>(DepartmentBranchesDocument, options);
        }
export type DepartmentBranchesQueryHookResult = ReturnType<typeof useDepartmentBranchesQuery>;
export type DepartmentBranchesLazyQueryHookResult = ReturnType<typeof useDepartmentBranchesLazyQuery>;
export type DepartmentBranchesQueryResult = Apollo.QueryResult<DepartmentBranchesQuery, DepartmentBranchesQueryVariables>;