import { Card, CustomTabs, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useMyFeed } from "./useMyFeed";

export const MyFeed: FC = () => {
  const { tabs } = useMyFeed();

  return (
    <Card>
      <Grid container direction='column' padding={0} height={419} sx={{ width: "100%" }}>
        <Grid item xs sx={{ width: "100%" }}>
          <CustomTabs items={tabs} />
        </Grid>
      </Grid>
    </Card>
  );
};
