import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Card, CardHeader, Divider, IconButton, ListItem, PlusIcon, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useAddMemberToChronicGuidedCareProgramMutation } from "../../gql";
import { useCoPilotContext } from "../CoPilotContext";

interface SuggestedProgramsProps {}

export const SuggestedPrograms: FC<SuggestedProgramsProps> = () => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const { selectedVisitDiagnosis } = useCoPilotContext();
  const [fetchAddMemberToGuidedCareProgram, { loading: isSubmitting }] = useAddMemberToChronicGuidedCareProgramMutation({
    onCompleted: request => {
      const visitDiagnosisRiskTemplateErrors = request?.addMemberToChronicGuidedCareProgram?.visitDiagnosisRiskTemplateErrors;
      if (visitDiagnosisRiskTemplateErrors?.length === 0) {
        succeeded(t("Add Care Program Successfully"));
      } else {
        formatMessageErrors(visitDiagnosisRiskTemplateErrors);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const suggestedGuidedPrograms = selectedVisitDiagnosis?.riskTemplate?.suggestedGuidedPrograms;

  const handleOnClickUseSuggestedProgram = (programId: string) => () => {
    fetchAddMemberToGuidedCareProgram({
      variables: {
        input: {
          guidedCareProgramId: programId,
          visitDiagnosisRiskTemplate: selectedVisitDiagnosis?.riskTemplate?.id!,
        },
      },
      onCompleted: ({ addMemberToChronicGuidedCareProgram }) => {
        if (addMemberToChronicGuidedCareProgram?.visitDiagnosisRiskTemplateErrors?.length) {
          failed(formatMessageErrors(addMemberToChronicGuidedCareProgram?.visitDiagnosisRiskTemplateErrors));
        } else {
          succeeded("User notified to join a chronic care team successfully.");
        }
      },
    });
  };
  const theme = useTheme();
  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader
        title={t("Suggested care programs")}
        titleTypographyProps={{ variant: "body1", fontWeight: theme.mixins.fonts.fontWeight.medium }}
      />
      <Divider />

      {suggestedGuidedPrograms?.length! < 1 ? (
        <ListItem>{t("No Program Suggestions Found")}</ListItem>
      ) : (
        <>
          {suggestedGuidedPrograms?.map(suggestedProgram => {
            return (
              <ListItem
                key={suggestedProgram?.id}
                secondaryAction={
                  <IconButton
                    edge='end'
                    aria-label='comments'
                    disabled={isSubmitting}
                    onClick={handleOnClickUseSuggestedProgram(suggestedProgram?.id!)}
                  >
                    <PlusIcon fill={theme.palette.primary.main} />
                  </IconButton>
                }
              >
                {suggestedProgram?.name}
              </ListItem>
            );
          })}
        </>
      )}
    </Card>
  );
};
