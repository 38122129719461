import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, Grid, useTheme } from "@toolkit/ui";
import { useProfileContext } from "../../context/ProfileContext";
import { OverviewInfo } from "./OverviewInfo";

export const BodyComposition = () => {
  const { t } = useTranslation("provider");

  const { patient } = useProfileContext();
  const non = "N/A";
  const dietType = patient?.bodyComposition?.dietType || non;
  const sleep = patient?.bodyComposition?.sleep || non;
  const smoking = patient?.bodyComposition?.smoking || non;
  const alcohol = patient?.bodyComposition?.alcohol;
  const exercise = patient?.bodyComposition?.exercise;
  const description = t("By Patient");
  const theme = useTheme();

  return (
    <Grid item container spacing={2}>
      <Grid item xs={6} sm={4} md={4} lg={4}>
        <OverviewInfo
          title={t("Diet")}
          subTitle={dietType.replaceAll("_", "").toLowerCase()}
          color={theme.palette.primary.main}
          endIcon='user3'
          description={description}
          icon={<CustomIcon icon='diet' color={theme.palette.common.white} width={22} height={22} viewBox='-2 0 26 20' />}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={2}>
        <OverviewInfo
          title={t("Smoking")}
          subTitle={smoking}
          color={theme.palette.error.main}
          endIcon='user3'
          description={description}
          icon={<CustomIcon icon='smoking' color={theme.palette.common.white} width={22} height={22} viewBox='-3 -2 28 20' />}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={2}>
        <OverviewInfo
          title={t("Alcohol")}
          subTitle={alcohol ? t("Yes") : t("No")}
          color={theme.palette.success.main}
          endIcon='user3'
          description={description}
          icon={<CustomIcon icon='bar' color={theme.palette.common.white} width={22} height={22} viewBox='2 0 22 22' />}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <OverviewInfo
          title={t("Sleep")}
          subTitle={sleep}
          color={theme.palette.success.main}
          endIcon='user3'
          description={description}
          icon={<CustomIcon icon='sleep' color={theme.palette.common.white} width={22} height={22} viewBox='2 0 22 22' />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={2}>
        <OverviewInfo
          title={t("Exercise")}
          subTitle={exercise ? t("Yes") : t("No")}
          color={theme.palette.success.main}
          endIcon='user3'
          description={description}
          icon={<CustomIcon icon='exercise' color={theme.palette.common.white} width={22} height={22} viewBox='0 0 22 22' />}
        />
      </Grid>
    </Grid>
  );
};
