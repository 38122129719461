var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Client, LocalStorage } from "@meetora/client-json";
import { MeetoraProvider as Provider } from "@meetora/react/client";
import { AuthorizeByToken } from "@meetora/schema/auth/AuthorizeByToken";
import { GetOAuthToken } from "@meetora/schema/auth/GetOAuthToken";
import { oAuthTypeSehacity } from "@meetora/schema/auth/OAuthType";
import React, { memo, useEffect, useMemo, useRef } from "react";
import { meetoraConfig } from "./meetoraConfig";
import { compareOAuthToken } from "./util";
export class MeetoraClient {
    static getInstance() {
        if (!MeetoraClient.client) {
            MeetoraClient.client = new Client({
                baseUrl: meetoraConfig.get("meetoraBaseUrl"),
                storage: new LocalStorage({ key: "health-im-authentication" }),
                debug: process.env.NODE_ENV !== "production",
            });
        }
        return MeetoraClient.client;
    }
    static logout() {
        var _a;
        return (_a = MeetoraClient.client) === null || _a === void 0 ? void 0 : _a.logout();
    }
}
MeetoraClient.client = null;
const MeetoraProvider = ({ token, children }) => {
    const unsubscribeRef = useRef();
    const client = MeetoraClient.getInstance();
    useMemo(() => {
        var _a;
        (_a = unsubscribeRef.current) === null || _a === void 0 ? void 0 : _a.call(unsubscribeRef);
        unsubscribeRef.current = undefined;
        if (!token) {
            return;
        }
        unsubscribeRef.current = client.onPreAuthorize(() => __awaiter(void 0, void 0, void 0, function* () {
            const oAuthToken = yield client.sendMessage(GetOAuthToken());
            if (oAuthToken._name === "auth.oAuthToken" && !compareOAuthToken(token, oAuthToken.token)) {
                yield client.logout();
            }
            return client.sendMessage(AuthorizeByToken({ token, authType: oAuthTypeSehacity() }));
        }));
    }, [token]);
    useEffect(() => {
        return () => {
            var _a;
            (_a = unsubscribeRef.current) === null || _a === void 0 ? void 0 : _a.call(unsubscribeRef);
            unsubscribeRef.current = undefined;
        };
    }, []);
    return React.createElement(Provider, { client: client }, children);
};
export default memo(MeetoraProvider);
