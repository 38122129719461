import { VendorDepartmentError, VendorDepartmentUpdateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { departmentEditBreadCrumb, departmentListBreadCrumb } from "../constants";
import { DepartmentQuery, VendorDepartmentUpdateMutation, useDepartmentQuery, useVendorDepartmentUpdateMutation } from "../gql";

export const useDepartmentEditPageHook = () => {
  const [errors, setErrors] = useState<VendorDepartmentError[]>([]);
  const { t } = useTranslation("provider");
  const { departmentId } = useParams();
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const [vendorDepartmentUpdateMutation, { loading: isUpdateLoading }] = useVendorDepartmentUpdateMutation({
    onCompleted: (mutationData: VendorDepartmentUpdateMutation) => {
      const error = mutationData?.vendorDepartmentUpdate?.vendorDepartmentErrors as VendorDepartmentError[];
      if (error && error?.length > 0) {
        failed(t(formatMessageErrors(error)));
        setErrors(error);
      } else {
        succeeded(t("Department updated successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed in updating department"));
    },
  });
  const { data, loading } = useDepartmentQuery({
    variables: {
      vendorDepartmentId: departmentId as string,
    },
    onCompleted: (dataQuery: DepartmentQuery) => {
      if (!dataQuery?.vendorDepartment) {
        failed(t("Department not found"));
      }
    },
  });
  const department = data?.vendorDepartment;
  const handleUpdateDepartment = (inputData: VendorDepartmentUpdateInput | any) => {
    delete inputData?.branch;
    vendorDepartmentUpdateMutation({
      variables: {
        vendorDepartmentUpdateId: departmentId as string,
        input: inputData,
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: "Departments",
      values: [departmentListBreadCrumb(t), departmentEditBreadCrumb(department?.name)],
    });
  }, [departmentId, department?.name]);

  return { errors, isUpdateLoading, department, loading, handleUpdateDepartment };
};
