import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { useMedicationsSectionStyles } from "../Medications.styles";

export const MedicationsInfo: FC<{ medication }> = ({ medication }) => {
  const { t } = useTranslation("provider");
  const { classes } = useMedicationsSectionStyles();

  return (
    <>
      <Typography className={classes.list}>
        {t("Instructions")} : {pickLocalizedValue(medication?.instructions, medication?.arabicInstructions) || "-"}
      </Typography>

      <Typography className={classes.list}>
        {t("Refills")} : {medication?.refills}
      </Typography>

      <Typography className={classes.list}>
        {t("Duration")} : {medication?.duration || "-"}
      </Typography>

      <Typography className={classes.list}>
        {t("ROA")} : {pickLocalizedValue(medication?.roaEnName, medication?.roaArName) || "-"}
      </Typography>

      <Typography className={classes.list}>
        {t("Code")} : {medication?.code}
      </Typography>
    </>
  );
};
