import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { healthPackagesManagementPaths } from "pages/HealthPackages/route";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { healthPackageListBreadCrumb, healthPackageNewBreadCrumb } from "../../constant";
import { useHealthPackageCreateMutation } from "../../gql";
import { formData } from "../type";

export const usePackageNewPageHook = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();

  const [createHealthPackage, { loading: isSaving }] = useHealthPackageCreateMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      succeeded(t("Health package created successfully"));
      navigate(healthPackagesManagementPaths.indexPage.fullPath);
    },
    onError: () => {
      failed(t("Failed in creating health package"));
    },
  });

  const handleCreatePackage = (data: formData) => {
    console.log(data);
    createHealthPackage({
      variables: {
        input: {
          description: data.description,
          descriptionAr: data.descriptionAr,
          shortDescription: data.shortDescription,
          shortDescriptionAr: data.shortDescriptionAr,
          mainImageUrl: encodeURI(data.mainImageUrl!),
          name: data.name,
          nameAr: data.nameAr,
          price: +data.price,
          targetGender: data?.targetGender?.value,
          targetFromAge: Number(data?.targetFromAge),
          targetToAge: Number(data?.targetToAge),
          categoryId: data?.categoryId?.value?.id,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: t("Health Packages Management"),
      values: [healthPackageListBreadCrumb(t), healthPackageNewBreadCrumb(t)],
    });
  }, []);

  return {
    isSaving: isSaving,
    handleCreatePackage,
  };
};
