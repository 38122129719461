import moment from "moment";

export function safeJsonParseWithDefault(jsonString: string, defaultValue: object) {
  try {
    const parsedJson = JSON.parse(jsonString);
    return parsedJson !== null && typeof parsedJson === "object" ? parsedJson : defaultValue;
  } catch (error) {
    return defaultValue;
  }
}

export const getRemainingTimeText = (momentDate: Date) => {
  const currentDate = moment();

  const durationDifference = moment.duration(moment(momentDate).diff(currentDate));
  const isMinus = durationDifference.hours() < 0 || durationDifference.minutes() < 0 || durationDifference.seconds() < 0;

  const remainingHours = String(Math.abs(durationDifference.hours())).padStart(2, "0");
  const remainingMinutes = String(Math.abs(durationDifference.minutes())).padStart(2, "0");
  const remainingSeconds = String(Math.abs(durationDifference.seconds())).padStart(2, "0");

  const text = `${remainingHours}:${remainingMinutes}:${remainingSeconds}`;

  return isMinus ? "00:00:00" : text;
};
