import { vendorUserTypeOptions, vendorUserTypeOptionsMap } from "@health/enum-options";
import { AccountError, AppRoleTypes, User, VendorUserTypes } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, FormControlLabel, FormPhoneInput, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AutocompleteAppComponent } from "./CustomAutocompleteComponent";
import { userFields } from "./UserInformation.utils";

export const UserGeneralInformation: FC<{
  errors: AccountError[];
  defaultValues?: User;
  isEdit?: boolean;
}> = ({ errors, defaultValues, isEdit }) => {
  const {
    register,
    formState: { errors: reactFormErrors },
    control,
    watch,
    setValue,
  } = useFormContext();
  const combinedErrors = combineErrors(reactFormErrors, errors);
  const { t } = useTranslation("provider");
  const appRoleValue = watch("appRole");
  const appRole = Object?.keys(AppRoleTypes)?.map(key => ({ value: AppRoleTypes[key], name: key }));
  const appRoleDefaultValue = appRole?.find(el => el?.value === defaultValues?.appRole) || appRoleValue || null;
  const vendorUserDefaultValue = vendorUserTypeOptionsMap?.[defaultValues?.vendorUserType || ""] || undefined;
  const watchVendorUserType = watch("vendorUserType");
  const filteredAppRole =
    watchVendorUserType?.value === VendorUserTypes.Manager || vendorUserDefaultValue?.value === VendorUserTypes.Manager
      ? appRole.filter(role => role.value != AppRoleTypes.User)
      : appRole;

  useEffect(() => {
    setValue("mobile", defaultValues?.mobile);
  }, [defaultValues?.mobile, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      {userFields(t, defaultValues, isEdit)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item?.name}>
          <TextField
            inputProps={{
              autoComplete: item?.autoComplete ?? "off",
            }}
            fullWidth
            error={Boolean(combinedErrors?.[item?.name]?.message)}
            helperText={t(combinedErrors?.[item?.name]?.message)}
            defaultValue={item?.defaultValue}
            label={item?.label}
            placeholder={item?.label}
            autoComplete='off'
            {...register(item?.name, {
              required: { value: item?.isRequired as boolean, message: (item?.isRequired && t("Required")) as any },
              minLength: item?.minLength,
              maxLength: item?.maxLength,
              pattern: item?.pattern as any,
            })}
            type={item?.type}
            disabled={item?.disabled}
          />
        </Grid>
      ))}

      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register("mobile", {
            required: getRequiredValidation(t, true),
          })}
          label={t("Mobile Number")}
          fullWidth
          error={Boolean(combinedErrors?.mobile?.message)}
          helperText={t(combinedErrors?.mobile?.message)}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <AutocompleteAppComponent
          formErrors={errors}
          name={"appRole"}
          label={t("App Role")}
          options={filteredAppRole}
          defaultAppValue={appRoleDefaultValue}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <AutocompleteAppComponent
          formErrors={errors}
          name={"vendorUserType"}
          label={t("Vendor User type")}
          options={vendorUserTypeOptions}
          defaultAppValue={vendorUserDefaultValue}
          isDisabled={isEdit}
        />
      </Grid>

      <Grid container columnSpacing={formGirdSpacing} item alignItems='center'>
        <Grid item {...formGirdBreakPoints}>
          <Controller
            name='isActive'
            defaultValue={isEdit ? defaultValues?.isActive : true}
            control={control}
            render={({ field: { onChange: handleC, value } }) => (
              <FormControlLabel
                control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                label={t("Is Active")}
              />
            )}
          />
        </Grid>
        {(watchVendorUserType?.value == VendorUserTypes.Doctor || vendorUserDefaultValue?.value == VendorUserTypes.Doctor) && (
          <>
            <Grid item {...formGirdBreakPoints}>
              <Controller
                name='doctorInfo.isMobileNumberPublic'
                defaultValue={isEdit ? defaultValues?.doctor?.isMobileNumberPublic : true}
                control={control}
                render={({ field: { onChange: handleC, value } }) => (
                  <FormControlLabel
                    control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                    label={t("Is Mobile Number Public")}
                  />
                )}
              />
            </Grid>

            <Grid item {...formGirdBreakPoints}>
              <Controller
                name='doctorInfo.isNationalIdPublic'
                defaultValue={isEdit ? defaultValues?.doctor?.isNationalIdPublic : true}
                control={control}
                render={({ field: { onChange: handleC, value } }) => (
                  <FormControlLabel
                    control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                    label={t("Is National Id Public")}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

UserGeneralInformation.defaultProps = {
  defaultValues: undefined,
  isEdit: false,
};
