import { makeStyles } from "@toolkit/ui";

export const useHeaderCardStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    fontSize: 13,
    whiteSpace: "nowrap",
    overflow: "hidden !important",
  },
  text: { color: color },
  total: {
    color: theme.palette.stale.main,
  },
}));
