import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { OrderDirection, UserSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomTableColumnProps, TruncateTypography, format } from "@toolkit/ui";
import { useMemo } from "react";
import { GetAllVendorUsersQuery } from "../../gql";
import { getAppRoleTypeFilter } from "@health/enum-options";
export type AllDoctorsListColumnsTypes = CustomTableColumnProps<ConnectionDocumentNode<GetAllVendorUsersQuery, "users">>[];

export const useAllDoctorsListColumns = (): AllDoctorsListColumnsTypes => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "App Role",
        header: t("App Role"),
        showOnlyForFilterField: true,
        filter: getAppRoleTypeFilter({ name: "appRole" }),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branchId" }),
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
      {
        key: "dateJoinedTo",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "firstName",
        header: t("First Name"),
        accessor: row => <TruncateTypography text={row?.firstName!} />,
        isSortable: true,
        sortColumnEnum: UserSortField.FirstName,
        sortDirection: OrderDirection.Desc,
      },
      {
        key: "lastName",
        header: t("Last Name"),
        accessor: row => <TruncateTypography text={row?.lastName!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.LastName,
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.Email,
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
      },
      {
        key: "isSuperUser",
        header: t("Is Super User"),
        accessor: row => (row?.isSuperuser === true ? t("Yes") : t("No")),
      },
      {
        key: "dateJoined",
        header: t("Date Joined"),
        accessor: row => format(row?.dateJoined, "DD MMM YYYY"),
        isHidden: false,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.DateJoined,
      },
    ];
  }, []);
};
