import { VisitStatus } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";

export type StatusEnums = VisitStatus.Canceled | VisitStatus.CanceledByConsumer | VisitStatus.EndedByOneOfTheParticipants;

const CanceledDisplay = i18n.t("Call Cancelled");
export const visitStatusMapper: Record<StatusEnums, { display: string; color: string }> = {
  [VisitStatus.Canceled]: { display: CanceledDisplay, color: "#913263" },
  [VisitStatus.CanceledByConsumer]: { display: CanceledDisplay, color: "#913263" },
  [VisitStatus.EndedByOneOfTheParticipants]: { display: i18n.t("Call Terminated"), color: "#913263" },
};
