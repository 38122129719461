import { useTranslation } from "@toolkit/i18n";
import { Box, Card, ErrorIcon, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const TranscriptionFailed: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: "400px",
        backgroundColor: theme.palette.common.white,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", mb: 1 }}>
        <ErrorIcon fill={theme.palette.error.main} sx={{ width: 40, height: 40 }} />
      </Box>
      <Typography textAlign={"center"} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
        {t("Failed to generate transcript")}
      </Typography>
      <Typography textAlign={"center"}>{t("Please contact support team")}</Typography>
    </Card>
  );
};
