import { makeStyles } from "@toolkit/ui";

export const useNotificationContentStyle = makeStyles<{ color?: string }>()((theme, { color }) => ({
  icon: {
    backgroundColor: color,
    width: "35px",
    height: "35px",
    border: "2px solid",
    borderColor: color,
    padding: "6px",
    borderRadius: "10px",
    marginLeft: 14,
    marginTop: 14,
    "& svg": {
      padding: "2px",
    },
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: `${theme.mixins.fonts.fontWeight.medium}!important`,
    wordWrap: "break-word",
    paddingLeft: 9,
    marginTop: 8,
  },
}));
