import { VendorUserTypes } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { healthProviderUserManagementPaths } from "../constants/HealthProvidersManagementPaths";

export const userManagementListBreadCrumb = (t: TFunction) => {
  return {
    id: healthProviderUserManagementPaths.indexPage.fullPath,
    name: t("Users", { ns: "provider" }),
    route: healthProviderUserManagementPaths.indexPage.fullPath,
  };
};

export const userManagementNewBreadCrumb = (t: TFunction) => {
  return {
    id: healthProviderUserManagementPaths.addNew.fullPath,
    name: t("New", { ns: "provider" }),
    // route: healthProviderUserManagementPaths.addNew.fullPath
  };
};

export const userManagementEditBreadCrumb = (userName: string | null | undefined) => {
  return {
    id: healthProviderUserManagementPaths.edit.fullPath,
    name: userName || "",
    // route: healthProviderUserManagementPaths.edit.fullPath.replace(":id", userId)
  };
};

export const hasHealthLicense = (vendorUserType: VendorUserTypes | undefined): boolean => {
  switch (vendorUserType) {
    case VendorUserTypes.Doctor:
    case VendorUserTypes.Pharmacist:
    case VendorUserTypes.Nurse:
    case VendorUserTypes.DentalHygienist:
    case VendorUserTypes.FitnessCoach:
    case VendorUserTypes.DiabetesEducator:
    case VendorUserTypes.Nutritionist:
    case VendorUserTypes.Optometrist:
    case VendorUserTypes.PodiatricMedicalAssistant:
    case VendorUserTypes.Psychologist:
    case VendorUserTypes.SocialWorker:
      return true;
    case VendorUserTypes.Manager:
    case VendorUserTypes.Receptionist:
      return false;
    default:
      return false;
  }
};
