import { z } from "zod";
import { zodSchema } from "@health/domains";
import { guidedCareProgramMemberRiskFactorParametersFormSchema } from "../GuidedCareProgramMemberRiskFactorParameters/GuidedCareProgramMemberRiskFactorParametersFormSchema";
import { i18n } from "@toolkit/i18n";

export const guidedCareProgramMemberAddFormSchema = z
  .object({
    program: zodSchema.guidedCareProgramAutocomplete,
    riskFactorTemplateFields: z.array(guidedCareProgramMemberRiskFactorParametersFormSchema),
    suggestedProgram: zodSchema.guidedCareProgramAutocompleteOptional,
    evaluationResult: z
      .object({
        level: z.string().nullable().optional(),
        suggestedPrograms: z.array(zodSchema.guidedCareProgramAutocomplete).nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.evaluationResult && data.evaluationResult.suggestedPrograms?.length && !data.suggestedProgram) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "provider" }),
        path: ["suggestedProgram"],
      });
    }
  });

export type IGuidedCareProgramMemberAddFormValues = z.infer<typeof guidedCareProgramMemberAddFormSchema>;

export const guidedCareProgramMemberAddFormDefaultValues: Partial<IGuidedCareProgramMemberAddFormValues> = {
  program: undefined,
  suggestedProgram: undefined,
  evaluationResult: undefined,
  riskFactorTemplateFields: [],
};
