import { useCallback } from "react";
import { EVENT_TOPIC, useSubscribeToNotificationTopic } from "../NotificationsServiceProvider";
export const useOnPatientProfileAccessResponse = (handler) => {
    const onNotificationChange = useCallback(data => {
        handler(data);
    }, []);
    useSubscribeToNotificationTopic(EVENT_TOPIC.PATIENT_PROFILE_ACCESS_RESPONSE, onNotificationChange);
};
export const useOnPatientProfileAccessRequest = (handler) => {
    const onNotificationChange = useCallback(data => {
        handler(data);
    }, []);
    useSubscribeToNotificationTopic(EVENT_TOPIC.PATIENT_PROFILE_ACCESS_REQUEST, onNotificationChange);
};
export const usePatientProfileAccessClose = (handler) => {
    const onNotificationChange = useCallback(data => {
        handler(data);
    }, []);
    useSubscribeToNotificationTopic(EVENT_TOPIC.PATIENT_PROFILE_ACCESS_CLOSE, onNotificationChange);
};
