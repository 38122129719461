import { useReactiveVar } from "@apollo/client";
import { isNotificationMenuOpenedVar } from "@toolkit/ui";
import { useVisitQuery } from "./gql";

export const useVisitNotificationHook = (id, isDisplayedInDropDown) => {
  const isOpen = useReactiveVar(isNotificationMenuOpenedVar);
  const skipIfDropdownIsClosed = isDisplayedInDropDown ? !isOpen : false;
  const { data, loading } = useVisitQuery({
    variables: {
      visitId: id,
    },
    skip: !id || skipIfDropdownIsClosed,
  });

  const visit = data?.visit;

  return {
    loading,
    visit,
  };
};
