/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type AppointmentsForNotificationPanelFragment = { __typename: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, requestedTimeSlots?: Array<string | null> | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, created?: any | null, cancellationReason?: string | null, suggestedTimeSlots?: Array<string | null> | null, paymentStatus?: Types.PaymentStatus | null, visit?: { __typename?: 'Visit', id: string, number?: string | null, meetingPlatformLinkForJoinCall?: string | null, meetingPlatformLinkForChatView?: string | null } | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', currency?: string | null, totalAmount?: number | null, type?: Types.AppointmentPaymentType | null } | null, consumer?: { __typename?: 'Patient', id: string, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, insuranceId?: string | null, membershipEnd?: any | null, payer?: { __typename?: 'Payer', name: string } | null } | null> | null, healthProgramsByActiveMembers?: Array<{ __typename?: 'HealthProgram', insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', id: string, name?: string | null } }> } | null } | null> | null } | null, doctor?: { __typename?: 'Doctor', user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, photo?: string | null } | null } | null };

export const AppointmentsForNotificationPanelFragmentDoc = gql`
    fragment AppointmentsForNotificationPanel on Appointment {
  __typename
  id
  type
  status
  requestedTimeSlots
  confirmedStartTime
  confirmedEndTime
  created
  visit {
    id
    number
    meetingPlatformLinkForJoinCall
    meetingPlatformLinkForChatView
  }
  cancellationReason
  suggestedTimeSlots
  paymentStatus
  paymentInfo {
    currency
    totalAmount
    type
  }
  consumer {
    id
    activeHealthProgramMembers {
      id
      insuranceId
      payer {
        name
      }
      membershipEnd
    }
    healthProgramsByActiveMembers {
      insuranceNetworks(first: 1) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
  doctor {
    user {
      firstName
      lastName
      photo
    }
  }
}
    `;