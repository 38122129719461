import { Rad } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useForm } from "react-hook-form";
import { getApolloContextFormToken } from "shared/utils";
import { useGetRadiologyListQuery, useRadiologyCreateMutation, useRadiologyDeleteMutation } from "../gql";

export const useRadiology = ({ visitId, token }: { visitId: string; token?: string }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const methods = useForm({
    mode: "onChange",
  });
  const {
    refetch,
    data: radologyHistorydata,
    loading: isLoading,
  } = useGetRadiologyListQuery({
    context: getApolloContextFormToken(token),
    variables: {
      id: visitId as string,
    },
    skip: !visitId,
  });

  const { errors } = methods.formState;
  const [radCreate, { loading: isSubmittingCreate }] = useRadiologyCreateMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const _errors = data?.radiologyCreate?.radiologyErrors;
      if (_errors) {
        if (_errors?.length === 0) {
          succeeded(t("labCreateSuccessfully", "Lab Create Successfully"));
          refetch();
        } else if (_errors?.length > 0) {
          const message = _errors
            .map(error => {
              return `${error.field || ""},\r\n ${error.message || ""}\r\n`;
            })
            .join("\n");
          failed(message);
        }
      } else {
        succeeded(t("labCreateSuccessfully", "Lab Create Successfully"));
        refetch();
      }
    },
    onError: () => {
      failed(t("labCreateFailed", "Lab Create Failed"));
    },
  });

  const [radDelete, { loading: isDeleting }] = useRadiologyDeleteMutation({
    onCompleted: data => {
      const _errors = data?.radDelete?.visitSummaryErrors;
      if (_errors) {
        if (_errors?.length === 0) {
          succeeded(t("radDeleteSuccessfully", "Rad Delete Successfully"));
          refetch();
        } else if (_errors?.length > 0) {
          const message = _errors
            .map(error => {
              return `${error.field || ""},\r\n ${error.message || ""}\r\n`;
            })
            .join("\n");
          failed(message);
        }
      } else {
        succeeded(t("radDeleteSuccessfully", "Rad Delete Successfully"));
        refetch();
      }
    },
    onError: () => {
      failed(t("radDeleteFailed", "Rad Delete Failed"));
    },
    context: getApolloContextFormToken(token),
  });

  const handleCreate = () => {
    const radiology = methods.watch("radiology");
    {
      visitId &&
        radCreate({
          variables: {
            input: {
              visitId: visitId as string,
              code: radiology?.code,
              conceptId: radiology?.code,
            } as any,
          },
        });
    }
  };

  const handleDelete = row => {
    radDelete({
      variables: {
        id: row?.id,
      } as any,
    });
  };

  const handleOnSort = () => {
    console.error("handleOnSort Methods not implemented ");
  };

  return {
    errors,
    methods,
    isLoading,
    isDeleting,
    radologyHistory: (radologyHistorydata?.visit?.rads as Rad[]) ?? [],
    isSubmittingCreate,
    handleOnSort,
    handleCreate,
    handleDelete,
  };
};
