import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UploadModal } from "./UploadModal";
import { CustomTable, FormCard, Grid } from "@toolkit/ui";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useChatFlowDocsQuery } from "../../../AIAgents/gql";
import { useParams } from "react-router-dom";
import { useChatFlowDocsColumns } from "./useChatFlowDocsColumns";
export const KnowledgeBaseForm = () => {
    var _a;
    const { t } = useTranslation("domains");
    const { agentId } = useParams();
    const columns = useChatFlowDocsColumns();
    const { data, loading: isLoading, refetch, } = useChatFlowDocsQuery({
        variables: {
            chatFlowId: agentId,
        },
    });
    const documents = ((_a = data === null || data === void 0 ? void 0 : data.chatFlow) === null || _a === void 0 ? void 0 : _a.documents) || [];
    return (_jsx(Grid, { item: true, xs: 12, children: _jsxs(FormCard, { title: t("Knowledge Base"), loading: isLoading, doYouHaveData: true, children: [_jsx(Grid, { container: true, spacing: formGirdSpacing, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(UploadModal, { refetch: refetch }) }) }), _jsx(Grid, { container: true, spacing: formGirdSpacing, marginTop: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(CustomTable, { TableContainerProps: {
                                sx: {
                                    height: 298,
                                },
                            }, columns: columns, data: documents, withoutDataMessage: t("No Knowledge Base Documents"), emptyIconHeight: 70, iconBoxProps: {
                                marginBottom: "15px",
                                height: "70px",
                            }, pageSize: 10, isLoading: isLoading, pageIndex: 10, pagesCount: 10 }) }) })] }) }));
};
