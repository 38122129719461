import { TFunction } from "@toolkit/i18n";
import { healthProviderGuidedCareTeamsPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";

export const createGuidedCareTeamBreadcrumbs = (t: TFunction) => ({
  title: t("Care Teams"),
  main: {
    id: "team-list",
    name: t("Teams", { ns: "provider" }),
    route: healthProviderGuidedCareTeamsPaths.main.fullPath,
  },
  create: {
    id: "team-new",
    name: t("New", { ns: "provider" }),
  },
  field: (fieldName: string) => ({
    id: `team-edit-${fieldName}`,
    name: fieldName,
  }),
});
