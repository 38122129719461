import { Department } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useDepartmentColumns } from "./components/DepartmentColumns";
import { useDepartmentsQuery, useVendorDepartmentDeleteMutation } from "./gql";

import { OrderDirection, VendorDepartmentOrderField } from "@health/queries/types";
import { healthProviderDepartmentsPaths } from "../constants/HealthProvidersManagementPaths";

export const DepartmentsList: FC = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const handleOnAddItem = () => {
    navigate(healthProviderDepartmentsPaths.addNew.fullPath);
  };
  const handleOnEditRow = (department: Department) => {
    navigate(healthProviderDepartmentsPaths.edit.fullPathWithParams({ departmentId: department?.id }));
  };

  useEffect(() => {
    setBreadCrumb({ title: t("Departments"), values: [] });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='departments'
      columns={useDepartmentColumns()}
      query={useDepartmentsQuery}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleOnEditRow,
        isDeleteVisible: true,
      }}
      deleteItemProps={{
        name: "Department",
        entityTypeName: "Department",
        useDeleteMutation: useVendorDepartmentDeleteMutation,
      }}
      variables={{
        sortBy: {
          direction: OrderDirection.Asc,
          field: VendorDepartmentOrderField.Name,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleOnAddItem} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
