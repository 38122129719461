import { ReferralGraphqlError, ReferralRequestInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { combineErrors, toPascalCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import React from "react";
import { useForm } from "react-hook-form";
import { getApolloContextFormToken } from "shared/utils";
import { GeneratePatientReferralByClinicianMutation, useGeneratePatientReferralByClinicianMutation, useVisitDiagnosesQuery } from "../gql";

export const useReferralsPageHook = ({ visitId, token }: { visitId; token?: string }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [created, setCreated] = React.useState(false);
  const [errors, setErrors] = React.useState<ReferralGraphqlError[] | undefined>();
  const methods = useForm({
    mode: "onChange",
  });

  const [createPatientReferralByClinician, { loading: isSubmitting }] = useGeneratePatientReferralByClinicianMutation({
    context: getApolloContextFormToken(token),
    onCompleted: (data: GeneratePatientReferralByClinicianMutation) => {
      const patientReferralByClinicianErrors = data?.generatePatientReferralByClinician?.errors;
      if (patientReferralByClinicianErrors && patientReferralByClinicianErrors?.length > 0) {
        failed(formatMessageErrors(patientReferralByClinicianErrors));
        setErrors([formatMessageErrors(patientReferralByClinicianErrors)] as ReferralGraphqlError[]);
      } else {
        succeeded(t("Patient referral by clinician added Successfully"));
        setCreated(true);
      }
      methods.reset();
      handleResetForm();
    },
    onError: error => {
      failed(t(error?.message || "Failed in adding patient referral by clinician"));
    },
  });

  const handleCreateReferral = (data: ReferralRequestInput | any) => {
    setCreated(false);
    const diagnosis =
      visit?.visit?.visitDiagnoses?.map(item => ({
        type: toPascalCase(item?.visitDiagnosisType?.toLowerCase() ?? ""), // map diagnosis types
        code: item?.code!,
      })) ?? [];

    createPatientReferralByClinician({
      variables: {
        newReferral: {
          visitId: visitId as string,
          observation: data?.observation,
          specialty: data?.specialty?.value?.code,
          diagnosis: diagnosis as any,
        },
      },
    });
  };

  const { data: visit } = useVisitDiagnosesQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
    skip: !visitId,
  });

  const combinedErrors = combineErrors(methods.formState.errors, errors);

  const handleResetForm = () => {
    methods.resetField("specialty");
  };

  return {
    combinedErrors,
    methods,
    isSubmitting,
    handleCreateReferral,
    created,
    handleResetForm,
  };
};
