import { i18n } from "@toolkit/i18n";
import { providerTranslationNamespace } from "utils";
import { discountsPaths } from "./DiscountsPaths";

export const discountsBreadcrumb = () => ({
  title: i18n.t("Marketplace Discounts", providerTranslationNamespace),
  main: {
    id: "discounts",
    name: i18n.t("Marketplace Discounts", providerTranslationNamespace),
    path: discountsPaths.main.fullPath,
  },
  create: {
    id: "discount-new",
    name: i18n.t("New", providerTranslationNamespace),
  },
  update: (fieldName: string) => ({
    id: "discount-update",
    name: fieldName || i18n.t("Update", providerTranslationNamespace),
  }),
});
