/* eslint-disable max-lines */
import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { PromotionCreateContainer, PromotionsList, PromotionUpdateContainer } from "../containers";
import { promotionsPaths, promotionsRoute } from "./PromotionsPaths";
export const promotionsRoutes: (props: { navigate: NavigateFunction; t: TFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  t,
  enabled,
}) => {
  return {
    id: "marketplace-product-promotions",
    text: t("Promotions"),
    route: promotionsRoute,
    hidden: !enabled || !hasPermission(PermissionEnum.ManagePromotions),
    isProhibited: !enabled || !hasPermission(PermissionEnum.ManagePromotions),
    subItems: [
      {
        id: "promotions-list",
        text: t("Promotions"),
        route: promotionsPaths.main.route,
        fullPath: promotionsPaths.main.fullPath,
        element: <PromotionsList />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "promotions-new",
        text: t("New"),
        element: <PromotionCreateContainer />,
        route: promotionsPaths.new.route,
        fullPath: promotionsPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "promotions-update",
        text: t("Update"),
        element: <PromotionUpdateContainer />,
        route: promotionsPaths.update.route,
        fullPath: promotionsPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
