import { sourceOptionsMap } from "@health/enum-options";
import { PatientHealthParameter, PatientHpVitalSignsChangePercentage } from "@health/queries/types";
import { keyBy } from "lodash";
import moment from "moment";
import { IVitalSignChartData } from "shared/modules/patient/components/VitalSigns/types";
import { healthParameterCodes } from "pages/PatientHealthParameters/constants";

export const createPatientHealthParameterItem = (
  patientHealthParameters: PatientHealthParameter[],
  unit: string
): IVitalSignChartData[] => {
  const sortedItems = patientHealthParameters?.sort((a, b) => moment(a?.createdDate).diff(moment(b?.createdDate)));

  return sortedItems?.map((item, index) => ({
    ...item,
    value: item?.valueNumber!,
    unit: unit,
    date: moment(item?.createdDate).format("DD MMM YYYY HH:mm"),
    source: sourceOptionsMap[item?.source!]?.label!,
    currentData: index + 1 === patientHealthParameters?.length || false,
  }));
};

export const getPatientHealthParameterVitalSignsChangePercentage = (vitalSignsChangePercentage: PatientHpVitalSignsChangePercentage[]) => {
  if (!vitalSignsChangePercentage || !vitalSignsChangePercentage.length) return;

  const visitVitalSignsChangePercentageMap = keyBy(vitalSignsChangePercentage, "hpCode");

  return {
    bloodPressureLow: visitVitalSignsChangePercentageMap[healthParameterCodes.bloodPressureLow]?.changePercentage,
    bloodPressureHigh: visitVitalSignsChangePercentageMap[healthParameterCodes.bloodPressureHigh]?.changePercentage,
    temperature: visitVitalSignsChangePercentageMap[healthParameterCodes.temperature]?.changePercentage,
    oxygenSaturation: visitVitalSignsChangePercentageMap[healthParameterCodes.oxygenSaturation]?.changePercentage,
    respiratoryRate: visitVitalSignsChangePercentageMap[healthParameterCodes.respiratoryRate]?.changePercentage,
    heartRate: visitVitalSignsChangePercentageMap[healthParameterCodes.heartRate]?.changePercentage,
  };
};
