import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const RobotIcon: FC<SvgIconProps> = props => {
  const { width = "23.244", height = "23.244", fill = "#25287f" } = props;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 23.244 23.244'>
      <path
        id='icons8-message-bot'
        d='M8.257,24.35V21.668H5.575A1.786,1.786,0,0,1,3.787,19.88V16.3h0A1.785,1.785,0,0,1,2,14.516V12.728A1.786,1.786,0,0,1,3.785,10.94V8.258A1.786,1.786,0,0,1,5.574,6.47h7.152V5.337a1.788,1.788,0,1,1,1.79,0V6.47h7.151a1.787,1.787,0,0,1,1.788,1.788V10.94a1.789,1.789,0,0,1,1.79,1.788v1.788A1.787,1.787,0,0,1,23.456,16.3V19.88a1.787,1.787,0,0,1-1.788,1.788h-9.39L9.9,24.839a.894.894,0,0,1-1.642-.489ZM16.3,15.41H10.936a2.908,2.908,0,0,0,5.367,0Zm-4.47-3.135a1.341,1.341,0,1,0-1.341,1.347A1.337,1.337,0,0,0,11.833,12.275Zm6.258,0a1.341,1.341,0,1,0-1.341,1.347A1.337,1.337,0,0,0,18.091,12.275Z'
        transform='translate(-2 -2)'
        fill={fill}
      />
    </SvgIcon>
  );
};
