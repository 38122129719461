import { Box, CustomIcon, DatePicker, LocalizationProvider, MomentUtils, TextField, Typography } from "@toolkit/ui";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Moment } from "moment/moment";
import { useGuidedCareJourneyInterventionActivitiesItemsStartDateStyle } from "./useGuidedCareJourneyInterventionActivitiesItemsStartDateStyle";

type GuidedCareJourneyInterventionActivitiesItemsStartDateProps = {
  value: Moment | null;
  onChange: (value: Moment | null) => void;
};

export const GuidedCareJourneyInterventionActivitiesItemsStartDate: FC<
  GuidedCareJourneyInterventionActivitiesItemsStartDateProps
> = props => {
  const { value, onChange } = props;

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareJourneyInterventionActivitiesItemsStartDateStyle();

  return (
    <Box>
      <Typography> {t("Start Date")} </Typography>

      <LocalizationProvider dateAdapter={MomentUtils}>
        <DatePicker
          label={t("Date")}
          value={value}
          minDate={moment()}
          components={{
            OpenPickerIcon: () => <CustomIcon icon={"calender"} width={25} height={25} />,
          }}
          onChange={onChange}
          renderInput={params => (
            <TextField
              fullWidth
              {...params}
              InputProps={{
                ...params.InputProps,
                classes: {
                  input: classes.input,
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
