/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorDepartmentUpdateMutationVariables = Types.Exact<{
  vendorDepartmentUpdateId: Types.Scalars['ID'];
  input: Types.VendorDepartmentUpdateInput;
}>;


export type VendorDepartmentUpdateMutation = { __typename?: 'Mutation', vendorDepartmentUpdate?: { __typename?: 'VendorDepartmentUpdate', vendorDepartmentErrors: Array<{ __typename?: 'VendorDepartmentError', code: Types.VendorErrorCode, field?: string | null, message?: string | null }>, department?: { __typename?: 'Department', id: string, description?: string | null, code: string, directions?: string | null, name: string, branch: { __typename?: 'Branch', id: string, name: string }, medicalServices?: { __typename?: 'DepartmentMedicalServiceCountableConnection', edges: Array<{ __typename?: 'DepartmentMedicalServiceCountableEdge', node: { __typename?: 'DepartmentMedicalService', code: string, id: string } }> } | null } | null } | null };


export const VendorDepartmentUpdateDocument = gql`
    mutation VendorDepartmentUpdate($vendorDepartmentUpdateId: ID!, $input: VendorDepartmentUpdateInput!) {
  vendorDepartmentUpdate(id: $vendorDepartmentUpdateId, input: $input) {
    vendorDepartmentErrors {
      code
      field
      message
    }
    department {
      id
      description
      code
      directions
      name
      branch {
        id
        name
      }
      medicalServices(first: 100) {
        edges {
          node {
            code
            id
          }
        }
      }
    }
  }
}
    `;
export type VendorDepartmentUpdateMutationFn = Apollo.MutationFunction<VendorDepartmentUpdateMutation, VendorDepartmentUpdateMutationVariables>;

/**
 * __useVendorDepartmentUpdateMutation__
 *
 * To run a mutation, you first call `useVendorDepartmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorDepartmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorDepartmentUpdateMutation, { data, loading, error }] = useVendorDepartmentUpdateMutation({
 *   variables: {
 *      vendorDepartmentUpdateId: // value for 'vendorDepartmentUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorDepartmentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VendorDepartmentUpdateMutation, VendorDepartmentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorDepartmentUpdateMutation, VendorDepartmentUpdateMutationVariables>(VendorDepartmentUpdateDocument, options);
      }
export type VendorDepartmentUpdateMutationHookResult = ReturnType<typeof useVendorDepartmentUpdateMutation>;
export type VendorDepartmentUpdateMutationResult = Apollo.MutationResult<VendorDepartmentUpdateMutation>;
export type VendorDepartmentUpdateMutationOptions = Apollo.BaseMutationOptions<VendorDepartmentUpdateMutation, VendorDepartmentUpdateMutationVariables>;