import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  doctorListRoot: {
    top: 30,
    height: "min-content",
    position: "sticky",
    [theme.breakpoints.down("md")]: {
      position: "initial",
      top: "initial",
    },
  },
}));
