import { OrderStatus } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { OrderColorEnum } from "../cards/OrdersCard";

export const getTitle = (t: TFunction) => ({
  [OrderStatus.ErxHubRejected]: t("Rejected", { ns: "provider" }),
  [OrderStatus.ErxHubFullyApproved]: t("Approved", { ns: "provider" }),
  [OrderStatus.WaitingErxHubApproval]: t("Pending Approval", { ns: "provider" }),
  [OrderStatus.InProgress]: t("Progress", { ns: "provider" }),
  [OrderStatus.ErxHubPartiallyApproved]: t("Partially Approved", { ns: "provider" }),
  [OrderStatus.ErxHubTimedOut]: t("Order Timed Out", { ns: "provider" }),
  [OrderStatus.ErxHubClaimApproved]: t("Claim Approved", { ns: "provider" }),
  [OrderStatus.WaitingErxHubClaimApproval]: t("Waiting Claim Approval", { ns: "provider" }),
  [OrderStatus.ErxHubClaimRejected]: t("Claim Rejected", { ns: "provider" }),
  [OrderStatus.ErxHubClaimFailed]: t("Claim Failed", { ns: "provider" }),
  [OrderStatus.ErxHubAuthRequestFailed]: t("Auth Request Failed", { ns: "provider" }),
  [OrderStatus.ReadyForCustomerPickup]: t("Ready For Customer Pickup", { ns: "provider" }),
  [OrderStatus.ErxHubClaimSubmitTimedOut]: t("Claim Submit Timed Out", { ns: "provider" }),
  [OrderStatus.ErxHubAuthSubmitTimedOut]: t("Auth Submit Timed Out", { ns: "provider" }),
  [OrderStatus.AcceptedByProvider]: t("Pending Allocation", { ns: "provider" }),
  [OrderStatus.Allocated]: t("Allocated", { ns: "provider" }),
  [OrderStatus.PaymentPending]: t("Payment Pending", { ns: "provider" }),
  [OrderStatus.PaymentSucceeded]: t("Payment Succeeded", { ns: "provider" }),
  [OrderStatus.PaymentFailed]: t("Payment Failed", { ns: "provider" }),
  [OrderStatus.PaymentTimedOut]: t("Payment Timed Out", { ns: "provider" }),
  [OrderStatus.CanceledByConsumer]: t("Canceled By Consumer", { ns: "provider" }),
  [OrderStatus.PaymentOnArrive]: t("Payment On Arrive", { ns: "provider" }),
  // Delivering
  [OrderStatus.Delivered]: t("Delivered", { ns: "provider" }),
  [OrderStatus.Dispensed]: t("Dispensed", { ns: "provider" }),
  [OrderStatus.OutForDelivery]: t("On The Way", { ns: "provider" }),
});

export const shouldShowResubmitButton = (status: string): boolean => {
  return (
    status === OrderStatus.ErxHubPartiallyApproved ||
    status === OrderStatus.ErxHubTimedOut ||
    status === OrderStatus.ErxHubRejected ||
    status === OrderStatus.ErxHubAuthRequestFailed ||
    status === OrderStatus.ErxHubClaimSubmitTimedOut ||
    status === OrderStatus.ErxHubAuthSubmitTimedOut ||
    status === OrderStatus.ErxHubClaimSubmitTimedOut
  );
};
export const shouldShowCancelButton = (status: string): boolean => {
  return (
    status === OrderStatus.InProgress ||
    status === OrderStatus.WaitingErxHubApproval ||
    status === OrderStatus.ErxHubFullyApproved ||
    status === OrderStatus.ErxHubPartiallyApproved ||
    status === OrderStatus.ErxHubRejected ||
    status === OrderStatus.ErxHubTimedOut ||
    status === OrderStatus.ErxHubAuthRequestFailed ||
    status === OrderStatus.ErxHubClaimFailed ||
    status === OrderStatus.ErxHubClaimSubmitTimedOut
  );
};

export const getColorFromStatus = status => {
  if (
    status === OrderStatus.ErxHubRejected ||
    status === OrderStatus.ErxHubAuthRequestFailed ||
    status === OrderStatus.ErxHubClaimFailed ||
    status === OrderStatus.ErxHubTimedOut ||
    status === OrderStatus.ErxHubClaimSubmitTimedOut ||
    status === OrderStatus.ErxHubAuthSubmitTimedOut ||
    status === OrderStatus.PaymentFailed ||
    status === OrderStatus.PaymentTimedOut ||
    status === OrderStatus.CanceledByConsumer
  ) {
    return OrderColorEnum.error;
  } else if (status === OrderStatus.AcceptedByProvider || status === OrderStatus.Allocated) {
    return OrderColorEnum.warning;
  } else if (status === OrderStatus.NewRequest) return OrderColorEnum.primary;
  else if (status === OrderStatus.Delivered || status === OrderStatus.OutForDelivery || status === OrderStatus.Dispensed) {
    return OrderColorEnum.info;
  } else return status === OrderStatus.WaitingErxHubApproval ? OrderColorEnum.warning : OrderColorEnum.success;
};
