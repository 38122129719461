import { AssigneeType, Patient, GuidedCareTaskAssignee as GuidedCareTaskAssigneeType, Maybe } from "@health/queries/types";
import { Box, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { PatientProfile } from "shared/modules/patient";

type GuidedCareTaskAssigneeProps = {
  assignee: Maybe<Array<Maybe<GuidedCareTaskAssigneeType>>>;
  patient?: Maybe<Patient>;
};

export const GuidedCareTaskAssignee: FC<GuidedCareTaskAssigneeProps> = props => {
  const { assignee, patient } = props;

  return (
    <Box>
      {assignee?.map(item => {
        return item?.assigneeType === AssigneeType.Patient ? (
          <PatientProfile
            patientId={patient?.id!}
            patientName={{
              firstName: patient?.firstName!,
              lastName: patient?.lastName!,
            }}
          />
        ) : (
          <Typography fontSize={14}>{`- ${item?.teamMember?.user?.fullName}`}</Typography>
        );
      })}
    </Box>
  );
};
