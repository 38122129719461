/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorRejectHealthPackageOrderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  rejectionReason: Types.Scalars['String'];
}>;


export type VendorRejectHealthPackageOrderMutation = { __typename?: 'Mutation', vendorRejectHealthPackageOrder?: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus } | null };


export const VendorRejectHealthPackageOrderDocument = gql`
    mutation vendorRejectHealthPackageOrder($id: ID!, $rejectionReason: String!) {
  vendorRejectHealthPackageOrder: marketplaceOrderReject(
    id: $id
    rejectionReason: $rejectionReason
  ) {
    id
    status
  }
}
    `;
export type VendorRejectHealthPackageOrderMutationFn = Apollo.MutationFunction<VendorRejectHealthPackageOrderMutation, VendorRejectHealthPackageOrderMutationVariables>;

/**
 * __useVendorRejectHealthPackageOrderMutation__
 *
 * To run a mutation, you first call `useVendorRejectHealthPackageOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorRejectHealthPackageOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorRejectHealthPackageOrderMutation, { data, loading, error }] = useVendorRejectHealthPackageOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useVendorRejectHealthPackageOrderMutation(baseOptions?: Apollo.MutationHookOptions<VendorRejectHealthPackageOrderMutation, VendorRejectHealthPackageOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorRejectHealthPackageOrderMutation, VendorRejectHealthPackageOrderMutationVariables>(VendorRejectHealthPackageOrderDocument, options);
      }
export type VendorRejectHealthPackageOrderMutationHookResult = ReturnType<typeof useVendorRejectHealthPackageOrderMutation>;
export type VendorRejectHealthPackageOrderMutationResult = Apollo.MutationResult<VendorRejectHealthPackageOrderMutation>;
export type VendorRejectHealthPackageOrderMutationOptions = Apollo.BaseMutationOptions<VendorRejectHealthPackageOrderMutation, VendorRejectHealthPackageOrderMutationVariables>;