import { ProviderGuidedCareHealthProgramTeam } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors, updateCache } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { createGuidedCareTeamBreadcrumbs } from "pages/HealthProviderManagement/GuidedCare/constants";
import { GuidedCareTeamUpsertForm } from "pages/HealthProviderManagement/GuidedCare/forms/TeamUpsert/GuidedCareTeamUpsertForm";
import { IGuidedCareTeamUpsertFormEvent } from "pages/HealthProviderManagement/GuidedCare/types";
import {
  GuidedCareTeamGetDocument,
  GuidedCareTeamUpdateMutation,
  useGuidedCareTeamGetQuery,
  useGuidedCareTeamUpdateMutation,
} from "pages/HealthProviderManagement/GuidedCare/gql";
import { convertTeamFormValuesToBackEndValues } from "pages/HealthProviderManagement/GuidedCare/others";
import { IGuidedCareTeamUpdateContainerParams } from "pages/HealthProviderManagement/GuidedCare/types";
import { healthProviderGuidedCareTeamsPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export const GuidedCareTeamUpdateContainer = () => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const { userInfo } = useOidcUserProfile();

  const { teamId } = useParams<IGuidedCareTeamUpdateContainerParams>();

  const { data, loading } = useGuidedCareTeamGetQuery({
    variables: {
      id: teamId!,
    },
    skip: !teamId,
  });

  const team = data?.providerGuidedCareHealthProgramTeam as ProviderGuidedCareHealthProgramTeam;
  const isTeamOutdated = !team?.guidedCareHealthProgram?.isLatestVersion;

  const [fetchGuidedCareTeamUpdateMutation, { loading: isSubmitting }] = useGuidedCareTeamUpdateMutation({
    onCompleted: (mutationData: GuidedCareTeamUpdateMutation) => {
      const errors = mutationData?.providerGuidedCareHealthProgramTeamUpdate?.providerGuidedCareHealthProgramTeamErrors;

      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Care Team Updated successfully"));
        navigate(healthProviderGuidedCareTeamsPaths.main.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update(cache, { data: teamData }) {
      updateCache(cache, teamData, GuidedCareTeamGetDocument);
    },
  });

  const onGuidedCareTeamUpsertFormChange = (event: IGuidedCareTeamUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertTeamFormValuesToBackEndValues(event.payload.values, userInfo?.vendor_id);

      fetchGuidedCareTeamUpdateMutation({
        variables: {
          input: {
            ...values,
            id: teamId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (team?.name) {
      const { title, main, field } = createGuidedCareTeamBreadcrumbs(t);
      setBreadCrumb({ title, values: [main, field(team.name)] });
    }
  }, [setBreadCrumb, t, team?.name]);

  return (
    <GuidedCareTeamUpsertForm
      submitButtonLabel={t("Update")}
      team={team}
      isTeamOutdated={isTeamOutdated}
      isLoading={loading || isSubmitting}
      onChange={onGuidedCareTeamUpsertFormChange}
    />
  );
};
