import { zodSchema } from "@health/domains";
import { i18n } from "@toolkit/i18n";
import { z } from "zod";

export const ProductUpsertFormSchema = z
  .object({
    name: z.string().min(3).max(255),
    nameAr: z.string().min(3).max(255),
    description: z.string().min(3),
    descriptionAr: z.string().min(3),
    howToUse: z.string().min(3).nullable().optional(),
    howToUseAr: z.string().min(3).nullable().optional(),
    mainImageUrl: z.string(),
    subImages: z.array(z.string().optional().nullable()).nullable().optional(),
    price: z.number().positive(),
    sku: z.string(),
    shortDescription: z.string().min(3),
    shortDescriptionAr: z.string().min(3),
    stockQuantity: z.number().positive(),
    isPublished: z.boolean().nullable().optional(),
    tags: z.array(z.string().optional().nullable()).nullable().optional(),
    productCategory: zodSchema.MarketplaceProductCategoryAutocomplete,
    productBrand: zodSchema.MarketplaceBrandAutocomplete,
    returnPolicy: z.string().nullable().optional(),
    returnPolicyAr: z.string().nullable().optional(),
    returnPolicyDays: z.number().positive().nullable().optional(),
    returnable: z.boolean().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.returnable) {
      if (!data.returnPolicy) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "provider" }),
          path: ["returnPolicy"],
        });
      }
      if (!data.returnPolicyAr) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "provider" }),
          path: ["returnPolicyAr"],
        });
      }
      if (!data.returnPolicyDays) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "provider" }),
          path: ["returnPolicyDays"],
        });
      }
    }
  });

export type IProductUpsertFormValues = z.infer<typeof ProductUpsertFormSchema>;

export const productUpsertFormSchemaDefaultValues: Partial<IProductUpsertFormValues> = {
  name: undefined,
  nameAr: undefined,
  description: undefined,
  descriptionAr: undefined,
  howToUse: undefined,
  howToUseAr: undefined,
  mainImageUrl: undefined,
  subImages: undefined,
  price: undefined,
  sku: undefined,
  shortDescription: undefined,
  shortDescriptionAr: undefined,
  stockQuantity: undefined,
  isPublished: false,
  tags: [],
  productBrand: undefined,
  productCategory: undefined,
  returnPolicy: undefined,
  returnPolicyAr: undefined,
  returnPolicyDays: undefined,
  returnable: false,
};
