/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentsCountsQueryVariables = Types.Exact<{
  filterNew?: Types.InputMaybe<Types.AppointmentFilterInput>;
  filterTentative?: Types.InputMaybe<Types.AppointmentFilterInput>;
  filterPending?: Types.InputMaybe<Types.AppointmentFilterInput>;
  filterConfirmed?: Types.InputMaybe<Types.AppointmentFilterInput>;
  filterOngoing?: Types.InputMaybe<Types.AppointmentFilterInput>;
  filterCancelled?: Types.InputMaybe<Types.AppointmentFilterInput>;
  filterDone?: Types.InputMaybe<Types.AppointmentFilterInput>;
}>;


export type AppointmentsCountsQuery = { __typename?: 'Query', new?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null } | null, tentative?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null } | null, pending?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null } | null, confirmed?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null } | null, ongoing?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null } | null, cancelled?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null } | null, done?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null } | null };


export const AppointmentsCountsDocument = gql`
    query appointmentsCounts($filterNew: AppointmentFilterInput, $filterTentative: AppointmentFilterInput, $filterPending: AppointmentFilterInput, $filterConfirmed: AppointmentFilterInput, $filterOngoing: AppointmentFilterInput, $filterCancelled: AppointmentFilterInput, $filterDone: AppointmentFilterInput) {
  new: appointments(filter: $filterNew) {
    totalCount
  }
  tentative: appointments(filter: $filterTentative) {
    totalCount
  }
  pending: appointments(filter: $filterPending) {
    totalCount
  }
  confirmed: appointments(filter: $filterConfirmed) {
    totalCount
  }
  ongoing: appointments(filter: $filterOngoing) {
    totalCount
  }
  cancelled: appointments(filter: $filterCancelled) {
    totalCount
  }
  done: appointments(filter: $filterDone) {
    totalCount
  }
}
    `;

/**
 * __useAppointmentsCountsQuery__
 *
 * To run a query within a React component, call `useAppointmentsCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsCountsQuery({
 *   variables: {
 *      filterNew: // value for 'filterNew'
 *      filterTentative: // value for 'filterTentative'
 *      filterPending: // value for 'filterPending'
 *      filterConfirmed: // value for 'filterConfirmed'
 *      filterOngoing: // value for 'filterOngoing'
 *      filterCancelled: // value for 'filterCancelled'
 *      filterDone: // value for 'filterDone'
 *   },
 * });
 */
export function useAppointmentsCountsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsCountsQuery, AppointmentsCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsCountsQuery, AppointmentsCountsQueryVariables>(AppointmentsCountsDocument, options);
      }
export function useAppointmentsCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsCountsQuery, AppointmentsCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsCountsQuery, AppointmentsCountsQueryVariables>(AppointmentsCountsDocument, options);
        }
export type AppointmentsCountsQueryHookResult = ReturnType<typeof useAppointmentsCountsQuery>;
export type AppointmentsCountsLazyQueryHookResult = ReturnType<typeof useAppointmentsCountsLazyQuery>;
export type AppointmentsCountsQueryResult = Apollo.QueryResult<AppointmentsCountsQuery, AppointmentsCountsQueryVariables>;