import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { GearsIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { SettingsPage } from "./";

const settingsRoute = "/settings";

export const settingsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${settingsRoute}${this.route}`;
    },
  },
};

export const settingsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "settings",
    text: t("Settings", { ns: "provider" }),
    icon: <GearsIcon />,
    route: settingsRoute,
    fullPath: settingsPaths.indexPage.fullPath,
    element: <SettingsPage />,
    isProhibited: !hasPermission(PermissionEnum.ManageSettings),
    onClick: (route: string) => navigate(route),
  };
};
