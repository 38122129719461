import { formatUserNameFull } from "@toolkit/core";
import { getApolloContextFormToken } from "shared/utils";
import { useGetPatientDataLazyQuery } from "../gql";

export const useCustomGetPatientDataQuery = ({ token }) => {
  const [getPatientDataQuery, { data: patientDataQueryResult, loading: patientDataQueryLoading }] = useGetPatientDataLazyQuery({
    context: getApolloContextFormToken(token!),
  });

  const patient = patientDataQueryResult?.patient;
  const userId = patient?.user?.id!;
  const patientName = formatUserNameFull({
    firstName: patient?.firstName,
    lastName: patient?.lastName,
  });

  return {
    patient,
    userId,
    patientName,
    getPatientDataQuery,
    patientDataQueryLoading,
  };
};
