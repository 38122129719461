/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBranchAddressQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBranchAddressQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', address?: { __typename?: 'Address', id: string, area: string, areaAr: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, buildingNumber?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null } } | null } | null };


export const GetBranchAddressDocument = gql`
    query getBranchAddress($id: ID!) {
  branch(id: $id) {
    address {
      id
      area
      areaAr
      streetAddress1
      streetAddress1Ar
      streetAddress2
      streetAddress2Ar
      buildingNumber
      city {
        id
        name
        nameAr
      }
    }
  }
}
    `;

/**
 * __useGetBranchAddressQuery__
 *
 * To run a query within a React component, call `useGetBranchAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBranchAddressQuery(baseOptions: Apollo.QueryHookOptions<GetBranchAddressQuery, GetBranchAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchAddressQuery, GetBranchAddressQueryVariables>(GetBranchAddressDocument, options);
      }
export function useGetBranchAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchAddressQuery, GetBranchAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchAddressQuery, GetBranchAddressQueryVariables>(GetBranchAddressDocument, options);
        }
export type GetBranchAddressQueryHookResult = ReturnType<typeof useGetBranchAddressQuery>;
export type GetBranchAddressLazyQueryHookResult = ReturnType<typeof useGetBranchAddressLazyQuery>;
export type GetBranchAddressQueryResult = Apollo.QueryResult<GetBranchAddressQuery, GetBranchAddressQueryVariables>;