import { FilterGrid, MainListActions, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { useGetGuidedPatientsColumns } from "./Components/getPatientsColumns";
import { useGuidedCareJourneyListQuery } from "./gql";
import { useTranslation } from "@toolkit/i18n";

export const GuidedCarePatientsScreen: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");

  useEffect(() => {
    setBreadCrumb({ title: t("Guided Care Patients"), values: [] });
  }, []);

  return (
    <GridProvider
      gridName='guidedCarePatientList'
      columns={useGetGuidedPatientsColumns()}
      query={useGuidedCareJourneyListQuery}
      hasTableSetting
    >
      <PageWrapper filters={<FilterGrid />} actions={<MainListActions />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
