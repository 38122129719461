import { useReactiveVar } from "@apollo/client";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { branchUserPreferenceVar } from "shared/components/BranchUser/branchUserReactiveVar";
import { VisitsCountDocument, VisitsDocument, useDoctorVisitAcceptMutation } from "../../gql";

export const useDoctorVisitAccept = (id, onVisitAccepted) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const branchUserPreference = useReactiveVar(branchUserPreferenceVar);

  const [doctorVisitAccept, { loading: isLoading }] = useDoctorVisitAcceptMutation({
    refetchQueries: [VisitsDocument, VisitsCountDocument],
    onCompleted: data => {
      if (data?.doctorVisitAccept?.visit) {
        succeeded(t("Doctor Visit Accepted successfully"));
        onVisitAccepted();
      } else if (data?.doctorVisitAccept?.visitErrors?.length) {
        failed(data?.doctorVisitAccept?.visitErrors?.map(el => el.message).join(" "));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleAccept = () => {
    doctorVisitAccept({
      variables: {
        id,
        branchId: branchUserPreference?.branch?.id,
      },
    });
  };

  return {
    handleAccept,
    isLoading,
  };
};
