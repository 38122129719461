import { OrderType, PrescriptionAddress } from "@health/queries/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { IOrderSection } from "../../actions/types";
import { BeneficiaryInfo } from "./BeneficiaryInfo.components";
import { ChatButton } from "./ChatButton";
import { DiagnosisInformation } from "./DiagnosisInformation";
import { MedicationsSection } from "./MedicationsSection";
import { OrderDetails } from "./OrderDetails";

export const useERXSections = (data: IOrderSection) => {
  const { t } = useTranslation("provider");
  const {
    status,
    number,
    type,
    referenceNumber,
    address: shippingAddress,
    patient,
    hasChat = false,
    diagnosis,
    medications,
    deliveryDate,
    deliveryTimeSlot,
    headerComponent,
    timeOutComponent,
  } = data;

  const address = shippingAddress
    ? [
        pickLocalizedValue(shippingAddress?.streetAddress1, shippingAddress?.streetAddress1Ar),
        pickLocalizedValue(shippingAddress?.area, (shippingAddress as PrescriptionAddress)?.areaAr),
        pickLocalizedValue(shippingAddress?.city, shippingAddress?.cityAr),
        shippingAddress?.postalCode,
      ]
        .filter(Boolean)
        .join(", ")
    : "";

  const eRxSections: Array<{
    hasData?: boolean;
    children?: JSX.Element;
    title?: string;
    color?: string;
    subTitle?: string;
  }> = [
    {
      hasData: hasChat,
      children: <ChatButton />,
    },
    {
      hasData: Boolean(headerComponent),
      children: headerComponent,
    },
    {
      hasData: Boolean(timeOutComponent || number || referenceNumber),
      children: (
        <OrderDetails
          deliveryMethod={type as OrderType}
          referenceNumber={referenceNumber!}
          number={number}
          timeOutComponent={timeOutComponent!}
          deliveryDate={deliveryDate}
          deliveryTimeSlot={deliveryTimeSlot}
        />
      ),
    },
    {
      hasData: true,
      title: t("Beneficiary info"),
      children: <BeneficiaryInfo patient={patient} status={status} />,
    },
    {
      title: t("Medications"),
      hasData: Boolean(medications?.length),
      children: <MedicationsSection medications={medications} status={status} />,
    },
    {
      title: t("Diagnosis Information"),
      hasData: Boolean(diagnosis?.length),
      children: <DiagnosisInformation diagnosis={diagnosis} />,
    },
    {
      title: t("Pickup from pharmacy"),
      hasData: type === OrderType.Pickup,
    },
  ];

  return {
    address,
    eRxSections,
  };
};
