import { BreadcrumbContextProvider, FallbackComponent } from "@toolkit/ui";
import React, { Suspense } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { doLogout } from "shared/configs/apollo";
import { ImmediateNotifications } from "./ImmediateNotifications";
import { LayoutComponent } from "./LayoutComponent";
import { NoMatch } from "./NoMatch";
import { useAppRoutes } from "./useAppRoutes";
import { visitCallRoute } from "pages/VisitCall/route";

export function LoggedInRoutes(): React.ReactElement {
  const auth = useAuth();
  const navigate = useNavigate();
  const { routes, routesInitialized } = useAppRoutes();

  switch (auth.activeNavigator) {
    case "signinSilent":
    case "signoutRedirect":
      return <FallbackComponent />;
  }

  if (auth.isLoading) {
    return <FallbackComponent />;
  }

  if (auth.error) {
    console.log("auth.error", auth.error);
    doLogout();
    return <FallbackComponent />;
  }
  const getRoutes = _routers => {
    return _routers.map(
      route =>
        !route?.isProhibited && (
          <Route key={route.id} path={route.route} element={route?.element}>
            {!!route?.subItems?.length && getRoutes(route.subItems)}
          </Route>
        )
    );
  };

  if (!auth.isAuthenticated || !routesInitialized) {
    return <FallbackComponent />;
  }

  return (
    <Suspense fallback={<FallbackComponent />}>
      {auth.isAuthenticated && routesInitialized && (
        <Routes>
          <Route key={visitCallRoute.id} path={visitCallRoute.route} element={visitCallRoute.element} />
          <Route
            element={
              <BreadcrumbContextProvider navigate={navigate}>
                <>
                  <ImmediateNotifications />
                  <LayoutComponent routes={routes} />
                </>
              </BreadcrumbContextProvider>
            }
          >
            {getRoutes(routes)}
            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
              routes for. */}
            <Route index element={<Navigate to={"dashboard"} replace={true} />} />
            <Route path='*' element={<NoMatch />} />
          </Route>
        </Routes>
      )}
    </Suspense>
  );
}
