/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateGoalListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GuidedCareTemplateGoalListQuery = { __typename?: 'Query', healthProgramTemplate?: { __typename?: 'HealthProgramTemplate', templateGoals?: Array<{ __typename?: 'TemplateGoal', id: string, name?: string | null, nameAr?: string | null, target?: string | null } | null> | null } | null };


export const GuidedCareTemplateGoalListDocument = gql`
    query GuidedCareTemplateGoalList($id: ID!) {
  healthProgramTemplate(id: $id) {
    templateGoals {
      id
      name
      nameAr
      target
    }
  }
}
    `;

/**
 * __useGuidedCareTemplateGoalListQuery__
 *
 * To run a query within a React component, call `useGuidedCareTemplateGoalListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateGoalListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTemplateGoalListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareTemplateGoalListQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTemplateGoalListQuery, GuidedCareTemplateGoalListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTemplateGoalListQuery, GuidedCareTemplateGoalListQueryVariables>(GuidedCareTemplateGoalListDocument, options);
      }
export function useGuidedCareTemplateGoalListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTemplateGoalListQuery, GuidedCareTemplateGoalListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTemplateGoalListQuery, GuidedCareTemplateGoalListQueryVariables>(GuidedCareTemplateGoalListDocument, options);
        }
export type GuidedCareTemplateGoalListQueryHookResult = ReturnType<typeof useGuidedCareTemplateGoalListQuery>;
export type GuidedCareTemplateGoalListLazyQueryHookResult = ReturnType<typeof useGuidedCareTemplateGoalListLazyQuery>;
export type GuidedCareTemplateGoalListQueryResult = Apollo.QueryResult<GuidedCareTemplateGoalListQuery, GuidedCareTemplateGoalListQueryVariables>;