import { isRTL } from "@toolkit/i18n";
import React, { FC, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import { Box, CircularProgress, IconButton, Typography } from "../../base/mui";
import { MuiPauseCircleIcon, MuiPlayCircleIcon } from "../../base/mui-icons";
import { useWaveSurferAudioStyle } from "./useWaveSurferAudioStyle";

type WaveSurferAudioProps = Partial<WaveSurferParams> & {
  url: string;
  isCursorHidden?: boolean;
  isAutoplayEnabled?: boolean;
};

export const WaveSurferAudio: FC<WaveSurferAudioProps> = props => {
  const {
    url,
    waveColor,
    progressColor,
    cursorColor,
    barWidth = 3,
    barHeight = 3,
    barRadius = 3,
    height = 50,
    isCursorHidden = false,
    isAutoplayEnabled = false,
    ...rest
  } = props;

  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);

  const rtl = isRTL();

  const { theme, classes } = useWaveSurferAudioStyle();

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
    wavesurfer.current?.playPause();
  };

  useEffect(() => {
    if (!waveformRef.current) return;

    wavesurfer.current = WaveSurfer.create({
      ...rest,
      container: waveformRef.current,
      waveColor: waveColor || theme.palette.gray.dark,
      progressColor: progressColor || theme.palette.primary.main,
      cursorColor: isCursorHidden ? "transparent" : cursorColor || theme.palette.warning.main,
      barWidth,
      barHeight,
      barRadius,
      height,
      hideScrollbar: true,
      responsive: true,
      rtl,
    });

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", () => {
      if (!wavesurfer.current || !waveformRef.current) return;

      setDuration(wavesurfer.current.getDuration() || 0);

      setLoading(false);
      wavesurfer.current.zoom(1);

      if (isAutoplayEnabled) {
        setPlaying(isAutoplayEnabled);
        wavesurfer.current?.play();
      }
    });

    wavesurfer.current.on("finish", () => {
      setPlaying(false);
    });

    return () => wavesurfer.current?.destroy();
  }, [url, isAutoplayEnabled]);

  return (
    <Box className={classes.root}>
      <IconButton className={classes.iconButton} disabled={loading} onClick={handlePlayPause}>
        {playing ? <MuiPauseCircleIcon color={"primary"} /> : <MuiPlayCircleIcon color={"primary"} />}
      </IconButton>

      {loading && <CircularProgress size={20} />}

      <div id={"waveform"} ref={waveformRef} className={classes.waveformRoot} />

      {!loading && duration && (
        <Typography variant={"body1"} marginLeft={1}>
          {formatTime(duration)}
        </Typography>
      )}
    </Box>
  );
};
