import { SystemCodeAutocomplete } from "@health/autocompletes";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomIcon, Grid, TextField, useTheme } from "@toolkit/ui";
import { CodeSystemCode } from "@health/queries/types";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { StyleFix } from "../components/StyleFix";
import { ReferralsList } from "./components/ReferralsList";
import { useReferralsPageHook } from "./ReferralPage.hook";

export const ReferralsPage: FC<{ visitId; token?: string }> = ({ visitId, token }) => {
  const { t } = useTranslation("provider");

  const { methods, isSubmitting, handleCreateReferral, created, combinedErrors, handleResetForm } = useReferralsPageHook({
    visitId,
    token,
  });

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    methods?.register("specialty", {
      required: getRequiredValidation(t, true),
    });
  }, [methods, t]);
  const theme = useTheme();

  return (
    <Box sx={{ padding: 2, background: "white" }}>
      <StyleFix />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleCreateReferral)} onReset={handleResetForm}>
          <Grid item container xs={12} columnSpacing={2} flexDirection='row' alignItems={"center"}>
            <Grid item xs={12}>
              <SystemCodeAutocomplete name={"specialty"} label={t("Specialty")} filter={{ codeSystemCode: CodeSystemCode.Speciality }} />
            </Grid>
          </Grid>

          <Box py={1}>
            <TextField
              multiline
              rows={3}
              fullWidth
              label={t("Observation")}
              placeholder={t("Observation")}
              {...methods.register("observation", { required: getRequiredValidation(t, true) })}
              error={Boolean(combinedErrors?.observation?.message)}
              helperText={t(combinedErrors?.observation?.message)}
            />
          </Box>

          <Box component={"footer"} sx={{ display: "flex", gap: 2 }}>
            <Button
              type='submit'
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={10} /> : <CustomIcon icon='add' color='white' />}
            >
              {isSubmitting ? t("Submitting") + "..." : t("Add")}
            </Button>

            <Button
              type='reset'
              sx={{
                backgroundColor: `${theme.palette.common.white} !important`,
                color: theme.palette.primary.main + "!important",
                ":hover": {
                  backgroundColor: `${theme.palette.common.white} !important`,
                  color: theme.palette.primary.main + "!important",
                },
              }}
            >
              {t("Reset Fields")}
            </Button>
          </Box>
        </form>
      </FormProvider>

      <ReferralsList created={created} visitId={visitId as string} token={token!} />
    </Box>
  );
};

ReferralsPage.defaultProps = {
  token: undefined,
};
