import { makeStyles } from "@toolkit/ui";

export const useDoctorConsolePageStyles = makeStyles()(theme => ({
  root: {
    background: theme.palette.common.white,
    border: "4px solid #fff",
    position: "relative",
    height: "inherit",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    justifyContent: "space-between",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  content: {
    overflow: "auto",
  },
}));
