import { getAutocompleteFilter } from "@toolkit/ui";
import { useDoctorsAutocompleteQuery } from "./gql";
export const getDoctorsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "doctors",
        query: useDoctorsAutocompleteQuery,
        labelBy: option => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.user) === null || _a === void 0 ? void 0 : _a.fullName; },
        backendAccessor: "id",
        filterSearchKey: "name",
    });
};
