import { FC } from "react";
import { Button, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

type VisitCallErrorProps = {
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  className?: string;
};

export const VisitCallError: FC<VisitCallErrorProps> = ({ title, subtitle, onClick, className }) => {
  const { t } = useTranslation("provider");
  return (
    <div className={className}>
      <h2>{title ?? t("Oops!")}</h2>
      <Typography>{subtitle ?? t("Failed to rejoin the call. Please retry.")}</Typography>
      {onClick && <Button onClick={onClick}>{t("Retry")}</Button>}
    </div>
  );
};
