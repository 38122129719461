import { CustomTable, Grid } from "@toolkit/ui";
import { usePatientHealthParametersHistory } from "../../hooks";
import { usePatientHealthParameterHistoryNumericColumns } from "./usePatientHealthParameterHistoryNumericColumns";
import React, { FC } from "react";
import { PatientHealthParameterChartLineZoom } from "../PatientHealthParameterChartLineZoom/PatientHealthParameterChartLineZoom";

type PatientHealthParameterHistoryAudioProps = {
  patientId: string;
  token: string;
  codes: string[];
};

export const PatientHealthParameterHistoryNumeric: FC<PatientHealthParameterHistoryAudioProps> = props => {
  const { patientId, token, codes } = props;

  const { selectedItem, loading, tableProps, handleSelectItem } = usePatientHealthParametersHistory({
    patientId,
    token,
    codes,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!!selectedItem?.valueNumericList?.length && (
          <PatientHealthParameterChartLineZoom dataSets={[{ data: selectedItem?.valueNumericList }]} isLoading={loading} />
        )}
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          {...tableProps}
          columns={usePatientHealthParameterHistoryNumericColumns({ selectedItem, onChange: handleSelectItem })}
        />
      </Grid>
    </Grid>
  );
};
