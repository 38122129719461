/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDashboardAppointmentsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.AppointmentSortingInput>;
  filter?: Types.InputMaybe<Types.AppointmentFilterInput>;
  isAdmin: Types.Scalars['Boolean'];
}>;


export type FetchDashboardAppointmentsQuery = { __typename?: 'Query', appointments?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'AppointmentCountableEdge', cursor: string, node: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null, type?: Types.AppointmentType | null, requestedBy?: Types.RequestedBy | null, suggestedTimeSlots?: Array<string | null> | null, requestedTimeSlots?: Array<string | null> | null, confirmedStartTime?: any | null, visit?: { __typename?: 'Visit', id: string, duration?: number | null, endTime?: any | null, startTime?: any | null, careType?: Types.VisitCareType | null, status?: Types.VisitStatus | null } | null, doctor?: { __typename?: 'Doctor', id: string, appointmentSlotTimePeriod: number, user?: { __typename?: 'User', fullName?: string | null } | null } | null, consumer?: { __typename?: 'Patient', id: string, lastName?: string | null, firstName?: string | null, user?: { __typename?: 'User', id: string } | null, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, insuranceId?: string | null, membershipEnd?: any | null, payer?: { __typename?: 'Payer', name: string } | null } | null> | null, healthProgramsByActiveMembers?: Array<{ __typename?: 'HealthProgram', insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', id: string, name?: string | null } }> } | null } | null> | null } | null, consumerUser?: { __typename?: 'User', fullName?: string | null, id: string } | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const FetchDashboardAppointmentsDocument = gql`
    query fetchDashboardAppointments($after: String, $before: String, $first: Int, $last: Int, $sortBy: AppointmentSortingInput, $filter: AppointmentFilterInput, $isAdmin: Boolean!) {
  appointments(
    after: $after
    before: $before
    first: $first
    last: $last
    sortBy: $sortBy
    filter: $filter
  ) {
    edges {
      cursor
      node {
        id
        status
        type
        requestedBy
        visit {
          id
          duration
          endTime
          startTime
          careType
          status
        }
        doctor @include(if: $isAdmin) {
          id
          user {
            fullName
          }
          appointmentSlotTimePeriod
        }
        consumer {
          id
          lastName
          firstName
          user {
            id
          }
          activeHealthProgramMembers {
            id
            insuranceId
            payer {
              name
            }
            membershipEnd
          }
          healthProgramsByActiveMembers {
            insuranceNetworks(first: 1) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
        suggestedTimeSlots
        consumerUser {
          fullName
          id
        }
        requestedTimeSlots
        confirmedStartTime
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

/**
 * __useFetchDashboardAppointmentsQuery__
 *
 * To run a query within a React component, call `useFetchDashboardAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardAppointmentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useFetchDashboardAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<FetchDashboardAppointmentsQuery, FetchDashboardAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDashboardAppointmentsQuery, FetchDashboardAppointmentsQueryVariables>(FetchDashboardAppointmentsDocument, options);
      }
export function useFetchDashboardAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDashboardAppointmentsQuery, FetchDashboardAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDashboardAppointmentsQuery, FetchDashboardAppointmentsQueryVariables>(FetchDashboardAppointmentsDocument, options);
        }
export type FetchDashboardAppointmentsQueryHookResult = ReturnType<typeof useFetchDashboardAppointmentsQuery>;
export type FetchDashboardAppointmentsLazyQueryHookResult = ReturnType<typeof useFetchDashboardAppointmentsLazyQuery>;
export type FetchDashboardAppointmentsQueryResult = Apollo.QueryResult<FetchDashboardAppointmentsQuery, FetchDashboardAppointmentsQueryVariables>;