import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomTable, Typography } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import PrescriptionActivityForm from "./PrescriptionActivityForm";
import { PrescriptionFormProps } from "./types";
import useFormColumns from "./useFormColumns";
import { usePrescriptionFormController } from "./useFormController";
import { useStyles } from "./useStyles";

export const PrescriptionForm: FC<PrescriptionFormProps> = ({ visitId, token, onCreateSuccess }) => {
  const { t } = useTranslation("provider");

  const {
    hasNoDiagnostic,
    activitiesFields,
    methods,
    isSubmittingCreate,
    handleDeleteRow,
    handleAddActivity,
    handleCreate,
    handleResetForm,
  } = usePrescriptionFormController({
    visitId,
    token,
    onCreateSuccess,
  });

  const { classes, theme } = useStyles();
  const formColumns = useFormColumns();

  if (hasNoDiagnostic) {
    return (
      <Typography variant='h6' sx={{ color: theme.palette.error[800] }}>
        {t("No diagnosis found, please add diagnosis first")}
      </Typography>
    );
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleCreate)} onReset={handleResetForm}>
        <PrescriptionActivityForm visitId={visitId} onAddActivity={handleAddActivity} />
        <Box className={classes.formGrid} my={2}>
          <CustomTable
            TableContainerProps={{
              style: {
                width: "100%",
              },
            }}
            minHeight={50}
            isLoading={false}
            data={activitiesFields}
            columns={formColumns as any[]}
            hasFooter={false}
            isDeleteVisible
            onDeleteRow={handleDeleteRow}
          />
        </Box>
        <Box component={"footer"} sx={{ display: "flex", width: "100%", gap: 2, pt: "10px" }}>
          <Button type='submit' color='success' disabled={isSubmittingCreate}>
            {isSubmittingCreate ? t("Creating") + "..." : t("Create Prescription")}
          </Button>
          <Button type='reset' className={classes.resetButton}>
            {t("Reset")}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default PrescriptionForm;
