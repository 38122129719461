import MessageComposer from "@meetora/react/ui/MessageComposer";
import MessageList from "@meetora/react/ui/MessageList";
import NotificationBar from "@meetora/react/ui/NotificationBar";
import PeerAvatar from "@meetora/react/ui/PeerAvatar";
import PeerTitle from "@meetora/react/ui/PeerTitle";
import { Box, CustomDialog, IconButton, ModalCloseIcon, SpeechIcon, Typography, useTheme } from "@toolkit/ui";
import * as React from "react";
import { FC, ReactElement } from "react";
import { useChatHooks } from "./useChatHooks";

type ChatDialogProps = {
  userId: string;
  buttonEl?: ReactElement;
};

export const ChatDialog: FC<ChatDialogProps> = ({ userId, buttonEl }) => {
  const { peer, handleToggle, open: isOpened, startChat: handleStartCall, isGetChatLinkLoading } = useChatHooks();
  const theme = useTheme();
  return (
    <CustomDialog
      open={isOpened}
      type='base'
      button={
        buttonEl ? (
          React.cloneElement(buttonEl, { onClick: () => handleStartCall(userId), disabled: isGetChatLinkLoading })
        ) : (
          <IconButton color='primary' disabled={isGetChatLinkLoading} onClick={() => handleStartCall(userId)}>
            <SpeechIcon color='inherit' sx={{ "& path": { fill: theme.palette.primary.main } }} />
          </IconButton>
        )
      }
      DialogActionsProps={{
        hasClose: false,
        closeTitle: "Done",
      }}
      DialogContentProps={{
        sx: {
          height: "100vh",
          padding: "10px 0px",
        },
      }}
      DialogTitleProps={{
        hidden: true,
      }}
      onBackdropClick={handleToggle}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box display={"flex"} alignItems={"center"} mx={1} mb={1}>
          <PeerAvatar peer={peer} size={32} />
          <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight='bold' mx={1}>
            <PeerTitle peer={peer} />
          </Typography>

          <Box flexGrow={1}></Box>

          <IconButton onClick={handleToggle} sx={{ height: "30px", width: "30px" }}>
            <ModalCloseIcon />
          </IconButton>
        </Box>
        <MessageList peer={peer} />
        <MessageComposer peer={peer} />
        <NotificationBar />
      </Box>
    </CustomDialog>
  );
};
