import { HealthParameter, Maybe, PatientHealthParameter, PatientHpVitalSignsChangePercentage } from "@health/queries/types";
import moment from "moment/moment";
import { sourceOptionsMap } from "@health/enum-options";
import { groupBy, isNil, keyBy, map, max, min } from "lodash";
import { i18n, pickLocalizedValue } from "@toolkit/i18n";
import { healthParameterCodes, healthParameterCodesBloodPressure } from "../constants";

const dateFormat = "DD MMM YYYY HH:mm";

export const convertPatientHealthParametersNumberToChartData = (patientHealthParameters: PatientHealthParameter[]) => {
  return (
    patientHealthParameters?.map(item => {
      return {
        value: parseFloat(item?.valueNumber!) || 0,
        date: moment(item?.createdDate).format(dateFormat),
        source: sourceOptionsMap[item?.source!]?.label!,
      };
    }) || []
  );
};

export const convertPatientHealthParametersToTableData = (patientHealthParameters: PatientHealthParameter[]) => {
  const groupedData = groupBy(patientHealthParameters, item => item?.createdDate);

  return map(groupedData, (items, date) => {
    const values = items.map(item => item?.valueNumber);
    const maxValue = max(values) || 0;
    const minValue = min(values) || 0;

    return {
      value: maxValue === minValue ? parseFloat(maxValue) : `${parseFloat(maxValue)}/${parseFloat(minValue)}`,
      date: date,
      source: items?.[0]?.source!,
    };
  });
};

export const getPatientHealthParameterChartTooltip = (params: any, dataLength: number, unit?: string) => {
  let tooltipContent = "";

  if (dataLength === 1) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { value = "-", date = "-", source } = params?.data;
    tooltipContent = `
        <strong>${i18n.t("Value", { ns: "provider" })}</strong>: ${value} ${unit || ""} <br/>
        <strong>${i18n.t("Date", { ns: "provider" })}</strong>: ${date}
        ${source ? `<br/> <strong>${i18n.t("Source", { ns: "provider" })}</strong>: ${source}` : ""}
      `;
  } else {
    const values = params?.map(param => param?.data?.value ?? "-").join("/");
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { date = "-", source } = params?.[0]?.data;

    tooltipContent = `
        <strong>${i18n.t("Values", { ns: "provider" })}</strong>: ${values} ${unit || ""} <br/>
        <strong>${i18n.t("Dates", { ns: "provider" })}</strong>: ${date}
        ${source ? `<br/> <strong>${i18n.t("Source", { ns: "provider" })}</strong>: ${source}` : ""}
      `;
  }

  return tooltipContent;
};

const getPatientHealthParameterVitalSignsChangePercentage = (vitalSignsChangePercentage: Maybe<PatientHpVitalSignsChangePercentage>[]) => {
  // if (!vitalSignsChangePercentage || !vitalSignsChangePercentage.length) return;

  const changePercentageKeyed = keyBy(vitalSignsChangePercentage, "hpCode");

  return {
    [healthParameterCodes.temperature]: changePercentageKeyed[healthParameterCodes.temperature]?.changePercentage,
    [healthParameterCodes.heartRate]: changePercentageKeyed[healthParameterCodes.heartRate]?.changePercentage,
    [healthParameterCodes.oxygenSaturation]: changePercentageKeyed[healthParameterCodes.oxygenSaturation]?.changePercentage,
    [healthParameterCodes.respiratoryRate]: changePercentageKeyed[healthParameterCodes.respiratoryRate]?.changePercentage,
    [healthParameterCodes.bloodPressureLow]: changePercentageKeyed[healthParameterCodes.bloodPressureLow]?.changePercentage,
    [healthParameterCodes.bloodPressureHigh]: changePercentageKeyed[healthParameterCodes.bloodPressureHigh]?.changePercentage,
  };
};

export const formatHealthParametersVitalSigns = (
  healthParameters: HealthParameter[],
  vitalSignsChangePercentages: Maybe<PatientHpVitalSignsChangePercentage>[]
) => {
  const vitalSignsChangePercentagesMap = getPatientHealthParameterVitalSignsChangePercentage(vitalSignsChangePercentages);

  const filteredCodes = healthParameters
    ?.filter(item => !healthParameterCodesBloodPressure.includes(item?.code!))
    ?.sort((a, b) => a?.display!.localeCompare(b?.display!))
    ?.map(item => ({
      code: [item.code!],
      title: pickLocalizedValue(item?.display, item?.arabicDisplay),
      changePercentage: vitalSignsChangePercentagesMap?.[item.code!],
    }));

  const hasBloodPressureHigh = healthParameters?.find(item => item.code === healthParameterCodes.bloodPressureHigh);
  const hasBloodPressureLow = healthParameters?.find(item => item.code === healthParameterCodes.bloodPressureLow);

  if (hasBloodPressureHigh && hasBloodPressureLow) {
    const bloodPressure = {
      code: healthParameterCodesBloodPressure,
      title: i18n.t("Blood Pressure", { ns: "provider" }),
      changePercentage:
        vitalSignsChangePercentagesMap?.[healthParameterCodes.bloodPressureHigh] ||
        vitalSignsChangePercentagesMap?.[healthParameterCodes.bloodPressureLow],
    };

    return [bloodPressure, ...filteredCodes];
  }

  return filteredCodes;
};

export const formatPatientHealthParametersVitalSigns = (
  healthParameters: HealthParameter[],
  patientHealthParameters: PatientHealthParameter[]
) => {
  const healthParametersVitalSigns =
    healthParameters
      ?.filter(item => ![healthParameterCodes.bloodPressureLow, healthParameterCodes.bloodPressureHigh].includes(item?.code!))
      ?.map(item => {
        const patientHealthParameter = patientHealthParameters?.find(param => param?.hpCode === item?.code);

        return {
          code: item?.code!,
          display: pickLocalizedValue(item?.display!, item?.arabicDisplay!),
          unit: pickLocalizedValue(item?.unit?.display!, item?.unit?.arabicDisplay!),
          value: !isNil(patientHealthParameter?.valueNumber) ? parseFloat(patientHealthParameter?.valueNumber!) : undefined,
          source: sourceOptionsMap[patientHealthParameter?.source!]?.label,
        };
      })
      ?.sort((a, b) => a?.display.localeCompare(b?.display)) || [];

  const healthParametersBloodPressureLow = healthParameters?.find(item => item?.code === healthParameterCodes.bloodPressureLow);
  const healthParametersBloodPressureHigh = healthParameters?.find(item => item?.code === healthParameterCodes.bloodPressureHigh);

  const patientHealthParametersBloodPressureLow = patientHealthParameters?.find(
    param => param?.hpCode === healthParameterCodes.bloodPressureLow
  );

  const bloodPressureLowValue = patientHealthParametersBloodPressureLow?.valueNumber;

  const patientHealthParametersBloodPressureHigh = patientHealthParameters?.find(
    param => param?.hpCode === healthParameterCodes.bloodPressureHigh
  );

  const bloodPressureHighValue = patientHealthParametersBloodPressureHigh?.valueNumber;

  if (healthParametersBloodPressureLow && healthParametersBloodPressureHigh) {
    const bloodPressure = {
      code: `${healthParametersBloodPressureHigh?.code}/${healthParametersBloodPressureLow?.code}`,
      display: i18n.t("Blood Pressure", { ns: "provider" }),
      unit: pickLocalizedValue(healthParametersBloodPressureHigh.unit?.display!, healthParametersBloodPressureHigh.unit?.arabicDisplay!),
      value:
        !isNil(bloodPressureHighValue) && !isNil(bloodPressureLowValue)
          ? `${parseFloat(bloodPressureHighValue)}/${parseFloat(bloodPressureLowValue)}`
          : undefined,
      source: sourceOptionsMap[patientHealthParametersBloodPressureHigh?.source!]?.label,
    };
    return [bloodPressure, ...healthParametersVitalSigns];
  }

  return healthParametersVitalSigns;
};
