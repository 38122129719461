import ExpandViewIcon from "./Icons/ExpandViewIcon";
import { createElement, FC, useState } from "react";
import { Button } from "@toolkit/ui";
import { useDoctorConsoleStyles } from "./DoctorConsole.styles";
import { doctorConsoleMenu, DoctorConsoleType } from "./constants";
import { DoctorConsolePage } from "./DoctorConsolePage";

type DoctorConsoleProps = {
  visitId: string;
};

export const DoctorConsole: FC<DoctorConsoleProps> = ({ visitId }) => {
  const { classes } = useDoctorConsoleStyles();

  const [type, setType] = useState<DoctorConsoleType>(DoctorConsoleType.notes);
  const [expandView, setExpandView] = useState(false);
  const handleSelectMenu = (id: DoctorConsoleType) => {
    return () => {
      setType(id);
      setExpandView(type === id ? !expandView : true);
    };
  };

  const handleToggleExpandView = () => {
    setExpandView(!expandView);
  };

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        {doctorConsoleMenu.map(menu => (
          <Button key={menu.id} onClick={handleSelectMenu(menu.id)} className={`${classes.menuItem} ${type === menu.id ? "active" : ""}`}>
            {createElement(menu.icon)}
            <span>{menu.title}</span>
          </Button>
        ))}

        <Button onClick={handleToggleExpandView} className={classes.menuItem}>
          <ExpandViewIcon />
        </Button>
      </div>
      <div className={`${classes.content} ${expandView && type !== undefined ? "open" : ""}`}>
        <DoctorConsolePage type={type} visitId={visitId} onClose={handleToggleExpandView} />
      </div>
    </div>
  );
};
