const subscriptionsManagement = "subscriptions-management";
const subscriptions = "subscriptions";
const newSubscription = "new";
const editSubscription = `edit/:id`;

export const subscriptionManagementRoutes = {
  name: "Subscription Management",
  path: subscriptionsManagement,
  subscriptions: {
    name: "Subscriptions",
    path: subscriptions,
    fullPath: [subscriptionsManagement, subscriptions].join("/"),
    new: {
      name: "New Subscription",
      path: [subscriptions, newSubscription].join("/"),
      fullPath: [subscriptionsManagement, subscriptions, newSubscription].join("/"),
    },
    edit: {
      name: "Edit Subscription",
      path: [subscriptions, editSubscription].join("/"),
      fullPath: [subscriptionsManagement, subscriptions, editSubscription].join("/"),
    },
  },
};
