import { useOpenState } from "@toolkit/core";
import { FC } from "react";
import { ChatDialogComponent } from "./components/ChatDialog.component";
import { ConversationComponent } from "./components/UnreadMessage";
import { useChatHooks } from "./components/useChatWithUnreadMessageCountHooks";

type ChatDialogProps = {
  userId: string;
  renderButton: (onClick: () => void, unread) => JSX.Element;
  meetingPlatformLinkForChatView: string | null;
};

export const ChatDialogWithUnreadMessageCount: FC<ChatDialogProps> = ({ userId, renderButton, meetingPlatformLinkForChatView }) => {
  const { open: isOpened, handleToggle } = useOpenState();
  const { peer, startChat } = useChatHooks({ handleToggle, meetingPlatformLinkForChatView, userId });
  return (
    <>
      <ChatDialogComponent
        unread={peer ? <ConversationComponent peer={peer} /> : null}
        startChat={startChat}
        peer={peer}
        isOpened={isOpened}
        handleToggle={handleToggle}
        renderButton={renderButton}
      />
    </>
  );
};
