import { MarketplaceApprovalStatus } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, CustomIcon, IconButton, Tooltip, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useMarketplaceProductSubmitForApprovalMutation } from "../gql";
import { ProductsQueryNode } from "../types";
import { ProductDetails } from "./ProductDetails";

export const RequestViewModal: FC<ProductsQueryNode> = props => {
  const { t } = useTranslation("provider");
  const { open, handleToggle, handleOpen } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const isDisabled = [MarketplaceApprovalStatus.PendingApproval, MarketplaceApprovalStatus.Approved].includes(props?.approvalStatus!);

  const [fetchViewRequest, { loading }] = useMarketplaceProductSubmitForApprovalMutation({
    onCompleted: data => {
      if (data?.marketplaceProductSubmitForApproval?.approvalStatus === MarketplaceApprovalStatus.PendingApproval) {
        succeeded("Product review request sent successfully!");
        handleToggle();
      } else {
        failed("Failed to send product review request");
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleRequestReview = () => {
    fetchViewRequest({
      variables: {
        marketplaceHealthPackageSubmitForApprovalId: props.id,
      },
    });
  };
  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Request product review"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={t("Send product to review")}>
          <IconButton disabled={isDisabled || !props?.updatable} onClick={handleOpen}>
            <CustomIcon icon='share' />
          </IconButton>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <>
            <Button variant='outlined' onClick={handleToggle}>
              {t("Close")}
            </Button>
            <Button disabled={loading} variant='contained' onClick={handleRequestReview}>
              {t("Request review")}
            </Button>
          </>
        ),
      }}
    >
      <ProductDetails {...props} />
    </CustomDialog>
  );
};
