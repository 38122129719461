import { i18n } from "@toolkit/i18n";
import { providerTranslationNamespace } from "utils";
import { promotionsPaths } from "./PromotionsPaths";

export const promotionsBreadcrumb = () => ({
  title: i18n.t("Marketplace Promotions", providerTranslationNamespace),
  main: {
    id: "promotions",
    name: i18n.t("Marketplace Promotions", providerTranslationNamespace),
    path: promotionsPaths.main.fullPath,
  },
  create: {
    id: "promotion-new",
    name: i18n.t("New", providerTranslationNamespace),
  },
  update: (fieldName: string) => ({
    id: "promotion-update",
    name: fieldName || i18n.t("Update", providerTranslationNamespace),
  }),
});
