import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { DocumentStoresAutocomplete } from "@health/autocompletes";
import { FileType } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog, Divider, FileUploader, LinearProgress, PlusIcon, TruncateTypography, Typography, useAddToast, useTheme, } from "@toolkit/ui";
import { convertBytes } from "../../../AIAgents/utils";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useOidcUserProfile } from "../../../../shared/hooks/useOidcUserProfile";
import { useChatFlowDocumentCreateMutation } from "../../gql";
export const UploadModal = ({ refetch }) => {
    var _a, _b, _c, _d;
    const { open, handleToggle } = useOpenState();
    const methods = useForm();
    const { handleSubmit } = methods;
    const [file, setFile] = useState();
    const [lastFile, setLastFile] = useState("");
    const { t } = useTranslation("domains");
    const theme = useTheme();
    const { accessToken } = useOidcUserProfile();
    const isSubmitActive = file && lastFile && ((_a = file[lastFile]) === null || _a === void 0 ? void 0 : _a.fileLink);
    const { agentId } = useParams();
    const { succeeded, failed } = useAddToast();
    const [createDocument, { loading }] = useChatFlowDocumentCreateMutation({
        onCompleted: data => {
            var _a;
            ((_a = data === null || data === void 0 ? void 0 : data.chatFlowDocumentCreate) === null || _a === void 0 ? void 0 : _a.id) && succeeded(t("Document added successfully!"));
            refetch();
            handleToggle();
            setFile({});
            setLastFile("");
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
            handleToggle();
        },
    });
    const onDoneClick = () => {
        var _a, _b, _c, _d, _e, _f, _g;
        const type = ((_c = (_b = (_a = file === null || file === void 0 ? void 0 : file[lastFile]) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.type) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === "application/pdf" ? FileType.Pdf : FileType.Csv;
        const fileName = (_e = (_d = file === null || file === void 0 ? void 0 : file[lastFile]) === null || _d === void 0 ? void 0 : _d.fileLink) === null || _e === void 0 ? void 0 : _e.split("/").pop();
        createDocument({
            variables: {
                chatFlowId: agentId,
                documentStoreId: (_g = (_f = methods.getValues("documentStoreId")) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.id,
                input: {
                    fileName: fileName,
                    fileType: type,
                    convertToText: true,
                },
            },
        });
    };
    const onFileSelect = (files) => {
        setLastFile(files[0].name);
        const newFiles = {};
        if (files.length > 0) {
            files.forEach(file => {
                newFiles[file === null || file === void 0 ? void 0 : file.name] = { file, progress: 0, fileLink: "" };
            });
            setFile(file => (Object.assign(Object.assign({}, file), newFiles)));
        }
    };
    const onProgressChange = (uploadProgress) => {
        setFile(file => (Object.assign(Object.assign({}, file), uploadProgress)));
    };
    const onEachFileUploadComplete = (filesProgress) => {
        setFile(file => (Object.assign(Object.assign({}, file), { [filesProgress.file.name]: filesProgress })));
    };
    return (_jsx(CustomDialog, { type: 'base', sx: {
            "&::before": {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: (_c = (_b = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _b === void 0 ? void 0 : _b.common) === null || _c === void 0 ? void 0 : _c.black,
                opacity: 0.8,
            },
            "& .MuiDialog-paper": {
                borderRadius: 15,
                width: "440px",
            },
        }, open: open, DialogTitleProps: {
            title: t("Upload File"),
            onClose: handleToggle,
        }, button: _jsx(Button, { startIcon: _jsx(PlusIcon, {}), onClick: handleToggle, children: t("Upload") }), DialogActionsProps: {
            children: (_jsx(_Fragment, { children: loading ? (_jsxs(_Fragment, { children: [_jsx(CircularProgress, { sx: {
                                marginInlineEnd: "5px",
                            }, size: theme.mixins.fonts.fontSize.md }), _jsx(Typography, { fontWeight: theme.mixins.fonts.fontWeight.medium, fontSize: theme.mixins.fonts.fontSize.md, children: t("Processing...") })] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'contained', disabled: !isSubmitActive || loading, sx: {
                                paddingX: "30px",
                            }, onClick: onDoneClick, children: t("Done") }), _jsx(Button, { variant: 'outlined', onClick: handleToggle, children: t("Close") })] })) })),
        }, children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("form", { onSubmit: handleSubmit(onDoneClick), children: [_jsx(DocumentStoresAutocomplete, { name: 'documentStoreId' }), _jsx(Divider, { sx: { paddingTop: "10px" } }), _jsx(FileUploader, { inputId: 'addaads', disabled: loading, onFileSelect: onFileSelect, onProgressChange: onProgressChange, token: accessToken, maxFileSize: 1000000, multiple: false, accept: '.pdf, .csv', onEachFileUploadComplete: onEachFileUploadComplete, child: _jsx(_Fragment, { children: file &&
                                ((_d = Object.keys(file)
                                    .filter(fileName => { var _a, _b; return fileName === ((_b = (_a = file === null || file === void 0 ? void 0 : file[lastFile]) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.name); })) === null || _d === void 0 ? void 0 : _d.map(fileName => {
                                    var _a, _b, _c, _d, _e, _f;
                                    return (_jsx(Box, { sx: { display: "flex", alignItems: "center", width: "100%", height: "100%" }, marginTop: "8px", children: _jsxs(Box, { sx: { display: "flex", alignItems: "center", width: "100%", flexDirection: "column" }, marginTop: "8px", children: [_jsxs(Box, { sx: { marginRight: 1, width: "100%", height: "100%", display: "flex", justifyContent: "space-between" }, children: [_jsx(Box, { children: _jsx(TruncateTypography, { text: t(`Uploading File (${fileName})`) }) }), _jsxs(Box, { color: "#5B7798", fontSize: '12px', children: [convertBytes(((((_b = (_a = file[fileName]) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.size) || 1) * (((_c = file === null || file === void 0 ? void 0 : file[fileName]) === null || _c === void 0 ? void 0 : _c.progress) || 100)) / 100), "/", convertBytes((_e = (_d = file[fileName]) === null || _d === void 0 ? void 0 : _d.file) === null || _e === void 0 ? void 0 : _e.size)] })] }), _jsx(Box, { sx: { marginRight: 1, width: "100%", height: "100%" }, children: _jsx(LinearProgress, { sx: {
                                                            height: 15,
                                                            width: "100%",
                                                            backgroundColor: "#E7EBF0",
                                                            borderRadius: "8px",
                                                            "& .MuiLinearProgress-bar": {
                                                                borderRadius: 8,
                                                                backgroundColor: theme.palette.primary.main,
                                                            },
                                                        }, variant: 'determinate', value: (_f = file === null || file === void 0 ? void 0 : file[fileName]) === null || _f === void 0 ? void 0 : _f.progress }) })] }) }, fileName));
                                })) }) })] }) })) }));
};
