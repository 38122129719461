/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTaskUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.GuidedCareTaskUpdateInput;
}>;


export type GuidedCareTaskUpdateMutation = { __typename?: 'Mutation', guidedCareTaskUpdate?: { __typename?: 'GuidedCareTaskCRUD', guidedCareTask?: { __typename?: 'GuidedCareTask', id: string, status?: Types.TaskDefinitionStatus | null, assignee?: Array<{ __typename?: 'GuidedCareTaskAssignee', id: string, assigneeUserId?: string | null, assigneeType?: Types.AssigneeType | null, teamMember?: { __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } | null> | null } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTaskUpdateDocument = gql`
    mutation GuidedCareTaskUpdate($id: ID!, $input: GuidedCareTaskUpdateInput!) {
  guidedCareTaskUpdate(id: $id, input: $input) {
    guidedCareTask {
      id
      status
      assignee {
        id
        assigneeUserId
        assigneeType
        teamMember {
          id
          user {
            id
            fullName
          }
        }
      }
    }
    entityErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTaskUpdateMutationFn = Apollo.MutationFunction<GuidedCareTaskUpdateMutation, GuidedCareTaskUpdateMutationVariables>;

/**
 * __useGuidedCareTaskUpdateMutation__
 *
 * To run a mutation, you first call `useGuidedCareTaskUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTaskUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTaskUpdateMutation, { data, loading, error }] = useGuidedCareTaskUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareTaskUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTaskUpdateMutation, GuidedCareTaskUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTaskUpdateMutation, GuidedCareTaskUpdateMutationVariables>(GuidedCareTaskUpdateDocument, options);
      }
export type GuidedCareTaskUpdateMutationHookResult = ReturnType<typeof useGuidedCareTaskUpdateMutation>;
export type GuidedCareTaskUpdateMutationResult = Apollo.MutationResult<GuidedCareTaskUpdateMutation>;
export type GuidedCareTaskUpdateMutationOptions = Apollo.BaseMutationOptions<GuidedCareTaskUpdateMutation, GuidedCareTaskUpdateMutationVariables>;