import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTeamUpsertFormStyle = makeStyles()(theme => ({
  syncLinkText: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: "bold",
    color: theme.palette.common.white,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
    },
  },
}));
