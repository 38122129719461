import { useTranslation } from "@toolkit/i18n";
import { Avatar, ConnectionDocumentNode, CustomTableColumnProps, Typography } from "@toolkit/ui";
import { GuidedCareTeamPatientListQuery } from "pages/HealthProviderManagement/GuidedCare/gql";
import { useMemo } from "react";
import { ChatDialog } from "shared/components/Chat/ChatDialog";
import { PatientProfile } from "shared/modules/patient";

export const useGuidedCareTeamPatientListTableColumns = () => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "photo",
        header: "",
        accessor: ({ patient }) => <Avatar sizes='39px' src={patient?.user?.photo!} />,
        type: "string",
        isHidden: false,
      },
      {
        key: "name",
        header: t("Patient Name"),
        accessor: ({ patient }) => (
          <PatientProfile
            patientId={patient?.id}
            patientName={{
              firstName: patient?.firstName,
              lastName: patient?.lastName,
            }}
          />
        ),
        type: "string",
        isHidden: false,
      },
      {
        key: "id",
        header: t("iO Health ID"),
        accessor: "id",
        isHidden: false,
      },
      {
        key: "nationalId",
        header: t("Emirates ID"),
        accessor: "nationalId",
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: "phone",
        type: "string",
      },
      {
        key: "chronicDiseases",
        header: t("Chronic Diseases"),
        accessor: ({ chronicDiseases }) => <Typography>{chronicDiseases ? chronicDiseases?.join(", ") : "-"}</Typography>,
        type: "string",
      },
      {
        key: "chat",
        header: t("Chat"),
        accessor: ({ id }) => <ChatDialog userId={id} />,
      },
    ] as CustomTableColumnProps<ConnectionDocumentNode<GuidedCareTeamPatientListQuery, "teamEnrolledMembers">>[];
  }, [t]);
};
