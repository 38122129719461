/* eslint-disable max-lines */
import { ConsentStatus } from "@health/queries/types";
import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomIcon, IconButton, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useProfileContext } from "../../context/ProfileContext";
import { useRequestAccess } from "./useRequestAccess";

export const RequestAccessView: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { tokenId, consentStatus, handleResetStatus, patientName, onClose: handleClose } = useProfileContext();
  const { loading, patientProfileConsentFormData, handleReset, handleRequestAccess } = useRequestAccess();
  const PatientName = (
    <TruncateTypography
      sx={{ display: "inline-block" }}
      text={(patientName || t("Patient")) + "'s"}
      fontWeight={theme.mixins.fonts.fontWeight.medium}
    />
  );

  const handleReCreate = () => {
    handleResetStatus?.();
    handleReset();
    handleClose?.();
  };

  return (
    <Box
      sx={{
        zIndex: 4,
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        paddingTop: 50,
        justifyContent: "center",
        backdropFilter: "blur(8px)",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: theme.palette.common.black,
          opacity: 0.4,
        },
      }}
    >
      <Box
        sx={{
          height: "fit-content",
          backgroundColor: "white",
          borderRadius: 3,
          width: "50%",
          boxShadow: theme.mixins.shadows.main,
          zIndex: theme.zIndex.modal,
        }}
      >
        <Box>
          {consentStatus ? (
            <Box
              p={1}
              sx={{
                height: 130,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {consentStatus === ConsentStatus.Approved ? (
                <>
                  <Typography
                    fontSize={theme.mixins.fonts.fontSize.xl}
                    color={theme.palette.success.main}
                    fontWeight={theme.mixins.fonts.fontWeight.medium}
                  >
                    {t("Access Approved From")} {patientName || t("Patient")}
                  </Typography>
                  <IconButton onClick={handleResetStatus}>
                    <CustomIcon icon='xAlert' />
                  </IconButton>
                </>
              ) : (
                <>
                  <Typography fontSize={theme.mixins.fonts.fontSize.xl} color='error' fontWeight={theme.mixins.fonts.fontWeight.medium}>
                    {t("Access Denied from")} {patientName || t("Patient")}
                  </Typography>
                  <IconButton onClick={handleReCreate}>
                    <CustomIcon icon='xAlert' />
                  </IconButton>
                </>
              )}
            </Box>
          ) : (
            <>
              {patientProfileConsentFormData || tokenId ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: 1,
                    }}
                  >
                    <IconButton onClick={handleClose}>
                      <CustomIcon icon='xAlert' />
                    </IconButton>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      height: 150,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <CircularProgress color={"success"} size={60} />
                    <Typography margin={1} fontSize={theme.mixins.fonts.fontSize.xl} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                      {t("Waiting Access From")} {patientName || t("Patient")}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                      {t("Access Denied")}
                    </Typography>
                    <Box>
                      <IconButton onClick={handleClose}>
                        <CustomIcon icon='xAlert' />
                      </IconButton>
                    </Box>
                  </Box>
                  <Typography fontSize={theme.mixins.fonts.fontSize.md} m={3}>
                    <Trans t={t} i18nKey={"request-medical-profile"} components={{ 1: PatientName }} />
                  </Typography>
                  <Box
                    p={1}
                    sx={{
                      boxShadow: theme.mixins.shadows.xs,
                    }}
                  >
                    <Button disabled={loading} onClick={handleRequestAccess}>
                      {loading ? t("Loading...") : t("Send")}
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
