import React, { FC } from "react";
import { PatientHealthParameterChartLineZoom } from "../PatientHealthParameterChartLineZoom/PatientHealthParameterChartLineZoom";
import { IPatientHealthParameterCardEvent, PatientHealthParameterCard } from "../PatientHealthParameterCard/PatientHealthParameterCard";
import { usePatientHealthParameters } from "../../hooks";
import { Grid } from "@toolkit/ui";
import { PatientHealthParameterHistoryModalApi } from "../../modals";

type PatientHealthParameterNumericProps = {
  visitId?: string;
  patientId?: string;
  code: string;
  token: string;
};

export const PatientHealthParameterNumeric: FC<PatientHealthParameterNumericProps> = props => {
  const { visitId, patientId, code, token } = props;

  const { data, loading } = usePatientHealthParameters({
    first: 1,
    visitId,
    patientId,
    token,
    codes: [code],
  });

  const patientHealthParameter = data?.[0];

  const dataSets = data?.filter(item => item?.valueNumericList)?.map(item => ({ data: item?.valueNumericList || [] })) || [];

  const onPatientHealthParameterCardChange = (event: IPatientHealthParameterCardEvent) => {
    if (event.type === "EXPAND_CLICK") {
      PatientHealthParameterHistoryModalApi.open({
        type: "NUMERIC",
        title: patientHealthParameter?.healthParameter?.display!,
        patientId,
        token,
        codes: [code],
      });
    }
  };

  return (
    <PatientHealthParameterCard
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={dataSets?.length ? !visitId : false}
      onChange={onPatientHealthParameterCardChange}
    >
      {dataSets?.length > 0 && (
        <Grid container justifyContent={"center"}>
          <Grid item xs={8}>
            <PatientHealthParameterChartLineZoom dataSets={dataSets} isLoading={loading} />
          </Grid>
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
