/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorAvailableTimeSlotsGetQueryVariables = Types.Exact<{
  doctorId?: Types.InputMaybe<Types.Scalars['ID']>;
  end?: Types.InputMaybe<Types.Scalars['Instant']>;
  start?: Types.InputMaybe<Types.Scalars['Instant']>;
}>;


export type DoctorAvailableTimeSlotsGetQuery = { __typename?: 'Query', doctor?: { __typename?: 'Doctor', availableTimeSlots?: Array<{ __typename?: 'TimeSlot', startTime?: any | null, endTime?: any | null } | null> | null } | null };


export const DoctorAvailableTimeSlotsGetDocument = gql`
    query DoctorAvailableTimeSlotsGet($doctorId: ID, $end: Instant, $start: Instant) {
  doctor(id: $doctorId) {
    availableTimeSlots(end: $end, start: $start) {
      startTime
      endTime
    }
  }
}
    `;

/**
 * __useDoctorAvailableTimeSlotsGetQuery__
 *
 * To run a query within a React component, call `useDoctorAvailableTimeSlotsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorAvailableTimeSlotsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorAvailableTimeSlotsGetQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useDoctorAvailableTimeSlotsGetQuery(baseOptions?: Apollo.QueryHookOptions<DoctorAvailableTimeSlotsGetQuery, DoctorAvailableTimeSlotsGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorAvailableTimeSlotsGetQuery, DoctorAvailableTimeSlotsGetQueryVariables>(DoctorAvailableTimeSlotsGetDocument, options);
      }
export function useDoctorAvailableTimeSlotsGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorAvailableTimeSlotsGetQuery, DoctorAvailableTimeSlotsGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorAvailableTimeSlotsGetQuery, DoctorAvailableTimeSlotsGetQueryVariables>(DoctorAvailableTimeSlotsGetDocument, options);
        }
export type DoctorAvailableTimeSlotsGetQueryHookResult = ReturnType<typeof useDoctorAvailableTimeSlotsGetQuery>;
export type DoctorAvailableTimeSlotsGetLazyQueryHookResult = ReturnType<typeof useDoctorAvailableTimeSlotsGetLazyQuery>;
export type DoctorAvailableTimeSlotsGetQueryResult = Apollo.QueryResult<DoctorAvailableTimeSlotsGetQuery, DoctorAvailableTimeSlotsGetQueryVariables>;