import { UserInfoType } from "shared/configs/oidc";
import { isNurseUser, isPharmacistUser, isVendorAdmin } from "utils";
import { ComingSoon as ComingSoonCard } from "@toolkit/ui";
import { NurseDashboardView } from "./NurseDashboardComponents";
import { UserDoctorDashboardView } from "./UserDoctorDashboardComponents";
import { AdminDoctorDashboardView } from "./AdminDoctorDashboard";

export const getDashboardComponent = (userInfo: UserInfoType) => {
  const isVendorAdminValue = isVendorAdmin(userInfo);
  const isNurse = isNurseUser(userInfo);
  const isPharmacist = isPharmacistUser(userInfo);
  let DashboardPage: React.FC = UserDoctorDashboardView;

  if (isVendorAdminValue) {
    DashboardPage = AdminDoctorDashboardView;
  }

  if (isNurse) {
    DashboardPage = NurseDashboardView;
  }
  if (isPharmacist) {
    DashboardPage = ComingSoonCard;
  }
  return DashboardPage;
};
