import { Grid } from "@toolkit/ui";
import { UpcomingActivities } from "./UpcomingActivities";
import { CareJourneyGaps } from "./CareJourneyGaps";

export const Activities = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <UpcomingActivities />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <CareJourneyGaps />
      </Grid>
    </Grid>
  );
};
