import { OrderLine } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { OrderSection } from "./OrderSection";
import { useOrdersFromCheckoutStyles } from "./ordersFromCheckout.styles";

type OrdersFromCheckoutProps = {
  city: string;
  number: number;
  color: string;
  products: OrderLine[];
  timeOutComponent: JSX.Element | boolean;
};

export const OrdersFromCheckout: FC<OrdersFromCheckoutProps> = props => {
  const { timeOutComponent, number, products, city, color } = props;
  const { t } = useTranslation("provider");
  const { classes } = useOrdersFromCheckoutStyles();

  return (
    <>
      {Boolean(timeOutComponent) && timeOutComponent}
      <OrderSection title={t("Order ID")} subTitle={String(number)!} color={color} />
      <OrderSection hasBG title={t("Products")} color={color}>
        {products?.map(line => (
          <Box key={line?.id} className={classes.box}>
            <Avatar variant='square' alt={line?.thumbnail?.alt!} src={line?.thumbnail?.url!} className={classes.avatar} />
            <div>
              <Typography className={classes.text}>
                {t("sku")}: {line.productSku}
              </Typography>
              <Typography className={classes.text}>
                {t("Qty")}: {line.quantity}
              </Typography>
            </div>
          </Box>
        ))}
      </OrderSection>
      <OrderSection title={t("Delivery Address")} subTitle={city} color={color} />
    </>
  );
};
