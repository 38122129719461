import { getAutocompleteFilter } from "@toolkit/ui";
import { usePatientsAutocompleteQuery } from "./gql";
export const getPatientsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "patients",
        query: usePatientsAutocompleteQuery,
        labelBy: option => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.user) === null || _a === void 0 ? void 0 : _a.fullName; },
        backendAccessor: "id",
        filterSearchKey: "firstName_Icontains",
    });
};
