import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { CurrentWorkingBranchSelectionItem } from "./CurrentWorkingBranch/CurrentWorkingBranchSelection";
import { NurseCurrentWorkSpaceDetailsItem } from "./NurseCurrentWorkspace/NurseCurrentWorkspaceSelection";
import { isNurseUser } from "utils";

export const UserWorkspaceSelection = () => {
  const { userInfo } = useOidcUserProfile();

  if (isNurseUser(userInfo)) return <NurseCurrentWorkSpaceDetailsItem />;

  return <CurrentWorkingBranchSelectionItem />;
};
