import { i18n } from "@toolkit/i18n";
import { dashboardPaths } from "./dashboardPaths.constants";

export const DashboardTitleBreadcrumbs = () => i18n.t("Dashboard", { ns: "provider" });

export const DashboardBreadcrumb = () => ({
  id: "dashboard",
  name: i18n.t("Dashboard", { ns: "provider" }),
  route: dashboardPaths.indexPage.fullPath,
});
