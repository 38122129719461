import { Box, CircularProgress, Divider, InfiniteScroll, Stack, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { NotificationItem } from "./NotificationItem";
import { NotificationItemWrapper } from "./NotificationItemWrapper";
import { NotificationsListProps } from "./types";

export const NotificationsList: FC<NotificationsListProps> = props => {
  const { hasMore, notifications, fetchMoreData: next, isDisplayedInDropDown } = props;
  const theme = useTheme();
  return (
    <InfiniteScroll
      dataLength={notifications?.length || 0}
      next={next}
      scrollableTarget='scrollable-id'
      hasMore={Boolean(hasMore)}
      loader={
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
          <CircularProgress size={30} sx={{ mx: 1, my: 1 }} />
        </Box>
      }
    >
      <Stack
        divider={
          <Divider
            light
            variant='middle'
            sx={{
              backgroundColor: theme.palette.gray.light,
              marginInline: "14px",
            }}
          />
        }
      >
        {notifications?.map(item => {
          return (
            <NotificationItemWrapper
              key={item?.id}
              id={item?.id}
              isSeen={item?.seen!}
              sx={{
                padding: 1,
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              renderNotificationItem={({ handleNotificationActionComplete }) => (
                <NotificationItem
                  type={item?.type!}
                  id={JSON.parse(String(item?.data))?.id}
                  data={item?.data && JSON.parse(String(item?.data!))}
                  isDisplayedInDropDown={isDisplayedInDropDown}
                  handleNotificationActionComplete={handleNotificationActionComplete}
                />
              )}
            ></NotificationItemWrapper>
          );
        })}
      </Stack>
    </InfiniteScroll>
  );
};
