import { TFunction } from "@toolkit/i18n";
import { healthProviderDepartmentsPaths } from "../constants/HealthProvidersManagementPaths";

export const departmentListBreadCrumb = (t: TFunction) => {
  return {
    id: healthProviderDepartmentsPaths.indexPage.fullPath,
    name: t("Departments", { ns: "provider" }),
    route: healthProviderDepartmentsPaths.indexPage.fullPath,
  };
};

export const departmentNewBreadCrumb = (t: TFunction) => {
  return {
    id: healthProviderDepartmentsPaths.addNew.fullPath,
    name: t("New", { ns: "provider" }),
    // route: healthProviderDepartmentsPaths.addNew.fullPath
  };
};

export const departmentEditBreadCrumb = (departmentName: string | undefined) => {
  return {
    id: healthProviderDepartmentsPaths.edit.fullPath,
    name: departmentName || "",
    // route: healthProviderDepartmentsPaths.edit.fullPath.replace(":id", departmentId)
  };
};
