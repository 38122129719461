import { GuidedCareJourneyItem } from "@health/queries/types";
import { Loader, MonthlyTimeLine } from "@toolkit/ui";
import { FC } from "react";
import { JourneyDurationPeriod } from "shared/modules/patient/types";
import { MonthlyTimeLineItem } from "./MonthlyTimeLineItem";
import { useAnnualTimeLineHooks } from "./useAnnualTimeLineHooks";

type MonthlyTimeLineProps = {
  source?: string;
  hasSimilar: boolean;
  duration: JourneyDurationPeriod;
  selectedTeamMemberPosition: string;
  selectedMe: string;
  selectedActivities: string;
  selectItem: GuidedCareJourneyItem;
  onSelectItem: (item: GuidedCareJourneyItem) => void;
};

export const MonthlyTimeLineView: FC<MonthlyTimeLineProps> = ({
  source,
  duration,
  hasSimilar,
  selectedTeamMemberPosition,
  selectedMe,
  selectItem,
  selectedActivities,
  onSelectItem,
}) => {
  const { year, step, filterInput, loading, timeline } = useAnnualTimeLineHooks({
    source,
    duration,
    selectedMe,
    selectedActivities,
    selectedTeamMemberPosition,
    onSelectItem,
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <MonthlyTimeLine
          currentStep={step!}
          numberOfItemsToShow={2}
          timeline={
            (timeline?.map(tl => ({
              label: tl.label,
              activities: tl?.activities!,
              activitiesWeeks: {
                activitiesWeeks1: tl.activitiesWeeks?.activitiesWeeks1!,
                activitiesWeeks2: tl.activitiesWeeks?.activitiesWeeks2!,
                activitiesWeeks3: tl.activitiesWeeks?.activitiesWeeks3!,
                activitiesWeeks4: tl.activitiesWeeks?.activitiesWeeks4!,
              },
            }))! || []) as any
            // TODO: Fix this any
          }
        >
          <MonthlyTimeLineItem
            year={year}
            filterInput={filterInput}
            hasSimilar={hasSimilar}
            onSelectItem={onSelectItem}
            selectItem={selectItem}
          />
        </MonthlyTimeLine>
      )}
    </>
  );
};
