import { useTranslation } from "@toolkit/i18n";
import { Box, Calender, Grid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const VisitsView: FC<{ number: string }> = ({ number }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <>
      <Grid item xs='auto'>
        <Box display='flex' justifyContent='center' alignItems='center' width='90px' height='85px' bgcolor={theme.palette.success.main}>
          <Calender />
        </Box>
      </Grid>
      <Grid item xs>
        <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
          <Typography fontWeight='bold'>{t("Visit starting now")}</Typography>
          <Typography variant='body2'>
            {t("Virtual visit")} #{number}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
