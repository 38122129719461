import { VisitStatus as VisitStatusEnum } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { FC, useCallback, useEffect, useState } from "react";
import { PageTabsStatusType, PageTabsType } from "shared/components/PageTabs";
import { ProgramsCalls } from "./ProgramsCalls";
import { NewVisit } from "./components/NewRequests/NewVisit";
import { useTabs, VisitTabsStatus } from "./utils";

export const ProgramsCallsContent: FC = () => {
  const { t } = useTranslation("provider");
  const [state, setState] = useState<PageTabsStatusType>(VisitTabsStatus.newRequested);
  const [isSelfOnly, setIsSelfOnly] = useState(true);
  const handleToggleOnlyMyVisit = (_, value) => {
    setIsSelfOnly(value);
  };
  const data = useTabs({ isSelfOnly }) as PageTabsType;
  const { setBreadCrumb } = useBreadCrumbs();

  const handleChangeTab = (status: PageTabsStatusType) => {
    setState(status);
  };
  const handleVisitAccepted = useCallback(() => {
    setState(VisitTabsStatus.activeCall);
  }, []);

  useEffect(() => {
    setBreadCrumb({ title: t("Virtual Visits"), values: [] });
  }, []);

  switch (state) {
    case VisitTabsStatus.newRequested:
      return <NewVisit state={state} tabs={data} onChangeTab={handleChangeTab} onVisitAccepted={handleVisitAccepted} />;
    case VisitTabsStatus.activeCall:
      return (
        <ProgramsCalls
          state={state}
          tabs={data}
          onChangeTab={handleChangeTab}
          status={[VisitStatusEnum.CallInProgress, VisitStatusEnum.CallRejoinable]}
          isSelfOnly={isSelfOnly}
          handleToggleOnlyMyVisit={handleToggleOnlyMyVisit}
        />
      );
    case VisitTabsStatus.incomplete:
      return (
        <ProgramsCalls
          state={state}
          tabs={data}
          onChangeTab={handleChangeTab}
          status={[VisitStatusEnum.Incompleted]}
          isSelfOnly={isSelfOnly}
          handleToggleOnlyMyVisit={handleToggleOnlyMyVisit}
        />
      );
    default:
    case VisitTabsStatus.completed:
      return (
        <ProgramsCalls
          state={state}
          tabs={data}
          onChangeTab={handleChangeTab}
          status={[VisitStatusEnum.Completed]}
          isSelfOnly={isSelfOnly}
          handleToggleOnlyMyVisit={handleToggleOnlyMyVisit}
        />
      );
  }
};

export const ProgramsCallsPage: FC = () => {
  return <ProgramsCallsContent />;
};
