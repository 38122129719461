import { makeStyles } from "@toolkit/ui";

export const useDoctorConsoleStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.common.black,
    zIndex: 3,
    height: "inherit",
    width: "100px",
    animation: "doctor-console-animation 0.4s",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
    "@keyframes doctor-console-animation": {
      from: {
        transform: "translateX(-100%)",
      },
      to: {
        transform: "translateX(0)",
      },
    },
  },
  menu: {
    borderRight: "1px solid #56565696",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.common.white,
    borderRadius: "0",
    position: "relative",
    boxShadow: "none",
    background: "inherit",
    height: "unset",
    fontSize: theme.mixins.fonts.fontSize.xs,
    margin: 0,
    gap: 4,
    "&.active, &:hover": {
      "&:before": {
        content: `""`,
        position: "absolute",
        left: -6,
        background: theme.palette.common.white,
        top: 0,
        bottom: 0,
        width: 10,
        borderRadius: 4,
      },
    },
    "&:last-of-type": {
      minHeight: 75,
    },
  },
  content: {
    position: "absolute",
    overflow: "hidden",
    display: "none",
    top: 0,
    left: "91px",
    zIndex: 5,

    height: "100%",
    minWidth: "50vw",
    width: "60vw",

    [theme.breakpoints.down("md")]: {
      width: "75vw",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      left: 0,
    },

    "&.open": {
      display: "block",
    },
  },
}));
