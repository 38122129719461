const PatientProfileIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.494 23.518' width={22.494} height={23.518} {...props}>
      <path
        d='M11.247 0a6.135 6.135 0 0 0-6.134 6.135v1.023a6.135 6.135 0 1 0 12.27 0V6.135A6.135 6.135 0 0 0 11.247 0Zm0 16.36c-4.1 0-9.352 2.216-10.864 4.182a1.857 1.857 0 0 0 1.488 2.976h18.753a1.857 1.857 0 0 0 1.488-2.976c-1.512-1.965-6.77-4.182-10.867-4.182Z'
        fill='#fff'
      />
    </svg>
  );
};

export default PatientProfileIcon;
