export const marketplaceTrackingOrdersRoute = `marketplace-orders-tracking`;

export const marketplaceOrdersTrackingPaths = {
  mainPath: {
    route: "",
    get fullPath() {
      return `${marketplaceTrackingOrdersRoute}${this.route}`;
    },
  },
};
