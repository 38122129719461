import { Box } from "@toolkit/ui";
import { FC, ReactNode } from "react";
import { useProfileContext } from "../../context/ProfileContext";
import { PatientProfileSection } from "./PatientProfileSection";
import { usePatientProfileTabContainerStyle } from "./PatientProfileTabContainerStyle";
import { RequestAccessView } from "./RequestAccessView";

export const PatientProfileTabContainer: FC<{
  sections: Array<{
    title?: string;
    subTitle?: string;
    content: ReactNode;
  }>;
  isContainer: boolean;
  isRoot: boolean;
}> = ({ sections, isContainer, isRoot }) => {
  const { classes } = usePatientProfileTabContainerStyle();
  const { checkAccess, selectedTab } = useProfileContext();
  const rootClasses = selectedTab === "1" ? `${classes.root} ${classes.rootTab}` : classes.root;

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {!checkAccess && <RequestAccessView />}

      {sections.map((item, index) => (
        <div className={isContainer ? classes.container : classes.content} key={index}>
          <Box sx={{ padding: 0 }} className={isRoot ? rootClasses : classes.defaultRoot}>
            <PatientProfileSection title={item?.title} subTitle={item?.subTitle} content={item.content} />
          </Box>
        </div>
      ))}
    </Box>
  );
};
