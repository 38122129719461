import { AccountError, UserUpdateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { healthProviderUserManagementPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserUpdateInfo } from "../UserManagementForm.util";
import { userManagementEditBreadCrumb, userManagementListBreadCrumb } from "../Users.constant";
import { VendorUserQuery, VendorUserUpdateMutation, useVendorUserQuery, useVendorUserUpdateMutation } from "../gql";

export const useUserManagementEditPageHook = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("provider");
  const [errors, setErrors] = React.useState<AccountError[]>([]);
  const { succeeded, failed } = useAddToast();
  const params = useParams();

  const [vendorUserUpdateMutation, { loading: isUpdateLoading }] = useVendorUserUpdateMutation({
    onCompleted: (mutationData: VendorUserUpdateMutation) => {
      const mutationErrors = mutationData?.userUpdate?.accountErrors;
      if (mutationErrors && mutationErrors.length > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
        setErrors(mutationErrors);
      } else {
        succeeded(t("User updated successfully"));
        navigate(healthProviderUserManagementPaths.indexPage.fullPath);
      }
    },
    onError: () => {
      failed(t("Failed to update user"));
    },
  });

  const { data, loading: isLoadingData } = useVendorUserQuery({
    variables: {
      userId: String(params?.id),
    },
    fetchPolicy: "no-cache",
    onCompleted: (user: VendorUserQuery) => {
      if (!user?.user) {
        failed(t("User not found"));
      }
    },
  });

  const handleUpdateUser = (inputData: UserUpdateInput | any) => {
    const request = getUserUpdateInfo(inputData);
    delete request?.vendorUserType;
    vendorUserUpdateMutation({
      variables: {
        id: String(params?.id),
        input: request,
      },
    });
  };
  const userDefaultValues = data?.user;
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: t("Users Management"),
      values: [userManagementListBreadCrumb(t), userManagementEditBreadCrumb(userDefaultValues?.firstName)],
    });
  }, [userDefaultValues?.firstName]);

  return {
    isUpdateLoading,
    errors,
    handleUpdateUser,
    isLoadingData,
    userDefaultValues,
  };
};
