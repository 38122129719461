import { FC, PropsWithChildren, useEffect, useState } from "react";
import { ProfileContext } from "./ProfileContext";
import { useCustomEvaluatePatientHealthConditionsMutation } from "./useCustomEvaluatePatientHealthConditionsMutation";
import { useCustomGetPatientDataQuery } from "./useCustomGetPatientDataQuery";
import { useCustomGuidedCareHealthProgramTeamsQuery } from "./useCustomGuidedCareHealthProgramTeamsQuery";
import { useCustomPatientMedicalProfileQuery } from "./useCustomPatientMedicalProfileQuery";

type ProfileContextProviderProps = PropsWithChildren<{
  patientId: string | undefined;
  isOpen: boolean;
  token?: string;
  tokenId?: string;
  defaultSelectedTab?: string;
  defaultProgramId?: string;
  onClose?: () => void;
}>;

export const ProfileContextProvider: FC<ProfileContextProviderProps> = props => {
  const { onClose, isOpen, patientId, token, tokenId, defaultSelectedTab = "0", defaultProgramId, children } = props;

  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
  const [selectedHealthProgram, setSelectedHealthProgram] = useState<string | undefined>(undefined);

  const handleProgramSelected = (programId?: string) => {
    setSelectedHealthProgram(programId);
  };

  const handleTabChange = newValue => {
    setSelectedTab(String(newValue));
  };

  const { conditions, evaluatePatientHealthConditionsMutationLoading, getEvaluatePatientHealthConditionsMutation } =
    useCustomEvaluatePatientHealthConditionsMutation({
      token,
    });

  const { getPatientDataQuery, patient, patientDataQueryLoading, patientName, userId } = useCustomGetPatientDataQuery({
    token,
  });

  const {
    consentStatus,
    handleResetStatus,
    hasAccess,
    patientCurrentMedicalStatus,
    patientHealthHistory,
    patientMedicalProfileQueryLoading,
    getPatientMedicalProfileQuery,
  } = useCustomPatientMedicalProfileQuery({
    patientId,
    token,
  });

  const {
    getProviderGuidedCareHealthProgramTeamsQuery,
    guidedCareHealthProgramTeamFetchMore,
    guidedCareHealthProgramTeamPageInfo,
    guidedCareHealthPrograms,
    guidedCareProgramTeamLeaderPhoto,
    guidedCareProgramTeamMembers,
    guidedCareProgramTemplateId,
    guidedCareProgramTeamMembersWithoutTeamLeader,
    hasGuidedCareHealthProgramTeam,
  } = useCustomGuidedCareHealthProgramTeamsQuery({
    patientId,
    token,
    selectedHealthProgram,
  });

  useEffect(() => {
    if (!selectedHealthProgram) {
      if (defaultProgramId) {
        const defaultProgramExist = !!guidedCareHealthPrograms?.find(item => item?.id === defaultProgramId);
        setSelectedHealthProgram(defaultProgramExist ? defaultProgramId : guidedCareHealthPrograms?.[0]?.id);
      } else {
        setSelectedHealthProgram(guidedCareHealthPrograms?.[0]?.id);
      }
    }
  }, [defaultProgramId, guidedCareHealthPrograms, selectedHealthProgram]);

  useEffect(() => {
    setSelectedTab(defaultSelectedTab);
  }, [defaultSelectedTab]);

  useEffect(() => {
    if (isOpen && patientId) {
      getPatientMedicalProfileQuery({
        variables: {
          patientId,
        },
      });

      getPatientDataQuery({
        variables: {
          patientId,
        },
      });

      getEvaluatePatientHealthConditionsMutation({
        variables: {
          patientId,
        },
      });

      getProviderGuidedCareHealthProgramTeamsQuery({
        variables: {
          first: 10,
          filter: {
            patients: [patientId!],
          },
        },
      });
    }
  }, [patientId, isOpen, consentStatus]);

  const loading = evaluatePatientHealthConditionsMutationLoading || patientDataQueryLoading || patientMedicalProfileQueryLoading;

  return (
    <ProfileContext.Provider
      value={{
        patientId,
        userId,
        patient,
        conditions,
        patientName,
        selectedTab,
        selectedHealthProgram,
        patientCurrentMedicalStatus,
        patientHealthHistory,
        checkAccess: hasAccess,
        consentStatus,
        loading,
        isOpen,
        onClose,
        handleTabChange,
        handleResetStatus,
        handleProgramSelected,
        token,
        tokenId,
        guidedCareHealthProgramTeamFetchMore,
        guidedCareHealthProgramTeamPageInfo,
        guidedCareHealthPrograms,
        guidedCareProgramTeamLeaderPhoto,
        guidedCareProgramTeamMembers,
        guidedCareProgramTemplateId,
        guidedCareProgramTeamMembersWithoutTeamLeader,
        hasGuidedCareHealthProgramTeam,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
