import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formGirdSpacing, useCustomForm, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { MedicalFormClassificationsForm, MedicalFormInformationForm, MedicalFormQuestionsForm } from "../../../MedicalForms/forms";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertMedicalFormToFormValues } from "../../others";
import { medicalFormUpsertFormDefaultValues, medicalFormUpsertFormSchema, } from "./MedicalFormUpsertFormSchema";
import { useMedicalFormUpsertFormStyle } from "./useMedicalFormUpsertFormStyle";
export const MedicalFormUpsertForm = props => {
    const { isAdmin, medicalForm, submitButtonLabel, isLoading, isSubmitDisabled, handleCancel, onChange } = props;
    const { t } = useTranslation("domains");
    const { open, handleOpen, handleClose } = useOpenState();
    const { classes } = useMedicalFormUpsertFormStyle();
    const form = useCustomForm({
        defaultValues: medicalFormUpsertFormDefaultValues,
        schema: medicalFormUpsertFormSchema,
    });
    const { setValues, handleSubmit, reset } = form;
    const onSubmit = (values) => {
        onChange({
            type: "SUBMIT",
            payload: { values },
        });
    };
    const onResetFieldsClick = () => {
        handleOpen();
    };
    const onWarningConfirmationDialogConfirm = () => {
        reset();
        handleClose();
    };
    useEffect(() => {
        if (medicalForm) {
            const _medicalForm = convertMedicalFormToFormValues(medicalForm);
            setValues(_medicalForm);
        }
    }, [medicalForm, setValues]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(PageWrapper, { actions: _jsxs(Box, { className: classes.formActionsWrapper, children: [_jsx(Button, { type: "submit", variant: "contained", color: "primary", disabled: isSubmitDisabled, startIcon: isSubmitDisabled && _jsx(CircularProgress, { size: 15 }), children: submitButtonLabel }), _jsx(Button, { variant: "outlined", className: classes.resetFieldsButton, onClick: onResetFieldsClick, children: t("Reset Fields") }), _jsx(Button, { variant: "outlined", onClick: handleCancel, children: t("Cancel") })] }), children: _jsxs(Grid, { container: true, spacing: formGirdSpacing, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormCard, { title: t("Medical Form Information"), loading: isLoading, doYouHaveData: true, children: _jsx(MedicalFormInformationForm, { isAdmin: !!isAdmin }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormCard, { loading: isLoading, doYouHaveData: true, children: _jsx(MedicalFormQuestionsForm, {}) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormCard, { loading: isLoading, doYouHaveData: true, children: _jsx(MedicalFormClassificationsForm, {}) }) })] }) }), _jsx(CustomDialog, { type: "warning", text: {
                        body: t("Are you sure you want to reset all fields?"),
                        title: t("Reset Fields"),
                    }, isOpen: open, onClose: handleClose, onConfirm: onWarningConfirmationDialogConfirm })] }) })));
};
