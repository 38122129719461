import { makeStyles } from "tss-react/mui";

export const usePatientHealthParameterCardStyle = makeStyles()(theme => ({
  root: {
    padding: "8px 16px 24px",
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    marginBottom: 5,
  },
  date: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.stale.main,
    whiteSpace: "nowrap",
  },
  source: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.stale.main,
  },
}));
