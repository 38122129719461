import { makeStyles } from "@toolkit/ui";

export const useAppointmentAcceptStyle = makeStyles()(theme => ({
  expiredDateWrapper: {
    width: "100%",
  },
  expiredDateMessage: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    marginTop: 8,
    marginLeft: 5,
  },
}));
