/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiagnosisPredictionMutationVariables = Types.Exact<{
  input: Types.DiagnosisPredictionInput;
}>;


export type DiagnosisPredictionMutation = { __typename?: 'Mutation', diagnosisPrediction?: { __typename?: 'DiagnosisPredictionMutation', diagnosis?: Array<string | null> | null, diagnosisPredictionsErrors: Array<{ __typename?: 'DiagnosisPredictionError', code: Types.DiagnosisPrescriptionErrorCode, field?: string | null, message?: string | null }> } | null };


export const DiagnosisPredictionDocument = gql`
    mutation DiagnosisPrediction($input: DiagnosisPredictionInput!) {
  diagnosisPrediction(input: $input) {
    diagnosis
    diagnosisPredictionsErrors {
      code
      field
      message
    }
  }
}
    `;
export type DiagnosisPredictionMutationFn = Apollo.MutationFunction<DiagnosisPredictionMutation, DiagnosisPredictionMutationVariables>;

/**
 * __useDiagnosisPredictionMutation__
 *
 * To run a mutation, you first call `useDiagnosisPredictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisPredictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [diagnosisPredictionMutation, { data, loading, error }] = useDiagnosisPredictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiagnosisPredictionMutation(baseOptions?: Apollo.MutationHookOptions<DiagnosisPredictionMutation, DiagnosisPredictionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiagnosisPredictionMutation, DiagnosisPredictionMutationVariables>(DiagnosisPredictionDocument, options);
      }
export type DiagnosisPredictionMutationHookResult = ReturnType<typeof useDiagnosisPredictionMutation>;
export type DiagnosisPredictionMutationResult = Apollo.MutationResult<DiagnosisPredictionMutation>;
export type DiagnosisPredictionMutationOptions = Apollo.BaseMutationOptions<DiagnosisPredictionMutation, DiagnosisPredictionMutationVariables>;