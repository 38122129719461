/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorTeamEnrolledMembersQueryVariables = Types.Exact<{
  before?: Types.InputMaybe<Types.Scalars['String']>;
  doctorId?: Types.InputMaybe<Types.Scalars['ID']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  teamId?: Types.InputMaybe<Types.Scalars['ID']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DoctorTeamEnrolledMembersQuery = { __typename?: 'Query', doctorTeamEnrolledMembers?: { __typename?: 'HealthProgramMemberCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberCountableEdge', node: { __typename?: 'HealthProgramMember', firstName?: string | null, lastName?: string | null, sehacityId?: string | null, nationalId?: string | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, user?: { __typename?: 'User', id: string, photo?: string | null } | null } | null } }> } | null };


export const DoctorTeamEnrolledMembersDocument = gql`
    query DoctorTeamEnrolledMembers($before: String, $doctorId: ID, $first: Int, $last: Int, $teamId: ID, $after: String) {
  doctorTeamEnrolledMembers(
    before: $before
    doctorId: $doctorId
    first: $first
    last: $last
    teamId: $teamId
    after: $after
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        firstName
        lastName
        sehacityId
        patient {
          id
          firstName
          lastName
          user {
            id
            photo
          }
        }
        nationalId
      }
    }
  }
}
    `;

/**
 * __useDoctorTeamEnrolledMembersQuery__
 *
 * To run a query within a React component, call `useDoctorTeamEnrolledMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorTeamEnrolledMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorTeamEnrolledMembersQuery({
 *   variables: {
 *      before: // value for 'before'
 *      doctorId: // value for 'doctorId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      teamId: // value for 'teamId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDoctorTeamEnrolledMembersQuery(baseOptions?: Apollo.QueryHookOptions<DoctorTeamEnrolledMembersQuery, DoctorTeamEnrolledMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorTeamEnrolledMembersQuery, DoctorTeamEnrolledMembersQueryVariables>(DoctorTeamEnrolledMembersDocument, options);
      }
export function useDoctorTeamEnrolledMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorTeamEnrolledMembersQuery, DoctorTeamEnrolledMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorTeamEnrolledMembersQuery, DoctorTeamEnrolledMembersQueryVariables>(DoctorTeamEnrolledMembersDocument, options);
        }
export type DoctorTeamEnrolledMembersQueryHookResult = ReturnType<typeof useDoctorTeamEnrolledMembersQuery>;
export type DoctorTeamEnrolledMembersLazyQueryHookResult = ReturnType<typeof useDoctorTeamEnrolledMembersLazyQuery>;
export type DoctorTeamEnrolledMembersQueryResult = Apollo.QueryResult<DoctorTeamEnrolledMembersQuery, DoctorTeamEnrolledMembersQueryVariables>;