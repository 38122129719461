import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import Chat from "./Chat";

const chatRoute = "/chat";

export const chatPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${chatRoute}${this.route}`;
    },
  },
};

export const chatRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: chatRoute,
    route: chatRoute,
    fullPath: chatPaths.indexPage.fullPath,
    text: t("Chat", { ns: "provider" }),
    element: <Chat />,
    onClick: (route: string) => navigate(route),
    hidden: true,
  };
};
