/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorVisitAcceptMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  branchId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type DoctorVisitAcceptMutation = { __typename?: 'Mutation', doctorVisitAccept?: { __typename?: 'VisitApprovalAction', visit?: { __typename?: 'Visit', id: string, number?: string | null, status?: Types.VisitStatus | null } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, message?: string | null, field?: string | null }> | null } | null };


export const DoctorVisitAcceptDocument = gql`
    mutation doctorVisitAccept($id: ID!, $branchId: ID) {
  doctorVisitAccept(id: $id, branchId: $branchId) {
    visit {
      id
      number
      status
    }
    visitErrors {
      code
      message
      field
    }
  }
}
    `;
export type DoctorVisitAcceptMutationFn = Apollo.MutationFunction<DoctorVisitAcceptMutation, DoctorVisitAcceptMutationVariables>;

/**
 * __useDoctorVisitAcceptMutation__
 *
 * To run a mutation, you first call `useDoctorVisitAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorVisitAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorVisitAcceptMutation, { data, loading, error }] = useDoctorVisitAcceptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useDoctorVisitAcceptMutation(baseOptions?: Apollo.MutationHookOptions<DoctorVisitAcceptMutation, DoctorVisitAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorVisitAcceptMutation, DoctorVisitAcceptMutationVariables>(DoctorVisitAcceptDocument, options);
      }
export type DoctorVisitAcceptMutationHookResult = ReturnType<typeof useDoctorVisitAcceptMutation>;
export type DoctorVisitAcceptMutationResult = Apollo.MutationResult<DoctorVisitAcceptMutation>;
export type DoctorVisitAcceptMutationOptions = Apollo.BaseMutationOptions<DoctorVisitAcceptMutation, DoctorVisitAcceptMutationVariables>;