import React, { FC } from "react";
import { Box, Typography, useTheme } from "../../base/mui";
import { UploadIcon1 } from "../../icons";
import { FileInput, FileUploaderLabel, FileUploaderRoot } from "./styles";
import { FileUploaderProps } from "./types";
import useFileUploader from "./useFileUploader";

const FileUploader: FC<FileUploaderProps> = props => {
  const { t, handleDragLeave, handleDragOver, handleDrop, handleFilesSelected } = useFileUploader(props);
  const { inputId, accept, multiple, icon, maxFileSize, child, disabled } = props;
  const theme = useTheme();

  return (
    <FileUploaderRoot onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
      <FileInput
        disabled={disabled}
        accept={accept}
        id={`file-uploader-${inputId}`}
        multiple={multiple}
        type='file'
        onChange={e => handleFilesSelected(e.target.files!)}
      />

      <FileUploaderLabel htmlFor={`file-uploader-${inputId}`}>
        <Typography variant='body1' align='center' sx={{ display: "inline" }} fontSize={"16px"} color={"#5B7798"}>
          {t("Drag and drop a file, or")}{" "}
          <Typography color={theme.palette.primary.main} sx={{ textDecoration: "underline", display: "inline", cursor: "pointer" }}>
            {t("browse")}
          </Typography>{" "}
          {t("for file.")}
        </Typography>
        <Box
          sx={{
            marginTop: "20px",
            "& .MuiSvgIcon-root ": {
              width: "146px",
              height: "102px",
            },
          }}
        >
          {icon ? icon : <UploadIcon1 width='146' height='102' />}
        </Box>
        {maxFileSize && (
          <Typography
            marginTop={"13PX"}
            variant='body2'
            align='center'
            sx={{ display: "inline" }}
            fontSize={"16px"}
            fontWeight={theme.mixins.fonts.fontWeight.semiBold}
            color={theme.palette.primary.main}
          >
            {t("Max file size:")} {5}MB
          </Typography>
        )}
        {child}
      </FileUploaderLabel>
    </FileUploaderRoot>
  );
};

export default FileUploader;
