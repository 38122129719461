import { useReactiveVar } from "@apollo/client";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, isNotificationMenuOpenedVar, Typography, useTheme } from "@toolkit/ui";
import { FC, MouseEvent, MouseEventHandler } from "react";
import { PatientProfile } from "../containers";

type AccessResponseNotificationsProps = {
  data: {
    consentStatus: string;
    patientId: string;
    name: {
      firstName: string | null | undefined;
      lastName: string | null | undefined;
    };
  };
};
export const AccessResponseNotifications: FC<AccessResponseNotificationsProps> = ({ data }) => {
  const { t } = useTranslation("provider");

  const isNotificationMenuOpened = useReactiveVar(isNotificationMenuOpenedVar);

  const handleButtonClick = (onClick: MouseEventHandler<HTMLButtonElement>) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    isNotificationMenuOpened && onClick(e);
  };

  const theme = useTheme();
  return (
    <Box display='flex' gap={1} m={2} width='100%'>
      <Box
        sx={{
          backgroundColor: data?.consentStatus === "APPROVED" ? theme.palette.success.main : theme.palette.secondary.main,
          width: "35px",
          height: "35px",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <CustomIcon icon='group' color='white' width={22} height={25} viewBox='0 0 28 28' />
      </Box>
      <div>
        <Typography whiteSpace='nowrap'>
          {t("Profile access Response For")}
          <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{formatUserNameFull(data?.name!)}</Typography>
        </Typography>
        <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} color={data?.consentStatus !== "APPROVED" ? "error" : "green"}>
          {t("Status")}: {data?.consentStatus === "APPROVED" ? t("Approved") : t("Rejected")}
        </Typography>

        <PatientProfile
          patientId={data?.patientId}
          renderModalButton={({ onClick }) => {
            return (
              <Button variant='outlined' color='primary' size='small' onClick={handleButtonClick(onClick!)}>
                {data?.consentStatus === "APPROVED" ? t("View Profile") : t("Re-Send")}
              </Button>
            );
          }}
        />
      </div>
    </Box>
  );
};
