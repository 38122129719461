import { TeamMemberPosition } from "@health/queries/types";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";

export const GuidedCareTeamUpsertFormSchema = z.object({
  name: z.string().min(1, { message: "Required" }).max(70),
  description: z.string().min(1, { message: "Required" }).max(255),
  maxNumberOfPatients: z.coerce
    .number({
      invalid_type_error: "Required",
    })
    .min(1),
  program: createZodAutocompleteObject(z.object({ id: z.string(), name: z.string() })),
  branch: createZodAutocompleteObject(z.object({ id: z.string(), name: z.string() })),
  isActive: z.boolean(),
  teamMembers: z.array(
    z.object({
      id: z.string().optional(),
      programTeamMemberId: z.string(),
      position: createZodAutocompleteObject(TeamMemberPosition),
      specialization: createZodAutocompleteObject(z.object({ code: z.string(), display: z.string() })).nullable(),
      isLicencedHealthProfessional: z.boolean(),
      isBackup: z.boolean(),
      isKeyTeamMember: z.boolean(),
      user: createZodAutocompleteObject(z.object({ id: z.string(), fullName: z.string() })),
    })
  ),
});

export type IGuidedCareTeamUpsertFormValues = z.infer<typeof GuidedCareTeamUpsertFormSchema>;

export const guidedCareTeamUpsertFormDefaultValues: Partial<IGuidedCareTeamUpsertFormValues> = {
  name: "",
  description: "",
  maxNumberOfPatients: undefined,
  program: undefined,
  branch: undefined,
  isActive: true,
  teamMembers: [],
};
