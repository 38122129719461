import callNotificationSound from "assets/sounds/callNotificationSound.mp3";
import { useRef, useState } from "react";

const VIBRATION_PERIOD = 600;
const VIBRATION_BREAK = 900;
const RINGING_PERIOD = 25000;
const RINGING_RETRIES = 5;
const RINGING_RETRY_INTERVAL = 2000;

const useVisitStartingNowNotification = () => {
  const [audio] = useState(() => new Audio(callNotificationSound));
  const vibrationInterval = useRef<any>();

  const playSound = () => {
    let retryCount = 0;

    audio.loop = true;
    audio.currentTime = 0;

    const tryPlayAudio = () => {
      const playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            clearInterval(retryTimer);
          })
          .catch(() => {
            retryCount++;
            if (retryCount > RINGING_RETRIES) {
              clearInterval(retryTimer);
              console.error("Failed to play audio after retries");
            }
          });
      }
    };

    const retryTimer = setInterval(tryPlayAudio, RINGING_RETRY_INTERVAL);
    tryPlayAudio();
  };

  const stopSound = () => {
    audio.pause();
    audio.currentTime = 0;
  };

  const startVibration = () => {
    vibrationInterval.current = setInterval(() => {
      if ("vibrate" in navigator) {
        navigator.vibrate(VIBRATION_PERIOD);
      }
    }, VIBRATION_PERIOD + VIBRATION_BREAK);
  };

  const stopVibration = () => {
    clearInterval(vibrationInterval.current);
    if ("vibrate" in navigator) {
      navigator.vibrate(0);
    }
  };

  const startNotificationSound = () => {
    playSound();
    startVibration();

    setTimeout(() => {
      stopNotificationSound();
    }, RINGING_PERIOD);
  };

  const stopNotificationSound = () => {
    stopSound();
    stopVibration();
  };

  return { startNotificationSound, stopNotificationSound };
};
export default useVisitStartingNowNotification;
