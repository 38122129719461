import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, IconButton, MuiPlayCircleIcon, MuiVisibilityIcon } from "@toolkit/ui";
import { useMemo } from "react";
import { PatientHealthParameter } from "@health/queries/types";
import { detectFileType, FileType } from "@toolkit/core";

type PatientHealthParameterHistoryAudioColumnsProps = {
  selectedItem?: PatientHealthParameter;
  onChange: (value: PatientHealthParameter) => void;
};

export const usePatientHealthParameterHistoryVideoColumns = (
  props: PatientHealthParameterHistoryAudioColumnsProps
): CustomTableColumnProps<PatientHealthParameter>[] => {
  const { selectedItem, onChange } = props;

  const { t } = useTranslation("provider");

  const itemFileType = detectFileType(selectedItem?.valueVideoFile!);

  return useMemo(() => {
    return [
      {
        key: "source",
        header: t("Source"),
        accessor: "source",
      },
      {
        key: "date",
        header: t("Date"),
        accessor: "createdDate",
      },
      {
        key: "playOrPreview",
        header: itemFileType === FileType.IMAGE ? t("Preview") : t("Play"),
        accessor: item => {
          return (
            <IconButton disabled={item?.valueVideoFile === selectedItem?.valueVideoFile} onClick={() => onChange(item)}>
              {itemFileType === FileType.IMAGE ? <MuiVisibilityIcon color={"primary"} /> : <MuiPlayCircleIcon color={"primary"} />}
            </IconButton>
          );
        },
      },
    ];
  }, [itemFileType, onChange, selectedItem?.valueVideoFile, t]);
};
