import { Box, CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { LiveSigns } from "./LiveSigns";
import { usePatientDataByVisit } from "./usePatientDataByVisit";

type LiveSignsPageProps = {
  visitId?: string;
  patientId?: string;
  isDoctorConsole?: boolean;
};

export const LiveSignsPage: FC<LiveSignsPageProps> = props => {
  const { visitId, patientId, isDoctorConsole = true } = props;

  const { patient, loadingVisit } = usePatientDataByVisit({ visitId, patientId });

  return (
    <>
      {loadingVisit ? (
        <Box display='flex' justifyContent='center' m={1}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <LiveSigns patientId={patient?.id!} isDoctorConsole={isDoctorConsole} />
      )}
    </>
  );
};
