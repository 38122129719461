import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, MuiExpandMoreIcon, Typography, useAutocompleteCustomHook, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useMedicalFormsQuery } from "../../../gql";

export const MedicalFormsField: FC = () => {
  const { t } = useTranslation("provider");
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    data,
    isLoading,
    onFetchMoreData: handleFetchMore,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useMedicalFormsQuery,
    input: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
    dataAccessor: "medicalForms",
  });
  const options = data?.medicalForms?.edges?.map(medicalForm => medicalForm?.node);
  const hasMore = data?.medicalForms?.pageInfo?.hasNextPage;
  const theme = useTheme();

  return (
    <>
      <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium} gutterBottom>
        {t("Medical Forms")}
      </Typography>
      <AutocompleteController
        ControllerProps={{
          name: "medicalFormId",
          control,
          rules: {
            required: getRequiredValidation(t, true),
          },
        }}
        TextFieldProps={{
          fullWidth: true,
          placeholder: t("Medical Forms"),
          error: Boolean(errors.medicalFormId?.message),
          helperText: errors.medicalFormId?.message,
          InputProps: {
            endAdornment: <MuiExpandMoreIcon />,
          },
        }}
        hasMore={hasMore}
        fetchMoreData={handleFetchMore}
        loading={isLoading}
        options={options}
        getOptionLabel={o => o?.name}
      />
    </>
  );
};
