import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyInterventionActivitiesItemsStyle = makeStyles()(theme => ({
  card: {
    marginBottom: 8,
    borderRadius: 10,
    background: "#F4F8FF",
    boxShadow: "none",
    border: `1px solid ${theme.palette.gray.light}`,
  },
  cardContent: {
    paddingBottom: 16,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoWrapper: {
    display: "flex",
    flex: 1,
    gap: 8,
  },
  templateActivityType: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  date: {
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  deletedText: {
    textDecoration: "line-through",
    color: theme.palette.gray.main,
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    paddingInline: 8,
    borderRadius: 10,
    background: "white",
    height: 40,
  },
  deletedIcon: {
    opacity: 0.5,
    filter: "grayscale(100%)",
  },
  deleteRestoreIconWrapper: {
    background: theme.palette.common.white,
    borderRadius: 7,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  restoreIcon: {
    width: 26,
  },
}));
