import { useDebounce } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, IconButton, TextField } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";
import { useDoctorsSearchInputStyles } from "./DoctorsSearchInput.styles";
import { DoctorsSearchInputProps } from "./types";

export const DoctorsSearchInput: FC<DoctorsSearchInputProps> = ({ onFilter }) => {
  const { t } = useTranslation("provider");
  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, 500);

  const { classes } = useDoctorsSearchInputStyles();

  const handleSearchInputChange = event => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    onFilter?.(debouncedValue);
  }, [debouncedValue]);

  const handleFilter = () => {
    onFilter(searchInput);
  };

  return (
    <Box margin={2}>
      <TextField
        fullWidth
        hiddenLabel
        type='text'
        className={classes.textField}
        placeholder={t("Search Doctor") + "..."}
        InputProps={{
          classes: { root: classes.textFieldRoot },
          endAdornment: (
            <IconButton type='submit' onClick={handleFilter}>
              <CustomIcon icon='searchInput' />
            </IconButton>
          ),
        }}
        value={searchInput}
        onChange={handleSearchInputChange}
      />
    </Box>
  );
};
