import { UserNotification } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Divider, useIsMobileView } from "@toolkit/ui";
import { FC } from "react";
import { useAppointmentNotification } from "./AppointmentNotification";
import { useNotificationsHooks } from "./Notifications.hook";
import { NotificationsContent } from "./NotificationsContent";
import { ReadAllNotificationsButton } from "./ReadAllNotificationsButton";
import { NotificationContainerProps } from "./types";

export const NotificationsContainer: FC<NotificationContainerProps> = props => {
  const { unReadMessagesCount, lastNotificationsCountChangeAction } = props;

  const { t } = useTranslation("provider");
  const { notifications, pageInfo, loading, fetchMoreData } = useNotificationsHooks({
    unReadMessagesCount,
    lastNotificationsCountChangeAction,
  });
  const isMobile = useIsMobileView();
  const { classes, cx, theme } = useAppointmentNotification({ isMobile });

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <div className={cx(classes.card, classes.font)}>{t("Notifications")}</div>
        <Box>
          {/* <Button variant='text' onClick={handleClick} className={classes.seeAll}>
            {t("View all")}
          </Button> */}
          <ReadAllNotificationsButton notificationsCount={unReadMessagesCount} />
        </Box>
      </Box>
      <Divider
        light
        variant='middle'
        sx={{
          backgroundColor: theme.palette.gray.light,
        }}
      />
      <NotificationsContent
        loading={loading}
        notifications={notifications as UserNotification[]}
        fetchMoreData={fetchMoreData}
        hasMore={pageInfo?.hasNextPage!}
      />
    </>
  );
};
