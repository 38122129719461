import { pickLocalizedValue } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";

export const useOrdersFromCheckoutStyles = makeStyles()(theme => ({
  title: {
    color: theme.palette.stale.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  box: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  avatar: {
    height: 65,
    width: 65,
    borderRadius: 10,
    marginRight: 10,
    marginLeft: pickLocalizedValue(0, 10),
  },
  text: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
