import { AppointmentFilterInput } from "@health/queries/types";
import { useOnAppointmentStatusUpdate } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, useGridContext } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { CustomizedGridTableProps } from "../../types/DashboardGrid";

export const PendingAppointmentsCustomizedGrid: FC<CustomizedGridTableProps<AppointmentFilterInput>> = props => {
  const { t } = useTranslation("provider");
  const { filterInput, ...rest } = props;

  const { customTableProps, refetch } = useGridContext();

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      refetch({
        ...filterInput,
        confirmedStartTime: { gte: moment().format() },
      });
    }
  });

  return (
    <CustomTable
      {...customTableProps!}
      TableContainerProps={{
        sx: {
          height: 250,
        },
      }}
      pageSize={3}
      withoutDataMessage={t("No Pending Items")}
      emptyIconHeight={70}
      iconBoxProps={{
        marginBottom: "15px",
        height: "70px",
      }}
      {...rest}
    />
  );
};
