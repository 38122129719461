/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMemberToChronicGuidedCareProgramMutationVariables = Types.Exact<{
  input: Types.VisitChronicGuidedCareMemberAddInput;
}>;


export type AddMemberToChronicGuidedCareProgramMutation = { __typename?: 'Mutation', addMemberToChronicGuidedCareProgram?: { __typename?: 'VisitDiagnosisRiskTemplateCRUD', visitDiagnosisRiskTemplate?: { __typename?: 'VisitDiagnosisRiskTemplate', id: string, code?: string | null, result?: string | null, notification?: string | null, chronicDiseaseCode?: string | null, chronicDiseaseSeverity?: string | null, isGuidedCareProgramSelected?: boolean | null, suggestedGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, enrolledGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, selectedGuidedCareProgram?: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null } | null, visitDiagnosisRiskTemplateErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const AddMemberToChronicGuidedCareProgramDocument = gql`
    mutation addMemberToChronicGuidedCareProgram($input: VisitChronicGuidedCareMemberAddInput!) {
  addMemberToChronicGuidedCareProgram(input: $input) {
    visitDiagnosisRiskTemplate {
      id
      code
      result
      notification
      chronicDiseaseCode
      chronicDiseaseSeverity
      suggestedGuidedPrograms {
        id
        name
      }
      enrolledGuidedPrograms {
        id
        name
      }
      isGuidedCareProgramSelected
      selectedGuidedCareProgram {
        id
        name
      }
    }
    visitDiagnosisRiskTemplateErrors {
      code
      field
      message
    }
  }
}
    `;
export type AddMemberToChronicGuidedCareProgramMutationFn = Apollo.MutationFunction<AddMemberToChronicGuidedCareProgramMutation, AddMemberToChronicGuidedCareProgramMutationVariables>;

/**
 * __useAddMemberToChronicGuidedCareProgramMutation__
 *
 * To run a mutation, you first call `useAddMemberToChronicGuidedCareProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToChronicGuidedCareProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToChronicGuidedCareProgramMutation, { data, loading, error }] = useAddMemberToChronicGuidedCareProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberToChronicGuidedCareProgramMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberToChronicGuidedCareProgramMutation, AddMemberToChronicGuidedCareProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberToChronicGuidedCareProgramMutation, AddMemberToChronicGuidedCareProgramMutationVariables>(AddMemberToChronicGuidedCareProgramDocument, options);
      }
export type AddMemberToChronicGuidedCareProgramMutationHookResult = ReturnType<typeof useAddMemberToChronicGuidedCareProgramMutation>;
export type AddMemberToChronicGuidedCareProgramMutationResult = Apollo.MutationResult<AddMemberToChronicGuidedCareProgramMutation>;
export type AddMemberToChronicGuidedCareProgramMutationOptions = Apollo.BaseMutationOptions<AddMemberToChronicGuidedCareProgramMutation, AddMemberToChronicGuidedCareProgramMutationVariables>;