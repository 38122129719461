import { Button, ButtonProps, TruncateTypography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const DefaultPatientProfileButton: FC<ButtonProps & { patientName: string }> = props => {
  const { patientName, ...buttonProps } = props;
  const theme = useTheme();
  return (
    <Button
      color={"secondary"}
      variant='text'
      sx={{ paddingX: "1px", margin: 0, height: "8px", borderRadius: 0, width: "min-content" }}
      {...buttonProps}
    >
      <TruncateTypography
        sx={{ whiteSpace: "nowrap", textDecoration: "underline" }}
        fontSize={theme.mixins.fonts.fontSize.sm}
        color={theme.palette.secondary.main}
        text={patientName}
        marginBlockStart={0}
      />
    </Button>
  );
};
