import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { UserInfoType } from "shared/configs/oidc";
import { isPharmacistUser, isVendorAdmin } from "utils";
import { doctorsCallsRatingsPaths, doctorsCallsRatingsRoute } from "./constants";
import { DoctorsCallsRatingsContainer } from "./DoctorsCallsRatings.container";

export const doctorCallsRatingsRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: UserInfoType }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}): RouteItem => {
  const isUserAVendorAdmin = isVendorAdmin(userInfo);
  const isUserAPharmacist = isPharmacistUser(userInfo);

  return {
    id: "doctors-calls-ratings",
    text: t("Doctors Calls Ratings"),
    route: doctorsCallsRatingsRoute,
    hidden: !isUserAVendorAdmin || isUserAPharmacist,
    isProhibited: !isUserAVendorAdmin || isUserAPharmacist,
    fullPath: doctorsCallsRatingsPaths.indexPage.fullPath,
    element: <DoctorsCallsRatingsContainer />,
    onClick: route => navigate(route),
  };
};
