import { Branch, BranchOrderField, VendorBranchTypeEnum } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomTableColumnProps, TruncateTypography, Typography, format } from "@toolkit/ui";
import { useMemo } from "react";
import { AddressInformation } from "shared/components/AddressModal/AddressInformation.component";
import { ActiveSwitch } from "../components/ActiveSwitch.components";
import { WasfatyCredentialsDialog } from "../components/WasfatyCredentials.component";
import { WorkingHoursBranch } from "../components/WorkingHoursBranch.component";
import { BranchesDataQuery } from "../gql";
import { UsersList } from "./components/UserLists";

export type BranchColumnsType = CustomTableColumnProps<ConnectionDocumentNode<BranchesDataQuery, "branches">>[];

const types = Object.keys(VendorBranchTypeEnum).map(key => ({ value: VendorBranchTypeEnum[key], name: key }));
const typeValue = value => types?.find(item => item?.value === value);

const activeOptions = [
  { value: true, label: "Active" },
  { value: false, label: "In active" },
];
const acceptsDeliveryOptions = [
  { value: true, label: "Accepts delivery" },
  { value: false, label: "Doesn't accept delivery" },
];

export const useGetBranchesColumns = (): CustomTableColumnProps<Branch>[] => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: row => <TruncateTypography text={row?.name!} />,
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: BranchOrderField.Name,
        isHidden: false,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => format(row?.created),
        isSortable: true,
        sortDirection: "DESC",
        isHidden: false,
        sortColumnEnum: BranchOrderField.Created,
      },
      {
        key: "description",
        header: t("Description"),
        accessor: row => <TruncateTypography text={row?.description!} />,
        isHidden: false,
      },
      {
        key: "credential",
        header: t("eRxHub Credential"),
        accessor: row => <WasfatyCredentialsDialog row={row} />,
        isHidden: false,
      },
      {
        key: "address",
        header: t("Address"),
        accessor: row => <AddressInformation id={row?.id} />,
        isHidden: false,
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: "contactNumber",
        isHidden: false,
        filter: {
          type: "string",
          name: "contactNumber_Icontains",
        },
      },
      {
        key: "healthLicense",
        header: t("Health License"),
        accessor: "healthLicense",
        isHidden: false,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => <Typography>{t(typeValue(type)?.name || "")}</Typography>,
        isHidden: false,
        filter: {
          type: "autocomplete",
          name: "type",
          options: Object.keys(VendorBranchTypeEnum).map(key => ({
            value: VendorBranchTypeEnum[key],
            label: key,
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "isActive",
        header: t("Active"),
        accessor: row => <ActiveSwitch id={row?.id} isActive={row?.isActive} vendorActive={row?.vendor?.isActive} />,
        isHidden: false,
        filter: {
          type: "autocomplete",
          name: "isActive",
          options: activeOptions,
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "workingHours",
        header: t("Working Hours"),
        accessor: ({ id }) => <WorkingHoursBranch id={id} />,
        isHidden: false,
      },
      {
        key: "users",
        header: t("Users"),
        accessor: row => {
          return <UsersList id={row?.id} />;
        },
        isHidden: false,
      },
      {
        key: "healthLicenseNumber",
        header: t("Health License Number"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "healthLicenseNumber",
        },
      },
      {
        key: "acceptsDelivery",
        header: t("Accepts Delivery"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "acceptsDelivery",
          options: acceptsDeliveryOptions,
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
    ];
  }, []);
};
