import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  toolbar: {
    backgroundColor: "white",
    minHeight: `55px !important`,
    justifyContent: "center",
    top: 61,
    position: "sticky",
    zIndex: 1100,
    width: "100%",
  },
  tabsUpperContainer: {
    maxWidth: "calc(100% - 40px)",
    padding: 0,
  },
  tab: {
    border: "none",
    borderRadius: 0,
    padding: 0,
    margin: 0,
  },
  disabled: {
    cursor: "not-allowed !important",
    pointerEvents: "all !important",
    opacity: 0.5,
  },
}));
