import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
import { IconProps } from "./types";
export const LifeCycle: FC<IconProps> = ({ width = "28", height = "24", viewBox = "0 0 28 24", ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-life-cycle_1_'
        data-name='icons8-life-cycle (1)'
        d='M15,3A12,12,0,0,0,3,15H1l3,3,3-3H5a10,10,0,0,1,19.164-3.994l1.5-1.5A11.992,11.992,0,0,0,15,3Zm0,5c-2.474,0-3.5,1.721-3.5,3.793a4.01,4.01,0,0,0,.309,1.4.765.765,0,0,0-.275.756c.1.811.42,1.017.627,1.035a3.338,3.338,0,0,0,1.092,1.865v1.264c-.449,1.459-2.622.994-4.133,2.293a7.964,7.964,0,0,0,11.766,0c-1.511-1.3-3.684-.836-4.133-2.295V16.846a3.332,3.332,0,0,0,1.092-1.865c.207-.018.531-.222.627-1.033a.767.767,0,0,0-.277-.758,3.554,3.554,0,0,0,.309-1.4c0-1.535-.556-2.844-1.75-2.844A1.841,1.841,0,0,0,15,8Zm11,4-3,3h2A10,10,0,0,1,5.836,18.994l-1.5,1.5A11.995,11.995,0,0,0,27,15h2Z'
        transform='translate(-1 -3)'
        fill={props.fill ? props.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
