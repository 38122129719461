/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetComplainsListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetComplainsListQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', complains?: { __typename?: 'VisitComplainCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VisitComplainCountableEdge', node: { __typename?: 'VisitComplain', id: string, complain?: string | null } }> } | null } | null };


export const GetComplainsListDocument = gql`
    query getComplainsList($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  visit(id: $id) {
    complains(before: $before, after: $after, first: $first, last: $last) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          complain
        }
      }
    }
  }
}
    `;

/**
 * __useGetComplainsListQuery__
 *
 * To run a query within a React component, call `useGetComplainsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplainsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplainsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetComplainsListQuery(baseOptions: Apollo.QueryHookOptions<GetComplainsListQuery, GetComplainsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComplainsListQuery, GetComplainsListQueryVariables>(GetComplainsListDocument, options);
      }
export function useGetComplainsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComplainsListQuery, GetComplainsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComplainsListQuery, GetComplainsListQueryVariables>(GetComplainsListDocument, options);
        }
export type GetComplainsListQueryHookResult = ReturnType<typeof useGetComplainsListQuery>;
export type GetComplainsListLazyQueryHookResult = ReturnType<typeof useGetComplainsListLazyQuery>;
export type GetComplainsListQueryResult = Apollo.QueryResult<GetComplainsListQuery, GetComplainsListQueryVariables>;