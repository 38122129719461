import { useTranslation } from "@toolkit/i18n";
import { Box, CustomToggleButton, Typography, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { useOrderPageContext } from "../state";

export const DeliveryTabVisibilityToggle: FC = () => {
  const { t } = useTranslation("provider");
  const { isDeliveringColumnVisible, onToggleDeliveringColumnVisibility: handleToggleDeliveringColumnVisibility } = useOrderPageContext();
  const theme = useTheme();
  return (
    <Box display='flex' alignItems='center' paddingRight='20px'>
      <CustomToggleButton checked={isDeliveringColumnVisible} onChange={handleToggleDeliveringColumnVisibility} />
      <Typography noWrap fontSize={theme.mixins.fonts.fontSize.sm}>
        {t("Show Delivering Panel")}
      </Typography>
    </Box>
  );
};
