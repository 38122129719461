import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { DeleteDocsButtons } from "../../../AIAgents/components/DeleteDocs";
import { convertBytes } from "../../../AIAgents/utils";
import { useMemo } from "react";
export const useChatFlowDocsColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "name",
                accessor: "fileName",
                header: t("Name"),
            },
            {
                key: "description",
                accessor: "fileName",
                header: t("Description"),
            },
            {
                key: "size",
                accessor: props => {
                    return (props === null || props === void 0 ? void 0 : props.fileSize) ? `(${convertBytes(props === null || props === void 0 ? void 0 : props.fileSize)})` : "-";
                },
                header: t("Size"),
            },
            {
                key: "type",
                header: t("Type"),
                accessor: "fileType",
            },
            {
                key: "actions",
                header: t("Actions"),
                accessor: ({ id, fileName }) => _jsx(DeleteDocsButtons, { docId: id, fileName: fileName }),
            },
        ];
    }, [t]);
};
