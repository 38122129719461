import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useProfessionalsQuery } from "../gql";
import { useProfessionalsColumns } from "./useProfessionalsColumns";
import { useTranslation } from "@toolkit/i18n";

export const ProfessionalsList = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");
  const handleEditRow = data => {
    navigate("edit/" + data?.user?.id);
  };
  useEffect(() => {
    setBreadCrumb({
      title: t("Doctors"),
    });
  }, []);
  return (
    <GridProvider
      gridName='doctors'
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEditRow,
      }}
      columns={useProfessionalsColumns()}
      query={useProfessionalsQuery}
      hasTableSetting
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
