import { getAutocompleteFilter } from "@toolkit/ui";
import { useGuidedCareProgramsAutocompleteQuery } from "./gql";
export const getGuidedCareProgramsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "guidedCareHealthPrograms",
        query: useGuidedCareProgramsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
        filtersForQuery: {
            name_Icontains: "",
        },
    });
};
