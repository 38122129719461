import { Appointment, PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export const useGetAppointmentsDoctorNameColumns = (isSelfOnlyAppointments: boolean): CustomTableColumnProps<Appointment>[] => {
  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();
  const showColumn =
    hasPermission(PermissionEnum.ManageAppointments) && (userInfo?.vendor_user_type === "Doctor" ? !isSelfOnlyAppointments : true);
  return useMemo(
    () => [
      {
        key: "Doctor_Name",
        header: t("Doctor Name"),
        accessor: row => <TruncateTypography text={row?.doctor?.user?.fullName!} />,
        hideFromPreferencesAndTable: !showColumn,
      },
    ],
    [t, showColumn]
  );
};
