import { Maybe, ProviderGuidedCareTeamStatus } from "@health/queries/types";
import { toPascalCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { getGuidedCareTeamStatusColor } from "pages/HealthProviderManagement/GuidedCare/others";
import { FC } from "react";
import { useGuidedCareTeamStatusStyle } from "./useGuidedCareTeamStatusStyle";

type GuidedCareTeamStatusProps = {
  teamStatus?: Maybe<ProviderGuidedCareTeamStatus>;
};

export const GuidedCareTeamStatus: FC<GuidedCareTeamStatusProps> = props => {
  const { teamStatus } = props;

  const { t } = useTranslation("provider");
  const { classes, theme } = useGuidedCareTeamStatusStyle();

  if (!teamStatus) {
    return <></>;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.circle} sx={{ backgroundColor: getGuidedCareTeamStatusColor(teamStatus, theme.palette) }} />
      <Typography color={getGuidedCareTeamStatusColor(teamStatus, theme.palette)}>{t(toPascalCase(teamStatus?.toLowerCase()))}</Typography>
    </Box>
  );
};
