import { useTheme } from "@toolkit/ui";
import React, { FC, PropsWithChildren } from "react";
import { NotificationContent } from "../NotificationContent";
import { AppointmentStatus } from "@health/queries/types/types";
import { appointmentStatusOptionsMap } from "@health/enum-options";
import { getAppointmentNotificationStatusColor } from "./utils";

type AppointmentLayoutProps = PropsWithChildren<{
  status: AppointmentStatus;
}>;

export const AppointmentLayout: FC<AppointmentLayoutProps> = props => {
  const { status, children } = props;

  const theme = useTheme();

  const color = getAppointmentNotificationStatusColor(status, theme);

  return (
    <NotificationContent title={appointmentStatusOptionsMap[status]?.label} color={color}>
      {children}
    </NotificationContent>
  );
};
