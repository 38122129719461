import QRCodeStyling, {
  CornerDotType,
  CornerSquareType,
  DotType,
  DrawType,
  ErrorCorrectionLevel,
  Mode,
  Options,
  TypeNumber,
} from "qr-code-styling";
import React, { useEffect, useRef, useState } from "react";

const defaultQRCodeOptions: Options = {
  width: 300,
  height: 300,
  type: "svg" as DrawType,
  data: "-",
  image: "/iohealth-fav-bordered.svg",
  margin: 10,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: "Byte" as Mode,
    errorCorrectionLevel: "Q" as ErrorCorrectionLevel,
  },
  imageOptions: {
    hideBackgroundDots: false,
    imageSize: 0.6,
    margin: 2,
    crossOrigin: "anonymous",
  },
  dotsOptions: {
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        { offset: 0, color: "#66306a" },
        { offset: 1, color: "#292f75" },
      ],
    },
    type: "rounded" as DotType,
  },
  backgroundOptions: {
    color: "white",
  },
  cornersSquareOptions: {
    type: "extra-rounded" as CornerSquareType,
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        { offset: 0, color: "#66306a" },
        { offset: 1, color: "#292f75" },
      ],
    },
  },
  cornersDotOptions: {
    type: "dot" as CornerDotType,
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        { offset: 0, color: "#66306a" },
        { offset: 1, color: "#292f75" },
      ],
    },
  },
};

export const QRCode: React.FC<{ qrCodeText: string }> = ({ qrCodeText }) => {
  const [options, setOptions] = useState<Options>({ ...defaultQRCodeOptions, data: qrCodeText });
  const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(options));
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(options);
  }, [qrCode, options]);

  useEffect(() => {
    setOptions(options => ({ ...options, data: qrCodeText }));
  }, [qrCodeText]);

  return (
    <div>
      <div ref={ref} />
    </div>
  );
};
