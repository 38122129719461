import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useGuidedCareJourneyInterventionStatisticsStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    background: "#F4F8FF",
    padding: 15,
    borderRadius: 8,
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  currentValueWrapper: {
    display: "flex",
    gap: 1,
  },
  currentValue: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.error.main,
  },
  circlesWrapper: {
    display: "flex",
    flex: 1,
    marginLeft: pickLocalizedValue(64, 0),
    marginRight: pickLocalizedValue(0, 64),
    gap: 32,
  },
  circleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  circle: {
    width: 75,
    height: 75,
    borderRadius: "50%",
    textAlign: "center",
    borderWidth: 10,
    borderStyle: "solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circleValue: {
    fontSize: theme.mixins.fonts.fontSize.md,
    display: "flex",
    flexDirection: "column",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  label: {
    marginTop: 4,
  },
}));
