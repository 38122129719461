import { useReactiveVar } from "@apollo/client";
import { OrderStatus } from "@health/queries/types";
import { convertToTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { TruncateTypography, Typography } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import { isOpenOrderCardDialog } from "./OrdersCard";
import { useOrderSectionStyles } from "./OrderSection.styles";

type InformationCardProps = PropsWithChildren<{
  title?: string;
  subTitle?: string | null;
  color?: string;
  hasBG?: boolean;
  hasAction?: boolean;
  status?: OrderStatus;
}>;

export const OrderSection: FC<InformationCardProps> = props => {
  const { t } = useTranslation("provider");

  const { title, subTitle, color, children, hasBG, hasAction, status } = props;
  const open = useReactiveVar(isOpenOrderCardDialog);
  const { classes } = useOrderSectionStyles({ color, hasBG, hasAction, open });

  const allowedStatuses = [OrderStatus.Delivered, OrderStatus.Dispensed, OrderStatus.OutForDelivery];
  const isAllowedStatus = status && allowedStatuses?.includes(status);
  return (
    <div className={classes.section}>
      <Typography className={classes.title}>{title}</Typography>
      {isAllowedStatus && <Typography className={classes.title}>{t(convertToTitleCase(status))}</Typography>}
      {subTitle && <TruncateTypography className={classes.subTitle} text={subTitle!} />}
      {children}
    </div>
  );
};

OrderSection.defaultProps = {
  title: undefined,
  subTitle: undefined,
  hasBG: false,
  hasAction: undefined,
};
