import { FeatureEnum, IsFeatureEnabledType } from "@health/domains";
import { PermissionEnum } from "@health/queries/types";
import { hasAnyPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ProductIcon } from "@toolkit/ui";
import { healthPackagesRoutes } from "pages/HealthPackages/route";
import { discountsRoutes } from "pages/MarketplaceDiscounts";
import { ProductsRoutes } from "pages/MarketplaceProducts/constants";
import { promotionsRoutes } from "pages/MarketplacePromotions";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceRoute } from "./marketplace-paths.constants";

export const marketplaceRoutes: (props: {
  navigate: NavigateFunction;
  t: TFunction;
  isFeatureEnabled: IsFeatureEnabledType;
}) => RouteItem = ({ navigate, t, isFeatureEnabled }) => {
  const canViewMarketplaceMenu = hasAnyPermission([
    PermissionEnum.ManageProducts,
    PermissionEnum.ManageHealthPackages,
    PermissionEnum.ManagePromotions,
    PermissionEnum.ManageDiscounts,
    // PermissionEnum.ManageMarketplaceOrders,
  ]);

  const isMarketplaceRootHidden = !(isFeatureEnabled([FeatureEnum.HealthPackage, FeatureEnum.Marketplace]) && canViewMarketplaceMenu);
  const isMarketplaceItemsEnabled = isFeatureEnabled([FeatureEnum.Marketplace]);
  const isHealthPackageItemEnabled = isFeatureEnabled([FeatureEnum.HealthPackage]);

  return {
    id: "marketplace-routes",
    text: t("Marketplace", { ns: "provider" }),
    icon: <ProductIcon />,
    isProhibited: isMarketplaceRootHidden,
    hidden: isMarketplaceRootHidden,
    route: marketplaceRoute,
    subItems: [
      ProductsRoutes({ navigate, t, enabled: isMarketplaceItemsEnabled }),
      healthPackagesRoutes({ navigate, t, enabled: isHealthPackageItemEnabled }),
      promotionsRoutes({ navigate, t, enabled: isMarketplaceItemsEnabled }),
      discountsRoutes({ navigate, t, enabled: isMarketplaceItemsEnabled }),
    ],
  };
};
