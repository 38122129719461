import { useTranslation } from "@toolkit/i18n";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  CustomIcon,
  MuiExpandMoreIcon,
  Typography,
  useTheme,
} from "@toolkit/ui";
import { useAppointmentReminderTimeSecondsQuery } from "../../gql";

export const Instructions = () => {
  const { t } = useTranslation("provider");
  const { data, loading } = useAppointmentReminderTimeSecondsQuery();
  const seconds = Number(data?.siteSettings?.appointmentReminderTimeSeconds) / 60 || 0;
  const theme = useTheme();
  return (
    <Accordion
      elevation={0}
      disableGutters
      square
      sx={{
        borderTop: `1px solid ${theme.palette.background.default}`,
        background: theme.palette.background.default,
        borderRadius: "0px 0px 10px 10px",
      }}
    >
      <AccordionSummary
        expandIcon={<MuiExpandMoreIcon />}
        sx={{
          height: "60px",
        }}
      >
        <Box
          sx={{
            paddingInline: 1,
          }}
        >
          <CustomIcon icon='info' />
        </Box>
        <Typography fontSize={theme.mixins.fonts.fontSize.lg}>{t("Virtual visit instructions")}</Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          paddingInline: "40px",
        }}
      >
        {loading ? (
          <Box display='flex' justifyContent='center'>
            <CircularProgress size={40} />
          </Box>
        ) : (
          <>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
              {t("instructions-patient-confirms-appointment", { count: Math.round(seconds * 100) / 100 })}
            </Typography>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium} mt={3}>
              {t("Reconnecting to the Call")}:
            </Typography>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("instructions-connection-problems")}</Typography>

            <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium} mt={3}>
              {t("Writing medical notes, requested orders, and prescriptions")}:
            </Typography>

            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("instructions-system-integrated")}</Typography>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
