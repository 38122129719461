import { IEnumToOptionsMap } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";

export enum GuidedCareTemplateInterventionType {
  RecommendedInterventions = "RECOMMENDED_INTERVENTIONS",
  AllInterventions = "ALL_INTERVENTIONS",
}

export const guidedCareTemplateInterventionTypeOptionsMap: IEnumToOptionsMap<GuidedCareTemplateInterventionType> = {
  [GuidedCareTemplateInterventionType.RecommendedInterventions]: {
    key: GuidedCareTemplateInterventionType.RecommendedInterventions,
    label: i18n.t("Recommended Interventions", { ns: "provider" }),
    value: GuidedCareTemplateInterventionType.RecommendedInterventions,
  },
  [GuidedCareTemplateInterventionType.AllInterventions]: {
    key: GuidedCareTemplateInterventionType.AllInterventions,
    label: i18n.t("All Interventions", { ns: "provider" }),
    value: GuidedCareTemplateInterventionType.AllInterventions,
  },
};

export const guidedCareTemplateInterventionTypeOptions = Object.values(guidedCareTemplateInterventionTypeOptionsMap);
