export const getAdherenceColor = (value: number, palette: any) => {
  const adherenceColor = {
    color: palette.success.main,
    theme: "success",
  };

  if (value < 80 && value >= 50) {
    adherenceColor.color = palette.warning.main;
    adherenceColor.theme = "warning";
  } else if (value < 50) {
    adherenceColor.color = palette.error.main;
    adherenceColor.theme = "error";
  }

  return adherenceColor;
};
