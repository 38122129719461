import React from "react";
import { SvgIcon, useTheme } from "../../base/mui";
export const ExpandIcon = () => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      className='svg-icon'
      sx={{
        width: "12px",
        height: "12px",
      }}
      viewBox='0 0 12.626 12.624'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='icons8-expand'
        d='M4.562,3.99a.573.573,0,0,0-.568.653V7.435a.573.573,0,1,0,1.146,0V5.953L7.6,8.413A.573.573,0,1,0,8.412,7.6L5.951,5.142H7.433A.573.573,0,1,0,7.433,4h-2.8A.573.573,0,0,0,4.562,3.99Zm11.458,0A.573.573,0,0,0,15.956,4H13.165a.573.573,0,1,0,0,1.146h1.482L12.186,7.6A.573.573,0,1,0,13,8.413l2.461-2.461V7.435a.573.573,0,1,0,1.146,0V4.642a.573.573,0,0,0-.584-.652ZM8,12.015a.573.573,0,0,0-.394.174L5.14,14.649V13.166a.573.573,0,1,0-1.146,0v2.779a.573.573,0,0,0,.66.66H7.433a.573.573,0,1,0,0-1.146H5.951L8.412,13A.573.573,0,0,0,8,12.015Zm4.591,0a.573.573,0,0,0-.4.984l2.461,2.461H13.165a.573.573,0,1,0,0,1.146h2.779a.573.573,0,0,0,.66-.66V13.166a.573.573,0,1,0-1.146,0v1.482L13,12.188a.573.573,0,0,0-.411-.174Z'
        transform='translate(-3.986 -3.99)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
