import { AppointmentFilterInput, AppointmentSortingField, H_OrderDirection } from "@health/queries/types";
import moment from "moment";
import { FC, useMemo } from "react";
import { GridProvider } from "shared/components";
import { ComingSoonAppointmentsCustomizedGrid } from "./ComingSoonAppointmentsCustomizedGrid";
import { useComingSoonAppointmentsGridColumns } from "./useComingSoonAppointmentsGridColumns";
import { DashboardGridProps } from "../../types/DashboardGrid";
import { NurseCurrentWorkSpaceDetailsVar } from "shared/components/NurseCurrentWorkDetails";
import { useReactiveVar } from "@apollo/client";
import { isNurseAssignedToDoctor, isNurseUser, isVendorAdmin } from "utils";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useFetchDashboardAppointmentsQuery } from "pages/dashboard/gql/queries";

export const ComingSoonAppointmentsGrid: FC<DashboardGridProps> = props => {
  const { pageSize = 3, ...rest } = props;
  const currentNurseWorkDetails = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const doctorsFilterVarValue = currentNurseWorkDetails?.doctors?.map(e => e?.id);
  const { userInfo } = useOidcUserProfile();
  const isNurse = isNurseUser(userInfo);
  const isAdmin = isVendorAdmin(userInfo);
  const currentDate = useMemo(() => {
    return moment().format();
  }, []);
  const filterInput: Partial<AppointmentFilterInput> = {
    upcoming: true,
    confirmedStartTime: {
      gte: currentDate,
    },
    doctors: isNurse && doctorsFilterVarValue ? doctorsFilterVarValue : [],
  };
  return (
    <GridProvider
      gridName='comingSoonAppointmentsList'
      columns={useComingSoonAppointmentsGridColumns()}
      query={useFetchDashboardAppointmentsQuery}
      skipCall={!isNurseAssignedToDoctor(currentNurseWorkDetails) && isNurse}
      filterInput={filterInput}
      pageSize={pageSize}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Asc,
          field: AppointmentSortingField.ConfirmedStartDate,
        },
        isAdmin: isAdmin,
      }}
    >
      <ComingSoonAppointmentsCustomizedGrid filterInput={filterInput} pageSize={pageSize} {...rest} />
    </GridProvider>
  );
};
