import { Box, BoxProps } from "@toolkit/ui";
import React, { MouseEvent, ReactNode, PropsWithChildren } from "react";

interface PropagationEndProps extends BoxProps, PropsWithChildren {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  children?: ReactNode;
}

const PropagationEnd: React.FC<PropagationEndProps> = ({ onClick, children, ...rest }) => {
  const handleStopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Box onClick={handleStopPropagation} {...rest}>
      {children}
    </Box>
  );
};

export default PropagationEnd;
