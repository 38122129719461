import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
export const useMedicalFormQuestionsTableColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "question",
                header: t("Question"),
                accessor: ({ question }) => question,
            },
            {
                key: "questionType",
                header: t("Question Type"),
                accessor: ({ questionType }) => questionType === null || questionType === void 0 ? void 0 : questionType.label,
            },
            {
                key: "required",
                header: t("Required"),
                accessor: ({ required }) => (required ? t("Required") : "-"),
            },
        ];
    }, [t]);
};
