import { IEnumToOptionsMap } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";
import { JourneyDurationPeriod } from "shared/modules/patient/types";

export const journeyDurationPeriodOptionsMap: IEnumToOptionsMap<JourneyDurationPeriod> = {
  [JourneyDurationPeriod.Annual]: {
    key: JourneyDurationPeriod.Annual,
    label: i18n.t("Annual", { ns: "provider" }),
    value: JourneyDurationPeriod.Annual,
  },
  [JourneyDurationPeriod.Quarterly]: {
    key: JourneyDurationPeriod.Quarterly,
    label: i18n.t("3 Months", { ns: "provider" }),
    value: JourneyDurationPeriod.Quarterly,
  },
};

export const journeyDurationPeriodOptions = Object.values(journeyDurationPeriodOptionsMap);
