import { getOrderStatusColor } from "@health/domains";
import { orderStatusActiveOptions } from "@health/enum-options";
import { MarketplaceOrderSortingField, MarketplaceOrderStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, OrderDirection, OrderStatusTypography, TruncateTypography, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { SetProductOrderAsConsumedButton } from "./SetProductOrderAsConsumedButton";
import { RejectProductOrderButton } from "./RejectProductOrderButton";
import { AcceptProductOrderButton } from "./AcceptProductOrderButton";
import { GetProductOrderNode } from "../types";
import { ProductsModal } from "./ProductModal/ProductModal";
import moment from "moment";
import { SetProductOrderAsDeliveredButton } from "./SetProductOrderAsDeliveredButton";

export type PatientsColumnsType = CustomTableColumnProps<GetProductOrderNode>[];

export const useGetProductOrdersListColumns = (): PatientsColumnsType => {
  const { t, i18n } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "number",
        header: t("Number"),
        accessor: ({ id }) => {
          return <Typography> {id}</Typography>;
        },
        isHidden: false,
        filter: {
          type: "string",
          name: "numbers",
        },
      },
      {
        key: "customerName",
        header: t("Customer Name"),
        accessor: ({ user }) => <TruncateTypography text={user?.fullName!} />,
      },
      {
        key: "contactNumber",
        header: t("Customer Mobile"),
        accessor: ({ user }) => <span className='mobile-phone-rtl-fix'>{String(user?.mobile ?? "")}</span>,
      },
      {
        key: "contactEmail",
        header: t("Customer Email"),
        accessor: ({ user }) => <span className='mobile-phone-rtl-fix'>{String(user?.email ?? "")}</span>,
      },
      {
        header: t("Price"),
        key: "totalAmount",
        accessor: "totalNetAmount",
      },

      {
        key: "Created",
        header: t("Created"),
        accessor: ({ createdDate }) => {
          moment.localeData("ar");
          const createdTz = moment.tz.guess();
          moment.locale(i18n.language);
          return moment(createdDate).tz(createdTz).format("DD MMM YYYY hh:mm a");
        },
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: MarketplaceOrderSortingField.Created,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <OrderStatusTypography status={status} color={getOrderStatusColor(status)} t={t} />,
        filter: {
          name: "status",
          type: "autocomplete",
          multiple: true,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.map(item => item?.value),
          options: orderStatusActiveOptions,
        },
      },
      {
        key: "orderItems",
        header: t("Order Items"),
        accessor: ({ id }) => <ProductsModal orderId={id} title={t("Items")} />,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ status, id }) => {
          if (status === MarketplaceOrderStatus.New) {
            return (
              <Box sx={{ width: "200px" }}>
                <AcceptProductOrderButton orderId={id} />
                <RejectProductOrderButton orderId={id} />
              </Box>
            );
          }
          if (status === MarketplaceOrderStatus.Accepted) {
            return <SetProductOrderAsConsumedButton orderId={id} />;
          }
          if (status === MarketplaceOrderStatus.OutForDelivery) {
            return <SetProductOrderAsDeliveredButton orderId={id} />;
          }
        },
      },
      {
        key: "toDate",
        header: t("To Date"),
        accessor: "toDate",
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "toDate",
        },
      },
      {
        key: "fromDate",
        header: t("From Date"),
        accessor: "fromDate",
        hideFromPreferencesAndTable: true,
        filter: {
          type: "date",
          name: "fromDate",
        },
      },
    ] as PatientsColumnsType;
  }, [i18n.language, t]);
};
