import { Card, Grid, Skeleton } from "@mui/material";
import React, { FC } from "react";
import { useSkeletonCardStyle } from "./useSkeletonCardStyle";

type SkeletonCardProps = {
  count?: number;
  height: number;
  widths?: { xs?: number; sm?: number; md?: number; lg?: number };
};

export const SkeletonCard: FC<SkeletonCardProps> = props => {
  const { count, height, widths } = props;

  const { classes } = useSkeletonCardStyle();

  const defaultWidths = { xs: 12, sm: 6, md: 4, lg: 3 };

  const loadingArray = count ? new Array<number>(count).fill(0) : [];

  return (
    <>
      {count && count > 1 ? (
        loadingArray?.map((_, index) => (
          <Grid
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            item
            xs={widths?.xs || defaultWidths?.xs}
            sm={widths?.sm || defaultWidths?.sm}
            md={widths?.md || defaultWidths?.md}
            lg={widths?.lg || defaultWidths?.lg}
          >
            <Card className={classes.root}>
              <Skeleton variant={"rectangular"} height={height} />
            </Card>
          </Grid>
        ))
      ) : (
        <Card className={classes.root}>
          <Skeleton variant={"rectangular"} height={height} />
        </Card>
      )}
    </>
  );
};
