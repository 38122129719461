import { useTranslation } from "@toolkit/i18n";
import { useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { visitStatusMapper } from "./utils";
import { VisitContent } from "./VisitContent";
import { PropsWithChildren } from "react";

type VisitLayoutProps = PropsWithChildren<{
  status: string;
}>;

export const VisitLayout: FC<VisitLayoutProps> = ({ children, status }) => {
  const { t } = useTranslation("provider");
  const title = visitStatusMapper[status!]?.display;
  const theme = useTheme();
  return (
    <VisitContent title={t(title!)} color={visitStatusMapper[status!]?.color! || theme.palette.success.main}>
      {children}
    </VisitContent>
  );
};
