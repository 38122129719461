import { IEnumOption } from "@health/enum-options";
import { Box, Button, CustomIcon, Menu, MenuItem, Typography } from "@toolkit/ui";
import React, { useState } from "react";
import { useMenuTimeLineStyles } from "./MenuTimeLineStyle";

type MenuTimeLineProps<T> = {
  items: IEnumOption<T>[];
  title?: string;
  selected?: string;
  onChange: (type: IEnumOption<T>) => void;
};

export const MenuTimeLine = <T,>(props: MenuTimeLineProps<T>) => {
  const { items, title, selected, onChange } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (type: IEnumOption<T>) => {
    return () => {
      setAnchorEl(null);
      onChange(type);
    };
  };

  const isOpen = !!anchorEl;
  const nameItem = selected ? items?.find(item => item?.value === selected)?.label : "-";

  const { classes, theme } = useMenuTimeLineStyles();

  return (
    <Box>
      <Button
        id='patient-add-activity-menu-button'
        aria-controls={isOpen ? "patient-add-activity-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen ? "true" : undefined}
        variant='text'
        disableElevation
        onClick={handleClick}
        className={classes.btn}
        endIcon={
          <Box>
            <CustomIcon icon='expandArrow' viewBox='0 -3 20 20' color='inherit' />
          </Box>
        }
      >
        <Box display='flex' flexDirection='column' alignItems='baseline'>
          <Typography color={theme.palette.stale.main} fontSize={theme.mixins.fonts.fontSize.xxs} whiteSpace='nowrap'>
            {title}
          </Typography>
          <Typography fontSize={theme.mixins.fonts.fontSize.xs}>
            {nameItem || selected?.charAt(0)?.toUpperCase()! + selected?.slice(1)?.toLowerCase()!}
          </Typography>
        </Box>
      </Button>

      <Menu
        sx={{
          marginTop: "8px !important",
          borderRadius: "10px !important",
        }}
        id='patient-add-activity-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        {items?.map(item => (
          <MenuItem key={`${item?.key}`} onClick={handleSelect(item)}>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{item?.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
