import { makeStyles } from "tss-react/mui";

type PatientHealthParameterPictureStyleProps = {
  maxHeight: number;
};

export const usePatientHealthParameterPictureStyle = makeStyles<PatientHealthParameterPictureStyleProps>()((_, params) => ({
  image: {
    width: "100%",
    maxHeight: params.maxHeight,
  },
}));
