import { makeStyles } from "@toolkit/ui";

export const useTranscriptionStyles = makeStyles()(theme => ({
  firstSpeaker: {
    backgroundColor: theme.palette.yellow[200],
    borderRadius: "10px",
    display: "flex",
    alignSelf: "flex-end",
    textAlign: "right",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "15px",
    margin: "5px 0px",
  },
  secondSpeaker: {
    backgroundColor: theme.palette.gray.light,
    borderRadius: "10px",
    alignSelf: "flex-start",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    padding: "15px",
  },
}));
