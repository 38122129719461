/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Step, StepLabel, Stepper, Typography } from "../../base/mui";
import { CustomIcon } from "../CustomIcon";
import { useTimelineStyles } from "./useTimelineStyles";

interface BaseActivity {
  offsetInDays?: number | null;
}
interface MonthlyTimeLineProps<T extends BaseActivity = BaseActivity> {
  timeline: {
    label: string;
    activities: T[];
    activitiesWeeks: { activitiesWeeks1: T[]; activitiesWeeks2: T[]; activitiesWeeks3: T[]; activitiesWeeks4: T[] };
  }[];
  numberOfItemsToShow?: number;
  currentStep?: number;
  children: React.ReactNode;
  isDisableNext?: boolean;
  isDisablePrev?: boolean;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

export const MonthlyTimeLine = <T extends BaseActivity = BaseActivity>({
  timeline,
  children,
  numberOfItemsToShow = 10,
  currentStep,
  isDisableNext,
  isDisablePrev,
  onIncrement,
  onDecrement,
}: MonthlyTimeLineProps<T>) => {
  const { classes, theme } = useTimelineStyles();
  const [step, setStep] = useState(currentStep || 0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 400);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setWindowWidth(window.innerWidth - 400);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  const numItemsToShow = window.innerWidth >= 1660 ? 3 : Math.floor(windowWidth / 350);

  const handleNext = () => {
    setStep(step + numItemsToShow);
    onIncrement?.();
  };

  const handlePrev = () => {
    setStep(step - numItemsToShow);
    onDecrement?.();
  };

  const endIndex = Math.min(step + numItemsToShow, timeline.length);

  useEffect(() => {
    currentStep && setStep(currentStep);
  }, [currentStep]);
  return (
    <Box display='flex' alignItems='end' justifyContent='center'>
      <IconButton disabled={isDisablePrev || step === 0} className={classes.btn} onClick={handlePrev}>
        <CustomIcon icon='arrow' color={theme.palette.common.white} transform='rotate(180deg)' viewBox='-3 -3 24 24' />
      </IconButton>
      <Stepper connector={<Box />}>
        {timeline?.map((item, ind) => {
          const activitiesWeeks = [
            ...[item?.activitiesWeeks?.activitiesWeeks1],
            ...[item?.activitiesWeeks?.activitiesWeeks2],
            ...[item?.activitiesWeeks?.activitiesWeeks3],
            ...[item?.activitiesWeeks?.activitiesWeeks4],
          ];
          const isVisible = ind >= step && ind < endIndex;

          return (
            <Step key={item?.label} className={classes.step} sx={{ display: isVisible ? "flex" : "none !important" }}>
              <StepLabel
                StepIconComponent={props => <Box {...props} />}
                classes={{
                  root: classes.stepLabel,
                  iconContainer: classes.iconContainer,
                }}
              >
                <>
                  <Box sx={{ display: "flex", alignItems: "end", justifyContent: "center" }}>
                    {activitiesWeeks?.map((activitiesWeek, i) => {
                      return (
                        <Box key={i} className={`${classes.activities} ${classes.monthly}`}>
                          {activitiesWeek?.slice(0, numberOfItemsToShow)?.map((item, index) => {
                            const minOffsetInDays = Math.min(...activitiesWeek.map(a => a?.["offsetInDays"]!));
                            const maxOffsetInDays = Math.max(...activitiesWeek.map(a => a?.["offsetInDays"]!));

                            const childrenWithProps = React.Children.map(children, child => {
                              if (React.isValidElement(child)) {
                                return React.cloneElement<any>(child, {
                                  activity: item,
                                  hasMoreData: index + 1 === numberOfItemsToShow && activitiesWeek.length! > numberOfItemsToShow,
                                  minOffsetInDays,
                                  maxOffsetInDays,
                                });
                              }
                              return child;
                            });

                            return <>{childrenWithProps}</>;
                          })}
                        </Box>
                      );
                    })}
                  </Box>

                  <Box display='flex'>
                    {Array.from({ length: 4 }, (_, index) => (
                      <Box display='flex' key={index} width={105} alignItems='end' justifyContent={"space-evenly"}>
                        {index == 0 && <Box className={classes.stepIcon} />}

                        <Typography fontSize={theme.mixins.fonts.fontSize.xs} mt={0} mx='30px' color={theme.palette.primary[300]}>
                          {`Week ${index + 1}`}
                        </Typography>
                        {endIndex == ind + 1 && index == 3 && <Box className={classes.stepIcon} />}
                        {index !== 3 && <Box className={classes.separateWeek} />}
                      </Box>
                    ))}
                  </Box>
                  <Typography
                    align='center'
                    fontSize={theme.mixins.fonts.fontSize.xs}
                    marginTop={2}
                    color={theme.palette.primary.main}
                    fontWeight={theme.mixins.fonts.fontWeight.medium}
                  >
                    {item?.label}
                  </Typography>
                </>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <IconButton disabled={isDisableNext || endIndex === timeline.length} className={classes.btn} onClick={handleNext}>
        <CustomIcon icon='arrow' color={theme.palette.common.white} viewBox='-3 -3 24 24' />
      </IconButton>
    </Box>
  );
};

MonthlyTimeLine.defaultProps = {
  timeline: [],
  currentStep: 0,
  numberOfItemsToShow: 10,
  children: null,
  isDisableNext: false,
  isDisablePrev: false,
  onIncrement: () => {},
  onDecrement: () => {},
};
