import { Grid, SkeletonCard } from "@toolkit/ui";
import React, { FC } from "react";
import { useHealthParameterVitalSigns } from "../../hooks/useHealthParameterVitalSigns";
import { PatientHealthParameterVitalSignReadingCard } from "../PatientHealthParameterVitalSignReadingCard/PatientHealthParameterVitalSignReadingCard";

type VisitVitalSignsReadingsProps = {
  visitId: string;
  token: string;
};

export const PatientHealthParametersVitalSignsReadings: FC<VisitVitalSignsReadingsProps> = props => {
  const { visitId, token } = props;

  const { data, isLoading } = useHealthParameterVitalSigns({ visitId, token });

  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <SkeletonCard count={5} height={80} widths={{ xs: 12, sm: 4, md: 4, lg: 2.4 }} />
      ) : (
        <>
          {data?.map(item => {
            return (
              <Grid key={item?.code} item xs={12} sm={4} md={4} lg={2.4}>
                <PatientHealthParameterVitalSignReadingCard
                  title={item?.display}
                  value={item?.value}
                  unit={item?.unit}
                  source={item?.source}
                />
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};
