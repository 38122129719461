import { VisitTranscription, VisitTranscriptionProcessingStatus } from "@health/queries/types";
import { FC } from "react";
import { VisitQuery, useVisitQuery } from "../gql";
import { TranscriptionFailed } from "./components/TranscriptionFailed";
import { TranscriptionLoading } from "./components/TranscriptionLoading";
import { TranscriptionSegments } from "./components/TranscriptionSegments";

interface TranscriptsTabProps {
  visitId: string;
}

export const TranscriptsTab: FC<TranscriptsTabProps> = ({ visitId }) => {
  const { data: visitData, fetchMore } = useVisitQuery({
    variables: {
      visitId: visitId,
      first: 2,
    },
  });

  const processingStatus = visitData?.visit?.transcription?.processingStatus;
  const transcription = visitData?.visit?.transcription as VisitTranscription;
  const hasMore = transcription?.segments?.pageInfo?.hasNextPage;

  const onFetchMoreSegments = () => {
    if (!hasMore) {
      return;
    }
    fetchMore({
      variables: {
        first: 10,
        after: visitData?.visit?.transcription?.segments?.pageInfo?.endCursor,
      },
      updateQuery: (prev: VisitQuery, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;
        return {
          ...fetchMoreResult,
          visit: {
            ...prev?.visit,
            transcription: {
              ...prev?.visit?.transcription,
              segments: {
                ...prev?.visit?.transcription?.segments,
                edges: [...prev?.visit?.transcription?.segments?.edges!, ...fetchMoreResult?.visit?.transcription?.segments?.edges],
                pageInfo: fetchMoreResult?.visit?.transcription?.segments?.pageInfo,
              },
            },
          },
        };
      },
    });
  };
  return (
    <>
      {processingStatus === VisitTranscriptionProcessingStatus.Processed && (
        <TranscriptionSegments transcription={transcription} handleFetchMoreSegments={onFetchMoreSegments} hasMore={!!hasMore} />
      )}
      {(processingStatus === VisitTranscriptionProcessingStatus.UnderProcess ||
        processingStatus === VisitTranscriptionProcessingStatus.Pending) && <TranscriptionLoading />}
      {processingStatus === VisitTranscriptionProcessingStatus.Failed && <TranscriptionFailed />}
    </>
  );
};
