import { FC } from "react";
import { Grid } from "@toolkit/ui";
import { useStyles } from "./EventCard.styles";
import { PropsWithChildren } from "react";

interface EventCardRootProps extends PropsWithChildren {
  classNames?: string;
  cardWidth?: string;
}

export const EventCardRoot: FC<EventCardRootProps> = ({ children, classNames, cardWidth }) => {
  const { classes, cx } = useStyles({ cardWidth });

  return (
    <Grid container className={cx(classes.root, classNames)} alignItems='center' padding={2}>
      {children}
    </Grid>
  );
};
