import { useContext, useEffect, useRef } from "react";
import { NotificationsServiceContext } from "./NotificationsServiceContext";
export const useNotificationService = () => {
    const { subscribe: orgSubscribe } = useContext(NotificationsServiceContext);
    const _unsubscribeFN = useRef();
    useEffect(() => {
        return () => {
            if (typeof _unsubscribeFN.current === "function") {
                _unsubscribeFN.current();
            }
        };
    }, []);
    const subscribe = (topic, listner) => {
        _unsubscribeFN.current = orgSubscribe(topic, listner);
    };
    const unSubscribe = () => {
        typeof _unsubscribeFN.current === "function" ? _unsubscribeFN.current() : void 0;
    };
    return { subscribe, unSubscribe };
};
