import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyInterventionCardStyle = makeStyles()(theme => ({
  card: {
    marginBottom: 8,
    borderRadius: 10,
    cursor: "pointer",
    marginInline: 1,
    boxShadow: "none",
    border: `1px solid ${theme.palette.gray.light}`,
    "&:hover": {
      boxShadow: theme.shadows[10],
    },
  },
  cardContent: {
    paddingBottom: 16,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  circleWrapper: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    gap: 8,
  },
  circle: {
    background: "linear-gradient(202deg, #25287F, #5B2669, #7D255C, #882558)",
    borderRadius: "50%",
    width: 35,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  name: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  infoWrapper: {
    color: theme.palette.stale.main,
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  infoValue: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginLeft: 2,
  },
}));
