import { GuidedCareTask } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { IGuidedCareTaskUpdateFormEvent } from "pages/GuidedCareTasks/types";
import React, { FC, MouseEvent, useRef } from "react";
import { GuidedCareTaskUpdateForm, GuidedCareTaskUpdateFormRef } from "../../forms/GuidedCareTaskUpdateForm/GuidedCareTaskUpdateForm";

type GuidedCareTaskUpdateModalProps = {
  isOpen: boolean;
  guidedCareTask: GuidedCareTask;
  isLoading?: boolean;
  onChange: (values: IGuidedCareTaskUpdateFormEvent) => void;
  onClose: (event?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareTaskUpdateModal: FC<GuidedCareTaskUpdateModalProps> = props => {
  const { isOpen, guidedCareTask, isLoading, onChange, onClose } = props;

  const { t } = useTranslation("provider");
  const guidedCareTaskUpdateFormRef = useRef<GuidedCareTaskUpdateFormRef>(null);

  const onUpdateClick = () => {
    guidedCareTaskUpdateFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Update Task"),
        onClose,
      }}
      DialogActionsProps={{
        children: (
          <>
            <Button
              onClick={onUpdateClick}
              disabled={isLoading}
              endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            >
              {t("Update")}
            </Button>

            <Button onClick={onClose} variant={"outlined"}>
              {t("Close")}
            </Button>
          </>
        ),
      }}
    >
      <GuidedCareTaskUpdateForm guidedCareTask={guidedCareTask} ref={guidedCareTaskUpdateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
