import { makeVar } from "@apollo/client";
import { CurrentNurseWorkSpaceDetail } from "./utils";

export type NurseCurrentWorkSpaceDetailsVar = {
  branch?: {
    name: string;
    id: string;
  };
  department?: {
    name: string;
    id: string;
  } | null;
  doctors?: Array<{
    name: string;
    id: string;
  }>;
};

export const NurseCurrentWorkSpaceDetailsVar = makeVar<NurseCurrentWorkSpaceDetailsVar | undefined>(
  JSON.parse(String(CurrentNurseWorkSpaceDetail))
);
