import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, Typography, useTheme, VideoConference } from "@toolkit/ui";

export const AppointmentsView = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <>
      <Grid item xs='auto'>
        <Box display='flex' justifyContent='center' alignItems='center' width='90px' height='85px' bgcolor={theme.palette.success.main}>
          <VideoConference
            sx={{
              width: "40px",
              height: "40px",
              "& path": {
                fill: `${theme.palette.common.white} !important`,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
          <Typography fontWeight='bold'>{t("Your appointment is started")}</Typography>
          <Typography variant='body2'>{t("General Medical Program by Care")}</Typography>
        </Box>
      </Grid>
    </>
  );
};
