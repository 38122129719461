import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { PatientSendNotificationData, useSendNotificationMessage } from "./hooks";
import { useStyles } from "./PatientAppointmentForm.styles";

type PatientSendNotificationFormProps = {
  onClose: () => void;
};

export const PatientSendNotificationForm: FC<PatientSendNotificationFormProps> = ({ onClose }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();

  const methods = useForm<PatientSendNotificationData>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const { handleSendNotificationMessage } = useSendNotificationMessage();
  const onSubmit = async (data: PatientSendNotificationData) => {
    const isSent = await handleSendNotificationMessage(data);
    if (isSent) {
      onClose();
    }
  };

  return (
    <CustomDialog
      type='base'
      open={true}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
      DialogTitleProps={{
        title: t("Send Notification"),
        onClose: onClose,
      }}
      DialogContentProps={{
        sx: {
          paddingInline: 2,
        },
      }}
      DialogActionsProps={{
        children: (
          <Button onClick={handleSubmit(onSubmit)} type='submit'>
            {t("Send Notification")}
          </Button>
        ),
      }}
    >
      <form>
        <FormProvider {...methods}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                {t("URL")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField isRequired type='url' name='url' label={t("Add URL")} />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                {t("Notification Message")}
              </Typography>
            </Grid>
            <Grid item xs={12} columnSpacing={2}>
              <CustomTextField isRequired multiline minRows={5} name='message' label={t("Notification Message")} />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </CustomDialog>
  );
};
