import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const Calender: FC<SvgIconProps> = ({ color, ...props }) => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.533' height='15.533' viewBox='0 0 15.533 15.533' {...props}>
      <path
        id='icons8-calendar'
        d='M18.121,4H5.412A1.412,1.412,0,0,0,4,5.412V18.121a1.412,1.412,0,0,0,1.412,1.412H18.121a1.412,1.412,0,0,0,1.412-1.412V5.412A1.412,1.412,0,0,0,18.121,4Zm-12,13.415a.706.706,0,1,1,.706-.706A.706.706,0,0,1,6.118,17.415Zm0-2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,6.118,14.591Zm2.824,2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,8.942,17.415Zm0-2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,8.942,14.591Zm0-2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,8.942,11.767Zm2.824,5.648a.706.706,0,1,1,.706-.706A.706.706,0,0,1,11.767,17.415Zm0-2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,11.767,14.591Zm0-2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,11.767,11.767Zm2.824,5.648a.706.706,0,1,1,.706-.706A.706.706,0,0,1,14.591,17.415Zm0-2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,14.591,14.591Zm-.706-3.53a.706.706,0,1,1,.706.706A.706.706,0,0,1,13.885,11.061Zm3.53,3.53a.706.706,0,1,1,.706-.706A.706.706,0,0,1,17.415,14.591Zm0-2.824a.706.706,0,1,1,.706-.706A.706.706,0,0,1,17.415,11.767Zm.706-3.53H5.412V5.412H18.121Z'
        transform='translate(-4 -4)'
        fill={color ? color : palette.common.white}
      />
    </SvgIcon>
  );
};
