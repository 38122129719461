import { useTranslation } from "@toolkit/i18n";
import { Box, Button, makeStyles, PageWrapper, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { NotificationItem, NotificationItemWrapper, ReadAllNotificationsButton } from "shared/components/Notifications";
import { useNotificationsPageHook } from "./Notifications.hook";

const useNotificationsScreenStyle = makeStyles()(theme => ({
  mainBox: {
    padding: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: theme.palette.gray[1600],
  },
}));

export const NotificationsScreen: FC = () => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useNotificationsScreenStyle();
  const { notifications, fetchMoreNotifications, pageInfo, loading } = useNotificationsPageHook();
  const { setBreadCrumb } = useBreadCrumbs();

  const hasMore = pageInfo?.hasNextPage;

  useEffect(() => {
    setBreadCrumb({ title: t("Notifications center"), values: [] });
  }, []);

  return (
    <PageWrapper>
      <Box display='flex' justifyContent='flex-end' paddingX='10px'>
        <ReadAllNotificationsButton notificationsCount={notifications?.length} />
      </Box>
      <Box className={classes.mainBox}>
        {notifications?.map(item => (
          <NotificationItemWrapper
            key={item?.id}
            id={item?.id}
            isSeen={item?.seen!}
            sx={{
              backgroundColor: "white",
              width: "848px",
              borderRadius: "10px",
              margin: "10px",
              padding: "15px !important",
              border: "1px solid",
              borderColor: theme.palette.gray[1600],
              borderLeft: !item?.seen ? `5px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.gray[1400]}`,
            }}
            renderNotificationItem={({ handleNotificationActionComplete }) => (
              <NotificationItem
                type={item?.type!}
                id={JSON.parse(String(item?.data))?.id}
                data={item?.data && JSON.parse(String(item?.data!))}
                handleNotificationActionComplete={handleNotificationActionComplete}
              />
            )}
          ></NotificationItemWrapper>
        ))}
        {hasMore && (
          <Button onClick={fetchMoreNotifications} disabled={loading}>
            {loading ? t("Loading...") : t("View More")}
          </Button>
        )}
      </Box>
    </PageWrapper>
  );
};
