import { FC } from "react";
import { VisitNotePage } from "pages/e-visit/Notes/VisitNotePage";
import { DiagnosisPage } from "pages/e-visit/Diagnosis/DiagnosisPage";
import { VitalSigns } from "pages/e-visit/VitalSigns/containers/VitalSigns/VitalSigns";
import { LiveSignsPage } from "shared/modules/patient";
import { PrescriptionPage } from "pages/e-visit/Prescription/PrescriptionPage";
import { LabTestsPage } from "pages/e-visit/LabTests/LabTestsPage";
import RadiologyPage from "pages/e-visit/Radiology/RadiologyPage";
import { DoctorConsoleType } from "./constants";
import { PatientProfilePage } from "shared/modules/patient/containers";

type DoctorConsolePageContentProps = {
  type: DoctorConsoleType;
  visitId: string;
};

export const DoctorConsolePageContent: FC<DoctorConsolePageContentProps> = ({ type, visitId }) => {
  switch (type) {
    case DoctorConsoleType.notes: {
      return <VisitNotePage visitId={visitId} />;
    }
    case DoctorConsoleType.diagnosis: {
      return <DiagnosisPage id={visitId} />;
    }
    case DoctorConsoleType.vitalSigns: {
      return <VitalSigns id={visitId} />;
    }
    case DoctorConsoleType.liveSigns: {
      return <LiveSignsPage visitId={visitId} />;
    }
    case DoctorConsoleType.prescription: {
      return <PrescriptionPage visitId={visitId} />;
    }
    case DoctorConsoleType.labTest: {
      return <LabTestsPage id={visitId} />;
    }
    case DoctorConsoleType.radiology: {
      return <RadiologyPage visitId={visitId} />;
    }
    case DoctorConsoleType.patientProfile: {
      return <PatientProfilePage visitId={visitId} />;
    }
  }
};
