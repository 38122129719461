import { Box, EmptyDataIcon, Typography } from "@toolkit/ui";
import React from "react";
import { usePatientHealthParameterEmptyStyle } from "./usePatientHealthParameterEmptyStyle";
import { useTranslation } from "@toolkit/i18n";

export const PatientHealthParameterEmpty = () => {
  const { t } = useTranslation("provider");

  const { classes } = usePatientHealthParameterEmptyStyle();

  return (
    <Box className={classes.root}>
      <EmptyDataIcon height={90} />
      <Typography className={classes.message}>{t("No Data Available")}</Typography>
    </Box>
  );
};
