import { getMaxValueValidation } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";

export const locationFields = (t: TFunction, address) => [
  {
    name: "addressInput.area",
    label: t("Area", { ns: "provider" }),
    defaultValue: address?.area,
    maxValue: getMaxValueValidation(t, 128),
  },
  {
    name: "addressInput.streetAddress1",
    label: t("English Street Address 1", { ns: "provider" }),
    defaultValue: address?.streetAddress1,
  },
  {
    name: "arabicStreetAddress",
    label: t("Arabic Street Address 1", { ns: "provider" }),
    defaultValue: address?.streetAddress1Ar,
    maxValue: getMaxValueValidation(t, 255),
  },
  {
    name: "addressInput.streetAddress2",
    label: t("English Street Address 2", { ns: "provider" }),
    defaultValue: address?.streetAddress2,
  },
  {
    name: "arabicStreetAddress2",
    label: t("Arabic Street Address 2", { ns: "provider" }),
    defaultValue: address?.streetAddress2Ar,
    maxValue: getMaxValueValidation(t, 255),
  },
];

export const checkOpenTimeRangesIsValid = (openTimeRanges): boolean => {
  return !(openTimeRanges?.closeTime === "Invalid date" || openTimeRanges?.openTime === "Invalid date");
};
