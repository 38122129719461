import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useStyles } from "./useStyles";
import { Box } from "@toolkit/ui";
import { isLTR, isRTL, pickLocalizedValue } from "@toolkit/i18n";

type VerificationInputProps = {
  onChange: (value: string) => void;
};

export type VerificationInputRef = {
  resetInput: () => void;
};

export const VerificationInputForwardRef: ForwardRefRenderFunction<VerificationInputRef, VerificationInputProps> = (props, ref) => {
  const { onChange } = props;

  const { classes } = useStyles();

  const [otp, setOTP] = useState(new Array(6).fill(""));
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const resetInput = () => {
    setOTP(new Array(6).fill(""));
  };

  const handleChange = (index, value) => {
    const newOTP = [...otp];

    const prevValues = otp[index] ? Array.from(otp[index]) : [];
    const currentValues = Array.from(value);
    const diffIndex = currentValues.findIndex((char, i) => char !== prevValues[i]);

    if (value.length > 1) {
      newOTP[index] = currentValues[diffIndex];
    } else {
      newOTP[index] = value;
    }

    setOTP(newOTP);

    if (value !== "" && index < 5 && isLTR()) {
      inputRefs.current[index + 1].focus();
    }

    if (value !== "" && index > 0 && isRTL()) {
      inputRefs.current[index - 1].focus();
    }
  };

  useImperativeHandle(ref, () => ({
    resetInput,
  }));

  useEffect(() => {
    if (isLTR()) {
      onChange(otp.join(""));
    } else {
      onChange([...otp].reverse().join(""));
    }
  }, [otp]);

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "" && isLTR()) {
      inputRefs.current[index - 1].focus();
    }
    if (event.key === "Backspace" && index < 5 && otp[index] === "" && isRTL()) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = event => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text");
    const pastedOTP = pastedData.slice(0, 6).split("");
    const newOTP = [...otp];

    pastedOTP.forEach((value, index) => {
      if (inputRefs.current[index] && value.match(/[0-9]/)) {
        const _index = pickLocalizedValue(index, 5 - index);
        newOTP[_index] = value;
      }
    });

    setOTP(newOTP);
    onChange(newOTP.join(""));
  };

  return (
    <Box sx={{ direction: "ltr" }}>
      {Array.from({ length: 6 }, (_, index) => (
        <input
          key={index}
          maxLength={1}
          type='number'
          value={otp[index]}
          onPaste={handlePaste}
          onChange={e => handleChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          ref={input => (inputRefs.current[index] = input as HTMLInputElement)}
          className={classes.input}
        />
      ))}
    </Box>
  );
};
export const VerificationInput = forwardRef(VerificationInputForwardRef);
