import { base64Atob } from "./base64";
function parseUserId(token) {
    try {
        if (!token) {
            return;
        }
        const userInfo = JSON.parse(base64Atob(token.split(".")[1] || ""));
        return userInfo.user_id;
    }
    catch (error) {
        console.error("Failed to parse accessToken", error);
        return;
    }
}
export function compareOAuthToken(ioHealthToken, meetoraToken) {
    if (ioHealthToken === meetoraToken) {
        return true;
    }
    const ioHealthId = parseUserId(ioHealthToken);
    const meetoraId = parseUserId(meetoraToken);
    return !!(ioHealthId && meetoraId && ioHealthId === meetoraId);
}
