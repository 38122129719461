/* eslint-disable max-statements */
import { CodeSystemCode, CodeSystemConcept } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteComponent, Box, Button, PlusIcon, Stack } from "@toolkit/ui";
import { FC, useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { useGetCodeSystemConceptSearchQuery } from "../../../gql";

export const getStandardSystemCode = standard => {
  switch (standard) {
    case "CPT":
      return CodeSystemCode.Cpt;
    case "DRG":
      return CodeSystemCode.DiagnosisRelatedGroup;
    case "HCPCS":
      return CodeSystemCode.Service;
    default:
      return undefined;
  }
};

type ServiceTypeCodeFieldProps = {
  onAdd: (service: CodeSystemConcept) => void;
};

export const ServiceTypeCodeField: FC<ServiceTypeCodeFieldProps> = ({ onAdd }) => {
  const { t } = useTranslation("provider");
  const [value, setValue] = useState<CodeSystemConcept | null>(null);
  const { token } = useProfileContext();

  const {
    data: servicesData,
    fetchMore,
    refetch,
  } = useGetCodeSystemConceptSearchQuery({
    variables: {
      keyword: "all",
      first: 10,
      codeSystemCode: CodeSystemCode.Diagnosis,
    },
    context: getApolloContextFormToken(token!),
  });

  const hasMore = servicesData?.codeSystemConceptSearch?.pageInfo?.hasNextPage;

  const handleInputChange = (e, option) => {
    setValue(option);
  };

  const handleSearch = keyword => {
    refetch({ keyword });
  };

  const fetchMoreData = () => {
    if (hasMore) {
      fetchMore({
        variables: {
          first: 5,
          after: servicesData?.codeSystemConceptSearch?.pageInfo?.endCursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            codeSystemConceptSearch: {
              ...fetchMoreResult.codeSystemConceptSearch,
              edges: [...prev?.codeSystemConceptSearch?.edges, ...fetchMoreResult?.codeSystemConceptSearch?.edges],
            },
          });
        },
      });
    }
  };

  const services = servicesData?.codeSystemConceptSearch?.edges?.map(service => service?.node);
  const handleAdd = () => {
    if (value) {
      onAdd(value);
      setValue(null);
    }
  };

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <Box flex={1}>
        <AutocompleteComponent
          fullWidth
          TextFieldProps={{
            label: t("Service Type"),
          }}
          value={value}
          onChange={handleInputChange}
          onSearchInput={handleSearch}
          hasMore={hasMore}
          fetchMoreData={fetchMoreData}
          getOptionLabel={o => `${o?.code} - ${o?.display}`}
          options={services || []}
        />
      </Box>
      <Box>
        <Button onClick={handleAdd} disabled={!value} startIcon={<PlusIcon color='inherit' />}>
          {t("Add")}
        </Button>
      </Box>
    </Stack>
  );
};
