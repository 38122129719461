import { makeStyles } from "@toolkit/ui";

type TimelineStylesParams = {
  isSelected: boolean;
  isTemplateIntervention: boolean;
  color: string;
};

const borderStyle = "1.5px solid";

export const useTimelineStyles = makeStyles<TimelineStylesParams>()((theme, params) => ({
  root: {
    borderRadius: params.isTemplateIntervention ? "50%" : 10,
    margin: "5px 2px",
    padding: "4px",
    width: 38,
    height: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: (() => {
      if (params.isSelected) {
        return params.isTemplateIntervention ? theme.palette.secondary.main : theme.palette.primary.main;
      }

      return theme.palette.common.white;
    })(),
    border: borderStyle,
    borderColor: params.isTemplateIntervention ? theme.palette.secondary.main : theme.palette.primary.main,
    "&:hover": {
      border: "1px solid",
      borderColor: params.color,
      background: params.color,
      "& svg": {
        fill: theme.palette.common.white,
      },
      "#id": {
        "& svg": {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  wrapper: {
    position: "relative",
  },
  iconStatusWrapper: {
    width: "17px",
    height: "17px",
    background: params.color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: borderStyle,
    borderColor: theme.palette.common.white,
    borderRadius: "50%",
    position: "absolute",
    right: -11,
    top: -8,
    zIndex: 1,
    "& svg": {
      fill: theme.palette.common.white,
    },
    "&:hover": {
      "& svg": {
        fill: theme.palette.common.white,
      },
    },
  },
  iconWrapper: {
    "& svg": {
      fill: (() => {
        if (params.isSelected) {
          return theme.palette.common.white;
        }

        if (params.isTemplateIntervention) {
          return theme.palette.secondary.main;
        }

        return theme.palette.primary.main;
      })(),
    },
  },
  personalizedIconWrapper: {
    width: "16px",
    height: "16px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: borderStyle,
    borderColor: theme.palette.primary.main,
    borderRadius: 1,
    position: "absolute",
    left: -11,
    bottom: -8,
    zIndex: 1,
    "&:hover": {
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
}));
