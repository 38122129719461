export const handleNoOpFunction = () => {};

export const delay = duration => {
  return new Promise(resolve => setTimeout(resolve, duration));
};

export const getApolloContextFormToken = (token?: string) => {
  if (!token) return undefined;
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};
