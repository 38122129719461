import { User } from "@health/queries/types";
import { CustomIcon, IconButton, MuiDrawer, useMediaQuery } from "@toolkit/ui";
import { ProfileScreen } from "pages/profile";
import { FC } from "react";
import { useStyles } from "./useStyles";

interface DoctorProfileDrawerProps {
  isOpen: boolean;
  user: User;
  isLoadingUser: boolean;
  onClose: () => void;
}

export const DoctorProfileDrawer: FC<DoctorProfileDrawerProps> = ({ isOpen, onClose, user, isLoadingUser }) => {
  const isMobile = useMediaQuery("(max-width:1600px)");
  const { classes } = useStyles();

  return (
    <MuiDrawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : "80%",
        },
      }}
    >
      <IconButton onClick={onClose} className={classes.iconButton}>
        <CustomIcon icon='close' viewBox='-1 0 26 25' />
      </IconButton>

      <ProfileScreen user={user} isLoadingUser={isLoadingUser} isEditable={false} />
    </MuiDrawer>
  );
};

export default DoctorProfileDrawer;
