import { getEnvVariable } from "@/env";
import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";

export type UserInfoType = {
  app_role: string;
  app_type: string;
  aud: string;
  doctor_id?: number;
  email_verified: boolean;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  meeting_platform_id: string;
  name: string;
  permissions: Array<string>;
  preferred_username: string;
  sub: string;
  user_id: number;
  vendor_id: number;
  vendor_user_type: string;
};
const isIframe = window !== window.top;
export const oidcUserManager = new UserManager({
  authority: getEnvVariable("KEYCLOAK_REALM_LINK"),
  client_id: getEnvVariable("KEYCLOAK_CLIENT_ID_PROVIDER"),
  redirect_uri: location.href,
  response_type: "code",
  scope: "openid profile email",
  userStore: isIframe ? undefined : new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTimeInSeconds: 10,
  includeIdTokenInSilentSignout: true,
});

oidcUserManager.events.addUserLoaded(user => {
  if (!user || !user?.access_token || user?.expired) {
    oidcUserManager.signinRedirect();
  }
});

Log.setLogger(console);
