import { MarketplaceApprovalStatus } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, IconButton, Tooltip, useAddToast } from "@toolkit/ui";

import { FC } from "react";
import { useMarketplaceHealthPackageSubmitForApprovalMutation, MarketplaceHealthPackageSubmitForApprovalMutation } from "../../gql";
import { HealthPackagesQueryNode } from "pages/HealthPackages/types";

export const HealthPackageRequestReviewButton: FC<{ row: HealthPackagesQueryNode }> = ({ row }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [healthPackageRequestApprovalMutation, { loading }] = useMarketplaceHealthPackageSubmitForApprovalMutation({
    onCompleted: (data: MarketplaceHealthPackageSubmitForApprovalMutation) => {
      if (data.marketplaceHealthPackageSubmitForApproval?.approvalStatus === MarketplaceApprovalStatus.PendingApproval) {
        succeeded(t("Succeeded in Requesting Approval!"));
      } else failed(t("Failed to Request Approval!"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleRequestReview = () => {
    healthPackageRequestApprovalMutation({
      variables: {
        marketplaceHealthPackageSubmitForApprovalId: row?.id,
      },
    });
  };

  const isButtonDisabled =
    (row?.approvalStatus !== MarketplaceApprovalStatus.Draft && row?.approvalStatus !== MarketplaceApprovalStatus.Rejected) ||
    !row?.updatable;

  return (
    <Tooltip title={t("Send health package to review")}>
      <IconButton disabled={loading || isButtonDisabled} onClick={handleRequestReview}>
        <CustomIcon icon='share' />
      </IconButton>
    </Tooltip>
  );
};
