/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceDiscountUpdateMutationVariables = Types.Exact<{
  marketplaceDiscountUpdateId: Types.Scalars['ID'];
  input: Types.MarketplaceDiscountUpdateInput;
}>;


export type MarketplaceDiscountUpdateMutation = { __typename?: 'Mutation', marketplaceDiscountUpdate?: { __typename?: 'MarketplaceDiscount', id: string } | null };


export const MarketplaceDiscountUpdateDocument = gql`
    mutation marketplaceDiscountUpdate($marketplaceDiscountUpdateId: ID!, $input: MarketplaceDiscountUpdateInput!) {
  marketplaceDiscountUpdate(id: $marketplaceDiscountUpdateId, input: $input) {
    id
  }
}
    `;
export type MarketplaceDiscountUpdateMutationFn = Apollo.MutationFunction<MarketplaceDiscountUpdateMutation, MarketplaceDiscountUpdateMutationVariables>;

/**
 * __useMarketplaceDiscountUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceDiscountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceDiscountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceDiscountUpdateMutation, { data, loading, error }] = useMarketplaceDiscountUpdateMutation({
 *   variables: {
 *      marketplaceDiscountUpdateId: // value for 'marketplaceDiscountUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceDiscountUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceDiscountUpdateMutation, MarketplaceDiscountUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceDiscountUpdateMutation, MarketplaceDiscountUpdateMutationVariables>(MarketplaceDiscountUpdateDocument, options);
      }
export type MarketplaceDiscountUpdateMutationHookResult = ReturnType<typeof useMarketplaceDiscountUpdateMutation>;
export type MarketplaceDiscountUpdateMutationResult = Apollo.MutationResult<MarketplaceDiscountUpdateMutation>;
export type MarketplaceDiscountUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceDiscountUpdateMutation, MarketplaceDiscountUpdateMutationVariables>;