import { DoctorAvailabilityStatusEnum } from "@health/queries/types";
import { useOnDoctorAvailabilityStatusChanged } from "@health/sse";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useToasts } from "@toolkit/ui";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { DoctorAvailabilityStatusType } from "./DoctorAvailabilityStatus.type";
import { MeDoctorDocument, useDoctorAvailabilityStatusUpdateMutation, useMeDoctorQuery } from "./gql";
import { useDoctorAvailabilityStatusDataHook } from "./useDoctorAvailabilityStatusDataHook";

export const useDoctorAvailabilityStatus = () => {
  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  const [doctorUpdate, { loading: doctorAvailabilityStatusUpdateMutationLoading }] = useDoctorAvailabilityStatusUpdateMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: [MeDoctorDocument],
    ignoreResults: true,
    onCompleted: response => {
      if (response?.doctorAvailabilityStatusUpdate?.doctorErrors?.length) {
        const formattedMessage = formatMessageErrors(response?.doctorAvailabilityStatusUpdate?.doctorErrors);
        addToast(formattedMessage, { appearance: "error", autoDismiss: true });
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedMessage = formatGraphQLError(graphQLErrors);
      addToast(formattedMessage, { appearance: "error", autoDismiss: true });
    },
  });

  const {
    data: doctorData,
    refetch,
    loading: meDoctorQueryLoading,
  } = useMeDoctorQuery({
    notifyOnNetworkStatusChange: true,
  });

  const doctor = doctorData?.me?.doctor;
  const isDoctorUser = Boolean(doctor);
  const lastAvailability = doctor?.lastAvailability;
  const status = doctor?.availabilityStatus;
  const startTime = lastAvailability?.startTime;
  const endTime = lastAvailability?.endTime;

  const { timeDiff } = useMemo(() => {
    const diffBetweenEndTimeAndStartTime: number = startTime && endTime ? moment(moment(endTime)).diff(moment(startTime), "hours") : 0;
    const diffBetweenEndTimeAndNow: number = moment(moment(endTime)).diff(moment.now(), "milliseconds");
    return { numberOfHours: diffBetweenEndTimeAndStartTime, timeDiff: diffBetweenEndTimeAndNow };
  }, [endTime?.toString(), startTime?.toString()]);

  const { AvailabilityStatusValueList, getCurrentDoctorAvailabilityStatus, defaultStatus } = useDoctorAvailabilityStatusDataHook();
  const [selectedStatus, setSelectedStatus] = useState<DoctorAvailabilityStatusType | undefined>(defaultStatus);

  useOnDoctorAvailabilityStatusChanged(({ data }) => {
    const isSameStatus =
      data.status === status &&
      endTime &&
      data.endTime &&
      moment(data.endTime).isSame(moment(endTime)) &&
      startTime &&
      data.startTime &&
      moment(data.startTime).isSame(moment(startTime));

    if (!isSameStatus) {
      refetch();
    }
  });
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (status !== DoctorAvailabilityStatusEnum.Unavailable && timeDiff > 0) {
      timer.current = setTimeout(() => {
        refetch();
      }, timeDiff);
      return () => {
        timer.current && clearTimeout(timer.current);
      };
    }
  }, [timeDiff, status]);

  useEffect(() => {
    status && setSelectedStatus(getCurrentDoctorAvailabilityStatus(status));
  }, [status]);

  const handleUpdateDoctorAvailabilityStatus = (selectedItem: DoctorAvailabilityStatusType) => () => {
    doctorUpdate({
      variables: {
        availabilityStatus: selectedItem.availabilityStatus,
        period: selectedItem.period,
      },
    });
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(event.currentTarget);
  };
  const handleClose = () => {
    setIsOpen(null);
  };

  const loading = doctorAvailabilityStatusUpdateMutationLoading || meDoctorQueryLoading;

  return {
    open,
    isOpen,
    selectedStatus,
    isDoctorUser,
    loading,
    handleClick,
    handleClose,
    handleUpdateDoctorAvailabilityStatus,
    AvailabilityStatusValueList,
  };
};
