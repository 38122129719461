import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, useAutocompleteCustomHook } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { NurseCurrentWorkSpaceDetailsVar } from "../NurseCurrentWorkSpaceDetailsVar";
import { useDepartmentsQuery } from "gql/queries";

export const DepartmentsFilter: FC = () => {
  const branchUserPreference = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const { t } = useTranslation("provider");
  const {
    watch,
    control,
    formState: { errors: formErrors },
  } = useFormContext();
  const selectedBranch = watch("branch");
  const departmentAutocompleteFilterInput = {
    branch: selectedBranch?.id,
  };

  const {
    data: DepartmentsData,
    isLoading: loadingDepartments,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useDepartmentsQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
      filter: {
        name_Icontains: "",
        ...departmentAutocompleteFilterInput,
      },
    },
    dataAccessor: "vendorDepartments",
  });
  const departmentPageInfo = DepartmentsData?.vendorDepartments?.pageInfo;
  const hasNextPage = departmentPageInfo?.hasNextPage;
  const departments = DepartmentsData?.vendorDepartments?.edges?.map(item => item?.node)!;
  const departmentsOptions = departments?.map(item => {
    return { name: item?.name, id: item?.id };
  });
  const handleSearch = (filter: string) => {
    refetch({
      filter: {
        name_Icontains: filter,
        ...departmentAutocompleteFilterInput,
      },
    });
  };
  return (
    <AutocompleteController
      ControllerProps={{
        name: "department",
        control: control,
        defaultValue: branchUserPreference?.department,
      }}
      fullWidth
      loading={loadingDepartments}
      fetchMoreData={onFetchMoreData}
      hasMore={hasNextPage}
      TextFieldProps={{
        label: t("Department"),
        placeholder: t("Department"),
        error: Boolean(formErrors?.department?.message),
        helperText: t(formErrors?.department?.message),
      }}
      getOptionLabel={o => o?.name || o?.code || o}
      options={departmentsOptions || []}
      isOptionEqualToValue={(option, id) => option?.id === id?.id}
      onSearchInput={handleSearch}
    />
  );
};
