import {
  HealthPackagesAutocomplete,
  MarketplaceHealthPackagesCategoriesAutocomplete,
  MarketplaceProductsAutocomplete,
  MarketplaceProductsCategoriesAutocomplete,
} from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormDatePickerField, FormTextField, Grid } from "@toolkit/ui";
import React, { FC } from "react";

export const PromotionInformationForm: FC = () => {
  const { t } = useTranslation("provider");

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"name"} label={t("Name")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"description"} label={t("Description")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <MarketplaceProductsAutocomplete name={"product"} label={t("Product")} multiple />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <MarketplaceProductsCategoriesAutocomplete name={"productCategory"} multiple label={t("Product Category")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <HealthPackagesAutocomplete name='healthPackage' label={t("Health Package")} multiple />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <MarketplaceHealthPackagesCategoriesAutocomplete name='healthPackageCategory' multiple label={t("Health Package Category")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormDatePickerField name='startDate' label={"Start Date"} placeholder={t("Start Date")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormDatePickerField name='endDate' label={t("End Date")} placeholder={t("End Date")} />
      </Grid>
    </Grid>
  );
};
