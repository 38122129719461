/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductQueryVariables = Types.Exact<{
  marketplaceProductId: Types.Scalars['ID'];
}>;


export type MarketplaceProductQuery = { __typename?: 'Query', marketplaceProduct?: { __typename?: 'MarketplaceProduct', description?: string | null, descriptionAr?: string | null, howToUse?: string | null, howToUseAr?: string | null, id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, returnPolicy?: string | null, returnPolicyAr?: string | null, returnPolicyDays?: number | null, returnable?: boolean | null, shortDescription?: string | null, shortDescriptionAr?: string | null, sku?: string | null, stockQuantity: number, subImages?: Array<string | null> | null, tags?: Array<string | null> | null, brand?: { __typename?: 'MarketplaceBrand', id: string, name?: string | null, nameAr?: string | null } | null, category?: { __typename?: 'MarketplaceProductCategory', id: string, nameAr?: string | null, name?: string | null } | null } | null };


export const MarketplaceProductDocument = gql`
    query marketplaceProduct($marketplaceProductId: ID!) {
  marketplaceProduct(id: $marketplaceProductId) {
    brand {
      id
      name
      nameAr
    }
    category {
      id
      nameAr
      name
    }
    description
    descriptionAr
    howToUse
    howToUseAr
    id
    mainImageUrl
    name
    nameAr
    price
    returnPolicy
    returnPolicyAr
    returnPolicyDays
    returnable
    shortDescription
    shortDescriptionAr
    sku
    stockQuantity
    subImages
    tags
  }
}
    `;

/**
 * __useMarketplaceProductQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductQuery({
 *   variables: {
 *      marketplaceProductId: // value for 'marketplaceProductId'
 *   },
 * });
 */
export function useMarketplaceProductQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceProductQuery, MarketplaceProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceProductQuery, MarketplaceProductQueryVariables>(MarketplaceProductDocument, options);
      }
export function useMarketplaceProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductQuery, MarketplaceProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceProductQuery, MarketplaceProductQueryVariables>(MarketplaceProductDocument, options);
        }
export type MarketplaceProductQueryHookResult = ReturnType<typeof useMarketplaceProductQuery>;
export type MarketplaceProductLazyQueryHookResult = ReturnType<typeof useMarketplaceProductLazyQuery>;
export type MarketplaceProductQueryResult = Apollo.QueryResult<MarketplaceProductQuery, MarketplaceProductQueryVariables>;