import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

interface CardsContainerArrowProps extends SvgIconProps {
  direction?: "up" | "down" | "left" | "right";
}

export const CardsContainerArrow: FC<CardsContainerArrowProps> = props => {
  const { width, height, fill, direction = "right" } = props;
  const { palette } = useTheme();
  let rotationAngle;
  switch (direction) {
    case "up":
      rotationAngle = 270;
      break;
    case "down":
      rotationAngle = 90;
      break;
    case "left":
      rotationAngle = 180;
      break;
    case "right":
    default:
      rotationAngle = 0;
      break;
  }

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 23.551 19.625'
      sx={{ transform: `rotate(${rotationAngle}deg)` }}
    >
      <path
        id='icons8-arrow_5_'
        data-name='icons8-arrow (5)'
        d='M22.737,10a1.957,1.957,0,0,1,1.388.575l7.85,7.85a1.96,1.96,0,0,1,0,2.775l-7.85,7.85a1.962,1.962,0,0,1-2.775-2.775l4.5-4.5H10.962a1.962,1.962,0,1,1,0-3.925H25.85l-4.5-4.5A1.962,1.962,0,0,1,22.737,10Z'
        transform='translate(-9 -10)'
        fill={fill || palette.primary.main}
      />
    </SvgIcon>
  );
};
