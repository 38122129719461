import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Grid, Typography, useTheme } from "@toolkit/ui";
import React, { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PatientHealthParameterHistoryModal } from "pages/PatientHealthParameters/modals";
import { PatientHealthParametersVisit, PatientHealthParametersPatient } from "pages/PatientHealthParameters/components";

type LiveSignsProps = {
  patientId: string;
  isDoctorConsole: boolean;
};

export const LiveSigns: FC<LiveSignsProps> = props => {
  const { patientId, isDoctorConsole } = props;

  const [showHistory, setShowHistory] = useState(false);

  const { t } = useTranslation("provider");
  const theme = useTheme();

  const [params] = useSearchParams();

  const token = params.get("token")!;
  const visitId = params.get("visitId")!;

  const handleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  return (
    <Grid item container spacing={2} padding={4}>
      <PatientHealthParameterHistoryModal />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            {!isDoctorConsole && (
              <Typography fontSize={30} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                {t("Live Signs")}
              </Typography>
            )}
          </Grid>

          <Grid xs={4} lg={2}>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Button onClick={handleShowHistory}>{showHistory ? t("Last Read") : t("Show History")}</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {showHistory ? (
          <PatientHealthParametersPatient patientId={patientId} token={token} />
        ) : (
          <PatientHealthParametersVisit visitId={visitId} token={token} />
        )}
      </Grid>
    </Grid>
  );
};
