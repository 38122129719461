/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplacePromotionPublishStatusUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  isPublish: Types.Scalars['Boolean'];
}>;


export type MarketplacePromotionPublishStatusUpdateMutation = { __typename?: 'Mutation', publishMarketplacePromotion?: { __typename?: 'MarketplacePromotion', id: string, isPublished: boolean } | null, unPublishMarketplacePromotion?: { __typename?: 'MarketplacePromotion', id: string, isPublished: boolean } | null };


export const MarketplacePromotionPublishStatusUpdateDocument = gql`
    mutation MarketplacePromotionPublishStatusUpdate($id: ID!, $isPublish: Boolean!) {
  publishMarketplacePromotion: marketplacePromotionPublish(id: $id) @include(if: $isPublish) {
    id
    isPublished
  }
  unPublishMarketplacePromotion: marketplacePromotionUnpublish(id: $id) @skip(if: $isPublish) {
    id
    isPublished
  }
}
    `;
export type MarketplacePromotionPublishStatusUpdateMutationFn = Apollo.MutationFunction<MarketplacePromotionPublishStatusUpdateMutation, MarketplacePromotionPublishStatusUpdateMutationVariables>;

/**
 * __useMarketplacePromotionPublishStatusUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplacePromotionPublishStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePromotionPublishStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplacePromotionPublishStatusUpdateMutation, { data, loading, error }] = useMarketplacePromotionPublishStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPublish: // value for 'isPublish'
 *   },
 * });
 */
export function useMarketplacePromotionPublishStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplacePromotionPublishStatusUpdateMutation, MarketplacePromotionPublishStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplacePromotionPublishStatusUpdateMutation, MarketplacePromotionPublishStatusUpdateMutationVariables>(MarketplacePromotionPublishStatusUpdateDocument, options);
      }
export type MarketplacePromotionPublishStatusUpdateMutationHookResult = ReturnType<typeof useMarketplacePromotionPublishStatusUpdateMutation>;
export type MarketplacePromotionPublishStatusUpdateMutationResult = Apollo.MutationResult<MarketplacePromotionPublishStatusUpdateMutation>;
export type MarketplacePromotionPublishStatusUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplacePromotionPublishStatusUpdateMutation, MarketplacePromotionPublishStatusUpdateMutationVariables>;