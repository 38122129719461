import { useCallback, useEffect, useRef, useState } from "react";

type MeetoraMessageEvent = MessageEvent<
  | { action: "ready" | "close" | "closeWindow" }
  | { action: "callReady" }
  | { action: "doctorConsoleStatus"; open: boolean; visitId: string }
>;

export const useVisitCallEmbedHook = ({ token, onRequestClose }: { token: string; onRequestClose?: (closeWindow?: boolean) => void }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isReady, setReady] = useState(false);
  const [hasError, setError] = useState(false);
  const [doctorConsole, setDoctorConsole] = useState<string | null>(null);

  const onLoad = useCallback(() => setError(false), []);
  const onError = useCallback(() => setError(true), []);
  const onRetry = useCallback(() => {
    setError(false);
    if (iframeRef.current) {
      iframeRef.current.src += "";
    }
  }, []);

  useEffect(() => {
    const exchangeTokens = ({ data }: MeetoraMessageEvent) => {
      switch (data?.action) {
        case "callReady": {
          iframeRef.current?.contentWindow?.postMessage(
            {
              action: "call_config",
              doctorConsole: "iframe_exchange",
            },
            "*"
          );
          break;
        }
        case "doctorConsoleStatus": {
          setDoctorConsole(data.open ? data.visitId : null);
          break;
        }
        case "ready": {
          setReady(true);
          iframeRef.current?.contentWindow?.postMessage({ token }, "*");
          break;
        }
        case "close": {
          onRequestClose?.();
          break;
        }
        case "closeWindow": {
          onRequestClose?.(true);
          break;
        }
      }
    };

    window.addEventListener("message", exchangeTokens, false);

    return () => {
      window.removeEventListener("message", exchangeTokens, false);
    };
  }, [onRequestClose, token]);

  return { ref: iframeRef, isReady, doctorConsole, hasError, onLoad, onError, onRetry };
};
