import { makeStyles } from "@toolkit/ui";

export const usePatientHealthParameterEmptyStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  message: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
