import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyGoalsStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  text: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  circle: {
    borderRadius: 25,
    marginInline: 1,
    width: 7,
    height: 7,
    background: theme.palette.primary[200],
  },
}));
