import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  listContainer: {
    overflow: "auto",
    height: "100%",
    width: "100%",
    paddingInline: 8,
  },
  listWrapper: {
    flex: 1,
  },
  card: {
    height: "calc(100vh - 150px)",
    display: "flex",
    flexDirection: "column",
  },
}));
