/* eslint-disable max-statements */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { fetchFreshAccessToken } from "shared/utils/tokenUtils";

export const useVisitCallHook = ({ meetingLink }: { meetingLink: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [token, setToken] = useState();
  const { accessToken, refreshToken } = useOidcUserProfile();

  useEffect(() => {
    setToken(undefined);
  }, []);

  const isLoadingRef = useRef(false);

  const getToken = useCallback(async () => {
    try {
      if (isLoadingRef.current || token || !accessToken || !refreshToken) {
        return;
      }
      isLoadingRef.current = true;
      setLoading(true);
      setError(false);

      const value = await fetchFreshAccessToken(accessToken!, refreshToken!, r => r);
      if (!value) {
        setError(true);
        return;
      }

      setToken(value);
    } catch (e) {
      setError(true);
    } finally {
      isLoadingRef.current = false;
      setLoading(false);
    }
  }, [accessToken, refreshToken, token]);

  const meetingInfo = useMemo(() => {
    if (!token || !meetingLink) {
      return;
    }

    return {
      token,
      link: meetingLink
        .replace(/(^")|("$)/g, "")
        .replace("@oauth_token", "iframe_exchange")
        .replace("@exit_url", "iframe"),
    };
  }, [meetingLink, token]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return { meetingInfo, loading, error, handleRetry: getToken };
};
