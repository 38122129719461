import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { MedicationIcon } from "@toolkit/ui";
import { medicalFormsPaths, medicalFormsRoute } from "pages/MedicalForms/constants/MedicalFormsPaths";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { ProviderMedicalFormCreateContainer, ProviderMedicalFormUpdateContainer, ProviderMedicalFormsContainer } from "../containers";

export const medicalFormsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "medical-forms",
    text: t("Medical Forms", { ns: "provider" }),
    icon: <MedicationIcon />,
    route: medicalFormsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageMedicalForms),
    subItems: [
      {
        id: medicalFormsPaths.main.fullPath,
        route: medicalFormsPaths.main.route,
        fullPath: medicalFormsPaths.main.fullPath,
        text: "",
        element: <ProviderMedicalFormsContainer />,
        onClick: route => navigate(route),
      },
      {
        id: medicalFormsPaths.new.fullPath,
        route: medicalFormsPaths.new.route,
        fullPath: medicalFormsPaths.new.fullPath,
        text: "",
        element: <ProviderMedicalFormCreateContainer />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: medicalFormsPaths.update.fullPath,
        route: medicalFormsPaths.update.route,
        fullPath: medicalFormsPaths.update.fullPath,
        text: "",
        element: <ProviderMedicalFormUpdateContainer />,
        onClick: route => navigate(route),
        hidden: true,
      },
    ],
  };
};
