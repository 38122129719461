import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useGuidedCareJourneyEligibleInterventionsAlertStyle = makeStyles()(theme => ({
  root: {
    marginInline: 16,
  },
  alert: {
    background: theme.palette.error.light,
    color: theme.palette.primary.main,
    alignItems: "center",
    "& .MuiAlert-message": {
      flex: 1,
    },
    "& .MuiAlert-icon": {
      color: theme.palette.error.main,
      fontSize: 35,
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  messageWrapper: {
    display: "flex",
    flex: 1,
    gap: 4,
  },
  actionsWrapper: {
    display: "flex",
    gap: 4,
  },
  button: {
    borderRadius: 32,
    fontSize: theme.mixins.fonts.fontSize.xs,
    paddingInline: 18,
    height: 38,
  },
  iconButtonsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    transform: pickLocalizedValue("rotate(0)", "rotate(180deg)"),
  },
  iconButtonPrev: {
    marginRight: 0.5,
  },
  iconButtonPrevText: {
    marginLeft: 4,
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  iconButtonNextText: {
    marginRight: 4,
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  circle: {
    marginInline: 4,
    height: 8,
    width: 8,
    borderRadius: "50%",
  },
}));
