/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorAppointmentRejectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.DoctorAppointmentRejectInput;
}>;


export type DoctorAppointmentRejectMutation = { __typename?: 'Mutation', doctorAppointmentReject?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const DoctorAppointmentRejectDocument = gql`
    mutation doctorAppointmentReject($id: ID!, $input: DoctorAppointmentRejectInput!) {
  doctorAppointmentReject(id: $id, input: $input) {
    appointment {
      id
      status
    }
    appointmentErrors {
      field
      message
      code
    }
  }
}
    `;
export type DoctorAppointmentRejectMutationFn = Apollo.MutationFunction<DoctorAppointmentRejectMutation, DoctorAppointmentRejectMutationVariables>;

/**
 * __useDoctorAppointmentRejectMutation__
 *
 * To run a mutation, you first call `useDoctorAppointmentRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorAppointmentRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorAppointmentRejectMutation, { data, loading, error }] = useDoctorAppointmentRejectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoctorAppointmentRejectMutation(baseOptions?: Apollo.MutationHookOptions<DoctorAppointmentRejectMutation, DoctorAppointmentRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorAppointmentRejectMutation, DoctorAppointmentRejectMutationVariables>(DoctorAppointmentRejectDocument, options);
      }
export type DoctorAppointmentRejectMutationHookResult = ReturnType<typeof useDoctorAppointmentRejectMutation>;
export type DoctorAppointmentRejectMutationResult = Apollo.MutationResult<DoctorAppointmentRejectMutation>;
export type DoctorAppointmentRejectMutationOptions = Apollo.BaseMutationOptions<DoctorAppointmentRejectMutation, DoctorAppointmentRejectMutationVariables>;