import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useGuidedCareJourneyInterventionsActivitiesDialogTitleStyle = makeStyles()(theme => ({
  root: {
    padding: "0 !important",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  iconWrapper: {
    height: 30,
    width: 30,
  },
  icon: {
    transform: pickLocalizedValue("rotate(0)", "rotate(180deg)"),
  },
}));
