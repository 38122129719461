import { FC } from "react";
import { PatientActivityType } from "./PatientAddActivity.types";
import { PatientAppointmentForm } from "./PatientAppointmentForm";
import { PatientSendNotificationForm } from "./PatientSendNotificationForm";

type PatientAddActivityDialogProps = {
  type?: PatientActivityType;
  onClose: () => void;
};

export const PatientAddActivityDialog: FC<PatientAddActivityDialogProps> = ({ type, onClose }) => {
  switch (type) {
    case PatientActivityType.SendNotification:
      return <PatientSendNotificationForm onClose={onClose} />;

    case PatientActivityType.Service:
    case PatientActivityType.Visit:
    case PatientActivityType.MedicalForms:
      return <PatientAppointmentForm type={type} onClose={onClose} />;
    default:
      return null;
  }
};
