import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { GuidedCareTeamActivationMutation, useGuidedCareTeamActivationMutation } from "pages/HealthProviderManagement/GuidedCare/gql";
import { FC, useState } from "react";

type GuidedCareTeamActivationProps = {
  id: string;
  isActive: boolean;
};

export const GuidedCareTeamActivation: FC<GuidedCareTeamActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [fetchGuidedCareTeamActivationMutation, { loading: isSubmitting }] = useGuidedCareTeamActivationMutation({
    onCompleted: (data: GuidedCareTeamActivationMutation) => {
      const errors = data?.changeProviderGuidedCareHealthProgramTeamActiveStatus?.providerGuidedCareHealthProgramTeamErrors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        setActive(!!data?.changeProviderGuidedCareHealthProgramTeamActiveStatus?.providerGuidedCareHealthProgramTeam?.isActive);
        succeeded(t("Team updated successfully"));
      }
    },
    onError: () => {
      failed(t("Team update failed"));
    },
  });

  const handleChangeToggle = () => {
    fetchGuidedCareTeamActivationMutation({
      variables: {
        id: id!,
      },
    });
  };

  return <CustomToggleButton isLoading={isSubmitting} disabled={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
