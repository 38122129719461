import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, ShowButton, TextField, Typography } from "@toolkit/ui";
import React, { FC, useState } from "react";
import { useGetBranchAddressQuery } from "./gql";

export const AddressInformation: FC<{ id: string }> = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("provider");
  const currentDir = i18n.dir(i18n.language);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const { data } = useGetBranchAddressQuery({
    variables: {
      id: id,
    },
    skip: !isOpen,
  });
  const address = data?.branch?.address;

  return (
    <React.Fragment>
      <ShowButton onClick={handleOpen} />

      {isOpen && (
        <CustomDialog
          type='base'
          open={isOpen}
          DialogTitleProps={{
            title: t("Address Information"),
            onClose: handleClose,
          }}
        >
          <>
            {!address ? (
              <Typography> {t("There is No Address Information")}</Typography>
            ) : (
              <Grid container spacing={2}>
                {address?.city && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      dir={currentDir}
                      fullWidth
                      placeholder={t("City")}
                      value={pickLocalizedValue(address?.city?.name, address.city?.nameAr)}
                      label={t("City")}
                    />
                  </Grid>
                )}

                {address?.area && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      dir={currentDir}
                      fullWidth
                      placeholder={t("Area")}
                      value={pickLocalizedValue(address?.area, address?.areaAr)}
                      label={t("Area")}
                    />
                  </Grid>
                )}

                {address?.streetAddress1 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      dir={currentDir}
                      placeholder={t("Street Address 1")}
                      value={pickLocalizedValue(address?.streetAddress1, address?.streetAddress1Ar)}
                      label={t("Street Address 1")}
                    />
                  </Grid>
                )}

                {address?.streetAddress2 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      dir={currentDir}
                      placeholder={t("Street Address 2")}
                      value={pickLocalizedValue(address?.streetAddress2, address?.streetAddress2Ar)}
                      label={t("Street Address 2")}
                    />
                  </Grid>
                )}

                {address?.buildingNumber && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      dir={currentDir}
                      placeholder={t("Building Number")}
                      value={t(address?.buildingNumber)}
                      label={t("Building Number")}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        </CustomDialog>
      )}
    </React.Fragment>
  );
};
