import React, { FC, useEffect, useState } from "react";
import { Box, Tab, TabContext, TabList, TabPanel, type TabListProps } from "../../base/mui";
import { useTabsStyle } from "./styles";
import { TabsProps } from "./types";

const CustomTabs: FC<TabsProps> = ({ variant = "modal", items, TabListProps }) => {
  const { classes } = useTabsStyle();
  const [value, setValue] = React.useState(items[0]?.value);
  const [tabs, setTabs] = useState(items);
  const handleChange: TabListProps["onChange"] = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTabs(items);
  }, [items]);

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <TabList
          variant='fullWidth'
          classes={{
            flexContainer: variant === "card" ? classes.cardTabs : classes.tabs,
          }}
          onChange={handleChange}
          aria-label='lab API tabs example'
          {...TabListProps}
        >
          {tabs?.map(item => (
            <Tab
              id={item.value}
              key={item.value + item.name}
              label={item.name}
              value={item.value}
              aria-controls={item.value}
              disabled={item?.disabled ?? false}
              classes={{
                root: variant === "card" ? classes.cardTab : classes.tab,
                selected: variant === "card" ? classes.cardSelectedTab : classes.selectedTab,
              }}
            />
          ))}
        </TabList>
        {tabs?.map((item, index) => (
          <TabPanel
            key={item.value + item.name}
            value={item.value}
            classes={{ root: classes.tabPanel }}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
          >
            {item.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default CustomTabs;
