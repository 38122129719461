import { Button, useAddToast } from "@toolkit/ui";
import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { AppointmentSortingField, H_OrderDirection, Maybe, ServiceDetails } from "@health/queries/types";
import {
  AppointmentServiceDetailsUpsertModal,
  AppointmentServiceDetailsUpsertModalApi,
} from "../../modals/AppointmentServiceDetailsUpsert/AppointmentServiceDetailsUpsertModal";
import { IAppointmentServiceDetailsUpsertFormEvent } from "../../forms/AppointmentServiceDetailsUpsert/AppointmentServiceDetailsUpsertForm";
import { AppointmentsDocument, useAppointmentServiceDetailsUpdateMutation } from "../../gql";
import { formatGraphQLError } from "@toolkit/apollo";
import { PageTabsStatusType } from "shared/components/PageTabs";
import { AppointmentTabsStatus, mapTabStatesToFilterInput } from "../../utils";

type AppointmentServiceDetailsManageProps = {
  id: string;
  status: PageTabsStatusType;
  serviceDetails?: Maybe<Maybe<ServiceDetails>[]>;
};

export const AppointmentServiceDetailsManage: FC<AppointmentServiceDetailsManageProps> = props => {
  const { id, status, serviceDetails } = props;

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [updateAppointmentServiceDetails, { loading }] = useAppointmentServiceDetailsUpdateMutation({
    onCompleted: () => {
      succeeded(t("Appointment service details updated successfully"));
      AppointmentServiceDetailsUpsertModalApi.close();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: AppointmentsDocument,
        variables: {
          first: 10,
          includeCancellationReason: status === AppointmentTabsStatus.Cancelled,
          filter: { ...mapTabStatesToFilterInput(status) },
          sortBy: {
            direction: H_OrderDirection.Desc,
            field: AppointmentSortingField.Created,
          },
        },
      },
    ],
  });

  const onManageClick = () => {
    AppointmentServiceDetailsUpsertModalApi.open({
      appointmentId: id,
      serviceDetails: serviceDetails as ServiceDetails[],
    });
  };

  const onAppointmentServiceDetailsUpsertModalChange = (event: IAppointmentServiceDetailsUpsertFormEvent) => {
    if (event.type === "UPDATE") {
      updateAppointmentServiceDetails({
        variables: {
          appointmentId: event.payload.appointmentId,
          serviceDetailsInputs: event.payload.values?.items?.map(item => ({
            id: item?.id,
            serviceCode: item?.service?.value?.code,
            serviceName: item?.service?.value?.display,
            standard: item?.standard,
          })),
        },
      });
    }
  };

  return (
    <>
      <AppointmentServiceDetailsUpsertModal isLoading={loading} onChange={onAppointmentServiceDetailsUpsertModalChange} />

      <Button onClick={onManageClick}>{t("Manage")}</Button>
    </>
  );
};
