import { makeStyles } from "@toolkit/ui";

export const usePatientHealthParameterVitalSignCardInformationStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
    marginBottom: 4,
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  source: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.stale.main,
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  expandIcon: {
    height: 20,
  },
  percentageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 2,
  },
  percentage: {
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  date: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.stale.main,
  },
}));
