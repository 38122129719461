/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PatientCurrentMedicalStatusFragmentFragmentDoc } from '../../fragments/__generated__/patientCurrentMedicalStatus';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePatientChronicMedicationMutationVariables = Types.Exact<{
  deletePatientChronicMedicationId: Types.Scalars['ID'];
  patientId: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
}>;


export type DeletePatientChronicMedicationMutation = { __typename?: 'Mutation', deletePatientChronicMedication?: { __typename?: 'PatientMedicalProfile', id: string, patientCurrentMedicalStatus?: { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null } | null } | null };


export const DeletePatientChronicMedicationDocument = gql`
    mutation DeletePatientChronicMedication($deletePatientChronicMedicationId: ID!, $patientId: ID!, $userId: ID!) {
  deletePatientChronicMedication(
    id: $deletePatientChronicMedicationId
    patientId: $patientId
    userId: $userId
  ) {
    id
    patientCurrentMedicalStatus {
      ...patientCurrentMedicalStatusFragment
    }
  }
}
    ${PatientCurrentMedicalStatusFragmentFragmentDoc}`;
export type DeletePatientChronicMedicationMutationFn = Apollo.MutationFunction<DeletePatientChronicMedicationMutation, DeletePatientChronicMedicationMutationVariables>;

/**
 * __useDeletePatientChronicMedicationMutation__
 *
 * To run a mutation, you first call `useDeletePatientChronicMedicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientChronicMedicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientChronicMedicationMutation, { data, loading, error }] = useDeletePatientChronicMedicationMutation({
 *   variables: {
 *      deletePatientChronicMedicationId: // value for 'deletePatientChronicMedicationId'
 *      patientId: // value for 'patientId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeletePatientChronicMedicationMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientChronicMedicationMutation, DeletePatientChronicMedicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientChronicMedicationMutation, DeletePatientChronicMedicationMutationVariables>(DeletePatientChronicMedicationDocument, options);
      }
export type DeletePatientChronicMedicationMutationHookResult = ReturnType<typeof useDeletePatientChronicMedicationMutation>;
export type DeletePatientChronicMedicationMutationResult = Apollo.MutationResult<DeletePatientChronicMedicationMutation>;
export type DeletePatientChronicMedicationMutationOptions = Apollo.BaseMutationOptions<DeletePatientChronicMedicationMutation, DeletePatientChronicMedicationMutationVariables>;