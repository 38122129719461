import { useTranslation } from "@toolkit/i18n";
import { last } from "lodash";
import { FC } from "react";
import BasicLineChart from "../Charts/BasicLineChart";
import { OverviewCard } from "./OverviewCard";
import { IVitalSignChart } from "./types";
import { usePatientHealthParametersQuery } from "../../gql";
import { createPatientHealthParameterItem } from "./utils";
import { getApolloContextFormToken } from "../../../../utils";
import { useVisitsVitalSignsChanged } from "@health/sse";
import { Loader } from "@toolkit/ui";
import { H_OrderDirection, PatientHealthParameterSortField } from "@health/queries/types";
import { healthParameterCodes } from "pages/PatientHealthParameters/constants";

type VitalSignRespirationRateChartProps = IVitalSignChart;

export const VitalSignRespirationRateChart: FC<VitalSignRespirationRateChartProps> = props => {
  const { token, width, percentage, patientId } = props;

  const { t } = useTranslation("provider");
  const { data, loading, refetch } = usePatientHealthParametersQuery({
    variables: {
      first: 10,
      filter: {
        patientId: [patientId],
        codes: [healthParameterCodes.respiratoryRate],
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const patientHealthParameters = data?.patientHealthParameters?.edges?.map(item => item?.node) || [];

  const healthParameters = createPatientHealthParameterItem(patientHealthParameters, "°bpm");

  const lastItem = last(healthParameters);
  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.hpCode === healthParameterCodes.respiratoryRate) {
      refetch({
        first: 10,
        filter: {
          patientId: [patientId],
          codes: [healthParameterCodes.respiratoryRate],
        },
      });
    }
  });

  return (
    <OverviewCard
      width={width}
      title={t("Respiration Rate")}
      value={`${Math.floor(lastItem?.value! || 0)}`}
      percentage={percentage}
      unit={"bpm"}
      date={lastItem?.date!}
      description={t("By") + " " + (lastItem?.source! || "-")}
    >
      {loading ? <Loader /> : <BasicLineChart title={t("Respiration Rate")} data={healthParameters} />}
    </OverviewCard>
  );
};
