import { useTranslation } from "@toolkit/i18n";
import { FormCard, WorkingHoursCard } from "@toolkit/ui";
import { FC } from "react";
import { useWorkingHoursHook } from "./useWorkingHours.hook";

export type WorkingHoursProps = {
  workingHoursDefaultValue?;
  inputName: string;
  isLoading?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  doYouHaveData: boolean;
  errorText?: JSX.Element;
};

export const WorkingHours: FC<WorkingHoursProps> = props => {
  const { isLoading, doYouHaveData, workingHoursDefaultValue, inputName, errorText } = props;
  const { t } = useTranslation("provider");
  const { workingHours, onSetWorkingHours, onError } = useWorkingHoursHook({
    workingHoursDefaultValue: workingHoursDefaultValue,
    inputName,
  });
  return (
    <FormCard title={t("Working Hours")} loading={isLoading} doYouHaveData={doYouHaveData}>
      <WorkingHoursCard
        workingHours={workingHours}
        onChange={onSetWorkingHours}
        withTitle={false}
        onError={onError}
        errorText={errorText}
      />
    </FormCard>
  );
};
