import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";
import { useSetOrderToDeliveredMutation } from "../gql";

export const DeliveredButton: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation("provider");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [orderVendorFinishProcessing, { loading }] = useSetOrderToDeliveredMutation({
    onCompleted: () => {
      succeeded(t("Order delivered successfully"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleOrderVendorFinishProcessing = (): void => {
    orderVendorFinishProcessing({
      variables: { orderId: id },
    });
  };

  return (
    <CircularIntegration loading={loading}>
      <Button disabled={loading} className={classes.accept} onClick={handleOrderVendorFinishProcessing}>
        {t("Delivered")}
      </Button>
    </CircularIntegration>
  );
};
