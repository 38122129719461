import { useReactiveVar } from "@apollo/client";
import { TFunction, useTranslation } from "@toolkit/i18n";
import { useEffect } from "react";
import { NurseCurrentWorkSpaceDetailsVar } from "shared/components/NurseCurrentWorkDetails/NurseCurrentWorkSpaceDetailsVar";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isNurseUser } from "utils";

export const useNurseCurrentWorkspaceSelection = (onOpenSelectionDialog: () => void) => {
  const { userInfo } = useOidcUserProfile();
  const NurseWorkDetails = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const nurseAlreadyAssignedToDoctor = !!NurseWorkDetails?.doctors?.length;
  const { t } = useTranslation("provider");

  useEffect(() => {
    !nurseAlreadyAssignedToDoctor && onOpenSelectionDialog();
  }, [NurseWorkDetails]);

  const assignedDoctorsLength = NurseWorkDetails?.doctors?.length;
  const message = assignedDoctorsLength ? getAssignedMessage(assignedDoctorsLength, t) : t("Select Doctors");

  if (!isNurseUser(userInfo)) return { message: "" };
  return {
    message,
  };
};
const getAssignedMessage = (assignedDoctorsLength: number, t: TFunction) => {
  switch (assignedDoctorsLength) {
    case 1:
      return t("Assigned to 1 doctor", { ns: "provider" });
    case 2:
      return t("Assigned to 2 doctors", { ns: "provider" });
    default:
      return t("assigned-to-n-doctors", { doctorsCount: assignedDoctorsLength, ns: "provider" });
  }
};
