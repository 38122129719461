import { i18n } from "@toolkit/i18n";
import { providerTranslationNamespace } from "utils";
import { productsPaths } from "./ProductsPaths";

export const productsBreadcrumb = () => ({
  title: i18n.t("Marketplace Products", { ns: "provider" }),
  main: {
    id: "products",
    name: i18n.t("Marketplace Products", providerTranslationNamespace),
    path: productsPaths.main.fullPath,
  },
  create: {
    id: "product-new",
    name: i18n.t("New", providerTranslationNamespace),
  },
  update: (fieldName: string) => ({
    id: "product-update",
    name: fieldName || i18n.t("Update", providerTranslationNamespace),
  }),
});
