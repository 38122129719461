/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteVisitDiagnosisMutationVariables = Types.Exact<{
  deleteVisitDiagnosisId: Types.Scalars['ID'];
}>;


export type DeleteVisitDiagnosisMutation = { __typename?: 'Mutation', deleteVisitDiagnosis?: { __typename?: 'VisitDiagnosisCRUD', visitDiagnosis?: { __typename?: 'VisitDiagnosis', visitDiagnosisType: Types.VisitDiagnosisType, id: string, code: string } | null, visitDiagnosisErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const DeleteVisitDiagnosisDocument = gql`
    mutation DeleteVisitDiagnosis($deleteVisitDiagnosisId: ID!) {
  deleteVisitDiagnosis(id: $deleteVisitDiagnosisId) {
    visitDiagnosis {
      visitDiagnosisType
      id
      code
    }
    visitDiagnosisErrors {
      message
      field
      code
    }
  }
}
    `;
export type DeleteVisitDiagnosisMutationFn = Apollo.MutationFunction<DeleteVisitDiagnosisMutation, DeleteVisitDiagnosisMutationVariables>;

/**
 * __useDeleteVisitDiagnosisMutation__
 *
 * To run a mutation, you first call `useDeleteVisitDiagnosisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisitDiagnosisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisitDiagnosisMutation, { data, loading, error }] = useDeleteVisitDiagnosisMutation({
 *   variables: {
 *      deleteVisitDiagnosisId: // value for 'deleteVisitDiagnosisId'
 *   },
 * });
 */
export function useDeleteVisitDiagnosisMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVisitDiagnosisMutation, DeleteVisitDiagnosisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVisitDiagnosisMutation, DeleteVisitDiagnosisMutationVariables>(DeleteVisitDiagnosisDocument, options);
      }
export type DeleteVisitDiagnosisMutationHookResult = ReturnType<typeof useDeleteVisitDiagnosisMutation>;
export type DeleteVisitDiagnosisMutationResult = Apollo.MutationResult<DeleteVisitDiagnosisMutation>;
export type DeleteVisitDiagnosisMutationOptions = Apollo.BaseMutationOptions<DeleteVisitDiagnosisMutation, DeleteVisitDiagnosisMutationVariables>;