import { ORDERS_STATUS } from "../statuses";

export const buttons = [
  {
    id: ORDERS_STATUS.NEW,
    icon: "new",
  },
  {
    id: ORDERS_STATUS.PENDING,
    icon: "pending",
  },
  {
    id: ORDERS_STATUS.DISPENSING,
    icon: "dispensing",
  },
  {
    id: ORDERS_STATUS.DELIVERING,
    icon: "delivering",
  },
];
