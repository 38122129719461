import { DoctorError, User } from "@health/queries/types";
import { combineErrors, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const AboutUserInformation: FC<{ defaultValues: User | undefined; errors?: DoctorError[] }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("provider");
  const { register, formState } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          multiline
          rows={6}
          label={t("About")}
          placeholder={t("About")}
          {...register("bio")}
          error={Boolean(combinedErrors?.bio?.message)}
          helperText={t(combinedErrors?.bio?.message)}
          defaultValue={defaultValues?.doctor?.bio}
        />
      </Grid>
    </Grid>
  );
};
