import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { ReferralsPage as ReferralsPage } from "./ReferralsPage";

export const Referrals: FC<{ visitId?: string }> = ({ visitId: visitIdFromProps }) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const visitId = params.get("visitId") || visitIdFromProps;
  return <ReferralsPage visitId={visitId} token={token!} />;
};

Referrals.defaultProps = {
  visitId: undefined,
};
