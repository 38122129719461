import { useEffect } from "react";
import { useNotificationService } from "./useNotificationService";
export const useSubscribeToNotificationTopic = (topic, listener) => {
    const { subscribe, unSubscribe } = useNotificationService();
    useEffect(() => {
        unSubscribe();
        subscribe(topic, listener);
        return () => {
            unSubscribe();
        };
    }, [listener, topic]);
};
