import { VendorDepartmentCreateInput, VendorDepartmentError } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { departmentListBreadCrumb, departmentNewBreadCrumb } from "../constants";
import { CreateDepartmentMutation, useCreateDepartmentMutation } from "../gql";

export const useDepartmentNewPageHook = () => {
  const [errors, setErrors] = React.useState<VendorDepartmentError[]>([]);
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [createDepartmentMutation, { loading: isCreateLoading }] = useCreateDepartmentMutation({
    onCompleted: (mutationData: CreateDepartmentMutation) => {
      if (mutationData?.vendorDepartmentCreate?.vendorDepartmentErrors?.length! > 0) {
        setErrors(mutationData?.vendorDepartmentCreate?.vendorDepartmentErrors as VendorDepartmentError[]);
        failed(t(formatMessageErrors(mutationData?.vendorDepartmentCreate?.vendorDepartmentErrors)));
      } else {
        succeeded(t("Department created successfully!"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed to create new department"));
    },
  });
  const handleCreateDepartment = (inputData: VendorDepartmentCreateInput | any) => {
    createDepartmentMutation({
      variables: {
        input: {
          ...inputData,
          branch: inputData?.branch?.id || null,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: "Departments",
      values: [departmentListBreadCrumb(t), departmentNewBreadCrumb(t)],
    });
  }, []);

  return {
    errors,
    isCreateLoading,
    handleCreateDepartment,
  };
};
