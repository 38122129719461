import { TFunction } from "@toolkit/i18n";
import { GearsIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { NotificationsScreen } from "./";

const notificationRoutes = "/notifications-center";

export const notificationsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${notificationRoutes}${this.route}`;
    },
  },
};

export const notificationsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "notification",
    text: t("Notifications center", { ns: "provider" }),
    icon: <GearsIcon />,
    route: notificationRoutes,
    fullPath: notificationsPaths.indexPage.fullPath,
    element: <NotificationsScreen />,
    hidden: true,
    onClick: (route: string) => navigate(route),
  };
};
