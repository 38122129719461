import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { promotionsBreadcrumb } from "../../constants";
import { promotionsPaths } from "../../constants";
import { IPromotionUpsertFormValues, PromotionUpsertForm } from "../../forms";
import { MarketplacePromotionUpdateMutation, useMarketplacePromotionUpdateMutation } from "../../gql";
import { useMarketplacePromotionQuery } from "../../gql";
import { IPromotionUpdateContainerParams } from "../../types";
import { convertPromotionFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const PromotionUpdateContainer: React.FC = () => {
  const { t } = useTranslation("admin");
  const { promotionId } = useParams<IPromotionUpdateContainerParams>();

  const { data, loading } = useMarketplacePromotionQuery({
    variables: { marketplacePromotionId: promotionId! },
    skip: !promotionId,
  });
  const promotion = data?.marketplacePromotion;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchPromotionUpdateMutation, { loading: isSubmitting }] = useMarketplacePromotionUpdateMutation({
    onCompleted: (data: MarketplacePromotionUpdateMutation) => {
      if (data?.marketplacePromotionUpdate?.id) {
        succeeded(t("Promotion updated successfully!"));
        navigate(promotionsPaths.main.fullPath);
      } else {
        failed(t("Promotion update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onPromotionUpsertFormChange = (values: IPromotionUpsertFormValues) => {
    const _values = convertPromotionFormValuesToBackEndValues(values);

    fetchPromotionUpdateMutation({
      variables: {
        marketplacePromotionUpdateId: promotionId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, update } = promotionsBreadcrumb();
    setBreadCrumb({ title, values: [main, update(pickLocalizedValue(promotion?.name!, promotion?.name!))] });
  }, [setBreadCrumb, promotion, t]);
  return (
    <PromotionUpsertForm
      isLoading={loading}
      promotion={promotion!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onPromotionUpsertFormChange}
    />
  );
};
