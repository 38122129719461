import { makeStyles } from "@toolkit/ui";

export const useGuidedCareProgramMemberAddFormStyle = makeStyles()(() => ({
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBlock: 8,
    width: "100%",
  },
  footer: {
    marginTop: 16,
  },
}));
