import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";

export type PatientSendNotificationData = { url: string; message: string };

export const useSendNotificationMessage = () => {
  const { t } = useTranslation();
  const { succeeded } = useAddToast();
  const handleSendNotificationMessage = async (values: PatientSendNotificationData) => {
    const valid = values.url && values.message;
    if (valid) {
      succeeded(t("Notification Message Created."));
    }

    return valid;
  };

  return {
    handleSendNotificationMessage,
  };
};
