import { Grid, InfiniteScroll } from "@toolkit/ui";
import { FC } from "react";
import { OrdersEmpty } from "../cards/OrdersCard";
import { useOrderPageContext } from "../state";
import { mapStatusToColor } from "../statuses";
import { Loading } from "./Loading";
import { Order } from "./Order.component";
import { OrdersLoading } from "./OrdersLoading.component";

type IOrdersBody = {
  status: string;
};

export const OrdersBody: FC<IOrdersBody> = ({ status }) => {
  const { data } = useOrderPageContext();

  const column = data?.[status];
  const color = mapStatusToColor[status];
  const pageOffsetHeight = document.body.offsetHeight - 230;
  const { data: orders, hasNextPage: hasNext, fetchMore: next, loading } = column;

  return (
    <OrdersLoading isLoading={loading} color={color}>
      <OrdersEmpty length={orders?.length} color={color}>
        <InfiniteScroll
          dataLength={orders?.length}
          hasMore={Boolean(hasNext)}
          next={next}
          height={pageOffsetHeight}
          loader={<Loading color={color} />}
        >
          <Grid container spacing={1}>
            {orders?.map(order => (
              <Grid item xs={12} key={String(order.id) + "-container"}>
                <Order order={order} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </OrdersEmpty>
    </OrdersLoading>
  );
};
