import { makeStyles } from "@toolkit/ui";

export const useDispenseModelStyles = makeStyles()(theme => ({
  paper: {
    width: 536,
    height: "fit-content",
    borderRadius: 15,
  },
  box: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 0,
  },
  id: {
    fontSize: 28,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 0,
  },
  print: {
    width: 76,
  },
  ok: {
    width: 53,
    color: theme.palette.primary.main,
    border: "1px solid",
    background: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  action: {
    height: 70,
    padding: 10,
    display: "flex",
    alignItems: "center",
    boxShadow: theme.mixins.shadows.md,
  },
  width: {
    width: "100%",
  },
  line: {
    marginTop: 10,
    marginBottom: 10,
    background: theme.palette.gray.main,
    opacity: 0.2,
  },
  container: {
    position: "relative",
  },
  logo: {
    padding: 5,
    background: theme.palette.common.white,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& svg": {
      height: 100,
      width: 145,
    },
  },
  button: {
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));
