import { Box, useTheme } from "@toolkit/ui";
import React from "react";
import { VisitStartingNowNotification } from "../VisitStartingNowNotification";

export const ImmediateNotifications = () => {
  const theme = useTheme();
  return (
    <Box position='fixed' top='0' width='100vw' zIndex={1500} bgcolor={theme.palette.common.white}>
      <VisitStartingNowNotification />
    </Box>
  );
};
