const NoteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.495 22.495' width={22.495} height={22.495} {...props}>
      <path
        d='M3.067 0A3.083 3.083 0 0 0 0 3.068v16.36A3.083 3.083 0 0 0 3.067 22.5h12.782a1.022 1.022 0 0 0 .723-.3l5.628-5.628a1.023 1.023 0 0 0 .3-.723V3.068A3.083 3.083 0 0 0 19.427 0Zm0 2.045h16.36a1.006 1.006 0 0 1 1.023 1.023v12.27h-3.068a2.044 2.044 0 0 0-2.045 2.045v3.067H3.067a1.006 1.006 0 0 1-1.022-1.023V3.068a1.006 1.006 0 0 1 1.022-1.023Zm3.067 5.113a1.023 1.023 0 1 0 0 2.045h4.09a1.023 1.023 0 1 0 0-2.045Zm8.18 0a1.023 1.023 0 1 0 0 2.045h2.046a1.023 1.023 0 1 0 0-2.045Zm-8.18 4.09a1.023 1.023 0 1 0 0 2.045h8.18a1.023 1.023 0 1 0 0-2.045Z'
        fill='#fff'
      />
    </svg>
  );
};

export default NoteIcon;
