import { MarketplaceHealthPackage } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { healthPackageEditBreadCrumb, healthPackageListBreadCrumb } from "pages/HealthPackages/constant";
import { useHealthPackageQuery, useHealthPackageUpdateMutation } from "pages/HealthPackages/gql";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ellipseName } from "utils";
import { formData } from "../type";

export const usePackageEditPageHook = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const { succeeded, failed } = useAddToast();

  const [healthPackageUpdate, { loading: isFetchingUpdate }] = useHealthPackageUpdateMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      succeeded(t("Health package updated successfully"));
      navigate(-1);
    },
    onError: () => {
      failed(t("Failed in updating health package"));
    },
  });

  const { data, loading: isLoading } = useHealthPackageQuery({
    variables: {
      healthPackageId: String(params?.id),
    },
  });

  const handleUpdatePackage = (data: formData) => {
    healthPackageUpdate({
      variables: {
        healthPackageUpdateId: params.id as string,
        input: {
          description: data.description,
          descriptionAr: data.descriptionAr,
          shortDescription: data.shortDescription,
          shortDescriptionAr: data.shortDescriptionAr,
          mainImageUrl: encodeURI(data.mainImageUrl!),
          categoryId: data?.categoryId?.value?.id,
          name: data.name,
          nameAr: data.nameAr,
          price: +(data.price ?? 0),
          targetGender: data?.targetGender?.value,
          targetFromAge: Number(data?.targetFromAge),
          targetToAge: Number(data?.targetToAge),
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: t("Health Packages Management"),
      values: [healthPackageListBreadCrumb(t), healthPackageEditBreadCrumb(ellipseName(data?.marketplaceHealthPackage?.name!, 20))],
    });
  }, [data?.marketplaceHealthPackage?.name]);

  return {
    healthPackage: data?.marketplaceHealthPackage as MarketplaceHealthPackage,
    isLoading,
    isFetchingUpdate,
    handleUpdatePackage,
  };
};
