/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitsCountQueryVariables = Types.Exact<{
  onlyMeSwitchValue: Types.Scalars['Boolean'];
}>;


export type VisitsCountQuery = { __typename?: 'Query', newRequested?: { __typename?: 'VisitCountableConnection', totalCount?: number | null } | null, activeCall?: { __typename?: 'VisitCountableConnection', totalCount?: number | null } | null, incomplete?: { __typename?: 'VisitCountableConnection', totalCount?: number | null } | null };


export const VisitsCountDocument = gql`
    query visitsCount($onlyMeSwitchValue: Boolean!) {
  newRequested: visits(filter: {status: [ASSIGNED], selfOnly: true}) {
    totalCount
  }
  activeCall: visits(
    filter: {status: [CALL_IN_PROGRESS, CALL_REJOINABLE], selfOnly: true}
  ) {
    totalCount
  }
  incomplete: visits(
    filter: {status: [INCOMPLETED], selfOnly: $onlyMeSwitchValue}
  ) {
    totalCount
  }
}
    `;

/**
 * __useVisitsCountQuery__
 *
 * To run a query within a React component, call `useVisitsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsCountQuery({
 *   variables: {
 *      onlyMeSwitchValue: // value for 'onlyMeSwitchValue'
 *   },
 * });
 */
export function useVisitsCountQuery(baseOptions: Apollo.QueryHookOptions<VisitsCountQuery, VisitsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitsCountQuery, VisitsCountQueryVariables>(VisitsCountDocument, options);
      }
export function useVisitsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitsCountQuery, VisitsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitsCountQuery, VisitsCountQueryVariables>(VisitsCountDocument, options);
        }
export type VisitsCountQueryHookResult = ReturnType<typeof useVisitsCountQuery>;
export type VisitsCountLazyQueryHookResult = ReturnType<typeof useVisitsCountLazyQuery>;
export type VisitsCountQueryResult = Apollo.QueryResult<VisitsCountQuery, VisitsCountQueryVariables>;