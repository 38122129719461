import { GuidedCareJourneyItem } from "@health/queries/types";
import { AnnualTimeLine, Loader } from "@toolkit/ui";
import { FC } from "react";
import { JourneyDurationPeriod } from "shared/modules/patient/types";
import { AnnualTimeLineItem } from "./AnnualTimeLineItem";
import { useAnnualTimeLineHooks } from "./useAnnualTimeLineHooks";

type AnnualTimeLineViewProps = {
  source?: string;
  duration: JourneyDurationPeriod;
  hasSimilar: boolean;
  selectedTeamMemberPosition: string;
  selectedMe: string;
  selectedItem: GuidedCareJourneyItem;
  selectedActivities: string;
  onSelectItem: (appointment: GuidedCareJourneyItem) => void;
};

export const AnnualTimeLineView: FC<AnnualTimeLineViewProps> = props => {
  const { source, duration, hasSimilar, selectedMe, selectedTeamMemberPosition, selectedItem, selectedActivities, onSelectItem } = props;

  const { year, loading, filterInput, timeline, handleIncrementYear, handleDecrementYear } = useAnnualTimeLineHooks({
    source,
    duration,
    selectedMe,
    selectedActivities,
    selectedTeamMemberPosition,
    onSelectItem,
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AnnualTimeLine timeline={timeline} year={year!} onDecrement={handleDecrementYear} onIncrement={handleIncrementYear}>
          <AnnualTimeLineItem
            year={year}
            filterInput={filterInput}
            selectedItem={selectedItem}
            onSelectItem={onSelectItem}
            hasSimilar={hasSimilar}
          />
        </AnnualTimeLine>
      )}
    </>
  );
};
