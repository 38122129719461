import { AppointmentTypeEnum } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CardContent, Typography, useTheme, VideoConference2Icon } from "@toolkit/ui";
import { FC } from "react";
import { InformationDoctor } from "./InformationDoctor.component";

export const VisitsType: FC<{ visits: AppointmentTypeEnum[] | any; appointmentSlotTimePeriod: number }> = ({
  visits,
  appointmentSlotTimePeriod,
}) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <InformationDoctor title={t("Type of Accepted Visits")} headerIcon={<VideoConference2Icon fill={theme.palette.primary.main} />}>
          <>
            <Typography fontSize={theme.mixins.fonts.fontSize.md}>
              {t("Visit Slot Duration (Min)")} : {appointmentSlotTimePeriod}
            </Typography>
            {visits?.map((visit, i) => (
              <Box key={i}>
                <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
                  {Object.keys(AppointmentTypeEnum)?.find(item => AppointmentTypeEnum[item] === visit)}
                </Typography>
              </Box>
            ))}
          </>
        </InformationDoctor>
      </CardContent>
    </Card>
  );
};
