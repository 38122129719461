import { pickLocalizedValue } from "@toolkit/i18n";
import { CollapseIcon, CustomDialog, IconButton } from "@toolkit/ui";
import { FC } from "react";
import { useOrdersCardStyles } from "./OrdersCard.styles";
import { OrdersDialogProps } from "./OrdersCard.types";

export const OrdersDialog: FC<OrdersDialogProps> = props => {
  const { children, open, onToggle: handleToggle, color } = props;

  const { classes } = useOrdersCardStyles({ color });

  return (
    <CustomDialog
      type='base'
      fullWidth
      maxWidth='lg'
      open={!!open}
      onClose={handleToggle}
      classes={{
        paper: `${classes.paper} ${classes.border}`,
      }}
      DialogTitleProps={{
        sx: {
          display: "none!important",
        },
      }}
      DialogContentProps={{
        sx: {
          padding: "0px!important",
        },
      }}
    >
      <IconButton onClick={handleToggle} className={pickLocalizedValue(classes.icon, `${classes.icon} ${classes.iconRtl}`)}>
        <CollapseIcon />
      </IconButton>

      <div>{children}</div>
    </CustomDialog>
  );
};
