import { EChart, EChartOption, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { getPatientHealthParameterChartTooltip } from "../../others";
import { IHealthParameterChartDataSet } from "../../types";

type PatientHealthParameterChartBarProps = {
  dataSets: IHealthParameterChartDataSet[];
  unit?: string;
  isLoading?: boolean;
};

export const PatientHealthParameterChartBar: FC<PatientHealthParameterChartBarProps> = props => {
  const { dataSets, unit, isLoading } = props;

  const theme = useTheme();

  const defaultColors = [theme.palette.primary.main, theme.palette.secondary.main];

  const chartOption: EChartOption = {
    tooltip: {
      trigger: dataSets.length === 1 ? "item" : "axis",
      formatter: params => getPatientHealthParameterChartTooltip(params, dataSets?.length, unit),
      extraCssText: "border: 1px solid #ccc",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: theme.palette.gray[300],
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: theme.palette.gray.main,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.palette.gray[300],
        },
      },
    },
    series: dataSets?.map((dataSet, index) => ({
      name: `Bar ${index + 1}`,
      type: "bar",
      barWidth: "30%",
      data: dataSet?.data?.map((item, dataIndex) => ({
        ...item,
        itemStyle: {
          color: dataIndex === 0 ? theme.palette.secondary.main : dataSet.color || defaultColors[index] || theme.palette.gray.main,
        },
      })),
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
        color: dataSet.color || defaultColors[index] || theme.palette.gray.main,
      },
    })),
  };

  return <EChart option={chartOption} isLoading={isLoading} />;
};
