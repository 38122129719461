import { Maybe, QuestionAnswerExtended } from "@health/queries/types";
import { Button, TruncateTypography } from "@toolkit/ui";
import { FC, useState } from "react";
import { HealthInsuranceSatisfaction } from "./HealthInsuranceSatisfaction";

type MedicalFormDialogProps = {
  classificationName: string;
  numberOfQuestions: number;
  questionAnswers: Maybe<QuestionAnswerExtended>[];
  date: string;
  label: string;
};
export const MedicalFormDialog: FC<MedicalFormDialogProps> = ({ label, date, classificationName, numberOfQuestions, questionAnswers }) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button variant='text' onClick={handleToggle}>
        <TruncateTypography text={label!} />
      </Button>
      {open && (
        <HealthInsuranceSatisfaction
          isOpen={open}
          date={date}
          label={label!}
          questionAnswers={questionAnswers!}
          onToggle={handleToggle}
          classificationName={classificationName!}
          numberOfQuestions={numberOfQuestions}
        />
      )}
    </>
  );
};
