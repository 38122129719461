import { Grid } from "@toolkit/ui";
import { FC } from "react";
import { useOrderPageContext } from "../state";
import { ORDERS_STATUS, statuses } from "../statuses";
import { OrderColumn } from "./OrderColumn";

export const OrdersQueueScreen: FC = () => {
  const { layoutGridWidth, isDeliveringColumnVisible } = useOrderPageContext();

  return (
    <Grid container spacing={1}>
      {Object.keys(statuses)
        .filter(item => isDeliveringColumnVisible || item !== ORDERS_STATUS.DELIVERING)
        .map(status => {
          return (
            <Grid key={status} item xs={12} md={6} lg={layoutGridWidth}>
              <OrderColumn status={status} />
            </Grid>
          );
        })}
    </Grid>
  );
};
