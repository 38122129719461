/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderTimeoutsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrderTimeoutsQuery = { __typename?: 'Query', siteSettings: { __typename?: 'SiteSettings', orderAcceptanceTimeout?: number | null, orderErxHubApprovalTimeout?: number | null, orderErxHubApprovalResubmitTimeout?: number | null, orderDispensingTimeout?: number | null, orderDeliveryTimeout?: number | null, vatPercentage?: number | null } };


export const OrderTimeoutsDocument = gql`
    query orderTimeouts {
  siteSettings {
    orderAcceptanceTimeout
    orderErxHubApprovalTimeout
    orderErxHubApprovalResubmitTimeout
    orderDispensingTimeout
    orderDeliveryTimeout
    vatPercentage
  }
}
    `;

/**
 * __useOrderTimeoutsQuery__
 *
 * To run a query within a React component, call `useOrderTimeoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTimeoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTimeoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderTimeoutsQuery(baseOptions?: Apollo.QueryHookOptions<OrderTimeoutsQuery, OrderTimeoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTimeoutsQuery, OrderTimeoutsQueryVariables>(OrderTimeoutsDocument, options);
      }
export function useOrderTimeoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTimeoutsQuery, OrderTimeoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTimeoutsQuery, OrderTimeoutsQueryVariables>(OrderTimeoutsDocument, options);
        }
export type OrderTimeoutsQueryHookResult = ReturnType<typeof useOrderTimeoutsQuery>;
export type OrderTimeoutsLazyQueryHookResult = ReturnType<typeof useOrderTimeoutsLazyQuery>;
export type OrderTimeoutsQueryResult = Apollo.QueryResult<OrderTimeoutsQuery, OrderTimeoutsQueryVariables>;