import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { ProfileFormEditPage, ProfileScreen } from "./index";

const profileRoute = "/doctor/profile";

export const profilePaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${profileRoute}${this.route}`;
    },
  },
  edit: {
    route: "edit/:id",
    get fullPath() {
      return `${profileRoute}/${this.route}`;
    },
  },
};

export const profileRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "doctor-profile",
    text: t("Profile", { ns: "provider" }),
    route: profileRoute,
    hidden: true,
    subItems: [
      {
        id: profilePaths.indexPage.fullPath,
        text: t("Profile", { ns: "provider" }),
        route: profilePaths.indexPage.route,
        fullPath: profilePaths.indexPage.fullPath,
        element: <ProfileScreen />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
      {
        id: profilePaths.edit.fullPath,
        text: t("Edit Profile", { ns: "provider" }),
        route: profilePaths.edit.route,
        fullPath: profilePaths.edit.fullPath,
        element: <ProfileFormEditPage />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
    ],
  };
};
