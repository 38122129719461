import { makeStyles } from "@toolkit/ui";

export const useDoctorsCallsRatingsStyles = makeStyles()(theme => ({
  container: {
    height: "83vh",
    overflow: "auto",
    padding: theme.spacing(2),
  },
  item: {
    display: "inline-block",
    padding: theme.spacing(2),
    width: "25%",
    [theme.breakpoints.down("lg")]: {
      width: "33%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
