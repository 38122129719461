import { AccountError, User } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { Branches } from "./components/Branches.component";
import { DoctorDetailsComponent } from "./components/DoctorDetails.component";
import { SearchByNatIdForm } from "./components/SearchByNatIdForm";
import { UserGeneralInformation } from "./components/UserGeneralInformation.component";
import { useUserManagementFormHook } from "./components/UserManagementForm.hook";
import { HealthLicenseComponent } from "./components/HealthLicense.component";

export const UserManagementForm: FC<{
  errors: AccountError[];
  onDone: (data) => void;
  isLoading: boolean;
  defaultValues?: User;
  isSubmitting?: boolean;
  isEdit?: boolean;
}> = ({ errors, onDone, isLoading, defaultValues, isEdit }) => {
  const { t } = useTranslation("provider");
  const doYouHaveData = isEdit ? defaultValues != undefined : true;
  const { handleCancel, methods, onSubmit, handleSearchByNatId, isUserByNatIdLoading } = useUserManagementFormHook({ onDone });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box display='inline-block'>
              <FormActions
                isLoading={isLoading}
                hasCancel
                hasFormButton
                formButtonTitle={isEdit ? t("Update") : t("Create")}
                onNavigation={handleCancel}
              />
            </Box>
          }
        >
          <Grid container spacing={formGirdSpacing}>
            {!isEdit && (
              <Grid item xs={12}>
                <FormCard title={t("National id search")} loading={isLoading} doYouHaveData={doYouHaveData}>
                  <SearchByNatIdForm onSearchByNatId={handleSearchByNatId} />
                </FormCard>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormCard doYouHaveData={doYouHaveData as boolean} loading={isLoading || isUserByNatIdLoading} title={t("User Information")}>
                <UserGeneralInformation errors={errors} defaultValues={defaultValues} isEdit={isEdit} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Branches")} loading={isLoading || isUserByNatIdLoading} doYouHaveData={doYouHaveData}>
                <Branches defaultValues={defaultValues} errors={errors} />
              </FormCard>
            </Grid>
            <DoctorDetailsComponent
              errors={errors}
              doYouHaveUser={doYouHaveData}
              isLoading={isLoading || isUserByNatIdLoading}
              defaultValues={defaultValues}
              isEdit={isEdit as boolean}
            />
            <HealthLicenseComponent
              errors={errors}
              defaultValues={defaultValues}
              isLoading={isLoading || isUserByNatIdLoading}
              doYouHaveUser={doYouHaveData}
              isEdit={isEdit as boolean}
            />
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
UserManagementForm.defaultProps = {
  errors: undefined,
  defaultValues: undefined,
};
