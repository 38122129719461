/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceOrderConsumedUpdateMutationVariables = Types.Exact<{
  marketplaceOrderConsumedUpdateId: Types.Scalars['ID'];
}>;


export type MarketplaceOrderConsumedUpdateMutation = { __typename?: 'Mutation', marketplaceOrderConsumedUpdate?: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus } | null };


export const MarketplaceOrderConsumedUpdateDocument = gql`
    mutation MarketplaceOrderConsumedUpdate($marketplaceOrderConsumedUpdateId: ID!) {
  marketplaceOrderConsumedUpdate(id: $marketplaceOrderConsumedUpdateId) {
    id
    status
  }
}
    `;
export type MarketplaceOrderConsumedUpdateMutationFn = Apollo.MutationFunction<MarketplaceOrderConsumedUpdateMutation, MarketplaceOrderConsumedUpdateMutationVariables>;

/**
 * __useMarketplaceOrderConsumedUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceOrderConsumedUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceOrderConsumedUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceOrderConsumedUpdateMutation, { data, loading, error }] = useMarketplaceOrderConsumedUpdateMutation({
 *   variables: {
 *      marketplaceOrderConsumedUpdateId: // value for 'marketplaceOrderConsumedUpdateId'
 *   },
 * });
 */
export function useMarketplaceOrderConsumedUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceOrderConsumedUpdateMutation, MarketplaceOrderConsumedUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceOrderConsumedUpdateMutation, MarketplaceOrderConsumedUpdateMutationVariables>(MarketplaceOrderConsumedUpdateDocument, options);
      }
export type MarketplaceOrderConsumedUpdateMutationHookResult = ReturnType<typeof useMarketplaceOrderConsumedUpdateMutation>;
export type MarketplaceOrderConsumedUpdateMutationResult = Apollo.MutationResult<MarketplaceOrderConsumedUpdateMutation>;
export type MarketplaceOrderConsumedUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceOrderConsumedUpdateMutation, MarketplaceOrderConsumedUpdateMutationVariables>;