export const displayStringOfArray = (array?: string[]) => {
  if (!array || array?.length === 0) {
    return "";
  } else if (array?.length === 1) {
    return array[0];
  } else if (array?.length === 2) {
    return array?.join(" and ");
  } else {
    const lastElement = array?.pop();
    const formattedArray = array?.join(", ");
    return `${formattedArray} and ${lastElement}`;
  }
};

export const determineRatingColor = (rating: number) => {
  if (rating >= 5) return "success";
  if (rating >= 3) return "warning";
  return "error";
};
