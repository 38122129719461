import { TemplateIntervention } from "@health/queries/types";
import { Box, Card, CardContent, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useGuidedCareJourneyInterventionCardStyle } from "./useGuidedCareJourneyInterventionCardStyle";

export type IGuidedCareJourneyInterventionCardEvent = {
  type: "CLICK";
  payload: {
    intervention: TemplateIntervention;
  };
};

type GuidedCareJourneyInterventionCardProps = {
  index: number;
  intervention: TemplateIntervention;
  onChange: (event: IGuidedCareJourneyInterventionCardEvent) => void;
};

export const GuidedCareJourneyInterventionCard: FC<GuidedCareJourneyInterventionCardProps> = props => {
  const { index, intervention, onChange } = props;

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareJourneyInterventionCardStyle();

  const onCardClick = () => {
    onChange({
      type: "CLICK",
      payload: { intervention },
    });
  };

  return (
    <Card className={classes.card} onClick={onCardClick}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.wrapper}>
          <Box className={classes.circleWrapper}>
            <Box className={classes.circle}>{t("I{{value}}", { value: index + 1 })}</Box>
            <Typography className={classes.name}>{pickLocalizedValue(intervention?.name, intervention?.nameAr)}</Typography>
          </Box>

          <Box className={classes.infoWrapper}>
            <Typography display={"flex"}>
              {t("Time")}:
              <Typography className={classes.infoValue}>
                {intervention?.duration ? t("{{duration}} Days", { duration: intervention.duration }) : "-"}
              </Typography>
            </Typography>

            <Typography display={"flex"}>
              {t("Goal")}: <Typography className={classes.infoValue}>-</Typography>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
