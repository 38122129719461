import { makeStyles } from "@toolkit/ui";

export const useMenuTimeLineStyles = makeStyles()(theme => ({
  btn: {
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.main,
  },
  paper: {
    mainWidth: 120,
    borderRadius: "10px !important",
  },
  paperList: {
    borderRadius: "10px !important",
  },
}));
