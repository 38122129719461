import { UserGender } from "@health/queries/types";
import doctorMale from "assets/doctorMale.png";
import doctorFemale from "assets/doctorFemale.png";
export const getDoctorPhoto = ({ photo, gender }: { photo?: string | null; gender?: UserGender | null }) => {
  if (photo) {
    return photo;
  } else if (gender === UserGender.Female) {
    return doctorFemale;
  } else {
    return doctorMale;
  }
};
