import { VendorBranchTypeEnum } from "@health/queries/types";
import { formGirdBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { BranchDefaultData } from "../../BranchesForm.types";

export const BranchesType: FC<{ defaultValue: BranchDefaultData }> = ({ defaultValue }) => {
  const { t } = useTranslation("provider");
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const types = [
    { name: t("Clinic"), value: VendorBranchTypeEnum.Clinic },
    { name: t("Diagnostic Center"), value: VendorBranchTypeEnum.DiagnosticCenter },
    { name: t("Hospital"), value: VendorBranchTypeEnum.Hospital },
    { name: t("Pharmacy"), value: VendorBranchTypeEnum.Pharmacy },
  ];
  const typeValue = defaultValue
    ? types?.find(item => (item.value as any) === defaultValue?.type)
    : { value: VendorBranchTypeEnum.Pharmacy, name: t("Pharmacy") };

  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        ControllerProps={{
          control: control,
          name: "type",
          defaultValue: typeValue,
        }}
        TextFieldProps={{
          placeholder: t("Type"),
          error: Boolean(formErrors?.type?.message),
          helperText: formErrors?.type?.message,
        }}
        getOptionLabel={option => option.name}
        options={types || []}
      />
    </Grid>
  );
};
