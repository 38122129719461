import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const UserFilled: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.999' height='23' viewBox='0 0 21.999 23' {...props}>
      <path
        id='icons8-user_1_'
        data-name='icons8-user (1)'
        d='M15,3A6,6,0,0,0,9,9v1a6,6,0,0,0,12,0V9A6,6,0,0,0,15,3Zm0,16c-4.006,0-9.146,2.167-10.625,4.09A1.816,1.816,0,0,0,5.828,26H24.17a1.816,1.816,0,0,0,1.455-2.91C24.146,21.168,19,19,15,19Z'
        transform='translate(-4 -3)'
        fill={palette.success.main}
      />
    </SvgIcon>
  );
};
