import { i18n } from "@toolkit/i18n";
import { allDoctorPaths } from "./allDoctorsPaths.constants";

export const AllDoctorsTitleBreadcrumbs = () => i18n.t("All Doctors", { ns: "provider" });

export const AllDoctorsListBreadcrumb = () => {
  return {
    id: "all-doctors-list",
    name: i18n.t("All Doctors", { ns: "provider" }),
    route: allDoctorPaths.indexPage.fullPath,
  };
};
