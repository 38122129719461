import { makeStyles } from "@toolkit/ui";

export const usePatientInformationSectionStyle = makeStyles()(theme => ({
  box: {
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: "5px",
    padding: "5px 10px",
    background: theme.palette.warning[200],
  },
  text: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
    color: theme.palette.warning.main,
  },
  noData: {
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: "5px",
    padding: "5px 10px",
    background: theme.palette.warning[200],
  },
  clickable: {
    cursor: "pointer",
  },
}));
