import { useAutocompleteCustomHook } from "@toolkit/ui";
import { useBranchesQuery, useCitiesAutocompleteQuery } from "gql/queries";

const withBranchUserData = Element => {
  return props => {
    const { data, onFetchMoreData: handleFetchMoreData } = useAutocompleteCustomHook({
      useAutocompleteControllerQuery: useCitiesAutocompleteQuery,
      input: {
        first: 10,
      },
      dataAccessor: "cities",
    });
    const {
      data: branchesData,
      onFetchMoreData: handleFetchMoreBranches,
      refetch,
    } = useAutocompleteCustomHook({
      useAutocompleteControllerQuery: useBranchesQuery,
      input: {
        first: 10,
        filter: {
          isActive: true,
        },
      },
      dataAccessor: "branches",
    });

    const cities = data?.cities?.edges?.map(item => item.node);
    const branches = branchesData?.branches?.edges?.map(item => item.node);
    const pageInfoBranches = branchesData?.branches?.pageInfo;
    const pageInfoCities = data?.cities?.pageInfo;
    // TODO fix  branchesUserAdmin no body store branches to local storage

    return (
      <Element
        {...props}
        cities={cities}
        branches={branches}
        pageInfoCities={pageInfoCities}
        pageInfoBranches={pageInfoBranches}
        refetch={refetch}
        onFetchMoreData={handleFetchMoreData}
        onFetchMoreBranches={handleFetchMoreBranches}
      />
    );
  };
};

export default withBranchUserData;
