import { i18n } from "@toolkit/i18n";
import { doctorsCallsRatingsPaths } from "./doctorsCallsRatingsPaths.constants";

export const doctorsCallsRatingsTitleBreadcrumbs = () => i18n.t("Doctors Calls Ratings", { ns: "provider" });

export const doctorsCallsRatingsBreadcrumb = () => {
  return {
    id: "doctors-calls-ratings",
    name: i18n.t("Doctors Calls Ratings", { ns: "provider" }),
    route: doctorsCallsRatingsPaths.indexPage.fullPath,
  };
};
