import { Patient, Maybe } from "@health/queries/types";
import { Box, IconButton, MessageIcon } from "@toolkit/ui";
import { FC } from "react";
import { ChatDialog } from "shared/components/Chat/ChatDialog";

type ChatWithPatientProps = {
  patient?: Maybe<Patient>;
};

export const ChatWithPatient: FC<ChatWithPatientProps> = props => {
  const { patient } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ChatDialog
        userId={patient?.user?.id!}
        buttonEl={
          <IconButton
            sx={{
              "& svg": {
                width: 25,
                height: 25,
              },
            }}
          >
            <MessageIcon width={25} height={25} />
          </IconButton>
        }
      />
    </Box>
  );
};
