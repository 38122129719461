import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { FC } from "react";

export const ProductFormReturnInstructions: FC = () => {
  const { t } = useTranslation("provider");
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"returnPolicy"} label={t("Return Policy")} multiline rows={3} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"returnPolicyAr"} label={t("Return Policy (Arabic)")} multiline rows={3} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormNumberField name={"returnPolicyDays"} label={t("Return Policy Days")} />
      </Grid>
    </Grid>
  );
};
