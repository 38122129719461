import React, { FC } from "react";
import BranchForm from "../BranchForm.component";
import { useBranchNewPageHook } from "./BranchesNewPage.hook";

const NewBranch: FC = () => {
  const { errors, isSaving, handleCreateBranch } = useBranchNewPageHook();

  return <BranchForm errors={errors} isSaving={isSaving} onSubmit={handleCreateBranch} />;
};

export default NewBranch;
