import { Card, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { usePatientHealthParameterVitalSignReadingCardStyle } from "./usePatientHealthParameterVitalSignReadingCardStyle";

type PatientHealthParameterVitalSignReadingCardProps = {
  title: string;
  value?: number | string;
  unit?: string;
  source?: string;
};

export const PatientHealthParameterVitalSignReadingCard: FC<PatientHealthParameterVitalSignReadingCardProps> = props => {
  const { title, value, unit, source } = props;

  const { t } = useTranslation("provider");

  const { classes } = usePatientHealthParameterVitalSignReadingCardStyle();

  return (
    <Card className={classes.root}>
      <Typography className={classes.value}>
        {value || t("N/A")}

        {value && unit && (
          <Typography component={"span"} className={classes.unit}>
            <sup>{unit}</sup>
          </Typography>
        )}
      </Typography>

      <Typography className={classes.title}>{title}</Typography>

      <Typography className={classes.source}>{source ? `${t("By")} ${source}` : ""}</Typography>
    </Card>
  );
};
