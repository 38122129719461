import { LinearProgress, makeStyles, styled } from "@toolkit/ui";
import { CARD_HEIGHT } from "../../constants";
import { BorderLinearProgressStyledProps } from "./types";

export const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressStyledProps>(({ theme, customColor }) => {
  return {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.gray.light,
    "& .MuiLinearProgress-bar": {
      backgroundColor: customColor,
      borderRadius: 5,
    },
  };
});

export const useSimpleCardLoaderStyles = makeStyles()(theme => ({
  card: {
    height: CARD_HEIGHT,
    display: "inline-block",
    padding: theme.spacing(2),
    width: "25%",
    [theme.breakpoints.down("lg")]: {
      width: "33%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
