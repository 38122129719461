import { Box, Divider, Typography, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren, ReactNode } from "react";

export const InformationDoctor: FC<PropsWithChildren<{ title: string; subTitle?: string; headerIcon: ReactNode }>> = ({
  title,
  subTitle,
  headerIcon,
  children,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Box alignItems={"center"} py={1} display='flex'>
          {headerIcon}
          <Typography
            variant='subtitle1'
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            ml={1}
          >
            {title}
          </Typography>
        </Box>
        <Divider />
      </Box>
      {subTitle && (
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.md} color={theme.palette.primary.main}>
          {subTitle}
        </Typography>
      )}
      <Box margin={1}>{children}</Box>
    </>
  );
};
