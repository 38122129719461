import { PatientProfileTabContainer } from "../PatientProfileTabContainer";
import { Overview } from "./PatientProfile";

export const PatientOverview = () => {
  return (
    <PatientProfileTabContainer
      isContainer
      isRoot
      sections={[
        {
          content: <Overview />,
        },
      ]}
    />
  );
};
