import { i18n } from "@toolkit/i18n";
import NoteIcon from "./Icons/NoteIcon";
import PatientProfileIcon from "./Icons/PatientProfileIcon";
import DiagnosisIcon from "./Icons/DiagnosisIcon";
import PrescriptionIcon from "./Icons/PrescriptionIcon";
import LabTestIcon from "./Icons/LabTestIcon";
import RadiologyIcon from "./Icons/RadiologyIcon";

export enum DoctorConsoleType {
  notes,
  diagnosis,
  vitalSigns,
  liveSigns,
  prescription,
  labTest,
  radiology,
  patientProfile,
}

export const doctorConsoleMenu = [
  {
    id: DoctorConsoleType.notes,
    title: i18n.t("Notes", { ns: "provider" }),
    icon: NoteIcon,
  },
  {
    id: DoctorConsoleType.diagnosis,
    title: i18n.t("Diagnosis", { ns: "provider" }),
    icon: DiagnosisIcon,
  },
  {
    id: DoctorConsoleType.vitalSigns,
    title: i18n.t("Vital Signs", { ns: "provider" }),
    icon: DiagnosisIcon,
  },
  {
    id: DoctorConsoleType.liveSigns,
    title: i18n.t("Live Signs", { ns: "provider" }),
    icon: DiagnosisIcon,
  },
  {
    id: DoctorConsoleType.prescription,
    title: i18n.t("Prescription", { ns: "provider" }),
    icon: PrescriptionIcon,
  },
  {
    id: DoctorConsoleType.labTest,
    title: i18n.t("Lab Test", { ns: "provider" }),
    icon: LabTestIcon,
  },
  {
    id: DoctorConsoleType.radiology,
    title: i18n.t("Radiology", { ns: "provider" }),
    icon: RadiologyIcon,
  },
  {
    id: DoctorConsoleType.patientProfile,
    title: i18n.t("Patient Profile", { ns: "provider" }),
    icon: PatientProfileIcon,
  },
];
