import { guidedCareActivityTypeMapper } from "@health/enum-options";
import { GuidedCareJourneyItem, GuidedCareJourneyItemSource } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, paths, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { statusMapper } from "../utils";
import { useTimelineStyles } from "./useTimelineStyles";

type AppointmentIconViewProps = {
  item: GuidedCareJourneyItem;
  selectedItem: GuidedCareJourneyItem;
  hasSimilar: boolean;
  onSelectItem: () => void;
};

export const AppointmentIconView: FC<AppointmentIconViewProps> = props => {
  const { item, selectedItem, hasSimilar, onSelectItem } = props;

  const { t } = useTranslation("provider");

  const theme = useTheme();

  const { icon, viewBox } = guidedCareActivityTypeMapper(item?.guidedCareJourneyItemType!, t, theme.palette);
  const { color, iconStatus, viewBoxStatus } = statusMapper(item?.guidedCareJourneyItemStatus!);

  const isSelected =
    selectedItem?.id === item?.id ||
    (selectedItem?.guidedCareJourneyItemStatus === item?.guidedCareJourneyItemStatus &&
      selectedItem?.guidedCareJourneyItemType === item?.guidedCareJourneyItemType &&
      hasSimilar);

  const isTemplateIntervention = !!item?.templateIntervention;

  const { classes } = useTimelineStyles({ isSelected, isTemplateIntervention, color });

  return (
    <Box className={classes.root} onClick={onSelectItem}>
      <Box className={classes.wrapper}>
        <Box className={classes.iconStatusWrapper}>
          <CustomIcon icon={iconStatus as keyof typeof paths} color={theme.palette.common.white} viewBox={viewBoxStatus} />
        </Box>

        <Box className={classes.iconWrapper}>
          <CustomIcon icon={icon as keyof typeof paths} color={theme.palette.primary.main} viewBox={viewBox} />
        </Box>

        {item?.guidedCareJourneyItemSource === GuidedCareJourneyItemSource.Personalized && (
          <Box id={"id"} className={classes.personalizedIconWrapper}>
            <CustomIcon icon={"user2"} color={theme.palette.primary.main} viewBox={"-4 -4 23 24"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
