/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabsQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type LabsQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', labs?: Array<{ __typename?: 'Lab', id: string, result?: string | null, code?: string | null, display?: string | null, resultReceived?: boolean | null, evaluatedTemplate?: string | null } | null> | null } | null };


export const LabsDocument = gql`
    query Labs($visitId: ID) {
  visit(id: $visitId) {
    labs {
      id
      result
      code
      display
      resultReceived
      evaluatedTemplate
    }
  }
}
    `;

/**
 * __useLabsQuery__
 *
 * To run a query within a React component, call `useLabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabsQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useLabsQuery(baseOptions?: Apollo.QueryHookOptions<LabsQuery, LabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabsQuery, LabsQueryVariables>(LabsDocument, options);
      }
export function useLabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabsQuery, LabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabsQuery, LabsQueryVariables>(LabsDocument, options);
        }
export type LabsQueryHookResult = ReturnType<typeof useLabsQuery>;
export type LabsLazyQueryHookResult = ReturnType<typeof useLabsLazyQuery>;
export type LabsQueryResult = Apollo.QueryResult<LabsQuery, LabsQueryVariables>;