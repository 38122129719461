import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { isNurseAssignedToDoctor } from "utils";
import { useCurrentWorkingBranchSelectDialogStyles } from "./CurrentWorkingBranchSelectDialog.styles";
import { SaveDoctorNurseAssignmentMutation, useSaveDoctorNurseAssignmentMutation } from "./gql/mutations";
import { NurseCurrentWorkSpaceDetailsVar } from "./NurseCurrentWorkSpaceDetailsVar";
import NurseWorkSpaceDetailsForm from "./NurseWorkSpaceDetailsForm";
import { setNurseWorkSpaceDetailsToLocalStorage } from "./utils";

export const NurseWorkingDetailsSelectDialog: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { t } = useTranslation("provider");
  const { classes } = useCurrentWorkingBranchSelectDialogStyles();
  const [saveDoctorNurseAssignmentMutation, { loading: isSubmitting }] = useSaveDoctorNurseAssignmentMutation();
  const currentNurseWorkDetails = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;
  const onSubmit = data => {
    const inputData = {
      branch: data?.branch && {
        id: data?.branch?.id,
        name: data?.branch?.name,
      },
      department: data?.department && {
        id: data?.department?.id,
        name: data?.department?.name,
      },
      doctors: data?.doctors,
    };
    const departmentId = data?.department?.id;
    const requestInput = data?.doctors?.map(doctor => {
      return { departmentId: departmentId, doctorId: doctor?.id };
    });
    saveDoctorNurseAssignmentMutation({
      variables: {
        input: requestInput,
      },
      onCompleted: (dataMutation: SaveDoctorNurseAssignmentMutation) => {
        if (dataMutation.saveDoctorNurseAssignment?.length) {
          NurseCurrentWorkSpaceDetailsVar(inputData);
          setNurseWorkSpaceDetailsToLocalStorage(JSON.stringify(inputData));
          data?.branch && onClose();
        }
      },
    });
  };
  const handleClose = (event?: object) => {
    if (event === undefined) {
      onClose();
    }
  };

  return (
    <CustomDialog
      type='base'
      open={Boolean(isOpen)}
      classes={{
        paper: classes.paper,
      }}
      onClose={handleClose}
      DialogActionsProps={{
        children: (
          <Button disabled={isSubmitting} className={classes.selectBtn} onClick={handleSubmit(onSubmit)}>
            {t("Assign")}
          </Button>
        ),
      }}
      DialogTitleProps={{
        title: t("Complete Your Current Working Data"),
        onClose: onClose,
        hasCloseButton: !!isNurseAssignedToDoctor(currentNurseWorkDetails),
      }}
    >
      <FormProvider {...methods}>
        <form>
          <Box className={classes.box}>
            <Box
              sx={{
                margin: "15px 0px",
              }}
            >
              <NurseWorkSpaceDetailsForm />
            </Box>
          </Box>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
