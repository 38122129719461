import moment, { Moment } from "moment";
import { TFunction } from "../types";

export const getPatientAge = (DoB: Date | Moment | null | undefined, t: TFunction) => {
  const ageInYears = moment().diff(DoB, "years");
  const ageWithYear = t("year", { count: ageInYears });
  const ageInMonths = moment().diff(DoB, "months");
  const ageWithMonth = t("month", { count: ageInMonths });
  const ageInDays = moment().diff(DoB, "days");
  const ageWithDay = t("day", { count: ageInDays });
  if (ageInYears) return ageWithYear;
  else return ageInMonths ? ageWithMonth : ageWithDay;
};
