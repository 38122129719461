import { EChart, EChartOption, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { getPatientHealthParameterChartTooltip } from "../../others";
import { IHealthParameterChartDataSet } from "../../types";

type PatientHealthParameterChartLineProps = {
  dataSets: IHealthParameterChartDataSet[];
  unit?: string;
  isLoading?: boolean;
};

export const PatientHealthParameterChartLine: FC<PatientHealthParameterChartLineProps> = props => {
  const { dataSets, unit, isLoading } = props;

  const theme = useTheme();

  const defaultColors = [theme.palette.primary.main, theme.palette.secondary.main];

  const chartOption: EChartOption = {
    tooltip: {
      trigger: dataSets.length === 1 ? "item" : "axis",
      formatter: params => getPatientHealthParameterChartTooltip(params, dataSets?.length, unit),
      extraCssText: "border: 1px solid",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
    },
    series: dataSets?.map((dataSet, index) => ({
      name: `Line ${index + 1}`,
      type: "line",
      data: dataSet.data,
      lineStyle: {
        color: dataSet.color || defaultColors[index] || theme.palette.gray.main,
      },
      itemStyle: {
        borderWidth: 4,
        color: dataSet.color || defaultColors[index] || theme.palette.gray.main,
      },
      symbol: "circle",
      symbolSize: 7,
    })),
  };

  return <EChart option={chartOption} isLoading={isLoading} />;
};
