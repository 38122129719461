import { DoctorError, DoctorProfileInput, User } from "@health/queries/types";
import { combineErrors, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, CustomIcon, Grid, IconButton, TextField, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

export const UserQualificationInformation: FC<{ defaultValues: User | undefined; errors: DoctorError[] }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("provider");
  const { register, control, formState, trigger } = useFormContext<DoctorProfileInput>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "qualifications",
  });

  useEffect(() => {
    const qualifications = defaultValues?.doctor?.qualifications?.length
      ? defaultValues?.doctor?.qualifications
      : [{ code: "", issuer: "", fromDate: "", toDate: "" }];
    trigger("qualifications").then(() => {
      replace(qualifications);
    });
  }, [trigger, replace, defaultValues]);

  const handleAddNew = () => {
    append({ code: "", issuer: "", fromDate: "", toDate: "" });
  };

  const handleRemove = index => {
    remove(index);
  };

  const combinedErrors = combineErrors(formState.errors, errors);
  const theme = useTheme();
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item xs display='flex' justifyContent='flex-end'>
        <IconButton onClick={handleAddNew}>
          <CustomIcon color={theme.palette.success.main} icon='add' />
        </IconButton>
      </Grid>
      {fields?.map((item, index) => (
        <Grid key={item?.id} item xs={12} container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              label={t("Code")}
              placeholder={t("Code")}
              fullWidth
              error={Boolean(combinedErrors?.qualifications?.[index]?.code?.message)}
              helperText={t(combinedErrors?.qualifications?.[index]?.code?.message)}
              defaultValue={item?.code!}
              {...register(`qualifications.${index}.code`, {
                required: getRequiredValidation(t, true),
              })}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label={t("Issuer")}
              placeholder={t("Issuer")}
              fullWidth
              defaultValue={item?.issuer!}
              error={Boolean(combinedErrors?.qualifications?.[index]?.issuer?.message)}
              helperText={t(combinedErrors?.qualifications?.[index]?.issuer?.message)}
              {...register(`qualifications.${index}.issuer`, {
                required: getRequiredValidation(t, true),
              })}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomDatePicker
              control={control}
              label={t("From Date")}
              placeholder={t("From Date")}
              name={`qualifications.${index}.fromDate`}
              defaultValue={item?.fromDate!}
              error={Boolean(combinedErrors?.qualifications?.[index]?.fromDate?.message)}
              helperText={t(combinedErrors?.qualifications?.[index]?.fromDate?.message)}
              controllerProps={{
                rules: { required: getRequiredValidation(t, true) },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomDatePicker
              control={control}
              label={t("To Date")}
              placeholder={t("To Date")}
              name={`qualifications.${index}.toDate`}
              defaultValue={item?.toDate!}
              error={Boolean(combinedErrors?.qualifications?.[index]?.toDate?.message)}
              helperText={t(combinedErrors?.qualifications?.[index]?.toDate?.message)}
              controllerProps={{
                rules: { required: getRequiredValidation(t, true) },
              }}
            />
          </Grid>
          {index >= 1 && (
            <Grid item xs={12} md={1}>
              <IconButton onClick={() => handleRemove(index)}>
                <CustomIcon color={theme.palette.error[900]} icon='minus' />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
