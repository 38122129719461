import { useTranslation } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui";
import moment from "moment-timezone";
import { FC, useEffect, useState } from "react";
import { WaitingListVisit } from "./types";
import { formatDuration } from "./utils";

export const VisitWaitingTime: FC<{ visit: WaitingListVisit }> = ({ visit }) => {
  const { t } = useTranslation("provider");
  const [waitingMessage, setWaitingMessage] = useState("");

  const isPatientInTheCall = visit?.consumerJoined && !visit?.consumerLeft;
  const isDoctorInTheCall = visit?.doctorJoined && !visit?.doctorLeft;
  const isPatientWaiting = isPatientInTheCall && !visit?.doctorJoined;
  const isDoctorWaiting = isDoctorInTheCall && !visit?.consumerJoined;

  const updateWaitingTime = () => {
    if (visit) {
      if ((isDoctorInTheCall && isPatientInTheCall) || (!isDoctorInTheCall && !isPatientInTheCall)) {
        setWaitingMessage("");
        return;
      }

      const participantDateJoin = isPatientWaiting ? visit?.consumerJoinedDate : visit?.doctorJoinedDate;
      const userTz = moment.tz.guess();
      const joinedDate = moment.tz(participantDateJoin, userTz);
      if (!joinedDate.isValid()) return;
      const now = moment();
      const diffDuration = moment.duration(now.diff(joinedDate));
      const formattedOutput = moment.duration(diffDuration);
      if (isPatientWaiting) {
        setWaitingMessage(t("patient-waiting-for", { waitingTime: formatDuration(formattedOutput, t) }));
      }
      if (isDoctorWaiting) {
        setWaitingMessage(t("doctor-waiting-for", { waitingTime: formatDuration(formattedOutput, t) }));
      }
    }
  };

  useEffect(() => {
    updateWaitingTime();
    if ((!isDoctorInTheCall && !isPatientInTheCall) || (isDoctorInTheCall && isPatientInTheCall)) return;
    const intervalId = setInterval(updateWaitingTime, 1000);

    return () => clearInterval(intervalId);
  }, [visit]);
  const theme = useTheme();
  return (
    <Typography
      fontSize={theme.mixins.fonts.fontSize.xs}
      sx={{ marginTop: "5px" }}
      color={theme.palette.stale.main}
      fontWeight={theme.mixins.fonts.fontWeight.regular}
    >
      {waitingMessage}
    </Typography>
  );
};
