/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentPatientShareGetQueryVariables = Types.Exact<{
  appointmentId: Types.Scalars['ID'];
}>;


export type AppointmentPatientShareGetQuery = { __typename?: 'Query', patientShareForAppointment?: { __typename?: 'PatientShare', coverage?: Types.Coverage | null, patientShare?: number | null } | null };


export const AppointmentPatientShareGetDocument = gql`
    query AppointmentPatientShareGet($appointmentId: ID!) {
  patientShareForAppointment(appointmentId: $appointmentId) {
    coverage
    patientShare
  }
}
    `;

/**
 * __useAppointmentPatientShareGetQuery__
 *
 * To run a query within a React component, call `useAppointmentPatientShareGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentPatientShareGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentPatientShareGetQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentPatientShareGetQuery(baseOptions: Apollo.QueryHookOptions<AppointmentPatientShareGetQuery, AppointmentPatientShareGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentPatientShareGetQuery, AppointmentPatientShareGetQueryVariables>(AppointmentPatientShareGetDocument, options);
      }
export function useAppointmentPatientShareGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentPatientShareGetQuery, AppointmentPatientShareGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentPatientShareGetQuery, AppointmentPatientShareGetQueryVariables>(AppointmentPatientShareGetDocument, options);
        }
export type AppointmentPatientShareGetQueryHookResult = ReturnType<typeof useAppointmentPatientShareGetQuery>;
export type AppointmentPatientShareGetLazyQueryHookResult = ReturnType<typeof useAppointmentPatientShareGetLazyQuery>;
export type AppointmentPatientShareGetQueryResult = Apollo.QueryResult<AppointmentPatientShareGetQuery, AppointmentPatientShareGetQueryVariables>;