/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PatientCurrentMedicalStatusFragmentFragmentDoc } from '../../fragments/__generated__/patientCurrentMedicalStatus';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPatientNonChronicDiseaseMutationVariables = Types.Exact<{
  input: Types.PatientNonChronicDiseaseInput;
}>;


export type AddPatientNonChronicDiseaseMutation = { __typename?: 'Mutation', addPatientNonChronicDisease?: { __typename?: 'PatientMedicalProfile', id: string, patientCurrentMedicalStatus?: { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null } | null } | null };


export const AddPatientNonChronicDiseaseDocument = gql`
    mutation AddPatientNonChronicDisease($input: PatientNonChronicDiseaseInput!) {
  addPatientNonChronicDisease(input: $input) {
    id
    patientCurrentMedicalStatus {
      ...patientCurrentMedicalStatusFragment
    }
  }
}
    ${PatientCurrentMedicalStatusFragmentFragmentDoc}`;
export type AddPatientNonChronicDiseaseMutationFn = Apollo.MutationFunction<AddPatientNonChronicDiseaseMutation, AddPatientNonChronicDiseaseMutationVariables>;

/**
 * __useAddPatientNonChronicDiseaseMutation__
 *
 * To run a mutation, you first call `useAddPatientNonChronicDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientNonChronicDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientNonChronicDiseaseMutation, { data, loading, error }] = useAddPatientNonChronicDiseaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPatientNonChronicDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientNonChronicDiseaseMutation, AddPatientNonChronicDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPatientNonChronicDiseaseMutation, AddPatientNonChronicDiseaseMutationVariables>(AddPatientNonChronicDiseaseDocument, options);
      }
export type AddPatientNonChronicDiseaseMutationHookResult = ReturnType<typeof useAddPatientNonChronicDiseaseMutation>;
export type AddPatientNonChronicDiseaseMutationResult = Apollo.MutationResult<AddPatientNonChronicDiseaseMutation>;
export type AddPatientNonChronicDiseaseMutationOptions = Apollo.BaseMutationOptions<AddPatientNonChronicDiseaseMutation, AddPatientNonChronicDiseaseMutationVariables>;