/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDepartmentMutationVariables = Types.Exact<{
  input: Types.VendorDepartmentCreateInput;
}>;


export type CreateDepartmentMutation = { __typename?: 'Mutation', vendorDepartmentCreate?: { __typename?: 'VendorDepartmentCreate', vendorDepartmentErrors: Array<{ __typename?: 'VendorDepartmentError', code: Types.VendorErrorCode, field?: string | null, message?: string | null }>, department?: { __typename?: 'Department', id: string, name: string, code: string, description?: string | null, directions?: string | null, branch: { __typename?: 'Branch', id: string }, medicalServices?: { __typename?: 'DepartmentMedicalServiceCountableConnection', edges: Array<{ __typename?: 'DepartmentMedicalServiceCountableEdge', node: { __typename?: 'DepartmentMedicalService', id: string, code: string } }> } | null } | null } | null };


export const CreateDepartmentDocument = gql`
    mutation createDepartment($input: VendorDepartmentCreateInput!) {
  vendorDepartmentCreate(input: $input) {
    vendorDepartmentErrors {
      code
      field
      message
    }
    department {
      id
      name
      branch {
        id
      }
      code
      description
      directions
      medicalServices(first: 100) {
        edges {
          node {
            id
            code
          }
        }
      }
    }
  }
}
    `;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<CreateDepartmentMutation, CreateDepartmentMutationVariables>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument, options);
      }
export type CreateDepartmentMutationHookResult = ReturnType<typeof useCreateDepartmentMutation>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>;