import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, extractCountableConnectionData, TableGrid } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { useMedicalServicesQuery } from "../gql";

export const MedicalServicesDialog: FC<{ departmentId: string }> = ({ departmentId }) => {
  const { t } = useTranslation("provider");
  return (
    <CustomDialog
      type='info'
      title={t("Medical Services")}
      DialogContentProps={{
        sx: { maxHeight: "unset" },
      }}
    >
      <GridProvider
        hasTableSetting
        gridName={t("Medical Services")}
        columns={[{ accessor: "code", header: t("Medical Service"), key: "code" }]}
        query={useMedicalServicesQuery}
        extractCountableConnectionData={({ responseData }) => {
          return extractCountableConnectionData({ responseData: responseData?.vendorDepartment!, entityName: "medicalServices" });
        }}
        variables={{ vendorDepartmentId: departmentId }}
      >
        <TableGrid />
      </GridProvider>
    </CustomDialog>
  );
};
