import { getApolloContextFormToken } from "shared/utils";
import { useEvaluatePatientHealthConditionsMutation } from "../gql";

export const useCustomEvaluatePatientHealthConditionsMutation = ({ token }) => {
  const [
    getEvaluatePatientHealthConditionsMutation,
    { data: evaluatePatientHealthConditionsMutationResult, loading: evaluatePatientHealthConditionsMutationLoading },
  ] = useEvaluatePatientHealthConditionsMutation({
    context: getApolloContextFormToken(token!),
  });

  const conditions =
    evaluatePatientHealthConditionsMutationResult?.evaluatePatientHealthConditions?.map(edge => edge?.evaluatedHealthCondition!) || [];

  return {
    conditions,
    getEvaluatePatientHealthConditionsMutation,
    evaluatePatientHealthConditionsMutationLoading,
  };
};
