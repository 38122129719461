import { NetworkStatus } from "@apollo/client";
import { i18n, useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs, useGridFilter } from "@toolkit/ui";
import { useCallback, useEffect } from "react";
import { DoctorCallsRatingsQuery, useDoctorCallsRatingsQuery } from "../../gql";
import { useDoctorsCallsRatingsStyles } from "./useDoctorsCallsRatings.styles";
import { doctorsCallsRatingsBreadcrumb, doctorsCallsRatingsTitleBreadcrumbs } from "pages/doctorsCallsRatings/constants";
import { DashboardBreadcrumb } from "pages/dashboard/constants";

export const useDoctorsCallsRatings = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const { classes } = useDoctorsCallsRatingsStyles();
  const currentDir = i18n.dir(i18n.language);

  const updateQuery = (prev: DoctorCallsRatingsQuery, { fetchMoreResult }) => {
    if (!fetchMoreResult) return prev;

    const newEdges = [...(prev?.doctorCallsRatings?.edges || []), ...(fetchMoreResult?.doctorCallsRatings?.edges || [])];

    return {
      ...fetchMoreResult,
      doctorCallsRatings: {
        ...fetchMoreResult.doctorCallsRatings,
        edges: newEdges,
      },
    };
  };

  const handleFilter = filter => {
    refetch({
      filterInput: {
        ...filter,
        minRatingAverage: filter.minRatingAverage ? +filter.minRatingAverage : null,
      },
    });
  };

  const { activeFiltersProps, staticFiltersProps } = useGridFilter(handleFilter, {});

  const { data, networkStatus, loading, fetchMore, refetch } = useDoctorCallsRatingsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 50,
      filterInput: {},
    },
    fetchPolicy: "network-only",
  });

  const doctorsCallsRating = data?.doctorCallsRatings?.edges.map(item => item.node);

  const pageInfo = data?.doctorCallsRatings?.pageInfo;

  const fetchMoreData = useCallback(() => {
    if (!pageInfo?.hasNextPage) {
      return; // Early return if there's no next page.
    }

    fetchMore({
      variables: {
        first: 5,
        filterInput: {
          ...activeFiltersProps?.requestedFilters,
          minRatingAverage: +(activeFiltersProps?.requestedFilters?.minRatingAverage ?? 0),
        },
        after: pageInfo?.endCursor ?? null, // No need for optional chaining here; we've already checked `hasNextPage`.
      },
      updateQuery, // Separate function to handle query updates.
    });
  }, [activeFiltersProps?.requestedFilters, pageInfo, fetchMore]);

  useEffect(() => {
    setBreadCrumb({
      title: doctorsCallsRatingsTitleBreadcrumbs(),
      values: [DashboardBreadcrumb(), doctorsCallsRatingsBreadcrumb()],
    });
  }, []);

  return {
    isLoading:
      loading ||
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.fetchMore ||
      networkStatus === NetworkStatus.refetch,
    isRefetching: networkStatus === NetworkStatus.refetch,
    activeFiltersProps,
    staticFiltersProps,
    currentDir,
    classes,
    pageInfo,
    doctorsCallsRating,
    fetchMoreData,
    t,
    handleFilter,
  };
};
