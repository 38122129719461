import { coverageOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, EditIcon, IconButton, TextField, Typography } from "@toolkit/ui";
import { SectionTitle } from "pages/appointments/components";
import { useAppointmentPatientShareGetQuery } from "pages/appointments/gql";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAppointmentAcceptPaymentInformationStyle } from "./useAppointmentAcceptPaymentInformationStyle";
import { Coverage } from "@health/queries/types";
import { useSiteSettings } from "shared/siteSettings.var";

type AppointmentAcceptPaymentInformationProps = {
  appointmentId: string;
  isOpen?: boolean;
  patientShareAmount?: number;
  handlePatientShareAmountChange: (value?: number, coverage?: Coverage | null) => void;
};

export const AppointmentAcceptPaymentInformation: FC<AppointmentAcceptPaymentInformationProps> = props => {
  const { appointmentId, patientShareAmount, handlePatientShareAmountChange, isOpen } = props;

  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const { t } = useTranslation("provider");

  const { classes, theme } = useAppointmentAcceptPaymentInformationStyle();

  const { defaultCurrency } = useSiteSettings();
  const { data, loading, refetch } = useAppointmentPatientShareGetQuery({
    variables: {
      appointmentId: appointmentId,
    },
    skip: !appointmentId,
  });

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  const patientShareForAppointment = data?.patientShareForAppointment;

  const handleNewPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    handlePatientShareAmountChange(Number(event.target.value), patientShareForAppointment?.coverage);
  };

  const handleEditIconClick = () => {
    if (isUpdateMode) {
      handlePatientShareAmountChange(patientShareForAppointment?.patientShare! || 0, patientShareForAppointment?.coverage);
    }

    setIsUpdateMode(!isUpdateMode);
  };

  useEffect(() => {
    handlePatientShareAmountChange(patientShareForAppointment?.patientShare! || 0, patientShareForAppointment?.coverage);
  }, [patientShareForAppointment, handlePatientShareAmountChange]);
  return (
    <Box>
      <SectionTitle title={t("Payment Information")} />

      {loading ? (
        <CircularProgress color='inherit' size={theme.mixins.fonts.fontSize.xl} />
      ) : (
        <Box marginBlock={2}>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
            <span className={classes.boldTitle}>{t("Coverage")}: </span>
            {coverageOptionsMap[patientShareForAppointment?.coverage!]?.label}
          </Typography>

          <Box marginBlock={2} display={"flex"}>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
              <span className={classes.boldTitle}>{t("Patient Share Amount")}: </span>
              {patientShareAmount} {defaultCurrency}
            </Typography>

            <IconButton onClick={handleEditIconClick} sx={{ height: 20 }}>
              <EditIcon sx={{ fontSize: theme.mixins.fonts.fontSize.md }} color={"primary"} />
            </IconButton>
          </Box>

          {isUpdateMode && (
            <TextField
              label={t("New Price")}
              placeholder={t("New Price")}
              type={"number"}
              InputProps={{ inputProps: { min: 0 } }}
              value={patientShareAmount}
              onChange={handleNewPriceChange}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
