import { Medication, OrderStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useDrugIdentification } from "../DrugIdentification.hook";
import { useMedicationsSectionStyles } from "../Medications.styles";
import { MedicationsInfo } from "./MedicationsInfo";
import { AuthorizationStatus } from "@health/queries/types";

type IMedication = Medication & { rejectionReason?: string; authStatus?: string };
type MedicationsSectionProps = {
  medications: IMedication[];
  status: string;
};

export const MedicationsSection: FC<MedicationsSectionProps> = props => {
  const { medications, status } = props;
  const { t } = useTranslation("provider");

  const { classes } = useMedicationsSectionStyles();
  const { handleMedication, getGenericDrugProperties } = useDrugIdentification();

  const statusForShowRejectionsReason = OrderStatus.ErxHubRejected === status || OrderStatus.ErxHubPartiallyApproved === status;
  const statusForAuthStatus = OrderStatus.ErxHubRejected || OrderStatus.ErxHubFullyApproved || OrderStatus.ErxHubPartiallyApproved;

  const getAuthStatus = item => Object.keys(AuthorizationStatus).filter(status => AuthorizationStatus[status] === item);

  return (
    <Box
      sx={{
        marginInline: 2,
      }}
    >
      {medications?.length ? (
        medications?.map(medication => {
          const hasRejectionsReason = statusForShowRejectionsReason && medication?.rejectionReason;
          const genericDrugProperties = medication?.genericDrugProperties;
          const item = getGenericDrugProperties(genericDrugProperties);
          const getItem = handleMedication(item);
          const name = medication?.display;

          return (
            <Box key={medication?.id}>
              <Box
                sx={{
                  color: getItem.color,
                }}
              >
                <ul className={classes.medicationName}>
                  <li>
                    <Typography
                      color={getItem.color}
                      fontWeight={getItem.weight}
                      className={hasRejectionsReason ? classes.reject : classes.name}
                    >
                      {name}
                    </Typography>
                  </li>
                </ul>
                {OrderStatus.ErxHubFullyApproved !== status && (
                  <>
                    {statusForAuthStatus === status && medication?.authStatus && (
                      <Typography className={classes.name}>
                        <span className={classes.nameBold}> {t("Auth Status")}</span> :
                        <span
                          className={
                            AuthorizationStatus.Rejected === medication?.authStatus ? classes.authStatus : classes.authStatusApproved
                          }
                        >
                          {getAuthStatus(medication?.authStatus)}
                        </span>
                      </Typography>
                    )}
                    {hasRejectionsReason && (
                      <Typography className={classes.reject}>
                        <span className={classes.nameBold}>{t("Rejections Reason")} </span> : {medication?.rejectionReason}
                      </Typography>
                    )}
                  </>
                )}
              </Box>

              {status !== OrderStatus.NewRequest && <MedicationsInfo medication={medication} />}
              <Typography className={classes.name}>
                {t("Qty")} : {medication?.quantity}
              </Typography>
            </Box>
          );
        })
      ) : (
        <Typography className={classes.name}>{t("No Active medications")}</Typography>
      )}
    </Box>
  );
};
