/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthParametersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthParameterFilterInput>;
}>;


export type GetHealthParametersQuery = { __typename?: 'Query', getHealthParameters?: { __typename?: 'HealthParameterConnection', edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', id?: string | null, type?: Types.TemplateFieldType | null, code?: string | null, display?: string | null, chartType?: Types.ChartType | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, visibleForPatientOverview?: boolean | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null } | null } | null } | null> | null } | null };


export const GetHealthParametersDocument = gql`
    query getHealthParameters($first: Int, $after: String, $filter: HealthParameterFilterInput) {
  getHealthParameters(first: $first, after: $after, filter: $filter) {
    edges {
      node {
        id
        type
        code
        display
        chartType
        unit {
          id
          display
        }
        maxNormalRangeValue
        minNormalRangeValue
        visibleForPatientOverview
      }
    }
  }
}
    `;

/**
 * __useGetHealthParametersQuery__
 *
 * To run a query within a React component, call `useGetHealthParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthParametersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetHealthParametersQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthParametersQuery, GetHealthParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthParametersQuery, GetHealthParametersQueryVariables>(GetHealthParametersDocument, options);
      }
export function useGetHealthParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthParametersQuery, GetHealthParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthParametersQuery, GetHealthParametersQueryVariables>(GetHealthParametersDocument, options);
        }
export type GetHealthParametersQueryHookResult = ReturnType<typeof useGetHealthParametersQuery>;
export type GetHealthParametersLazyQueryHookResult = ReturnType<typeof useGetHealthParametersLazyQuery>;
export type GetHealthParametersQueryResult = Apollo.QueryResult<GetHealthParametersQuery, GetHealthParametersQueryVariables>;