import { CitiesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { LocationPicker } from "@toolkit/maps";
import { FormPhoneInput, Grid, Typography, createAutocompleteOption } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { LocationProps } from "../BranchesForm.types";
import { locationFields } from "./locationUtils";

const Address: FC<LocationProps> = props => {
  const { errorsInput, onLocationChange: handleLocation, location, defaultValues } = props;

  const { t } = useTranslation("provider");

  const form = useFormContext();
  const { register, setValue } = form;
  const phoneInput = "addressInput.phone";

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    register("addressInput.city", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const cityDefaultValue = defaultValues?.address?.city
      ? createAutocompleteOption(defaultValues?.address?.city, "id", item => pickLocalizedValue(item?.name, item?.nameAr!))
      : undefined;

    setValue("addressInput.city", cityDefaultValue);
    setValue(phoneInput, defaultValues?.address?.phone || "");
  }, [defaultValues?.address, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      {locationFields(t, defaultValues?.address)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item?.defaultValue}
            errorsInput={errorsInput}
            maxValue={item?.maxValue}
          />
        </Grid>
      ))}

      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register(phoneInput, {
            required: getRequiredValidation(t, false),
          })}
          label={t("Phone Number")}
          error={Boolean(errorsInput?.[phoneInput]?.message)}
          helperText={t(errorsInput?.[phoneInput]?.message)}
          fullWidth
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <CitiesAutocomplete name={"addressInput.city"} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1' gutterBottom>
          {t("Please Click on Map to detect your Coordinates")}
        </Typography>

        <LocationPicker
          hasSearch
          hasLocationLngLat
          location={location}
          onLocationChange={mapLocation => handleLocation(mapLocation?.coordinates)}
        />
      </Grid>
    </Grid>
  );
};

export default Address;
