import { VisitDiagnosis } from "@health/queries/types";
import { convertToTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useColumns = (): CustomTableColumnProps<VisitDiagnosis>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        type: "string",
        accessor: "code",
      },
      {
        key: "display",
        header: t("visit-details-display"),
        type: "string",
        accessor: "display",
      },
      {
        key: "visitDiagnosisType",
        header: t("Visit Diagnosis Type"),
        accessor: row => t(convertToTitleCase(row.visitDiagnosisType || "")),
        type: "string",
      },
    ];
  }, []);
};
