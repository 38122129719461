import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const RxIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "30.083px"}
      height={height ? height : "35.096px"}
      viewBox={viewBox ? viewBox : "0 0 30.083 35.096"}
      {...props}
    >
      <path
        id='icons8-pharmacy_1_'
        data-name='icons8-pharmacy (1)'
        d='M7,4V25.726h5.014V19.877h4.764l6.094,9.81L17.028,39.1h5.9l4.124-6.639L31.179,39.1h5.9l-5.97-9.61,5.97-9.61h-5.9l-4.25,6.84-4.852-7.81A7.937,7.937,0,0,0,18.281,4H7ZM8.671,5.671h9.61a6.266,6.266,0,0,1,2.995,11.771l-1.55.847.934,1.5,4.852,7.81.912,1.469h1.014l.912-1.469,3.76-6.052h1.967l-4.384,7.057-.547.881.547.881,4.384,7.057H32.11l-3.635-5.851-.519-.834h-1.8l-.519.834L22,37.425H20.034l4.258-6.855.548-.883-.548-.881L18.2,19l-.491-.79H10.343v5.849H8.671V5.671Zm1.671,1.671v9.192h7.938a4.6,4.6,0,0,0,0-9.192H10.343Zm1.671,1.671h6.267a2.925,2.925,0,1,1,0,5.849H12.014Z'
        transform='translate(-7 -4)'
        fill={fill ?? "inherit"}
      />
    </SvgIcon>
  );
};
