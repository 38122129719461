import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const ShareIcon: FC<SvgIconProps> = props => {
  const { width = "23.244", height = "23.244", fill = "#25287f" } = props;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 26 22.001'>
      <path
        id='icons8-right-2'
        d='M3,26a1,1,0,0,1-1-1c0-1.6,1-14.984,17-14.984V5a1,1,0,0,1,1.707-.707l7,7a1,1,0,0,1,0,1.414l-7,7A1,1,0,0,1,19,19V14.014C10,14.014,4,20,4,25A1,1,0,0,1,3,26Z'
        transform='translate(-2 -3.999)'
        fill={fill}
      />
    </SvgIcon>
  );
};
