/* eslint-disable max-lines */
import { MarketplaceProductSortingField, MarketplacePromotionSortingField, SortDirection } from "@health/queries/types";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTableColumnProps, format, GridImageModal, TruncateTooltipTypography, Typography } from "@toolkit/ui";
import { TogglePromotionsPublishStatus } from "pages/MarketplacePromotions/components/PublishPromotions";
import { useMemo } from "react";
import { PromotionsQueryNode } from "../../types";
import {
  getHealthPackagesAutocompleteFilter,
  getMarketplaceHealthPackageCategoriesAutocompleteFilter,
  getMarketplaceProductCategoriesAutocompleteFilter,
  getMarketplaceProductsAutocompleteFilter,
} from "@health/autocompletes";
import { getYesNoAutocompleteFilter } from "@health/enum-options";

export const usePromotionsListTableColumns = (): CustomTableColumnProps<PromotionsQueryNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ photo }) => (
          <GridImageModal image={photo!} title={t("Promotion Image")} fallbackText={t("Promotion Not Provided")} altText={t("Promotion")} />
        ),
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => <TruncateTooltipTypography text={pickLocalizedValue(name, nameAr) || name || "-"} />,
        filter: {
          type: "string",
          name: "name",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplaceProductSortingField.Name,
      },
      {
        key: "description",
        header: t("Description"),
        accessor: "description",
      },
      {
        key: "healthPackageCategoryIds",
        accessor: "healthPackageCategoryIds",
        header: t("Health Package Categories"),
        showOnlyForFilterField: true,
        filter: getMarketplaceHealthPackageCategoriesAutocompleteFilter({
          name: "healthPackageCategoryIds",
          multiple: true,
        }),
      },
      {
        key: "startDate",
        header: t("Start Date"),
        accessor: ({ startDate }) => (
          <Typography width='max-content'>{format(startDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language)}</Typography>
        ),
        filter: { type: "date", name: "startDate" },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplacePromotionSortingField.StartDate,
      },
      {
        key: "endDate",
        header: t("End Date"),
        accessor: ({ endDate }) => (
          <Typography width='max-content'>{format(endDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language)}</Typography>
        ),
        filter: {
          type: "date",
          name: "endDate",
        },
      },
      {
        key: "Products",
        header: t("Products"),
        accessor: ({ products }) => (
          <CustomDialog type='info' title={t("Promotion's Products")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {products?.length
                ? products?.map(product => pickLocalizedValue(product?.name!, product?.nameAr!)).join("\n")
                : t("No product provided")}
            </Typography>
          </CustomDialog>
        ),
      },
      {
        key: "Products Categories",
        header: t("Products Categories"),
        accessor: ({ productCategories }) => (
          <CustomDialog type='info' title={t("Promotion's Products Categories")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {productCategories?.length
                ? productCategories?.map(product => pickLocalizedValue(product?.name!, product?.nameAr!)).join("\n")
                : t("No product categories provided")}
            </Typography>
          </CustomDialog>
        ),
      },
      {
        key: "health-packages",
        header: t("Health Packages"),
        accessor: ({ healthPackages }) => (
          <CustomDialog type='info' title={t("Promotion's Health Packages")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {healthPackages?.length
                ? healthPackages?.map(healthPackage => pickLocalizedValue(healthPackage?.name!, healthPackage?.nameAr!)).join("\n")
                : t("No health package provided")}
            </Typography>
          </CustomDialog>
        ),
      },
      {
        key: "health-packages-categories",
        header: t("Health Packages Categories"),
        accessor: ({ healthPackageCategories }) => (
          <CustomDialog type='info' title={t("Promotion's Health Packages Categories")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {healthPackageCategories?.length
                ? healthPackageCategories?.map(healthPackage => pickLocalizedValue(healthPackage?.name!, healthPackage?.nameAr!)).join("\n")
                : t("No health package categories provided")}
            </Typography>
          </CustomDialog>
        ),
      },
      {
        key: "isPublished",
        header: t("Published"),
        accessor: row => <TogglePromotionsPublishStatus row={row} />,
        filter: {
          ...getYesNoAutocompleteFilter({ name: "isPublished" }),
          label: t("Is Published"),
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: "isPublished",
      },
      {
        key: "healthPackageIds",
        accessor: "healthPackageIds",
        header: t("Health Packages"),
        showOnlyForFilterField: true,
        filter: getHealthPackagesAutocompleteFilter({ name: "healthPackageIds", multiple: true }),
      },
      {
        key: "productCategoryIds",
        accessor: "productCategoryIds",
        header: t("Product Categories"),
        filter: getMarketplaceProductCategoriesAutocompleteFilter({ name: "productCategoryIds", multiple: true }),
        showOnlyForFilterField: true,
      },
      {
        key: "productIds",
        accessor: "productIds",
        header: t("Products"),
        filter: getMarketplaceProductsAutocompleteFilter({ name: "productIds", multiple: true }),
        showOnlyForFilterField: true,
      },
      {
        key: "isActive",
        header: t("Is Active"),
        filter: {
          ...getYesNoAutocompleteFilter({ name: "isActive" }),
          label: t("Is Active"),
        },
        showOnlyForFilterField: true,
      },
    ];
  }, []);
};
