/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { AppointmentsForNotificationPanelFragmentDoc } from '../../fragments/__generated__/appointmentsForNotificationPanel';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.AppointmentFilterInput>;
  sortBy?: Types.InputMaybe<Types.AppointmentSortingInput>;
}>;


export type AppointmentsQuery = { __typename?: 'Query', appointments?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'AppointmentCountableEdge', node: { __typename: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, requestedTimeSlots?: Array<string | null> | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, created?: any | null, cancellationReason?: string | null, suggestedTimeSlots?: Array<string | null> | null, paymentStatus?: Types.PaymentStatus | null, visit?: { __typename?: 'Visit', id: string, number?: string | null, meetingPlatformLinkForJoinCall?: string | null, meetingPlatformLinkForChatView?: string | null } | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', currency?: string | null, totalAmount?: number | null, type?: Types.AppointmentPaymentType | null } | null, consumer?: { __typename?: 'Patient', id: string, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, insuranceId?: string | null, membershipEnd?: any | null, payer?: { __typename?: 'Payer', name: string } | null } | null> | null, healthProgramsByActiveMembers?: Array<{ __typename?: 'HealthProgram', insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', id: string, name?: string | null } }> } | null } | null> | null } | null, doctor?: { __typename?: 'Doctor', user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, photo?: string | null } | null } | null } }> } | null };


export const AppointmentsDocument = gql`
    query appointments($first: Int, $after: String, $before: String, $last: Int, $filter: AppointmentFilterInput, $sortBy: AppointmentSortingInput) {
  appointments(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        ...AppointmentsForNotificationPanel
      }
    }
  }
}
    ${AppointmentsForNotificationPanelFragmentDoc}`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;