import { TFunction } from "@toolkit/i18n";
import { ActivityFormData } from "./types";

export const getInstructions = (activity: ActivityFormData | any, t: TFunction) => {
  return `${t("Take", { ns: "provider" })} ${activity.frequencyValue ?? "-"} ${activity.frequencyUnitType?.display ?? "-"} ${t("of", {
    ns: "provider",
  })} ${activity.code?.display ?? "-"} ${activity.quantity ?? "-"} ${t("(time/s)", { ns: "provider" })} ${t("per", { ns: "provider" })} ${
    activity.frequencyType?.display ?? "-"
  } ${t("for")} ${activity.duration ?? "-"} ${t("days", { ns: "provider" })}. ${t("refill", { ns: "provider" })} ${
    activity.refills ?? "-"
  } ${t("times", { ns: "provider" })}.`;
};
