import { BranchOrderField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { healthProviderBranchesPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useBranchesDataQuery } from "../gql";
import { useGetBranchesColumns } from "./getBranchesColumns";

export const BranchesListScreen: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");

  const handleAddBranch = () => {
    navigate(healthProviderBranchesPaths.addNew.fullPath);
  };

  const handleEdit = row => {
    const route = healthProviderBranchesPaths.edit.fullPathWithParams({ branchId: row.id });
    navigate(route);
  };

  useEffect(() => {
    setBreadCrumb({ title: t("Branches Management"), values: [] });
  }, []);

  return (
    <GridProvider
      gridName='branchesList'
      columns={useGetBranchesColumns()}
      query={useBranchesDataQuery}
      dataAccessor={"branches"}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEdit,
      }}
      hasTableSetting
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: BranchOrderField.Created,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddBranch} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
