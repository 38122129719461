import { usePatientHealthParameterListQuery } from "../gql";
import { getApolloContextFormToken } from "../../../shared/utils";
import { H_OrderDirection, PatientHealthParameter, PatientHealthParameterSortField } from "@health/queries/types";
import { useHealthParametersChanged } from "@health/sse";

type PatientHealthParametersProps = {
  first: number;
  visitId?: string;
  patientId?: string;
  token: string;
  codes: string[];
};

export const usePatientHealthParameters = (props: PatientHealthParametersProps) => {
  const { first, visitId, patientId, token, codes } = props;

  const { data, loading, refetch } = usePatientHealthParameterListQuery({
    variables: {
      first,
      filter: {
        visitId,
        patientId: patientId ? [patientId] : undefined,
        codes,
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    skip: !codes?.length || (!visitId && !patientId),
    context: getApolloContextFormToken(token!),
  });

  const patientHealthParameters = (data?.patientHealthParameters?.edges?.map(item => item?.node) || []) as PatientHealthParameter[];
  const pageInfo = data?.patientHealthParameters?.pageInfo;

  useHealthParametersChanged(({ data: healthParametersData }) => {
    if (!healthParametersData?.healthParameters?.length) return;

    const healthParameterItems = healthParametersData.healthParameters.filter(item => codes?.includes(item.code));

    if (!healthParameterItems || !healthParameterItems?.length) return;

    refetch({
      first,
      filter: {
        visitId,
        patientId: patientId ? [patientId] : undefined,
        codes: healthParameterItems?.map(item => item?.code),
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    });
  });

  return {
    data: patientHealthParameters,
    pageInfo,
    loading,
  };
};
