import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const HealthCheckupIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='36.768' height='36.768' viewBox='0 0 38.439 36.768' {...props}>
      <path
        id='icons8-health-checkup'
        d='M32.083,3a4.178,4.178,0,1,0,4.178,4.178A4.191,4.191,0,0,0,32.083,3ZM7.014,4.671a1.671,1.671,0,0,0-1.446.836H2.836A.836.836,0,0,0,2,6.342v6.685a10.037,10.037,0,0,0,9.194,9.985q0,.021,0,.042c0,2.083.1,4.963,1.611,7.408s4.481,4.291,9.623,4.291A11.659,11.659,0,0,0,28,33.489a.836.836,0,0,0,.325-.2,8.662,8.662,0,0,0,2.982-2.881,11.355,11.355,0,0,0,1.611-5.805V22.934a3.343,3.343,0,1,0-1.671,0v1.673a10.012,10.012,0,0,1-1.36,4.924A7.258,7.258,0,0,1,28.5,31.16l-.219-4.175L25.4,25.831V20.548a6.685,6.685,0,1,1,13.37,0v5.283l-2.882,1.154L35.3,38.1H28.867l-.17-3.075a10.3,10.3,0,0,1-1.629.71l.085,1.616q0,.031,0,.062l.126,2.358h9.606L37.5,28.139l2.941-1.175V20.548a8.356,8.356,0,1,0-16.713,0v6.416l2.941,1.175.214,4.067a10.31,10.31,0,0,1-4.456.876c-4.761,0-6.989-1.536-8.2-3.5s-1.361-4.514-1.361-6.53q0-.021,0-.042a10.037,10.037,0,0,0,9.194-9.985V6.343a.836.836,0,0,0-.836-.836H18.486a1.67,1.67,0,1,0,0,1.671h1.9v5.849a8.356,8.356,0,1,1-16.713,0V7.178h1.9A1.67,1.67,0,1,0,7.014,4.671Zm25.069,0a2.507,2.507,0,1,1-2.507,2.507A2.494,2.494,0,0,1,32.083,4.671Zm0,13.37a1.668,1.668,0,0,1,.075,3.334.836.836,0,0,0-.088,0l-.064,0a1.668,1.668,0,0,1,.077-3.334Z'
        transform='translate(-2 -3)'
        fill={props?.fill ?? "inherit"}
      />
    </SvgIcon>
  );
};
export default HealthCheckupIcon;
