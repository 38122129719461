import { usePatientHealthParameters } from "./usePatientHealthParameters";
import { convertPatientHealthParametersNumberToChartData } from "../others";
import { pickLocalizedValue } from "@toolkit/i18n";

type PatientHealthParametersVitalSignsProps = {
  patientId: string;
  token: string;
  codes: string[];
};

export const usePatientHealthParametersNumber = (props: PatientHealthParametersVitalSignsProps) => {
  const { patientId, token, codes } = props;

  const { data, loading } = usePatientHealthParameters({
    first: codes.length === 1 ? 10 : 20,
    patientId,
    token,
    codes,
  });

  const patientHealthParametersByCode = codes.map(code => data?.filter(item => item?.hpCode === code)) || [];

  const formattedData = patientHealthParametersByCode
    .filter(item => item?.length)
    .map(item => convertPatientHealthParametersNumberToChartData(item!));

  const chartData = formattedData.map(item => ({ data: item }));

  const [value, date, source] = [
    formattedData?.map(item => item?.[0]?.value)?.join("/"),
    formattedData?.map(item => item?.[0]?.date)?.[0],
    formattedData?.map(item => item?.[0]?.source)?.[0],
  ];

  const healthParameter = patientHealthParametersByCode?.[0]?.find(item => item?.healthParameter)?.healthParameter;
  const unit = pickLocalizedValue(healthParameter?.unit?.display!, healthParameter?.unit?.arabicDisplay!);
  const chartType = healthParameter?.chartType;

  return {
    chartData,
    value,
    date,
    source,
    unit,
    chartType,
    loading,
  };
};
