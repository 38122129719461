import { Card } from "@toolkit/ui";
import { CARD_HEIGHT } from "../../constants";
import { FC } from "react";
import ContentLoader from "react-content-loader";
import { useSimpleCardLoaderStyles } from "./DoctorRatingCard.styles";

const DoctorRatingCardSkeleton: FC = () => {
  const { classes } = useSimpleCardLoaderStyles();
  return (
    <>
      {Array.from({ length: 4 })
        .fill(0)
        .map((_, index) => (
          <div key={index} className={classes.card}>
            <Card sx={{ height: CARD_HEIGHT, padding: 2, paddingTop: 3 }}>
              <ContentLoader viewBox='0 0 350 350'>
                <rect x='105' y='250' rx='3' ry='3' width='135' height='8' />
                <rect x='105' y='280' rx='3' ry='3' width='135' height='8' />
                <rect x='8' y='310' rx='3' ry='3' width='334' height='8' />
                <rect x='8' y='340' rx='3' ry='3' width='334' height='8' />
                <rect x='8' y='370' rx='3' ry='3' width='334' height='8' />
                <rect x='8' y='400' rx='3' ry='3' width='334' height='8' />
                <rect x='8' y='430' rx='3' ry='3' width='334' height='8' />
                <circle cx='170' cy='120' r='80' />
              </ContentLoader>
            </Card>
          </div>
        ))}
    </>
  );
};

export default DoctorRatingCardSkeleton;
