import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormAutocomplete,
  FormDatePickerField,
  FormNumberField,
  FormTextField,
  Grid,
  HintComponent,
  mapStringsToAutocompleteOptions,
  MuiExpandMoreIcon,
} from "@toolkit/ui";
import { yesNoOptions } from "@health/enum-options";
import React, { useEffect, useState } from "react";
import { TemplateFieldType } from "@health/queries/types/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { isNil } from "lodash";
import { IGuidedCareProgramMemberRiskFactorParametersFormValues } from "./GuidedCareProgramMemberRiskFactorParametersFormSchema";
import { useGuidedCareProgramMemberRiskFactorParametersFormStyle } from "./useGuidedCareProgramMemberRiskFactorParametersFormStyle";
import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareProgramMemberAddFormValues } from "../GuidedCareProgramMemberAdd/GuidedCareProgramMemberAddFormSchema";

export const GuidedCareProgramMemberRiskFactorParametersForm = () => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const form = useCustomFormContext<IGuidedCareProgramMemberAddFormValues>();

  const { watch, setValue } = form;

  const riskFactorTemplateFields = watch("riskFactorTemplateFields");
  const hasEvaluationResult = !!watch("evaluationResult");

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareProgramMemberRiskFactorParametersFormStyle();

  const onAccordionChange = () => {
    setIsAccordionExpanded(prevState => !prevState);
  };

  const onEditValuesClick = () => {
    setValue("evaluationResult", undefined);
    setValue("suggestedProgram", undefined);
  };

  const renderField = (item: IGuidedCareProgramMemberRiskFactorParametersFormValues, index: number) => {
    const display = pickLocalizedValue(item?.display, item?.arabicDisplay)!;
    const unit = pickLocalizedValue(item?.unit?.display, item?.unit?.arabicDisplay)!;

    const label = unit ? `${display} (${unit})` : display;

    if (item?.type === TemplateFieldType.Boolean) {
      return (
        <FormAutocomplete
          name={`riskFactorTemplateFields.${index}.valueBoolean`}
          label={label}
          placeholder={label}
          options={yesNoOptions}
          disabled={hasEvaluationResult}
        />
      );
    } else if (item?.type === TemplateFieldType.Date) {
      return (
        <FormDatePickerField
          name={`riskFactorTemplateFields.${index}.valueDate`}
          label={label}
          placeholder={label}
          disabled={hasEvaluationResult}
        />
      );
    } else if (item?.type === TemplateFieldType.String) {
      if (item?.allowedValues?.length) {
        return (
          <FormAutocomplete
            name={`riskFactorTemplateFields.${index}.valueStringList`}
            label={label}
            placeholder={label}
            options={mapStringsToAutocompleteOptions(item?.allowedValues)}
            disabled={hasEvaluationResult}
          />
        );
      } else {
        return (
          <FormTextField
            name={`riskFactorTemplateFields.${index}.valueString`}
            label={label}
            placeholder={label}
            disabled={hasEvaluationResult}
          />
        );
      }
    } else if (item?.type === TemplateFieldType.Number) {
      const hintText = `${t("Min Value")}: ${!isNil(item?.minNormalRangeValue) ? item?.minNormalRangeValue : "-"}, ${t("Max Value")}: ${
        !isNil(item?.maxNormalRangeValue) ? item?.maxNormalRangeValue : "-"
      }`;

      return (
        <FormNumberField
          name={`riskFactorTemplateFields.${index}.valueNumber`}
          label={label}
          placeholder={label}
          disabled={hasEvaluationResult}
          InputProps={{
            endAdornment: item?.minNormalRangeValue || item?.maxNormalRangeValue ? <HintComponent title={hintText} /> : undefined,
          }}
        />
      );
    }

    return (
      <FormTextField
        name={`riskFactorTemplateFields.${index}.valueString`}
        label={label}
        placeholder={label}
        disabled={hasEvaluationResult}
      />
    );
  };

  useEffect(() => {
    if (hasEvaluationResult) {
      setIsAccordionExpanded(false);
    } else {
      setIsAccordionExpanded(true);
    }
  }, [hasEvaluationResult]);

  return (
    <Accordion expanded={isAccordionExpanded} elevation={0} className={classes.accordion} onChange={onAccordionChange}>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<MuiExpandMoreIcon />}>
        {t("Risk Factor Parameters")}
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          {riskFactorTemplateFields?.map((item, index) => (
            <Grid item xs={12} key={item?.id}>
              {renderField(item, index)}
            </Grid>
          ))}

          {hasEvaluationResult && (
            <Grid item xs={12}>
              <Button variant={"contained"} onClick={onEditValuesClick}>
                {t("Edit Values")}
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
