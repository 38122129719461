/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDoctorsQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.DoctorOrder>;
  filter?: Types.InputMaybe<Types.DoctorFilterInput>;
}>;


export type FetchDoctorsQuery = { __typename?: 'Query', doctors?: { __typename?: 'DoctorCountableConnection', edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string, bio?: string | null, ratingsSum: number, totalRatings: number, user?: { __typename?: 'User', gender?: Types.UserGender | null, fullName?: string | null, photo?: string | null, id: string } | null, experiences?: Array<{ __typename?: 'DoctorExperience', id: string, jobTitle: string, description?: string | null } | null> | null } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null } } | null };


export const FetchDoctorsDocument = gql`
    query fetchDoctors($last: Int, $first: Int, $after: String, $before: String, $sortBy: DoctorOrder, $filter: DoctorFilterInput) {
  doctors(
    last: $last
    first: $first
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    edges {
      node {
        id
        bio
        user {
          gender
          fullName
          photo
          id
        }
        experiences {
          id
          jobTitle
          description
        }
        ratingsSum
        totalRatings
      }
    }
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useFetchDoctorsQuery__
 *
 * To run a query within a React component, call `useFetchDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDoctorsQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchDoctorsQuery(baseOptions?: Apollo.QueryHookOptions<FetchDoctorsQuery, FetchDoctorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDoctorsQuery, FetchDoctorsQueryVariables>(FetchDoctorsDocument, options);
      }
export function useFetchDoctorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDoctorsQuery, FetchDoctorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDoctorsQuery, FetchDoctorsQueryVariables>(FetchDoctorsDocument, options);
        }
export type FetchDoctorsQueryHookResult = ReturnType<typeof useFetchDoctorsQuery>;
export type FetchDoctorsLazyQueryHookResult = ReturnType<typeof useFetchDoctorsLazyQuery>;
export type FetchDoctorsQueryResult = Apollo.QueryResult<FetchDoctorsQuery, FetchDoctorsQueryVariables>;