import { useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { agentsBreadcrumb, agentsPaths } from "../constants";
import { AgentsList } from "@health/domains";
import { useNavigate } from "react-router-dom";

export const ProviderAgentsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  useEffect(() => {
    setBreadCrumb({
      title: agentsBreadcrumb().title,
    });
  }, []);
  const handleEditClick = (agentId: string) => {
    navigate(agentsPaths.update.fullPathWithParams({ agentId: agentId }));
  };
  return <AgentsList isAdmin={false} handleEditClick={handleEditClick} />;
};
