import { useTranslation } from "@toolkit/i18n";
import { Button } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const DiagnosisButtonsGroup: FC<{ isSubmitting: boolean }> = ({ isSubmitting }) => {
  const { t } = useTranslation("provider");
  const { reset } = useFormContext();

  const handleResetClick = () => {
    reset();
  };
  return (
    <>
      <Button disabled={isSubmitting} type='submit' sx={{ margin: "10px" }}>
        {t("Add Diagnosis")}
      </Button>
      <Button variant='text' onClick={handleResetClick}>
        {t("Reset Fields")}
      </Button>
    </>
  );
};
