/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGuidedCareHealthProgramsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetGuidedCareHealthProgramsQuery = { __typename?: 'Query', guidedCareHealthPrograms?: { __typename?: 'GuidedCareHealthProgramCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'GuidedCareHealthProgramCountableEdge', node: { __typename?: 'GuidedCareHealthProgram', name?: string | null, id: string, guidedCareHealthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string, riskFactorTemplate?: { __typename?: 'RiskFactorTemplate', id?: string | null, display?: string | null, fields?: Array<{ __typename?: 'HealthParameter', createdDate?: string | null, id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null, allowedValues?: Array<string | null> | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, type?: Types.TemplateFieldType | null, standard?: Types.FieldStandard | null, isMandatory?: boolean | null, defaultValue?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null> | null } | null } | null } }> } | null };


export const GetGuidedCareHealthProgramsDocument = gql`
    query getGuidedCareHealthPrograms($first: Int, $before: String, $after: String, $last: Int) {
  guidedCareHealthPrograms(
    first: $first
    before: $before
    after: $after
    last: $last
  ) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        name
        id
        guidedCareHealthProgramTemplate {
          id
          riskFactorTemplate {
            id
            display
            fields {
              createdDate
              id
              display
              code
              arabicDisplay
              allowedValues
              minNormalRangeValue
              maxNormalRangeValue
              type
              standard
              isMandatory
              allowedValues
              defaultValue
              unit {
                id
                display
                code
                arabicDisplay
              }
              category {
                id
                display
                code
                arabicDisplay
              }
              errors {
                code
                errorType
                field
                message
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGuidedCareHealthProgramsQuery__
 *
 * To run a query within a React component, call `useGetGuidedCareHealthProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuidedCareHealthProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuidedCareHealthProgramsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetGuidedCareHealthProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetGuidedCareHealthProgramsQuery, GetGuidedCareHealthProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuidedCareHealthProgramsQuery, GetGuidedCareHealthProgramsQueryVariables>(GetGuidedCareHealthProgramsDocument, options);
      }
export function useGetGuidedCareHealthProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuidedCareHealthProgramsQuery, GetGuidedCareHealthProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuidedCareHealthProgramsQuery, GetGuidedCareHealthProgramsQueryVariables>(GetGuidedCareHealthProgramsDocument, options);
        }
export type GetGuidedCareHealthProgramsQueryHookResult = ReturnType<typeof useGetGuidedCareHealthProgramsQuery>;
export type GetGuidedCareHealthProgramsLazyQueryHookResult = ReturnType<typeof useGetGuidedCareHealthProgramsLazyQuery>;
export type GetGuidedCareHealthProgramsQueryResult = Apollo.QueryResult<GetGuidedCareHealthProgramsQuery, GetGuidedCareHealthProgramsQueryVariables>;