import { TFunction } from "@toolkit/i18n";
import { Badge, Button, Chat, useTheme } from "@toolkit/ui";
import { FC, MouseEventHandler, ReactNode } from "react";

interface ChatButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  unread: ReactNode;
  t: TFunction;
}

const ChatButton: FC<ChatButtonProps> = ({ onClick, unread, t }) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      sx={{
        borderRadius: 0,
        backgroundColor: "inherit",
        marginInline: 0,
        height: 20,
        width: 112,
        paddingInline: 0,
        minWidth: 100,
        "& svg": {
          width: 18,
          height: 18,
          position: "relative",
          left: 0,
        },
      }}
      startIcon={
        <Badge
          sx={{
            "& .MuiBadge-badge": {
              border: "2px solid",
              borderColor: theme.palette.common.white,
            },
          }}
          badgeContent={unread}
          color={"secondary"}
        >
          <Chat color={"primary"} width={18} height={18} />
        </Badge>
      }
      variant='text'
    >
      {t("Chat Patient", { ns: "provider" })}
    </Button>
  );
};

export default ChatButton;
