import React from "react";
import { useSearchParams } from "react-router-dom";
import { Prescription } from "./Prescription";

export const PrescriptionPage: React.FC<{ visitId?: string }> = ({ visitId }) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const visitIdFromParam = params.get("visitId");

  return <Prescription visitId={visitIdFromParam || visitId} token={token!} />;
};
