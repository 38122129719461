import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog, CustomIcon, IconButton, TextField, useAddToast } from "@toolkit/ui";
import React, { FC } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { useGuidedCareJourneyItemUpdateMutation } from "../../../gql";

type NoteProps = {
  id: string;
};
export const Note: FC<NoteProps> = ({ id }) => {
  const { succeeded, failed } = useAddToast();
  const { token } = useProfileContext();

  const { t } = useTranslation("provider");

  const [note, setNote] = React.useState<string>("");
  const { open, handleToggle } = useOpenState();
  const [updateGuidedCareJourney, { loading }] = useGuidedCareJourneyItemUpdateMutation({
    context: getApolloContextFormToken(token),
    onCompleted: response => {
      const mutationErrors = response?.guidedCareJourneyItemUpdate?.guidedCareJourneyItemErrors;
      if (mutationErrors?.length! > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        handleToggle();
        succeeded(t("Guided Care Journey Item Note Updated Successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const handleUpdateNote = () => {
    updateGuidedCareJourney({
      variables: {
        id,
        input: {
          note: note!,
        },
      },
    });
  };

  return (
    <CustomDialog
      type='base'
      open={open}
      button={
        <IconButton onClick={handleToggle}>
          <CustomIcon icon='note-activity' />
        </IconButton>
      }
      maxWidth='xs'
      DialogTitleProps={{
        title: t("Update Note"),
        onClose: handleToggle,
      }}
      keepMounted={false}
      DialogActionsProps={{
        children: (
          <>
            <Button onClick={handleUpdateNote} disabled={loading}>
              {loading ? (
                <>
                  <CircularProgress size={14} /> {t("Update")}...
                </>
              ) : (
                t("Update")
              )}
            </Button>
            <Button variant='outlined' onClick={handleToggle}>
              {t("Close")}
            </Button>
          </>
        ),
      }}
    >
      <TextField fullWidth multiline rows={4} value={note} onChange={handleChangeValue} placeholder={t("Please write your note")} />
    </CustomDialog>
  );
};
