const providersManagement = "branches";
const newPath = "new";
const editPath = `edit/:id`;
const editBranchPath = `edit/:idBranch`;
const branches = `:id/branches`;
const provider = "provider";
export const providersManagementRoutes = {
  name: "Providers",
  path: providersManagement,
  // fullPath: providersManagement,
  providers: {
    name: "providers",
    path: provider,
    fullPath: [providersManagement, provider].join("/"),
    new: {
      fullPath: [providersManagement, provider, newPath].join("/"),
      name: "New",
      path: [provider, newPath].join("/"),
    },
    edit: {
      fullPath: [providersManagement, provider, editPath].join("/"),
      name: "Edit",
      path: [provider, editPath].join("/"),
    },
    branch: {
      fullPath: [providersManagement, provider, branches].join("/"),
      name: "Branch",
      path: [provider, branches].join("/"),
    },
    newBranch: {
      fullPath: [providersManagement, provider, branches, newPath].join("/"),
      name: "New Branch",
      path: [provider, branches, newPath].join("/"),
    },
    editBranch: {
      fullPath: [providersManagement, provider, branches, editBranchPath].join("/"),
      name: "New Branch",
      path: [provider, branches, editBranchPath].join("/"),
    },
  },
};
