/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceDiscountPublishStatusUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  isPublish: Types.Scalars['Boolean'];
}>;


export type MarketplaceDiscountPublishStatusUpdateMutation = { __typename?: 'Mutation', publishMarketplaceDiscount?: { __typename?: 'MarketplaceDiscount', id: string, isPublished: boolean } | null, unPublishMarketplaceDiscount?: { __typename?: 'MarketplaceDiscount', id: string, isPublished: boolean } | null };


export const MarketplaceDiscountPublishStatusUpdateDocument = gql`
    mutation MarketplaceDiscountPublishStatusUpdate($id: ID!, $isPublish: Boolean!) {
  publishMarketplaceDiscount: marketplaceDiscountPublish(id: $id) @include(if: $isPublish) {
    id
    isPublished
  }
  unPublishMarketplaceDiscount: marketplaceDiscountUnpublish(id: $id) @skip(if: $isPublish) {
    id
    isPublished
  }
}
    `;
export type MarketplaceDiscountPublishStatusUpdateMutationFn = Apollo.MutationFunction<MarketplaceDiscountPublishStatusUpdateMutation, MarketplaceDiscountPublishStatusUpdateMutationVariables>;

/**
 * __useMarketplaceDiscountPublishStatusUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceDiscountPublishStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceDiscountPublishStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceDiscountPublishStatusUpdateMutation, { data, loading, error }] = useMarketplaceDiscountPublishStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPublish: // value for 'isPublish'
 *   },
 * });
 */
export function useMarketplaceDiscountPublishStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceDiscountPublishStatusUpdateMutation, MarketplaceDiscountPublishStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceDiscountPublishStatusUpdateMutation, MarketplaceDiscountPublishStatusUpdateMutationVariables>(MarketplaceDiscountPublishStatusUpdateDocument, options);
      }
export type MarketplaceDiscountPublishStatusUpdateMutationHookResult = ReturnType<typeof useMarketplaceDiscountPublishStatusUpdateMutation>;
export type MarketplaceDiscountPublishStatusUpdateMutationResult = Apollo.MutationResult<MarketplaceDiscountPublishStatusUpdateMutation>;
export type MarketplaceDiscountPublishStatusUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceDiscountPublishStatusUpdateMutation, MarketplaceDiscountPublishStatusUpdateMutationVariables>;