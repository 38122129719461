import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, Card, CustomIcon, Grid, Rating, RatingIcon, Tooltip, Typography, useTheme } from "@toolkit/ui";
import { toNumber } from "lodash";
import { FC } from "react";
import { getDoctorPhoto } from "utils";
import { CARD_HEIGHT } from "../../constants";
import { LinearProgressWrapper } from "./LinearProgressWrapper.component";
import { DoctorRatingCardProps } from "./types";

export const DoctorRatingCard: FC<DoctorRatingCardProps> = ({ title, subTitle, description, ratings, totalRatings, photo, gender }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const RATINGS = {
    5: { color: theme.palette.success.main, count: 0 },
    4: { color: theme.palette.success[800], count: 0 },
    3: { color: theme.palette.primary.main, count: 0 },
    2: { color: theme.palette.warning.main, count: 0 },
    1: { color: theme.palette.error.main, count: 0 },
  };

  const averageRating =
    ratings?.reduce((acc, curr) => acc + (curr.rateValue ? curr.rateValue * curr.rateCount : 0), 0) / (totalRatings || 1);

  return (
    <Card sx={{ height: CARD_HEIGHT, padding: 2, paddingTop: 3 }}>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Avatar
              src={getDoctorPhoto({ photo, gender })}
              sx={{
                width: 100,
                height: 100,
                boxShadow: theme.mixins.shadows.md,
                border: "3px solid",
                borderColor: theme.palette.common.white,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}
            variant='body1'
            textAlign='center'
            fontWeight='bold'
          >
            {title}
          </Typography>
          <Typography variant='body1' textAlign='center' fontWeight='bold'>
            {subTitle}
          </Typography>
          <Tooltip title={description}>
            <Typography
              variant='body2'
              textAlign='center'
              fontSize={theme.mixins.fonts.fontSize.sm}
              color={theme.palette.primary.main}
              fontWeight={theme.mixins.fonts.fontWeight.regular}
              sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}
            >
              {description || "-"}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign='center'>
            <Rating
              value={averageRating}
              icon={<RatingIcon fill={theme.palette.primary.main} />}
              disabled
              emptyIcon={<CustomIcon icon='rating' color={theme.palette.gray.light} width={22} height={20} viewBox='0 0 22 22' />}
            />
          </Box>
          <Typography
            variant='body1'
            fontSize={theme.mixins.fonts.fontSize.sm}
            color={theme.palette.stale.main}
            fontWeight={theme.mixins.fonts.fontWeight.regular}
            textAlign='center'
          >
            <Typography
              sx={{ display: "inline" }}
              fontSize={theme.mixins.fonts.fontSize.sm}
              fontWeight={theme.mixins.fonts.fontWeight.medium}
            >
              {averageRating ? `(${averageRating.toFixed(1)}) ` : ""}
            </Typography>
            {totalRatings} {t("Ratings")}
          </Typography>
        </Grid>
        {Object.entries(RATINGS)
          .sort(([rate1], [rate2]) => +rate2 - +rate1)
          .map(([rate, { count, color }]) => (
            <Grid key={`${rate}`} item xs={12}>
              <Box textAlign='center'>
                <LinearProgressWrapper
                  customColor={color as string}
                  variant='determinate'
                  rateCount={ratings.find(x => x.rateValue === +rate)?.rateCount ?? count}
                  rateValue={!isNaN(toNumber(rate)) ? toNumber(rate) : 0}
                  totalRatings={totalRatings}
                />
              </Box>
            </Grid>
          ))}
      </Grid>
    </Card>
  );
};
