/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTaskUpdateToDoneMutationVariables = Types.Exact<{
  actionEntityId: Types.Scalars['ID'];
  assigneeUserId: Types.Scalars['ID'];
  guidedCareJourneyItemId: Types.Scalars['ID'];
  taskWorkflowType: Types.TaskWorkflowType;
}>;


export type GuidedCareTaskUpdateToDoneMutation = { __typename?: 'Mutation', updateGuidedCareTaskToDone?: { __typename?: 'GuidedCareTaskCRUD', guidedCareTask?: { __typename?: 'GuidedCareTask', id: string, status?: Types.TaskDefinitionStatus | null, assignee?: Array<{ __typename?: 'GuidedCareTaskAssignee', id: string, assigneeUserId?: string | null, assigneeType?: Types.AssigneeType | null, teamMember?: { __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } | null> | null } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTaskUpdateToDoneDocument = gql`
    mutation GuidedCareTaskUpdateToDone($actionEntityId: ID!, $assigneeUserId: ID!, $guidedCareJourneyItemId: ID!, $taskWorkflowType: TaskWorkflowType!) {
  updateGuidedCareTaskToDone(
    actionEntityId: $actionEntityId
    assigneeUserId: $assigneeUserId
    guidedCareJourneyItemId: $guidedCareJourneyItemId
    taskWorkflowType: $taskWorkflowType
  ) {
    guidedCareTask {
      id
      status
      assignee {
        id
        assigneeUserId
        assigneeType
        teamMember {
          id
          user {
            id
            fullName
          }
        }
      }
    }
    entityErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTaskUpdateToDoneMutationFn = Apollo.MutationFunction<GuidedCareTaskUpdateToDoneMutation, GuidedCareTaskUpdateToDoneMutationVariables>;

/**
 * __useGuidedCareTaskUpdateToDoneMutation__
 *
 * To run a mutation, you first call `useGuidedCareTaskUpdateToDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTaskUpdateToDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTaskUpdateToDoneMutation, { data, loading, error }] = useGuidedCareTaskUpdateToDoneMutation({
 *   variables: {
 *      actionEntityId: // value for 'actionEntityId'
 *      assigneeUserId: // value for 'assigneeUserId'
 *      guidedCareJourneyItemId: // value for 'guidedCareJourneyItemId'
 *      taskWorkflowType: // value for 'taskWorkflowType'
 *   },
 * });
 */
export function useGuidedCareTaskUpdateToDoneMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTaskUpdateToDoneMutation, GuidedCareTaskUpdateToDoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTaskUpdateToDoneMutation, GuidedCareTaskUpdateToDoneMutationVariables>(GuidedCareTaskUpdateToDoneDocument, options);
      }
export type GuidedCareTaskUpdateToDoneMutationHookResult = ReturnType<typeof useGuidedCareTaskUpdateToDoneMutation>;
export type GuidedCareTaskUpdateToDoneMutationResult = Apollo.MutationResult<GuidedCareTaskUpdateToDoneMutation>;
export type GuidedCareTaskUpdateToDoneMutationOptions = Apollo.BaseMutationOptions<GuidedCareTaskUpdateToDoneMutation, GuidedCareTaskUpdateToDoneMutationVariables>;