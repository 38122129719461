import { useTranslation } from "@toolkit/i18n";
import { Typography, makeStyles } from "@toolkit/ui";
import { FC } from "react";

const useOrderDetailsStyles = makeStyles()(theme => ({
  title: {
    color: theme.palette.stale.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
}));

type IOrderDetails = {
  timeOutComponent: JSX.Element | boolean;
  referenceNumber: string;
  number: string | number;
};

export const OrderDetails: FC<IOrderDetails> = ({ timeOutComponent, referenceNumber, number }) => {
  const { t } = useTranslation("provider");
  const { classes } = useOrderDetailsStyles();

  return (
    <>
      {timeOutComponent}

      <Typography className={classes.title}>
        {t("Order ID")}: {number}
      </Typography>

      {referenceNumber && (
        <Typography className={classes.title}>
          {t("eRx Number")} : {referenceNumber}
        </Typography>
      )}
    </>
  );
};
