import { useOpenState } from "@toolkit/core";
import React, { FC } from "react";
import { Collapse, ListItem, ListItemAvatar, ListItemText, SxProps, Typography, useTheme } from "../base/mui";
import { ArrowDown, ArrowUp } from "../icons";

export const Expandable: FC<{
  headerTitle: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
  titleSx?: SxProps;
}> = ({ children, headerTitle, defaultOpen, titleSx }) => {
  const { open, handleToggle } = useOpenState(defaultOpen);
  const theme = useTheme();
  return (
    <>
      <ListItem onClick={handleToggle}>
        <ListItemText>
          <Typography
            variant='h4'
            sx={{ mb: 2, fontSize: theme.mixins.fonts.fontSize.lg, fontWeight: theme.mixins.fonts.fontWeight.bold, ...titleSx }}
          >
            {headerTitle}
          </Typography>
        </ListItemText>
        <ListItemAvatar>{open ? <ArrowUp /> : <ArrowDown />}</ListItemAvatar>
      </ListItem>
      <Collapse in={open} title='test'>
        {children}
      </Collapse>
    </>
  );
};

Expandable.defaultProps = {
  defaultOpen: false,
};
