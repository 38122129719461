import { getMaxLengthValidation, getMinLengthValidation, getPatternValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useForm } from "react-hook-form";

type FormData = { natId: string };
interface SearchByNatIdFormProps {
  onSearchByNatId: (natId: string) => void;
}
export const SearchByNatIdForm: FC<SearchByNatIdFormProps> = ({ onSearchByNatId }) => {
  const { t } = useTranslation("provider");
  const methods = useForm<FormData>({
    mode: "all",
  });

  const error = methods.formState.errors.natId?.message;
  const handleSubmit = ({ natId }: FormData) => {
    if (!natId) return;
    onSearchByNatId(natId);
  };

  const handleSearchClicked = () => {
    methods.handleSubmit(handleSubmit)();
  };

  return (
    <>
      <TextField
        fullWidth
        type='text'
        {...methods.register("natId", {
          required: getRequiredValidation(t, true),
          pattern: getPatternValidation(t, /^\d{10,12}$/g),
          maxLength: getMaxLengthValidation(t, 12),
          minLength: getMinLengthValidation(t, 10),
        })}
        inputProps={{
          maxLength: 12,
        }}
        size='small'
        label={t("National ID")}
        error={!!error}
        helperText={error}
      />
      <Button onClick={handleSearchClicked} sx={{ marginTop: "16px" }}>
        {t("Search")}
      </Button>
    </>
  );
};
