import { CircularProgress } from "@toolkit/ui";
import { useProfileContext } from "../../context/ProfileContext";
import { PatientInfo } from "./PatientInfo";

export const Overview = () => {
  const { loading } = useProfileContext();

  return loading ? (
    <CircularProgress
      size={80}
      sx={{
        color: "linear-gradient(to right, #882558, #25287F)",
      }}
    />
  ) : (
    <PatientInfo />
  );
};
