import { PatientHealthParameterInput, VisitStatus } from "@health/queries/types";
import { IVitalSignsUpdateFormValues } from "pages/e-visit/VitalSigns/forms/VitalSignUpdate/VitalSignUpdateFormSchema";
import { healthParameterCodes } from "../../../PatientHealthParameters/constants";

export const isVisitDetailsEnabled = (status: VisitStatus) => {
  return (
    status === VisitStatus.Completed ||
    status === VisitStatus.CallInProgress ||
    status === VisitStatus.Incompleted ||
    status === VisitStatus.CallRejoinable
  );
};

export const convertVisitVitalSignsFormValuesToBackEndValues = (values: IVitalSignsUpdateFormValues): PatientHealthParameterInput[] => {
  const patientHealthParameters =
    values?.items
      ?.filter(item => item?.value && item?.code !== `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`)
      ?.map(item => ({
        hpCode: item?.code!,
        valueNumber: item?.value,
      })) || [];

  const bloodPressure = values?.items?.find(
    item => item?.code === `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`
  );

  const patientHealthParametersBloodPressures: PatientHealthParameterInput[] = [];

  if (bloodPressure?.value) {
    const [highValue, lowValue] = (bloodPressure.value as string).split("/");

    if (highValue && lowValue) {
      patientHealthParametersBloodPressures.push(
        {
          hpCode: healthParameterCodes?.bloodPressureHigh,
          valueNumber: Number(highValue),
        },
        {
          hpCode: healthParameterCodes?.bloodPressureLow,
          valueNumber: Number(lowValue),
        }
      );
    }
  }

  return [...patientHealthParameters, ...patientHealthParametersBloodPressures];
};
