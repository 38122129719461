import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, TruncateTypography } from "@toolkit/ui";
import { FC } from "react";
import { useVisitContentStyle } from "../VisitContent.styles";

export const TerminatedView: FC<{
  doctorFullName?: string | null | undefined;
  patientFullName?: string | null | undefined;
}> = ({ doctorFullName, patientFullName }) => {
  const { classes } = useVisitContentStyle({});
  const { t } = useTranslation("provider");
  const DoctorName = <TruncateTypography className={classes?.nameInDescription} text={doctorFullName!} />;
  const PatientName = <TruncateTypography className={classes?.nameInDescription} text={patientFullName!} />;

  return (
    <>
      <Box className={classes.visitDescription}>
        <Trans t={t} i18nKey='call-notification-terminate-message' components={{ 1: DoctorName, 2: PatientName }}>
          Dear Dr. {{ DoctorName }}, it seems that call with: {{ PatientName }} has been terminated.
        </Trans>
      </Box>
    </>
  );
};
