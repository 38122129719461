import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import React, { useState } from "react";
import { useDoctorVisitRejectMutation, useVisitRejectionReasonsQuery } from "../../gql";

export const useRejectedModelHook = ({ id, onClose }: { id: string; onClose?: () => void }) => {
  const { succeeded, failed } = useAddToast();
  const [state, setState] = useState({ reason: "", other: "", isOther: false });
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("provider");
  const { data, loading: reasonsLoading } = useVisitRejectionReasonsQuery({
    skip: !isOpen,
  });
  const reasons = data?.rejectionReasons?.length ? data?.rejectionReasons : [{ id: "1", text: "Other", text_ar: t("Other") }];

  const [doctorVisitRejectMutation, { loading: isSubmitting }] = useDoctorVisitRejectMutation({
    onCompleted: data => {
      const errors = data?.doctorVisitReject?.visitErrors;
      if (errors?.length === 0) {
        succeeded(t("Rejected Successfully"));
        onClose?.();
        handleToggleDialog();
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleToggleDialog = () => {
    setIsOpen(!isOpen);
  };
  const handleToggle = e => {
    e.stopPropagation();
    handleToggleDialog();
  };
  const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "Other") {
      setState({ ...state, isOther: true, reason: e.target.value });
    } else {
      setState({ isOther: false, reason: e.target.value, other: "" });
    }
  };
  const handleChangeOtherReason = e => {
    setState({ ...state, other: e.target.value, isOther: true });
  };
  const handleSubmit = () => {
    doctorVisitRejectMutation({
      variables: {
        input: {
          visit: id,
          reason: state.isOther ? state.other : state.reason,
        },
      },
    });
  };
  return {
    isSubmitting,
    handleSubmit,
    handleChangeOtherReason,
    handleChangeReason,
    handleToggle,
    state,
    isOpen,
    reasons,
    reasonsLoading,
    handleToggleDialog,
  };
};
