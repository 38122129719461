import { appointmentTypeOptionsMap } from "@health/enum-options";
import { i18n, useTranslation } from "@toolkit/i18n";
import { DocumentIcon, Grid, Typography, UserIcon, format } from "@toolkit/ui";
import moment from "moment";
import { getRemainingTimeText } from "pages/dashboard/utils";
import { FC, useEffect, useRef, useState } from "react";
import { ellipseName } from "utils";
import { EventCardProps } from "../types";
import { useStyles } from "./EventCard.styles";

export const EventCard: FC<EventCardProps> = ({ careType, eventDate, patientName, doctorName, isAdmin, cardActions, cardWidth }) => {
  const [remainingTimeText, setRemainingTimeText] = useState(getRemainingTimeText(eventDate) ?? "00:00:00");
  const interval = useRef<any>();
  const { t } = useTranslation("provider");
  const { classes, cx, theme } = useStyles({ cardWidth });
  const isThereCardActions = !!cardActions;

  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      setRemainingTimeText(getRemainingTimeText(eventDate));
    }, 1000);
  }, [eventDate]);

  const eventMoment = moment(eventDate);

  return (
    <Grid container className={cx(classes.root, "card")} direction='row' justifyContent='center' alignItems='center' padding={2}>
      <Grid item xs={2} container alignItems='center' justifyContent='center' flexDirection='column'>
        <Typography variant='subtitle1' fontSize={45} fontWeight={theme.mixins.fonts.fontWeight.medium} sx={{ lineHeight: 1 }}>
          {format(eventMoment.toString(), "DD", i18n.language)}
        </Typography>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.xxl} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {format(eventMoment.toString(), "MMM", i18n.language)}
        </Typography>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {format(eventMoment.toString(), "hh:mm A", i18n.language)}
        </Typography>
      </Grid>
      <Grid item xs={5} sx={{ textAlign: "start" }} container gap={isThereCardActions ? 2.5 : 1.5}>
        {isAdmin && (
          <Grid item xs={12} gap={1} container sx={{ width: "max-content" }}>
            <Grid item>
              <UserIcon fill={theme.palette.common.white} sx={{ fontSize: theme.mixins.fonts.fontSize.sm }} />
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle1'
                fontSize={theme.mixins.fonts.fontSize.md}
                fontWeight={theme.mixins.fonts.fontWeight.medium}
                sx={{ lineHeight: 1 }}
              >
                {t("Doctor Name")}
              </Typography>
              <Typography
                variant='subtitle1'
                fontSize={theme.mixins.fonts.fontSize.sm}
                fontWeight={theme.mixins.fonts.fontWeight.regular}
                sx={{ lineHeight: 1 }}
              >
                {ellipseName(doctorName, 25)}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} container gap={1} sx={{ minWidth: "max-content" }}>
          <Grid item>
            <UserIcon fill={theme.palette.common.white} sx={{ fontSize: theme.mixins.fonts.fontSize.sm }} />
          </Grid>
          <Grid item>
            <Typography
              variant='subtitle1'
              fontSize={theme.mixins.fonts.fontSize.md}
              fontWeight={theme.mixins.fonts.fontWeight.medium}
              sx={{ lineHeight: 1 }}
              mb={0.5}
            >
              {t("Patient Name")}
            </Typography>
            <Typography
              variant='subtitle1'
              fontSize={theme.mixins.fonts.fontSize.sm}
              fontWeight={theme.mixins.fonts.fontWeight.regular}
              sx={{ lineHeight: 1 }}
            >
              {ellipseName(patientName, 25)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container gap={1}>
          <Grid item>
            <DocumentIcon sx={{ fontSize: theme.mixins.fonts.fontSize.sm }} fill={theme.palette.common.white} />
          </Grid>
          <Grid item>
            <Typography
              variant='subtitle1'
              fontSize={theme.mixins.fonts.fontSize.md}
              fontWeight={theme.mixins.fonts.fontWeight.medium}
              sx={{ lineHeight: 1 }}
              mb={0.5}
            >
              {t("Appointment Type")}
            </Typography>
            <Typography
              variant='subtitle1'
              fontSize={theme.mixins.fonts.fontSize.sm}
              fontWeight={theme.mixins.fonts.fontWeight.regular}
              sx={{ lineHeight: 1 }}
            >
              {appointmentTypeOptionsMap[careType].label}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={isThereCardActions ? 1 : 5} container justifyContent='center'>
        <Typography variant='subtitle1' fontSize={30} fontWeight={theme.mixins.fonts.fontWeight.semiBold} sx={{ lineHeight: 1 }}>
          {remainingTimeText}
        </Typography>
      </Grid>
      {isThereCardActions && (
        <Grid item xs={4} container justifyContent={"end"}>
          {cardActions}
        </Grid>
      )}
    </Grid>
  );
};
