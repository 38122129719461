import { PatientHealthParameterChart } from "../PatientHealthParameterChart/PatientHealthParameterChart";
import React, { FC } from "react";
import { Card, SkeletonCard } from "@toolkit/ui";
import { PatientHealthParameterHistoryModalApi } from "../../modals";
import {
  IPatientHealthParameterVitalSignCardInformationEvent,
  PatientHealthParameterVitalSignCardInformation,
} from "../PatientHealthParameterVitalSignCardInformation/PatientHealthParameterVitalSignCardInformation";
import { usePatientHealthParametersNumber } from "../../hooks";

type PatientHealthParameterVitalSignCardProps = {
  patientId: string;
  token: string;
  codes: string[];
  title: string;
  changePercentage?: string;
};

export const PatientHealthParameterVitalSignCard: FC<PatientHealthParameterVitalSignCardProps> = props => {
  const { patientId, token, codes, title, changePercentage } = props;

  const { chartData, value, date, source, unit, chartType, loading } = usePatientHealthParametersNumber({
    patientId,
    token,
    codes,
  });

  const onPatientHealthParameterVitalSignCardInformationChange = (event: IPatientHealthParameterVitalSignCardInformationEvent) => {
    if (event.type === "EXPAND_CLICK") {
      PatientHealthParameterHistoryModalApi.open({
        type: "NUMBER",
        title,
        patientId,
        token,
        codes,
      });
    }
  };

  return (
    <>
      {loading ? (
        <SkeletonCard height={200} />
      ) : (
        <Card>
          <PatientHealthParameterVitalSignCardInformation
            title={title}
            value={value}
            date={date}
            unit={unit}
            source={source}
            changePercentage={changePercentage}
            isExpandIconShown={!!chartData?.length}
            onChange={onPatientHealthParameterVitalSignCardInformationChange}
          />

          <PatientHealthParameterChart chartType={chartType!} chartData={chartData} unit={unit} isLoading={loading} />
        </Card>
      )}
    </>
  );
};
