import { useTranslation } from "@toolkit/i18n";
import { HealthCheckupIcon, LabIcon, NoteIcon, RestartIcon, RxIcon, useTheme, XrayIcon } from "@toolkit/ui";
import { DiagnosisPage } from "pages/e-visit/Diagnosis/DiagnosisPage";
import { LabTestsPage } from "pages/e-visit/LabTests/LabTestsPage";
import { VisitNotePage } from "pages/e-visit/Notes/VisitNotePage";
import { PrescriptionPage } from "pages/e-visit/Prescription/PrescriptionPage";
import RadiologyPage from "pages/e-visit/Radiology/RadiologyPage";
import { Referrals } from "pages/e-visit/Referrals/Referral";
import { VitalSignUpdateContainer } from "pages/e-visit/VitalSigns/containers/VitalSignUpdate/VitalSignUpdateContainer";
import { VisitDetailTab } from "pages/e-visit/VitalSigns/types";
import { useMemo } from "react";

type VisitDetailsItemsProps = {
  visitId?: string;
  isHidden?: boolean;
  handleVisitNoteChanged?: (notes: string) => void;
};

export const useVisitDetailsDialogItems = (props: VisitDetailsItemsProps) => {
  const { visitId, isHidden, handleVisitNoteChanged } = props;

  const { t } = useTranslation("provider");
  const theme = useTheme();
  return useMemo(
    () => [
      {
        id: VisitDetailTab.VitalSigns,
        name: t("Vital Signs"),
        icon: <HealthCheckupIcon sx={{ color: theme.palette.primary.main }} />,
        component: <VitalSignUpdateContainer visitId={visitId!} />,
      },
      {
        id: VisitDetailTab.Notes,
        name: t("Notes"),
        icon: <NoteIcon sx={{ color: theme.palette.primary.main }} />,
        component: <VisitNotePage visitId={visitId!} onVisitNoteChanged={handleVisitNoteChanged} />,
      },
      {
        id: VisitDetailTab.Diagnosis,
        name: t("Diagnosis"),
        icon: <HealthCheckupIcon sx={{ color: theme.palette.primary.main }} />,
        component: <DiagnosisPage id={visitId!} />,
      },
      {
        id: VisitDetailTab.Medications,
        name: t("Medications"),
        icon: <RxIcon sx={{ color: theme.palette.primary.main }} />,
        component: <PrescriptionPage visitId={visitId!} />,
      },
      {
        id: VisitDetailTab.LabTests,
        name: t("Lab test"),
        icon: <LabIcon sx={{ color: theme.palette.primary.main }} />,
        component: <LabTestsPage id={visitId!} />,
      },
      {
        id: VisitDetailTab.Radiology,
        name: t("Radiology"),
        icon: <XrayIcon sx={{ color: theme.palette.primary.main }} />,
        component: <RadiologyPage />,
      },
      {
        id: VisitDetailTab.Referrals,
        name: t("Referrals"),
        icon: <RestartIcon sx={{ color: theme.palette.primary.main }} />,
        component: !isHidden && <Referrals visitId={visitId!} />,
      },
    ],
    [visitId, isHidden]
  );
};
