/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type HealthProgramMembersFragmentFragment = { __typename?: 'HealthProgramMember', id: string, firstName?: string | null, lastName?: string | null, dateOfBirth?: any | null, gender?: Types.H_Gender | null, insuranceCompanyId?: string | null, insuranceId?: string | null, insuranceCompanyLicenseNumber?: string | null, membershipEnd?: any | null, user?: { __typename?: 'User', id: string, nationalId?: string | null, photo?: string | null } | null };

export const HealthProgramMembersFragmentFragmentDoc = gql`
    fragment healthProgramMembersFragment on HealthProgramMember {
  id
  firstName
  lastName
  dateOfBirth
  gender
  insuranceCompanyId
  insuranceId
  insuranceCompanyLicenseNumber
  membershipEnd
  user {
    id
    nationalId
    photo
  }
}
    `;