import { MuiDrawer, useMediaQuery } from "@toolkit/ui";
import { FC } from "react";
import { useProfileContext } from "../context/ProfileContext";
import { PatientProfileComponent } from "./PatientProfile.screen";

export const PatientProfileDrawer: FC = () => {
  const { isOpen, onClose: handleToggleDrawer } = useProfileContext();
  const isMobile = useMediaQuery("(max-width:1600px)");

  return (
    <MuiDrawer
      anchor='right'
      open={isOpen}
      onClose={handleToggleDrawer}
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : "80%",
        },
      }}
    >
      <PatientProfileComponent />
    </MuiDrawer>
  );
};
