import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
export const useMedicalFormClassificationsTableColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "label",
                header: t("Label"),
                accessor: ({ name }) => name,
            },
            {
                key: "scoreValueRange",
                header: t("Score Value Range"),
                accessor: ({ rangeFrom, rangeTo }) => `${rangeFrom} - ${rangeTo}`,
            },
        ];
    }, [t]);
};
