import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, IconButton, Tooltip } from "@toolkit/ui";
export const EditButtons = ({ handleEditClick, agentId }) => {
    const { t } = useTranslation("domains");
    return (_jsx(Tooltip, { title: t("Edit Agent"), children: _jsx(IconButton, { onClick: () => handleEditClick(agentId), sx: {
                "& svg": {
                    width: 30,
                    height: 30,
                    marginTop: 0.5,
                    padding: 0,
                },
            }, children: _jsx(CustomIcon, { icon: 'edit' }) }) }));
};
