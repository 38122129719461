import useSelectPeerFromInvitationLink from "@meetora/react/sehacity/Chat/useSelectPeerFromInvitationLink";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useGetChatLinkMutation } from "./gql";

export const useChatHooks = () => {
  const [link, setLink] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const auth = useAuth();
  const { peer } = useSelectPeerFromInvitationLink(link);

  const authenticated = auth.isAuthenticated || false;

  const { failed } = useAddToast();

  const [getChatLinkMutation, { loading: isGetChatLinkLoading }] = useGetChatLinkMutation({
    onCompleted: async response => {
      const error = response?.saveOrFetchExistedAnonymousChatGroup?.error;
      if (error?.length) {
        failed(error);
      } else {
        const chatLink = response?.saveOrFetchExistedAnonymousChatGroup?.anonymousChatLinkForChatView;
        if (chatLink && chatLink?.length > 0) {
          setLink(chatLink);
          setOpen(true);
        }
      }
    },
  });

  const startChat = (userId: string) => {
    if (authenticated) {
      userId &&
        getChatLinkMutation({
          variables: {
            userIds: [userId],
          },
        });
    } else {
      auth.signinRedirect();
    }
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return { startChat, peer, open, handleToggle, isGetChatLinkLoading };
};
