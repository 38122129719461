import { Department, VendorDepartmentError } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { Autocomplete, AutocompleteController, Chip, Grid, TextField } from "@toolkit/ui";
import { FC, Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDepartmentInfoHook } from "./DepartmentInfo";
import { departmentFields } from "./DepartmentInfo.utils";

export const DepartmentInformation: FC<{ defaultValues?: Department; errors: VendorDepartmentError[] }> = ({ defaultValues, errors }) => {
  const {
    control,
    register,
    formState: { errors: formErrors },
  } = useFormContext();
  const { t } = useTranslation("provider");
  const combinedErrors = combineErrors(formErrors, errors);
  const { branches, hasMoreBranches, loadingBranch, onFetchMoreDataForBranches } = useDepartmentInfoHook();
  const branchValue = branches?.find(item => item?.id === defaultValues?.branch?.id);
  return (
    <Grid container spacing={formGirdSpacing}>
      {departmentFields(t, defaultValues)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item?.name}>
          <TextField
            fullWidth
            error={Boolean(combinedErrors?.[item?.name]?.message)}
            helperText={t(combinedErrors?.[item?.name]?.message)}
            defaultValue={item?.defaultValue}
            label={item?.label}
            placeholder={item?.label}
            {...register(item?.name, {
              required: getRequiredValidation(t, !!item?.isRequired),
              minLength: item?.minLength,
              maxLength: item?.maxLength,
            })}
            rows={item?.rows}
            multiline={item?.multiline}
          />
        </Grid>
      ))}

      <Grid item {...formGirdBreakPoints}>
        <AutocompleteController
          ControllerProps={{
            name: "branch",
            control: control,
            defaultValue: branchValue,
            rules: { required: getRequiredValidation(t, true) },
          }}
          TextFieldProps={{
            error: Boolean(combinedErrors?.branch?.message),
            helperText: combinedErrors?.branch?.message,
            label: t("Branch"),
          }}
          disabled={!!defaultValues?.branch}
          hasMore={hasMoreBranches}
          loading={loadingBranch}
          fetchMoreData={onFetchMoreDataForBranches}
          getOptionLabel={option => (i18n?.language == "ar" && option?.nameAr ? option?.nameAr : option?.name)}
          options={branches || []}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Controller
          control={control}
          name='medicalServices'
          rules={{ required: false }}
          render={({ field: { onChange: handleChange } }) => (
            <Autocomplete
              multiple
              defaultValue={defaultValues?.medicalServices?.edges.map(edge => edge.node.code) ?? []}
              options={[]}
              freeSolo
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Fragment key={index}>
                    <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                  </Fragment>
                ))
              }
              // eslint-disable-next-line react/jsx-handler-names
              onChange={(_, values) => {
                handleChange(values);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t("Medical Services")}
                  placeholder={t("Medical Services")}
                  helperText={t(combinedErrors?.medicalServices?.message)}
                  error={Boolean(combinedErrors?.medicalServices?.message)}
                />
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

DepartmentInformation.defaultProps = {
  defaultValues: undefined,
};
