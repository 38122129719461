/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveDoctorNurseAssignmentMutationVariables = Types.Exact<{
  input: Array<Types.DoctorNurseAssignmentInput> | Types.DoctorNurseAssignmentInput;
}>;


export type SaveDoctorNurseAssignmentMutation = { __typename?: 'Mutation', saveDoctorNurseAssignment?: Array<{ __typename?: 'DoctorNurseAssignment', id: string } | null> | null };


export const SaveDoctorNurseAssignmentDocument = gql`
    mutation SaveDoctorNurseAssignment($input: [DoctorNurseAssignmentInput!]!) {
  saveDoctorNurseAssignment(input: $input) {
    id
  }
}
    `;
export type SaveDoctorNurseAssignmentMutationFn = Apollo.MutationFunction<SaveDoctorNurseAssignmentMutation, SaveDoctorNurseAssignmentMutationVariables>;

/**
 * __useSaveDoctorNurseAssignmentMutation__
 *
 * To run a mutation, you first call `useSaveDoctorNurseAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDoctorNurseAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDoctorNurseAssignmentMutation, { data, loading, error }] = useSaveDoctorNurseAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDoctorNurseAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<SaveDoctorNurseAssignmentMutation, SaveDoctorNurseAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDoctorNurseAssignmentMutation, SaveDoctorNurseAssignmentMutationVariables>(SaveDoctorNurseAssignmentDocument, options);
      }
export type SaveDoctorNurseAssignmentMutationHookResult = ReturnType<typeof useSaveDoctorNurseAssignmentMutation>;
export type SaveDoctorNurseAssignmentMutationResult = Apollo.MutationResult<SaveDoctorNurseAssignmentMutation>;
export type SaveDoctorNurseAssignmentMutationOptions = Apollo.BaseMutationOptions<SaveDoctorNurseAssignmentMutation, SaveDoctorNurseAssignmentMutationVariables>;