import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyAllInterventionsStyle = makeStyles()(theme => ({
  textFieldWrapper: {
    marginBottom: 16,
  },
  textField: {
    "& .MuiFilledInput-input": {
      color: theme.palette.primary.main,
      "&::placeholder": {
        color: theme.palette.primary.main,
      },
      "&:focus": {
        background: "transparent",
      },
      "&:active": {
        background: "transparent",
      },
    },
  },
  inputProps: {
    borderRadius: 10,
    color: theme.palette.primary.main,
    "& .MuiSvgIcon-root path": {
      fill: theme.palette.primary.main,
    },
  },
  searchIcon: {
    marginInline: 8,
  },
}));
