import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";
export const PhoneConnecting: FC<IconProps> = ({ width = 87, height = 87, viewBox = "0 0 87 87", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <g id='Group_6601' data-name='Group 6601' transform='translate(0 0.219)'>
        <g id='Path_4441' data-name='Path 4441' transform='translate(0 -0.219)' fill='#e67e22' opacity='0.149'>
          <path d='M43.5,0A43.5,43.5,0,1,1,0,43.5,43.5,43.5,0,0,1,43.5,0Z' stroke='none' />
          <path
            d='M 43.5 2 C 37.89664840698242 2 32.46197509765625 3.096977233886719 27.34693908691406 5.260452270507812 C 22.40538787841797 7.350547790527344 17.96700286865234 10.3431396484375 14.15505981445312 14.15505981445312 C 10.3431396484375 17.96700286865234 7.350547790527344 22.40538787841797 5.260452270507812 27.34693908691406 C 3.096977233886719 32.46197509765625 2 37.89664840698242 2 43.5 C 2 49.10335159301758 3.096977233886719 54.53802490234375 5.260452270507812 59.65306091308594 C 7.350547790527344 64.59461212158203 10.3431396484375 69.03300476074219 14.15505981445312 72.84494018554688 C 17.96700286865234 76.6568603515625 22.40538787841797 79.64945220947266 27.34693908691406 81.73954772949219 C 32.46197509765625 83.90302276611328 37.89664840698242 85 43.5 85 C 49.10335159301758 85 54.53802490234375 83.90302276611328 59.65306091308594 81.73954772949219 C 64.59461212158203 79.64945220947266 69.03300476074219 76.6568603515625 72.84494018554688 72.84494018554688 C 76.6568603515625 69.03300476074219 79.64945220947266 64.59461212158203 81.73954772949219 59.65306091308594 C 83.90302276611328 54.53802490234375 85 49.10335159301758 85 43.5 C 85 37.89664840698242 83.90302276611328 32.46197509765625 81.73954772949219 27.34693908691406 C 79.64945220947266 22.40538787841797 76.6568603515625 17.96700286865234 72.84494018554688 14.15505981445312 C 69.03300476074219 10.3431396484375 64.59461212158203 7.350547790527344 59.65306091308594 5.260452270507812 C 54.53802490234375 3.096977233886719 49.10335159301758 2 43.5 2 M 43.5 0 C 67.52438354492188 0 87 19.47560882568359 87 43.5 C 87 67.52438354492188 67.52438354492188 87 43.5 87 C 19.47560882568359 87 0 67.52438354492188 0 43.5 C 0 19.47560882568359 19.47560882568359 0 43.5 0 Z'
            stroke='none'
            fill='rgba(255,255,255,0.2)'
          />
        </g>
        <g id='Path_4442' data-name='Path 4442' transform='translate(8.878 8.659)' fill='#e67e22'>
          <path d='M34.622,0A34.622,34.622,0,1,1,0,34.622,34.622,34.622,0,0,1,34.622,0Z' stroke='none' />
          <path
            d='M 34.6224479675293 6.999996185302734 C 19.39138031005859 6.999996185302734 6.999996185302734 19.39138031005859 6.999996185302734 34.6224479675293 C 6.999996185302734 49.853515625 19.39138031005859 62.24489593505859 34.6224479675293 62.24489593505859 C 49.853515625 62.24489593505859 62.24489593505859 49.853515625 62.24489593505859 34.6224479675293 C 62.24489593505859 19.39138031005859 49.853515625 6.999996185302734 34.6224479675293 6.999996185302734 M 34.6224479675293 0 C 53.743896484375 0 69.24489593505859 15.50099945068359 69.24489593505859 34.6224479675293 C 69.24489593505859 53.743896484375 53.743896484375 69.24489593505859 34.6224479675293 69.24489593505859 C 15.50099945068359 69.24489593505859 0 53.743896484375 0 34.6224479675293 C 0 15.50099945068359 15.50099945068359 0 34.6224479675293 0 Z'
            stroke='none'
            fill='rgba(255,255,255,0.68)'
          />
        </g>
        <path
          id='icons8-ringer-volume'
          d='M17.1,3a1.092,1.092,0,1,0,0,2.184,9.81,9.81,0,0,1,9.827,9.827,1.092,1.092,0,1,0,2.184,0A12.027,12.027,0,0,0,17.1,3ZM8.082,5.188a1.3,1.3,0,0,0-.416.034,4.239,4.239,0,0,0-1.981,1.19c-2.307,2.307-3.531,6.2,5.144,14.873S23.393,28.737,25.7,26.429a4.242,4.242,0,0,0,1.192-1.985,1.283,1.283,0,0,0-.6-1.416l-5.122-3a1.282,1.282,0,0,0-1.305.006l-2.039,1.216a1.279,1.279,0,0,1-1.446-.085,33.444,33.444,0,0,1-2.9-2.531,33.071,33.071,0,0,1-2.531-2.9,1.277,1.277,0,0,1-.085-1.446l1.216-2.039a1.287,1.287,0,0,0,0-1.309L9.088,5.824A1.292,1.292,0,0,0,8.082,5.188ZM17.1,7.368a1.092,1.092,0,1,0,0,2.184,5.442,5.442,0,0,1,5.459,5.459,1.092,1.092,0,1,0,2.184,0A7.66,7.66,0,0,0,17.1,7.368Zm0,4.368a1.092,1.092,0,1,0,0,2.184,1.074,1.074,0,0,1,1.092,1.092,1.092,1.092,0,1,0,2.184,0A3.293,3.293,0,0,0,17.1,11.735Z'
          transform='translate(27.071 27.853)'
          fill='#fff'
        />
      </g>
    </SvgIcon>
  );
};
