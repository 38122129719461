import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceOrdersTrackingPaths, marketplaceTrackingOrdersRoute } from "./constants/MarketPlaceTrackingPaths";
import { ProviderMarketPlaceOrdersTrackingContainer } from "./List/MarketPlaceOrdersTrackingContainer";

export const marketplaceOrdersTrackingRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({
  navigate,
  t,
}): RouteItem => {
  return {
    id: "marketplace-order-tracking-routes",
    text: t("Marketplace Orders Tracking", { ns: "provider" }),
    route: marketplaceTrackingOrdersRoute,
    fullPath: marketplaceOrdersTrackingPaths.mainPath.fullPath,
    element: <ProviderMarketPlaceOrdersTrackingContainer />,
    isProhibited: !hasPermission(PermissionEnum.ManageMarketplaceOrders),
    hidden: !hasPermission(PermissionEnum.ManageMarketplaceOrders),
    onClick: (route: string) => navigate(route),
  };
};
