import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, paths, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
// import { pharmacyUserPreferencesVar } from "shared/components";
import { useOrderPageContext } from "../state";
import { mapStatusToColor, mapStatusToName, ORDERS_STATUS } from "../statuses";
import { buttons } from "./buttons";
import { useOrdersTabStyles } from "./OrdersTab.styles";

export const OrdersTab: FC = () => {
  const { isDeliveringColumnVisible } = useOrderPageContext();

  const newTabs = buttons
    ?.map(item => (item.id === ORDERS_STATUS.DELIVERING ? { ...item, isVisible: isDeliveringColumnVisible } : { ...item, isVisible: true }))
    .filter(item => item.isVisible);
  const theme = useTheme();
  return (
    <Box
      display='flex'
      justifyContent='center'
      sx={{
        background: theme.palette.common.white,
        borderTop: `1px solid ${theme.palette.gray.light}`,
      }}
    >
      {newTabs.map(item => (
        <OrderTab key={item.id} item={item} />
      ))}
    </Box>
  );
};

const OrderTab: FC<{ item: { id: string; icon: string } }> = ({ item }) => {
  const { classes } = useOrdersTabStyles({ color: mapStatusToColor[item.id] });
  const { t } = useTranslation("provider");
  const name = t(mapStatusToName(item.id));
  // const pharmacyBranch = useReactiveVar(pharmacyUserPreferencesVar);

  const { selectedTab, onChangeSelectedTab, fetchDataByStatus, filter } = useOrderPageContext();

  useEffect(() => {
    fetchDataByStatus(item.id, filter);
  }, [item.id, filter]);

  const handleChangeSelectedTab = status => () => {
    onChangeSelectedTab(status);
  };

  return (
    <Button
      className={selectedTab === item.id ? `${classes.button} ${classes.selected}` : classes.button}
      startIcon={
        <div className={classes.icon}>
          <CustomIcon icon={item.icon as keyof typeof paths} />
        </div>
      }
      variant='text'
      onClick={handleChangeSelectedTab(item?.id)}
    >
      {name}
    </Button>
  );
};
