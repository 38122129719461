import { makeStyles } from "@toolkit/ui";

export const usePatientGuidedCareProgramsStyle = makeStyles()(theme => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 150,
  },
  title: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  button: {
    height: "35px",
    color: theme.palette.primary.main,
    marginInline: 0,
    background: theme.palette.common.white,
    borderRadius: "25px",
    border: `1px solid ${theme.palette.background.default}`,
  },
  menu: {
    marginTop: "8px !important",
    borderRadius: "10px !important",
  },
  menuPaper: {
    borderRadius: "10px !important",
  },
  loadingText: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    padding: 16,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
