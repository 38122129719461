import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomIcon, Menu, MenuItem, Typography } from "@toolkit/ui";
import { memo } from "react";
import { useDoctorAvailabilityStatus } from "./DoctorAvailabilityStatus.hooks";
import { useStyles } from "./doctorAvailabilityStatus.styles";

const DoctorAvailabilityStatus = () => {
  const { classes } = useStyles();
  const { t } = useTranslation("provider");
  const {
    open,
    isOpen,
    selectedStatus,
    loading,
    AvailabilityStatusValueList,
    handleClick,
    handleClose,
    handleUpdateDoctorAvailabilityStatus,
  } = useDoctorAvailabilityStatus();

  return (
    <Box sx={{ marginInlineEnd: 2 }}>
      <Button
        id='doctor-status-menu-button'
        aria-controls={open ? "doctor-status-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        variant='outlined'
        disableElevation
        onClick={handleClick}
        endIcon={<CustomIcon icon='expandArrow' viewBox='4 1 20 20' color='inherit' />}
        disabled={loading}
        className={`${classes.doctorCommonButton} ${selectedStatus?.className}`}
      >
        <Typography display={"flex"} justifyContent='center' alignItems={"center"}>
          {loading ? (
            <>
              <CircularProgress size={20} /> {t("loading ..")}
            </>
          ) : (
            <>
              <span className={classes.iconMargin}>{selectedStatus?.icon}</span>
              <span>{selectedStatus?.label}</span>
            </>
          )}
        </Typography>
      </Button>

      <Menu
        id='doctor-status-menu'
        anchorEl={isOpen}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "doctor-status-menu-button",
        }}
        classes={{
          paper: classes.doctorStatusMenu,
        }}
      >
        {AvailabilityStatusValueList?.map(item => (
          <MenuItem key={item?.id} onClick={handleClose} className={classes.menuItem}>
            <Button onClick={handleUpdateDoctorAvailabilityStatus(item)} className={item?.className} startIcon={item?.icon}>
              {item?.label}
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default memo(DoctorAvailabilityStatus);
