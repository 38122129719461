import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { UserInfoType } from "shared/configs/oidc";
import { fetchFreshAccessToken } from "shared/utils/tokenUtils";

interface AuthContextType {
  accessToken?: string;
  refreshToken?: string;
  sseToken?: string;
  userInfo: UserInfoType;
}

const AuthUserProfileContext = createContext<AuthContextType | undefined>(undefined);

export const AuthUserProfileProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const [sseToken, setSseToken] = useState<string>(auth?.user?.access_token!);

  const userInfo = auth.user?.profile! as unknown as UserInfoType;
  const tokenAsQuery = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    if (!auth?.user?.access_token || !auth?.user?.refresh_token) return;
    fetchFreshAccessToken(auth?.user?.access_token, auth?.user?.refresh_token)
      .then(nextAccessToken => {
        setSseToken(nextAccessToken);
      })
      .catch(error => {
        console.error("Failed to get refresh token.", error);
      });
  }, [auth?.user?.access_token, auth?.user?.refresh_token]);

  const value = {
    accessToken: tokenAsQuery || auth?.user?.access_token,
    refreshToken: auth?.user?.refresh_token,
    sseToken: sseToken ?? (tokenAsQuery || undefined),
    userInfo,
  };

  return <AuthUserProfileContext.Provider value={value}>{children}</AuthUserProfileContext.Provider>;
};

export const useAuthUserProfileContext = () => {
  const context = useContext(AuthUserProfileContext);
  if (context === undefined) {
    throw new Error("useAuthUserProfileContext must be used within an AuthUserProfileProvider");
  }
  return context;
};
