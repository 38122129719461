import { OrderStatus, Patient } from "@health/queries/types";
import { getPatientAge } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";
import { FC } from "react";

type BeneficiaryInfoProps = {
  patient: Patient;
  status: string;
};

const useBeneficiaryInfoStyles = makeStyles()(() => ({
  list: {
    margin: 0,
    marginInline: 12,
    padding: 0,
    fontSize: 13,
  },
}));

export const BeneficiaryInfo: FC<BeneficiaryInfoProps> = props => {
  const { patient, status } = props;
  const { t } = useTranslation("provider");
  const { classes } = useBeneficiaryInfoStyles();

  const gender = patient?.gender === "M" || patient?.gender === "Male" || patient?.gender === "MALE" ? t("Male") : t("Female");

  return (
    <ul className={classes.list}>
      {status !== OrderStatus.NewRequest && status !== OrderStatus.AcceptedByProvider && (
        <>
          {patient?.firstName && (
            <li>
              {t("Name")} : {patient?.firstName + " " + patient?.lastName}
            </li>
          )}
        </>
      )}
      {patient?.dateOfBirth || patient?.gender ? (
        <>
          {patient?.dateOfBirth && (
            <li>
              {t("Age")}: {getPatientAge(patient?.dateOfBirth, t)}
            </li>
          )}
          {patient?.gender && (
            <li>
              {t("Gender")}: {gender}
            </li>
          )}
        </>
      ) : (
        <>-</>
      )}
    </ul>
  );
};
