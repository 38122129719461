import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { MarketplaceApprovalStatus, MarketplaceProductSortingField, MarketplaceSortDirection } from "@health/queries/types";
import { productsBreadcrumb } from "../../constants/ProductsBreadcrumbs";
import { useMarketplaceProductsQuery } from "../../gql/queries";
import { useProductsListTableColumns } from "./useProductListTableColumns";
import { productsPaths } from "../../constants/ProductsPaths";
import { useNavigate } from "react-router-dom";
import { ProductsQueryNode } from "pages/MarketplaceProducts/types";
import { DiscountModal } from "pages/MarketplaceProducts/components/CreateDiscount";

export const ProductsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const onEditRowClick = (item: ProductsQueryNode) => {
    navigate(productsPaths.update.fullPathWithParams({ productId: item?.id }));
  };
  const onAddNewItemClick = () => {
    navigate(productsPaths.new.fullPath);
  };
  useEffect(() => {
    setBreadCrumb({
      title: productsBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName='marketplace-Products'
      query={useMarketplaceProductsQuery}
      columns={useProductsListTableColumns()}
      hasTableSetting
      variables={{
        sortBy: {
          field: MarketplaceProductSortingField.Created,
          direction: MarketplaceSortDirection.Desc,
        },
      }}
      tableAction={{
        isEditVisible: true,
        isRowEditable: row =>
          !!row?.updatable &&
          (row?.approvalStatus === MarketplaceApprovalStatus.Draft || row?.approvalStatus === MarketplaceApprovalStatus.Rejected),
        onEditRow: onEditRowClick,
      }}
      selectionProps={{
        isSelectVisible: true,
        isRowSelectable: row => row?.approvalStatus === MarketplaceApprovalStatus.Approved,
        rowId: row => row?.id,
        formatSelectedRow: row => ({
          name: row?.name,
          id: row?.id,
          nameAr: row?.nameAr,
          price: row?.price,
          activeDiscount: row?.activeDiscount,
          mainImageUrl: row?.mainImageUrl,
        }),
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <DiscountModal />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
