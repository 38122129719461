import { Box, TabLayout, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useProfileContext } from "../context/ProfileContext";
import { PatientInformation } from "./PatientInformation";
import { ProfileContentTabs } from "./ProfileContentTabs";

export const PatientProfileComponent: FC = () => {
  const theme = useTheme();

  const { selectedTab, handleTabChange } = useProfileContext();

  return (
    <>
      <Box
        sx={{
          background: theme.palette.common.white,
        }}
      >
        <Box
          sx={{
            margin: "auto",
            maxWidth: 1400,
          }}
        >
          <PatientInformation />
        </Box>
      </Box>

      <Box bgcolor={theme.palette.common.white}>
        <TabLayout
          items={ProfileContentTabs()}
          marginLeft='inherit'
          isRoot={false}
          selectedValue={selectedTab}
          onTabChange={handleTabChange}
        />
      </Box>
    </>
  );
};
