const RadiologyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.495 22.495' width={22.495} height={22.495} {...props}>
      <path
        d='M2.045 0A2.044 2.044 0 0 0 0 2.045V20.45a2.044 2.044 0 0 0 2.045 2.05h18.4a2.044 2.044 0 0 0 2.045-2.045V2.045A2.044 2.044 0 0 0 20.45 0Zm9.2 2.045a1.023 1.023 0 0 1 1.025 1.023v9.2h2.045a3.068 3.068 0 0 1 0 6.135c-.368 0-1.022 2.045-3.067 2.045s-2.7-2.045-3.067-2.045a3.068 3.068 0 0 1 0-6.135h2.045v-2.043h-4.09a1.023 1.023 0 0 1 0-2.045h4.09V6.135H5.112a1.023 1.023 0 0 1 0-2.045h5.112V3.068a1.023 1.023 0 0 1 1.023-1.023Zm3.07 2.045H18.4a1.023 1.023 0 0 1 0 2.045h-4.09Zm0 4.09h3.067a1.023 1.023 0 0 1 0 2.045h-3.067ZM8.18 14.315a1.023 1.023 0 1 0 1.02 1.023 1.023 1.023 0 0 0-1.02-1.023Zm6.135 0a1.023 1.023 0 1 0 1.022 1.023 1.023 1.023 0 0 0-1.022-1.023Z'
        fill='#fff'
      />
    </svg>
  );
};

export default RadiologyIcon;
