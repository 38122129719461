import { Department } from "@health/queries/types";
import { FC } from "react";
import { DepartmentForm } from "../Form/DepartmentForm";
import { useDepartmentEditPageHook } from "./DepartmentEditPageHook";

export const DepartmentEditPage: FC = () => {
  const { department, errors, handleUpdateDepartment, isUpdateLoading } = useDepartmentEditPageHook();
  return (
    <DepartmentForm
      errors={errors}
      onDone={handleUpdateDepartment}
      isLoading={isUpdateLoading}
      defaultValues={department as Department}
      isEditMode={true}
    />
  );
};
