import { GridProvider } from "@health/domains";
import { GuidedCareJourneyItemFilter } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, IconButton, MuiMoreVertIcon, TableGrid } from "@toolkit/ui";
import { FC } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { useTimelineItemsQuery } from "../../../gql";
import { useUpcomingPatientCareActivitiesColumns } from "../UpcomingActivities/useUpcomingPatientCareActivitiesColumns";
import { useActivityItemsDialogStyles } from "./ActivityItemsDialog.style";

type ActivityItemsDialogProps = {
  filterInput?: GuidedCareJourneyItemFilter;
};
export const ActivityItemsDialog: FC<ActivityItemsDialogProps> = ({ filterInput }) => {
  const { t } = useTranslation("provider");
  const { classes } = useActivityItemsDialogStyles();
  const { token } = useProfileContext();

  return (
    <CustomDialog
      type='info'
      maxWidth='md'
      title={t("Journey Items")}
      DialogContentProps={{
        sx: { maxHeight: "100vh" },
      }}
      button={
        <IconButton className={classes.showMoreButtonIcon}>
          <Box mt={1} className={classes.moreIcon}>
            <MuiMoreVertIcon />
          </Box>
        </IconButton>
      }
    >
      <GridProvider
        gridName='guidedCareJourneyItems'
        columns={useUpcomingPatientCareActivitiesColumns({ upcoming: false })}
        query={useTimelineItemsQuery}
        filterInput={{ ...filterInput }}
        context={getApolloContextFormToken(token!)}
      >
        <TableGrid />
      </GridProvider>
    </CustomDialog>
  );
};
