import { useTranslation } from "@toolkit/i18n";
import { CareTeamChats, Notifications } from "./components/ChatTabs";

export const useMyFeed = () => {
  const { t } = useTranslation("provider");

  const tabs = [
    {
      name: t("Chats"),
      value: "0",
      content: <CareTeamChats />,
    },
    {
      name: t("Notifications"),
      value: "1",
      content: <Notifications />,
    },
  ];

  return { tabs };
};
