import { useTranslation } from "@toolkit/i18n";
import { useProfileContext } from "../../context/ProfileContext";
import { PatientInformationSection } from "./PatientInformationSection";

export const HealthConditions = () => {
  const { t } = useTranslation("provider");
  const { loading, conditions } = useProfileContext();
  const conditionsDisplay = conditions?.map(condition => condition?.display! || "-") || [];

  return <>{!loading && <PatientInformationSection title={t("Conditions")} hasItems items={conditionsDisplay} />}</>;
};
