import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  paper: {
    width: "139px",
    background: theme.palette.primary.main,
    borderRadius: "10px",
    color: theme.palette.common.white,
  },
  expandIcon: {
    background: isOpen ? theme.palette.primary[800] : theme.palette.primary.main,
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
    height: "40px",
    width: "36px",
  },
  btn: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    background: theme.palette.primary.main,
    paddingRight: "0px !important",
  },
}));
