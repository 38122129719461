import { FC, useState } from "react";
import { PatientActivityType } from "./PatientAddActivity.types";
import { PatientAddActivityButton } from "./PatientAddActivityButton";
import { PatientAddActivityDialog } from "./PatientAddActivityDialog";

export const PatientAddActivity: FC = () => {
  const [type, setType] = useState<PatientActivityType>();
  const handleOpen = (a: PatientActivityType) => setType(a);
  const handleClose = () => setType(undefined);

  return (
    <>
      <PatientAddActivityButton onSelect={handleOpen} />
      <PatientAddActivityDialog type={type} onClose={handleClose} />
    </>
  );
};
