/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductPublishStatusUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  isPublish: Types.Scalars['Boolean'];
}>;


export type MarketplaceProductPublishStatusUpdateMutation = { __typename?: 'Mutation', publishMarketplaceProduct?: { __typename?: 'MarketplaceProduct', id: string, isPublished: boolean } | null, unPublishMarketplaceProduct?: { __typename?: 'MarketplaceProduct', id: string, isPublished: boolean } | null };


export const MarketplaceProductPublishStatusUpdateDocument = gql`
    mutation MarketplaceProductPublishStatusUpdate($id: ID!, $isPublish: Boolean!) {
  publishMarketplaceProduct: marketplaceProductPublish(id: $id) @include(if: $isPublish) {
    id
    isPublished
  }
  unPublishMarketplaceProduct: marketplaceProductUnpublish(id: $id) @skip(if: $isPublish) {
    id
    isPublished
  }
}
    `;
export type MarketplaceProductPublishStatusUpdateMutationFn = Apollo.MutationFunction<MarketplaceProductPublishStatusUpdateMutation, MarketplaceProductPublishStatusUpdateMutationVariables>;

/**
 * __useMarketplaceProductPublishStatusUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductPublishStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductPublishStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductPublishStatusUpdateMutation, { data, loading, error }] = useMarketplaceProductPublishStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPublish: // value for 'isPublish'
 *   },
 * });
 */
export function useMarketplaceProductPublishStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductPublishStatusUpdateMutation, MarketplaceProductPublishStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductPublishStatusUpdateMutation, MarketplaceProductPublishStatusUpdateMutationVariables>(MarketplaceProductPublishStatusUpdateDocument, options);
      }
export type MarketplaceProductPublishStatusUpdateMutationHookResult = ReturnType<typeof useMarketplaceProductPublishStatusUpdateMutation>;
export type MarketplaceProductPublishStatusUpdateMutationResult = Apollo.MutationResult<MarketplaceProductPublishStatusUpdateMutation>;
export type MarketplaceProductPublishStatusUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductPublishStatusUpdateMutation, MarketplaceProductPublishStatusUpdateMutationVariables>;