import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography, useTheme } from "@toolkit/ui";
import { Fragment } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../context/ProfileContext";
import { useMedicalFormAnswersQuery } from "../../gql";
import { MedicalFormItem } from "./MedicalFormItem";

export const CareEngagement = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { handleTabChange, token, patientId } = useProfileContext();

  const { data, loading } = useMedicalFormAnswersQuery({
    variables: {
      first: 3,
      filter: {
        patients: [patientId!],
      },
    },
    context: getApolloContextFormToken(token!),
  });
  const medicalForms = data?.medicalFormAnswers?.edges?.map(item => item?.node) || [];

  const handleChangeTab = () => {
    handleTabChange?.("2");
  };
  return (
    <>
      {!!medicalForms?.length && !loading && (
        <>
          <Box display='flex' justifyContent={"space-between"} mb={1}>
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {t("Care Engagement")}{" "}
              <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.regular} component='span'>
                ({t("last 90 days")})
              </Typography>
            </Typography>
            <Button
              variant='text'
              sx={{ color: theme.palette.secondary.main, fontSize: theme.mixins.fonts.fontSize.xs, height: 30 }}
              onClick={handleChangeTab}
            >
              {t("See All")}
            </Button>
          </Box>
          <Box
            sx={{
              padding: "12px",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              borderRadius: "10px",
              background: theme.palette.background.default,
              boxShadow: theme.mixins.shadows.xs,
            }}
          >
            {medicalForms.map(care => (
              <Fragment key={care?.id}>
                <MedicalFormItem care={care!} />
              </Fragment>
            ))}
          </Box>
        </>
      )}
    </>
  );
};
