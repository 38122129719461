import { makeStyles } from "@toolkit/ui";

export const useTimeLineItemStyles = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    color: theme.palette.primary.main,
    boxShadow: theme.mixins.shadows.xs,
    minWidth: 100,
    padding: 8,
    paddingInline: 15,
  },
  popper: {
    zIndex: 1300,
  },
}));
