/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import { BranchFragmentFragmentDoc } from './branchFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchesDataQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.BranchFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.BranchOrder>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type BranchesDataQuery = { __typename?: 'Query', branches?: { __typename?: 'BranchCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', created: any, healthLicense?: string | null, contactNumber?: string | null, type?: Types.BranchType | null, id: string, isOpen?: boolean | null, isActive: boolean, name: string, description: string, nameAr?: string | null } }> } | null };


export const BranchesDataDocument = gql`
    query BranchesData($first: Int, $filter: BranchFilterInput, $after: String, $sortBy: BranchOrder, $before: String, $last: Int) {
  branches(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        created
        healthLicense
        ...BranchFragment
        contactNumber
        type
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${BranchFragmentFragmentDoc}`;

/**
 * __useBranchesDataQuery__
 *
 * To run a query within a React component, call `useBranchesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesDataQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBranchesDataQuery(baseOptions?: Apollo.QueryHookOptions<BranchesDataQuery, BranchesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchesDataQuery, BranchesDataQueryVariables>(BranchesDataDocument, options);
      }
export function useBranchesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesDataQuery, BranchesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchesDataQuery, BranchesDataQueryVariables>(BranchesDataDocument, options);
        }
export type BranchesDataQueryHookResult = ReturnType<typeof useBranchesDataQuery>;
export type BranchesDataLazyQueryHookResult = ReturnType<typeof useBranchesDataLazyQuery>;
export type BranchesDataQueryResult = Apollo.QueryResult<BranchesDataQuery, BranchesDataQueryVariables>;