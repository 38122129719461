import { useTranslation } from "@toolkit/i18n";
import { Box, PageWrapper, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useOrderPageContext } from "../state";
import { OrdersTab } from "../tabs/OrdersTab.component";
import { DeliveryTabVisibilityToggle } from "./DeliveryTabVisibilityToggle";
import { OrdersFilter } from "./OrdersFilter";
import { OrdersViewModeSelect } from "./OrdersViewModeSelect";
import { PropsWithChildren } from "react";

export const OrdersPageWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { selectedView } = useOrderPageContext();
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({ title: t("Orders"), values: [] });
  }, []);

  return (
    <PageWrapper
      // shouldRemoveBottomPadding
      start={
        <Box display='flex'>
          <DeliveryTabVisibilityToggle />
          <OrdersViewModeSelect />
        </Box>
      }
      actions={
        <>
          <OrdersFilter />
        </>
      }
      headerComponent={selectedView === "Tabs" && <OrdersTab />}
    >
      {children}
    </PageWrapper>
  );
};
