import {
  ActiveFilters,
  CustomToggleButton,
  FormControlLabel,
  GridContext,
  PageWrapper,
  StaticFilterDialog,
  TableGrid,
  TableSettingComponent,
  useTheme,
} from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { useVisitsQuery } from "../gql";
import { useVisitsColumns } from "./useVisitsColumns";
import { useVisitsHistoryListPage } from "./useVisitsHistoryListPage";

const VisitsHistoryListPage: FC = () => {
  const { t, isMeOnly, isVendorAdmin, selfOnly, handleToggleMyOnly, skipCall, filters } = useVisitsHistoryListPage();
  const theme = useTheme();
  return (
    <GridProvider
      gridName='visits'
      columns={useVisitsColumns({ selfOnly: !!selfOnly })}
      query={useVisitsQuery}
      filterInput={{ selfOnly: isVendorAdmin ? isMeOnly : undefined, ...filters }}
      hasTableSetting
      skipCall={skipCall}
    >
      <PageWrapper
        filters={
          <GridContext.Consumer>
            {({ activeFiltersProps }) => {
              return <ActiveFilters {...(activeFiltersProps as any)} />;
            }}
          </GridContext.Consumer>
        }
        actions={
          <GridContext.Consumer>
            {({ activeFiltersProps, staticFiltersProps, fields }) => {
              return (
                <>
                  {isVendorAdmin && (
                    <FormControlLabel
                      label={t("Me Only")}
                      sx={{ fontSize: theme.mixins.fonts.fontSize.xs, minWidth: 120, margin: 1 }}
                      control={<CustomToggleButton value={isMeOnly} defaultChecked={isMeOnly} onChange={handleToggleMyOnly} />}
                    />
                  )}
                  <TableSettingComponent />
                  <StaticFilterDialog
                    {...{
                      isOpen: staticFiltersProps?.isOpen!,
                      onApplyFilters: staticFiltersProps?.onApplyFilters!,
                      onToggleDialog: staticFiltersProps?.onToggleDialog!,
                      filterFields: fields!,
                      filters: activeFiltersProps?.filters!,
                    }}
                  />
                </>
              );
            }}
          </GridContext.Consumer>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
export default VisitsHistoryListPage;
