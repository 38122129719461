import { formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { useBranchUpdateMutation } from "../gql";

export const ActiveSwitch: FC<{ id; isActive; vendorActive }> = ({ id, isActive, vendorActive }) => {
  const { t, i18n } = useTranslation("provider");
  const currentDir = i18n.dir(i18n.language);
  const { open, handleToggle } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const [active, setActive] = useState(isActive);
  const [updateBranch] = useBranchUpdateMutation({
    onCompleted: data => {
      const errors = data?.branchUpdate?.branchErrors;
      if (errors && errors.length > 0) {
        formatMessageErrors(errors);
        failed(t("Branch Update Failed"));
      } else {
        setActive(data?.branchUpdate?.branch?.isActive);
        succeeded(t("Branch Updated Successfully"));
      }
    },
    onError: () => {
      failed(t("Branch Update Failed"));
    },
  });

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const handleActive = () => {
    updateBranch({
      variables: {
        id: id,
        branch: {
          isActive: !active,
        },
      },
    });
  };

  return (
    <>
      <CustomToggleButton dir={currentDir} checked={active} disabled={!vendorActive} onChange={handleToggle} />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: active ? t("Deactivating selected branch") : t("Activate selected branch"),
            title: active ? t("Deactivate Branch Confirmation") : t("Activate Branch Confirmation"),
          }}
          onClose={handleToggle}
          onConfirm={handleActive}
        />
      )}
    </>
  );
};
