import { ProviderSettings } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { Button, FormCard, Grid, TextField, ToggleButtonController } from "@toolkit/ui";
import useOutPatientJourneyForm from "pages/Settings/GeneralSettings/forms/out-patient-journey/out-patient-journey.hook";
import { FC } from "react";

export type OutPatientJourneyFormProps = {
  providerSettings?: ProviderSettings | null;
  isLoading?: boolean;
};

const OutPatientJourneyForm: FC<OutPatientJourneyFormProps> = props => {
  const { providerSettings, isLoading: loading = false } = props;

  const { control, register, errors, t, onSubmit, loadingMutation } = useOutPatientJourneyForm();

  return (
    <form onSubmit={onSubmit}>
      <FormCard title={t("Out Patient Journey")} loading={loading || loadingMutation} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <Grid item xs={12}>
            <ToggleButtonController
              control={control}
              label={t("Out Patient Journey Supported")}
              defaultValue={providerSettings?.outPatientJourneySupported ?? false}
              {...register("outPatientJourneySupported")}
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonController
              control={control}
              label={t("Payment After Consultation")}
              defaultValue={providerSettings?.paymentAfterConsultation ?? false}
              {...register("paymentAfterConsultation")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              type='number'
              label={t("Appointment Confirmation TimeOut (Minutes)")}
              error={Boolean(errors.appointmentConfirmationTimeoutSeconds?.message)}
              helperText={t(errors.appointmentConfirmationTimeoutSeconds?.message!)}
              {...register("appointmentConfirmationTimeoutSeconds", {
                required: { value: true, message: t("Required") },
                valueAsNumber: true,
              })}
              InputProps={{
                inputProps: { min: 0 },
              }}
              defaultValue={providerSettings?.appointmentConfirmationTimeoutSeconds! / 60}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              type='number'
              label={t("Appointment Reminder TimeOut (Minutes)")}
              error={Boolean(errors.appointmentReminderTimeoutSeconds?.message)}
              helperText={t(errors.appointmentReminderTimeoutSeconds?.message!)}
              {...register("appointmentReminderTimeoutSeconds", {
                required: { value: true, message: t("Required") },
                valueAsNumber: true,
              })}
              InputProps={{
                inputProps: { min: 0 },
              }}
              defaultValue={providerSettings?.appointmentReminderTimeoutSeconds! / 60}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type={"submit"}> {t("Save")} </Button>
          </Grid>
        </Grid>
      </FormCard>
    </form>
  );
};

export default OutPatientJourneyForm;
