/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientProfileConsentFormCreateMutationVariables = Types.Exact<{
  input: Types.PatientProfileConsentFormInput;
}>;


export type PatientProfileConsentFormCreateMutation = { __typename?: 'Mutation', patientProfileConsentFormCreate?: { __typename?: 'PatientProfileConsentFormCRUD', patientProfileConsentForm?: { __typename?: 'PatientProfileConsentForm', id: string, type?: Types.ConsentType | null, status?: Types.ConsentStatus | null } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const PatientProfileConsentFormCreateDocument = gql`
    mutation PatientProfileConsentFormCreate($input: PatientProfileConsentFormInput!) {
  patientProfileConsentFormCreate(input: $input) {
    patientProfileConsentForm {
      id
      type
      status
    }
    entityErrors {
      code
      field
      message
    }
  }
}
    `;
export type PatientProfileConsentFormCreateMutationFn = Apollo.MutationFunction<PatientProfileConsentFormCreateMutation, PatientProfileConsentFormCreateMutationVariables>;

/**
 * __usePatientProfileConsentFormCreateMutation__
 *
 * To run a mutation, you first call `usePatientProfileConsentFormCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientProfileConsentFormCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientProfileConsentFormCreateMutation, { data, loading, error }] = usePatientProfileConsentFormCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientProfileConsentFormCreateMutation(baseOptions?: Apollo.MutationHookOptions<PatientProfileConsentFormCreateMutation, PatientProfileConsentFormCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientProfileConsentFormCreateMutation, PatientProfileConsentFormCreateMutationVariables>(PatientProfileConsentFormCreateDocument, options);
      }
export type PatientProfileConsentFormCreateMutationHookResult = ReturnType<typeof usePatientProfileConsentFormCreateMutation>;
export type PatientProfileConsentFormCreateMutationResult = Apollo.MutationResult<PatientProfileConsentFormCreateMutation>;
export type PatientProfileConsentFormCreateMutationOptions = Apollo.BaseMutationOptions<PatientProfileConsentFormCreateMutation, PatientProfileConsentFormCreateMutationVariables>;