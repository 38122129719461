/* eslint-disable react/prop-types */
import { useGetConversationsQuery } from "@meetora/react/sehacity/Chat";
import { TInputPeer } from "@meetora/schema/InputPeer";
import { TInvitationInfo } from "@meetora/schema/conversation/InvitationInfo";
import { useMemo } from "react";
export const ConversationComponent = ({ peer }: { peer: TInputPeer | TInvitationInfo }) => {
  const chatId = useMemo(() => {
    if ("anonChatId" in peer) {
      return peer.anonChatId;
    }

    if ("anonChat" in peer) {
      return peer.anonChat.id;
    }
  }, [peer]);

  const { conversations } = useGetConversationsQuery({
    anonChatIds: chatId ? [chatId] : [],
  });
  return chatId ? <>{conversations[chatId]?.unreadCount}</> : <></>;
};
