import { getEnvVariable } from "@/env";

export const fetchFreshAccessToken = async (accessToken: string, refreshToken: string, map = (res: any) => res.access_token) => {
  try {
    return await fetch(getEnvVariable("KEYCLOAK_API_URL") + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
      body: `grant_type=refresh_token&client_id=${getEnvVariable("KEYCLOAK_CLIENT_ID_PROVIDER")}&refresh_token=${refreshToken}`,
    })
      .then(res => res.json())
      .then(res => map(res));
  } catch (error) {
    console.error("Failed to get refresh token.", error);
    return null;
  }
};
