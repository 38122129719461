/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RadiologyCreateMutationVariables = Types.Exact<{
  input: Types.VisitSummaryInput;
}>;


export type RadiologyCreateMutation = { __typename?: 'Mutation', radiologyCreate?: { __typename?: 'VisitSummaryCRUD', radiology?: { __typename?: 'VisitSummary', id: string, doctorNotes?: string | null, visit?: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null } | null } | null, radiologyErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, message?: string | null, field?: string | null }> | null } | null };


export const RadiologyCreateDocument = gql`
    mutation radiologyCreate($input: VisitSummaryInput!) {
  radiologyCreate: visitSummaryCreate(input: $input) {
    radiology: visitSummary {
      id
      visit {
        id
        status
      }
      doctorNotes
    }
    radiologyErrors: visitSummaryErrors {
      code
      message
      field
    }
  }
}
    `;
export type RadiologyCreateMutationFn = Apollo.MutationFunction<RadiologyCreateMutation, RadiologyCreateMutationVariables>;

/**
 * __useRadiologyCreateMutation__
 *
 * To run a mutation, you first call `useRadiologyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRadiologyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [radiologyCreateMutation, { data, loading, error }] = useRadiologyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRadiologyCreateMutation(baseOptions?: Apollo.MutationHookOptions<RadiologyCreateMutation, RadiologyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RadiologyCreateMutation, RadiologyCreateMutationVariables>(RadiologyCreateDocument, options);
      }
export type RadiologyCreateMutationHookResult = ReturnType<typeof useRadiologyCreateMutation>;
export type RadiologyCreateMutationResult = Apollo.MutationResult<RadiologyCreateMutation>;
export type RadiologyCreateMutationOptions = Apollo.BaseMutationOptions<RadiologyCreateMutation, RadiologyCreateMutationVariables>;