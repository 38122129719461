import { Grid, SkeletonCard } from "@toolkit/ui";
import React, { FC } from "react";
import { PatientHealthParameterVitalSignCard } from "../PatientHealthParameterVitalSignCard/PatientHealthParameterVitalSignCard";
import { useHealthParameterVitalSignListQuery, usePatientHealthParametersVitalSignsChangePercentageListQuery } from "../../gql";
import { getApolloContextFormToken } from "../../../../shared/utils";
import { formatHealthParametersVitalSigns } from "../../others";
import { HealthParameter } from "@health/queries/types";

type PatientHealthParametersVitalSignsProps = {
  patientId: string;
  token: string;
};

export const PatientHealthParametersVitalSigns: FC<PatientHealthParametersVitalSignsProps> = props => {
  const { patientId, token } = props;

  const { data: vitalSignsChangePercentageData, loading: isVitalSignsChangePercentageLoading } =
    usePatientHealthParametersVitalSignsChangePercentageListQuery({
      variables: { patientId },
      skip: !patientId,
      context: getApolloContextFormToken(token!),
    });

  const vitalSignsChangePercentages = vitalSignsChangePercentageData?.patientHealthParameterVitalSignsChangePercentage;

  const { data, loading } = useHealthParameterVitalSignListQuery({
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const healthParameters = data?.getHealthParameters?.edges?.map(item => item?.node) as HealthParameter[];

  const isLoading = loading || isVitalSignsChangePercentageLoading;

  const formattedVitalSigns = formatHealthParametersVitalSigns(healthParameters, vitalSignsChangePercentages!);

  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <SkeletonCard count={5} height={200} widths={{ xs: 12, sm: 4, md: 4, lg: 4 }} />
      ) : (
        <>
          {formattedVitalSigns?.map(item => (
            <Grid key={item?.code?.[0]} item xs={12} sm={4} md={4} lg={4}>
              <PatientHealthParameterVitalSignCard
                patientId={patientId!}
                token={token!}
                codes={item?.code}
                title={item?.title!}
                changePercentage={item?.changePercentage}
              />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};
