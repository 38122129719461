import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { IconProps, ButtonProps, IconButton, JoinCallIcon, Tooltip } from "@toolkit/ui";
import { FC } from "react";
import { isExpiredLicenseEndDate } from "shared/components/VisitStartingNowNotification/utils";
import { useDoctorVisitAccept } from "./useDoctorVisitAccept";

export const AcceptVisit: FC<{
  id?: string;
  onVisitAccepted: () => void;
  icon?: JSX.Element;
  iconProps?: IconProps;
  buttonProps?: ButtonProps;
}> = ({ id, onVisitAccepted, iconProps, buttonProps, icon }) => {
  const { t } = useTranslation("provider");
  const { handleAccept, isLoading } = useDoctorVisitAccept(id, onVisitAccepted);
  const isExpired = useReactiveVar(isExpiredLicenseEndDate);

  return (
    <Tooltip title={isExpired ? t("Your health license is not valid or expired") : ""}>
      <span>
        <IconButton disabled={isExpired || isLoading} onClick={handleAccept} {...buttonProps}>
          {icon ?? <JoinCallIcon color={iconProps?.color as any} {...iconProps} />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

AcceptVisit.defaultProps = {
  id: undefined,
};
