import { useTranslation } from "@toolkit/i18n";
import { Card, FlowerIcon, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "../../WaitingRooms/WaitingRoomsList/WaitingRoomsList.styles";

export const NurseUserFeedsCard: FC = () => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();
  return (
    <Card sx={{ height: "420px", marginTop: 1 }}>
      <Grid container alignItems='center' justifyContent='center' className={classes.emptyCard}>
        <FlowerIcon sx={{ width: "unset", height: "unset" }} />
        <Typography
          variant='body2'
          sx={{
            padding: "10px 0px 0px 5px",
            fontSize: theme.mixins.fonts.fontSize.xl,
          }}
        >
          <strong>{t("No Data!")}</strong>
        </Typography>
      </Grid>
    </Card>
  );
};
