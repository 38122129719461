import { PermissionEnum, Visit } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export const useGetVisitsDoctorNameColumns = (selfOnly: boolean): CustomTableColumnProps<Visit>[] => {
  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();
  const showColumn = hasPermission(PermissionEnum.ManageVisits) && (userInfo?.vendor_user_type === "Doctor" ? !selfOnly : true);

  return useMemo(
    () => [
      {
        key: "Doctor_Name",
        header: t("Doctor Name"),
        accessor: row => <TruncateTypography text={row?.doctor?.user?.fullName!} />,
        hideFromPreferencesAndTable: !showColumn,
      },
    ],
    [t, showColumn]
  );
};
