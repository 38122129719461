import { useGapsCircleStyle } from "pages/GuidedCarePatients/Components/GapsCircle/useGapsCircleStyle";
import { FC } from "react";

type GapsCircleProps = {
  value: number;
};

export const GapsCircle: FC<GapsCircleProps> = props => {
  const { value } = props;

  const { classes } = useGapsCircleStyle();

  if (!value) {
    return <> -- </>;
  }

  return <span className={classes.root}> {value} </span>;
};
