import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Grid, Typography, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import { useOrderVendorDispensingMutation } from "../gql";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";
import { usePickupVerificationButtonStyle } from "./PickupVerificationButton.style";
import { formatGraphQLError } from "@toolkit/apollo";
import { OrderDeliveryStatus } from "@health/queries/types";

export const PickupVerificationButton: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const { open, handleOpen, handleClose } = useOpenState();
  const [code, setCode] = useState<string | undefined>(undefined);
  const { classes } = useOrderActionStyles();
  const { classes: cls } = usePickupVerificationButtonStyle();
  const [errors, setErrors] = useState<string[]>([]);
  const [isWrongLanguage, setIsWrongLanguage] = useState(false);

  const handleCloseModel = () => {
    setCode(undefined);
    setErrors([]);
    setIsWrongLanguage(false);
    handleClose();
  };

  const handleChangeInput = (data: string) => {
    errors?.length && setErrors([]);
    const trimmedCode = data.trim().substring(0, 4);
    setCode(trimmedCode);
  };
  const handleSubmit = () => {
    if (code) {
      orderVendorDispensing({
        variables: {
          orderId: id,
          input: {
            deliveryStatus: OrderDeliveryStatus.PickedByConsumer,
            pickupOTP: code,
          },
        },
      });
    } else {
      setErrors([t("Please enter verification code")]);
    }
  };

  const handleCheckLanguage = e => {
    const re = /[A-Za-z0-9]+/;
    const a = e.key.match(re);
    if (a == null) {
      setIsWrongLanguage(true);
    } else {
      setIsWrongLanguage(false);
    }
  };

  const [orderVendorDispensing, { loading }] = useOrderVendorDispensingMutation({
    onCompleted: () => {
      succeeded(t("Order Picked Successfully"));
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  return (
    <CustomDialog
      type='base'
      button={
        <Button className={classes.accept} onClick={handleOpen}>
          {t("Picked")}
        </Button>
      }
      open={open}
      onClose={handleCloseModel}
      DialogTitleProps={{
        title: t("Pickup Verification"),
        onClose: handleCloseModel,
      }}
      DialogActionsProps={{
        children: (
          <CircularIntegration loading={loading}>
            <Button onClick={handleSubmit}>{t("Verify")}</Button>
          </CircularIntegration>
        ),
      }}
      maxWidth={"sm"}
    >
      <Grid container spacing={1} direction='column' alignContent='center'>
        <Grid item xs>
          <Typography textAlign='center'>
            {t("Please enter the")} <strong>{t("verification code")}</strong> {t("that has been sent to the Patient Portal")}
          </Typography>
        </Grid>
        <Grid item xs>
          <div className={errors.length > 0 ? `${cls.confirm} ${cls.confirmError}` : cls.confirm} onKeyDown={handleCheckLanguage} dir='ltr'>
            <ReactInputVerificationCode value={code} placeholder='' onChange={handleChangeInput} />
          </div>
          {isWrongLanguage && (
            <Typography variant='body2' textAlign='center' color='error'>
              {t("Please enter the number in english")}
            </Typography>
          )}
          <Box>
            {errors.map((error, index) => (
              <Typography key={index} color='error' variant='body2' textAlign='center'>
                {t(error)}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
