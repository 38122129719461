/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceOrderDeliveredUpdateMutationVariables = Types.Exact<{
  marketplaceOrderDeliveredUpdateId: Types.Scalars['ID'];
}>;


export type MarketplaceOrderDeliveredUpdateMutation = { __typename?: 'Mutation', marketplaceOrderDeliveredUpdate?: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus } | null };


export const MarketplaceOrderDeliveredUpdateDocument = gql`
    mutation MarketplaceOrderDeliveredUpdate($marketplaceOrderDeliveredUpdateId: ID!) {
  marketplaceOrderDeliveredUpdate(id: $marketplaceOrderDeliveredUpdateId) {
    id
    status
  }
}
    `;
export type MarketplaceOrderDeliveredUpdateMutationFn = Apollo.MutationFunction<MarketplaceOrderDeliveredUpdateMutation, MarketplaceOrderDeliveredUpdateMutationVariables>;

/**
 * __useMarketplaceOrderDeliveredUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceOrderDeliveredUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceOrderDeliveredUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceOrderDeliveredUpdateMutation, { data, loading, error }] = useMarketplaceOrderDeliveredUpdateMutation({
 *   variables: {
 *      marketplaceOrderDeliveredUpdateId: // value for 'marketplaceOrderDeliveredUpdateId'
 *   },
 * });
 */
export function useMarketplaceOrderDeliveredUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceOrderDeliveredUpdateMutation, MarketplaceOrderDeliveredUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceOrderDeliveredUpdateMutation, MarketplaceOrderDeliveredUpdateMutationVariables>(MarketplaceOrderDeliveredUpdateDocument, options);
      }
export type MarketplaceOrderDeliveredUpdateMutationHookResult = ReturnType<typeof useMarketplaceOrderDeliveredUpdateMutation>;
export type MarketplaceOrderDeliveredUpdateMutationResult = Apollo.MutationResult<MarketplaceOrderDeliveredUpdateMutation>;
export type MarketplaceOrderDeliveredUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceOrderDeliveredUpdateMutation, MarketplaceOrderDeliveredUpdateMutationVariables>;