import { makeVar, useReactiveVar } from "@apollo/client";
import { useSiteSettingsGetQuery } from "gql/queries";
import React, { PropsWithChildren, useEffect } from "react";

type SiteSettingsVariables = {
  defaultCurrency: string;
  timeZone?: number | null;
  orderAcceptanceTimeout?: number | null;
  orderErxHubApprovalTimeout?: number | null;
  orderErxHubApprovalResubmitTimeout?: number | null;
  orderDispensingTimeout?: number | null;
  orderDeliveryTimeout?: number | null;
  vatPercentage?: number | null;
  appointmentReminderTimeSeconds?: number | null;
};

export const siteSettingsVar = makeVar<SiteSettingsVariables | undefined>(undefined);

const SiteSettingsVarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useSiteSettingsGetQuery();

  useEffect(() => {
    if (data?.siteSettings) {
      siteSettingsVar({
        defaultCurrency: data?.siteSettings?.defaultCurrency,
        timeZone: data.siteSettings.timeZone,
        orderAcceptanceTimeout: data.siteSettings.orderAcceptanceTimeout,
        orderErxHubApprovalTimeout: data.siteSettings.orderErxHubApprovalTimeout,
        orderErxHubApprovalResubmitTimeout: data.siteSettings.orderErxHubApprovalResubmitTimeout,
        orderDispensingTimeout: data.siteSettings.orderDispensingTimeout,
        orderDeliveryTimeout: data.siteSettings.orderDeliveryTimeout,
        vatPercentage: data.siteSettings.vatPercentage,
        appointmentReminderTimeSeconds: data.siteSettings.appointmentReminderTimeSeconds,
      });
    }
  }, [data]);

  return <>{children}</>;
};

export default SiteSettingsVarProvider;

export const useSiteSettings = () => {
  const data = useReactiveVar(siteSettingsVar);

  return {
    defaultCurrency: data?.defaultCurrency,
    timeZone: data?.timeZone,
    orderAcceptanceTimeout: data?.orderAcceptanceTimeout,
    orderErxHubApprovalTimeout: data?.orderErxHubApprovalTimeout,
    orderErxHubApprovalResubmitTimeout: data?.orderErxHubApprovalResubmitTimeout,
    orderDispensingTimeout: data?.orderDispensingTimeout,
    orderDeliveryTimeout: data?.orderDeliveryTimeout,
    vatPercentage: data?.vatPercentage,
    appointmentReminderTimeSeconds: data?.appointmentReminderTimeSeconds,
  };
};
