import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { MedicalFormQuestionUpsertForm, } from "../../../MedicalForms/forms/MedicalFormQuestionUpsert/MedicalFormQuestionUpsertForm";
import { useRef } from "react";
export const MedicalFormQuestionUpsertModal = props => {
    const { question, onChange, onClose: handleClose, isOpen } = props;
    const { t } = useTranslation("domains");
    const medicalFormQuestionUpsertFormRef = useRef(null);
    const onButtonClick = () => {
        var _a;
        (_a = medicalFormQuestionUpsertFormRef.current) === null || _a === void 0 ? void 0 : _a.submit();
    };
    return (_jsx(CustomDialog, { type: 'base', open: isOpen, DialogTitleProps: {
            title: question ? t("Update Question") : t("Add New Question"),
            onClose: handleClose,
        }, DialogActionsProps: {
            hasClose: !!question,
            submitTitle: question ? t("Update") : t("Add"),
            onSubmit: onButtonClick,
        }, children: _jsx(MedicalFormQuestionUpsertForm, { question: question, ref: medicalFormQuestionUpsertFormRef, onChange: onChange }) }));
};
