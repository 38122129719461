import { Branch } from "@health/queries/types";
import { useAutocompleteCustomHook } from "@toolkit/ui";
import { useDepartmentBranchesQuery } from "pages/HealthProviderManagement/Departments/gql";

export const useDepartmentInfoHook = () => {
  const branchAccessor = "branches";
  const branchInput = {
    first: 10,
    after: null,
    before: null,
    last: null,
  };
  const {
    data: branchData,
    isLoading: loadingBranch,
    onFetchMoreData: onFetchMoreDataForBranches,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useDepartmentBranchesQuery,
    input: branchInput,
    dataAccessor: branchAccessor,
  });
  const hasMoreBranches = branchData?.branches?.pageInfo?.hasNextPage;
  const branches = branchData?.branches?.edges?.map(branch => branch?.node)! as Branch[];

  return {
    loadingBranch,
    onFetchMoreDataForBranches,
    hasMoreBranches,
    branches,
  };
};
