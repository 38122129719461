import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useMarketplaceOrderDeliveredUpdateMutation } from "../gql";

export const SetProductOrderAsDeliveredButton: FC<{ orderId: string }> = ({ orderId }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [setProductToDelivered, { loading: isSubmittingReject }] = useMarketplaceOrderDeliveredUpdateMutation({
    onCompleted: data => {
      if (data.marketplaceOrderDeliveredUpdate?.id) {
        succeeded(t("Product status set to delivered successfully"));
      }
    },
    onError: error => {
      failed(t(formatGraphQLError(error.graphQLErrors)));
    },
    update: cache => {
      const normalizedId = cache.identify({ id: orderId, __typename: "MarketplaceOrder" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });
  const handleConsumedClick = () => {
    setProductToDelivered({
      variables: {
        marketplaceOrderDeliveredUpdateId: orderId!,
      },
    });
  };

  return (
    <Button
      sx={{ width: "160px" }}
      color={"success"}
      disabled={isSubmittingReject}
      onClick={handleConsumedClick}
      title={t("Click here to mark product order status as consumed")}
    >
      {t("Set As Delivered")}
    </Button>
  );
};
