import { FC } from "react";
import { OrderActions } from "../actions/OrderActions";
import { IActions, IOrderSection } from "../actions/types";
import { OrdersCard } from "../cards/OrdersCard";
import { OrdersContainer } from "../cards/OrdersContainer";
import { OrderProps } from "./Order.types";
import { useOrderMapper } from "./useOrderMapper.hook";
import { getColorFromStatus } from "./utils";

export const Order: FC<OrderProps> = ({ order }) => {
  const { mapOrderToData, mapOrderToAction } = useOrderMapper();

  const { action } = mapOrderToAction(order) as IOrderSection & IActions;

  const hasAction = Object.values(action!).some(value => value);
  return (
    <OrdersCard hasExpand color={getColorFromStatus(order?.status)}>
      <OrdersContainer hasAction={hasAction} data={mapOrderToData(order) as IOrderSection} />
      <OrderActions data={mapOrderToAction(order) as IOrderSection & IActions} />
    </OrdersCard>
  );
};
