import { CustomDialog } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import React, { useEffect, useState } from "react";
import {
  PatientHealthParameterHistoryAudio,
  PatientHealthParameterHistoryNumber,
  PatientHealthParameterHistoryVideo,
} from "../../components";
import { PatientHealthParameterHistoryNumeric } from "../../components/PatientHealthParameterHistoryNumeric/PatientHealthParameterHistoryNumeric";

type IPatientHealthParameterHistoryModalData = {
  type: "NUMBER" | "NUMERIC" | "AUDIO" | "VIDEO";
  title: string;
  patientId?: string;
  token: string;
  codes: string[];
};

type IOpen = (data: IPatientHealthParameterHistoryModalData) => void;
type IClose = () => void;

export const PatientHealthParameterHistoryModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const PatientHealthParameterHistoryModal = () => {
  const [data, setData] = useState<IPatientHealthParameterHistoryModalData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    PatientHealthParameterHistoryModalApi.open = open;
    PatientHealthParameterHistoryModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: data?.title,
        onClose: close,
      }}
    >
      {data?.type === "NUMBER" && (
        <PatientHealthParameterHistoryNumber patientId={data?.patientId!} token={data?.token!} codes={data?.codes!} />
      )}

      {data?.type === "NUMERIC" && (
        <PatientHealthParameterHistoryNumeric patientId={data?.patientId!} token={data?.token!} codes={data?.codes!} />
      )}

      {data?.type === "AUDIO" && (
        <PatientHealthParameterHistoryAudio patientId={data?.patientId!} token={data?.token!} codes={data?.codes!} />
      )}

      {data?.type === "VIDEO" && (
        <PatientHealthParameterHistoryVideo patientId={data?.patientId!} token={data?.token!} codes={data?.codes!} />
      )}
    </CustomDialog>
  );
};
