/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetChatLinkMutationVariables = Types.Exact<{
  userIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type GetChatLinkMutation = { __typename?: 'Mutation', saveOrFetchExistedAnonymousChatGroup?: { __typename?: 'AnonymousChatGroup', anonymousChatLinkForJoinCall?: string | null, anonymousChatLinkForChatView?: string | null, error?: string | null } | null };


export const GetChatLinkDocument = gql`
    mutation getChatLink($userIds: [ID!]!) {
  saveOrFetchExistedAnonymousChatGroup(userIds: $userIds) {
    anonymousChatLinkForJoinCall
    anonymousChatLinkForChatView
    error
  }
}
    `;
export type GetChatLinkMutationFn = Apollo.MutationFunction<GetChatLinkMutation, GetChatLinkMutationVariables>;

/**
 * __useGetChatLinkMutation__
 *
 * To run a mutation, you first call `useGetChatLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetChatLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getChatLinkMutation, { data, loading, error }] = useGetChatLinkMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetChatLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetChatLinkMutation, GetChatLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetChatLinkMutation, GetChatLinkMutationVariables>(GetChatLinkDocument, options);
      }
export type GetChatLinkMutationHookResult = ReturnType<typeof useGetChatLinkMutation>;
export type GetChatLinkMutationResult = Apollo.MutationResult<GetChatLinkMutation>;
export type GetChatLinkMutationOptions = Apollo.BaseMutationOptions<GetChatLinkMutation, GetChatLinkMutationVariables>;