/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveAppointmentNotificationSettingMutationVariables = Types.Exact<{
  input: Array<Types.AppointmentNotificationSettingInput> | Types.AppointmentNotificationSettingInput;
}>;


export type SaveAppointmentNotificationSettingMutation = { __typename?: 'Mutation', saveAppointmentNotificationSetting?: Array<{ __typename?: 'AppointmentNotificationSetting', arabicText?: string | null, channels?: Array<Types.NotificationChannel | null> | null, createdBy?: string | null, createdDate?: any | null, englishText?: string | null, id: string, lastModifiedBy?: string | null, lastModifiedDate?: any | null, sendTimeInMinutes?: number | null, types?: Array<Types.AppointmentType | null> | null } | null> | null };


export const SaveAppointmentNotificationSettingDocument = gql`
    mutation SaveAppointmentNotificationSetting($input: [AppointmentNotificationSettingInput!]!) {
  saveAppointmentNotificationSetting(input: $input) {
    arabicText
    channels
    createdBy
    createdDate
    englishText
    id
    lastModifiedBy
    lastModifiedDate
    sendTimeInMinutes
    types
  }
}
    `;
export type SaveAppointmentNotificationSettingMutationFn = Apollo.MutationFunction<SaveAppointmentNotificationSettingMutation, SaveAppointmentNotificationSettingMutationVariables>;

/**
 * __useSaveAppointmentNotificationSettingMutation__
 *
 * To run a mutation, you first call `useSaveAppointmentNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAppointmentNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAppointmentNotificationSettingMutation, { data, loading, error }] = useSaveAppointmentNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAppointmentNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<SaveAppointmentNotificationSettingMutation, SaveAppointmentNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAppointmentNotificationSettingMutation, SaveAppointmentNotificationSettingMutationVariables>(SaveAppointmentNotificationSettingDocument, options);
      }
export type SaveAppointmentNotificationSettingMutationHookResult = ReturnType<typeof useSaveAppointmentNotificationSettingMutation>;
export type SaveAppointmentNotificationSettingMutationResult = Apollo.MutationResult<SaveAppointmentNotificationSettingMutation>;
export type SaveAppointmentNotificationSettingMutationOptions = Apollo.BaseMutationOptions<SaveAppointmentNotificationSettingMutation, SaveAppointmentNotificationSettingMutationVariables>;