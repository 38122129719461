import { useTranslation } from "@toolkit/i18n";
import { Grid, MDEditorField } from "@toolkit/ui";
import { ProductData } from "pages/MarketplaceProducts/types";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type ProductFormDescriptionsProps = {
  product?: ProductData;
};

export const ProductFormDescriptions: FC<ProductFormDescriptionsProps> = ({ product }) => {
  const { control } = useFormContext();

  const { t } = useTranslation("provider");
  return (
    <>
      <Grid item xs={12}>
        <MDEditorField
          controllerProps={{
            name: "description",
            control: control,
          }}
          label={t("Description")}
          placeholder={t("Description")}
          defaultValue={product?.description || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <MDEditorField
          controllerProps={{
            name: "descriptionAr",
            control: control,
          }}
          label={t("Description (Arabic)")}
          placeholder={t("Description (Arabic)")}
          defaultValue={product?.descriptionAr || ""}
        />
      </Grid>
    </>
  );
};
