import { MedicalFormAnswer } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, Typography, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { FC, useState } from "react";
import { Classification } from "../Assessments/Classification";
import { HealthInsuranceSatisfaction } from "../Assessments/HealthInsuranceSatisfaction";

type MedicalFormItemProps = {
  care: MedicalFormAnswer;
};
export const MedicalFormItem: FC<MedicalFormItemProps> = ({ care }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box
      display='flex'
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={1}
      p={1}
      sx={{
        height: 65,
        cursor: "pointer",
        borderRadius: "5px",
        backgroundColor: theme.palette.background.default,
      }}
      onClick={handleToggle}
    >
      <Box display='flex' alignItems='center' gap={2}>
        <CustomIcon icon='medical-form' />
        <Box>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm} color={theme.palette.primary.main}>
            {care?.medicalForm?.name}
          </Typography>
          <Typography color={theme.palette.stale.main} fontSize={theme.mixins.fonts.fontSize.xs}>
            {moment(care?.medicalForm?.updateDate || care?.medicalForm?.createdDate).format("DD MMM YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box display='flex' alignItems='center' gap={1}>
        <Typography fontSize={theme.mixins.fonts.fontSize.xs} color={theme.palette.stale.main}>
          {t("Status")}
        </Typography>
        <Classification name={care?.classificationName!} />
      </Box>

      {open && (
        <HealthInsuranceSatisfaction
          isOpen={open}
          label={care?.medicalForm?.name!}
          date={care?.medicalForm?.updateDate || care?.medicalForm?.createdDate}
          questionAnswers={care?.questionAnswers!}
          onToggle={handleToggle}
          classificationName={care?.classificationName!}
          numberOfQuestions={care?.questionAnswers?.length!}
        />
      )}
    </Box>
  );
};
