import { sourceOptionsMap } from "@health/enum-options";
import { PatientHealthParameter } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";

export const useVitalSignsColumns = (): CustomTableColumnProps<PatientHealthParameter>[] => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return useMemo(() => {
    return [
      {
        key: "value",
        header: t("Value"),
        accessor: row => (
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {row?.valueNumber || "-"}
          </Typography>
        ),
      },
      {
        key: "date",
        header: t("Date"),
        accessor: row => moment(row?.createdDate).format("DD-MM-YYYY hh:mm"),
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ source }) => sourceOptionsMap[source!]?.label || "-",
      },
    ];
  }, [t]);
};
