/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorAppointmentRescheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.DoctorAppointmentRescheduleInput;
}>;


export type DoctorAppointmentRescheduleMutation = { __typename?: 'Mutation', doctorAppointmentReschedule?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string, requestedTimeSlots?: Array<string | null> | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, doctor?: { __typename?: 'Doctor', id: string } | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', id: string, type?: Types.AppointmentPaymentType | null, currency?: string | null, totalAmount?: number | null } | null } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const DoctorAppointmentRescheduleDocument = gql`
    mutation DoctorAppointmentReschedule($id: ID!, $input: DoctorAppointmentRescheduleInput!) {
  doctorAppointmentReschedule(id: $id, input: $input) {
    appointment {
      id
      requestedTimeSlots
      confirmedStartTime
      confirmedEndTime
      doctor {
        id
      }
      paymentInfo {
        id
        type
        currency
        totalAmount
      }
      type
      status
    }
    appointmentErrors {
      code
      field
      message
    }
  }
}
    `;
export type DoctorAppointmentRescheduleMutationFn = Apollo.MutationFunction<DoctorAppointmentRescheduleMutation, DoctorAppointmentRescheduleMutationVariables>;

/**
 * __useDoctorAppointmentRescheduleMutation__
 *
 * To run a mutation, you first call `useDoctorAppointmentRescheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorAppointmentRescheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorAppointmentRescheduleMutation, { data, loading, error }] = useDoctorAppointmentRescheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoctorAppointmentRescheduleMutation(baseOptions?: Apollo.MutationHookOptions<DoctorAppointmentRescheduleMutation, DoctorAppointmentRescheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorAppointmentRescheduleMutation, DoctorAppointmentRescheduleMutationVariables>(DoctorAppointmentRescheduleDocument, options);
      }
export type DoctorAppointmentRescheduleMutationHookResult = ReturnType<typeof useDoctorAppointmentRescheduleMutation>;
export type DoctorAppointmentRescheduleMutationResult = Apollo.MutationResult<DoctorAppointmentRescheduleMutation>;
export type DoctorAppointmentRescheduleMutationOptions = Apollo.BaseMutationOptions<DoctorAppointmentRescheduleMutation, DoctorAppointmentRescheduleMutationVariables>;