import { makeStyles } from "@toolkit/ui";

export const usePickupVerificationButtonStyle = makeStyles()(theme => ({
  confirm: {
    margin: "10px 0px",
    borderRadius: 15,
    "--ReactInputVerificationCode-itemWidth": "70px",
    "--ReactInputVerificationCode-itemHeight": "70px",
    "--ReactInputVerificationCode-itemSpacing": "2rem",
    display: "flex",
    justifyContent: "center",
    "& .ReactInputVerificationCode__item": {
      borderRadius: 10,
      fontSize: 40,
      border: "2px solid",
      borderColor: theme.palette.gray.light,
      boxShadow: theme.mixins.shadows.none,
    },
    "& .ReactInputVerificationCode__item.is-active": {
      boxShadow: theme.mixins.shadows.none,
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
  },
  confirmError: {
    "& .ReactInputVerificationCode__item": {
      borderRadius: 10,
      color: theme.palette.error.main,
      border: "2px solid",
      borderColor: theme.palette.error.main + " !important",
      boxShadow: theme.mixins.shadows.none,
    },
    "& .ReactInputVerificationCode__item.is-active": {
      boxShadow: theme.mixins.shadows.none,
      border: "2px solid",
      borderColor: theme.palette.error.main,
    },
  },
}));
