import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { Classification } from "./Classification";

type MedicalFormInfoProps = {
  title: string;
  subTitle: string | number;
  isRow?: boolean;
};
export const MedicalFormInfo: FC<MedicalFormInfoProps> = ({ title, subTitle, isRow = false }) => {
  const theme = useTheme();
  return (
    <Box display={isRow ? "flex" : "block"} gap={1} alignItems='center'>
      <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={500} color={theme.palette.stale.main}>
        {title}
      </Typography>
      {isRow ? <Classification name={String(subTitle)} /> : <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{subTitle}</Typography>}
    </Box>
  );
};
