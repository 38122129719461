import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    height: 120,
    borderRadius: 8,
    overflow: "hidden",
    margin: 16,
    paddingRight: 16,
    background: theme.palette.gray.light,
  },
}));
