import { initializeDomainsConfig } from "@health/domains";
import { initializeMeetoraConfig } from "@health/meetora";
import { initializeSadaConfig } from "@health/sada";
import { initializeCoreConfig } from "@toolkit/core";
import { initializeI18nConfig } from "@toolkit/i18n";
import { initializeMapConfig } from "@toolkit/maps";
import { getEnvVariable } from "./env";

export default function setupPackages() {
  initializeI18nConfig({
    enableTolgeeWizard: getEnvVariable("ENABLE_TOLGEE_WIZARD"),
    tolgeeProjectID: getEnvVariable("TOLGEE_PROJECT_ID"),
    apiUrl: getEnvVariable("TOLGEE_API_URL"),
    apiKey: getEnvVariable("TOLGEE_API_KEY"),
  });
  initializeCoreConfig({
    baseMediaUrl: getEnvVariable("BASE_MEDIA_URL"),
    fileServiceApiUrl: getEnvVariable("FILE_SERVICE_API_URL"),
    utils: {
      getAccessToken: () => {
        const data = localStorage.getItem(
          `oidc.user:${getEnvVariable("KEYCLOAK_REALM_LINK")}:${getEnvVariable("KEYCLOAK_CLIENT_ID_PROVIDER")}`
        );
        return data ? JSON.parse(data)?.access_token : null;
      },
    },
  });
  initializeMapConfig({
    googleApiKey: getEnvVariable("GOOGLE_API_KEY"),
  });
  initializeDomainsConfig({
    fileServiceApiUrl: getEnvVariable("FILE_SERVICE_API_URL"),
    flowiseBaseUrl: getEnvVariable("FLOWISE_BASE_URL"),
    serverUrl: getEnvVariable("SERVER_URL"),
  });
  initializeSadaConfig({
    sadaBaseUrl: getEnvVariable("SADA_BASE_URL"),
    sadaApiEnv: getEnvVariable("SADA_API_ENV"),
    sadaApiBaseUrl: getEnvVariable("SADA_API_BASE_URL"),
  });
  initializeMeetoraConfig({
    meetoraBaseUrl: getEnvVariable("MEETORA_BASE_URL"),
  });
}
