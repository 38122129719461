import { IVitalSignsUpdateFormValues } from "pages/e-visit/VitalSigns/forms/VitalSignUpdate/VitalSignUpdateFormSchema";
import { ReactNode } from "react";

export type IVitalSignUpdateFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IVitalSignsUpdateFormValues;
  };
};

export enum VisitDetailTab {
  VitalSigns = "vitalSigns",
  Notes = "notes",
  Diagnosis = "diagnosis",
  Medications = "medications",
  LabTests = "labTests",
  Radiology = "radiology",
  Referrals = "referrals",
}

export type ISideButton = {
  id: VisitDetailTab;
  name: string;
  icon: ReactNode;
  component?: ReactNode;
};
