/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { HeaderDetailsTabMap } from "../constants";
import { HeaderDetailsTab } from "../types";

export const useHealthPackageOrdersList = () => {
  const [selectedTab, setSelectedTab] = useState<HeaderDetailsTab>(HeaderDetailsTabMap.find(t => t.statusEnums?.length === 0)!);

  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({ title: t("Health Packages Orders"), values: [] });
  }, []);

  const onChangeTab = (tab: HeaderDetailsTab) => {
    setSelectedTab(tab);
  };

  return {
    onChangeTab,
    selectedTab,
  };
};
