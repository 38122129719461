const darkBlue = "#1B2346";
const orange = "#ED7B15";
const grey = "#E4EAF0";
const green = "#56B112";
const imgBoxShadow = "0px 3px 6px #00000029";
const rootBoxShadow = "0px 3px 6px #0000001A";
const red = "#CB382A";
const white = "#FFFFFF";
const lightGrey = "#C9D5E2";
const border = "1px solid " + darkBlue;
const lightRed = "#CE5145";
const yellow = "#D1A700";

export const patientProfileColors = {
  border,
  lightRed,
  darkBlue,
  orange,
  grey,
  green,
  imgBoxShadow,
  rootBoxShadow,
  red,
  white,
  lightGrey,
  yellow,
};
