import { Alert, Box, Button, IconButton, MuiArrowBackIcon, MuiArrowForwardIcon, Typography, useAddToast } from "@toolkit/ui";
import {
  GuidedCareJourneyInterventionsActivitiesModal,
  GuidedCareJourneyInterventionsActivitiesModalApi,
} from "../../../modals/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivitiesModal";
import React, { FC, useState } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useGuidedCareJourneyEligibleInterventionsAlertStyle } from "./useGuidedCareJourneyEligibleInterventionsAlertStyle";
import { GuidedCareJourneyTemplateInterventionListDocument, useGuidedCareJourneyInterventionActivateMutation } from "../../../gql";
import { GuidedCareJourneyIntervention, GuidedCareJourneyStatus } from "@health/queries/types";
import { IGuidedCareJourneyInterventionsActivitiesEvent } from "../GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivities";
import { formatGraphQLError } from "@toolkit/apollo";
import moment from "moment";
import { useProfileContext } from "shared/modules/patient/context/ProfileContext";

type GuidedCareJourneyEligibleInterventionsAlertProps = {
  eligibleInterventions: GuidedCareJourneyIntervention[];
  guidedCareJourneyId: string;
  patientName: string;
};

export const GuidedCareJourneyEligibleInterventionsAlert: FC<GuidedCareJourneyEligibleInterventionsAlertProps> = props => {
  const { eligibleInterventions, guidedCareJourneyId, patientName } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const { userId, selectedHealthProgram } = useProfileContext();

  const { classes, theme } = useGuidedCareJourneyEligibleInterventionsAlertStyle();

  const currentItem = eligibleInterventions?.[currentIndex];

  const [activateGuidedCareJourneyIntervention, { loading: isSubmitting }] = useGuidedCareJourneyInterventionActivateMutation({
    onCompleted: mutationData => {
      if (mutationData?.guidedCareJourneyInterventionActivate?.id) {
        succeeded(t("Intervention has been successfully activated for the patient"));
      } else {
        failed(t("Failed to activate interventions for the patient"));
      }

      GuidedCareJourneyInterventionsActivitiesModalApi.close();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: GuidedCareJourneyTemplateInterventionListDocument,
        variables: {
          first: 1,
          filter: {
            healthProgramId: selectedHealthProgram,
            patientsIds: [userId!],
            statuses: [GuidedCareJourneyStatus.InProgress],
          },
        },
      },
    ],
  });

  const onArrowBackIconClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : eligibleInterventions?.length - 1));
  };

  const onArrowForwardIconClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < eligibleInterventions?.length - 1 ? prevIndex + 1 : 0));
  };

  const onTakeActionClick = () => {
    GuidedCareJourneyInterventionsActivitiesModalApi.open({ eligibleInterventions });
  };

  const onGuidedCareJourneyInterventionsActivitiesModalChange = (event: IGuidedCareJourneyInterventionsActivitiesEvent) => {
    activateGuidedCareJourneyIntervention({
      variables: {
        input: {
          guidedCareJourneyId,
          interventionActivationType: event.type,
          templateInterventionId: event.payload.selectedIntervention?.id,
          startDate: moment(event.payload.selectedDate).format(),
          templateInterventionActivityItemsIds: event.payload.interventionActivitiesItems?.map(item => item?.id) || [],
        },
      },
    });
  };

  return (
    <Box className={classes.root}>
      <GuidedCareJourneyInterventionsActivitiesModal
        isLoading={isSubmitting}
        onChange={onGuidedCareJourneyInterventionsActivitiesModalChange}
      />

      <Alert severity={"warning"} className={classes.alert}>
        <Box className={classes.wrapper}>
          <Box className={classes.messageWrapper}>
            <Typography>
              {t("{{patient}} has {{intervention}}", {
                patient: patientName || "Patient",
                intervention: pickLocalizedValue(currentItem?.templateIntervention?.name, currentItem?.templateIntervention?.nameAr!),
              })}
              .
            </Typography>

            <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{t("Immediate intervention recommended")}.</Typography>
          </Box>

          <Box className={classes.actionsWrapper}>
            <Button color={"secondary"} disabled={isSubmitting} className={classes.button} onClick={onTakeActionClick}>
              {t("Take Action")}
            </Button>

            <Box className={classes.iconButtonsWrapper}>
              <IconButton disabled={eligibleInterventions?.length === 1} className={classes.iconButtonPrev} onClick={onArrowBackIconClick}>
                <MuiArrowBackIcon color={"primary"} className={classes.icon} />
                <Typography className={classes.iconButtonPrevText}>{t("Prev")}</Typography>
              </IconButton>

              <Box
                className={classes.circle}
                bgcolor={eligibleInterventions?.length === 1 ? theme.palette.gray.main : theme.palette.primary.main}
              />

              <IconButton disabled={eligibleInterventions?.length === 1} onClick={onArrowForwardIconClick}>
                <Typography className={classes.iconButtonNextText}>{t("Next")}</Typography>
                <MuiArrowForwardIcon color={"primary"} className={classes.icon} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Alert>
    </Box>
  );
};
