import { useTranslation } from "@toolkit/i18n";
import { CircularProgress, ListItem } from "@toolkit/ui";

export const CoPilotLoading = () => {
  const { t } = useTranslation("provider");
  return (
    <ListItem sx={{ alignItems: "center", justifyContent: "center" }}>
      <CircularProgress size={14} sx={{ mx: 2 }} /> {t("loading")}....
    </ListItem>
  );
};
