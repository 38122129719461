const PrescriptionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.834 24.54' width={18.834} height={24.54} {...props}>
      <path
        d='M18.322 11.248h-2.217a1.024 1.024 0 0 0-.9.533l-2.114 3.871h-.175L9.364 8.92a4.357 4.357 0 0 0 2.483-4.12c0-3.007-2.039-4.8-5.44-4.8H1.022A1.023 1.023 0 0 0 0 1.023v12.27a1.023 1.023 0 0 0 1.022 1.023h1.659A1.023 1.023 0 0 0 3.7 13.293V9.465h2.191l4.768 8.369-3.608 5.93a.511.511 0 0 0 .438.776H9.6a1.022 1.022 0 0 0 .875-.494l2.244-3.716h.175l2.226 3.713a1.02 1.02 0 0 0 .876.5h2.3a.511.511 0 0 0 .433-.784L15.01 17.87l3.742-5.836a.511.511 0 0 0-.43-.786ZM3.7 6.852V2.8h2.18a1.979 1.979 0 0 1 2.18 2.038 1.926 1.926 0 0 1-2.17 2.018Z'
        fill='#fff'
      />
    </svg>
  );
};

export default PrescriptionIcon;
