import { IEnumOption } from "@health/enum-options";
import { GuidedCareJourneyItem, GuidedCareJourneyItemSource, TeamMemberPosition } from "@health/queries/types";
import { useState } from "react";
import { JourneyActivityFilter, JourneyActivityScope, JourneyDurationPeriod } from "shared/modules/patient/types";

export const usePatientTimeLineHooks = () => {
  const [selectItem, setSelectItem] = useState<GuidedCareJourneyItem | undefined>(undefined);
  const [duration, setDuration] = useState<JourneyDurationPeriod>(JourneyDurationPeriod.Annual);
  const [source, setSource] = useState<GuidedCareJourneyItemSource | undefined>();
  const [hasSimilar, setHasSimilar] = useState(false);
  const [selectedTeamMemberPosition, setSelectedTeamMemberPosition] = useState<TeamMemberPosition | undefined>(undefined);
  const [selectedMe, setSelectedMe] = useState<JourneyActivityScope>(JourneyActivityScope.AllActivities);
  const [selectedActivities, setSelectedActivities] = useState<JourneyActivityFilter>(JourneyActivityFilter.HideMedicalMessages);

  const handleSelectItem = (item: GuidedCareJourneyItem) => {
    item?.id === selectItem?.id ? setSelectItem(undefined) : setSelectItem({ ...item });
    setHasSimilar(false);
  };

  const handleHasSimilar = () => {
    setHasSimilar(!hasSimilar);
  };

  const handleDuration = (item: IEnumOption<JourneyDurationPeriod>) => {
    setDuration(item?.value);
  };

  const handleSelectedMe = (item: IEnumOption<JourneyActivityScope>) => {
    setSelectedMe(item?.value);
  };

  const handleChangeSource = (item: IEnumOption<GuidedCareJourneyItemSource>) => {
    setSource(item?.value);
  };

  const handleSelectedShowActivities = (item: IEnumOption<JourneyActivityFilter>) => {
    setSelectedActivities(item?.value);
  };

  const handleSelectedTeamMemberPosition = (item: IEnumOption<TeamMemberPosition>) => {
    setSelectedTeamMemberPosition(item?.value!);
  };

  return {
    source,
    duration,
    hasSimilar,
    selectedMe,
    selectItem,
    selectedActivities,
    selectedTeamMemberPosition,
    handleDuration,
    handleSelectItem,
    handleHasSimilar,
    handleSelectedMe,
    handleChangeSource,
    handleSelectedShowActivities,
    handleSelectedTeamMemberPosition,
  };
};
