import { OrdersPageWrapper } from "./header";
import { OrdersPageContent } from "./Orders.content";
import { OrderPageProvider } from "./state";

export const OrdersScreen = () => {
  return (
    <OrderPageProvider>
      <OrdersPageWrapper>
        <OrdersPageContent />
      </OrdersPageWrapper>
    </OrderPageProvider>
  );
};
