import { makeStyles } from "@toolkit/ui";

export const useDoctorsSearchInputStyles = makeStyles()(theme => ({
  textFieldRoot: {
    boxShadow: theme.mixins.shadows.sm,
  },
  textField: {
    "& .MuiInputBase-inputHiddenLabel": {
      paddingTop: 5,
      paddingBottom: 5,
    },
    "& .MuiIconButton-root": {
      height: 38,
    },
  },
}));
