import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const Translate: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='25.555' height='25.555' viewBox='0 0 25.555 25.555' {...props}>
      <path
        id='icons8-translation'
        d='M6.323,4A2.34,2.34,0,0,0,4,6.323V19.1a2.34,2.34,0,0,0,2.323,2.323h5.808v5.808a2.323,2.323,0,0,0,2.323,2.323H27.232a2.323,2.323,0,0,0,2.323-2.323V14.454a2.323,2.323,0,0,0-2.323-2.323H21.424V6.323A2.34,2.34,0,0,0,19.1,4Zm0,2.323H19.1v6.969L13.293,19.1H6.323Zm5.808,1.742V9.808H8.646v1.162h5.808a4.1,4.1,0,0,1-1.441,3.589,4.091,4.091,0,0,1-.894.5,5.1,5.1,0,0,1-1.023-.644,3.274,3.274,0,0,1-1.289-2.28H8.646a4.3,4.3,0,0,0,1.7,3.169c.069.058.153.1.225.159a12,12,0,0,1-1.926.157v1.162a11.077,11.077,0,0,0,3.435-.458,6.434,6.434,0,0,0,2.373.458V15.616a5.263,5.263,0,0,1-.824-.077c.028-.02.059-.036.086-.057a5.357,5.357,0,0,0,1.9-4.512h1.162V9.808H13.293V8.066Zm7.437,8.685h2.545l2.752,8.185H22.6l-.51-1.8H19.414l-.526,1.8H16.823Zm1.139,1.844-.9,3.045h1.883l-.878-3.045Z'
        transform='translate(-4 -4)'
        fill={palette.success.main}
      />
    </SvgIcon>
  );
};
export default Translate;
