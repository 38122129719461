import { useReducer } from "react";
import { ActionsEnum } from "./actions";
import { OrderPageStateType } from "./types";
import { initialState } from "./utils";

const reducer = (state: OrderPageStateType, action): OrderPageStateType => {
  switch (action?.type) {
    case ActionsEnum.ChangeView:
      return { ...state, selectedView: state.selectedView === "Tabs" ? "Queue" : "Tabs" };
    case ActionsEnum.ToggleDeliveringColumnVisible:
      return {
        ...state,
        isDeliveringColumnVisible: !state.isDeliveringColumnVisible,
        layoutGridWidth: state.isDeliveringColumnVisible ? 4 : 3,
      };
    case ActionsEnum.ChangeSelectedTab:
      return { ...state, selectedTab: action?.value };
    case ActionsEnum.GetOrders: {
      return {
        ...state,
        loading: action?.value?.loading,
        data: {
          ...state?.data!,
          [action.value.column]: {
            ...action?.value?.info,
            data: action?.value?.data,
          },
        },
      };
    }
    case ActionsEnum.ColumnFetchMore:
      return {
        ...state,
        loading: action?.value?.loading,
        data: {
          ...state?.data!,
          [action.value.column]: {
            ...state?.data?.[action.value.column],
            ...action?.value?.info,
            data: [...(state?.data?.[action.value.column]?.data || []), ...(action?.value?.data || [])],
          },
        },
      };
    case ActionsEnum.FilterData: {
      return {
        ...state,
        filter: action?.value,
      };
    }

    default: {
      const message = "unhandled state action change" + JSON.stringify({ ...action });
      if (process.env.NODE_ENV === "development") throw new Error(message);
      console.log({ message });
      return { ...state };
    }
  }
};

export const useOrderPageReducer = () => {
  return useReducer(reducer, initialState);
};
