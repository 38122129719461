import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles<{ cardWidth?: string }>()((theme, { cardWidth = "510px" }) => ({
  root: {
    color: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
    borderRadius: 15,
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    width: cardWidth,
    minWidth: cardWidth,
    height: 151,
  },
}));
