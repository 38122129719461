import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    height: 120,
    background: theme.palette.primary[50],
    borderRadius: 8,
    cursor: "pointer",
    marginBottom: 8,
  },
  content: {
    padding: "16px 0",
  },
  avatar: {
    width: 75,
    height: 75,
    boxShadow: theme.mixins.shadows.main,
  },
}));
