import { Doctor } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, Grid, Rating, RatingIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./DoctorCard.styles";
import { getDoctorPhoto } from "utils";

interface DoctorCardProps {
  doctor: Doctor;
  onClick: (doctor: Doctor) => void;
}

export const DoctorCard: FC<DoctorCardProps> = ({ doctor, onClick: handleClick }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();
  return (
    <Grid container className={classes.root} onClick={() => handleClick(doctor)}>
      <Grid item xs={3} container alignItems='center' justifyContent='center'>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Avatar src={getDoctorPhoto({ photo: doctor?.user?.photo, gender: doctor?.user?.gender })} className={classes.avatar} />
        </Box>
      </Grid>
      <Grid item xs className={classes.content}>
        <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight='bold'>
          {doctor.user?.fullName}
        </Typography>
        <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{doctor.experiences?.[0]?.jobTitle ?? "-"} </Typography>
        <Rating
          value={doctor.totalRatings}
          icon={<RatingIcon fill={theme.palette.primary.main} />}
          disabled
          emptyIcon={<RatingIcon fill={theme.palette.gray[500]} />}
        />
        <Typography fontSize={theme.mixins.fonts.fontSize.xs}>
          {doctor.ratingsSum} {t("Ratings")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DoctorCard;
