import { TFunction } from "@toolkit/i18n";
import { profilePaths } from "./route";

export const profileBreadCrumb = (t: TFunction) => {
  return {
    id: profilePaths.indexPage.fullPath,
    name: t("Profile", { ns: "provider" }),
    route: profilePaths.indexPage.fullPath,
  };
};
export const profileEditBreadCrumb = (t: TFunction) => {
  return {
    id: profilePaths.edit.fullPath,
    name: t("Edit", { ns: "provider" }),
    // route: profilePaths.edit.fullPath.replace(":id", profileId)
  };
};
