import { useReactiveVar } from "@apollo/client";
import { Appointment, Visit } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useDoctorsQuery } from "gql/queries";
import { produce } from "immer";
import { useMemo } from "react";
import { NurseCurrentWorkSpaceDetailsVar } from "shared/components/NurseCurrentWorkDetails";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isDoctorUser, isNurseUser } from "utils";

export const useGetDoctorsFilters = (selfOnly: boolean): CustomTableColumnProps<Partial<Visit | Appointment>>[] => {
  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();
  const filterObject = {
    key: "doctors",
    header: t("Doctors"),
    showOnlyForFilterField: true,
    filter: {
      type: "autocomplete" as const,
      name: "doctors",
      filterSearchKey: "name",
      multiple: true,
    },
  };
  const currentNurseWorkDetails = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);

  const nurseDoctorsOptions = currentNurseWorkDetails?.doctors?.map(doctor => ({
    value: doctor.id,
    name: doctor.name,
    label: doctor.name,
  }));

  return useMemo<CustomTableColumnProps<Partial<Visit | Appointment>>[]>(() => {
    if (isDoctorUser(userInfo) && selfOnly) {
      return [];
    }
    if (isNurseUser(userInfo)) {
      return [
        produce(filterObject, draft => {
          draft.filter["getOptionLabel"] = option => option?.label!;
          draft.filter["getValueForBackend"] = options => options?.map(item => item?.value);
          draft.filter["options"] = nurseDoctorsOptions || [];
          draft.filter["initialValue"] = "nurseDoctorsOptions" || [];
        }),
      ];
    }

    return [
      produce(filterObject, draft => {
        draft.filter["query"] = useDoctorsQuery;
        draft.filter["getOptionLabel"] = option => option?.user?.fullName!;
        draft.filter["getValueForBackend"] = options => options?.map(item => item?.id);
        draft.filter["accessor"] = "doctors";
      }),
    ];
  }, [t, selfOnly, currentNurseWorkDetails]);
};
