import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { DepartmentsAutocomplete } from "@health/autocompletes";
import { medicalFormCategoryOptions, medicalFormTypeOptions } from "@health/enum-options";
export const MedicalFormInformationForm = props => {
    const { isAdmin } = props;
    const { t } = useTranslation("domains");
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: "name", label: t("Name") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: "nameAr", label: t("Arabic Name") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: "code", label: t("Code") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormAutocomplete, { name: "type", label: t("Type"), options: medicalFormTypeOptions }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormAutocomplete, { name: "category", label: t("Category"), options: medicalFormCategoryOptions }) }), !isAdmin && (_jsx(Grid, { item: true, xs: 4, children: _jsx(DepartmentsAutocomplete, { name: "department" }) })), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: "description", multiline: true, rows: 5, placeholder: t("Description"), label: t("Description") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: "welcomeCard", multiline: true, rows: 5, label: t("Welcome Card") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: "welcomeCardAr", multiline: true, rows: 5, label: t("Welcome Card (Arabic)") }) })] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormSwitch, { name: "active", label: t("Is Active") }) })] }));
};
