/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVisitPrescriptionMutationVariables = Types.Exact<{
  createPrescriptionInput?: Types.InputMaybe<Types.CreateVisitPrescriptionInput>;
}>;


export type CreateVisitPrescriptionMutation = { __typename?: 'Mutation', createVisitPrescription?: { __typename?: 'VisitPrescriptionTransaction', id: string, referenceId?: string | null, type?: string | null, activities?: Array<{ __typename?: 'VisitPrescriptionActivity', code?: string | null, denialCode?: string | null, denialDescription?: string | null, duration?: string | null, frequencyValue?: number | null, frequencyUnitType?: string | null, id: string, instructions?: string | null, observation?: string | null, quantity?: number | null, refills?: number | null } | null> | null } | null };


export const CreateVisitPrescriptionDocument = gql`
    mutation CreateVisitPrescription($createPrescriptionInput: CreateVisitPrescriptionInput) {
  createVisitPrescription(createPrescriptionInput: $createPrescriptionInput) {
    activities {
      code
      denialCode
      denialDescription
      duration
      frequencyValue
      frequencyUnitType
      id
      instructions
      observation
      quantity
      refills
    }
    id
    referenceId
    type
  }
}
    `;
export type CreateVisitPrescriptionMutationFn = Apollo.MutationFunction<CreateVisitPrescriptionMutation, CreateVisitPrescriptionMutationVariables>;

/**
 * __useCreateVisitPrescriptionMutation__
 *
 * To run a mutation, you first call `useCreateVisitPrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitPrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitPrescriptionMutation, { data, loading, error }] = useCreateVisitPrescriptionMutation({
 *   variables: {
 *      createPrescriptionInput: // value for 'createPrescriptionInput'
 *   },
 * });
 */
export function useCreateVisitPrescriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateVisitPrescriptionMutation, CreateVisitPrescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVisitPrescriptionMutation, CreateVisitPrescriptionMutationVariables>(CreateVisitPrescriptionDocument, options);
      }
export type CreateVisitPrescriptionMutationHookResult = ReturnType<typeof useCreateVisitPrescriptionMutation>;
export type CreateVisitPrescriptionMutationResult = Apollo.MutationResult<CreateVisitPrescriptionMutation>;
export type CreateVisitPrescriptionMutationOptions = Apollo.BaseMutationOptions<CreateVisitPrescriptionMutation, CreateVisitPrescriptionMutationVariables>;