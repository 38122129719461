import { getMaxValueValidation, patternEmail } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";

export const generalInformationFields = (t: TFunction, defaultValue) => [
  {
    name: "name",
    label: t("Branch Name", { ns: "provider" }),
    defaultValue: defaultValue?.name,
    maxValue: getMaxValueValidation(t, 255),
  },
  {
    name: "nameAr",
    label: t("Branch Arabic Name", { ns: "provider" }),
    defaultValue: defaultValue?.nameAr,
  },
  {
    name: "description",
    label: t("Description", { ns: "provider" }),
    defaultValue: defaultValue?.description,
  },
  {
    name: "contactEmail",
    label: t("Contact Email", { ns: "provider" }),
    defaultValue: defaultValue?.contactEmail,
    pattern: patternEmail(t),
  },
  {
    name: "healthLicense",
    label: t("Health License", { ns: "provider" }),
    defaultValue: defaultValue?.healthLicense,
  },
];
