import { useReactiveVar } from "@apollo/client";
import { useOrderTimeoutAlert } from "@health/sse";
import { AdminHeaderItemProps, Layout, useBreadCrumbs } from "@toolkit/ui";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Notifications, OrderAlert } from "shared/components";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { openVar } from "shared/reactiveVariables";
import { ChatStatus } from "../Chat/ChatStatus";
import { DoctorAvailabilityStatus } from "../DoctorAvailabilityStatus";
import { UserWorkspaceSelection } from "../UserWorkspaceSelection";
import { DoctorLicenseNotification } from "../VisitStartingNowNotification/DoctorLicense";
import { UserProfileMenu } from "./UserProfileMenu";
import { routeWithSelectedItems } from "./utils";

export function LayoutComponent({ routes }: { routes: any }) {
  const navigate = useNavigate();
  const { userInfo } = useOidcUserProfile();

  const [orderTimeoutInfo, setOrderTimeoutInfo] = useState<{ orderId: string; open: boolean; message: string } | undefined>(undefined);

  const { values } = useBreadCrumbs();

  const handleGoToHome = () => {
    navigate("/");
  };

  const open = useReactiveVar(openVar);

  const handleToggleDrawer = () => {
    openVar(!openVar());
  };

  const [menuItems, setMenuItems] = useState<Array<AdminHeaderItemProps>>([
    {
      id: "current-working-selection",
      renderer: <UserWorkspaceSelection />,
    },
    {
      id: "chat",
      renderer: <ChatStatus />,
    },
    {
      id: "notifications",
      renderer: <Notifications />,
    },
    {
      id: "admin",
      renderer: <UserProfileMenu />,
    },
  ]);

  useEffect(() => {
    const doesHaveDoctorAvailabilityStatusItem = menuItems.findIndex(item => item.id === "doctorAvailabilityStatus") < 0;
    if (doesHaveDoctorAvailabilityStatusItem && Boolean(userInfo?.doctor_id) && Boolean(userInfo?.vendor_id)) {
      // * Add Doctor Availability Status menu to doctor users
      setMenuItems(state => {
        const stateHasNoAvailabilityStatusItem = state.findIndex(item => item.id === "doctorAvailabilityStatus") < 0;
        if (stateHasNoAvailabilityStatusItem)
          return _.concat(
            {
              id: "doctorAvailabilityStatus",
              renderer: <DoctorAvailabilityStatus />,
            },
            [...state]
          );
        else {
          return state;
        }
      });
    }
  }, [JSON.stringify(userInfo)]);

  useOrderTimeoutAlert(({ data }) => {
    setOrderTimeoutInfo({ open: true, ...data });
  });

  const handleReset = () => {
    setOrderTimeoutInfo({ open: false, orderId: "", message: "" });
  };

  const drawerItems = routeWithSelectedItems(routes);

  return (
    <Fragment>
      {orderTimeoutInfo?.open && (
        <OrderAlert
          isOpen={orderTimeoutInfo?.open!}
          orderId={orderTimeoutInfo?.orderId}
          message={orderTimeoutInfo?.message}
          onReset={handleReset}
        />
      )}

      {routes && (
        <Layout
          title={values?.title!}
          isOpen={open}
          drawerItems={drawerItems}
          onGoToHome={handleGoToHome}
          onToggleDrawer={handleToggleDrawer}
          rightItems={menuItems}
        >
          <DoctorLicenseNotification />
          <Outlet />
        </Layout>
      )}
    </Fragment>
  );
}
