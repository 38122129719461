const ExpandViewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.513 25.513' width={25.513} height={25.513} {...props}>
      <path
        d='M16.2 13.426a.925.925 0 0 0 0-1.339l-4.177-4.174a.885.885 0 0 0-.654-.255.936.936 0 0 0-.686.287 1 1 0 0 0-.255.686.885.885 0 0 0 .255.654l3.477 3.471-3.508 3.508a.885.885 0 0 0-.255.654.936.936 0 0 0 .287.686 1.007 1.007 0 0 0 1.339 0Zm9.312-.67a12.939 12.939 0 0 1-.973 5.039A12.347 12.347 0 0 1 17.8 24.54a12.939 12.939 0 0 1-5.043.973 12.856 12.856 0 0 1-5.007-.973 12.45 12.45 0 0 1-4.05-2.695A12.718 12.718 0 0 1 0 12.756 12.654 12.654 0 0 1 12.757 0a12.718 12.718 0 0 1 9.089 3.7 12.45 12.45 0 0 1 2.695 4.05 12.856 12.856 0 0 1 .972 5.006Z'
        fill='#fff'
      />
    </svg>
  );
};

export default ExpandViewIcon;
