import { useFormContext } from "react-hook-form";

export const useGetFormSelectedValues = () => {
  const { watch } = useFormContext();
  const selectedBranch = watch("branch");
  const selectedDepartment = watch("department");

  return {
    selectedBranch,
    selectedDepartment,
  };
};
