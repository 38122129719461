import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {},
  button: {},
  grid: {
    paddingTop: "10px",
  },
  formGrid: {
    paddingTop: "10px",
    width: "100%",
  },
  resetButton: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: theme.palette.primary.main + "!important",
    ":hover": {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: theme.palette.primary.main + "!important",
    },
  },
}));
