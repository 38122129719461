import { IsFeatureEnabledType } from "@health/domains";
import { PermissionEnum } from "@health/queries/types";
import { hasAnyPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ProductIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
// Pages
import { healthPackageOrdersRoutes } from "../HealthPackageOrders/route";
import { marketplaceOrdersInvoicesRoutes } from "../MarketplaceOrderInvoices";
import { marketplaceOrdersTrackingRoutes } from "../MarketplaceOrderTracking/route";
import { ordersRoutes } from "../Orders/route";
import { productOrdersRoutes } from "../ProductOrders/route";

export const globalOrdersRoutes: (props: {
  navigate: NavigateFunction;
  t: TFunction;
  isFeatureEnabled: IsFeatureEnabledType;
}) => RouteItem = ({ navigate, t, isFeatureEnabled }) => {
  const isOrdersRootHidden = !hasAnyPermission([PermissionEnum.ManageMarketplaceOrders, PermissionEnum.ManageOrders]);

  return {
    id: "global-orders-routes",
    text: t("Orders", { ns: "provider" }),
    icon: <ProductIcon />,
    isProhibited: isOrdersRootHidden,
    hidden: isOrdersRootHidden,
    subItems: [
      ordersRoutes({ navigate, t }),
      healthPackageOrdersRoutes({ navigate, t, isFeatureEnabled }),
      productOrdersRoutes({ navigate, t, isFeatureEnabled }),
      marketplaceOrdersTrackingRoutes({ navigate, t }),
      marketplaceOrdersInvoicesRoutes({ navigate, t }),
    ],
  };
};
