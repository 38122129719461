import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  listContainer: {
    overflow: "auto",
    height: "420px",
    padding: 8,
  },
  emptyCard: {
    height: "420px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));
