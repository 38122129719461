import { useReactiveVar } from "@apollo/client";
import { NotificationType, SortDirection, UserNotificationField } from "@health/queries/types";
import { isNotificationMenuOpenedVar } from "@toolkit/ui";
import { MeNotificationsQuery, useMeNotificationsQuery } from "pages/Notifications/gql";
import { notificationsPaths } from "pages/Notifications/route";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useNotificationsHooks = ({
  unReadMessagesCount = 0,
  lastNotificationsCountChangeAction,
}: {
  unReadMessagesCount?: number;
  lastNotificationsCountChangeAction?: NotificationType;
}) => {
  const navigate = useNavigate();
  const isNotificationMenuOpened = useReactiveVar(isNotificationMenuOpenedVar);

  const { data, loading, fetchMore, refetch } = useMeNotificationsQuery({
    variables: {
      first: 10,
      sortBy: {
        field: UserNotificationField.CreationDate,
        direction: SortDirection.Desc,
      },
      filter: {
        seen: false,
      },
    },
  });
  const notifications = data?.me?.notifications?.edges?.map(item => item?.node);
  const totalCount = data?.me?.notifications?.totalCount;
  const unReadNotificationsCount = data?.me?.unReadMessagesCount;
  const pageInfo = data?.me?.notifications?.pageInfo;

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: MeNotificationsQuery, { fetchMoreResult }) => {
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            me: {
              ...fetchMoreResult?.me,
              notifications: {
                ...fetchMoreResult?.me?.notifications,
                edges: [...(prev?.me?.notifications?.edges || []), ...(fetchMoreResult?.me?.notifications?.edges || [])],
              },
            },
          });
        },
      });
    }
  };

  useEffect(() => {
    if (isNotificationMenuOpened && lastNotificationsCountChangeAction !== NotificationType.MarkAsRead) refetch();
  }, [unReadMessagesCount, refetch]);

  useEffect(() => {
    if (isNotificationMenuOpened) refetch();
  }, [isNotificationMenuOpened, refetch]);

  const handleGoToNotificationScreen = () => {
    navigate(notificationsPaths.indexPage.fullPath);
  };

  return {
    loading,
    pageInfo,
    totalCount,
    notifications,
    unReadNotificationsCount,
    fetchMoreData,
    handleGoToNotificationScreen,
  };
};
