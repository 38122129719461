import { InMemoryCacheConfig } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
// import { relayStylePagination } from "@apollo/client/utilities";

export const cacheUtils: InMemoryCacheConfig = {
  typePolicies: {
    doctor: {
      merge: true,
    },
    Visit: {
      merge: true,
    },
    Patient: {
      merge: true,
    },
    User: {
      merge: true,
    },
    Query: {
      fields: {
        // categories: relayStylePagination(),
        // vendors: relayStylePagination(),
        getCodeSystems: relayStylePagination(),
      },
    },
  },
};
