import React from "react";
import { useSearchParams } from "react-router-dom";
import { Radiology } from "./Radiology";

type RadiologyPageProps = {
  visitId?: string;
};

export default function RadiologyPage(props: RadiologyPageProps): React.ReactElement<unknown> {
  const [params] = useSearchParams();
  const visitId = params.get("visitId") || props.visitId;
  const token = params.get("token");
  return (
    <React.Fragment>
      <Radiology token={token!} visitId={visitId!} />
    </React.Fragment>
  );
}
