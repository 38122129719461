/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentReminderTimeSecondsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppointmentReminderTimeSecondsQuery = { __typename?: 'Query', siteSettings: { __typename?: 'SiteSettings', appointmentReminderTimeSeconds?: number | null } };


export const AppointmentReminderTimeSecondsDocument = gql`
    query AppointmentReminderTimeSeconds {
  siteSettings {
    appointmentReminderTimeSeconds
  }
}
    `;

/**
 * __useAppointmentReminderTimeSecondsQuery__
 *
 * To run a query within a React component, call `useAppointmentReminderTimeSecondsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentReminderTimeSecondsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentReminderTimeSecondsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentReminderTimeSecondsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentReminderTimeSecondsQuery, AppointmentReminderTimeSecondsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentReminderTimeSecondsQuery, AppointmentReminderTimeSecondsQueryVariables>(AppointmentReminderTimeSecondsDocument, options);
      }
export function useAppointmentReminderTimeSecondsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentReminderTimeSecondsQuery, AppointmentReminderTimeSecondsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentReminderTimeSecondsQuery, AppointmentReminderTimeSecondsQueryVariables>(AppointmentReminderTimeSecondsDocument, options);
        }
export type AppointmentReminderTimeSecondsQueryHookResult = ReturnType<typeof useAppointmentReminderTimeSecondsQuery>;
export type AppointmentReminderTimeSecondsLazyQueryHookResult = ReturnType<typeof useAppointmentReminderTimeSecondsLazyQuery>;
export type AppointmentReminderTimeSecondsQueryResult = Apollo.QueryResult<AppointmentReminderTimeSecondsQuery, AppointmentReminderTimeSecondsQueryVariables>;