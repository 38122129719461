import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { VitalSignUpdateForm } from "../../forms/VitalSignUpdate/VitalSignUpdateForm";
import { convertVisitVitalSignsFormValuesToBackEndValues } from "../../others";
import { IVitalSignUpdateFormEvent } from "../../types";
import { usePatientHealthParametersInsertMutation } from "../../gql";

type VitalSignUpdateContainerProps = {
  visitId: string;
  token?: string;
};

export const VitalSignUpdateContainer: FC<VitalSignUpdateContainerProps> = props => {
  const { visitId, token } = props;

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [insertPatientHealthParameters, { loading: isSubmitting }] = usePatientHealthParametersInsertMutation({
    onCompleted: () => {
      succeeded(t("Visit vital signs updated successfully"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFormToken(token!),
  });

  const onVisitVitalSignUpdateFormChange = (event: IVitalSignUpdateFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertVisitVitalSignsFormValuesToBackEndValues(event.payload.values);

      insertPatientHealthParameters({
        variables: {
          visitId,
          input: values,
        },
      });
    }
  };

  return <VitalSignUpdateForm visitId={visitId} token={token} isSubmitting={isSubmitting} onChange={onVisitVitalSignUpdateFormChange} />;
};
