import { useTranslation } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type AppointmentItemProps = {
  appointmentDate: string;
  timeframe: string;
};

export const AppointmentDateTime: FC<AppointmentItemProps> = ({ appointmentDate, timeframe }) => {
  const { t } = useTranslation("provider");
  const [dayOfWeek, dayOfMonth, month, year] = appointmentDate.split(/,\s|\s/);
  const translatedTimeFrame = timeframe.replace(/(\d+:\d+)([APMapm]+)/g, (_, time, period) => {
    const translatedPeriod = period;
    return `${time}${t(translatedPeriod)}`;
  });
  const theme = useTheme();
  return (
    <>
      <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.sm}>
        {t("Appointment Date")}:{" "}
        <Typography component='span' variant='body2'>
          {`${t(dayOfWeek)}, ${dayOfMonth} ${t(month)} ${year}`}
        </Typography>
      </Typography>

      <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.sm}>
        {t("Timeframe")}:{" "}
        <Typography component='span' variant='body2'>
          {translatedTimeFrame}
        </Typography>
      </Typography>
    </>
  );
};
