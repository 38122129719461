import { getDepartmentsAutocompleteFilter, getSystemCodeAutocompleteFilter } from "@health/autocompletes";
import { CodeSystemCode, Survey } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useFiltersFields = (): CustomTableColumnProps<Partial<Survey>>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "doctorName",
        header: t("Doctor Name"),
        showOnlyForFilter: true,
        filter: {
          type: "string",
          name: "doctorName",
          label: t("Doctor Name"),
          gridSM: 12,
        },
      },
      {
        key: "department",
        header: t("Department"),
        type: "string",
        showOnlyForFilterField: true,
        filter: {
          ...getDepartmentsAutocompleteFilter({ name: "department" }),
          gridSM: 12,
        },
      },
      {
        key: "specialization",
        header: t("Specialization"),
        type: "string",
        showOnlyForFilterField: true,
        filter: {
          ...getSystemCodeAutocompleteFilter({
            name: "specializations",
            multiple: true,
            isCodeHidden: true,
            queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
          }),
          gridSM: 12,
        },
      },
      {
        key: "minRatingAverage",
        header: t("Rating"),
        showOnlyForFilterField: true,
        filter: {
          type: "radioGroup",
          name: "minRatingAverage",
          gridSM: 12,
          options: new Array(5).fill(undefined).map((_, index) => ({
            label: `${index + 1}`,
            value: `${index + 1}`,
          })),
        },
      },
    ];
  }, []);
};
