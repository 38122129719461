import { Maybe, Patient } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { InsuranceInformation } from "pages/appointments/components";
import { FC } from "react";

type InsuranceInformationModalProps = {
  patient?: Maybe<Patient>;
};

export const InsuranceInformationModal: FC<InsuranceInformationModalProps> = props => {
  const { patient } = props;

  const { t } = useTranslation("provider");

  return (
    <CustomDialog
      type='base'
      DialogTitleProps={{
        title: t("Insurance Information"),
      }}
      button={<ShowButton />}
      DialogActionsProps={{
        hasClose: true,
        closeTitle: t("Done"),
      }}
    >
      <InsuranceInformation
        activeHealthProgramMember={patient?.activeHealthProgramMembers?.[0]}
        healthProgramsByActiveMember={patient?.healthProgramsByActiveMembers?.[0]}
      />
    </CustomDialog>
  );
};
