import { Grid } from "@toolkit/ui";
import React from "react";
import ContentLoader from "react-content-loader";

export const CardSkeleton: React.FC = () => {
  return (
    <Grid
      container
      sx={{
        height: 180,
        overflow: "hidden",
        borderRadius: 2,
      }}
    >
      <ContentLoader width='100%' viewBox='0 0 200 180'>
        <rect x='0' y='0' width='200' height='180' />
      </ContentLoader>
    </Grid>
  );
};
