import { Box, JoinCallIcon, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { VisitContentProps } from "./types";
import { useVisitContentStyle } from "./VisitContent.styles";

export const VisitContent: FC<VisitContentProps> = ({ title, color, children }) => {
  const { classes } = useVisitContentStyle({ color });

  return (
    <Box display='inline-flex'>
      <Typography className={classes.icon}>
        <JoinCallIcon
          sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
          fontSize='small'
          width={15.54}
          height={15.54}
        />
      </Typography>
      <Box display={"list-item"} flexGrow={1}>
        <Typography variant='body2' className={classes.visit}>
          {title}
        </Typography>
        <Box m={1}>{children}</Box>
      </Box>
    </Box>
  );
};
