/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRadiologyListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetRadiologyListQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', rads?: Array<{ __typename?: 'Rad', id: string, code?: string | null, conceptId?: string | null } | null> | null } | null };


export const GetRadiologyListDocument = gql`
    query getRadiologyList($id: ID!) {
  visit(id: $id) {
    rads {
      id
      code
      conceptId
    }
  }
}
    `;

/**
 * __useGetRadiologyListQuery__
 *
 * To run a query within a React component, call `useGetRadiologyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRadiologyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRadiologyListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRadiologyListQuery(baseOptions: Apollo.QueryHookOptions<GetRadiologyListQuery, GetRadiologyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRadiologyListQuery, GetRadiologyListQueryVariables>(GetRadiologyListDocument, options);
      }
export function useGetRadiologyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRadiologyListQuery, GetRadiologyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRadiologyListQuery, GetRadiologyListQueryVariables>(GetRadiologyListDocument, options);
        }
export type GetRadiologyListQueryHookResult = ReturnType<typeof useGetRadiologyListQuery>;
export type GetRadiologyListLazyQueryHookResult = ReturnType<typeof useGetRadiologyListLazyQuery>;
export type GetRadiologyListQueryResult = Apollo.QueryResult<GetRadiologyListQuery, GetRadiologyListQueryVariables>;