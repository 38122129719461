import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, useAutocompleteCustomHook } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { NurseCurrentWorkSpaceDetailsVar } from "../NurseCurrentWorkSpaceDetailsVar";
import { useDoctorsQuery } from "gql/queries";

export const DoctorsFilter: FC = () => {
  const { t } = useTranslation("provider");
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();
  const { watch } = useFormContext();
  const selectedBranch = watch("department");
  const departmentAutocompleteFilterInput = {
    departmentIds: selectedBranch?.id ? [selectedBranch?.id] : [],
    isActive: true,
  };

  const {
    data: doctorsData,
    isLoading: loadingDoctors,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useDoctorsQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
      filter: {
        name: "",
        ...departmentAutocompleteFilterInput,
      },
    },
    dataAccessor: "doctors",
  });
  const branchUserPreference = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const doctorPageInfo = doctorsData?.doctors?.pageInfo;
  const hasNextPage = doctorPageInfo?.hasNextPage;
  const doctors = doctorsData?.doctors?.edges?.map(item => item?.node)!;
  const doctorsOptions = doctors?.map(item => {
    return { name: item?.user?.fullName, id: item?.id };
  });
  const handleSearch = (filter: string) => {
    refetch({
      filter: {
        name: filter,
        ...departmentAutocompleteFilterInput,
      },
    });
  };

  return (
    <AutocompleteController
      ControllerProps={{
        name: "doctors",
        control: control,
        defaultValue: branchUserPreference?.doctors,
      }}
      fullWidth
      loading={loadingDoctors}
      fetchMoreData={onFetchMoreData}
      hasMore={hasNextPage}
      TextFieldProps={{
        label: t("Doctors"),
        placeholder: t("Doctors"),
        error: Boolean(formErrors?.doctors?.message),
        helperText: t(formErrors?.doctors?.message),
      }}
      getOptionLabel={o => o?.name}
      options={doctorsOptions || []}
      isOptionEqualToValue={(option, id) => option?.id === id.id}
      onSearchInput={handleSearch}
      multiple
    />
  );
};
