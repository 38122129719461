import { TFunction } from "@toolkit/i18n";
import { ListViewIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { ProgramsCallsPage } from "./index";

const programsCallsRoute = "/programs-calls";

export const programsCallsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${programsCallsRoute}${this.route}`;
    },
  },
};

export const programsCallsRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: any }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}): RouteItem => {
  return {
    id: "programs-calls",
    route: programsCallsRoute,
    fullPath: programsCallsPaths.indexPage.fullPath,
    text: t("Virtual Visits", { ns: "provider" }),
    icon: <ListViewIcon />,
    element: <ProgramsCallsPage />,
    isProhibited: !userInfo?.doctor_id,
    hidden: !userInfo?.doctor_id,
    onClick: (route: string) => navigate(route),
  };
};
