const usersManagement = "users-management";
const users = "users";
const editUser = "edit/:id";
const newUser = "new";
const permission = "permissions";

const newpPermission = "new";
const editPermission = "update/:id";

export const usersManagementRoutes = {
  name: "Users Management",
  path: usersManagement,
  users: {
    name: "Users",
    path: users,
    fullPath: [usersManagement, users].join("/"),
    edit: {
      name: "Update User",
      path: [users, editUser].join("/"),
      fullPath: [usersManagement, users, editUser].join("/"),
    },
    new: {
      name: "Add User",
      path: [users, newUser].join("/"),
      fullPath: [usersManagement, users, newUser].join("/"),
    },
  },
  permissions: {
    name: "Permission Management",
    path: permission,
    fullPath: [usersManagement, permission].join("/"),
    new: {
      name: "new Permission",
      path: [permission, newpPermission].join("/"),
      fullPath: [usersManagement, permission, newpPermission].join("/"),
    },
    edit: {
      name: "Update Permission",
      path: [permission, editPermission].join("/"),
      fullPath: [usersManagement, permission, editPermission].join("/"),
    },
  },
};
