import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const useIsInEmbedView = () => {
    const { pathname, search } = useLocation();
    const isInEmbedView = pathname.includes("/embed");
    useEffect(() => {
        if (!isInEmbedView || typeof window === "undefined" || window["EnableSSELogging"]) {
            return;
        }
        window["EnableSSELogging"] = search.includes("enableSSELogging");
    }, [isInEmbedView, search]);
    return isInEmbedView;
    return {
        isInEmbedView: pathname.includes("/embed"),
        isInCustomEmbedView: (path) => pathname.includes("/embed/" + path.toLowerCase().replace("/embed/", "")),
    };
};
