import { makeStyles } from "@toolkit/ui";

export const usePatientProfileSectionStyle = makeStyles()(theme => ({
  box: {
    marginBottom: "10px",
  },
  root: {
    boxShadow: theme.mixins.shadows.none,
    marginBottom: "30px",
    background: "none",
  },
  accordionSummary: {
    padding: 0,
    background: "inherit",
  },
  arrowRoot: {
    width: "auto",
    height: "auto",
  },
  detailsRoot: {
    padding: 0,
  },
  span: {
    ["@media (max-width:500px)"]: {
      width: "305px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  link: {
    ["@media (max-width:500px)"]: {
      width: "310px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
}));
