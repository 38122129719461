import { CustomTable, Grid, WaveSurferAudio } from "@toolkit/ui";
import { usePatientHealthParametersHistory } from "../../hooks";
import { usePatientHealthParameterHistoryAudioColumns } from "./usePatientHealthParameterHistoryAudioColumns";
import React, { FC } from "react";

type PatientHealthParameterHistoryAudioProps = {
  patientId: string;
  token: string;
  codes: string[];
};

export const PatientHealthParameterHistoryAudio: FC<PatientHealthParameterHistoryAudioProps> = props => {
  const { patientId, token, codes } = props;

  const { selectedItem, tableProps, handleSelectItem } = usePatientHealthParametersHistory({
    patientId,
    token,
    codes,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {selectedItem?.valueAudioFile && <WaveSurferAudio url={selectedItem?.valueAudioFile} isAutoplayEnabled />}
      </Grid>

      <Grid item xs={12}>
        <CustomTable {...tableProps} columns={usePatientHealthParameterHistoryAudioColumns({ selectedItem, onChange: handleSelectItem })} />
      </Grid>
    </Grid>
  );
};
