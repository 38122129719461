import React, { FC } from "react";
import BranchForm from "../BranchForm.component";
import { useBranchEditPageHook } from "./useBranchEditPageHook";

const EditBranch: FC = () => {
  const { branch, errors, loading, isFetchingUpdate, handleUpdateBranch } = useBranchEditPageHook();
  return (
    <BranchForm isSaving={isFetchingUpdate} isEditMode loading={loading} branch={branch} onSubmit={handleUpdateBranch} errors={errors} />
  );
};

export default EditBranch;
