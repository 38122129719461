import { H_PageInfo, HealthCondition, PatientCurrentMedicalStatus, PatientHealthHistory } from "@health/queries/types";
import { createContext, useContext } from "react";
import { useGetPatientDataQuery } from "../gql";
import { IPatientGuidedCareProgram } from "../types/types";

type PatientInfoType = NonNullable<ReturnType<typeof useGetPatientDataQuery>["data"]>["patient"];

type IProfileContext = {
  userId: string | undefined;
  selectedHealthProgram?: string;
  handleProgramSelected?: (programId?: string) => void;
  loading: boolean | undefined;
  consentStatus: string | undefined;
  checkAccess: boolean;
  patient: PatientInfoType | undefined;
  patientCurrentMedicalStatus: Partial<PatientCurrentMedicalStatus> | undefined | null;
  patientHealthHistory: PatientHealthHistory[] | undefined;
  patientId: string | undefined;
  handleResetStatus?: () => void;
  patientName: string | undefined;
  onClose?: () => void | undefined;
  isOpen?: boolean | undefined;
  token?: string | undefined;
  tokenId?: string | undefined;
  selectedTab?: string;
  handleTabChange?: (newValue: string) => void;
  conditions?: HealthCondition[];
  // GuidedCareHealthProgramTeams
  guidedCareHealthProgramTeamFetchMore: () => void;
  guidedCareHealthProgramTeamPageInfo: H_PageInfo;
  guidedCareHealthPrograms?: IPatientGuidedCareProgram[];
  guidedCareProgramTemplateId?: string;
  guidedCareProgramTeamLeaderPhoto: string | null | undefined;
  guidedCareProgramTeamMembers: {
    key: any; // TODO: Fix any Type ?
    label: string;
    value: any; // TODO: Fix any Type ?
  }[];
  guidedCareProgramTeamMembersWithoutTeamLeader:
    | {
        id: string;
        photo: string;
      }[]
    | undefined;
  hasGuidedCareHealthProgramTeam: boolean;
};

export const ProfileContext = createContext<IProfileContext>({
  patient: undefined,
  userId: "",
  selectedTab: undefined,
  consentStatus: undefined,
  loading: undefined,
  checkAccess: false,
  patientCurrentMedicalStatus: undefined,
  patientHealthHistory: undefined,
  patientId: undefined,
  isOpen: undefined,
  patientName: undefined,
  onClose: undefined,
  handleResetStatus: undefined,
  token: undefined,
  handleTabChange: undefined,
  // CustomGuidedCareHealthProgramTeamsQuery
  guidedCareHealthProgramTeamFetchMore: () => {},
  guidedCareHealthProgramTeamPageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    endCursor: null,
    startCursor: null,
  },
  guidedCareHealthPrograms: undefined,
  guidedCareProgramTeamLeaderPhoto: undefined,
  guidedCareProgramTemplateId: undefined,
  guidedCareProgramTeamMembers: [],
  guidedCareProgramTeamMembersWithoutTeamLeader: undefined,
  hasGuidedCareHealthProgramTeam: false,
});

export const useProfileContext = () => {
  return useContext(ProfileContext);
};
