/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamCreateMutationVariables = Types.Exact<{
  input: Types.ProviderGuidedCareHealthProgramTeamInput;
}>;


export type GuidedCareTeamCreateMutation = { __typename?: 'Mutation', providerGuidedCareHealthProgramTeamCreate?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCRUD', providerGuidedCareHealthProgramTeamErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTeamCreateDocument = gql`
    mutation GuidedCareTeamCreate($input: ProviderGuidedCareHealthProgramTeamInput!) {
  providerGuidedCareHealthProgramTeamCreate(input: $input) {
    providerGuidedCareHealthProgramTeamErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTeamCreateMutationFn = Apollo.MutationFunction<GuidedCareTeamCreateMutation, GuidedCareTeamCreateMutationVariables>;

/**
 * __useGuidedCareTeamCreateMutation__
 *
 * To run a mutation, you first call `useGuidedCareTeamCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTeamCreateMutation, { data, loading, error }] = useGuidedCareTeamCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareTeamCreateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTeamCreateMutation, GuidedCareTeamCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTeamCreateMutation, GuidedCareTeamCreateMutationVariables>(GuidedCareTeamCreateDocument, options);
      }
export type GuidedCareTeamCreateMutationHookResult = ReturnType<typeof useGuidedCareTeamCreateMutation>;
export type GuidedCareTeamCreateMutationResult = Apollo.MutationResult<GuidedCareTeamCreateMutation>;
export type GuidedCareTeamCreateMutationOptions = Apollo.BaseMutationOptions<GuidedCareTeamCreateMutation, GuidedCareTeamCreateMutationVariables>;