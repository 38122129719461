import { GuidedCareJourneyItemStatus } from "@health/queries/types";
import { patientProfileColors } from "../../constants/colors";

export const statusMapper = (status?: string) => {
  switch (status) {
    case GuidedCareJourneyItemStatus.Pending:
    case GuidedCareJourneyItemStatus.Rescheduled:
      return { color: patientProfileColors.orange, title: "Tentative appointment", iconStatus: "qmark", viewBoxStatus: "-3 -5 25 25" };
    case GuidedCareJourneyItemStatus.Completed:
      return { color: patientProfileColors.green, iconStatus: "checkok", viewBoxStatus: "-5 -4 25 25" };
    case GuidedCareJourneyItemStatus.Cancelled:
    case GuidedCareJourneyItemStatus.Missed:
      return { color: patientProfileColors.red, iconStatus: "close2", viewBoxStatus: "-5 -5 25 25" };
    default:
      return { color: patientProfileColors.orange, title: "Tentative appointment", iconStatus: "qmark", viewBoxStatus: "-3 -5 25 25" };
  }
};
