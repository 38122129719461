/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PatientCurrentMedicalStatusFragmentFragmentDoc } from '../../fragments/__generated__/patientCurrentMedicalStatus';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPatientChronicDiseaseMutationVariables = Types.Exact<{
  input: Types.PatientChronicDiseaseInput;
}>;


export type AddPatientChronicDiseaseMutation = { __typename?: 'Mutation', addPatientChronicDisease?: { __typename?: 'PatientMedicalProfile', id: string, patientCurrentMedicalStatus?: { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null } | null } | null };


export const AddPatientChronicDiseaseDocument = gql`
    mutation AddPatientChronicDisease($input: PatientChronicDiseaseInput!) {
  addPatientChronicDisease(input: $input) {
    id
    patientCurrentMedicalStatus {
      ...patientCurrentMedicalStatusFragment
    }
  }
}
    ${PatientCurrentMedicalStatusFragmentFragmentDoc}`;
export type AddPatientChronicDiseaseMutationFn = Apollo.MutationFunction<AddPatientChronicDiseaseMutation, AddPatientChronicDiseaseMutationVariables>;

/**
 * __useAddPatientChronicDiseaseMutation__
 *
 * To run a mutation, you first call `useAddPatientChronicDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientChronicDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientChronicDiseaseMutation, { data, loading, error }] = useAddPatientChronicDiseaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPatientChronicDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientChronicDiseaseMutation, AddPatientChronicDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPatientChronicDiseaseMutation, AddPatientChronicDiseaseMutationVariables>(AddPatientChronicDiseaseDocument, options);
      }
export type AddPatientChronicDiseaseMutationHookResult = ReturnType<typeof useAddPatientChronicDiseaseMutation>;
export type AddPatientChronicDiseaseMutationResult = Apollo.MutationResult<AddPatientChronicDiseaseMutation>;
export type AddPatientChronicDiseaseMutationOptions = Apollo.BaseMutationOptions<AddPatientChronicDiseaseMutation, AddPatientChronicDiseaseMutationVariables>;