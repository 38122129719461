import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    minHeight: 126.5,
    background: theme.palette.background.default,
    borderRadius: 8,
    margin: 8,
  },
  content: {
    marginTop: "15px",
  },
  avatar: {
    width: 75,
    height: 75,
    boxShadow: theme.mixins.shadows.xs,
  },
  rescheduleButton: {
    borderRadius: 0,
    backgroundColor: "inherit",
    height: 20,
    width: 103,
    marginLeft: 0,
    paddingRight: 5,
    marginRight: "5px",
    "& svg": {
      width: 18,
      height: 18,
    },
  },
  joinCallButton: {
    borderRadius: 0,
    backgroundColor: "inherit",
    height: 20,
    width: 80,
    padding: 0,
    marginRight: "10px",
    "& svg": {
      width: 18,
      height: 18,
    },
  },
}));
