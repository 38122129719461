import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, EvaluatedTemplateLabField, Lab } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { createAutocompleteOption, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { getApolloContextFormToken } from "shared/utils";
import { getRequiredValidation } from "@toolkit/core";
import { safeJsonParseWithDefault } from "pages/dashboard/utils";
import { ShowInput } from "../CoPilot";

export const LabTestsForm: FC<{ data: Lab }> = ({ data }) => {
  const { t } = useTranslation("provider");

  const [params] = useSearchParams();
  const token = params.get("token");

  const { register, setValue } = useFormContext();

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("code", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  useEffect(() => {
    setValue("code", data?.code ? createAutocompleteOption(data, "code", item => item?.code + " - " + item?.display) : undefined);
  }, [data, data?.code, setValue]);
  const selectedLabFields = safeJsonParseWithDefault(data?.evaluatedTemplate!, {})?.fields as EvaluatedTemplateLabField[];

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <SystemCodeAutocomplete
          name={"code"}
          disabled={Boolean(data?.code)}
          context={getApolloContextFormToken(token!)}
          filter={{ codeSystemCode: CodeSystemCode.LabTest }}
        />
      </Grid>

      {!!selectedLabFields?.length &&
        selectedLabFields.map(item => (
          <Grid item md={12} key={item?.code}>
            <ShowInput
              unit={item?.unit?.display!}
              result={data?.result!}
              isSelected={false}
              isMandatory={false}
              code={item?.code!}
              type={item?.type!}
              display={item?.display!}
              values={item?.allowedValues as string[]}
              defaultValue={item?.defaultValue!}
              minValue={item?.minNormalRangeValue!}
              maxValue={item?.maxNormalRangeValue!}
            />
          </Grid>
        ))}
    </Grid>
  );
};
