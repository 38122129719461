/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TimelineItemsQueryVariables = Types.Exact<{
  filter: Types.GuidedCareJourneyItemFilter;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type TimelineItemsQuery = { __typename?: 'Query', timelineItems?: { __typename?: 'GuidedCareJourneyItemCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'GuidedCareJourneyItemCountableEdge', node: { __typename?: 'GuidedCareJourneyItem', id: string, dueDate?: any | null, statusReason?: string | null, guidedCareJourneyItemSource?: Types.GuidedCareJourneyItemSource | null, guidedCareJourneyItemStatus?: Types.GuidedCareJourneyItemStatus | null, guidedCareJourneyItemType?: Types.GuidedCareActivityType | null, medicalForm?: { __typename?: 'MedicalForm', id: string, name?: string | null, description?: string | null } | null, medicalMessage?: { __typename?: 'MedicalMessage', id: string, name?: string | null, medicalMessageType?: Types.MedicalMessageType | null } | null, appointment?: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, paymentStatus?: Types.PaymentStatus | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', currency?: string | null, totalAmount?: number | null, type?: Types.AppointmentPaymentType | null } | null, consumer?: { __typename?: 'Patient', id: string } | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null, firstName?: string | null, lastName?: string | null } | null } | null } | null, templateIntervention?: { __typename?: 'TemplateIntervention', id: string } | null } }> } | null };


export const TimelineItemsDocument = gql`
    query TimelineItems($filter: GuidedCareJourneyItemFilter!, $first: Int, $after: String) {
  timelineItems: guidedCareJourneyItems(
    filter: $filter
    first: $first
    after: $after
  ) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        medicalForm {
          id
          name
          description
        }
        medicalMessage {
          id
          name
          medicalMessageType
        }
        dueDate
        statusReason
        guidedCareJourneyItemSource
        guidedCareJourneyItemStatus
        guidedCareJourneyItemType
        appointment {
          id
          type
          status
          paymentStatus
          paymentInfo {
            currency
            totalAmount
            type
          }
          consumer {
            id
          }
          doctor {
            id
            user {
              id
              fullName
              firstName
              lastName
            }
          }
        }
        templateIntervention {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useTimelineItemsQuery__
 *
 * To run a query within a React component, call `useTimelineItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTimelineItemsQuery(baseOptions: Apollo.QueryHookOptions<TimelineItemsQuery, TimelineItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelineItemsQuery, TimelineItemsQueryVariables>(TimelineItemsDocument, options);
      }
export function useTimelineItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineItemsQuery, TimelineItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelineItemsQuery, TimelineItemsQueryVariables>(TimelineItemsDocument, options);
        }
export type TimelineItemsQueryHookResult = ReturnType<typeof useTimelineItemsQuery>;
export type TimelineItemsLazyQueryHookResult = ReturnType<typeof useTimelineItemsLazyQuery>;
export type TimelineItemsQueryResult = Apollo.QueryResult<TimelineItemsQuery, TimelineItemsQueryVariables>;