import { useTranslation } from "@toolkit/i18n";
import { Link, Typography, useIsMobileView } from "@toolkit/ui";
import React, { FC } from "react";
import { usePatientProfileSectionStyle } from "./PatientProfileSectionStyle";

export const PatientProfileSectionSubtitle: FC<{ subTitle?: string }> = ({ subTitle = "" }) => {
  const { t } = useTranslation("provider");
  const [first, ...rest] = subTitle?.split(".");
  const remainder = rest.join(" ");
  const isMobile = useIsMobileView();
  const { classes, theme } = usePatientProfileSectionStyle();
  return (
    <React.Fragment>
      {subTitle && (
        <Typography display={"flex"} fontWeight={theme.mixins.fonts.fontWeight.medium} flexDirection={isMobile ? "column" : "row"}>
          <span className={classes.span}> {t(first) + "."}</span>
          <Link color='inherit' fontWeight={"bold"} className={classes.link}>
            {t(remainder)}
          </Link>
        </Typography>
      )}
    </React.Fragment>
  );
};
