import { getMediaLink, useDebounce, useFileUpload } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { DragEventHandler, useRef, useState } from "react";
import { FileUploaderProps, FileUploadError, FileUploadProgress } from "./types";

const useFileUploader = ({
  token,
  multiple,
  onFilesUploadComplete,
  onEachFileUploadComplete,
  onFileSelect,
  onProgressChange,
  onEachFileUploadError,
  onFilesUploadError,
}: FileUploaderProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const filesUploadError = useRef<Record<string, FileUploadError>>({});
  const filesUploadProgress = useRef<Record<string, FileUploadProgress>>({});
  const { t } = useTranslation();
  const { fetchUploadFile, removeFile } = useFileUpload({ token });

  const handleDrop: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = e.dataTransfer.files;
    const files: File[] = Array.from(droppedFiles);
    if (multiple) {
      onFileSelect?.(files);
      handleFilesUpload(files);
    } else {
      onFileSelect?.([files[0]]);
      handleFilesUpload([files[0]]);
    }
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(!isDragging);
  };

  const handleFilesUpload = async (files: Array<File>) => {
    if (files) {
      const uploadPromises: Promise<unknown>[] = [];
      filesUploadProgress.current = files.reduce((acc, curr) => ({ ...acc, [curr.name]: { progress: 0, file: curr } }), {});
      files.forEach(file => {
        const uploadPromise = new Promise((resolve, reject) => {
          fetchUploadFile({
            files: [file],
            onComplete: fileName => {
              const fileLink = getMediaLink(fileName);
              const newFilesUploadProgress = { ...filesUploadProgress.current, [file.name]: { progress: 100, file, fileLink } };
              filesUploadProgress.current = newFilesUploadProgress;
              onEachFileUploadComplete?.(newFilesUploadProgress[file.name]);
              resolve(fileName);
            },
            onProgress: progress => {
              const newFilesUploadProgress = { ...filesUploadProgress.current, [file.name]: { progress: progress, file, fileLink: "" } };
              filesUploadProgress.current = newFilesUploadProgress;
              onProgressChange?.(newFilesUploadProgress);
            },
            onError: error => {
              filesUploadError.current = { ...filesUploadError.current, [file.name]: { file, error: error } };
              onEachFileUploadError?.(file, error);
              reject({ file, error: error });
            },
          });
        });

        uploadPromises.push(uploadPromise);
      });
      await Promise.all(uploadPromises);
      filesUploadProgress.current = {};
      // if all failed, then throw error, else call complete if at least one success
      if (Object.values(filesUploadError.current).length === Object.values(filesUploadProgress).length) {
        onFilesUploadError?.(filesUploadError.current);
      } else {
        onFilesUploadComplete?.(filesUploadProgress.current);
      }
    }
  };

  const handleFilesSelected = (files: FileList) => {
    onFileSelect?.(Array.from(files));
    handleFilesUpload(Array.from(files));
  };

  const filesUploadProgressDebounced = useDebounce(filesUploadProgress.current, 1000);

  return {
    isUploading: !!Object.values(filesUploadProgressDebounced).length,
    filesUploadProgress: Object.values(filesUploadProgressDebounced) as Array<FileUploadProgress>,
    t,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    handleFilesUpload,
    handleFilesSelected,
    removeFile,
  };
};

export default useFileUploader;
