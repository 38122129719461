import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";
import { useOrderGetInsuranceApprovalMutation } from "../gql";

export const GetApprovalButton: FC<{ id: string; onDone?: () => void }> = ({ id, onDone }) => {
  const { t } = useTranslation("provider");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [orderGetInsuranceApproval, { loading }] = useOrderGetInsuranceApprovalMutation({
    onCompleted: () => {
      succeeded(t("Order insurance approval request sent successfully"));
      onDone?.();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors) || t("Get Insurance Approval Failed"));
    },
  });

  const handleOrderGetInsuranceApproval = (): void => {
    orderGetInsuranceApproval({
      variables: {
        orderId: id,
      },
    });
  };

  return (
    <CircularIntegration loading={loading}>
      <Button variant='outlined' className={classes.outlinedAccept} onClick={handleOrderGetInsuranceApproval}>
        {t("Get iO Health Approval")}
      </Button>
    </CircularIntegration>
  );
};
