import { createAutocompleteOption } from "@toolkit/ui";
import { guidedCareTeamMemberPositionOptionsMap } from "@health/enum-options";
import {
  GuidedCareHealthProgram,
  GuidedCareProgramTeamMember,
  Maybe,
  ProviderGuidedCareHealthProgramTeam,
  ProviderGuidedCareHealthProgramTeamInput,
  ProviderGuidedCareTeamStatus,
} from "@health/queries/types";
import { IGuidedCareTeamMembers } from "pages/HealthProviderManagement/GuidedCare/types";
import { IGuidedCareTeamUpsertFormValues } from "../forms/TeamUpsert/GuidedCareTeamUpsertFormSchema";

export const getGuidedCareTeamStatusColor = (teamStatus: ProviderGuidedCareTeamStatus, palette: any) => {
  switch (teamStatus) {
    case ProviderGuidedCareTeamStatus.Approved:
      return palette.success.main;
    case ProviderGuidedCareTeamStatus.Pending:
      return palette.warning.main;
    case ProviderGuidedCareTeamStatus.Rejected:
      return palette.error.main;
  }
};

export const convertProgramTeamMembersToFormValues = (
  programTeamMembers: Maybe<GuidedCareProgramTeamMember>[]
): IGuidedCareTeamMembers[] => {
  return (
    programTeamMembers?.flatMap(item => {
      const teamMember = {
        programTeamMemberId: item?.id!,
        position: guidedCareTeamMemberPositionOptionsMap[item?.position!],
        specialization: item?.specialization
          ? createAutocompleteOption(
              {
                code: item?.specialization!,
                display: item?.specializationDisplay!,
              },
              "code",
              "display"
            )
          : null,
        isBackup: false,
        isLicencedHealthProfessional: !!item?.isLicencedHealthProfessional,
        isKeyTeamMember: !!item?.isKeyTeamMember,
        user: null as any, // we used any here because we need nullable user as a default value but required on submit
      };

      if (item?.isBackupPersonMandatory) {
        return [
          teamMember,
          {
            ...teamMember,
            isBackup: true,
            position: {
              ...teamMember?.position,
              label: `${teamMember.position.label} (Backup)`,
            },
          },
        ];
      } else {
        return [teamMember];
      }
    }) || []
  );
};

export const convertTeamToFormValues = (
  team: ProviderGuidedCareHealthProgramTeam,
  program?: GuidedCareHealthProgram
): IGuidedCareTeamUpsertFormValues => {
  const programTeam = program
    ? { id: program?.id!, name: program?.name! }
    : { id: team?.guidedCareHealthProgram?.id!, name: team?.guidedCareHealthProgram?.name! };

  return {
    name: team?.name!,
    description: team?.description!,
    maxNumberOfPatients: team?.maxNumberOfPatients!,
    isActive: !!team?.isActive,
    program: createAutocompleteOption(programTeam, "id", "name"),
    branch: createAutocompleteOption(team?.branch, "id", "name"),
    teamMembers: program
      ? convertProgramTeamMembersToFormValues(program?.teamMembers!)
      : team?.teamMembers?.map(item => {
          const position = guidedCareTeamMemberPositionOptionsMap[item?.guidedCareProgramTeamMember?.position!];

          return {
            id: item?.id!,
            programTeamMemberId: item?.guidedCareProgramTeamMember?.id!,
            position: item?.isBackup
              ? {
                  ...position,
                  label: `${position?.label} (Backup)`,
                }
              : position,
            user: createAutocompleteOption({ id: item?.user?.id!, fullName: item?.user?.fullName! }, "id", "fullName"),
            specialization: item?.guidedCareProgramTeamMember?.specialization
              ? createAutocompleteOption(
                  {
                    code: item?.guidedCareProgramTeamMember?.specialization!,
                    display: item?.guidedCareProgramTeamMember?.specializationDisplay!,
                  },
                  "code",
                  "display"
                )
              : null,
            isBackup: !!item?.isBackup,
            isLicencedHealthProfessional: !!item?.guidedCareProgramTeamMember?.isLicencedHealthProfessional,
            isKeyTeamMember: !!item?.guidedCareProgramTeamMember?.isKeyTeamMember,
          };
        }) || [],
  };
};

export const convertTeamFormValuesToBackEndValues = (
  teamFormValues: IGuidedCareTeamUpsertFormValues,
  providerId: number
): ProviderGuidedCareHealthProgramTeamInput => {
  return {
    name: teamFormValues.name,
    description: teamFormValues.description,
    maxNumberOfPatients: teamFormValues.maxNumberOfPatients,
    guidedCareHealthProgram: teamFormValues.program?.value?.id,
    isActive: teamFormValues.isActive,
    providerId: String(providerId || ""),
    branchId: teamFormValues?.branch?.value?.id,
    teamMembers: teamFormValues.teamMembers.map(item => {
      return {
        id: item?.id,
        isBackup: item.isBackup,
        guidedCareProgramTeamMember: {
          id: item.programTeamMemberId,
        },
        userId: item.user?.value?.id!,
      };
    }),
  };
};
