import { FC, useMemo } from "react";
import { CloseIcon, IconButton, Typography } from "@toolkit/ui";
import { DoctorConsolePageContent } from "./DoctorConsolePageContent";
import { doctorConsoleMenu, DoctorConsoleType } from "./constants";
import { useDoctorConsolePageStyles } from "./DoctorConsolePage.styles";

type DoctorConsolePageProps = {
  type: DoctorConsoleType;
  visitId: string;
  onClose: () => void;
};

export const DoctorConsolePage: FC<DoctorConsolePageProps> = ({ type, visitId, onClose }) => {
  const { classes, theme } = useDoctorConsolePageStyles();
  const { title } = useMemo(() => doctorConsoleMenu.find(m => m.id === type)!, [type]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        <div>
          <IconButton onClick={onClose} color='primary'>
            <CloseIcon
              color='primary'
              fontSize='small'
              fill={theme.palette.primary.main}
              sx={{
                fontSize: theme.mixins.fonts.fontSize.xs,
              }}
            />
          </IconButton>
        </div>
      </div>
      <div className={classes.content}>
        <DoctorConsolePageContent type={type} visitId={visitId} />
      </div>
    </div>
  );
};
