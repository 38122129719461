import { Appointment } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, Card, Grid, PhoneConnecting, styled, Typography, useTheme } from "@toolkit/ui";
import { OngoingVisitPatientProfileDrawer } from "pages/appointments/components";
import { getPatientName } from "pages/appointments/utils";
import { FC } from "react";
import { openMeetingPlatformLink } from "shared/components/VisitStartingNowNotification/utils";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isDoctorUser } from "utils";

type OngoingVisitCardProps = {
  appointment: Appointment;
};

export const OngoingVisitCard: FC<OngoingVisitCardProps> = props => {
  const { appointment } = props;

  const { t } = useTranslation("provider");

  const { userInfo } = useOidcUserProfile();
  const meetingPlatformLink = appointment?.visit?.meetingPlatformLinkForJoinCall;

  const handleJoinCall = async () => {
    openMeetingPlatformLink(meetingPlatformLink!, appointment?.visit?.id);
  };

  const isDoctor = isDoctorUser(userInfo);
  const theme = useTheme();
  return (
    <Card
      key={appointment?.id?.toString()}
      sx={{ padding: "40px", boxShadow: theme.mixins.shadows.none, maxWidth: "924px", marginInline: "auto" }}
    >
      <Grid container flexDirection='column' alignContent='center' alignItems='center' spacing={2} sx={{ width: "100%" }}>
        <Grid item xs>
          <PhoneConnectingIconStyled viewBox='0 0 100 100' />
        </Grid>

        <Grid item xs>
          <Typography fontSize={theme.mixins.fonts.fontSize.xxl} fontWeight={theme.mixins.fonts.fontWeight.semiBold}>
            {getPatientName(appointment?.visit?.patient)} {t("is ready to start the call")}
          </Typography>
        </Grid>

        <Grid item xs>
          <Button disabled={!(meetingPlatformLink && meetingPlatformLink?.length > 0)} color='primary' onClick={handleJoinCall}>
            {t("Join Call")}
          </Button>

          <Button
            disabled
            sx={{
              backgroundColor: theme.palette.yellow.main,
              "&:hover": {
                backgroundColor: theme.palette.yellow.main,
              },
              "&.Mui-disabled": {
                backgroundColor: `${theme.palette.yellow.main} !important`,
              },
            }}
          >
            {t("Fill Visit Details")}
          </Button>

          <OngoingVisitPatientProfileDrawer isDoctor={isDoctor} appointment={appointment} />
        </Grid>
      </Grid>
    </Card>
  );
};

const PhoneConnectingIconStyled = styled(PhoneConnecting)(() => ({
  width: "80px",
  height: "80px",
  "& svg": {
    width: 76,
    height: 76,
  },
  "& g": {
    fill: "#D1A700",
  },
}));
