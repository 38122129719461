import { FC, PropsWithChildren } from "react";
import { FormActions, PageWrapper } from "@toolkit/ui";

type ConditionalDiscountPageWrapper = {
  isLoading: boolean;
  isButtonDisabled: boolean;
  submitButtonLabel: string;
  handleCancel: () => void;
  shouldWrapChildren: boolean;
};

export const ConditionalDiscountPageWrapper: FC<PropsWithChildren<ConditionalDiscountPageWrapper>> = ({
  isLoading,
  isButtonDisabled,
  submitButtonLabel,
  handleCancel,
  shouldWrapChildren,
  children,
}) => {
  return (
    <>
      {shouldWrapChildren ? (
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          {children}
        </PageWrapper>
      ) : (
        children
      )}
    </>
  );
};
