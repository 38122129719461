import { useTranslation } from "@toolkit/i18n";
import { Grid, Typography, useTheme } from "@toolkit/ui";
import { GuidedCareTaskList } from "pages/GuidedCareTasks/components/GuidedCareTaskList/GuidedCareTaskList";
import { FC } from "react";
import { ChatDialogComponent } from "shared/components/Chat/components/ChatDialog.component";
import { DashboardContext, DashboardContextProvider } from "../../context/UserDoctorDashboardContextProvider";
import { ComingSoonAppointmentsGrid } from "../ComingSoonAppointmentsGrid";
import { PendingAppointmentsGrid } from "../PendingAppointmentsGrid";
import { WaitingRoomsList } from "../WaitingRooms";
import { EventCards } from "./Cards";
import { MyFeed } from "./MyFeed";

export const UserDoctorDashboardView: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <DashboardContextProvider>
      <DashboardContext.Consumer>
        {({ selectedPeer, onModalClose, open }) => (
          <>
            <ChatDialogComponent peer={selectedPeer} isOpened={open} handleToggle={onModalClose} renderButton={() => <></>} />
            <Grid container marginTop={2} spacing={2} paddingBottom={4} paddingLeft={5}>
              <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
                <Grid item xs={12}>
                  <WaitingRoomsList
                    cardTitle={
                      <Typography
                        variant='subtitle1'
                        marginX={2}
                        marginTop={1}
                        marginBottom={0}
                        fontSize={theme.mixins.fonts.fontSize.md}
                        fontWeight={theme.mixins.fonts.fontWeight.medium}
                      >
                        {t("Virtual waiting rooms")}
                      </Typography>
                    }
                    cardProps={{ sx: { height: "524px" } }}
                  />
                </Grid>
                <Grid xs={12} marginTop={7.3} direction='row'>
                  <Grid item xs={12}>
                    <MyFeed />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8} container px={2} order={{ xs: 1, md: 2 }}>
                <Grid container xs={12}>
                  <EventCards />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Typography
                    variant='subtitle1'
                    fontSize={theme.mixins.fonts.fontSize.md}
                    fontWeight={theme.mixins.fonts.fontWeight.medium}
                    mb={1}
                  >
                    {t("Upcoming Appointments")}
                  </Typography>
                  <ComingSoonAppointmentsGrid />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Typography
                    variant='subtitle1'
                    fontSize={theme.mixins.fonts.fontSize.md}
                    fontWeight={theme.mixins.fonts.fontWeight.medium}
                    mb={1}
                  >
                    {t("Pending Items")}
                  </Typography>
                  <PendingAppointmentsGrid
                    TableContainerProps={{
                      sx: {
                        height: 357.5,
                      },
                    }}
                    pageSize={5}
                    emptyIconHeight={100}
                    iconBoxProps={{
                      marginBottom: "90px",
                      height: "5px",
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={2}>
                  <GuidedCareTaskList />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </DashboardContext.Consumer>
    </DashboardContextProvider>
  );
};
