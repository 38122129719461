import { PatientActivityType } from "../PatientAddActivity.types";

export const getPatientActivityTitle = (type: PatientActivityType) => {
  switch (type) {
    case PatientActivityType.Service:
      return "Add Service";
    case PatientActivityType.Visit:
      return "Add Visit";
    case PatientActivityType.MedicalForms:
      return "Add Medical Forms";
    default:
      return "Add Visit";
  }
};
