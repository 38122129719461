import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { usePatientHealthParameterPictureStyle } from "./usePatientHealthParameterPictureStyle";

type PatientHealthParameterPictureProps = {
  src: string;
  alt?: string | null;
  maxHeight?: number;
};

export const PatientHealthParameterPicture: FC<PatientHealthParameterPictureProps> = props => {
  const { src, alt, maxHeight = 400 } = props;

  const { t } = useTranslation("provider");

  const { classes } = usePatientHealthParameterPictureStyle({ maxHeight });

  return <img src={src} alt={alt || t("Picture")} loading={"lazy"} className={classes.image} />;
};
