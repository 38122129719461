import { Maybe, VisitPrescriptionTransaction } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, Typography, useMediaQuery, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const ActiveMedications: FC<{ visitPrescriptions: (Maybe<Maybe<VisitPrescriptionTransaction>[]> | undefined)[] }> = ({
  visitPrescriptions,
}) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:1045px)");

  return (
    <>
      <Box display='flex' justifyContent={"space-between"} mb={1}>
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Latest Medications in ioHealth")}{" "}
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontWeight={theme.mixins.fonts.fontWeight.regular} component='span'>
            ({t("last 90 days")})
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          padding: "12px",
          borderRadius: "10px",
          background: theme.palette.common.white,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          boxShadow: theme.mixins.shadows.xs,
        }}
      >
        {visitPrescriptions?.map(visitPrescription =>
          visitPrescription?.map(item =>
            item?.activities?.map(medication => (
              <Box
                key={medication?.id}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                gap={1}
                p={1}
                sx={{
                  borderRadius: "5px",
                  height: isMobile ? "100%" : 65,
                  backgroundColor: theme.palette.background.default,
                }}
              >
                <Box display='flex' alignItems='center' gap={1}>
                  <CustomIcon icon='pill-medication' />
                  <Box>
                    <Typography fontSize={theme.mixins.fonts.fontSize.sm} color={theme.palette.primary.main}>
                      {medication?.denialCode} - {medication?.denialDescription} - {medication?.frequencyValue} - {medication?.quantity}
                    </Typography>
                    <Typography color={theme.palette.stale.main} fontSize={theme.mixins.fonts.fontSize.sm}>
                      {medication?.instructions}
                    </Typography>
                  </Box>
                </Box>
                <CustomIcon icon={"info"} />
              </Box>
            ))
          )
        )}
      </Box>
    </>
  );
};
