/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareJourneyItemGetQueryVariables = Types.Exact<{
  guidedCareJourneyItemId: Types.Scalars['ID'];
}>;


export type GuidedCareJourneyItemGetQuery = { __typename?: 'Query', guidedCareJourneyItem?: { __typename?: 'GuidedCareJourneyItem', appointment?: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null, requestedTimeSlots?: Array<string | null> | null, paymentStatus?: Types.PaymentStatus | null, doctor?: { __typename?: 'Doctor', id: string } | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', totalAmount?: number | null, type?: Types.AppointmentPaymentType | null } | null, consumer?: { __typename?: 'Patient', activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', insuranceId?: string | null, membershipEnd?: any | null, payer?: { __typename?: 'Payer', name: string } | null } | null> | null, healthProgramsByActiveMembers?: Array<{ __typename?: 'HealthProgram', insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', name?: string | null } }> } | null } | null> | null } | null } | null, medicalFormAnswers?: { __typename?: 'MedicalFormAnswerConnection', edges?: Array<{ __typename?: 'MedicalFormAnswerEdge', node?: { __typename?: 'MedicalFormAnswer', id: string, classificationName?: string | null, questionAnswers?: Array<{ __typename?: 'QuestionAnswerExtended', id: string, booleanAnswer?: boolean | null, stringAnswer?: string | null, singleChoiceAnswer?: string | null, multipleChoiceAnswer?: Array<string | null> | null, question?: { __typename?: 'Question', question?: string | null, questionType?: Types.QuestionType | null, options?: Array<string | null> | null } | null } | null> | null, medicalForm?: { __typename?: 'MedicalForm', id: string, name?: string | null, nameAr?: string | null, createdDate?: any | null, updateDate?: any | null } | null } | null } | null> | null } | null } | null };


export const GuidedCareJourneyItemGetDocument = gql`
    query GuidedCareJourneyItemGet($guidedCareJourneyItemId: ID!) {
  guidedCareJourneyItem(id: $guidedCareJourneyItemId) {
    appointment {
      id
      status
      requestedTimeSlots
      doctor {
        id
      }
      paymentStatus
      paymentInfo {
        totalAmount
        type
      }
      consumer {
        activeHealthProgramMembers {
          insuranceId
          membershipEnd
          payer {
            name
          }
        }
        healthProgramsByActiveMembers {
          insuranceNetworks(first: 1) {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
    medicalFormAnswers(first: 1) {
      edges {
        node {
          id
          classificationName
          questionAnswers {
            id
            booleanAnswer
            stringAnswer
            singleChoiceAnswer
            multipleChoiceAnswer
            question {
              question
              questionType
              options
            }
          }
          medicalForm {
            id
            name
            nameAr
            createdDate
            updateDate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareJourneyItemGetQuery__
 *
 * To run a query within a React component, call `useGuidedCareJourneyItemGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareJourneyItemGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareJourneyItemGetQuery({
 *   variables: {
 *      guidedCareJourneyItemId: // value for 'guidedCareJourneyItemId'
 *   },
 * });
 */
export function useGuidedCareJourneyItemGetQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareJourneyItemGetQuery, GuidedCareJourneyItemGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareJourneyItemGetQuery, GuidedCareJourneyItemGetQueryVariables>(GuidedCareJourneyItemGetDocument, options);
      }
export function useGuidedCareJourneyItemGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareJourneyItemGetQuery, GuidedCareJourneyItemGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareJourneyItemGetQuery, GuidedCareJourneyItemGetQueryVariables>(GuidedCareJourneyItemGetDocument, options);
        }
export type GuidedCareJourneyItemGetQueryHookResult = ReturnType<typeof useGuidedCareJourneyItemGetQuery>;
export type GuidedCareJourneyItemGetLazyQueryHookResult = ReturnType<typeof useGuidedCareJourneyItemGetLazyQuery>;
export type GuidedCareJourneyItemGetQueryResult = Apollo.QueryResult<GuidedCareJourneyItemGetQuery, GuidedCareJourneyItemGetQueryVariables>;