import { createPathDefinition } from "@toolkit/core";
import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";

export const productsRoute = `${marketplaceRoute}/products`;

export const productsPaths = createPathDefinition(productsRoute, {
  main: "",
  new: "new",
  update: ":productId",
});
