import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, Grid, TextField, Tooltip, Typography, useAddToast } from "@toolkit/ui";
import { toNumber } from "lodash";
import { FC, useState } from "react";
import { z } from "zod";
import { useMarketplaceProductUpdateStockQuantityMutation } from "../gql";
import { ProductsQueryNode } from "../types";

const Max32BitValue = Math.pow(2, 31) - 1;

const PositiveIntLessThan32BitSchema = z
  .number()
  .int()
  .min(0)
  .max(Max32BitValue, {
    message: `Number must be less than ${Max32BitValue}.`,
  });

export const UpdateProductQuantity: FC<Pick<ProductsQueryNode, "id" | "stockQuantity">> = ({ id, stockQuantity }) => {
  const { t } = useTranslation("provider");
  const { open, handleToggle, handleOpen } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const [quantity, setQuantity] = useState<number | string>(stockQuantity || 0);
  const [error, setError] = useState<string>("");
  const handleQuantityValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event?.target?.value;
    setQuantity(val);
    const numberValue = PositiveIntLessThan32BitSchema.safeParse(Number(val));

    if (numberValue.success) {
      setError("");
    } else {
      const errorMessage = numberValue.error.errors[0].message;
      setError(errorMessage);
    }
  };

  const [updateStockQuantity, { loading }] = useMarketplaceProductUpdateStockQuantityMutation({
    onCompleted: data => {
      if (data?.marketplaceProductUpdateStockQuantity?.id) {
        succeeded(t("Product quantity updated successfully!"));
        handleToggle();
      } else {
        failed(t("Failed to update product"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleUpdate = () => {
    updateStockQuantity({
      variables: {
        marketplaceProductUpdateStockQuantityId: id,
        quantity: toNumber(quantity) || 0,
      },
    });
  };
  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Update product stock quantity"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={t("update product stock quantity")}>
          <Typography
            sx={{ cursor: "string", width: "max-content", textDecoration: "underline" }}
            fontSize={16}
            color={"#913263"}
            onClick={handleOpen}
          >
            {stockQuantity || 0}
          </Typography>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <>
            <Button variant='outlined' onClick={handleToggle}>
              {t("Close")}
            </Button>
            <Button disabled={loading || !!error} variant='contained' onClick={handleUpdate}>
              {t("Update")}
            </Button>
          </>
        ),
      }}
    >
      <Grid container>
        <Grid item>
          <TextField
            value={quantity}
            label={t("Stock Quantity")}
            placeholder={t("Stock Quantity")}
            type={"number"}
            onChange={handleQuantityValueChange}
            error={!!error}
            helperText={error}
          />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
