import { NetworkStatus } from "@apollo/client";
import { NotificationType, SortDirection, UserNotificationField } from "@health/queries/types";
import {
  useAppointmentReminder,
  useOnAppointmentStatusUpdate,
  useOnPatientProfileAccessRequest,
  useOnPatientProfileAccessResponse,
} from "@health/sse";
import { isNotificationMenuOpenedVar } from "@toolkit/ui";
import { MeNotificationsQuery, useMeNotificationsQuery } from "./gql";

export const useNotificationsPageHook = () => {
  const { data, loading, fetchMore, refetch, networkStatus } = useMeNotificationsQuery({
    variables: {
      first: 10,
      sortBy: {
        field: UserNotificationField.CreationDate,
        direction: SortDirection.Desc,
      },
      filter: {
        excludeTypes: [
          NotificationType.HealthProgramVisitStatusChanged,
          NotificationType.PaymentNotification,
          NotificationType.OrderStatusUpdate,
          NotificationType.DoctorAvailabilityStatusChanged,
          NotificationType.PingPong,
        ],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const notifications = data?.me?.notifications?.edges?.map(item => item?.node);
  const pageInfo = data?.me?.notifications?.pageInfo;

  const fetchMoreNotifications = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: MeNotificationsQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            me: {
              ...fetchMoreResult?.me,
              notifications: {
                ...fetchMoreResult?.me?.notifications,
                edges: [...(prev?.me?.notifications?.edges || []), ...(fetchMoreResult?.me?.notifications?.edges || [])],
              },
            },
          });
        },
      });
    }
  };

  const handleReFetch = ({ data }) => {
    if (data?.patientProfileConsentFormId) {
      refetch();
      isNotificationMenuOpenedVar(true);
    }
  };
  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      refetch();
      isNotificationMenuOpenedVar(true);
    }
  });

  useOnPatientProfileAccessRequest(handleReFetch);
  useOnPatientProfileAccessResponse(handleReFetch);
  useAppointmentReminder(handleReFetch);

  return {
    notifications,
    fetchMoreNotifications,
    pageInfo,
    loading: loading || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore,
  };
};
