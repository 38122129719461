import { makeStyles } from "@toolkit/ui";

export const useGuidedCareJourneyInterventionsActivitiesDialogActionsStyle = makeStyles()(theme => ({
  root: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    background: theme.palette.common.white,
  },
  dialogActionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  mainButtonWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    borderRadius: 23,
    paddingBlock: 22,
    paddingInline: 30,
  },
}));
