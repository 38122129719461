import { BranchError } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { branchEditBreadCrumb, branchListBreadCrumb } from "pages/HealthProviderManagement/Branches/constant";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBranchQuery, useBranchUpdateMutation } from "../../gql";
import { IFormData } from "../BranchesForm.types";

export const useBranchEditPageHook = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { branchId } = useParams();

  const { setBreadCrumb } = useBreadCrumbs();
  const [errors, setError] = useState<BranchError[]>();

  const { succeeded, failed } = useAddToast();
  const translateBranch = t("Branch Update Successfully");

  const [branchUpdate, { loading: isFetchingUpdate }] = useBranchUpdateMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const errors = data?.branchUpdate?.branchErrors;
      if (errors?.length === 0) {
        succeeded(translateBranch);
        navigate(-1);
      } else {
        const error = t(formatMessageErrors(errors));
        failed(error);
        setError(errors as BranchError[]);
      }
    },
    onError: () => {
      failed(t("Branch Update Failed"));
    },
  });

  const { data, loading } = useBranchQuery({
    variables: {
      id: String(branchId),
    },
    fetchPolicy: "no-cache",
  });

  const handleUpdateBranch = (formData: IFormData) => {
    const cityId = formData?.addressInput?.city?.value?.id;
    branchUpdate({
      variables: {
        id: branchId as string,
        branch: {
          ...formData,
          preferredPharmacies: formData?.preferredPharmacies?.map(item => item?.value?.id),
          addressInput: {
            ...formData?.addressInput,
            city: cityId,
          },
        },
      },
    });
  };

  const branch = data?.branch;

  useEffect(() => {
    setBreadCrumb({
      title: "Branches Management",
      values: [branchListBreadCrumb(t), branchEditBreadCrumb(String(branchId), branch?.name, t, false)],
    });
  }, [branchId, branch?.name]);

  return {
    branch,
    errors,
    loading,
    isFetchingUpdate,
    handleUpdateBranch,
  };
};
