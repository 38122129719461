import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  Button,
  CircularProgress,
  CustomDialog,
  CustomIcon,
  IconButton,
  Tooltip,
  Typography,
  useAddToast,
  useTheme,
} from "@toolkit/ui";
import { useHealthPackageCloneMutation } from "pages/HealthPackages/gql";

import { FC, memo, useState } from "react";
import { CloneDialogProps } from "./CloneDialog.types";

const CloneDialog: FC<CloneDialogProps> = ({ row }) => {
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const { t } = useTranslation("provider");
  const { succeeded } = useAddToast();

  const [healthPackageCloneMutation, { loading: isCloneLoading }] = useHealthPackageCloneMutation({
    onCompleted: () => {
      succeeded(t("Succeeded in Cloning Health Package!"));
      setIsCloneModalOpen(false);
    },
    update: cache => {
      const normalizedId = cache.identify({ id: row?.id, __typename: "MarketplaceHealthPackage" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleClone = () => {
    if (isCloneLoading) return;
    healthPackageCloneMutation({
      variables: {
        healthPackageCloneId: row?.id,
      },
    });
    handleClose();
  };

  const handleClose = () => {
    setIsCloneModalOpen(false);
  };
  const handleOpen = () => {
    setIsCloneModalOpen(true);
  };
  const theme = useTheme();
  return (
    <>
      <Tooltip title={t("Clone Health Package")}>
        <IconButton disabled={!row?.updatable} onClick={handleOpen}>
          <CustomIcon icon='clone' />
        </IconButton>
      </Tooltip>
      <CustomDialog
        type='base'
        DialogTitleProps={{
          onClose: handleClose,
          title: t("Clone Health Package"),
        }}
        open={isCloneModalOpen}
        onClose={handleClose}
        maxWidth='md'
        keepMounted={false}
        DialogActionsProps={{
          children: (
            <>
              <Button onClick={handleClone} disabled={isCloneLoading} startIcon={isCloneLoading && <CircularProgress size={15} />}>
                {t("Clone")}
              </Button>
              <Button variant='outlined' onClick={handleClose} autoFocus>
                {t("Close")}
              </Button>
            </>
          ),
        }}
        DialogContentProps={{ sx: { minHeight: "10vh" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography fontSize={theme.mixins.fonts.fontSize.xl} marginBottom='16px' color={theme.palette.primary.main}>
            {t("Are you sure you want to clone this Health Package?")}
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};
export default memo(CloneDialog);
