import { CustomDialog } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { GuidedCareProgramMemberAddForm } from "../../forms/GuidedCareProgramMemberAdd/GuidedCareProgramMemberAddForm";

type IOpen = () => void;
type IClose = () => void;

export const GuidedCareProgramMemberAddModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const GuidedCareProgramMemberAddModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation("provider");

  const open: IOpen = () => {
    setIsOpen(true);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareProgramMemberAddModalApi.open = open;
    GuidedCareProgramMemberAddModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      maxWidth={"sm"}
      DialogTitleProps={{
        title: t("Add Patient to Program"),
        onClose: close,
      }}
    >
      <GuidedCareProgramMemberAddForm />
    </CustomDialog>
  );
};
