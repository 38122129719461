/* eslint-disable max-lines */
import { ChartType, TemplateFieldType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, ExpandIcon, IconButton, Loader, useMediaQuery, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { HealthParameterDialog } from "./HealthParameterDialog";
import { HealthParameterFieldDataType } from "./TableChartType";
import { useHealthParameterItem } from "./useHealthParameterItem";
// Import from overview
import BarLineChart from "../Charts/BarLineChart";
import BasicLineChart from "../Charts/BasicLineChart";
import { ECGChart } from "../Charts/ECGChart";
import { OverviewCard } from "../VitalSigns/OverviewCard";

type HealthParameterItemProps = {
  width?: number | string;
  code: string;
  display: string;
  chartType: string;
  unit?: string;
  type: string;
  patientId: string;
  token: string;
  visitId?: string;
  referenceRange?: string;
  maxRangeValue?: number;
  minRangeValue?: number;
};

export const HealthParameterItem: FC<HealthParameterItemProps> = ({
  width: widthLg,
  chartType,
  code,
  display,
  unit,
  type,
  token,
  visitId,
  patientId,
  referenceRange,
  minRangeValue,
  maxRangeValue,
}) => {
  const { t } = useTranslation("provider");
  const isMobile = useMediaQuery("(max-width:1340px)");
  const pathname = useLocation().pathname;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const width = open || isMobile ? "100%" : widthLg || 200;

  const handleClick = () => {
    setOpen(!open);
  };
  const { loading, options, tableOptions, selectedECG, loadingPatientHealthParameters, handleSelectECG } = useHealthParameterItem(
    token,
    patientId,
    code,
    type,
    visitId,
    unit
  );
  const getChart = () => {
    switch (chartType) {
      case ChartType.Line:
        return type === TemplateFieldType.NumericList ? (
          <>{loading ? <Loader /> : <ECGChart options={selectedECG} />}</>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <BasicLineChart
                widthValue={"100%"}
                title={display!}
                data={options}
                minRangeValue={minRangeValue}
                maxRangeValue={maxRangeValue}
              />
            )}
          </>
        );
      case ChartType.Bar:
        return (
          <BarLineChart
            widthValue={"100%"}
            title={display!}
            data={options}
            color={theme.palette.primary.main}
            minRangeValue={minRangeValue}
            maxRangeValue={maxRangeValue}
          />
        );
      case ChartType.HorizontalBar:
        return (
          <BarLineChart
            isHorizontal
            title={display!}
            color={theme.palette.primary.main}
            data={options}
            minRangeValue={minRangeValue}
            maxRangeValue={maxRangeValue}
          />
        );
      case ChartType.Table:
        return <HealthParameterFieldDataType type={type} tableOptions={tableOptions!} />;
      default:
        return <BasicLineChart title={display!} data={options} />;
    }
  };

  const stringValue = tableOptions?.[tableOptions?.length! - 1]?.valueBoolean ? t("Yes") : t("No");

  const getData = () => {
    switch (chartType) {
      case ChartType.Line:
        return type === TemplateFieldType.NumericList
          ? {
              value: display,
              date: selectedECG?.[selectedECG?.length! - 1]?.date,
              description: selectedECG?.[selectedECG?.length! - 1]?.source || "-",
            }
          : {
              value: options?.[options?.length! - 1]?.value,
              date: options?.[options?.length! - 1]?.date,
              description: options?.[options?.length! - 1]?.source || "-",
            };
      case ChartType.Table:
        return {
          value: tableOptions?.[tableOptions?.length! - 1]?.valueString || stringValue,
          date: tableOptions?.[tableOptions?.length! - 1]?.date,
          description: tableOptions?.[tableOptions?.length! - 1]?.source || "-",
        };

      default:
        return {
          value: options?.[options?.length! - 1]?.value,
          date: options?.[options?.length! - 1]?.date,
          description: options?.[options?.length! - 1]?.source || "-",
        };
    }
  };
  const outOfRange = options?.[options?.length! - 1]?.value! > maxRangeValue! || options?.[options?.length! - 1]?.value! < minRangeValue!;

  const showContent = () => {
    if (chartType !== ChartType.Table) {
      return getChart();
    } else return !open ? getChart() : null;
  };
  const cardView = (showContent, height?) => {
    return (
      <OverviewCard
        width={width}
        height={token && pathname === "/embed/live-signs" && type === TemplateFieldType.NumericList && selectedECG ? 400 : height}
        colorTitle={outOfRange ? theme.palette.error.main : theme.palette.primary.main}
        title={type !== TemplateFieldType.NumericList ? display! : ""}
        unit={type === TemplateFieldType.NumericList ? `${unit || ""}` : unit}
        description={t("By") + " " + getData()?.description!}
        value={getData()?.value!}
        range={type !== TemplateFieldType.NumericList ? referenceRange : undefined}
        date={getData()?.date!}
        icon={
          type === TemplateFieldType.NumericList && (
            <IconButton onClick={handleClick}>
              <ExpandIcon />
            </IconButton>
          )
        }
      >
        {showContent()}
      </OverviewCard>
    );
  };
  const handleOpen = e => {
    e.stopPropagation();
    type !== TemplateFieldType.NumericList && handleClick();
  };
  return (
    <div>
      {!loadingPatientHealthParameters && (
        <>
          <Box onClick={handleOpen}>{cardView(getChart)}</Box>
          <HealthParameterDialog
            isOpen={open}
            display={display}
            visitId={visitId}
            type={type}
            code={code}
            token={token}
            patientId={patientId}
            onClose={handleClick}
            onSelectECG={handleSelectECG}
          >
            {cardView(showContent, open && chartType === ChartType.Table ? 100 : undefined)}
          </HealthParameterDialog>
        </>
      )}
    </div>
  );
};
