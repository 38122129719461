/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDoctorIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDoctorIdQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, doctor?: { __typename?: 'Doctor', id: string } | null } | null };


export const GetDoctorIdDocument = gql`
    query getDoctorId {
  me {
    id
    doctor {
      id
    }
  }
}
    `;

/**
 * __useGetDoctorIdQuery__
 *
 * To run a query within a React component, call `useGetDoctorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDoctorIdQuery(baseOptions?: Apollo.QueryHookOptions<GetDoctorIdQuery, GetDoctorIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorIdQuery, GetDoctorIdQueryVariables>(GetDoctorIdDocument, options);
      }
export function useGetDoctorIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorIdQuery, GetDoctorIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorIdQuery, GetDoctorIdQueryVariables>(GetDoctorIdDocument, options);
        }
export type GetDoctorIdQueryHookResult = ReturnType<typeof useGetDoctorIdQuery>;
export type GetDoctorIdLazyQueryHookResult = ReturnType<typeof useGetDoctorIdLazyQuery>;
export type GetDoctorIdQueryResult = Apollo.QueryResult<GetDoctorIdQuery, GetDoctorIdQueryVariables>;