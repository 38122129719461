import { Box, Typography, useTheme } from "@toolkit/ui";
import { Fragment, useState } from "react";
import { NurseWorkingDetailsSelectDialog } from "shared/components/NurseCurrentWorkDetails";
import { useNurseCurrentWorkspaceSelection } from "./NurseCurrentWorkspaceSelection.hook";
import { useSetNurseAssignment } from "./useSetNurseAssignment";

export const NurseCurrentWorkSpaceDetailsItem = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  useSetNurseAssignment();
  const { message } = useNurseCurrentWorkspaceSelection(handleOpen);
  return (
    <Fragment>
      <Box sx={{ marginInlineEnd: 2, cursor: "pointer" }} display='flex' alignItems='center'>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: theme.mixins.fonts.fontSize.md,
            lineHeight: 1,
            fontWeight: "bold",
          }}
          onClick={handleOpen}
        >
          {message}
        </Typography>
      </Box>
      <NurseWorkingDetailsSelectDialog isOpen={isOpen} onClose={handleClose} />
    </Fragment>
  );
};
