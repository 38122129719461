import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { PatientProfilePage } from "./PatientProfilePage";

export const PatientProfileEmbed: FC = () => {
  const [params] = useSearchParams();
  const patientId = params.get("patientId");
  const nationalId = params.get("nationalId");
  const visitId = params.get("visitId");
  const token = params.get("token");

  if (token) {
    return <PatientProfilePage token={token} patientId={patientId} nationalId={nationalId} visitId={visitId} />;
  } else {
    return <>you are not authenticated</>;
  }
};
