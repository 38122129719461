/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRiskFactorTemplatesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.RiskFactorTemplateFilterInput>;
  sortBy?: Types.InputMaybe<Types.RiskFactorTemplateSortingInput>;
}>;


export type GetRiskFactorTemplatesQuery = { __typename?: 'Query', getRiskFactorTemplates?: { __typename?: 'RiskFactorTemplateConnection', edges?: Array<{ __typename?: 'RiskFactorTemplateEdge', node?: { __typename?: 'RiskFactorTemplate', createdDate?: string | null, id?: string | null, isActive?: boolean | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', createdDate?: string | null, id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null, allowedValues?: Array<string | null> | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, type?: Types.TemplateFieldType | null, standard?: Types.FieldStandard | null, isMandatory?: boolean | null, defaultValue?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null> | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null, errorType?: Types.ErrorType | null, code?: Types.DecisionMakerErrorCodes | null } | null> | null } | null } | null> | null } | null };


export const GetRiskFactorTemplatesDocument = gql`
    query GetRiskFactorTemplates($first: Int, $last: Int, $before: String, $after: String, $filter: RiskFactorTemplateFilterInput, $sortBy: RiskFactorTemplateSortingInput) {
  getRiskFactorTemplates(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        createdDate
        id
        isActive
        fields {
          createdDate
          id
          display
          code
          arabicDisplay
          allowedValues
          minNormalRangeValue
          maxNormalRangeValue
          type
          standard
          isMandatory
          allowedValues
          defaultValue
          unit {
            id
            display
            code
            arabicDisplay
          }
          category {
            id
            display
            code
            arabicDisplay
          }
          errors {
            code
            errorType
            field
            message
          }
        }
        code
        display
        arabicDisplay
        errors {
          message
          field
          errorType
          code
        }
      }
    }
  }
}
    `;

/**
 * __useGetRiskFactorTemplatesQuery__
 *
 * To run a query within a React component, call `useGetRiskFactorTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskFactorTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskFactorTemplatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetRiskFactorTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetRiskFactorTemplatesQuery, GetRiskFactorTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRiskFactorTemplatesQuery, GetRiskFactorTemplatesQueryVariables>(GetRiskFactorTemplatesDocument, options);
      }
export function useGetRiskFactorTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRiskFactorTemplatesQuery, GetRiskFactorTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRiskFactorTemplatesQuery, GetRiskFactorTemplatesQueryVariables>(GetRiskFactorTemplatesDocument, options);
        }
export type GetRiskFactorTemplatesQueryHookResult = ReturnType<typeof useGetRiskFactorTemplatesQuery>;
export type GetRiskFactorTemplatesLazyQueryHookResult = ReturnType<typeof useGetRiskFactorTemplatesLazyQuery>;
export type GetRiskFactorTemplatesQueryResult = Apollo.QueryResult<GetRiskFactorTemplatesQuery, GetRiskFactorTemplatesQueryVariables>;