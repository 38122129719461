import { getAutocompleteFilter } from "@toolkit/ui";
import { useTeamLeaderDoctorsAutocompleteQuery } from "./gql";
export const getTeamLeaderDoctorsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "teamLeaderDoctors",
        query: useTeamLeaderDoctorsAutocompleteQuery,
        labelBy: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.fullName; },
        backendAccessor: item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.id; },
    });
};
