/* eslint-disable react/no-unused-prop-types */
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import { HealthProgram, HealthProgramMember, Maybe } from "@health/queries/types";
import { InsuranceInformationSection, SectionTitle } from "pages/appointments/components";
import moment from "moment";

type InsuranceInformationProps = {
  activeHealthProgramMember?: Maybe<Maybe<HealthProgramMember>>;
  healthProgramsByActiveMember?: Maybe<Maybe<HealthProgram>>;
};

export const InsuranceInformation: FC<InsuranceInformationProps> = props => {
  const { activeHealthProgramMember } = props;
  const { t, i18n } = useTranslation("provider");

  const isArabic = i18n.language === "ar";
  if (isArabic) {
    moment.locale("ar");
  }
  const formattedMembershipEnd = moment(activeHealthProgramMember?.membershipEnd).format("D MMM YYYY");

  return (
    <Box>
      <SectionTitle title={t("Insurance Information")} />

      <InsuranceInformationSection label={t("Company Name")} value={activeHealthProgramMember?.payer?.name} />
      <InsuranceInformationSection label={t("Card Id")} value={activeHealthProgramMember?.insuranceId} />
      <InsuranceInformationSection label={t("Expiry Date Insurance")} value={formattedMembershipEnd} />
    </Box>
  );
};
