import { guidedCareActivityTypeNonMedicalMessageOptions } from "@health/enum-options";
import { GuidedCareJourneyItemStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui";
import { GridProvider } from "shared/components";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { useCareJourneyGapsQuery } from "../../../gql";
import { useUpcomingPatientCareActivitiesColumns } from "./useUpcomingPatientCareActivitiesColumns";
import { Table } from "./Table";

export const CareJourneyGaps = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { patientId, selectedHealthProgram, token } = useProfileContext();

  return (
    <>
      <Typography fontSize={theme.mixins.fonts.fontSize.xxl} fontWeight={theme.mixins.fonts.fontWeight.medium} gutterBottom>
        {t("Care Journey Alerts")}
      </Typography>

      <GridProvider
        isCardView
        gridName='careJourneyGaps'
        query={useCareJourneyGapsQuery}
        columns={useUpcomingPatientCareActivitiesColumns({ upcoming: false })}
        context={getApolloContextFormToken(token!)}
        variables={{
          first: 10,
          filter: {
            patientId: patientId!,
            guidedCareHealthProgramId: selectedHealthProgram,
            types: guidedCareActivityTypeNonMedicalMessageOptions?.map(item => item?.value),
            statuses: [
              GuidedCareJourneyItemStatus.Failed,
              GuidedCareJourneyItemStatus.Missed,
              GuidedCareJourneyItemStatus.Pending,
              GuidedCareJourneyItemStatus.InProgress,
            ],
          },
        }}
        tableAction={{
          withoutDataMessage: " ",
        }}
      >
        <Table />
      </GridProvider>
    </>
  );
};
