import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";
export const Location: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='13.519' height='20.277' viewBox='0 0 13.519 20.277' {...props}>
      <path
        id='icons8-location_2_'
        d='M15.759,1A6.758,6.758,0,0,0,9,7.759c0,3.088,1.63,6.406,3.234,8.977a37.176,37.176,0,0,0,3.208,4.4.421.421,0,0,0,.634,0,39.084,39.084,0,0,0,3.208-4.449c1.6-2.586,3.234-5.9,3.234-8.925A6.758,6.758,0,0,0,15.759,1Zm0,.845a5.9,5.9,0,0,1,5.914,5.914c0,2.718-1.536,5.949-3.1,8.476a34.815,34.815,0,0,1-2.812,3.947,32.985,32.985,0,0,1-2.812-3.895c-1.564-2.508-3.1-5.745-3.1-8.528A5.9,5.9,0,0,1,15.759,1.845Zm0,3.38a2.957,2.957,0,1,0,2.957,2.957A2.963,2.963,0,0,0,15.759,5.225Zm0,.845a2.112,2.112,0,1,1-2.112,2.112A2.106,2.106,0,0,1,15.759,6.07Z'
        transform='translate(-9 -1)'
      />
    </SvgIcon>
  );
};
