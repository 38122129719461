import { guidedCareActivityTypeAppointmentOptions } from "@health/enum-options";
import { GuidedCareActivityType } from "@health/queries/types";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, Typography, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const AppointmentTypeField: FC = () => {
  const { t } = useTranslation("provider");
  const { register, setValue, watch } = useFormContext();
  const type = watch("type");
  const getOptionLabel = (value: GuidedCareActivityType) => {
    switch (value) {
      case GuidedCareActivityType.FacilityVisit:
        return t("In-Person", { ns: "provider" });
      case GuidedCareActivityType.OnlineVisit:
        return t("Virtual", { ns: "provider" });
      case GuidedCareActivityType.HomeVisit:
        return t("At Home", { ns: "provider" });
      default:
        return "";
    }
  };
  const options = guidedCareActivityTypeAppointmentOptions?.map(option => ({
    key: option.value,
    label: getOptionLabel(option.value),
    value: option.value,
  }));

  const defaultValue = options.find(opt => opt.value === type);

  useEffect(() => {
    register("type", {
      required: getRequiredValidation(t, true),
    });
    setValue("type", defaultValue);
  }, [register, setValue, t]);
  const theme = useTheme();
  return (
    <>
      <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium} gutterBottom>
        {t("Visit Type")}
      </Typography>

      <FormAutocomplete fullWidth name={"type"} placeholder={t("Visit Type")} options={options} />
    </>
  );
};
