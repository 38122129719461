import { useOnHealthProgramStatusUpdate } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import { useTheme } from "@toolkit/ui";
import { useMemo } from "react";
import { isTabsCountVisible } from "./constants";
import { useVisitsCountQuery } from "./gql";

export enum VisitTabsStatus {
  activeCall = "Active Call",
  incomplete = "Incomplete",
  canceled = "Canceled",
  completed = "Completed",
  newRequested = "New Request",
}

export const useTabs = ({ isSelfOnly }) => {
  const { data, refetch } = useVisitsCountQuery({ variables: { onlyMeSwitchValue: isSelfOnly }, skip: !isTabsCountVisible });
  const { t } = useTranslation("provider");
  const theme = useTheme();
  useOnHealthProgramStatusUpdate(({ data }) => {
    if (data.graphqlType === "Visit" && data?.id) {
      isTabsCountVisible && refetch();
    }
  });

  return useMemo(
    () => [
      {
        title: t("New Request"),
        color: theme.palette.primary.main,
        status: VisitTabsStatus.newRequested,
        count: data?.newRequested?.totalCount,
      },
      {
        title: t("Active Call"),
        color: theme.palette.success.main,
        status: VisitTabsStatus.activeCall,
        count: data?.activeCall?.totalCount,
      },
      {
        title: t("Incomplete"),
        color: theme.palette.warning.main,
        status: VisitTabsStatus.incomplete,
        count: data?.incomplete?.totalCount,
      },
    ],
    [JSON.stringify(data)]
  );
};

export const getSelfOnlyValue = ({ status, isSelfOnly }) => {
  if (status !== VisitTabsStatus.activeCall) {
    return isSelfOnly;
  }
  return true;
};
