/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorDepartmentDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type VendorDepartmentDeleteMutation = { __typename?: 'Mutation', vendorDepartmentDelete?: { __typename?: 'VendorDepartmentDelete', department?: { __typename?: 'Department', id: string } | null, vendorDepartmentErrors: Array<{ __typename?: 'VendorDepartmentError', code: Types.VendorErrorCode, field?: string | null, message?: string | null }> } | null };


export const VendorDepartmentDeleteDocument = gql`
    mutation VendorDepartmentDelete($id: ID!) {
  vendorDepartmentDelete(id: $id) {
    department {
      id
    }
    vendorDepartmentErrors {
      code
      field
      message
    }
  }
}
    `;
export type VendorDepartmentDeleteMutationFn = Apollo.MutationFunction<VendorDepartmentDeleteMutation, VendorDepartmentDeleteMutationVariables>;

/**
 * __useVendorDepartmentDeleteMutation__
 *
 * To run a mutation, you first call `useVendorDepartmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorDepartmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorDepartmentDeleteMutation, { data, loading, error }] = useVendorDepartmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorDepartmentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<VendorDepartmentDeleteMutation, VendorDepartmentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorDepartmentDeleteMutation, VendorDepartmentDeleteMutationVariables>(VendorDepartmentDeleteDocument, options);
      }
export type VendorDepartmentDeleteMutationHookResult = ReturnType<typeof useVendorDepartmentDeleteMutation>;
export type VendorDepartmentDeleteMutationResult = Apollo.MutationResult<VendorDepartmentDeleteMutation>;
export type VendorDepartmentDeleteMutationOptions = Apollo.BaseMutationOptions<VendorDepartmentDeleteMutation, VendorDepartmentDeleteMutationVariables>;