/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-statements */
import { AppointmentStatus } from "@health/queries/types";
import { Theme } from "@toolkit/ui";
import moment from "moment-timezone";
import { i18nProvider } from "shared/i18n";

export const getAppointmentNotificationStatusColor = (status: AppointmentStatus, theme: Theme) => {
  const successStatuses = [
    AppointmentStatus.Tentative,
    AppointmentStatus.Request,
    AppointmentStatus.CallInProgress,
    AppointmentStatus.ChatInProgress,
    AppointmentStatus.Approved,
    AppointmentStatus.Confirmed,
    AppointmentStatus.PaymentSucceeded,
  ];

  const secondaryStatuses = [
    AppointmentStatus.Rejected,
    AppointmentStatus.CanceledByDoctor,
    AppointmentStatus.Cancelled,
    AppointmentStatus.CanceledByConsumer,
  ];

  const primaryStatuses = [
    AppointmentStatus.PendingApproval,
    AppointmentStatus.RescheduledByConsumer,
    AppointmentStatus.RescheduledByDoctor,
  ];

  const warningStatuses = [AppointmentStatus.ComingSoon];

  if (successStatuses.includes(status)) {
    return theme.palette.success.main;
  }

  if (secondaryStatuses.includes(status)) {
    return theme.palette.secondary.main;
  }

  if (primaryStatuses.includes(status)) {
    return theme.palette.primary.main;
  }

  if (warningStatuses.includes(status)) {
    return theme.palette.warning.main;
  }

  return theme.palette.success.main;
};

export const getAppointmentDate = (data, t?, dateFormat = "dddd, DD MMMM YYYY") => {
  const translate = text => {
    return i18nProvider.t(text);
  };

  const formatTranslatedDate = date => {
    const [weekDay, restOfDate] = date.split(",");
    if (!restOfDate) return "";

    const [day, month, year] = restOfDate.trim().split(" ");
    const translatedWeekDay = translate(weekDay);
    const translatedMonth = translate(month);
    return `${translatedWeekDay}, ${day} ${translatedMonth} ${year}`;
  };
  const getDateFromTimeSlot = timeSlot => {
    return moment(JSON.parse(timeSlot).startTime).format(dateFormat);
  };

  if (data?.confirmedStartTime && data?.confirmedEndTime) {
    const formattedDate = moment(data?.confirmedStartTime).format(dateFormat);
    return formatTranslatedDate(formattedDate);
  }

  if (data?.suggestedTimeSlots?.[0]) {
    const formattedDate = getDateFromTimeSlot(data?.suggestedTimeSlots?.[0]);
    return formatTranslatedDate(formattedDate);
  }

  if (data?.requestedTimeSlots?.[0]) {
    const formattedDate = getDateFromTimeSlot(data?.requestedTimeSlots?.[0]);
    return formatTranslatedDate(formattedDate);
  }

  const formattedDate = moment(data?.created).format(dateFormat);
  return formatTranslatedDate(formattedDate);
};

export const getTimeFrame = item => {
  if (item?.confirmedStartTime && item?.confirmedEndTime) {
    return `${moment(item?.confirmedStartTime).format("hh:mmA")} - ${moment(item?.confirmedEndTime).format("hh:mmA")}`;
  }
  if (item?.suggestedTimeSlots?.[0]) {
    return item?.suggestedTimeSlots
      ?.map(slot => `${moment(JSON.parse(slot!)?.startTime).format("hh:mmA")} - ${moment(JSON.parse(slot!)?.endTime).format("hh:mmA")}`)
      .join(", ");
  } else {
    if (item?.requestedTimeSlots?.[0]) {
      return item?.requestedTimeSlots
        ?.map(slot => `${moment(JSON.parse(slot!)?.startTime).format("hh:mmA")} - ${moment(JSON.parse(slot!)?.endTime).format("hh:mmA")}`)
        .join(", ");
    } else {
      return "-";
    }
  }
};

export const formatTimeFromNow = (dateTime, t) => {
  const now = moment();
  const future = moment(dateTime).isAfter(now);
  const duration = moment.duration(future ? dateTime.diff(now) : now.diff(dateTime));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (years > 0) {
    return years === 1 ? t("{{ count }} year ago", { count: years }) : t("{{ count }} years ago", { count: years });
  }
  if (months > 0) {
    return months === 1 ? t("{{ count }} month ago", { count: months }) : t("{{ count }} months ago", { count: months });
  }
  if (days > 0) {
    return days === 1 ? t("{{ count }} day ago", { count: days }) : t("{{ count }} days ago", { count: days });
  }
  if (hours > 0) {
    return hours === 1 ? t("{{ count }} hour ago", { count: hours }) : t("{{ count }} hours ago", { count: hours });
  }
  if (minutes > 0) {
    return minutes === 1
      ? t(future ? "{{ count }} minute from now" : "{{ count }} minute ago", { count: minutes })
      : t(future ? "{{ count }} minutes from now" : "{{ count }} minutes ago", { count: minutes });
  }
  return seconds === 1
    ? t(future ? "{{ count }} second from now" : "{{ count }} second ago", { count: seconds })
    : t(future ? "{{ count }} seconds from now" : "{{ count }} seconds ago", { count: seconds });
};
