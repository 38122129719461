import { GuidedCareTaskActionsAppointment } from "pages/GuidedCareTasks/components/GuidedCareTaskActionsAppointment/GuidedCareTaskActionsAppointment";
import { GuidedCareTaskActionsMedicalForm } from "pages/GuidedCareTasks/components/GuidedCareTaskActionsMedicalForm/GuidedCareTaskActionsMedicalForm";
import { GuidedCareTaskUpdate } from "../GuidedCareTaskUpdate/GuidedCareTaskUpdate";
import { Appointment, GuidedCareTask, TaskWorkflowType } from "@health/queries/types";
import { Box, CircularProgress } from "@toolkit/ui";
import { isGuidedCareTaskActionable } from "pages/GuidedCareTasks/others";
import React, { FC } from "react";
import { useGuidedCareJourneyItemGetQuery } from "../../gql";

type GuidedCareTaskActionsProps = {
  guidedCareTask: GuidedCareTask;
  isUpdateHidden?: boolean;
};

export const GuidedCareTaskActions: FC<GuidedCareTaskActionsProps> = props => {
  const { guidedCareTask, isUpdateHidden = false } = props;

  const taskWorkflowType = guidedCareTask?.taskDefinition?.taskWorkflowType;
  const guidedCareTaskActionable = isGuidedCareTaskActionable(guidedCareTask?.status!);

  const { data, loading } = useGuidedCareJourneyItemGetQuery({
    variables: {
      guidedCareJourneyItemId: guidedCareTask?.guidedCareJourneyItemId!,
    },
    skip: !guidedCareTask?.guidedCareJourneyItemId,
  });

  const guidedCareJourneyItem = data?.guidedCareJourneyItem;

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1} marginBlock={2}>
      {loading ? (
        <CircularProgress color={"inherit"} size={20} />
      ) : (
        <>
          {guidedCareTaskActionable && (
            <>
              {taskWorkflowType === TaskWorkflowType.ConfirmAppointmentDate && (
                <GuidedCareTaskActionsAppointment appointment={guidedCareJourneyItem?.appointment as Appointment} />
              )}

              {taskWorkflowType === TaskWorkflowType.ReviewMedicalFormAnswer && (
                <GuidedCareTaskActionsMedicalForm
                  medicalFormAnswers={guidedCareJourneyItem?.medicalFormAnswers?.edges?.[0]?.node!}
                  guidedCareJourneyItemId={guidedCareTask?.guidedCareJourneyItemId!}
                  taskWorkflowType={guidedCareTask?.taskDefinition?.taskWorkflowType!}
                  assigneeUserId={guidedCareTask?.assignee?.[0]?.assigneeUserId!}
                />
              )}
            </>
          )}

          {!isUpdateHidden && <GuidedCareTaskUpdate guidedCareTask={guidedCareTask} />}
        </>
      )}
    </Box>
  );
};
