/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorUserDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type VendorUserDeleteMutation = { __typename?: 'Mutation', userDelete?: { __typename?: 'UserDelete', accountErrors: Array<{ __typename?: 'AccountError', code: Types.AccountErrorCode, field?: string | null, message?: string | null }>, user?: { __typename?: 'User', id: string } | null } | null };


export const VendorUserDeleteDocument = gql`
    mutation vendorUserDelete($id: ID!) {
  userDelete(id: $id) {
    accountErrors {
      code
      field
      message
    }
    user {
      id
    }
  }
}
    `;
export type VendorUserDeleteMutationFn = Apollo.MutationFunction<VendorUserDeleteMutation, VendorUserDeleteMutationVariables>;

/**
 * __useVendorUserDeleteMutation__
 *
 * To run a mutation, you first call `useVendorUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorUserDeleteMutation, { data, loading, error }] = useVendorUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<VendorUserDeleteMutation, VendorUserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorUserDeleteMutation, VendorUserDeleteMutationVariables>(VendorUserDeleteDocument, options);
      }
export type VendorUserDeleteMutationHookResult = ReturnType<typeof useVendorUserDeleteMutation>;
export type VendorUserDeleteMutationResult = Apollo.MutationResult<VendorUserDeleteMutation>;
export type VendorUserDeleteMutationOptions = Apollo.BaseMutationOptions<VendorUserDeleteMutation, VendorUserDeleteMutationVariables>;