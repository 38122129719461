import { isRTL, useTranslation } from "@toolkit/i18n";
import { Avatar, Button, Toolbar } from "@toolkit/ui";
import { FC } from "react";
import { Tab, Tabs } from "react-tabs-scrollable";
// eslint-disable-next-line no-restricted-imports
import "react-tabs-scrollable/dist/rts.css";
import type { PageTabsStatusType, PageTabsType } from "./PageTabs.types";
import "./style.css";
import { useStyles } from "./tabs.styles";

export const PageTabs: FC<{
  onChangeTab: (tab: PageTabsStatusType) => void;
  tabs: PageTabsType;
  selected: PageTabsStatusType;
  disabled?: boolean;
  showTabCount?: boolean;
}> = ({ onChangeTab, selected: selectedTab, tabs, disabled = false, showTabCount = true }) => {
  const { t } = useTranslation("provider");

  const isRtl = isRTL();

  const selected = tabs.findIndex(t => t.status === selectedTab);

  const handleClick = (status: PageTabsStatusType) => () => {
    onChangeTab(status);
  };
  const { classes, theme } = useStyles();
  const onTabClicked = () => {};

  const formatCount = (count: string | number | undefined): string => {
    if (count === undefined) return "0";

    const countNumber = typeof count === "string" ? parseFloat(count) : count;
    if (!isNaN(countNumber) && countNumber >= 1000) {
      const formattedCount = (countNumber / 1000).toFixed(1);
      return formattedCount.endsWith(".0") ? formattedCount.slice(0, -2) + t("k") : formattedCount + t("k");
    }

    return countNumber.toString();
  };

  return (
    <Toolbar className={classes.toolbar}>
      <Tabs
        tabsUpperContainerClassName={classes.tabsUpperContainer}
        tabsScrollAmount={2}
        hideNavBtns={false}
        hideNavBtnsOnMobile={false}
        isRTL={isRtl}
        activeTab={selected}
        onTabClick={onTabClicked}
      >
        {tabs.map((tab, index) => (
          <Tab className={classes.tab} key={index}>
            <Button
              disabled={disabled}
              key={tab.title.toLowerCase().split(" ").concat(index.toString()).join("-")}
              sx={{
                borderRadius: 0,
                backgroundColor: selected === index ? theme.palette.stale[100] : "inherit",
                marginInline: 0,
                height: 55,
                paddingInline: 3,
                minWidth: 100,
              }}
              className={disabled ? classes.disabled : ""}
              variant='text'
              startIcon={tab?.icon}
              endIcon={
                showTabCount && (
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      height: 26,
                      width: 26,
                      fontSize: `${theme.mixins.fonts.fontSize.xs} !important`,
                    }}
                  >
                    {formatCount(tab.count || 0)}
                  </Avatar>
                )
              }
              onClick={handleClick(tab.status)}
            >
              {tab.title}
            </Button>
          </Tab>
        ))}
      </Tabs>
    </Toolbar>
  );
};
