import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const RescheduleIcon: FC<SvgIconProps> = ({ width, height, fill }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
      <path
        id='icons8-schedule_2_'
        data-name='icons8-schedule (2)'
        d='M5.846,4A1.846,1.846,0,0,0,4,5.846V22.462a1.846,1.846,0,0,0,1.846,1.846h9.865a6.455,6.455,0,1,0,8.6-8.6V5.846A1.846,1.846,0,0,0,22.462,4Zm0,1.846H22.462V9.538H5.846Zm4.615,6.462a.923.923,0,1,1-.923.923A.923.923,0,0,1,10.462,12.308Zm3.692,0a.923.923,0,1,1-.923.923A.923.923,0,0,1,14.154,12.308Zm3.692,0a.923.923,0,1,1-.923.923A.923.923,0,0,1,17.846,12.308Zm3.692,0a.923.923,0,1,1-.923.923A.923.923,0,0,1,21.538,12.308ZM6.769,16a.923.923,0,1,1-.923.923A.923.923,0,0,1,6.769,16Zm3.692,0a.923.923,0,1,1-.923.923A.923.923,0,0,1,10.462,16Zm3.692,0a.923.923,0,1,1-.923.923A.923.923,0,0,1,14.154,16Zm7.385.923a4.615,4.615,0,1,1-4.615,4.615A4.6,4.6,0,0,1,21.538,16.923Zm-.014.91a.923.923,0,0,0-.909.936v3.151l1.648,1.648a.923.923,0,1,0,1.305-1.305l-1.107-1.107V18.769a.923.923,0,0,0-.937-.936ZM6.769,19.692a.923.923,0,1,1-.923.923A.923.923,0,0,1,6.769,19.692Zm3.692,0a.923.923,0,1,1-.923.923A.923.923,0,0,1,10.462,19.692Zm3.692,0a.923.923,0,1,1-.923.923A.923.923,0,0,1,14.154,19.692Z'
        transform='translate(-4 -4)'
        fill={fill ?? palette.primary.main}
      />
    </SvgIcon>
  );
};
