/* eslint-disable max-lines */
export var EVENT_TOPIC;
(function (EVENT_TOPIC) {
    EVENT_TOPIC["PING_PONG"] = "PING_PONG";
    EVENT_TOPIC["ORDER_STATUS_UPDATE"] = "ORDER_STATUS_UPDATE";
    EVENT_TOPIC["MEDICAL_DELIVERY_REQUEST_STATUS_CHANGED"] = "ERX_DELIVERY_STATUS_UPDATE";
    EVENT_TOPIC["PRESCRIPTION_STATUS_CHANGED"] = "ERX_STATUS_UPDATE";
    EVENT_TOPIC["ORDER_DELETED"] = "ORDER_DELETED";
    EVENT_TOPIC["CHAT_MESSAGE_RECEIVED"] = "CHAT";
    EVENT_TOPIC["NEED_ACTION_CODE_SYSTEM"] = "NEED_ACTION_CODE_SYSTEM";
    EVENT_TOPIC["HEALTH_PROGRAM_STATUS_UPDATE"] = "HEALTH_PROGRAM_VISIT_STATUS_CHANGED";
    EVENT_TOPIC["HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED"] = "HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED";
    EVENT_TOPIC["DOCTOR_AVAILABILITY_STATUS_CHANGED"] = "DOCTOR_AVAILABILITY_STATUS_CHANGED";
    EVENT_TOPIC["CHRONIC_PROGRAM_ENROLLMENT"] = "CHRONIC_PROGRAM_ENROLLMENT";
    EVENT_TOPIC["PATIENT_PROFILE_ACCESS_REQUEST"] = "PATIENT_PROFILE_ACCESS_REQUEST";
    EVENT_TOPIC["PATIENT_PROFILE_ACCESS_RESPONSE"] = "PATIENT_PROFILE_ACCESS_RESPONSE";
    EVENT_TOPIC["PATIENT_PROFILE_ACCESS_CLOSE"] = "PATIENT_PROFILE_ACCESS_CLOSE";
    EVENT_TOPIC["ORDER_TIMEOUT_ALERT"] = "ORDER_TIMEOUT_ALERT";
    EVENT_TOPIC["HEALTH_PROGRAM_APPOINTMENT_REMINDER"] = "HEALTH_PROGRAM_APPOINTMENT_REMINDER";
    EVENT_TOPIC["GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER"] = "GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER";
    EVENT_TOPIC["NOTIFICATION_COUNT_AFTER_MARK_ALL_NOTIFICATION_AS_READ"] = "MARK_ALL_AS_READ";
    EVENT_TOPIC["NOTIFICATION_COUNT_AFTER_MARK_A_NOTIFICATION_AS_READ"] = "MARK_AS_READ";
    EVENT_TOPIC["NOTIFICATION_COUNT_AFTER_NEW_NOTIFICATION"] = "NEW_NOTIFICATION";
    EVENT_TOPIC["VISIT_VITAL_SIGNS"] = "VISIT_VITAL_SIGNS";
    EVENT_TOPIC["OPTIMA_VALIDATION_REQUEST"] = "VALIDATION_REQUEST";
    EVENT_TOPIC["HEALTH_PARAMETERS"] = "HEALTH_PARAMETERS";
    EVENT_TOPIC["MEDICAL_FORM"] = "MEDICAL_FORM";
})(EVENT_TOPIC || (EVENT_TOPIC = {}));
