import { useTranslation } from "@toolkit/i18n";
import { FC, memo, useEffect } from "react";
// import { pharmacyUserPreferencesVar } from "shared/components";
import { OrdersBody } from "../components";
import { useOrderPageContext } from "../state";
import { mapStatusToName } from "../statuses";
import { ColumnHeader } from "./ColumnHeader";

interface IOrderColumn {
  status: string;
}

export const OrderColumn: FC<IOrderColumn> = memo(({ status }: IOrderColumn) => {
  const { t } = useTranslation("provider");
  const { fetchDataByStatus, filter } = useOrderPageContext();

  const name = t(mapStatusToName(status));

  // const pharmacyBranch = useReactiveVar(pharmacyUserPreferencesVar);

  useEffect(() => {
    fetchDataByStatus(status, filter);
  }, [status, filter]);

  return (
    <>
      <ColumnHeader name={name} status={status} />
      <OrdersBody status={status} />
    </>
  );
});
