import React, { FC, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { Box } from "@toolkit/ui";
import { useLocation } from "react-router-dom";
import { ECharts } from "echarts";

type ECGChartProps = {
  widthValue?: number | string;
  options?: {
    value: number;
    unit?: string;
    name?: string;
    color?: string;
    date?: string;
    source?: string;
  }[];
};

export const ECGChart: FC<ECGChartProps> = ({ widthValue, options }) => {
  const chartRefLine = useRef(null);
  const chart = useRef<ECharts | null>(null);

  const dataView = options?.map(item => item?.value!) || [];
  const [renderedData, setRenderedData] = useState<number[]>([]);
  const [dataIndex, setDataIndex] = useState(0);
  const pathname = useLocation().pathname;

  useEffect(() => {
    const interval = setInterval(() => {
      if (dataIndex < dataView.length) {
        const updatedData = dataView.slice(dataIndex, dataIndex + 612);
        setRenderedData(prevData => prevData.concat(updatedData));
        setDataIndex(dataIndex + 612);
      } else {
        clearInterval(interval);
      }
    }, 250);

    return () => clearInterval(interval);
  }, [dataIndex, dataView, JSON.stringify(options), options?.length]);

  useEffect(() => {
    if (options?.length) {
      chart.current = echarts.init(chartRefLine.current);

      chart.current?.setOption({
        grid: {
          show: true,
          containLabel: false,
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: true,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            name: "ECG",
            type: "line",
            data: renderedData,
            hoverAnimation: false,
            animation: false,
            // smooth: true,
            symbol: "none",
            itemStyle: {
              color: "#25287F",
            },
          },
        ],
        dataZoom: [
          {
            type: "slider",
            start: 70,
            end: 100,
          },
          {
            type: "inside",
            start: 70,
            end: 100,
          },
        ],
      });
    }
  }, [renderedData, renderedData?.length, widthValue, JSON.stringify(options), options?.length]);

  useEffect(() => {
    return () => {
      chart.current?.dispose();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: widthValue || "100%",
        marginTop: -6,
        height: pathname === "/embed/live-signs" && options?.length ? "360px" : "220px",
      }}
    >
      <Box ref={chartRefLine} sx={{ width: widthValue || "100%", height: "100%" }} />
    </Box>
  );
};
