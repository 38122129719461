/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplacePromotionQueryVariables = Types.Exact<{
  marketplacePromotionId: Types.Scalars['ID'];
}>;


export type MarketplacePromotionQuery = { __typename?: 'Query', marketplacePromotion?: { __typename?: 'MarketplacePromotion', id: string, name?: string | null, description?: string | null, isPublished: boolean, photo?: string | null, startDate?: any | null, endDate?: any | null, productCategories?: Array<{ __typename?: 'MarketplaceProductCategory', name?: string | null, nameAr?: string | null, id: string } | null> | null, products?: Array<{ __typename?: 'MarketplaceProduct', id: string, nameAr?: string | null, name?: string | null } | null> | null, healthPackages?: Array<{ __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null } | null> | null, healthPackageCategories?: Array<{ __typename?: 'MarketplaceHealthPackageCategory', nameAr?: string | null, name?: string | null, id: string } | null> | null } | null };


export const MarketplacePromotionDocument = gql`
    query MarketplacePromotion($marketplacePromotionId: ID!) {
  marketplacePromotion(id: $marketplacePromotionId) {
    id
    name
    description
    isPublished
    photo
    productCategories {
      name
      nameAr
      id
    }
    products {
      id
      nameAr
      name
    }
    healthPackages {
      id
      name
      nameAr
    }
    healthPackageCategories {
      nameAr
      name
      id
    }
    startDate
    endDate
  }
}
    `;

/**
 * __useMarketplacePromotionQuery__
 *
 * To run a query within a React component, call `useMarketplacePromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplacePromotionQuery({
 *   variables: {
 *      marketplacePromotionId: // value for 'marketplacePromotionId'
 *   },
 * });
 */
export function useMarketplacePromotionQuery(baseOptions: Apollo.QueryHookOptions<MarketplacePromotionQuery, MarketplacePromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplacePromotionQuery, MarketplacePromotionQueryVariables>(MarketplacePromotionDocument, options);
      }
export function useMarketplacePromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplacePromotionQuery, MarketplacePromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplacePromotionQuery, MarketplacePromotionQueryVariables>(MarketplacePromotionDocument, options);
        }
export type MarketplacePromotionQueryHookResult = ReturnType<typeof useMarketplacePromotionQuery>;
export type MarketplacePromotionLazyQueryHookResult = ReturnType<typeof useMarketplacePromotionLazyQuery>;
export type MarketplacePromotionQueryResult = Apollo.QueryResult<MarketplacePromotionQuery, MarketplacePromotionQueryVariables>;