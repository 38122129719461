import {
  getDoctorsAutocompleteFilter,
  getGuidedCareProgramsAutocompleteFilter,
  getHealthProgramMembersAutocompleteFilter,
  getPatientsAutocompleteFilter,
  getPayersAutocompleteFilter,
  getVendorsAutocompleteFilter,
} from "@health/autocompletes";
import {
  ProviderGuidedCareHealthProgramTeamSortingField,
  ProviderGuidedCareHealthProgramTeam,
  H_OrderDirection,
} from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton, useFormatDate } from "@toolkit/ui";
import { healthProviderGuidedCareTeamsPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { GuidedCareTeamActivation, GuidedCareTeamStatus } from "../../components";

export const useGuidedCareTeamListTableColumns = (): CustomTableColumnProps<ProviderGuidedCareHealthProgramTeam>[] => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();

  const { formatLocalizedDate } = useFormatDate();

  const onViewPatientsClick = useCallback(
    (teamId: string) => {
      navigate(healthProviderGuidedCareTeamsPaths.patients.fullPathWithParams({ teamId }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Name,
      },
      {
        key: "description",
        header: t("Description"),
        accessor: "description",
      },
      {
        key: "program",
        header: t("Program"),
        accessor: ({ guidedCareHealthProgram }) => guidedCareHealthProgram?.name,
        filter: getGuidedCareProgramsAutocompleteFilter({ name: "guidedCareHealthProgramId" }),
      },
      {
        key: "payer",
        header: t("Payer"),
        accessor: ({ guidedCareHealthProgram }) => guidedCareHealthProgram?.payer?.name,
        filter: getPayersAutocompleteFilter({ name: "payerId" }),
      },
      {
        key: "provider",
        header: t("Provider"),
        showOnlyForFilterField: true,
        filter: getVendorsAutocompleteFilter({ name: "providerId" }),
      },
      {
        key: "doctors",
        header: t("Doctors"),
        showOnlyForFilterField: true,
        filter: getDoctorsAutocompleteFilter({ name: "doctorIds", multiple: true }),
      },
      {
        key: "healthProgramMembers",
        header: t("Health Program Members"),
        showOnlyForFilterField: true,
        filter: getHealthProgramMembersAutocompleteFilter({ name: "healthProgramMembers", multiple: true }),
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ teamStatus }) => <GuidedCareTeamStatus teamStatus={teamStatus} />,
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Status,
      },
      {
        key: "patients",
        header: t("Patients"),
        accessor: ({ id }) => <ShowButton onClick={() => onViewPatientsClick(id)} />,
        type: "string",
        filter: getPatientsAutocompleteFilter({ name: "patients", multiple: true }),
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatLocalizedDate(createdDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Created,
      },
      {
        key: "lastModifiedDate",
        header: t("Last Modified Date"),
        accessor: ({ lastModifiedDate }) => formatLocalizedDate(lastModifiedDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: ProviderGuidedCareHealthProgramTeamSortingField.Modified,
      },
      {
        key: "isActive",
        header: t("Active"),
        type: "string",
        accessor: ({ id, isActive }) => <GuidedCareTeamActivation id={id} isActive={!!isActive} />,
      },
    ];
  }, [formatLocalizedDate, onViewPatientsClick, t]);
};
