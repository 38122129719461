import { AddDiagnosisToVisitInput, VisitDiagnosis, VisitDiagnosisType } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { getApolloContextFormToken } from "shared/utils";
import { useCoPilotContext } from "../CoPilot/CoPilotContext";
import { VisitDiagnosesDocument, useAddDiagnosisToVisitMutation, useDeleteVisitDiagnosisMutation, useVisitDiagnosesQuery } from "../gql";

export type FormValues = Omit<Partial<AddDiagnosisToVisitInput>, "visitDiagnosisType" | "code" | "visitId"> & {
  visitDiagnosisType: {
    value: VisitDiagnosisType;
  };
  code: {
    value: {
      code: string;
    };
  };
};

export const useVisitDiagnosesHooks = (visitId: string, token: string | undefined) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const { handleGetTemplates, handleSelectedVisitDiagnosis } = useCoPilotContext();

  const { data: visit, loading } = useVisitDiagnosesQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
    skip: !visitId,
  });

  const [addDiagnosisToVisit, { data, loading: isSubmitting }] = useAddDiagnosisToVisitMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const visitDiagnosisErrors = data?.addDiagnosisToVisit?.visitDiagnosisErrors;
      if (visitDiagnosisErrors?.length === 0) {
        succeeded(t("Diagnose Added Successfully"));
        handleSelectedVisitDiagnosis?.(data?.addDiagnosisToVisit?.visitDiagnosis!);
      } else {
        failed(t("Added Diagnose Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [VisitDiagnosesDocument],
  });

  const handleCreate: SubmitHandler<FormValues> = input => {
    addDiagnosisToVisit({
      variables: {
        input: {
          visitId: visitId!,
          visitDiagnosisType: input?.visitDiagnosisType?.value,
          code: input?.code && input?.code?.value?.code,
        },
      },
    });
  };

  const [deleteVisitDiagnosis] = useDeleteVisitDiagnosisMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const visitDiagnosisErrors = data?.deleteVisitDiagnosis?.visitDiagnosisErrors;
      if (visitDiagnosisErrors?.length === 0) {
        succeeded(t("Diagnose Delete Successfully"));
      } else {
        failed(t("Deleted Diagnose Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [VisitDiagnosesDocument],
  });

  const handleDelete = data => {
    deleteVisitDiagnosis({
      variables: {
        deleteVisitDiagnosisId: data?.id!,
      },
    });
  };

  const visitDiagnoses = visit?.visit?.visitDiagnoses as VisitDiagnosis[];
  const riskTemplate = data?.addDiagnosisToVisit?.visitDiagnosis?.riskTemplate;

  useEffect(() => {
    riskTemplate?.code && handleGetTemplates?.(riskTemplate?.code);
    handleSelectedVisitDiagnosis?.(data?.addDiagnosisToVisit?.visitDiagnosis!);
  }, [JSON.stringify(riskTemplate)]);

  return {
    loading,
    visitDiagnoses,
    riskTemplate,
    handleCreate,
    handleDelete,
    handleSelectedVisitDiagnosis,
    isSubmitting,
  };
};
