/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeDoctorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeDoctorQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, doctor?: { __typename?: 'Doctor', availabilityStatus: Types.DoctorAvailabilityStatusEnum, lastAvailability?: { __typename?: 'DoctorAvailability', id: string, startTime: any, endTime?: any | null, status: Types.DoctorAvailabilityStatus, period?: number | null } | null } | null } | null };


export const MeDoctorDocument = gql`
    query MeDoctor {
  me {
    id
    doctor {
      availabilityStatus
      lastAvailability {
        id
        startTime
        endTime
        status
        period
      }
    }
  }
}
    `;

/**
 * __useMeDoctorQuery__
 *
 * To run a query within a React component, call `useMeDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeDoctorQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeDoctorQuery(baseOptions?: Apollo.QueryHookOptions<MeDoctorQuery, MeDoctorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeDoctorQuery, MeDoctorQueryVariables>(MeDoctorDocument, options);
      }
export function useMeDoctorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeDoctorQuery, MeDoctorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeDoctorQuery, MeDoctorQueryVariables>(MeDoctorDocument, options);
        }
export type MeDoctorQueryHookResult = ReturnType<typeof useMeDoctorQuery>;
export type MeDoctorLazyQueryHookResult = ReturnType<typeof useMeDoctorLazyQuery>;
export type MeDoctorQueryResult = Apollo.QueryResult<MeDoctorQuery, MeDoctorQueryVariables>;