import { makeVar } from "@apollo/client";
import { selectedBranchData } from "./utils";

type pharmacyUserPreferencesType = {
  branch: {
    name: string;
    nameAr: string;
    id: string;
  };
  city?:
    | {
        name: string;
        id: string;
      }
    | undefined;
};

export const branchUserPreferenceVar = makeVar<pharmacyUserPreferencesType | undefined>(JSON.parse(String(selectedBranchData)));
