import { VisitStatus } from "@health/queries/types";
import { FC } from "react";
import { CanceledView } from "./VisitsViews/CanceledView";
import { TerminatedView } from "./VisitsViews/TerminatedView";

interface ViewVisitProps {
  status: VisitStatus | null | undefined;
  cancellationReason?: string | null | undefined;
  doctorFullName?: string | null | undefined;
  patientFullName?: string | null | undefined;
}
export const ViewVisit: FC<ViewVisitProps> = ({ status, cancellationReason, doctorFullName, patientFullName }) => {
  switch (status) {
    case VisitStatus.CanceledByConsumer:
    case VisitStatus.Canceled:
      return <CanceledView cancellationReason={cancellationReason} doctorFullName={doctorFullName} patientFullName={patientFullName} />;
    case VisitStatus.EndedByOneOfTheParticipants:
      return <TerminatedView doctorFullName={doctorFullName} patientFullName={patientFullName} />;

    default:
      return <></>;
  }
};
