import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  doctorCommonButton: {
    height: "40px",
    borderRadius: "50px",
  },
  doctorStatusMenu: {
    minWidth: "190px",
    borderRadius: 10,
    marginTop: 4,
    padding: 0,
  },
  menuItem: {
    margin: "9px 0px 9px 0px",
    padding: 5,
    "&:hover": {
      background: "none",
    },
  },
  menuItemButtonAvailable: {
    width: "100%",
    justifyContent: "flex-start",
    color: theme.palette.success.main,
    border: "1px solid",
    borderColor: theme.palette.success.main,
    background: theme.palette.common.white,
    borderRadius: 50,
    "&: hover": {
      background: theme.palette.success.main,
      color: theme.palette.common.white,
    },
  },
  menuItemButtonUnavailable: {
    width: "100%",
    justifyContent: "flex-start",
    color: theme.palette.error.main,
    border: "1px solid",
    borderColor: theme.palette.error.main,
    background: theme.palette.common.white,
    borderRadius: 50,
    "&: hover": {
      background: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  },
  menuItemButtonUrgentCallsOnly: {
    color: theme.palette.warning.main,
    border: "1px solid",
    borderColor: theme.palette.warning.main,
    background: theme.palette.common.white,
    borderRadius: 50,
    "&: hover": {
      background: theme.palette.warning.main,
      color: theme.palette.common.white,
    },
  },
  iconMargin: {
    marginInlineEnd: 5,
  },
}));
