import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AppLogo, Box, Button, CustomDialog, DeliveringIcon, Typography } from "@toolkit/ui";
import QRCode from "qrcode.react";
import { FC, useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { CircularIntegration } from "./CircularIntegration";
import { useDispenseModelStyles } from "./DispenseModel.styles";
import OrderQR from "./OrderQR.component";

type NewDispenseModelProps = {
  number: number | string | null;
  hasAwb?: boolean;
  type?: string | null;
};

export const ViewQRButton: FC<NewDispenseModelProps> = props => {
  const { open, handleOpen, handleClose } = useOpenState();

  const { number, type, hasAwb = false } = props;
  const { t } = useTranslation("provider");
  const { classes } = useDispenseModelStyles();
  const [toPrint, setToPrint] = useState<Date | undefined>();
  const [printReady, setPrintReady] = useState<Date | undefined>();
  const [isQRReady, setIsQRReady] = useState<boolean>(false);

  useEffect(() => {
    if (printReady) handlePrint();
  }, [printReady]);

  useEffect(() => {
    let timer;
    if (type === "HOME_DELIVERY") {
      timer = setTimeout(() => {
        setIsQRReady(true);
      }, 2000);
    } else {
      setIsQRReady(true);
    }
    return () => timer && clearTimeout(timer);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });
  const componentRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    setToPrint(new Date());
  };
  // const { data, loading } = useGetShipmentOrderForDeliveryShipmentQuery({
  //   variables: {
  //     dispenseOrderId: id,
  //   },
  //   skip: !open || !hasAwb,
  //   fetchPolicy: "no-cache",
  // });
  //
  // const awb = data?.getShipmentOrderForDeliveryShipment?.data?.awb || "";

  return (
    <CircularIntegration loading={!isQRReady}>
      <Button onClick={handleOpen} className={classes.button} disabled={!isQRReady}>
        {t("View QR")}
      </Button>
      {open && (
        <CustomDialog
          type='base'
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
          }}
          DialogTitleProps={{
            title: (
              <Box display='flex' gap={"10px"} height={"100%"}>
                <Box>
                  <DeliveringIcon />
                </Box>
                <Box>{t("Order QR Code")}</Box>
              </Box>
            ),
          }}
          DialogActionsProps={{
            children: (
              <>
                <ReactToPrint
                  trigger={() => (
                    <Button className={classes.print} onClick={handleClick}>
                      {t("Print")}
                    </Button>
                  )}
                  content={() => componentRef?.current}
                />
                <OrderQR
                  setPrintReady={setPrintReady}
                  toPrint={toPrint}
                  refForPrint={componentRef}
                  number={String(number)} // awb={awb}
                />
                <Button onClick={handleClose} className={classes.ok}>
                  {t("Ok")}
                </Button>
              </>
            ),
          }}
        >
          <Box className={classes.box}>
            <Typography className={classes.id} gutterBottom>
              {t("Order ID")} - #{number}
            </Typography>
            <div className={classes.container}>
              <div className={classes.logo}>
                <AppLogo height={147} width={173} />
              </div>
              <QRCode id={String(number)} value={String(number)} size={300} level={"H"} includeMargin={true} />
            </div>
          </Box>

          {hasAwb && (
            <Box className={classes.box}>
              {/* {loading ? (*/}
              {/*  <CircularProgress />*/}
              {/* ) : (*/}
              {/*  <>*/}
              {/*    {awb ? (*/}
              {/*      <Typography className={classes.id} gutterBottom>*/}
              {/*        {t("AWB Number")} - {awb}*/}
              {/*      </Typography>*/}
              {/*    ) : (*/}
              {/*      <Typography className={classes.id} gutterBottom>*/}
              {/*        {t("No AWB Number")}*/}
              {/*      </Typography>*/}
              {/*    )}*/}
              {/*  </>*/}
              {/* )}*/}
            </Box>
          )}
        </CustomDialog>
      )}
    </CircularIntegration>
  );
};

ViewQRButton.defaultProps = {
  hasAwb: false,
  type: undefined,
};
