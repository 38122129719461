import { makeStyles } from "@toolkit/ui";

export const useHealthPackageOrdersHeaderStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.common.white,
    height: 55,
  },
  selected: {
    backgroundColor: theme.palette.stale[100],
  },
  button: {
    color: theme.palette.primary.main,
    margin: 0,
    borderRadius: 0,
    height: "100%",
  },
}));
