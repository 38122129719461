/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamUpdateMutationVariables = Types.Exact<{
  input: Types.ProviderGuidedCareHealthProgramTeamInput;
}>;


export type GuidedCareTeamUpdateMutation = { __typename?: 'Mutation', providerGuidedCareHealthProgramTeamUpdate?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCRUD', providerGuidedCareHealthProgramTeamErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTeamUpdateDocument = gql`
    mutation GuidedCareTeamUpdate($input: ProviderGuidedCareHealthProgramTeamInput!) {
  providerGuidedCareHealthProgramTeamUpdate(input: $input) {
    providerGuidedCareHealthProgramTeamErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTeamUpdateMutationFn = Apollo.MutationFunction<GuidedCareTeamUpdateMutation, GuidedCareTeamUpdateMutationVariables>;

/**
 * __useGuidedCareTeamUpdateMutation__
 *
 * To run a mutation, you first call `useGuidedCareTeamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTeamUpdateMutation, { data, loading, error }] = useGuidedCareTeamUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareTeamUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTeamUpdateMutation, GuidedCareTeamUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTeamUpdateMutation, GuidedCareTeamUpdateMutationVariables>(GuidedCareTeamUpdateDocument, options);
      }
export type GuidedCareTeamUpdateMutationHookResult = ReturnType<typeof useGuidedCareTeamUpdateMutation>;
export type GuidedCareTeamUpdateMutationResult = Apollo.MutationResult<GuidedCareTeamUpdateMutation>;
export type GuidedCareTeamUpdateMutationOptions = Apollo.BaseMutationOptions<GuidedCareTeamUpdateMutation, GuidedCareTeamUpdateMutationVariables>;