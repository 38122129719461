const LabTestIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.495 22.495' width={22.495} height={22.495} {...props}>
      <path
        d='M7.157 0a1.023 1.023 0 1 0 0 2.045v8.18l-6.7 8.931A2.044 2.044 0 0 0 2.045 22.5h18.4a2.044 2.044 0 0 0 1.584-3.337l-6.7-8.931V2.045a1.023 1.023 0 1 0 0-2.045ZM9.2 2.045h4.09V8.18H9.2Zm1.022 8.18A1.023 1.023 0 1 1 9.2 11.248a1.023 1.023 0 0 1 1.025-1.023ZM13.8 16.36a1.534 1.534 0 1 1-1.534 1.534A1.534 1.534 0 0 1 13.8 16.36Z'
        fill='#fff'
      />
    </svg>
  );
};

export default LabTestIcon;
