import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  iconButton: {
    width: 35,
    height: 35,
    marginInline: "16px",
    marginBlock: "8px",
    color: theme.palette.primary.main,
    alignSelf: "start",
  },
}));
