import { AccountError, User } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useLanguagesQuery } from "../../gql";

export const DoctorLanguages: FC<{ errors: AccountError[]; defaultValues?: User | undefined }> = ({ errors, defaultValues }) => {
  const { t } = useTranslation("provider");
  const { formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);

  const { data, loading, fetchMore, refetch } = useLanguagesQuery({
    variables: {
      first: 10,
      after: null,
      before: null,
      last: null,
      filter: {
        display: "",
      },
    },
  });

  const pageInfo = data?.languages?.pageInfo;
  const hasNextPage = pageInfo?.hasNextPage;
  const languages = data?.languages?.edges?.map(item => item?.node)!;
  const defaultValue = defaultValues?.doctor?.languages;
  const handleFetchMore = useCallback(() => {
    if (pageInfo?.hasNextPage && !loading) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            languages: {
              ...fetchMoreResult.languages,
              edges: [...(prev?.languages?.edges || []), ...(fetchMoreResult?.languages?.edges || [])],
            },
          });
        },
      });
    }
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage, loading]);

  const handleSearch = (filter: string) => {
    refetch({
      filter: {
        display: filter,
      },
    });
  };
  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        ControllerProps={{
          name: "doctorInfo.languages",
          control: control,
          rules: { required: false },
          defaultValue: defaultValue,
        }}
        multiple
        loading={loading}
        fetchMoreData={handleFetchMore}
        hasMore={hasNextPage}
        TextFieldProps={{
          error: Boolean(combinedErrors?.doctorInfo?.languages),
          helperText: combinedErrors?.doctorInfo?.languages && t("Required"),
          label: t("Languages"),
        }}
        getOptionLabel={o => pickLocalizedValue(o.display, o?.displayAr)}
        options={languages || []}
        isOptionEqualToValue={(option, value) => option?.code === value.code}
        onSearchInput={handleSearch}
      />
    </Grid>
  );
};
