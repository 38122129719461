/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, status: Types.OrderStatus, number: string, created: any, prescription: { __typename?: 'Prescription', id: string, referenceNumber: string, patient: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null, id: string }, medications: Array<{ __typename?: 'Medication', id: number, type?: Types.ActivityType | null, display?: string | null, quantity?: number | null, instructions?: string | null, arabicInstructions?: string | null, refills?: number | null }> }, address: { __typename?: 'PrescriptionAddress', id: string, name?: string | null, buildingName?: string | null, buildingNumber?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, phone?: string | null, postalCode?: string | null, city?: never | null, coordinates?: never | null }, branch?: { __typename?: 'Branch', id: string, name: string, contactNumber?: string | null, vendor: { __typename?: 'Vendor', id: string, name: string }, address?: { __typename?: 'Address', id: string, name?: string | null, nameAr?: string | null, area: string, areaAr: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null } | null, orderLines: Array<{ __typename?: 'OrderLine', authStatus?: Types.AuthorizationStatus | null, id: string, rejectionReason?: string | null, quantity?: number | null, patientShareAmount?: number | null, netAmount?: number | null, tradeCode?: string | null, variantCode?: string | null }> } | null };


export const OrderDocument = gql`
    query order($id: ID!) {
  order(id: $id) {
    id
    status
    number
    prescription {
      id
      referenceNumber
      patient {
        firstName
        lastName
        id
      }
      medications {
        id
        type
        display
        quantity
        instructions
        arabicInstructions
        refills
      }
      referenceNumber
    }
    address {
      id
      name
      buildingName
      buildingNumber
      streetAddress1
      streetAddress2
      phone
      city {
        id
        name
        __typename
      }
      postalCode
      coordinates {
        lng
        lat
        __typename
      }
    }
    branch {
      id
      name
      contactNumber
      vendor {
        id
        name
      }
      address {
        id
        name
        nameAr
        area
        areaAr
        streetAddress1
        streetAddress1Ar
        streetAddress2
        streetAddress2Ar
        buildingName
        buildingNumber
        phone
        isDefaultShippingAddress
        city {
          id
          name
          nameAr
        }
        region
        coordinates {
          lng
          lat
        }
      }
    }
    created
    orderLines {
      authStatus
      id
      rejectionReason
      quantity
      patientShareAmount
      netAmount
      tradeCode
      variantCode
    }
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;