import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type ClassificationProps = {
  name: string | undefined;
};

export const Classification: FC<ClassificationProps> = ({ name }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        width: "fit-content",
        whiteSpace: "nowrap",
        paddingInline: 1,
        borderRadius: "25px",
        background: theme.palette.stale.main,
        fontSize: theme.mixins.fonts.fontSize.xs,
        color: theme.palette.common.white,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 50, height: 30 }}>{name || "N/A"}</Box>
    </Typography>
  );
};
