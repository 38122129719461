import { useTranslation } from "@toolkit/i18n";
import { Accordion, AccordionDetails, AccordionSummary, ArrowDown, Box, Typography } from "@toolkit/ui";
import { FC, ReactNode, useState } from "react";
import { usePatientProfileSectionStyle } from "./PatientProfileSectionStyle";
import { PatientProfileSectionSubtitle } from "./PatientProfileSectionSubtitle";

interface PatientProfileSectionProps {
  title?: string;
  subTitle?: string;
  content?: ReactNode;
}
export const PatientProfileSection: FC<PatientProfileSectionProps> = ({ title, subTitle, content }) => {
  const { classes, theme } = usePatientProfileSectionStyle();

  const { t } = useTranslation("provider");
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Box className={classes.box}>
      <Accordion expanded={expanded} onChange={handleChange} classes={{ root: classes.root }}>
        {title && (
          <AccordionSummary
            expandIcon={<ArrowDown width='15px' height='8px' classes={{ root: classes.arrowRoot }} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            classes={{ root: classes.accordionSummary }}
          >
            <div>
              <Typography
                className={classes.span}
                fontSize={"26px"}
                color={theme.palette.primary.dark}
                fontWeight={theme.mixins.fonts.fontWeight.medium}
              >
                {t(title)}
              </Typography>
              <PatientProfileSectionSubtitle subTitle={subTitle} />
            </div>
          </AccordionSummary>
        )}
        <AccordionDetails classes={{ root: classes.detailsRoot }}>
          <Box paddingTop={"16px"}>{content}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

PatientProfileSection.defaultProps = {
  content: "",
  subTitle: "",
  title: undefined,
};
