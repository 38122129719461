import { AccountError, DoctorInput, DoctorSeniorityEnum, User } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDoctorQuery, useDoctorUpdateMutation } from "../gql";

export type FormValues = Pick<User, "photo"> &
  Omit<DoctorInput, "seniority"> & {
    seniority: {
      value?: DoctorSeniorityEnum;
    };
  };
export const useProfessionalsEditHook = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("provider");

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<AccountError[]>([]);
  const params = useParams() as { id: string };

  const { data, loading } = useDoctorQuery({
    variables: {
      id: params.id!,
    },
    skip: !params.id,
    onCompleted: data => {
      !data?.user && failed(t("There is something wrong"));
    },
  });
  const doctor = data?.user as Partial<User>;

  const [doctorUpdateMutation, { loading: loadingUpdate }] = useDoctorUpdateMutation({
    onCompleted: data => {
      const doctorErrors = data?.DoctorUpdate?.accountErrors! || [];
      if (doctorErrors?.length) {
        setErrors(doctorErrors!);
        failed(t("Doctor Updated Failed"));
      } else {
        succeeded(t("Doctor updated successfully"));
        navigate("/doctors");
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(t(message));
    },
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    const { bio, seniority, photo } = data;
    doctorUpdateMutation({
      variables: {
        doctorUpdateId: params.id,
        input: {
          photo,
          doctorInfo: {
            bio,
            seniority: seniority && seniority?.value!,
          },
        },
      },
    });
  };

  const handleCancel = () => {
    navigate("/doctors");
  };

  return {
    doctor,
    loading,
    errors,
    loadingUpdate,
    onSubmit,
    handleCancel,
  };
};
