/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthConditionsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthConditionFilterInput>;
}>;


export type GetHealthConditionsQuery = { __typename?: 'Query', getHealthConditions?: { __typename?: 'HealthConditioneConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null, edges?: Array<{ __typename?: 'HealthConditionEdge', node?: { __typename?: 'HealthCondition', id?: string | null, code?: string | null, type?: Types.HealthConditionType | null, display?: string | null, isActive?: boolean | null, fields?: Array<{ __typename?: 'HealthParameter', id?: string | null, type?: Types.TemplateFieldType | null, code?: string | null, display?: string | null, chartType?: Types.ChartType | null, referenceRange?: string | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, visibleForPatientOverview?: boolean | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null } | null } | null> | null } | null } | null> | null } | null };


export const GetHealthConditionsDocument = gql`
    query GetHealthConditions($first: Int, $after: String, $filter: HealthConditionFilterInput) {
  getHealthConditions(first: $first, after: $after, filter: $filter) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        code
        type
        display
        isActive
        fields {
          id
          type
          code
          display
          chartType
          unit {
            id
            display
          }
          referenceRange
          maxNormalRangeValue
          minNormalRangeValue
          visibleForPatientOverview
        }
      }
    }
  }
}
    `;

/**
 * __useGetHealthConditionsQuery__
 *
 * To run a query within a React component, call `useGetHealthConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthConditionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetHealthConditionsQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>(GetHealthConditionsDocument, options);
      }
export function useGetHealthConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>(GetHealthConditionsDocument, options);
        }
export type GetHealthConditionsQueryHookResult = ReturnType<typeof useGetHealthConditionsQuery>;
export type GetHealthConditionsLazyQueryHookResult = ReturnType<typeof useGetHealthConditionsLazyQuery>;
export type GetHealthConditionsQueryResult = Apollo.QueryResult<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>;