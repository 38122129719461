import { VisitDoctorNoteInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useDebounce } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getApolloContextFormToken } from "shared/utils";
import { VisitDoctorNoteUpdateDocument, VisitDoctorNoteUpdateMutation, useVisitDoctorNoteUpdateMutation, useVisitNoteQuery } from "../gql";

const useVisitNote = ({
  visitId,
  token,
  onVisitNoteChanged,
}: {
  visitId?: string;
  token?: string;
  onVisitNoteChanged: (notes: string) => void;
}) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const methods = useForm<VisitDoctorNoteInput>({
    mode: "onChange",
  });
  const debouncedValue = useDebounce(methods.watch("doctorNote"), 500);
  const {
    data,
    loading: isDataLoading,
    refetch,
  } = useVisitNoteQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
    skip: !visitId,
  });

  const [visitDoctorNoteUpdateMutation, { loading: isUpdateSubmitting }] = useVisitDoctorNoteUpdateMutation({
    context: getApolloContextFormToken(token),
    refetchQueries: [VisitDoctorNoteUpdateDocument],
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: VisitDoctorNoteUpdateMutation) => {
      const updateErrors = response?.visitDoctorNoteUpdate?.visitErrors;
      if (updateErrors && updateErrors?.length > 0) {
        methods.setError("doctorNote", {
          message: formatMessageErrors(updateErrors),
          type: "value",
        });
      } else {
        refetch();
        methods.setValue("doctorNote", response?.visitDoctorNoteUpdate?.visit?.doctorNote);
        succeeded(t("Visit doctor note updated successfully"));
      }
    },
    onError: () => {
      failed(t("Failed to update visit doctor note"));
    },
  });

  const handleSubmit = (InputData: VisitDoctorNoteInput) => {
    visitDoctorNoteUpdateMutation({
      variables: {
        input: {
          doctorNote: InputData?.doctorNote,
          visit: String(visitId),
        },
      },
    });
  };
  const isSubmitting = isUpdateSubmitting;
  const defaultData = data?.visit?.doctorNote;
  useEffect(() => {
    if (defaultData) {
      methods.setValue("doctorNote", defaultData);
    }
  }, [defaultData]);

  useEffect(() => {
    if (!debouncedValue || !onVisitNoteChanged) return;
    onVisitNoteChanged?.(debouncedValue);
  }, [debouncedValue]);

  return {
    errors: methods.formState.errors,
    methods,
    isSubmitting,
    defaultData,
    isDataLoading,
    handleSubmit,
  };
};

export default useVisitNote;
