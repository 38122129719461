/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type PatientCurrentMedicalStatusFragmentFragment = { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null };

export const PatientCurrentMedicalStatusFragmentFragmentDoc = gql`
    fragment patientCurrentMedicalStatusFragment on PatientCurrentMedicalStatus {
  id
  patientChronicDiseases {
    id
    code
    display
    createdDate
  }
  patientChronicMedications {
    id
    code
    display
    createdDate
  }
  patientNonChronicDiseases {
    id
    code
    display
    createdDate
  }
}
    `;