import { createPathDefinition } from "@toolkit/core";
import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";

export const discountsRoute = `${marketplaceRoute}/discounts`;

export const discountsPaths = createPathDefinition(discountsRoute, {
  main: "",
  new: "new",
  update: ":discountId",
});
