/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorOrderRejectMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID'];
  rejectionReason: Types.Scalars['String'];
}>;


export type VendorOrderRejectMutation = { __typename?: 'Mutation', vendorOrderReject?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null };


export const VendorOrderRejectDocument = gql`
    mutation vendorOrderReject($orderId: ID!, $rejectionReason: String!) {
  vendorOrderReject(orderId: $orderId, rejectionReason: $rejectionReason) {
    id
    status
  }
}
    `;
export type VendorOrderRejectMutationFn = Apollo.MutationFunction<VendorOrderRejectMutation, VendorOrderRejectMutationVariables>;

/**
 * __useVendorOrderRejectMutation__
 *
 * To run a mutation, you first call `useVendorOrderRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorOrderRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorOrderRejectMutation, { data, loading, error }] = useVendorOrderRejectMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useVendorOrderRejectMutation(baseOptions?: Apollo.MutationHookOptions<VendorOrderRejectMutation, VendorOrderRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorOrderRejectMutation, VendorOrderRejectMutationVariables>(VendorOrderRejectDocument, options);
      }
export type VendorOrderRejectMutationHookResult = ReturnType<typeof useVendorOrderRejectMutation>;
export type VendorOrderRejectMutationResult = Apollo.MutationResult<VendorOrderRejectMutation>;
export type VendorOrderRejectMutationOptions = Apollo.BaseMutationOptions<VendorOrderRejectMutation, VendorOrderRejectMutationVariables>;