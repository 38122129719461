import { useTranslation } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type SectionTitleProps = {
  title: string;
};

export const SectionTitle: FC<SectionTitleProps> = props => {
  const { title } = props;

  const { t } = useTranslation("provider");

  const [firstPart, secondPart] = title.split("(").map(part => part.trim().replaceAll(")", ""));
  const theme = useTheme();
  return (
    <>
      <Typography component='span' fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.md}>
        {t(firstPart)}
      </Typography>

      {secondPart && <Typography component='span'> ({t(secondPart)})</Typography>}
    </>
  );
};
