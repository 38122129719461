import { Grid } from "@toolkit/ui";
import ContentLoader from "react-content-loader";
import { useStyles } from "./DoctorCardSkeleton.styles";

export const DoctorCardSkeleton: React.VFC = () => {
  const { classes } = useStyles();
  return (
    <Grid className={classes.root}>
      <ContentLoader width='100%' viewBox='0 0 200 400'>
        <circle cx='24' cy='22' r='15' />
        <rect x='50' y='12' width='335' height='3' />
        <rect x='50' y='20' width='335' height='3' />
        <rect x='50' y='28' width='335' height='3' />
      </ContentLoader>
    </Grid>
  );
};

export default DoctorCardSkeleton;
