import { useTranslation } from "@toolkit/i18n";
import { Grid, Typography, useTheme } from "@toolkit/ui";
import { useProfileContext } from "shared/modules/patient/context/ProfileContext";
import { BasicHealthParameters } from "./BasicHealthParameters";
import { VitalSignsCharts } from "./components/VitalSignsCharts/VitalSignsCharts";

export const PatientVitalSignsCharts = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { patientId, token } = useProfileContext();

  return (
    <Grid item container spacing={2} alignContent='baseline'>
      <Grid item xs={12} marginBottom='-7px'>
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Vital Signs")}
        </Typography>
      </Grid>

      <Grid item container spacing={2} alignContent='baseline'>
        <VitalSignsCharts patientId={patientId!} token={token!} gridWidth={2} />
        <BasicHealthParameters />
      </Grid>
    </Grid>
  );
};
