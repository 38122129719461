/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceDiscountCreateMutationVariables = Types.Exact<{
  input: Types.MarketplaceDiscountCreateInput;
}>;


export type MarketplaceDiscountCreateMutation = { __typename?: 'Mutation', marketplaceDiscountCreate?: { __typename?: 'MarketplaceDiscount', id: string } | null };


export const MarketplaceDiscountCreateDocument = gql`
    mutation marketplaceDiscountCreate($input: MarketplaceDiscountCreateInput!) {
  marketplaceDiscountCreate(input: $input) {
    id
  }
}
    `;
export type MarketplaceDiscountCreateMutationFn = Apollo.MutationFunction<MarketplaceDiscountCreateMutation, MarketplaceDiscountCreateMutationVariables>;

/**
 * __useMarketplaceDiscountCreateMutation__
 *
 * To run a mutation, you first call `useMarketplaceDiscountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceDiscountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceDiscountCreateMutation, { data, loading, error }] = useMarketplaceDiscountCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceDiscountCreateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceDiscountCreateMutation, MarketplaceDiscountCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceDiscountCreateMutation, MarketplaceDiscountCreateMutationVariables>(MarketplaceDiscountCreateDocument, options);
      }
export type MarketplaceDiscountCreateMutationHookResult = ReturnType<typeof useMarketplaceDiscountCreateMutation>;
export type MarketplaceDiscountCreateMutationResult = Apollo.MutationResult<MarketplaceDiscountCreateMutation>;
export type MarketplaceDiscountCreateMutationOptions = Apollo.BaseMutationOptions<MarketplaceDiscountCreateMutation, MarketplaceDiscountCreateMutationVariables>;