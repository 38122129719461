import { DoctorSeniorityEnum } from "@health/queries/types";
import { combineErrors, getRequiredValidation, required } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IProps } from "./types";

export const GeneralInformation: FC<IProps> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("provider");

  const {
    register,
    formState: { errors: reactFormErrors },
    control,
  } = useFormContext();

  const formErrors = combineErrors(reactFormErrors, errors);

  const doctorSeniority = Object.keys(DoctorSeniorityEnum).map(type => {
    return { name: type, value: DoctorSeniorityEnum[type] };
  });

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <AutocompleteController
            options={doctorSeniority || []}
            getOptionLabel={o => o?.name}
            defaultValue={doctorSeniority?.find(el => el?.value === defaultValue?.doctor?.seniority) || null}
            ControllerProps={{
              name: "seniority",
              control: control,
              rules: { required: getRequiredValidation(t, true) },
              defaultValue: doctorSeniority?.find(el => el?.value === defaultValue?.doctor?.seniority) || null,
            }}
            TextFieldProps={{
              error: Boolean(formErrors?.seniority?.message),
              helperText: formErrors?.seniority?.message,
              label: t("Seniority"),
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size='small'
            multiline
            rows={6}
            label={t("Bio")}
            error={Boolean(formErrors.bio?.message)}
            helperText={t(formErrors.bio?.message)}
            defaultValue={defaultValue?.doctor?.bio}
            {...register("bio", { required: required(t) })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
