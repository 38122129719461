import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { IMedicalFormUpsertFormEvent, MedicalFormUpsertForm } from "@health/domains";
import { MedicalForm } from "@health/queries/types";
import { createMedicalFormsBreadcrumbs, medicalFormsPaths } from "pages/MedicalForms/constants";
import {
  ProviderMedicalFormGetDocument,
  ProviderMedicalFormUpdateMutation,
  useProviderMedicalFormGetQuery,
  useProviderMedicalFormUpdateMutation,
} from "pages/MedicalForms/gql";
import { convertProviderMedicalFormValuesToBackEndValues } from "pages/MedicalForms/others/utils";
import { IProviderMedicalFormUpdateContainerParams } from "pages/MedicalForms/types/types";

export const ProviderMedicalFormUpdateContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const { t } = useTranslation("provider");

  const { failed, succeeded } = useAddToast();
  const { medicalFormId } = useParams<IProviderMedicalFormUpdateContainerParams>();

  const { data, loading } = useProviderMedicalFormGetQuery({
    variables: { medicalFormId: medicalFormId! },
    skip: !medicalFormId,
  });

  const medicalForm = data?.medicalForm as MedicalForm;

  const [fetchProviderMedicalFormUpdateMutation, { loading: isSubmitting }] = useProviderMedicalFormUpdateMutation({
    onCompleted: (mutationData: ProviderMedicalFormUpdateMutation) => {
      const errors = mutationData?.updateMedicalForm?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Medical form updated successfully"));
        navigate(medicalFormsPaths.main.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [ProviderMedicalFormGetDocument],
  });

  const onMedicalFormUpsertFormChange = (event: IMedicalFormUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertProviderMedicalFormValuesToBackEndValues(event.payload.values);

      if (!values?.questions?.length) {
        return failed(t("There should be at least one question included in the medical form"));
      }

      fetchProviderMedicalFormUpdateMutation({
        variables: {
          updateMedicalFormId: medicalFormId!,
          input: values,
        },
      });
    }
  };

  const handleCancel = () => {
    navigate(medicalFormsPaths.main.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({ title: createMedicalFormsBreadcrumbs(t).title });
  }, [setBreadCrumb, t]);

  return (
    <MedicalFormUpsertForm
      submitButtonLabel={t("Update")}
      medicalForm={medicalForm}
      isLoading={loading}
      isSubmitDisabled={loading || isSubmitting}
      handleCancel={handleCancel}
      onChange={onMedicalFormUpsertFormChange}
    />
  );
};
