import { Department } from "@health/queries/types";
import { maxRequired, minRequired } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";

export const departmentFields = (t: TFunction, defaultValues?: Department) => [
  {
    name: "name",
    label: t("Name", { ns: "provider" }),
    defaultValue: defaultValues?.name,
    isRequired: true,
    minLength: minRequired(t, 2),
    maxLength: maxRequired(t, 256),
  },
  {
    name: "code",
    label: t("Code", { ns: "provider" }),
    defaultValue: defaultValues?.code,
    minLength: minRequired(t, 3),
    maxLength: maxRequired(t, 256),
    isRequired: true,
  },
  {
    name: "directions",
    label: t("Directions", { ns: "provider" }),
    defaultValue: defaultValues?.directions,
  },
  {
    name: "description",
    label: t("Description", { ns: "provider" }),
    defaultValue: defaultValues?.description,
    isRequired: false,
    minLength: minRequired(t, 3),
    rows: 4,
    multiline: true,
  },
];
