import { formGirdSpacing } from "@toolkit/core";
import { Grid } from "@toolkit/ui";
import OutPatientJourneyForm from "pages/Settings/GeneralSettings/forms/out-patient-journey/out-patient-journey.form";
import useGeneralSettings from "pages/Settings/GeneralSettings/useGeneralSettings";
import React, { FC } from "react";

interface GeneralSettingsProps {}

export const GeneralSettings: FC<GeneralSettingsProps> = () => {
  const { providerSettings, loading } = useGeneralSettings();

  return (
    <Grid container spacing={formGirdSpacing} padding={4}>
      <Grid item xs={12}>
        <OutPatientJourneyForm providerSettings={providerSettings} isLoading={loading} />
      </Grid>
    </Grid>
  );
};
