import { AppointmentNotificationSetting, AppointmentType, NotificationChannel } from "@health/queries/types";
import { z } from "zod";

type ReminderSettingFormValidation = {
  appointmentType?: AppointmentType;
  reminderSetting?: AppointmentNotificationSetting;
};

export const useReminderSettingFormValidation = ({ appointmentType, reminderSetting }: ReminderSettingFormValidation) => {
  const getDefaultFormValues = () => {
    const defaultChannels = reminderSetting?.channels ? reminderSetting?.channels.map(item => item) : [NotificationChannel.App];
    const defaultAppointmentType = reminderSetting?.types ?? [appointmentType];

    return {
      sendTimeInMinutes: (reminderSetting?.sendTimeInMinutes ?? 0) / 60,
      arabicText: reminderSetting?.arabicText ?? "",
      englishText: reminderSetting?.englishText ?? "",
      channels: defaultChannels,
      types: defaultAppointmentType,
      id: reminderSetting?.id,
    };
  };

  const reminderSettingFormSchema = z.object({
    sendTimeInMinutes: z.coerce.number().min(0, { message: "Min value is 0" }),
    englishText: z
      .string()
      .min(3, { message: "Length must be at least 3 characters" })
      .max(255, { message: "Length must be at most 255 characters" }),
    arabicText: z
      .string()
      .min(3, { message: "Length must be at least 3 characters" })
      .max(255, { message: "Length must be at most 255 characters" }),
    channels: z.array(z.string()).min(1, { message: "At least one channel must be selected" }),
    types: z.array(z.string()),
  });

  return {
    getDefaultFormValues,
    reminderSettingFormSchema,
  };
};
