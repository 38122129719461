/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RadiologyDeleteMutationVariables = Types.Exact<{
  input: Types.VisitSummaryInput;
}>;


export type RadiologyDeleteMutation = { __typename?: 'Mutation', radDelete?: { __typename?: 'VisitSummaryCRUD', deleteResult?: { __typename?: 'VisitSummary', id: string } | null, visitSummaryErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, message?: string | null, field?: string | null }> | null } | null };


export const RadiologyDeleteDocument = gql`
    mutation radiologyDelete($input: VisitSummaryInput!) {
  radDelete: visitSummaryCreate(input: $input) {
    deleteResult: visitSummary {
      id
    }
    visitSummaryErrors {
      code
      message
      field
    }
  }
}
    `;
export type RadiologyDeleteMutationFn = Apollo.MutationFunction<RadiologyDeleteMutation, RadiologyDeleteMutationVariables>;

/**
 * __useRadiologyDeleteMutation__
 *
 * To run a mutation, you first call `useRadiologyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRadiologyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [radiologyDeleteMutation, { data, loading, error }] = useRadiologyDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRadiologyDeleteMutation(baseOptions?: Apollo.MutationHookOptions<RadiologyDeleteMutation, RadiologyDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RadiologyDeleteMutation, RadiologyDeleteMutationVariables>(RadiologyDeleteDocument, options);
      }
export type RadiologyDeleteMutationHookResult = ReturnType<typeof useRadiologyDeleteMutation>;
export type RadiologyDeleteMutationResult = Apollo.MutationResult<RadiologyDeleteMutation>;
export type RadiologyDeleteMutationOptions = Apollo.BaseMutationOptions<RadiologyDeleteMutation, RadiologyDeleteMutationVariables>;