import { useTranslation } from "@toolkit/i18n";
import { Grid, MDEditorField } from "@toolkit/ui";
import { ProductData } from "pages/MarketplaceProducts/types";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type ProductFormHowToUseProps = {
  product?: ProductData;
};

export const ProductFormHowToUse: FC<ProductFormHowToUseProps> = ({ product }) => {
  const { control } = useFormContext();
  const { t } = useTranslation("provider");
  return (
    <>
      <Grid item xs={12}>
        <MDEditorField
          controllerProps={{
            name: "howToUse",
            control: control,
          }}
          label={t("How to use")}
          placeholder={t("How to use")}
          defaultValue={product?.howToUse || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <MDEditorField
          controllerProps={{
            name: "howToUseAr",
            control: control,
          }}
          label={t("How to use (Arabic)")}
          placeholder={t("How to use (Arabic)")}
          defaultValue={product?.howToUse || ""}
        />
      </Grid>
    </>
  );
};
