import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Box, Button, CircularProgress, CustomIcon, CustomTable, Grid, TextField } from "@toolkit/ui";
// import { watch } from "fs";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { StyleFix } from "../components/StyleFix";
import { useColumns } from "./useColumns";
import { useRadiology } from "./useRadiology";
import { useStyles } from "./useStyles";

export const Radiology: FC<{ visitId: string; token?: string }> = ({ visitId, token }) => {
  const { t } = useTranslation("provider");
  // TODO : fetch rads from server
  const rads = [
    {
      code: "70460",
      name: "Computed tomography, head or brain; with contrast",
    },
    { code: "71048", name: "Radiologic examination, chest; 4 or more views" },
  ];
  const radiologyColumns = useColumns({ rads });
  const useRadiologyReturn = useRadiology({ visitId, token });
  const { errors, methods, isLoading, isDeleting, isSubmittingCreate } = useRadiologyReturn;
  const { handleOnSort, handleCreate, handleDelete } = useRadiologyReturn;
  const { classes, theme } = useStyles();
  return (
    <Box sx={{ p: 2 }}>
      <StyleFix />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleCreate)}>
          <Grid item xs={9.5} md={11} columnSpacing={2}>
            <Grid item py={"5px"}>
              <AutocompleteController
                ControllerProps={{
                  control: methods.control,
                  name: "radiology",
                }}
                options={rads}
                {...methods.register("radiology")}
                getOptionLabel={option => option.code + " " + option.name}
                TextFieldProps={{
                  fullWidth: true,
                  error: Boolean(errors?.radiology?.message),
                  helperText: errors.radiology?.message,
                  label: t("Procedure/Image"),
                }}
              />
            </Grid>
            <Grid item py={"5px"}>
              <TextField
                multiline
                rows={3}
                fullWidth
                label={t("Description (Spelled out clearly in proper English, don’t use abbreviations)")}
              />
            </Grid>
            <Grid item py={"5px"}>
              <TextField multiline fullWidth label={t("Reason for order")} />
            </Grid>
          </Grid>
          <Box component={"footer"} sx={{ display: "flex", gap: 2, py: "10px" }}>
            <Button
              startIcon={isSubmittingCreate ? <CircularProgress /> : <CustomIcon icon='add' color={theme.palette.common.white} />}
              type='submit'
            >
              {isSubmittingCreate ? t("Adding") + "..." : t("Add")}
            </Button>
            <Button
              type='reset'
              sx={{
                backgroundColor: `${theme.palette.common.white} !important`,
                color: theme.palette.primary.main + "!important",
                ":hover": {
                  backgroundColor: `${theme.palette.common.white} !important`,
                  color: theme.palette.primary.main + "!important",
                },
              }}
            >
              {t("Reset Fields")}
            </Button>
          </Box>
        </form>
      </FormProvider>
      <Box className={classes.grid}>
        <CustomTable
          isLoading={isLoading || isDeleting}
          data={(rads as any) || []}
          columns={radiologyColumns as any}
          onSortColumn={handleOnSort}
          onDeleteRow={row => handleDelete(row)}
          hasFooter={false}
          isDeleteVisible
        />
      </Box>
    </Box>
  );
};
Radiology.defaultProps = {
  token: undefined,
};

export default Radiology;
