/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientHealthParametersInsertMutationVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']>;
  input: Array<Types.PatientHealthParameterInput> | Types.PatientHealthParameterInput;
}>;


export type PatientHealthParametersInsertMutation = { __typename?: 'Mutation', insertPatientHealthParameters?: Array<{ __typename?: 'PatientHealthParameterCRUD', patientHealthParameter?: { __typename?: 'PatientHealthParameter', id: string } | null } | null> | null };


export const PatientHealthParametersInsertDocument = gql`
    mutation PatientHealthParametersInsert($visitId: ID, $input: [PatientHealthParameterInput!]!) {
  insertPatientHealthParameters(visitId: $visitId, input: $input) {
    patientHealthParameter {
      id
    }
  }
}
    `;
export type PatientHealthParametersInsertMutationFn = Apollo.MutationFunction<PatientHealthParametersInsertMutation, PatientHealthParametersInsertMutationVariables>;

/**
 * __usePatientHealthParametersInsertMutation__
 *
 * To run a mutation, you first call `usePatientHealthParametersInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientHealthParametersInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientHealthParametersInsertMutation, { data, loading, error }] = usePatientHealthParametersInsertMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientHealthParametersInsertMutation(baseOptions?: Apollo.MutationHookOptions<PatientHealthParametersInsertMutation, PatientHealthParametersInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientHealthParametersInsertMutation, PatientHealthParametersInsertMutationVariables>(PatientHealthParametersInsertDocument, options);
      }
export type PatientHealthParametersInsertMutationHookResult = ReturnType<typeof usePatientHealthParametersInsertMutation>;
export type PatientHealthParametersInsertMutationResult = Apollo.MutationResult<PatientHealthParametersInsertMutation>;
export type PatientHealthParametersInsertMutationOptions = Apollo.BaseMutationOptions<PatientHealthParametersInsertMutation, PatientHealthParametersInsertMutationVariables>;