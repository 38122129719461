import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { Box, CircularProgress, SxProps, Theme, makeStyles, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useMarkNotificationAsSeenMutation } from "./gql";
import { NotificationItemProps } from "./types";

const useMarkNotificationAsSeenWrapperStyle = makeStyles()(theme => ({
  root: {
    padding: 1,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  seenIndicator: {
    borderRadius: 25,
    background: theme.palette.primary.main,
    padding: "5px",
  },
}));

export const NotificationItemWrapper: FC<{
  id: string | null | undefined;
  isSeen: boolean | undefined;
  sx?: SxProps<Theme> | undefined;
  renderNotificationItem: (params: Partial<NotificationItemProps>) => React.ReactNode;
}> = ({ id, isSeen, sx, renderNotificationItem }) => {
  const { classes } = useMarkNotificationAsSeenWrapperStyle();
  const { failed } = useAddToast();

  const [doMarkNotificationAsSeen, { loading: markNotificationAsSeenLoading }] = useMarkNotificationAsSeenMutation({
    onCompleted: request => {
      const errors = request?.markNotificationAsSeen?.errors;
      if (errors?.length === 0) {
        console.info("Mark notification as seen successfully");
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleMarkNotificationAsSeen = () => () => {
    if (isSeen) return;
    id &&
      doMarkNotificationAsSeen({
        variables: {
          id,
        },
      });
  };

  return (
    <Box onClick={handleMarkNotificationAsSeen()} className={classes.root} sx={sx}>
      {renderNotificationItem({ handleNotificationActionComplete: handleMarkNotificationAsSeen() })}
      {!isSeen && <>{markNotificationAsSeenLoading ? <CircularProgress size={25} /> : <Box className={classes.seenIndicator} />}</>}
    </Box>
  );
};
