import { PageWrapper, TableGrid } from "@toolkit/ui";
import { useGuidedCareTeamPatientListQuery } from "pages/HealthProviderManagement/GuidedCare/gql";
import { IGuidedCarePatientsContainerParams } from "pages/HealthProviderManagement/GuidedCare/types";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useGuidedCareTeamPatientListTableColumns } from "./useGuidedCareTeamPatientListTableColumns";

export const GuidedCareTeamPatientListContainer = () => {
  const { teamId } = useParams<IGuidedCarePatientsContainerParams>();

  return (
    <GridProvider
      gridName='guidedCareTeamPatientList'
      columns={useGuidedCareTeamPatientListTableColumns()}
      query={useGuidedCareTeamPatientListQuery}
      variables={{ teamId: teamId! }}
      skipCall={!teamId}
    >
      <PageWrapper>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
