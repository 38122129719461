import { useTranslation } from "@toolkit/i18n";
import { IconButton, ProviderIcon, Tooltip, useTheme } from "@toolkit/ui";
import { useState } from "react";
import { NurseWorkingDetailsSelectDialog } from "shared/components/NurseCurrentWorkDetails";

export const BranchUserButton = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Tooltip title={t("Select Branch")}>
        <IconButton
          onClick={handleOpen}
          sx={{
            border: "1px solid",
            borderColor: theme.palette.primary.main,
            "&:hover": {
              background: theme.palette.primary.main,
              "& svg": {
                fill: theme.palette.common.white,
              },
            },
          }}
        >
          <ProviderIcon />
        </IconButton>
      </Tooltip>

      <NurseWorkingDetailsSelectDialog isOpen={isOpen} onClose={handleClose} />
    </>
  );
};
