import { Visit } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { Button, JoinCallIcon } from "@toolkit/ui";
import { FC } from "react";
import { openMeetingPlatformLink } from "shared/components/VisitStartingNowNotification/utils";
import { useStyles } from "./WaitingRoomsCard.styles";

export const JoinCall: FC<{ disabled?: boolean; t: TFunction; visit: Visit }> = ({ disabled = false, t, visit }) => {
  const handleJoinCall = () => {
    openMeetingPlatformLink(visit?.meetingPlatformLinkForJoinCall!, visit?.id);
  };
  const { classes, theme } = useStyles();
  return (
    <Button
      className={classes?.joinCallButton}
      startIcon={<JoinCallIcon width={"18px"} height={"18px"} fill={theme.palette.primary.main} />}
      variant='text'
      disabled={disabled}
      onClick={handleJoinCall}
      sx={{ width: 60 }}
    >
      {t("Join Call", { ns: "provider" })}
    </Button>
  );
};
