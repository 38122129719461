import { zodSchema } from "@health/domains";
import { z } from "zod";

export const GuidedCareTaskUpdateFormSchema = z.object({
  status: zodSchema.guidedCareTaskStatusAutocompleteEnum,
  assignees: z.array(zodSchema.guidedCareTeamMemberUserAutocomplete).min(1),
});

export type IGuidedCareTaskUpdateFormValues = z.infer<typeof GuidedCareTaskUpdateFormSchema>;

export const guidedCareTaskUpdateFormDefaultValues: Partial<IGuidedCareTaskUpdateFormValues> = {
  status: undefined,
  assignees: [],
};
