import { makeStyles } from "@toolkit/ui";
const displayButton = "inline-block";
const textOverflowEllipsis = "ellipsis";
const overflowHidden = "hidden !important";
export const useOrderActionStyles = makeStyles()(theme => ({
  outlinedAccept: {
    color: theme.palette.success.main,
    border: "1px solid",
    borderColor: theme.palette.success.main,
    whiteSpace: "nowrap",
    display: displayButton,
    textOverflow: textOverflowEllipsis,
    overflow: overflowHidden,
    padding: "7px 20px",
    "&:hover": {
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.success.main,
      background: `${theme.palette.success.main} !important`,
    },
  },
  buttons: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    fontSize: 13,
    whiteSpace: "nowrap",
    display: displayButton,
    textOverflow: textOverflowEllipsis,
    overflow: overflowHidden,
  },
  accept: {
    margin: 5,
    padding: "8px 20px",
    whiteSpace: "nowrap",
    display: displayButton,
    textOverflow: textOverflowEllipsis,
    overflow: overflowHidden,
    backgroundColor: `${theme.palette.success.main} !important`,
    "&:disabled": {
      color: theme.palette.common.white,
      opacity: 0.5,
      background: theme.palette.success.main,
    },
    "&:hover": {
      color: theme.palette.common.white,
      opacity: 0.9,
      background: theme.palette.success.main,
    },
  },
  dispense: {
    border: "1px solid !important",
    borderColor: theme.palette.success.main + " !important",
    color: `${theme.palette.success.main} !important`,
    backgroundColor: `${theme.palette.common.white} !important`,
    "&:disabled": {
      color: theme.palette.success.main,
      opacity: 0.5,
      background: theme.palette.common.white,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.success.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  insurance: {
    margin: 5,
    width: 180,
    background: theme.palette.success.main,
    "&:hover": {
      color: theme.palette.common.white,
      opacity: 0.9,
      background: theme.palette.success.main,
    },
  },
  reject: {
    margin: 5,
    paddingInline: 20,
    backgroundColor: `${theme.palette.error.main} !important`,
    whiteSpace: "nowrap",
    "&:hover": {
      color: theme.palette.common.white,
      opacity: 0.9,
      background: theme.palette.error.main,
    },
  },
  arabic: {
    margin: 5,
    padding: 25,
    background: theme.palette.success.main,
    "&:hover": {
      color: theme.palette.common.white,
      opacity: 0.9,
      background: theme.palette.success.main,
    },
  },

  box: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  avatar: {
    height: 65,
    width: 65,
    borderRadius: 10,
    marginRight: 10,
  },
  text: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
